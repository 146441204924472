(function () {
    'use strict'

    angular
        .module('showcaseApp')
        .service('SaleReportService', SaleReportService);

    //** NgInject */
    function SaleReportService(SaleReportFactory) {

        var service = {
            listUsersRegisteredByGenderWithSaleGeneratedByStore: listUsersRegisteredByGenderWithSaleGeneratedByStore,
            listMostLikedCategories: listMostLikedCategories,
            listUsersPerformMoreSalesTransactions: listUsersPerformMoreSalesTransactions,
            listUsersFirstInteraction: listUsersFirstInteraction
        };

        return service;

        function listUsersRegisteredByGenderWithSaleGeneratedByStore(storeId) {
            return SaleReportFactory.listUsersRegisteredByGenderWithSaleGeneratedByStore(storeId).$promise;
        }

        function listMostLikedCategories(storeId, langKey) {
            return SaleReportFactory.listMostLikedCategories({
                storeId: storeId,
                langKey: langKey
            }).$promise;
        }

        function listUsersPerformMoreSalesTransactions(storeId) {
            return SaleReportFactory.listUsersPerformMoreSalesTransactions(storeId).$promise;
        }

        function listUsersFirstInteraction(storeId) {
            return SaleReportFactory.listUsersFirstInteraction(storeId).$promise;
        }
    }


})();
