(function () {
    'use strict'

    angular
        .module('showcaseApp')
        .service('CustomerService', CustomerService);

    /** @ngInject */
    function CustomerService(CustomerFactory) {

        var service = {
            create: create,
            update: update,
            list: list,
            listAllByIds: listAllByIds,
            delete: deleteCustomers,
            saveImportedCustomers: saveImportedCustomers,
            getOrCreateCustomerByWhatsapp: getOrCreateCustomerByWhatsapp,
            getByName: getByName,
            getOrCreateCustomerByFbInstagram: getOrCreateCustomerByFbInstagram
        };

        return service;


        function create(customer) {
            return CustomerFactory.create(customer).$promise;
        }

        function update(customer) {
            return CustomerFactory.update({id: customer.id}, customer).$promise;
        }

        function list(pageable, customerFilter) {
            return CustomerFactory.list(pageable, customerFilter).$promise;
        }

        function listAllByIds(customerFilter, onSuccess, onError) {
            return CustomerFactory.listAllByIds(customerFilter, onSuccess, onError);
        }

        function deleteCustomers(id) {
            return CustomerFactory.delete({id: id}).$promise;
        }

        function saveImportedCustomers (customers) {
            return CustomerFactory.importCustomers(customers).$promise;
        }

        function getOrCreateCustomerByWhatsapp(customer) {
            return CustomerFactory.getOrCreateCustomerByWhatsapp(customer).$promise;
        }

        function getByName(fullname, storeId) {
            return CustomerFactory.getByName({search: fullname, storeId: storeId}).$promise;
        }

        function getOrCreateCustomerByFbInstagram(customer) {
            return CustomerFactory.getOrCreateCustomerByFbInstagram(customer).$promise;
        }
    }

})();
