(function() {
    'use strict';
    angular
        .module('showcaseApp')
        .factory('RentInvoicesFactory', RentInvoicesFactory);

        RentInvoicesFactory.$inject = ['$resource'];

    function RentInvoicesFactory($resource) {
        var resourceUrl =  'flyer/api/transaction/list?type=RENT';

        return $resource(resourceUrl, {id: '@id'}, {
            'listAll': {
                method: 'GET',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data){
                        response.data = angular.fromJson(data);
                    } else{
                        response.data = data;
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'updateStatus': {
                method: 'PUT',
                url: 'flyer/api/transaction/:id/rent/update-status',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data){
                        response.data = angular.fromJson(data);
                    } else{
                        response.data = data;
                    }
                    response.headers = headers();
                    return response;
                }
            },
        });
    }

})();
