(function() {
    "use strict";

    angular
        .module("showcaseApp")
        .factory("ConfirmPublishModalFactory", ConfirmPublishModalFactory);


    /** @ngInject */
    function ConfirmPublishModalFactory($uibModal) {

        var factory = {
            publishItem: publishItem
        };

        return factory;


        function publishItem() {
            return $uibModal.open({
                templateUrl: 'app/admin/hub-management/confirm-publish/confirm-publish.modal.html',
                controller: 'ConfirmPublishModalController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {}
            }).result;
        }


    }

})();
