(function () {
    'use strict';

    angular
        .module('showcaseApp')
        .controller('HubCampaignFormController', HubCampaignFormController);


    /** @ngInject */
    function HubCampaignFormController(HubCampaignService, toastr, $stateParams, $localStorage, $translate, $uibModal, $state) {

        var vm = this;

        vm.loading = false;
        vm.submitting = false;
        vm.isEdit = false;
        vm.hubId = $localStorage.hub.id;

        vm.currentTags = [
            {id: 1, name: "Test"}
        ]

        if ($stateParams.id) {
            vm.isEdit = true;
            getCampaign($stateParams.id)
        } else {
            vm.campaign = {hubId: vm.hubId};
            loadTags();
        }


        vm.searchTags = function (search) {
            return vm.currentTags.filter( function (tag) {
                return tag.name.toLowerCase().indexOf(search.toLocaleLowerCase()) >= 0;
            });
        }

        vm.save = function () {
            vm.submitting = true;

            if (vm.campaign.tags) {
                vm.campaign.tags = vm.campaign.tags.map(function (tag) {
                    return {id: tag.id, name: tag.name}
                })
            } else {
                vm.campaign.tags = [];
            }

            if (vm.isEdit) {
                HubCampaignService.update(vm.campaign.id, vm.campaign)
                    .then(function () {
                        toastr.success($translate.instant("campaign.message.campaign-saved"));
                        $state.go('hub-campaigns');
                    }, function () {
                        toastr.error($translate.instant("campaign.message.error-to-update"));
                    }).finally(function () { vm.submitting = false});
            } else {
                HubCampaignService.create(vm.campaign)
                    .then(function () {
                        toastr.success($translate.instant("campaign.message.campaign-saved"));
                        $state.go('hub-campaigns');
                    }, function () {
                        toastr.error($translate.instant("campaign.message.error-to-create"));
                    }).finally(function () { vm.submitting = false});
            }
        }

        vm.addShowcase = function () {
            $uibModal.open({
                templateUrl: 'app/admin/hub-management/campaign/_modal_add_showcase.html',
                controller: function (hubId, selectedShowcases, $uibModalInstance, HubShowcasesService, toastr) {

                    var vm = this;

                    vm.loadingShowcases = true;
                    vm.selectedShowcases = selectedShowcases ? selectedShowcases : [];

                    vm.listFilter = {
                        ownerId: hubId,
                        name: ''
                    };

                    loadShowcases();

                    vm.check = function (showcase, evt) {
                        if (evt.target.checked) {
                            vm.selectedShowcases.push(showcase);
                        } else {
                            var index = _.findIndex(vm.selectedShowcases, function (shc) {
                                return shc.id === showcase.id;
                            });
                            vm.selectedShowcases.splice(index, 1);
                        }
                    }

                    vm.isChecked = function (showcase) {
                        var index = _.findIndex(vm.selectedShowcases, function (shc) {
                            return shc.id === showcase.id;
                        });
                        return index > -1
                    }

                    vm.closeModal = function () {
                        $uibModalInstance.dismiss('cancel');
                    }

                    vm.ok = function () {
                        $uibModalInstance.close(vm.selectedShowcases);
                    }

                    function loadShowcases() {
                        HubShowcasesService.listShowcases(vm.listFilter)
                            .then(function (result) {
                                vm.showcases = result.data;
                            }, function () {
                                toastr.error("Erro ao carregar as vitrines")
                            }).finally(function () {vm.loadingShowcases = false;});
                    }
                },
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    selectedShowcases: function () {
                        return angular.copy(vm.campaign.showcases);
                    },
                    hubId: function () {
                        return vm.hubId;
                    }
                }
            }).result.then(function (response) {
                if (response) {
                    vm.campaign.showcases = response;
                }
            });
        }

        vm.removeShowcase = function (index) {
            vm.campaign.showcases.splice(index, 1);
        }

        function getCampaign(id) {
            vm.loading = true;
            HubCampaignService.getOne(id)
                .then(function (response) {
                    vm.campaign = response.data;
                }, function () {
                    toastr.error($translate.instant("Erro ao carregar a campanha"));
                }).finally(function () { vm.loading = false});
        }

        function loadTags() {

        }

    }

})();
