(function() {
    'use strict';

    angular
        .module('showcaseApp')
        .config(stateConfig);

    function stateConfig($stateProvider) {
        $stateProvider
        .state('poster-create-new', {
            parent: 'admin',
            url: '/digital-poster/new',
            data: {
                authorities: ['POSTER_CREATE'],
                pageTitle: 'digitalPoster.title',
                // specialClass: "md-skin fixed-nav"
            },
            views: {
                'content@app': {
                    templateUrl: 'app/admin/digital-poster/digital-poster.html',
                    controller: 'DigitalPosterController',
                    controllerAs: 'vm'
                }
            },
            params: {

            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('digital-poster');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        });
    }
})();
