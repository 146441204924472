(function() {
    'use strict';

    angular
        .module('showcaseApp')
        .controller('RecordingTimerController', RecordingTimerController);

    RecordingTimerController.$inject = ['$scope', '$timeout'];

    function RecordingTimerController($scope, $timeout) {
        var recordStopped;
        var blinking;

        $scope.timer = 0;
        $scope.showRedLight = false;
        function init() {
            $scope.timer = 0;
            $scope.showRedLight = false;
            recordCountdown();
            blink();
        }

        $scope.$on("$destroy", function() {
            stopRecordCountdown();
        });

        function recordCountdown() {
            recordStopped = $timeout(function() {
                $scope.timer++;
                recordCountdown();
            }, 1000);
        };

        function stopRecordCountdown(){
            $scope.timer = 0;
            $scope.showRedLight = false;
            $timeout.cancel(recordStopped);
            $timeout.cancel(blinking);
        };

        function blink() {
            blinking = $timeout(function() {
                $scope.showRedLight = !$scope.showRedLight;
                blink();
            }, 500);
        }

        init();
    }
})();
