(function () {
    'use strict'

    angular
        .module('showcaseApp')
        .controller('StoreTypeModalConstroller', StoreTypeModalConstroller);

    /** @ngInject */
    function StoreTypeModalConstroller(storeType, $uibModalInstance, $scope, HubService, toastr, $translate, moment) {
        var format = 'L';

        var vm = this;


        vm.storeType = storeType || {};
        vm.cancel = cancel;
        vm.init = init;
        vm.save = save;

        function init() {

        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            if (vm.formAddEditCustomer.$valid) {
                vm.isSaving = true;
                var customerToSave = angular.copy(vm.customer);
                customerToSave.birthdate = customerToSave.birthdate ? moment.utc(customerToSave.birthdate, format).toDate() : undefined;
                customerToSave.storeId = StoreInfoFactory.getStoreId();
                customerToSave.cpf = customerToSave.cpf === "" ? undefined : customerToSave.cpf;

                if (vm.customer.id) {
                    CustomerService.update(customerToSave).then(onSuccess, onError);
                } else {
                    CustomerService.create(customerToSave).then(onSuccess, onError);
                }

                function onSuccess(result) {
                    vm.isSaving = false;
                    $uibModalInstance.close(result);
                }

                function onError(error) {
                    vm.isSaving = false;
                    if (error.data.headers["x-adminapp-params"]) {
                        toastr.error($translate.instant(error.data.headers["x-adminapp-params"].concat(".").concat(error.data.headers["x-adminapp-error"])));
                    } else {
                        toastr.error($translate.instant("global.messages.somethingWrong"));
                    }
                }
            }
        }
    }

})();
