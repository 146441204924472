(function () {
    'use strict';

    angular
        .module('showcaseApp')
        .controller('StoreManagementEditController', StoreManagementEditController);

    StoreManagementEditController.$inject = ['$scope', '$rootScope', '$translate', 'StoreService', '$uibModalInstance', 'CategoryService', 'store', 'toastr', '$window', 'isStep'];

    function StoreManagementEditController($scope, $rootScope, $translate, StoreService, $uibModalInstance, CategoryService, store, toastr, $window, isStep) {

        var vm = this;
        vm.store = store;
        console.log(vm.store);
        vm.store.originNickname = vm.store.nickname;
        vm.isSaving = false;
        vm.imageUpload = '';
        vm.croppedImage = '';
        vm.imageSize = 800;
        vm.imageCroppedActive = false;
        vm.domainSufix = undefined;
        vm.currencyList = getCurrencyListConfiguration();

        vm.isMobile = detectar_mobile();

        vm.imagesErrors = {
            fileInputPublicLogo: false,
            fileInputFavicon: false,
            fileInputLogo: false
        };

        if (vm.store.social == null) {
            vm.store.social = { whatsApp: vm.store.contactPhone };
        }

        // if (!vm.store.currency) {
        //     vm.store.currency = 'REAL';
        // }

        // holds the number of installment to be applied
        vm.installment = [0, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

        vm.publicLogoImage = null;
        vm.faviconImage = null;
        vm.logoImage = null;
        vm.idSelectInput = '';
        vm.widthImage = null;
        vm.heightImage = null;

        loadAll();

        function loadAll() {
            CategoryService.listCategories($translate.use()).then(function (response) {
                vm.categories = response.data;
                if (isStep) {
                    vm.tab = 1;
                }
            });
            vm.domainSufix = '.' + getDomainNameSufix($window, vm.store.storeType);

            instanceImages();
        }

        function instanceImages() {
            var srcImagePublicLogo = vm.store.publicLogo;
            if (srcImagePublicLogo && srcImagePublicLogo.split(',')[0].indexOf('base64') == -1) {
                vm.publicLogoImage = vm.store.publicLogo + '?dummy=' + new Date().getTime();
            }

            var srcImageFavicon = vm.store.favicon;
            if (srcImageFavicon && srcImageFavicon.split(',')[0].indexOf('base64') == -1) {
                vm.faviconImage = vm.store.favicon + '?dummy=' + new Date().getTime();
            }

            var srcImageLogo = vm.store.storeLogo;
            if (srcImageLogo && srcImageLogo.split(',')[0].indexOf('base64') == -1) {
                vm.logoImage = vm.store.storeLogo + '?dummy=' + new Date().getTime();
            }
        }

        vm.checkInstallmentNoFee = function () {
            vm.store.installment < vm.store.installmentNoFee ? vm.store.installmentNoFee = 0 : '';
        };

        vm.allowInstallmentListner = function () {
            if (!vm.allowInstallment) {
                vm.store.installmentNoFee = 0;
                vm.store.installment = 0
            }
        };

        function savePublicLogo() {
            if (vm.publicLogoImage && vm.publicLogoImage != vm.store.publicLogo) {
                StoreService.saveStoreImage(dataURItoBlob(vm.publicLogoImage)).then(function (response, error) {
                    vm.store.publicLogoImage = response.data;
                });
            } else if (!vm.publicLogoImage) {
                vm.store.publicLogo = null;
            }
        }

        function saveLogo() {
            if (vm.logoImage && vm.logoImage !== vm.store.logo) {
                StoreService.saveStoreImage(dataURItoBlob(vm.logoImage)).then(function (response, error) {
                    vm.store.addImageFileDTO = response.data;
                });
            } else if (!vm.logoImage) {
                vm.store.logo = null;
            }
        }

        function saveFavicon() {
            if (vm.faviconImage && vm.faviconImage !== vm.store.favicon) {
                StoreService.saveStoreImage(dataURItoBlob(vm.faviconImage)).then(function (response, error) {
                    vm.store.faviconImageFileDTO = response.data;
                });
            } else if (!vm.faviconImage) {
                vm.store.favicon = null;
            }
        }

        function dataURItoBlob(dataURI) {
            // convert base64/URLEncoded data component to raw binary data held in a string
            var byteString;
            if (dataURI.split(',')[0].indexOf('base64') >= 0)
                byteString = atob(dataURI.split(',')[1]);
            else
                byteString = unescape(dataURI.split(',')[1]);

            // separate out the mime component
            var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

            // write the bytes of the string to a typed array
            var ia = new Uint8Array(byteString.length);
            for (var i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
            }

            return new Blob([ia], {
                type: mimeString
            });
        }

        vm.save = function () {
            if (!vm.storeEditForm.$invalid) {
                var category = vm.categories.filter(function (category) {
                    return category.key === vm.store.categoryKey;
                });
                vm.store.categoryId = category[0].id;
                vm.isSaving = true;
                vm.store.cnpj = (angular.isDefined(vm.store.cnpj) && vm.store.cnpj !== "") ? vm.store.cnpj : undefined;
                if (vm.store.seo) {
                    vm.store.seo.title = vm.store.seo.title === "" ? undefined : vm.store.seo.title;
                    vm.store.seo.description = vm.store.seo.description === "" ? undefined : vm.store.seo.description;
                }
                var storeToSave = angular.copy(vm.store);

                if (storeToSave.social) {
                    storeToSave.social.instagram = splitAbsoluteUrl("instagram.com/", storeToSave.social.instagram);
                    storeToSave.social.facebook = splitAbsoluteUrl("facebook.com/", storeToSave.social.facebook);
                    storeToSave.social.twitter = splitAbsoluteUrl("twitter.com/", storeToSave.social.twitter);
                    storeToSave.social.tiktok = splitAbsoluteUrl("tiktok.com/@", storeToSave.social.tiktok);
                }

                StoreService.updateStore(storeToSave).then(function (data) {
                    vm.store = data;
                    $rootScope.$broadcast('storeChangeSuccess');
                    toastr.success($translate.instant('store-management.messages.update.success'));
                    vm.isSaving = false;
                    $uibModalInstance.close(data);
                }, function (error) {
                    vm.isSaving = false;
                    toastr.error($translate.instant('store-management.messages.update.error'));
                });
            }
        }

        function splitAbsoluteUrl(url, value) {
            if (value) {
                if (value.includes(url)) {
                    return value.split(url)[1];
                }
            }
            return value;
        }

        $scope.$watch('vm.store.nickname', function (newVal, oldVal) {
            if (newVal && newVal.length >= 20) {
                vm.store.nickname = oldVal;
            }
        });

        vm.closeModal = function () {
            $uibModalInstance.close();
        }

        vm.openInput = function (evt, id, width, height) {
            vm.idSelectInput = id;
            vm.widthImage = Number(width);
            vm.heightImage = Number(height);
            document.getElementById(id).click();
            angular.element(document.querySelector('#' + id)).on('change', validateImageWidth);
        }

        var validateImageWidth = function (event) {
            var input = document.getElementById(vm.idSelectInput);

            if (input.files && input.files[0]) {

                var imagem = new Image();
                imagem.src = window.URL.createObjectURL(input.files[0]);

                imagem.onload = function () {
                    if (this.width != vm.widthImage || this.height != vm.heightImage) {
                        input = null
                        toastr.error($translate.instant('store-management.tab.imageError'));
                        vm.imagesErrors[vm.idSelectInput] = true;
                    } else {
                        vm.imagesErrors[vm.idSelectInput] = false;
                        handleFileSelect(event);
                    }
                };
            }
        };

        var handleFileSelect = function (event) {
            var file = event.target.files[0];
            if (file) {
                var reader = new FileReader();
                reader.onload = function (e) {
                    var img = document.createElement("img");
                    img.onload = function (evt) {
                        var canvas = document.createElement("canvas");
                        var ctx = canvas.getContext("2d");
                        ctx.drawImage(img, 0, 0);

                        var MAX_WIDTH = vm.widthImage;
                        var MAX_HEIGHT = vm.heightImage;
                        canvas.width = MAX_WIDTH;
                        canvas.height = MAX_HEIGHT;
                        var ctx = canvas.getContext("2d");
                        ctx.drawImage(img, 0, 0, MAX_WIDTH, MAX_HEIGHT);

                        var dataurl = canvas.toDataURL(file.type);

                        if (vm.idSelectInput === 'fileInputPublicLogo') {
                            vm.publicLogoImage = dataurl;
                            savePublicLogo();
                        } else if (vm.idSelectInput === 'fileInputFavicon') {
                            vm.faviconImage = dataurl;
                            saveFavicon();
                        } else if (vm.idSelectInput === 'fileInputLogo') {
                            vm.logoImage = dataurl;
                            saveLogo();
                        }

                        var output = document.getElementById('img' + vm.idSelectInput);
                        output.src = dataurl;
                    }
                    img.src = reader.result;

                };
                reader.readAsDataURL(file);
            }
        };

        vm.verifyNicknameIsAvailable = function (form) {
            if (vm.store.nickname && (vm.store.nickname != vm.store.originNickname)) {
                StoreService.verifyIfNicknameAvailable(vm.store.nickname).then(function (result) {
                    if (!result.data) {
                        form.nickname.$setValidity('nicknameIsUnavailable', false);
                    } else {
                        form.nickname.$setValidity('nicknameIsUnavailable', true);
                    }
                }, function (error) {
                    form.nickname.$setValidity('nicknameIsUnavailable', false);
                });
            } else {
                form.nickname.$setValidity('nicknameIsUnavailable', true);
            }
        }

    }

})();
