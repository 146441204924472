(function() {
    'use strict';
    angular
        .module('showcaseApp')
        .service('DirectRegisterService', DirectRegisterService);

    DirectRegisterService.$inject = ['DirectRegisterFactory', '$localStorage'];

    function DirectRegisterService(DirectRegisterFactory, $localStorage) {

        return {
            registerInit: registerInit,
            registerFinish: registerFinish
        }

        function registerInit(loginOrMail) {
            return DirectRegisterFactory.init(loginOrMail).$promise;
        }

        function registerFinish(login, password) {
            return DirectRegisterFactory.finish({
                login: login,
                password: password
            }).$promise;
        }
    }
})();
