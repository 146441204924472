(function() {
    'use strict';

    angular
        .module('showcaseApp')
        .constant('paginationConstants', {
            'itemsPerPage': 50,
            'sortOptions': {
                "pt-br": [
                    {id: 1, label: "A-Z", value: "name", direction: 'asc'},
                    {id: 2, label: "Z-A", value: "name", direction: 'desc'},
                    {id: 3, label: "Maior preço", value: "value", direction: 'desc'},
                    {id: 4, label: "Menor preço", value: "value", direction: 'asc'},
                    {id: 5, label: "Publicados", value: "published", direction: 'desc'},
                    {id: 6, label: "Recém atualizados", value: "lastModifiedDate", direction: 'desc'},
                    {id: 7, label: "Lançamento", value: "isLaunch", direction: 'desc'},
                    {id: 8, label: "Últimas peças", value: "isBasic", direction: 'desc'},
                    {id: 9, label: "Maior desconto", value: "discountValue", direction: 'desc'},
                    {id: 10, label: "Mais vendidos", value: "bestSellers", direction: 'desc'},
                    {id: 11, label: "Sem estoque", value: "inventory", direction: 'asc'}
                ],
                "es": [
                    {id: 1, label: "A-Z", value: "name", direction: 'asc'},
                    {id: 2, label: "Z-A", value: "name", direction: 'desc'},
                    {id: 3, label: "Precio más alto", value: "value", direction: 'desc'},
                    {id: 4, label: "Precio más bajo", value: "value", direction: 'asc'},
                    {id: 5, label: "Publicados", value: "published", direction: 'desc'},
                    {id: 6, label: "Recién actualizado", value: "lastModifiedDate", direction: 'desc'},
                    {id: 7, label: "Launch", value: "isLaunch", direction: 'desc'},
                    {id: 8, label: "Últimas piezas", value: "isBasic", direction: 'desc'},
                    {id: 9, label: "Mayor descuento", value: "discountValue", direction: 'desc'},
                    {id: 10, label: "Más vendidos", value: "bestSellers", direction: 'desc'},
                    {id: 11, label: "Agotado", value: "inventory", direction: 'asc'}
                ],
                "en": [
                    {id: 1, label: "A-Z", value: "name", direction: 'asc'},
                    {id: 2, label: "Z-A", value: "name", direction: 'desc'},
                    {id: 3, label: "Highest price", value: "value", direction: 'desc'},
                    {id: 4, label: "Lowest price", value: "value", direction: 'asc'},
                    {id: 5, label: "Publicados", value: "published", direction: 'desc'},
                    {id: 6, label: "Just updated", value: "lastModifiedDate", direction: 'desc'},
                    {id: 7, label: "Launch", value: "isLaunch", direction: 'desc'},
                    {id: 8, label: "Latest pieces", value: "isBasic", direction: 'desc'},
                    {id: 9, label: "Biggest discount", value: "discountValue", direction: 'desc'},
                    {id: 10, label: "Best Sellers", value: "bestSellers", direction: 'desc'},
                    {id: 11, label: "Out of stock", value: "inventory", direction: 'asc'}
                ]
            },
            'defaultSortOption': 5
        });
})();
