(function () {
    'use strict';

    angular
        .module('showcaseApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('checkoutDefault', {
                abstract: true,
                templateUrl: "app/account/checkout/default/content.html",
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('checkoutInner', {
                parent: 'checkoutDefault',
                url: '/checkout?payment',
                data: {
                    authorities: [],
                    pageTitle: 'store-info.pageTitle'
                },
                views: {
                    'content@checkoutDefault': {
                        templateUrl: 'app/account/checkout/checkout.html',
                        controller: 'CheckoutController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    plans: function(CheckoutService, $q, $location){
                        var deferred = $q.defer();
                        var currentEnv = $location.absUrl().split('.')[1];
                        CheckoutService.getPlans(currentEnv).then(function(result) {
                            deferred.resolve(result.data);
                        }, function (error) {
                            deferred.resolve(error);
                        });
                        return deferred.promise;
                    },
                    account: function (Principal, $q) {
                        var deferred = $q.defer();

                        Principal.identity().then(function(account) {
                            deferred.resolve(account);
                        }, function (error) {
                            deferred.resolve(undefined);
                        });
                        angular.element('body').removeClass('mini-navbar');
                        return deferred.promise;
                    },
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('checkout');
						$translatePartialLoader.addPart('gift-card');
                        $translatePartialLoader.addPart('store-management');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('error');
                        return $translate.refresh();
                    }]

                }
            })
            .state('checkoutInprogress', {
                parent: 'checkoutDefault',
                url: '/checkout-done',
                data: {
                    authorities: [],
                    pageTitle: 'store-info.pageTitle'
                },
                views: {
                    'content@checkoutDefault': {
                        templateUrl: 'app/account/checkout/checked.html',
                        controller: 'CheckedController',
                        controllerAs: 'vm'

                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('checkout');
						$translatePartialLoader.addPart('gift-card');
                        $translatePartialLoader.addPart('store-management');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]

                }
            });
    }
})();
