	(function () {
    'use strict';

    angular
        .module('showcaseApp')
        .controller('GiftCardCheckoutController', GiftCardCheckoutController);

    function GiftCardCheckoutController(GiftCardService, $translate, toastr) {

        var vm = this;

		vm.validateGiftCard = validateGiftCard;

		function validateGiftCard() {
            if (vm.formGiftValidate.$valid && !vm.isSending) {
                vm.isSending = true;
				GiftCardService.updateSubscriptionGiftCard(vm.giftCard).then(function (response) {
                    vm.isSending = false;
					/*if (response.headers()['x-error-on-subscriber-message']){
				    	toastr.error(response.headers()['x-error-on-subscriber-message']);
				    }*/
					toastr.success($translate.instant('gift-card.validate-sucess'));
	            }, function (response) {
                    vm.isSending = false;
					if (response.headers()['x-error-validate']){
				    	toastr.error($translate.instant('gift-card.not-exists'));
				    }else if (response.headers()['x-error']){
	                    toastr.error($translate.instant('gift-card.validate-error'));
					}
	            });

            }
        }

    }
})();
