(function () {
    'use strict'

    angular
        .module('showcaseApp')
        .controller('CategoryFlyerModalController', CategoryFlyerModalController);

    /** @ngInject */
    function CategoryFlyerModalController($uibModalInstance, category, CategoryFlyerService, $localStorage, toastr, $translate, $state) {
        var vm = this;
        vm.close = close;
        vm.save = save;
        vm.categorys = [];
        vm.category = category || { categoryId: '0' };
        vm.indexCategoryParent;

        init();

        function init() {
            getCategorys();
            vm.category.categoryId = vm.category.categoryId || '0';
        }

        function getCategorys() {
            CategoryFlyerService.listCategoriesByCurrentStore($translate.use()).then(function (response) {
                if (response && response.data) {
                    vm.categorys = response.data;
                    if (vm.category && vm.category.id) {
                        vm.categorys = response.data.filter(function(category) {
                            return category.id != vm.category.id;
                        });
                    }
                }
            });
        }

        function close() {
            $uibModalInstance.dismiss('cancel');
        }

        function findIndex(value){
			return value.id == this;
		}

        function save() {
            if (vm.categoryForm.$valid) {
                vm.isSaving = true;
                var key = vm.category.title.toUpperCase().replace(/ /g, "_");

                if (vm.category.id) {
                    var categoryDTO = { id: vm.category.id, key: key, title: vm.category.title, categoryId: vm.category.categoryId, index: vm.category.index };
                    CategoryFlyerService.update(categoryDTO).then(function () {
                        toastr.success($translate.instant('categorys.messages.edit.success'));
                        $state.reload();

                    }, function (error) {
                        if (error && error.data && error.data.headers.statuskey === 'categoryCannotBeChild') {
                            var index = vm.categorys.findIndex(findIndex, vm.category.categoryId);
                            toastr.error($translate.instant('categorys.messages.edit.error-child', {value: vm.categorys[index].title}));
                        } else {
                            toastr.error($translate.instant('categorys.messages.edit.error'));
                        }
                        vm.isCreatingCategory = false;
                    });
                } else {
                    var categoryDTO = { key: key, languages: [{ langKey: $translate.use(), title: vm.category.title }], categoryId: vm.category.categoryId };
                    CategoryFlyerService.create(categoryDTO).then(function () {
                        toastr.success($translate.instant('categorys.messages.new.success'));
                        $state.reload();

                    }, function (error) {
                        if(error && error.data && error.data.headers.statuskey === 'categoryAlreadyExists') {
                            toastr.error($translate.instant('categorys.messages.new.duplicated'));
                        } else {
                            toastr.error($translate.instant('categorys.messages.new.error'));
                        }
                        vm.isCreatingCategory = false;
                    });
                }

            }
        }

    }

})();
