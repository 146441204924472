(function() {
    'use strict';
    angular
        .module('showcaseApp')
        .factory('TeamFactory', TeamFactory);

        TeamFactory.$inject = ['$resource'];

    function TeamFactory($resource) {

        var resourceUrl =  'api/team';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                url: 'api/team/:id',
                transformResponse: function (data, headers) {
                    var response = {};
                    response.data = angular.fromJson(data);
                    response.headers = headers();
                    return response;
                }
            },
            'list': {
                method: 'GET',
                url: '/api/team/list',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'create': { 
                method:'POST',
                url: '/api/team',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                } 
            },
            'update': { 
                method:'PUT',
                url: '/api/team/:id',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                } 
            },
            'remove': { 
                method:'DELETE',
                url: '/api/team/:id',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                } 
            }
        });
    }
})();
