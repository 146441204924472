(function() {
    'use strict';
    angular
        .module('showcaseApp')
        .service('GoogleMapsService', GoogleMapsService);

    GoogleMapsService.$inject = ['GoogleMapsAddressFactory'];

    function GoogleMapsService(GoogleMapsAddressFactory) {
        var service = {
                getAddress: getAddress,
                getAddressByCoordinates: getAddressByCoordinates
        };

        return service;

        function getAddress(pointAddress) {
            return GoogleMapsAddressFactory.getAddress(pointAddress);
        }

        function getAddressByCoordinates(lat, lng) {
            return GoogleMapsAddressFactory.getAddressByCoordinates({lat: parseFloat(lat), lng: parseFloat(lng)});
        }
    }
})();
