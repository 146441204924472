(function () {
    'use strict';

    angular
        .module('showcaseApp')
        .controller('InvoiceNewController', InvoiceNewController);

    function InvoiceNewController($q, UserService, StoreInfoFactory, $localStorage, toastr, $translate, $state, $uibModal,
                                  InvoicesService, CheckCEPService, StoreService, DiscountCouponService) {

        var vm = this;
        vm.store = $localStorage.defaultStore;
        vm.currencyPrefix = getCurrencyListConfiguration()['REAL'].prefix;
        vm.isBrl = true;

        vm.randomTime = new Date().getTime();

        vm.height = window.innerHeight;
        vm.states = getAllStates();

        function setDynamicDivHeight() {
            vm.height = window.innerHeight;
        }

        window.addEventListener('load', setDynamicDivHeight);
        window.addEventListener('resize', setDynamicDivHeight);

        // Auxiliaries for sellers entities
        vm.querySearchSellers = querySearchSellers;
        vm.selectedSellerChange = selectedSellerChange;

        vm.invoice = {
            storeId: StoreInfoFactory.getStoreId(),
            shippingOption: 'PICK_UP_IN_STORE',
            paymentMethod: 'DIRECT_SALE',
            paymentCondition: 'MONEY',
            shippingValue: 0,
            customer: {},
            items: [],
            currency: 'REAL'
        }

        vm.selectedCoupon = undefined;
        vm.purchaseValue = 0;

        init();

        function init() {
            loadStore();
            getUsersSellers();
        }

        function loadCurrencyPrefix(store) {
            vm.invoice.currency = store.currency ? store.currency : 'REAL';
            var config = getCurrencyListConfiguration()[vm.invoice.currency];
            vm.currencyPrefix = config.prefix;
        }

        function loadStore() {
            StoreService.getStore($localStorage.defaultStore.storeId).then(function (response) {
                if (response && response.data) {
                    vm.store = response.data;
                    vm.storeCategory = response.data.categoryKey;
                    vm.isBrl = response.data.langKey == 'pt-br';
                    loadCurrencyPrefix(vm.store)
                }
            });
        }

        function getUsersSellers() {
            UserService.getUsersSellers({ storeId: vm.invoice.storeId }).then(function (response) {
                vm.sellers = response.data;
                vm.searchTextSeller = ''
                vm.selectedSeller = _.find(vm.sellers, function (user) {
                    return user.id === $localStorage.userId;
                });
            });
        }

        function querySearchSellers(query) {
            var results = query ? vm.sellers.filter(createFilterFor(query)) : vm.sellers;
            var defer = $q.defer();
            defer.resolve(results);
            return defer.promise;
        }

        function createFilterFor(query) {
            var lowercaseQuery = query.toLowerCase();

            return function filterFn(seller) {
                return seller.firstName.toLowerCase().indexOf(lowercaseQuery) >= 0
                    || seller.lastName && seller.lastName.toLowerCase().indexOf(lowercaseQuery) >= 0;
            };
        }

        function selectedSellerChange(seller) {
            if (seller) {
                vm.invoice.sellerId = seller.id;
                vm.invoice.sellerName = seller.fullName;
                vm.selectedSeller = seller;
            } else {
                vm.invoice.sellerId = null;
                vm.invoice.sellerName = null;
                vm.selectedSeller = null;
            }
        }

        vm.getTreatedName = function (item) {
            if (item.fullName.length > 20) {
                var treatedlastName = ' ';
                item.lastName.split(' ').forEach(function (lastName) {
                    treatedlastName = treatedlastName.concat(lastName[0]).concat('. ');
                });
                var treatedName = item.firstName + treatedlastName

                return treatedName.toLowerCase().trim().split(' ').map(function (word) {
                    return word[0].toUpperCase() + word.substring(1);
                }).join(" ");
            }

            return item.fullName.toLowerCase().trim().split(' ').map(function (word) {
                return word[0].toUpperCase() + word.substring(1);
            }).join(" ");
        }

        vm.selectedProduct = function (item) {
            if (!item.originalObject.details) {
                for (var i = 0; i < vm.invoice.items.length; i++) {
                    var object = vm.invoice.items[i];
                    if (item.originalObject.id === object.flyer.id) {
                        toastr.warning($translate.instant('invoice.new.messages.product-already-exists'));
                        return;
                    }
                }
            } else if (!item.originalObject.details && item.originalObject.inventory === 0) {
                toastr.warning($translate.instant('invoice.new.messages.product-without-inventory'));
                return;
            }

            vm.invoice.items.push({
                flyer: item.originalObject,
                quantity: 1,
                detail: null
            });
            vm.calculatePrice();
        }

        vm.selectedDiscountCoupon = function (item) {
            if (item && item.originalObject && item.originalObject.code) {
                if (!isDiscountCouponValid(item.originalObject)) {
                    toastr.warning($translate.instant('invoice.new.messages.invalid-discount-coupon'));
                    return;
                }
                vm.invoice.discountCoupon = item.originalObject.code;
                vm.selectedCoupon = item.originalObject;
            } else {
                vm.invoice.discountCoupon = undefined;
                vm.selectedCoupon = undefined;
            }
            vm.calculatePrice();
        }

        function isDiscountCouponValid(item) {
            return item && (!item.hasUsageLimit || !item.couponAvailable || item.couponsAvailable > 0);
        }

        vm.deleteProduct = function (index) {
            vm.invoice.items.splice(index, 1);
            vm.calculatePrice();
        }

        vm.duplicateProduct = function (product) {
            if (!product.details || product.details.length === 0) {
                toastr.warning($translate.instant('invoice.new.messages.product-cant-be-duplicated'));
                return;
            }

            vm.invoice.items.push({
                flyer: product,
                quantity: 1,
                detail: null
            });
        }

        vm.requestParams = function (query) {
            return {
                page: 0,
                size: 10,
                storeId: StoreInfoFactory.getStoreId(),
                search: query
            }
        }

        vm.requestDiscountCouponParams = function (query) {
            return {
                page: 0,
                size: 10,
                name: query,
                code: query,
                enabled: true
            }
        }

        vm.selectedCustomer = function (item) {
            if (item && item.originalObject) {
                vm.invoice.customer = item.originalObject;
            }
        }

        vm.filterSizes = function (details) {
            return details.filter(function (detail) {
                return detail.inventory == null || detail.inventory > 0;
            })
        }

        vm.changeCustomer = function (value) {
            if (vm.invoice.customer && vm.invoice.customer.id) {
                vm.invoice.customer = {
                    fullName: value
                };
            } else if (!vm.invoice.customer.id) {
                vm.invoice.customer.fullName = value;
            }
        }

        vm.updatePurchaseValue = function() {
            vm.purchaseValue = vm.invoice.subTotal + vm.invoice.shippingValue - (vm.invoice.discount ? vm.invoice.discount : 0);
            if (!vm.invoice.subTotal || vm.invoice.subTotal < 0 || !vm.purchaseValue || vm.purchaseValue < 0) {
                vm.purchaseValue = 0;
            }
        }

        vm.calculatePrice = function () {
            if (vm.invoice.items) {
                InvoicesService.calculateDiscount(vm.invoice)
                .then(function (response) {
                    if (response.data) {
                        vm.invoice.discount = response.data;
                    } else {
                        vm.invoice.discount = undefined;
                    }
                    vm.updatePurchaseValue();
                }, function (err) {
                    vm.invoice.discount = undefined;
                    vm.updatePurchaseValue();
                });
                var subTotal = 0;
                angular.forEach(vm.invoice.items, function (item) {
                    if (item.flyer.details && item.flyer.details.length) {
                        if (item.detail) {
                            subTotal += item.quantity * (item.detail.discountedValue ? item.detail.discountedValue : item.detail.purchaseValue);
                        }
                    } else {
                        subTotal += item.quantity * (item.flyer.discountedValue ? item.flyer.discountedValue : item.flyer.purchaseValue);
                    }
                })
                vm.invoice.subTotal = subTotal;
                if (!vm.invoice.shippingValue) {
                    vm.invoice.shippingValue = 0;
                }
                vm.invoice.total = subTotal;
                vm.updatePurchaseValue();
            }
        }

        vm.changeQuantity = function (item, value) {
            verifyQuantityMessage(item, item.quantity + value);
            if (item.quantity + value <= 0) {
                return;
            }

            if (item.flyer.inventory && (item.quantity + value) <= item.flyer.inventory) {
                item.quantity += value;
            }

            if (item.detail && item.detail.inventory) {
                if ((item.quantity + value) <= item.detail.inventory) {
                    item.quantity += value;
                }
            } else if (item.detail && item.detail.inventory == null) {
                item.quantity += value;
            }

            if (item.detail == null && item.flyer.inventory == null && (item.flyer.details && item.flyer.details.length == 0)) {
                item.quantity += value;
            }

            vm.calculatePrice();
        }

        vm.onChangeQuantity = function (item) {
            verifyQuantityMessage(item, item.quantity);
            if (item.quantity < 0) {
                item.quantity = 0;
            } else if (item.detail && (item.quantity === undefined || item.quantity > item.detail.inventory)) {
                item.quantity = item.detail.inventory;
            } else if (!item.detail && item.flyer.inventory && item.flyer.inventory !== 0
                && (item.quantity === undefined || item.quantity > item.flyer.inventory)) {
                item.quantity = item.flyer.inventory;
            }

            vm.calculatePrice();
        }

        vm.save = function () {
            if (hasInvalidItem() || checkInvalidCustomer()) {
                return;
            }

            var request = parseRequest();
            InvoicesService.createInvoice(request)
                .then(function () {
                    toastr.success($translate.instant('invoice.new.messages.invoice-created'));
                    $state.go('invoices')
                })
                .catch(function (err) {
                    toastr.error($translate.instant('invoice.new.messages.error-to-created'));
                });
        }

        vm.checkCEP = function () {
            if (vm.invoice.shippingOption === 'RECEIVE_AT_HOME' && vm.invoice.customer.zipcode && vm.invoice.customer.zipcode.length === 8) {
                CheckCEPService.getAddress(vm.invoice.customer.zipcode).then(function (response) {
                    if (response.data) {
                        vm.invoice.customer.address = response.data.name;
                        vm.invoice.customer.district = response.data.district.name;
                        vm.invoice.customer.city = response.data.location.name;
                        vm.invoice.customer.state = response.data.location.uf;
                    }
                });
            } else {
                vm.invoice.customer.state = null;
            }
        }

        vm.checkIfVariationAlreadyExists = function (item, index) {
            for (var i = 0; i < vm.invoice.items.length; i++) {
                var object = vm.invoice.items[i];
                if (item.flyer.id === object.flyer.id && item.detail.size === object.detail.size && i !== index) {
                    toastr.warning($translate.instant('invoice.new.messages.product-variation-already-exists'));
                    item.detail = undefined;
                    return;
                }
            }
        }

        function verifyQuantityMessage(item, value) {
            if (item.detail && (value === undefined || value > item.detail.inventory)) {
                toastr.warning($translate.instant('invoice.new.messages.limit-amount-reached'));
            } else if (!item.detail && item.flyer.inventory && item.flyer.inventory !== 0
                && (value === undefined || value > item.flyer.inventory)) {
                toastr.warning($translate.instant('invoice.new.messages.limit-amount-reached'));
            }
        }

        function parseRequest() {
            var request = angular.copy(vm.invoice);
            request.customer.phoneNumber = request.customer.phone;
            if (vm.invoice.shippingOption === 'RECEIVE_AT_HOME') {
                request.customer.street = request.customer.address;
                request.customer.streetNumber = request.customer.addressNumber;
                request.customer.zipCode = request.customer.zipcode;
            }

            return request;
        }

        function hasInvalidItem() {
            if (!vm.invoice.items || !vm.invoice.items.length) {
                toastr.warning($translate.instant('invoice.new.messages.select-a-product'));
                return true;
            }

            for (var i = 0; i < vm.invoice.items.length; i++) {
                var item = vm.invoice.items[i];
                if (item.flyer.details && item.flyer.details.length && !item.detail) {
                    toastr.warning($translate.instant('invoice.new.messages.has-product-without-size'));
                    return true;
                }

                if (!item.quantity) {
                    toastr.warning($translate.instant('invoice.new.messages.has-product-without-quantity'));
                    return true;
                }
            }

            return false;
        }

        function checkInvalidCustomer() {
            if (!vm.invoice.customer.id && (!vm.invoice.customer.fullName)) {
                toastr.warning($translate.instant('invoice.new.messages.invalid-customer-full-name'));
                return true;
            }

            if (!vm.invoice.customer.id && (!vm.invoice.customer.phone)) {
                toastr.warning($translate.instant('invoice.new.messages.invalid-customer-phone'));
                return true;
            }

            if (vm.invoice.shippingOption === 'RECEIVE_AT_HOME' &&
                (!vm.invoice.customer.address || !vm.invoice.customer.zipcode
                    || !vm.invoice.customer.addressNumber || !vm.invoice.customer.district
                    || !vm.invoice.customer.city || !vm.invoice.customer.state)) {
                toastr.warning($translate.instant('invoice.new.messages.invalid-customer-address'));
                return true;
            }

            return false;
        }

    }

})();
