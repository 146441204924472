(function () {
    'use strict';

    angular
        .module('showcaseApp')
        .factory('uiGridBuilder', uiGridBuilder);

    uiGridBuilder.$inject = ['$translate'];

    function uiGridBuilder($translate) {
        return {

            withDefaultOptions: function() {
                this.columnDefs = [];
                this.actions = undefined;
                this.enablePaginationControls = false,
                this.showGridFooter = false;
                this.showHeader = true;
                this.enablePaging = true;
                this.enableColumnMenus = true;
                this.useExternalPagination = true;
                this.useExternalSorting = true;
                this.paginationPageSize = 10;
                this.maxPagesPagination = 4;
                return this;
            },

            withHeader: function(showHeader) {
                this.showHeader = showHeader;
                return this;
            },

            withInitialSort: function(columnName, ascendent) {
                this.initialSort = {};
                this.initialSort.columnName = columnName;
                this.initialSort.direction = 'asc';
                if ((ascendent !== undefined) && (ascendent !== null) && (ascendent == false)) {
                    this.initialSort.direction = 'desc';
                }
                return this;
            },

            withColumns: function(columnDefs) {
                // for (var i = 0; i < columnDefs.length; i++) {
                //     this.columnDefs.push({field: columnDefs[i].field, displayName: $translate.instant(columnDefs[i].displayName)});
                // }
                this.columnDefs = columnDefs;
                return this;
            },

            withActionColumn: function(columnWidth) {
                this.showActionColumn = true;
                this.actionColumnWidth = columnWidth;
                return this;
            },

            addActionToActionColumn: function(name, methodName, icon, linkClasses, visibilityExpression, disabledExpression) {
                if (this.actions === undefined) {
                    this.actions = [];
                }
                this.actions.push(new UiGridAction(name, methodName, icon, linkClasses, visibilityExpression, disabledExpression));
                return this;
            },

            conditionallyAddActionToActionColumn: function(canAdd, name, methodName, icon, linkClasses, visibilityExpression, disabledExpression) {
                if (canAdd) {
                    return this.addActionToActionColumn(name, methodName, icon, linkClasses, visibilityExpression, disabledExpression);
                }
                return this;
            },

            build: function(searchMethodName) {
                var options = {};
                options.searchMethodName = searchMethodName;
                options.showGridFooter = this.showGridFooter;
                options.showHeader = this.showHeader;
                options.enablePaging = this.enablePaging;
                options.useExternalPagination = this.useExternalPagination;
                options.useExternalSorting = this.useExternalSorting;
                options.enableColumnMenus = this.enableColumnMenus;
                options.totalItems = 0;
                options.paginationPageSizes = this.paginationPageSizes;
                options.paginationPageSize = this.paginationPageSize;
                options.enablePaginationControls = this.enablePaginationControls;
                options.columnDefs = this.columnDefs;
                options.$initialSort = this.initialSort;
                options.maxPagesPagination = this.maxPagesPagination;
                options.enableFiltering = this.enableFiltering;
                options.paginationOptions = {
                    pageNumber: 1,
                    pageSize: this.paginationPageSize,
                    sort: null
                };

                // Action Column
                if ((this.showActionColumn) && (this.actions !== undefined) && (this.actions.length > 0)) {
                    var actionColDef = {
                        displayName: 'Actions',
                        name: 'actions',
                        enableSorting: false,
                        cellClass: 'text-center',
                    };
                    actionColDef.width = this.actionColumnWidth;
                    if (this.actions !== undefined) {
                        var actionColumnTemplate = '';
                        for (var i = 0; i < this.actions.length; i++) {
                            var action = this.actions[i];
                            var ngIf = '';
                            var ngDisabled = '';
                            if (action.visibilityExpression !== undefined) {
                                ngIf = ' ng-if="' + action.visibilityExpression + '"';
                            }
                            if (action.disabledExpression !== undefined) {
                                ngDisabled = ' ng-disabled="' + action.disabledExpression + '"';
                            }
                            actionColumnTemplate +=
                                '<a title="' + action.name + '"'
                                + ngIf
                                + ngDisabled
                                + ' ng-click="grid.appScope.' + action.methodName + '(row.entity);"'
                                + ' class="btn btn-link btn-sm ' + action.linkClasses + '">'
                                + '<i class="' + action.icon + '"></i></a>';
                        }
                        actionColDef.cellTemplate = '<div class="ui-grid-action-cell-contents">' + actionColumnTemplate + '</div>';
                    }
                    options.columnDefs.push(actionColDef);
                }

                options.getPageAndSortOptions = function() {
                    return new Pageable(this.paginationOptions.pageNumber - 1,
                        this.paginationOptions.pageSize, this.paginationOptions.sort);
                }

                options.getArrayPagination = function(callback) {
                     refreshPagination(this, this.gridApiTable, this.maxPagesPagination, function (arrayPagination) {
                         callback(arrayPagination);
                     });
                }

                options.update = function(content, total) {
                    this.data = content;
                    this.totalItems = total;
                }

                options.changeColumns = function(columns) {
                    options.columnDefs = columns;
                }

                options.onRegisterApi = function(gridApi) {
                    options.gridApiTable = gridApi;
                    gridApi.grid.appScope.$gridApi = gridApi;

                    gridApi.core.on.sortChanged(gridApi.grid.appScope, function(grid, sortColumns) {
                        var sort = [];
                        for (var i = 0; i < sortColumns.length; i++) {
                            sort.push(new Sort(sortColumns[i].field, sortColumns[i].sort.direction));
                        }
                        grid.options.paginationOptions.sort = sort;
                        gridApi.grid.appScope[options.searchMethodName]();
                    });
                    gridApi.pagination.on.paginationChanged(gridApi.grid.appScope, function (newPage, pageSize) {
                        gridApi.grid.options.paginationOptions.pageNumber = newPage;
                        gridApi.grid.options.paginationOptions.pageSize = pageSize;
                        gridApi.grid.appScope[options.searchMethodName]();
                    });
                    gridApi.core.on.rowsRendered(gridApi.grid.appScope, function(gridApi) {
                        if (gridApi.initialized === undefined) {
                            gridApi.initialized = true;
                            var initialSort = gridApi.grid.options.$initialSort;
                            if (initialSort !== undefined) {
                                var column = gridApi.grid.getColumn(initialSort.columnName);
                                if (column != null) {
                                    gridApi.grid.sortColumn(column, initialSort.direction);
                                } else {
                                    gridApi.grid.appScope[options.searchMethodName]();
                                }
                            } else {
                                gridApi.grid.appScope[options.searchMethodName]();
                            }
                        }
                    });
                }

                options.getApiGridTable = function() {
                    return this.gridApiTable;
                };

                options.setPaginationPageSizes = function(sizes) {
                    options.paginationPageSizes = sizes;
                };

                return options;
            }
        }
    }

    function Sort(field, direction) {
        this.field = field;
        if ((direction == 'asc') || (direction == 'desc')) {
            this.direction = direction;
        } else {
            this.direction = 'asc';
        }
    }

    function Pageable(pageNumber, pageSize, sort) {
        if ((pageNumber !== undefined) && (pageNumber !== null)) {
            this.pageNumber = pageNumber;
        } else {
            this.pageNumber = 0;
        }

        if ((pageSize !== undefined) && (pageSize !== null)) {
            this.pageSize = pageSize;
        } else {
            this.pageSize = 10;
        }

        this.sort = sort;

        this.getQueryString = function() {
            var result = '?size=' + this.pageSize;
            result += '&page=' + this.pageNumber;
            if ((this.sort !== null) && (this.sort !== undefined)) {
                for (var i = 0; i < this.sort.length; i++) {
                    result += '&sort=' + this.sort[i].field + ',' + this.sort[i].direction;
                }
            }
            return result;
        }

        this.getPageAbleObj =  function() {
            var PageAbleObj = {
                size: this.pageSize,
                page: this.pageNumber,
                sort: []
            }

            if ((this.sort !== null) && (this.sort !== undefined)) {
                for (var i = 0; i < this.sort.length; i++) {
                    PageAbleObj.sort.push(this.sort[i].field + ',' + this.sort[i].direction);
                }
            }
            return PageAbleObj;
        }
    }

    function refreshPagination(gridTableOptions, gridTableApi, maxPages, callback) {
        var totalPages = gridTableApi.pagination.getTotalPages();
        var actualPage = gridTableApi.pagination.getPage();
        if (totalPages > maxPages) {
            calcPagination(gridTableApi, maxPages, function (qtdLeft, qtdRight) {
                var arrayPagination = createArrayPagination(actualPage, qtdLeft, qtdRight);
                callback(arrayPagination);
            });
        } else {
            callback(createArray(totalPages));
        }
    }

    function calcPagination(gridTableApi, maxPages, callback) {
        var totalPages = gridTableApi.pagination.getTotalPages();
        var actualPage = gridTableApi.pagination.getPage();
        var halfMaxPages = maxPages/2;
        var qtdLeft = 0, qtdRight = 0
        var calcPageLeft = actualPage - halfMaxPages;
        var calcPageRight = actualPage + halfMaxPages;
        if (actualPage > 1) {
                qtdLeft = (actualPage - halfMaxPages) >= 1 ? halfMaxPages : actualPage - 1;
                qtdLeft = qtdLeft === 0 ? 1 : qtdLeft;
            }
            if (actualPage < totalPages) {
                if (calcPageRight >= totalPages) {
                    qtdRight = totalPages - calcPageRight;
                    if (qtdRight <= 0) {
                        qtdRight = totalPages - actualPage;
                    }
                } else {
                    qtdRight = maxPages;
                }
                if (qtdLeft >= 1 && (qtdRight > qtdLeft)) {
                    qtdRight -= qtdLeft;
                }
            }
        qtdLeft = qtdLeft > qtdRight ? maxPages - qtdRight : qtdLeft;
        callback(qtdLeft, qtdRight);
    }

    function createArrayPagination(actualPage, qtdLeft, qtdRight) {
        var arrayPagination = [];
        if (qtdLeft > 0) {
            for (var x = actualPage - qtdLeft; x < actualPage; x++) {
                arrayPagination.push(x);
            }
        }
        arrayPagination.push(actualPage);
        if (qtdRight > 0) {
            for (var y = actualPage + 1; y < (actualPage + qtdRight + 1); y++) {
                arrayPagination.push(y);
            }
        }
        return arrayPagination;
    }


    function createArray(range, divisor) {
            if(!divisor)divisor = 0;
            if (divisor!=0) {
                range = range/divisor;
            }
            var values = [];
            var iteratorNumber = 0;
            for (var i = 0; i < range; i++) {
              iteratorNumber = divisor!=0 ? i*divisor : i+1;
              values.push(iteratorNumber);
            }
            return values;
    };

    function UiGridAction(name, methodName, icon, linkClasses, visibilityExpression, disabledExpression) {
        this.name = name;
        this.methodName = methodName;
        this.icon = icon;
        this.linkClasses = linkClasses;
        this.visibilityExpression = visibilityExpression;
        this.disabledExpression = disabledExpression;
    }


})();
