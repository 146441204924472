(function () {
    'use strict';

    angular.module('showcaseApp').factory('ChecklistService', ChecklistService);

    ChecklistService.$inject = ['ChecklistFactory', '$uibModal'];

    function ChecklistService(ChecklistFactory, $uibModal) {
        var service = {
            getActivitiesList: getActivitiesList,
            getActivitiesListByStatus: getActivitiesListByStatus,
            updateActivity: updateActivity

        };

        return service;


        function getActivitiesList() {
            return ChecklistFactory.getActivitiesList().$promise;
        }

        function getActivitiesListByStatus(status) {
            return ChecklistFactory.getActivitiesListByStatus({'status': status}).$promise;
        }

        function updateActivity(id, status) {
            return ChecklistFactory.updateActivity({'activityId': id}, {'status': status, 'sort': 'id'}).$promise;
        }


    }

})();
