/* globals $ */
(function() {
    'use strict';
    angular
        .module('showcaseApp')
        .component('messageContainer', {
            bindings: {
                currentCustomer: '<',
                currentConversation: '<',
                message: '<'
            },
            controller: MessageContainerController,
            templateUrl: 'app/admin/communication/directives/message-container/message-container.html',
            controllerAs: 'vm'
        });

    MessageContainerController.$inject = ['$scope', '$rootScope', '$translate', '$uibModal', '$window', 'WatiService'];

    function MessageContainerController($scope, $rootScope, $translate, $uibModal, $window, WatiService) {
        var vm = this;

        vm.getOrderAmount = function (orders) {
            return _.sumBy(orders, function (order) {
                return order.quantity * order.price;
            }).toFixed(2);
        }

        vm.getOrderQuantity = function (orders) {
            return orders.reduce(function (accumulator, order) {
                return accumulator + order.quantity;
            }, 0);
        }

        vm.downloadDocument = function (message) {
            if (message.downloadedData) {
                $window.open(message.downloadedData);
            } else {
                message.loadingDownload = true;
                WatiService.findFileByName({ fileName: message.data }).then(function (result) {
                    var blob = new Blob([result.data], { type: result.data.type });
                    var url = $window.URL.createObjectURL(blob);
                    message.downloadedData = url;
                    message.loadingDownload = false;
                });
            }
        }

        vm.getStoryLabel = function () {
            if (!vm.message.owner) {
                if (vm.message.type === 'story_reply') {
                    return 'communication.someone-story-reply';
                } else if (vm.message.type === 'story_mention') {
                    return 'communication.someone-story-mention';
                }
            } else if (vm.message.owner) {
                if (vm.message.type === 'story_reply') {
                    return 'communication.your-story-reply';
                } else if (vm.message.type === 'story_mention') {
                    return 'communication.your-story-mention';
                }
            }
        }

        vm.openCart = function () {
            vm.chatInvoiceNewModal = $uibModal.open({
                templateUrl: 'app/admin/invoices/chat/chat-invoice-new.html',
                controller: 'ChatInvoiceNewController',
                windowClass: 'modal-center',
                windowTopClass: 'center-modal',
                controllerAs: 'vm',
                size: 'lg',
                keyboard: false,
                backdrop: 'static',
                resolve: {
                    currentCustomer: function () {
                        return vm.currentCustomer
                    },
                    cartProducts: function () {
                        return vm.message.orderProducts.productItems
                    }
                },
            }).result.then(function (response) {
                $rootScope.$emit('$applyResponseFromCreatedOrder', response);
            });
        }
    }
})();
