(function() {
    'use strict';
    angular
        .module('showcaseApp')
        .factory('ChatConfigurationFactory', ChatConfigurationFactory);

    ChatConfigurationFactory.$inject = ['$resource'];

    function ChatConfigurationFactory($resource) {

        var CHAT_CONFIGURATION = 'admin/api/chat-configuration';
        var CHAT_CONFIGURATION_GET_CHAT_CONFIG = CHAT_CONFIGURATION + '/get-configuration';

        return $resource(CHAT_CONFIGURATION, {}, {
            'getChatConfiguration': {
                method: 'GET',
                url: CHAT_CONFIGURATION_GET_CHAT_CONFIG,
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    } else {
                        response.data = data;
                    }
                    response.headers = headers();
                    return response;
                }
            }
        });
    }

})();
