(function() {
    "use strict";

    angular
        .module("showcaseApp")
        .factory("AudioModalFactory", AudioModalFactory);


    /** @ngInject */
    function AudioModalFactory($uibModal)  {

        var factory = {
            confirmAction: confirmAction
        };

        return factory;

        function confirmAction() {
            return $uibModal.open({
                templateUrl: 'app/admin/communication/modal-audio/modal-audio.modal.html',
                controller: 'ModalAudioController',
                controllerAs: 'vm',
                size: 'md'
            }).result;
        }
    }
})();
