(function () {
    'use strict';
    angular
        .module('showcaseApp')
        .controller('SelectGMBConfigurationsController', SelectGMBConfigurationsController);

    function SelectGMBConfigurationsController(googleAccounts, $uibModalInstance, $localStorage, GMBService, toastr, $translate, moment) {

        var vm = this;

        vm.step = 0;

        // Pre data
        var MIN_SEARCH_TERM_NUMBER_CHAR = 4;

        vm.googleAccounts = googleAccounts;

        vm.processing = false;

        // Functions
        vm.closeModal = closeModal;

        vm.isToSelectCategory = isToSelectCategory;

        vm.selectAccount = selectAccount;

        vm.verifyConditions = verifyConditions;

        vm.nextStep = nextStep;

        vm.backStep = backStep;

        vm.selectLocation = selectLocation;

        vm.doBackwardAction = doBackwardAction;

        vm.createANewLocation = createANewLocation;

        vm.searchCategory = searchCategory;

        // Objects
        vm.selectedAccount = undefined;

        vm.selectedLocation = undefined;

        vm.selectedCategory = undefined;

        // Object Fetched
        vm.gmbLocations = undefined;

        vm.gmbCategories = undefined;

        function nextStep() {
            if (vm.step == 2) {
                // lastStep, justDoAction;
                doAction();
            } else if (verifyConditions(vm.step)) {
                // doAction before stepFoward
                doAction();
            }
        }

        function backStep() {
            vm.step--;
        }

        function isToSelectCategory() {
            return vm.selectedLocation == null;
        }

        function verifyConditions() {
            // Account's step
            if (vm.step == 0) {
                return !vm.processing && vm.selectedAccount != null;
            }
            // Location's step
            else if (vm.step == 1) {
                // Always true, because could be a new location
                return !vm.processing && true;
            }
            // Category's step
            else if (vm.step == 2) {
                return !vm.processing && (vm.selectedCategory != null && !vm.selectedLocation);
            }

            return false;

        }

        function selectAccount(account) {
            vm.selectedAccount = account;
        }

        function selectLocation(location) {
            vm.selectedLocation = location;
        }

        function closeModal(method) {
            console.log(method);
            $uibModalInstance.dismiss(method);
        }

        function closeModalSuccessfully(method){
            console.log(method);
            $uibModalInstance.close(method);
        }

        function doBackwardAction() {
            if (vm.step > 0) {
                backStep();
            } else {
                closeModal();
            }
        }

        function selectAccountErrorCallback(error) {
            toastr.error($translate.instant('integrations.googleMyBusiness.modalSelectConfigurations.accounts.messages.errorSelectAccount'));
        }

        function getLocationListSuccessCallback(response) {
            if (response.data) {
                vm.selectedLocation = undefined;
                vm.gmbLocations = response.data;
                if (vm.gmbLocations.length == 0){
                    vm.processing = false;
                    nextStep();
                }
            }
            vm.processing = false;
        }

        function getLocationListErrorCallback(error) {
            toastr.error($translate.instant('integrations.googleMyBusiness.modalSelectConfigurations.accounts.messages.errorGetLocation'));
        }

        function createANewLocation() {
            vm.selectedLocation = undefined;
        }

        function selectAccountSuccessCallback(response) {
            if (response.data = 'OK') {
                vm.processing = true;
                GMBService.getGMBLocationList({
                    storeId: ($localStorage.defaultStore ? ($localStorage.defaultStore.storeId || '') : '')
                }, getLocationListSuccessCallback, getLocationListErrorCallback);
            }
        }

        function selectGMBLocationErrorCallback(error) {
            vm.processing = false;
            closeModal(vm.selectedLocation ? 'errorSelectLocation' : 'errorCreateNewLocation');
        }

        function selectGMBLocationSuccessCallback(response) {
            vm.processing = false;
            closeModalSuccessfully(vm.selectedLocation ? 'successAssociated' : 'successCreated');
        }

        function searchCategory() {
            var promise = GMBService.getGMBCategoriesList({
                storeId: ($localStorage.defaultStore ? ($localStorage.defaultStore.storeId || '') : ''),
                languageCode: "pt", //moment.locale() + "",
                searchTerm: vm.searchTerm,
                regionCode: (moment.locale() == "pt-br" ? "BR" : (moment.locale() == "es" ? "ES" : "US"))
            });

            return promise.then(function (response) {
                return response.data;
            })
        }

        function doAction() {
            if (vm.step == 0 && vm.selectedAccount) {
                vm.processing = true;
                GMBService.selectAccount({
                    storeId: ($localStorage.defaultStore ? ($localStorage.defaultStore.storeId || '') : '')
                }, {
                    googleAccountTarget: vm.selectedAccount.name
                }, selectAccountSuccessCallback, selectAccountErrorCallback);

                vm.step++;
            } else if (vm.step == 1) {
                vm.processing = true;
                // If selected a location, there's no need to select category
                if (vm.selectedLocation) {
                    GMBService.selectGMBLocation({
                        storeId: ($localStorage.defaultStore ? ($localStorage.defaultStore.storeId || '') : '')
                    }, {
                        googleLocationTarget: vm.selectedLocation.name
                    }, selectGMBLocationSuccessCallback, selectGMBLocationErrorCallback);
                } else {
                    vm.step++;
                    vm.processing = false;
                }



            } else if (vm.step == 2) {
                vm.processing = true;
                if (vm.selectedCategory && !vm.selectedLocation) {
                    // If a new location option has selected, then we just need to send the category as parameter
                    GMBService.selectGMBLocation({
                        storeId: ($localStorage.defaultStore ? ($localStorage.defaultStore.storeId || '') : '')
                    }, {
                        selectedCategory: vm.selectedCategory
                    }, selectGMBLocationSuccessCallback, selectGMBLocationErrorCallback);
                }


            }
        }

        function preSelects(){
            if (vm.googleAccounts.length == 1){
                vm.selectedAccount = vm.googleAccounts[0];
                vm.nextStep();
            }
        }

        preSelects();

    }
})();
