(function() {
    'use strict';
    angular
        .module('showcaseApp')
        .service('CommunicationService', CommunicationService);

    function CommunicationService($q) {
        var service = {
            sendMessageWhatsapp: sendMessageWhatsapp,
            getOldMessagesWhatsapp: getOldMessagesWhatsapp,
            getAudioRecorder: getAudioRecorder
        },

        listener = $q.defer(), socket = {
            client: null,
            stomp: null
        }, messageIds = [];

        service.RECONNECT_TIMEOUT = 30000;


        service.configureWS = function() {
            this.stompClient = Stomp.over(
                SockJS("https://localhost:8080/chat")
              );

              // tslint:disable-next-line:only-arrow-functions
              this.stompClient.connect({}, function () {
                //this.configureWsMessenger();
                //this.configureWsInstagram();
                configureWsWati();
              });
        };

        function sendMessageWhatsapp() {
        }

        function getOldMessagesWhatsapp() {
        }

        /**
         * Configures the subscriber Wati.
         */
         function configureWsWati() {
            this.stompClient.subscribe(
            "topic/new-message-wati",
            function (message) {
                var body = this.makeBody(message);
                if (!body.text) return;
                if (!Object.keys(this.conversations).includes(body.waId)) {
                this.currentUser = body.waId;
                this.conversations[this.currentUser] = {
                    messages: [],
                    source: body.source,
                    id: body.waId,
                };
                }
                this.currentUser = body.waId;
                this.conversations[this.currentUser].messages.push({
                isSource: false,
                text: body.text,
                type: MESSAGE.TYPES.TEXT,
                });
                this.scrollToBottom();
            }
            );
        }

        return service;

    }
})();
