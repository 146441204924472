(function() {
    'use strict';
    angular
        .module('showcaseApp')
        .service('ImageManagerService', ImageManagerService);

    function ImageManagerService() {

        var service = {
            handleImageSize: handleImageSize
        };

        return service;

        function handleImageSize(img, file) {
            var canvas = document.createElement("canvas");
            var MAX_WIDTH = img.width * 0.6;
            var MAX_HEIGHT = img.height * 0.6;

            canvas.width = Math.floor(MAX_WIDTH);
            canvas.height = Math.floor(MAX_HEIGHT);
            var ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0, MAX_WIDTH, MAX_HEIGHT);

            return canvas.toDataURL(file.type);
        };
    }

})();
