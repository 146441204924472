(function () {
    'use strict';

    angular
        .module('showcaseApp')
        .controller('StoreFileManagerController', StoreFileManagerController);

    function StoreFileManagerController(FileManagerService, $scope, $localStorage, $translate, toastr) {

        var vm = this;

        vm.hubId = $localStorage.defaultStore.hubId;
        vm.currentFolder = {id: -1};
        vm.children = [];
        vm.files = [];
        vm.tags = [];
        vm.breadcrumbs = [];
        vm.filterName = "";
        vm.filterTags = [];
        vm.loadingChildren = true;
        vm.loadingFiles = true;
        vm.loadingBreadcrumb = false;

        getFolderChildren(vm.currentFolder);
        getFolderFiles(vm.currentFolder);
        getFileTags();

        vm.enterInFolder = function (folder) {
            vm.filtring = false;
            vm.currentFolder = folder;
            clearFilter();
            getFolderChildren(vm.currentFolder);
            getFolderFiles(vm.currentFolder);
            if (vm.currentFolder.id === -1) {
                vm.breadcrumbs = [{id: -1, name: $translate.instant("file-manager.folder.home")}];
                vm.loadingBreadcrumb = false;
            } else {
                vm.loadingBreadcrumb = true;
                vm.breadcrumbs = mountBreadCrumb(folder, [{id: -1, name: $translate.instant("file-manager.folder.home")}]);
                setTimeout(function () {
                    vm.loadingBreadcrumb = false;
                    $scope.$apply();
                }, 650);
            }
        }

        vm.getFileType = function (type) {
            type = type.split('/')[0];
            switch (type){
                case 'image':
                    return 'IMAGE'
                case 'video':
                    return 'VIDEO';
                default:
                    return 'OTHER';
            }
        }

        vm.getFileName = function (originalName) {
            var extension = "." + originalName.substring(originalName.lastIndexOf(".") + 1);
            return originalName.substring(0, 14) + '(...)' + extension;
        }

        vm.filterByName = function () {
            filterFiles();
        }

        vm.filterByTag = function (tag) {
            var index = _.findIndex(vm.filterTags,function (t) { return t  === tag.id })
            if (index >= 0) {
                tag.selected = false;
                vm.filterTags.splice(index, 1);
            } else {
                tag.selected = true;
                vm.filterTags.push(tag.id)
            }
            filterFiles();
        }

        function getFolderChildren(folder) {
            vm.loadingChildren = true;
            FileManagerService.getFolderChildren(vm.hubId, folder.id)
                .then(function (result) {
                    vm.children = result.data;
                    vm.loadingChildren = false;
                }, function () {
                    toastr.error($translate.instant("file-manager.message.error-to-load-folder-children"));
                    vm.loadingChildren = false;
                });
        }

        function getFolderFiles(folder) {
            vm.loadingFiles = true;
            FileManagerService.getFolderFiles(vm.hubId, folder.id)
                .then(function (result) {
                    vm.files = result.data;
                    vm.loadingFiles = false;
                }, function () {
                    toastr.error($translate.instant("file-manager.message.error-to-load-folder-files"));
                    vm.loadingFiles = false;
                });
        }

        function getFileTags() {
            vm.loadingTags = true;
            FileManagerService.getFileTags(vm.hubId)
                .then(function (result) {
                    vm.tags = result.data;
                    vm.loadingTags = false;
                }, function () {
                    toastr.error($translate.instant("file-manager.message.error-to-load-tags"));
                    vm.loadingTags = false;
                });
        }

        function mountBreadCrumb(folder, breadcrumbs) {
            if (folder.id === -1) {
                return [];
            }

            if (folder.parent) {
                mountBreadCrumb(folder.parent, breadcrumbs)
            }

            breadcrumbs.push({id: folder.id, name: folder.name, parent: folder.parent})
            return breadcrumbs;
        }

        function filterFiles() {
            if (!vm.filterName && !vm.filterTags.length) {
                getFolderFiles(vm.currentFolder);
                vm.filtring = false;
                return;
            }

            vm.filtring = true;

            vm.loadingFiles = true;
            FileManagerService.filterFiles(vm.hubId, vm.filterName, vm.filterTags)
                .then(function (result) {
                    vm.files = result.data;
                }, function () {
                    toastr.error($translate.instant("file-manager.message.error-to-load-files"));
                }).finally(function () { vm.loadingFiles = false});
        }

        function clearFilter () {
            vm.filterName = "";
            vm.filterTags = [];
            angular.forEach(vm.tags, function (tag) {
                tag.selected = false;
            })
        }

    }
})();
