(function () {
    'use strict';

    angular
        .module('showcaseApp')
        .factory('stateHandler', stateHandler);

    function stateHandler($rootScope, $state, $translate, JhiLanguageService, translationHandler, $window,
        Auth, Principal, VERSION, formStepsStates, ADMIN_SYSTEM_ID, $localStorage, AuthServerProvider) {
        return {
            initialize: initialize
        };

        function initialize() {
            $rootScope.VERSION = VERSION;
            $rootScope.ADMIN_SYSTEM_ID = ADMIN_SYSTEM_ID;


            // var statesLocked = ['customers', 'social-medias', 'consumers', 'engagements', 'poster-create-new', 'store-rating', 'social-integrations'];
            // var statesLockedPlanStarter = ['customers', 'social-medias', 'bag', 'poster-create-new', 'tabTacebookBusiness' ];
            // var statesLockedPlanFree = ['consumers', 'engagements', 'store-rating', 'store-performance','store-file-manager', 'customers', 'social-medias', 'social-integrations','payment-settings', 'admin-reports', 'bag', 'poster-create-new' ];

            const Plan = {
                ESSENTIAL: {
                    planId: 10,
                    lockedFeatures: ['checklist', 'notifications', 'communication', 'customers', 'consumers', 'bag', 'erps-settings', 'marketing-settings']
                },
                SHOWKASE: {
                    planId: 11,
                    lockedFeatures: ['checklist', 'notifications', 'communication', 'customers', 'consumers', 'marketing-settings']
                },
                IMPULSE: {
                    planId: 12,
                    lockedFeatures: ['checklist', 'notifications', 'communication', 'marketing-settings']
                },
                SCALA: {
                    planId: 13,
                    lockedFeatures: []
                }
            };

            var stateChangeStart = $rootScope.$on('$stateChangeStart', function (event, toState, toStateParams, fromState) {
                $rootScope.toState = toState;
                $rootScope.toStateParams = toStateParams;
                $rootScope.fromState = fromState;

                var loggedAccount;

                var openRouteList = [
                    'direct-register',
                    'main-register',
                    'requestReset',
                    'finishReset',
                    'activate'
                ];

                var copyAccount = function (account) {
                    if (account) {
                        return {
                            activated: account.activated,
                            hasMoipPlan: account.hasMoipPlan,
                            statusPaymentSignature: account.statusPaymentSignature,
                            isExpired: account.isExpired,
                            isFree: account.isFree,
                            planId: account.planId,
                            remainingTime: account.remainingTime
                        };
                    }

                    return null;
                };

                function getLoggedAccount(callback) {
                    Principal.identity().then(function (account) {
                        loggedAccount = copyAccount(account);
                        callback();
                    }, function (err) {
                        console.log(err);
                        callback();
                    });
                }

                function verifyIfIsExpiredAndGoToCheckoutPage() {
                    Principal.identity().then(function (account) {
                        loggedAccount = copyAccount(account);
                        if (loggedAccount) {
                            if (loggedAccount.isExpired) {
                                if (!loggedAccount.isFree) {
                                    event.preventDefault();
                                    $state.go('checkoutInner');
                                }
                            }
                        }
                    });
                }

				function verifyForceLogout(){
					Principal.forceLogout().then(function (response) {
						if (response.data){
				            Auth.logout();
				            $state.go('main-login');
						}
	                });
				}

                if (toState.name != 'main-login' && !openRouteList.includes(toState.name) && !$localStorage.authenticationToken) {
                    event.preventDefault();
                    $state.go('main-login');
                }

                if (toState.name != 'main-login' && toState.name != 'checkoutInner' && toState.name != 'checkout-result') {
					verifyForceLogout();
                    verifyIfIsExpiredAndGoToCheckoutPage();
                }

                if ($localStorage.authenticationToken && toState.name === 'main-login') {
                    Principal.identity().then(function (account) {
                        loggedAccount = copyAccount(account);
                        console.log(account);
                        if (loggedAccount && loggedAccount.isExpired && !loggedAccount.isFree) {
                            event.preventDefault();
                            $state.go('checkoutInner');
                        } else {
                            event.preventDefault();
                            $state.go('home');
                        }
                    }, function (err) {
                        console.log(err);
                    });

                }

                function isTrial(account) {
                    return !isNullOrUndefined(account) && !isNullOrUndefined(account.remainingTime)
                        && account.remainingTime > 0 && account.remainingTime <= 7;
                }

                function isFeatureLockedByPlan() {
                    if (isNullOrUndefined(loggedAccount) || isNullOrUndefined(loggedAccount["planId"])) {
                        return !isTrial(loggedAccount);
                    }

                    var isFeatureLocked = true;

                    switch (loggedAccount.planId) {
                        case Plan.ESSENTIAL.planId:
                            isFeatureLocked = isFeatureLocked && Plan.ESSENTIAL.lockedFeatures.indexOf(toState.name) > -1
                            break;
                        case Plan.SHOWKASE.planId:
                            isFeatureLocked = isFeatureLocked && Plan.SHOWKASE.lockedFeatures.indexOf(toState.name) > -1
                            break;
                        case Plan.IMPULSE.planId:
                            isFeatureLocked = isFeatureLocked && Plan.IMPULSE.lockedFeatures.indexOf(toState.name) > -1
                            break;
                        case Plan.SCALA.planId:
                            isFeatureLocked = isFeatureLocked && Plan.SCALA.lockedFeatures.indexOf(toState.name) > -1
                            break;
                        default:
                            isFeatureLocked = true;
                    }

                    return isFeatureLocked && !isTrial(loggedAccount);
                }

                function processAllStateData() {
                    $localStorage.isLocked = isFeatureLockedByPlan();

					// if ($localStorage.isMoipPlanFree){
					// 	if (loggedAccount
	                //         && statesLockedPlanFree.indexOf(toState.name) > -1
	                //         && $localStorage.defaultStore.storeId !== ADMIN_SYSTEM_ID) {
	                //         $localStorage.isLocked = true;
	                //     } else {
	                //         $localStorage.isLocked = false;
	                //     }
					// }else if ($localStorage.isMoipPlanStarter){
					// 	if (loggedAccount
	                //         && statesLockedPlanStarter.indexOf(toState.name) > -1
	                //         && $localStorage.defaultStore.storeId !== ADMIN_SYSTEM_ID) {
	                //         $localStorage.isLocked = true;
	                //     } else {
	                //         $localStorage.isLocked = false;
	                //     }
					// }else{
	                //     if (loggedAccount
	                //         && statesLocked.indexOf(toState.name) > -1
	                //         && $localStorage.defaultStore.storeId !== ADMIN_SYSTEM_ID
	                //         && loggedAccount.isFree) {
	                //         $localStorage.isLocked = true;
	                //     } else {
	                //         $localStorage.isLocked = false;
	                //     }
					// }
                    if (loggedAccount && !loggedAccount.isExpired) {
                        if (toState.name === 'main-login') {
                            if (AuthServerProvider.getToken()) {
                                event.preventDefault();
                                $state.go('home');
                            }
                        }

                        if (toState.name === 'store-info' || toState.parent === "form") {
                            if ((AuthServerProvider.getToken()) && $localStorage.defaultStore.storeId !== null && $localStorage.defaultStore.storeId !== ADMIN_SYSTEM_ID) {
                                event.preventDefault();
                            }
                        }
                        if ((toState.name != 'checkoutInner' ||
                                toState.name != 'checkout-result' ||
                                toState.name !== 'main-login' ||
                                toState.name !== 'direct-register' ||
                                toState.name !== 'main-register' ||
                                toState !== 'requestReset' ||
                                toState !== 'finishReset' ||
                                toState.name !== 'activate') && toState.name !== 'store-info' && toState.parent !== "form") {
                            if (AuthServerProvider.getToken()) {
                                if ($localStorage.defaultStore.storeId == null && $localStorage.defaultStore.storeId !== ADMIN_SYSTEM_ID) {
                                    event.preventDefault();
                                    $state.go('store-info');
                                }
                            }
                        }

                        // Redirect to a state with an external URL (http://stackoverflow.com/a/30221248/1098564)
                        if (toState.external) {
                            event.preventDefault();
                            $window.open(toState.url, '_self');
                        }

                        if (Principal.isIdentityResolved()) {
                            Auth.authorize();
                        }

                        if (toState.parent === "form") {
                            if ((fromState.name === "" || fromState.name == null) && toState.name !== "store-info") {
                                event.preventDefault();
                                $state.go('store-info');
                            }

                            var canGoToStep = false;
                            // only go to next if previous is valid
                            var toStateIndex = _.findIndex(formStepsStates, function (formStepState) {
                                return formStepState.uiSref === toState.name;
                            });

                            if (toStateIndex === 0) {
                                canGoToStep = true;
                            } else {
                                canGoToStep = formStepsStates[toStateIndex - 1].valid;
                            }

                            // Stop state changing if the previous state is invalid
                            if (!canGoToStep) {
                                // Abort going to step
                                event.preventDefault();
                            }
                        }
                    }
                }

                getLoggedAccount(processAllStateData);

                // Update the language
                JhiLanguageService.getCurrent().then(function (language) {
                    $translate.use(language);
                });
            });

            var stateChangeSuccess = $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
                var titleKey = 'global.title';

                // Set the page title key to the one configured in state or use default one
                if (toState.data.pageTitle) {
                    titleKey = toState.data.pageTitle;
                }
                translationHandler.updateTitle(titleKey);
            });

            $rootScope.$on('$destroy', function () {
                if (angular.isDefined(stateChangeStart) && stateChangeStart !== null) {
                    stateChangeStart();
                }
                if (angular.isDefined(stateChangeSuccess) && stateChangeSuccess !== null) {
                    stateChangeSuccess();
                }
            });
        }
    }
})();
