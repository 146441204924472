(function() {
    'use strict';

    angular
        .module('showcaseApp')
        .controller('HubFlyersDetailController', HubFlyersDetailController);

    HubFlyersDetailController.$inject = ['$state', '$scope', '$filter', '$timeout', 'uiGmapGoogleMapApi', 'FlyersCurrencyValue', '$translate', 'FlyerCategoryService', 'StoreService', 'HubFlyersService', '$localStorage', '$q', '$stateParams', 'toastr', '$uibModal' , '$rootScope'];

    function HubFlyersDetailController($state, $scope, $filter, $timeout, uiGmapGoogleMapApi, FlyersCurrencyValue, $translate, FlyerCategoryService, StoreService, HubFlyersService, $localStorage, $q, $stateParams, toastr, $uibModal, $rootScope) {

        var vm = this;

        vm.loadingImage = false;
        vm.mainMap = {
            center: {
                latitude: 0,
                longitude: 0
            },
            zoom: 8,
            controls: {},
            bounds: {}
        };
        vm.options= {
            fullscreenControl: false
        };
        vm.allSelected = false;
        vm.isLoading = true;
        vm.showImage = true;
        vm.mainMap.markers = [];
        vm.showMap = false;
        vm.mainMap.window = {
            model: {},
            show: false,
            options: {
                pixelOffset: {
                    width: -1,
                    height: -65
                }
            },
            closeClick: function() {
                this.show = false;
            }
        }

        uiGmapGoogleMapApi.then(function() {
            loadFlyer();
        });

        vm.openDescriptionModal = openDescriptionModal;

        vm.mainMap.markerEvents = {
            dragend: function(marker, eventName, model) {
                vm.refreshMapWithCoordinates(marker.position.lat(), marker.position.lng());
            },
            mouseover: function(marker, eventName, model, args) {
                vm.mainMap.window.model = model;
                vm.mainMap.window.show = true;
            },
            mouseout: function(marker, eventName, model, args) {
                vm.mainMap.window.show = false;
            },
            click: function(marker, eventName, model, args) {
                if (!isOnlyOneSelected(model)) {
                  for (var i = 0; i < vm.mainMap.markers.length; i++) {
                      if (vm.mainMap.markers[i].id == marker.key) {
                          if (vm.mainMap.markers[i].clicked) {
                              vm.mainMap.markers[i].options.icon = ICON_INACTIVE;
                              vm.mainMap.markers[i].clicked = false;
                              updateFlyer();
                          } else {
                              vm.mainMap.markers[i].options.icon = ICON_ACTIVE;
                              vm.mainMap.markers[i].clicked = true;
                              updateFlyer();
                          }
                      }
                  }
                //   checkAllMarkersIsSelected();
                } else {
                  toastr.error($translate.instant('flyers.detail.error.atLeastOneSelected'));
                }
            }
        };

        vm.firstZoom = true;

        $scope.$watch('vm.mainMap.zoom', function(newVal) {
            if (newVal > 16 && vm.firstZoom) {
                vm.mainMap.zoom = 16;
                vm.firstZoom = false;
            }
        });

        vm.getEditFlyerInfo = function() {
            $uibModal.open({
                templateUrl: 'app/admin/hub-management/flyers/flyers-edit-info.html',
                controller: 'HubFlyersEditInfoController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    flyer: function() {
                        return angular.copy(vm.flyer);
                    }
                }
            }).result.then(function(response) {
                if (response) {
                    vm.flyer = response.data;
                    // It's not needed to load Image again, since that only has changed info
                    // $timeout(function() {
                    //     vm.imagePath = $filter('randomSrc')("/flyer/api/" + vm.flyer.id + "/picture?type=large");
                    // }, 1000);
                    refreshCategory();
                }
            });
        }


        vm.getEditFlyer = function() {
            $state.go('hub-flyers.edit', {
                id: vm.flyer.id
            });

            // $uibModal.open({
            //     templateUrl: 'app/admin/hub-management/flyers/flyers-edit.html',
            //     controller: 'HubFlyersEditController',
            //     controllerAs: 'vm',
            //     backdrop: 'static',
            //     size: 'lg',
            //     resolve: {
            //         flyer: function() {
            //             return angular.copy(vm.flyer);
            //         }
            //     }
            // }).result.then(function(response) {
            //     if (response) {
            //         vm.flyer = response.data;
            //         // It's not needed to load Image again, since that only has changed info
            //         // $timeout(function() {
            //         //     vm.imagePath = $filter('randomSrc')("/flyer/api/" + vm.flyer.id + "/picture?type=large");
            //         // }, 1000);
            //         refreshCategory();
            //     }
            // });
        }

        vm.getEditFlyerImage = function() {
            $uibModal.open({
                templateUrl: 'app/admin/hub-management/flyers/flyers-edit-image.html',
                controller: 'HubFlyersEditImageController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    flyer: function() {
                        return angular.copy(vm.flyer);
                    },
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('flyers');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function(response) {
                if (response) {
                    vm.loadingImage = true;
                    vm.flyer = response.data;
                    $timeout(function() {
                        vm.imagePath = $filter('randomSrc')("/flyer/api/" + vm.flyer.id + "/hub-picture?type=large&t=" + new Date().getTime());
                        vm.loadingImage = false;
                    }, 1000);
                    refreshCategory();
                }
            });
        }

        const ICON_ACTIVE = 'https://showkase-prod.s3-sa-east-1.amazonaws.com/hub/system-images/'+$rootScope.urlImage+'/pointactive.png';
        const ICON_INACTIVE = 'content/images/pointinactive.png';

        function isOnlyOneSelected(model){
            var count = 0;
            var modelIsSelected = false;
            for (var i = 0; i < vm.mainMap.markers.length; i++) {
                vm.mainMap.markers[i].clicked ? count++ : null;
                if (vm.mainMap.markers[i].id == model.id){
                    modelIsSelected =  vm.mainMap.markers[i].clicked;
                }
            }

            return (count == 1 && modelIsSelected);

        }

        vm.checkAllMarkers = function() {
            vm.allSelected = !vm.allSelected;
            var icon;
            if (vm.allSelected) {
                icon = ICON_ACTIVE;
            } else {
                icon = ICON_INACTIVE;
            }
            // The index need to begin with 1 because has to left unless one salespoint selected
            if (vm.allSelected){
                var beginsWith = 0;
            }
            else {
                var beginsWith = 1;
            }
            for (var i = beginsWith; i < vm.mainMap.markers.length; i++) {
                vm.mainMap.markers[i].options.icon = icon;
                vm.mainMap.markers[i].clicked = vm.allSelected;
            }
            updateFlyer();
        }

        vm.getFlyerCurrency = function(currency) {
            return FlyersCurrencyValue[currency];
        }


        vm.getFlyerTooltip = function (status) {
            switch (status) {
                case 'ACTIVATED':
                    return 'flyers.offer.active';
                case 'DEACTIVATED':
                    return 'flyers.offer.disabled';
                case 'EXPIRED':
                    return 'flyers.offer.expired';
                case 'CLOSED':
                    return 'flyers.offer.closed';
            }
        }

        function loadFlyer() {
            HubFlyersService.getFlyer($stateParams.id).then(function(response) {
                vm.flyer = response.data;
                vm.imagePath = $filter('randomSrc')("/flyer/api/" + vm.flyer.id + "/hub-picture?type=large");
                refreshCategory();
                StoreService.getSalesPoints($localStorage.defaultStore.storeId).then(function(response) {
                    convertToMarkers(response.data);
                    // checkAllMarkersIsSelected();
                });
            }, function (err) {
                if (err.status == 404){
                    toastr.error($translate.instant('flyers.messages.load.notFound'));
                    $state.go('flyers');

                }
            });
        }

        function checkAllMarkersIsSelected() {
            if (vm.flyer.salesPoint.length === vm.mainMap.markers.length) {
                vm.allMarkersChecked = true;
                vm.allSelected = true;
            } else {
                vm.allMarkersChecked = false;
                vm.allSelected = false;
            }
        }

        function openDescriptionModal(){
            $uibModal.open({
                templateUrl: 'app/admin/flyers/flyers-description-modal.html',
                controller: function(flyerDescription, $uibModalInstance){
                    var vm = this;
                    vm.flyerDescription = flyerDescription;

                    vm.closeModal = function(){
                        $uibModalInstance.dismiss('cancel');
                    }

                    vm.ok = function(){
                        $uibModalInstance.close();
                    }
                },
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    flyerDescription: function() {
                        return angular.copy(vm.flyer.description);
                    }
                }
            }).result
        }

        function convertToMarkers(points) {
            points.forEach(function(point) {
                vm.mainMap.markers.push({
                    pointlocation: {
                        name: point.name,
                        country: point.country,
                        state: point.state,
                        neighborhood: point.neighborhood,
                        number: point.number,
                        address: point.address,
                        city: point.city,
                        id: point.id,
                        postalCode: point.postalCode,
                        contactPhone: point.contactPhone,
                        storeId: vm.store,
                        salesPointId: point.id
                    },
                    latitude: point.coordinate.latitude,
                    longitude: point.coordinate.longitude,
                    id: point.id,
                    clicked: false,
                    options: {
                        icon: ICON_INACTIVE
                    }
                });
            });
            $q.all(vm.mainMap.markers).then(function(markers) {
                // updateFlyerMarkers();
                $timeout(function() {
                    vm.showMap = false;
                    vm.isLoading = false;
                    $timeout(function () {
                        vm.showMap = true;
                    }, 100);
                }, 50);
            });
        }

        function updateFlyerMarkers() {
            vm.flyer.salesPoint.forEach(function(salesPoint) {
                for (var i = 0; i < vm.mainMap.markers.length; i++) {
                    if (salesPoint.salesPointId == vm.mainMap.markers[i].pointlocation.salesPointId) {
                        vm.mainMap.markers[i].clicked = true;
                        vm.mainMap.markers[i].options.icon = ICON_ACTIVE;
                    }
                }
            });
        }

        function updateFlyer() {
            vm.flyer.salesPoint = [];
            vm.mainMap.markers.forEach(function(point) {
                if (point.clicked) {
                    var newPoint = point.pointlocation;
                    newPoint.storeId = $localStorage.defaultStore.storeId;
                    newPoint.location = {
                        type: "POINT",
                        "x": point.latitude,
                        "y": point.longitude,
                        coordinates: [point.latitude, point.longitude]
                    };
                    vm.flyer.salesPoint.push(newPoint);
                }
            });
            $q.all(vm.flyer.salesPoint).then(function(data) {
                HubFlyersService.updateFlyer(vm.flyer).then(function(response) {
                    vm.flyer = response.data;
                    toastr.success($translate.instant('flyers.messages.update.success'));
                    // updateFlyerMarkers();
                    // checkAllMarkersIsSelected();
                    refreshCategory();
                }, function (error) {
                    var header = error.headers();
                    toastr.error($translate.instant(header['x-flyerapp-error']));
                });
            });
        }

        function refreshCategory() {
            FlyerCategoryService.getCategoryTitleByKey(vm.flyer.categoryKey, $translate.use()).then(function(response) {
                vm.flyer.category = response.data;
            });
        }
    }
})();
