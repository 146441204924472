/* globals $ */
(function() {
	'use strict';

	angular.module("showcaseApp").directive("ngUploadChange",function(){
        return{
            scope:{
                ngUploadChange:"&"
            },
            link:function($scope, $element, $attrs){
                $element.on("change", function(event){
                    $scope.$apply(function(){
                        $scope.ngUploadChange({$event: event})

                        var list = new DataTransfer();
                        var myFileList = list.files;

                        event.target.files = myFileList;
                    })
                })

                $scope.$on("$destroy",function(event){
                    $element.files = [];
                    $element.off();
                });
            }
        }
    });
})();
