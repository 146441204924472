(function() {
    'use strict';
    angular
        .module('showcaseApp')
        .factory('PaymentDiscountFactory', PaymentDiscountFactory);

        PaymentDiscountFactory.$inject = ['$resource'];

    function PaymentDiscountFactory($resource) {
        var resourceUrl = 'admin/api/payment-condition-discount';

        return $resource(resourceUrl, {}, {
            'getById': {
                method: 'GET',
                url: resourceUrl + '/:id',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'getByStoreId': {
                method: 'GET',
                url: resourceUrl + '/store/:storeId',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'create': {
                method: 'POST',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'update': {
                method: 'PUT',
                url: resourceUrl + '/:id',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'remove': {
                method: 'DELETE',
                url: resourceUrl + '/:id',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            }
        });
    }

})();
