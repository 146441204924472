(function () {
    'use strict';

    angular
        .module('showcaseApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('first', {
                url: '/first',
                abstract: true,
                templateUrl: "app/store/components/common/content.html",
                data: {
                    authorities: ['STORE_CREATE'],
                    specialClass: "md-skin fixed-nav fixed-sidebar",
                    mainFlexClass: "app-wrap"
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                    }]
                }
            })

            .state('form', {
                parent: 'first',
                abstract: true,
                views: {
                    'content@first': {
                        templateUrl: 'app/store/components/content-form.html',
                        controller: 'FormFirstStoreController',
                        controllerAs: 'vm'
                    }
                },
                data: {
                    authorities: ['STORE_CREATE'],
                    pageTitle: 'store-info.pageTitle'
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('store-management');
                        return $translate.refresh();
                    }]

                }
            })

            .state('store-info', {
                parent: 'form',
                url: '/store-info',
                data: {
                    pageTitle: 'first.pageTitle'
                },
                views: {
                    'form-info@form': {
                        templateUrl: 'app/store/form.store.info.html'
                    },
                    'form-views@form': {
                        templateUrl: 'app/store/form.store.info.view.html',
                        controller: 'FormStoreInfoController',
                        controllerAs: 'vm'
                    }
                }
            })

            .state('store-point', {
                parent: 'form',
                url: '/store-point',
                data: {
                    pageTitle: 'store-point.pageTitle'
                },
                views: {
                    'form-info@form': {
                        templateUrl: 'app/store/form.store.point.info.html'
                    },
                    'form-views@form': {
                        templateUrl: 'app/store/form.store.point.view.html',
                        controller: 'FormStorePointController',
                        controllerAs: 'vm'
                    }
                }
            });
    }
})();
