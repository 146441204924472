(function() {
    'use strict';

    angular
        .module('showcaseApp')
        .controller('BannersModalController', BannersModalController);

    BannersModalController.$inject = ['$uibModalInstance', '$translate', '$localStorage', 'toastr', 'banners'];

    var BANNER_LINK_MAX_SIZE = 1000;

    function BannersModalController($uibModalInstance, $translate, $localStorage, toastr, banners) {

        var vm = this;
        vm.banners = angular.copy(banners);

        vm.DEFAULT_IMAGE = 'content/images/1-image-default-banner.png';

        function loadAll() {
            if (vm.banners.length === 0) {
                vm.addNewBanner();
            } else {
                vm.banners.forEach(function (banner) {
                    var srcImage = banner.image;
                    if (srcImage.split(',')[0].indexOf('base64') == -1) {
                        banner.image = banner.image + '?dummy=' + new Date().getTime();
                    }

                    if (banner.imageMobile && banner.imageMobile.split(',')[0].indexOf('base64') == -1) {
                        banner.imageMobile = banner.imageMobile + '?dummy=' + new Date().getTime();
                    } else if (!banner.imageMobile){
                        banner.imageMobile = vm.DEFAULT_IMAGE;
                    }
                });
            }
        }

        vm.addNewBanner = function () {
            vm.banners.push({
                'id': vm.banners.length + 1,
                'image': vm.DEFAULT_IMAGE,
                'imageMobile': vm.DEFAULT_IMAGE,
                'name': '',
                'link': ''
            });
            waitForElementToDisplay(vm.banners.length,function(){document.getElementById(vm.banners.length).scrollIntoView();},100,9000);
        }

        function waitForElementToDisplay(selector, callback, checkFrequencyInMs, timeoutInMs) {
            var startTimeInMs = Date.now();
            (function loopSearch() {
                if (document.getElementById(selector) != null) {
                    callback();
                    return;
                }
                else {
                    setTimeout(function () {
                        if (timeoutInMs && Date.now() - startTimeInMs > timeoutInMs)
                            return;
                        loopSearch();
                    }, checkFrequencyInMs);
                }
            })();
        }

        vm.removeBanner = function (index) {
            vm.banners.splice(index, 1);
            if (vm.banners.length === 0) {
                vm.addNewBanner();
            }
        }

        vm.closeModal = function(response) {
            $uibModalInstance.close(response);
        }

        vm.closeDropDown = function () {
            $("md-backdrop").trigger('click');
        }

        vm.applyBanners = function () {
            var error = false;
            vm.banners.forEach(function(banner, i) {
                if (banner.image.indexOf(vm.DEFAULT_IMAGE) != -1) {
                    toastr.error($translate.instant('showcases.detail.banner.error.bannerWithoutImage'));
                    error = true;
                } else if (banner.link && banner.link.length > BANNER_LINK_MAX_SIZE){
                    toastr.error($translate.instant('showcases.detail.banner.error.bannerLinkExceedingSizeLimit'));
                    error = true;
                } else if (banner.image.includes("?dummy=")) {
                    banner.image = banner.image.split("?dummy=")[0];
                }

                if (banner.imageMobile.indexOf(vm.DEFAULT_IMAGE) != -1) {
                    banner.imageMobile = null;
                } else if (banner.imageMobile.includes("?dummy=")) {
                    banner.imageMobile = banner.imageMobile.split("?dummy=")[0];
                } else if (banner.imageMobile.includes('base64')) {
                    banner.coverMobile = null;
                }
            });

            if (!error) {
                vm.closeModal({banners: vm.banners});
            }
        }

        vm.selectImg = function(index) {
            vm.index = index;
            vm.imageType = 'image';
            angular.element(document.getElementById('fileInput' + index)).on('change', handleFileSelect);
            document.getElementById('fileInput' + index).click();
        }

        vm.selectImgMobile = function(index) {
            vm.index = index;
            vm.imageType = 'imageMobile';
            angular.element(document.getElementById('fileInputMobile' + index)).on('change', handleFileSelect);
            document.getElementById('fileInputMobile' + index).click();
        }

        var handleFileSelect = function(event) {
            var file = event.target.files[0];
            if (file) {
                var reader = new FileReader();
                reader.onload = function(e) {
                    var img = document.createElement("img");
                    img.onload = function() {
                        var canvas = document.createElement("canvas");
                        var ctx = canvas.getContext("2d");
                        ctx.drawImage(img, 0, 0);

                        var MAX_WIDTH = 1800;
                        var MAX_HEIGHT = 640;

                        if (vm.imageType === 'imageMobile') {
                            MAX_WIDTH = 650;
                            MAX_HEIGHT = 640;
                        }

                        canvas.width = MAX_WIDTH;
                        canvas.height = MAX_HEIGHT;
                        var ctx = canvas.getContext("2d");
                        ctx.drawImage(img, 0, 0, MAX_WIDTH, MAX_HEIGHT);

                        var dataurl = canvas.toDataURL(file.type);
                        vm.banners[vm.index][vm.imageType] = dataurl;

                        var outputId = vm.imageType === 'imageMobile' ? 'imgMobile' : 'img';

                        var output = document.getElementById(outputId + vm.index);
                        output.src = dataurl;
                    }
                    img.src = reader.result;

                };
                reader.readAsDataURL(file);
            }
        };

        loadAll();
    }
})();
