(function () {
    'use strict';

    angular
        .module('showcaseApp')
        .factory('ShowcaseSettingsService', ShowcaseSettingsService);

        ShowcaseSettingsService.$inject = ['ShowcaseSettingsFactory'];

    function ShowcaseSettingsService(ShowcaseSettingsFactory) {

        var service = {
            getByStoreId: getByStoreId,
            update: update
        };

        return service;

        function getByStoreId(storeId) {
            return ShowcaseSettingsFactory.getByStoreId({ storeId: storeId }).$promise;
        }

        function update(storeId, settings) {
            return ShowcaseSettingsFactory.update({ storeId: storeId }, settings).$promise;
        }
    }
})();
