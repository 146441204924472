(function() {
    'use strict';
    angular
        .module('showcaseApp')
        .service('ChatCommerceService', ChatCommerceService);

    ChatCommerceService.$inject = ['ChatCommerceFactory', 'ChatConfigurationFactory'];

    function ChatCommerceService(ChatCommerceFactory, ChatConfigurationFactory) {

        var service = {
            getConversations: getConversations,
            getConversationsByFilter: getConversationsByFilter,
            getConversationByConversationId: getConversationByConversationId,
            connectToChat: connectToChat,
            synchronizeChat: synchronizeChat,
            getChatConfiguration: getChatConfiguration
        };

        return service;

        function getConversations(filter) {
            return ChatCommerceFactory.getConversations(filter).$promise;
        }

        function getConversationsByFilter(filter) {
            return ChatCommerceFactory.getConversationsByFilter(filter).$promise;
        }

        function synchronizeChat(chatPlatform) {
            return ChatCommerceFactory.synchronizeChat(chatPlatform).$promise;
        }

        function connectToChat(chatConnectionData) {
            return ChatCommerceFactory.connectToChat(chatConnectionData).$promise;
        }

        function getConversationByConversationId(conversationId) {
            return ChatCommerceFactory.getConversationByConversationId({conversationId: conversationId}).$promise;
        }

        function getChatConfiguration() {
            return ChatConfigurationFactory.getChatConfiguration().$promise;
        }
    }

})();
