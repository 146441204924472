(function() {
    'use strict';
    angular
        .module('showcaseApp')
        .factory('FileManagerFactory', FileManagerFactory);

    FileManagerFactory.$inject = ['$resource'];

    function FileManagerFactory($resource) {
        var resourceUrl =  'admin/api/gcs-manager/hub/:hubId';

        return $resource(resourceUrl, {hubId: '@hubId', folderId: '@folderId', fileId: '@fileId'}, {
            'createFolder': {
                method: 'POST',
                url: resourceUrl +  '/folder',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data){
                        response.data = angular.fromJson(data);
                    } else{
                        response.data = data;
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'editFolder': {
                method: 'PUT',
                url: resourceUrl +  '/folder',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data){
                        response.data = angular.fromJson(data);
                    } else{
                        response.data = data;
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'deleteFolder': {
                method: 'DELETE',
                url: resourceUrl + '/folder/:folderId',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'getFolderChildren': {
                method: 'GET',
                url: resourceUrl + '/folder/:folderId/children',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'getFolderFiles': {
                method: 'GET',
                url: resourceUrl + '/folder/:folderId/file',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'uploadFile': {
                method: 'POST',
                url: resourceUrl +  '/file',
                transformRequest: angular.identity,
                headers: {'Content-Type': undefined},
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'updateFile': {
                method: 'PUT',
                url: resourceUrl +  '/file',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data){
                        response.data = angular.fromJson(data);
                    } else{
                        response.data = data;
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'deleteFile': {
                method: 'DELETE',
                url: resourceUrl + '/file/:fileId',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'getFileTags': {
                method: 'GET',
                url: resourceUrl + '/tag',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
        });
    }

})();
