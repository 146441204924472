(function() {
    'use strict';
    angular
        .module('showcaseApp')
        .service('BroadcastListService', BroadcastListService);

    BroadcastListService.$inject = ['BroadcastListFactory'];

    function BroadcastListService(BroadcastListFactory) {

        var service = {
            getContacts: getContacts,
            importContacts: importContacts,
            getBroadcastDashboard: getBroadcastDashboard,
            addBroadcastList: addBroadcastList
        };

        return service;

        function getContacts(filters){
            return BroadcastListFactory.getContacts(filters).$promise;
        }

        function importContacts(contactsFile){
            return BroadcastListFactory.importContacts(contactsFile).$promise;
        }

        function addBroadcastList(broadcastList) {
            return BroadcastListFactory.addBroadcastList(broadcastList).$promise;
        }

        function getBroadcastDashboard(filters) {
            return BroadcastListFactory.getBroadcastDashboard(filters).$promise;
        }
    }
})();
