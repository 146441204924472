(function() {
    'use strict';

    angular
        .module('showcaseApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('gift-card', {
            parent: 'admin',
            url: '/gift-card',
            data: {
                pageTitle: 'gift-card.title'
            },
            views: {
                'content@app': {
                    templateUrl: 'app/admin/gift-card/gift-card.html',
                    controller: 'GiftCardController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('gift-card');
                    return $translate.refresh();
                }]
            }
        }).state('gift-card-validate', {
            parent: 'admin',
            url: '/gift-card-validate',
            data: {
                pageTitle: 'gift-card.title'
            },
            views: {
                'content@app': {
                    templateUrl: 'app/admin/gift-card/gift-card-validate.html',
                    controller: 'GiftCardController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('gift-card');
                    return $translate.refresh();
                }]
            }
        });
    }
})();
