(function () {
    'use strict';
    angular
        .module('showcaseApp')
        .service('ColorService', ColorService);

    ColorService.$inject = ['ColorFactoryAdmin'];

    function ColorService(ColorFactoryAdmin) {
        var service = {
            getColorsByCurrentStore: getColorsByCurrentStore,
            findColor: findColor,
            createColor: createColor,
            getColorById: getColorById,
            removeColor: removeColor,
            editColor: editColor
        };

        return service;

        function getColorsByCurrentStore() {
            return ColorFactoryAdmin.getColorsByCurrentSTore().$promise;
        }

        function findColor(colorName) {
            return ColorFactoryAdmin.findColor({ "name": colorName }).$promise;
        }

        function createColor(colorName) {
            return ColorFactoryAdmin.createColor({ "name": colorName }).$promise;
        }

        function getColorById(colorId) {
            return ColorFactoryAdmin.getColorById({"colorId": colorId}).$promise;
        }

        function removeColor(colorId) {
            return ColorFactoryAdmin.removeColor({"colorId": colorId}).$promise;
        }

        function editColor(color) {   
            return ColorFactoryAdmin.editColor({"colorId": color.id}, color).$promise;
        }

    }
})();
