(function() {
    'use strict';
    angular
        .module('showcaseApp')
        .service('MessengerService', MessengerService);

    MessengerService.$inject = ['MessengerFactory', '$resource'];

    function MessengerService(MessengerFactory, $resource) {

        var service = {
            sendMessage: sendMessage,
            sendCarousel: sendCarousel,
            makeMessengerCredentials: makeMessengerCredentials,
            getFbProfileById: getFbProfileById,
            findConversations: findConversations,
            findMessages: findMessages,
            sendMedia: sendMedia,
            findAttachmentResource: findAttachmentResource,
            findAttachment: findAttachment 
        };

        return service;

        function sendMessage(text, recipientId, type) {
            var body = {
                recipient: {
                    id: recipientId
                },
                message: {
                    text: text
                },
                type: type ? type : "TEXT"
               };
            return MessengerFactory.sendMessage(body).$promise;
        }

        function sendMedia(body) {
            return MessengerFactory.sendMessage(body).$promise;
        }

        function sendCarousel(flyers, recipientId) {
            var body = {
                recipient: {
                    id: recipientId
                },
                flyers: flyers,
                type: "TEMPLATE"
            };
            return MessengerFactory.sendMessage(body).$promise;
        }

        function makeMessengerCredentials(body) {
            MessengerFactory.makeMessengerCredentials({id: body.id}, body);
        }

        function getFbProfileById(id) {
            return MessengerFactory.getFbProfileById({id: id}).$promise;
        }

        function findConversations(pageId, plataform, cursor) {
            return MessengerFactory.findConversations({pageId: pageId, plataform: plataform, cursor: cursor}).$promise;
        }

        function findMessages(threadId, cursor) {
            return MessengerFactory.findMessages({threadId: threadId, cursor: cursor}).$promise;
        }

        function findAttachmentResource(id) {
            return MessengerFactory.findAttachment({id: id}).$promise;
        }

        function findAttachment(resource) {
            return $resource(resource, {}, {
                'find': {method: 'GET'}
            });
        }

    }

})();
