(function() {
    'use strict';

    angular
        .module('showcaseApp')
        .controller('HubFlyersEditController', HubFlyersEditController);

    function HubFlyersEditController($scope, $translate, FlyersCurrencyValue, FlyerCategoryService, HubFlyersService, toastr, $uibModalInstance, flyer, moment, BrandService, $localStorage, StoreService) {
        var format = 'L LT';
        var FORMAT_DATE = 'L';
        // Beginning of the day
        var CURRENT_DAY = moment().startOf('day');
        var vm = this;

        StoreService.getStore($localStorage.defaultStore.storeId).then(function(response) {
            vm.storeCategory = response.data.categoryKey;
        });

        vm.flyer = flyer;
        vm.flyerSnapshot = angular.copy(vm.flyer);
        delete vm.flyerSnapshot.addImageFileDTO;
        if (flyer.discountType == 'DISCOUNT_PERCENTAGE') {
            vm.flyer.discountPercentageValue = flyer.discountValue;
            vm.flyer.discountValue = undefined;
        }
        vm.convertDate = true;
        vm.isSaving = false;
        vm.checks = {
            validityTypeDate: !vm.flyer.validityTypeDate,
            validityTypeNumber: !vm.flyer.validityTypeNumber,
            usageLimitByUser: !vm.flyer.usageLimitByUser
        };

        vm.transactionalUserInteractions = ["RENT", "SALE"];
        vm.disabledCurrency = false;
        vm.sizes = getAllSizes();
        vm.brands = [];
        vm.colors = getAllColors();
        loadBrands();

        loadCategories();
        checkValidateFields();

        vm.currencyConfigurations = getCurrencyListConfiguration();

        vm.init = init;
        vm.checkFlyerHasCouponGenerated = checkFlyerHasCouponGenerated;
        vm.onValidityTypeDateChange = onValidityTypeDateChange;

        function init() {
            dateTimePicker();
            vm.flyer.currencyInstance = vm.currencyConfigurations[vm.flyer.currency];
            vm.blockCurrency();
            vm.flyer.gender = vm.flyer.gender ? vm.flyer.gender.toString() : null;
            vm.flyer.sizes = vm.flyer.sizes ? vm.flyer.sizes : [];
        }

        vm.checkIfSizeSelected = function(size) {
            return vm.flyer.sizes.includes(size);
        }

        vm.updateSizeOrRemove = function(size) {
            var isSelected = vm.flyer.sizes.includes(size);
            if (isSelected) {
                vm.flyer.sizes = vm.flyer.sizes.filter(function(item) {
                    return item !== size;
                });
            } else {
                vm.flyer.sizes.push(size);
            }
        }

        vm.closeModal = function() {
            $uibModalInstance.close();
        }

        vm.checkDiscountType = function() {

            if (vm.flyer.discountValue && (!vm.flyer.discountType)) {
                vm.flyer.discountType = 'DISCOUNT_FIXED';
            }
            if (vm.flyer.discountType === 'DISCOUNT_FIXED') {

                vm.flyerEditForm.discountValue.$setValidity('max', true);
                if (parseFloat(vm.flyer.discountValue) > parseFloat(vm.flyer.purchaseValue)) {
                    vm.flyerEditForm.discountValue.$setValidity('discountGreaterThanValue', false);
                } else {
                    vm.flyerEditForm.discountValue.$setValidity('discountGreaterThanValue', true);
                }
            }
        }

        vm.blockCurrency = function() {
            if (vm.transactionalUserInteractions.includes(vm.flyer.type)) {
                vm.flyer.currency = 'REAL';
                vm.flyer.currencyInstance = vm.currencyConfigurations.REAL;
                vm.disabledCurrency = true;
            } else {
                vm.disabledCurrency = false;
            }
        }

        vm.typeChanged = function() {
            if (vm.flyer.type == 'RENT') {
                vm.flyer.maxUsageByUser = 1;
                vm.flyer.maxUsages = 1;
                vm.checks['usageLimitByUser'] = false;
                vm.checks['validityTypeNumber'] = false;
                vm.checks['validityTypeDate'] = true;
            } else {
                vm.flyer.maxUsageByUser = vm.flyerSnapshot.maxUsageByUser;
                vm.flyer.maxUsages = vm.flyerSnapshot.maxUsages;
                vm.checks['usageLimitByUser'] = !vm.flyerSnapshot.usageLimitByUser;
                vm.checks['validityTypeNumber'] = !vm.flyerSnapshot.validityTypeNumber;
            }

            vm.flyer.bulkQuantity = null;
            vm.flyer.discountBulkPercentage = null;

            vm.blockCurrency();

        }

        function loadBrands() {
            BrandService.getBrandsByCurrentStore().then(function(response) {
                vm.brands = response.data;
            });
        }

        vm.isToDisplayMe = function(value) {
            return value == vm.flyer.discountType;
        }

        vm.getFlyerCurrency = function(currency) {
            return FlyersCurrencyValue[currency];
        }

        vm.checkIfLaunchSelected = function() {
            return vm.flyer.isLaunch;
        }

        vm.updateLaunchOrRemove = function() {
            vm.flyer.isLaunch = !vm.flyer.isLaunch;
        }

        vm.checkIfBasicSelected = function() {
            return vm.flyer.isBasic;
        }

        vm.updateBasicOrRemove = function() {
            vm.flyer.isBasic = !vm.flyer.isBasic;
        }

        vm.saveFlyer = function() {
            if (!vm.flyerEditForm.$invalid) {
                // Valid dates
                if (vm.flyer.startDate) {

                    if (vm.flyer.expirationDate) {
                        if (moment(vm.flyer.startDate, format).isSameOrAfter(moment(vm.flyer.expirationDate, format))) {
                            toastr.warning($translate.instant('error.flyerStartDateGreaterThanOrEqualsToExpirationDate'));
                            return;
                        }
                    }
                }

                vm.isSaving = true;
                var flyerToSave = angular.copy(vm.flyer);
                delete flyerToSave.addImageFileDTO;
                flyerToSave.validityTypeDate = !vm.checks['validityTypeDate'];
                flyerToSave.validityTypeNumber = !vm.checks['validityTypeNumber'];
                flyerToSave.usageLimitByUser = !vm.checks['usageLimitByUser'];
                flyerToSave.startDate = flyerToSave.startDate ? moment(flyerToSave.startDate, format).toDate() : moment().add(2, 'm');
                flyerToSave.expirationDate = flyerToSave.expirationDate ? moment(flyerToSave.expirationDate, format).toDate() : undefined;
                flyerToSave.currency = vm.flyer.currencyInstance.key;
                flyerToSave.gender = parseInt(vm.flyer.gender);
                flyerToSave.brand = vm.flyer.brand;
                flyerToSave.color = vm.flyer.color;
                flyerToSave.sizes = vm.flyer.sizes;
                flyerToSave.tags = vm.flyer.tags.map(function(tag) {
                    return tag['text'];
                });
                flyerToSave.productReference = vm.flyer.productReference;
                flyerToSave.isLaunch = vm.flyer.isLaunch;
                flyerToSave.isBasic = vm.flyer.isBasic;

                if (vm.flyer.discountType == 'DISCOUNT_PERCENTAGE') flyerToSave.discountValue = vm.flyer.discountPercentageValue;
                HubFlyersService.updateFlyer(flyerToSave).then(function(data) {
                        vm.isSaving = false;
                        toastr.success($translate.instant('flyers.messages.update.success'));
                        $uibModalInstance.close(data);
                    },
                    function(data) {
                        var header = data.headers();
                        vm.flyer.startDate = moment(vm.flyer.startDate).format(format);
                        vm.flyer.expirationDate = vm.flyer.expirationDate ? moment(vm.flyer.expirationDate).format(format) : undefined;
                        vm.isSaving = false;
                        toastr.error($translate.instant(header['x-flyerapp-error']));
                        $uibModalInstance.dismiss(data);
                    });
            }
        }

        function loadCategories() {
            FlyerCategoryService.listCategories($translate.use()).then(function(response) {
                vm.categories = response.data;
                vm.onChangeCategory();
            });
        }

        vm.onChangeCategory = function() {
            vm.fullCategory = vm.categories.find(function(category) {
                return category.key === vm.flyer.categoryKey;
            });
        }

        function checkValidateFields() {
            if (vm.flyer.expirationDate) {
                vm.flyer.expirationDate = new Date(vm.flyer.expirationDate);
                vm.flyer.validityTypeDate = true;
            }
            if (vm.flyer.maxUsages) {
                vm.flyer.maxUsages = parseInt(vm.flyer.maxUsages);
            }
            if (vm.flyer.maxUsageByUser) {
                vm.flyer.maxUsageByUser = parseInt(vm.flyer.maxUsageByUser);
            }
        }

        function checkExpirationDate() {
            if (vm.flyer.expirationDate) {
                if (moment(vm.flyer.startDate, format).isSameOrAfter(moment(vm.flyer.expirationDate, format))) {
                    /** returns false if the expirationDate is before startDate */
                    return false;
                }
                /** returns true if the expirationDate is ok (the expirationDate is after startDate) */
                return true;
            }
        }


        function checkFlyerHasCouponGenerated() {
            if (!vm.flyer.startDate && vm.flyer.hasAlreadyCouponGenerated) return false;
            return (moment(vm.flyer.startDate, format).isBefore(moment()) && vm.flyer.hasAlreadyCouponGenerated);
        }

        function onValidityTypeDateChange(checked) {
            if (checked) {
                angular.element('#expirationDateTime').data("DateTimePicker").clear();
            }
        }

        function dateTimePicker() {

            vm.startDateOptions = {
                format: format,
                sideBySide: true,
                ignoreReadonly: true,
                locale: moment.locale(),
                allowInputToggle: true,
                useCurrent: false,
                widgetPositioning: {
                    horizontal: 'auto',
                    vertical: 'top'
                }
            };

            vm.expireDateOptions = {
                format: format,
                sideBySide: true,
                ignoreReadonly: true,
                locale: moment.locale(),
                allowInputToggle: true,
                minDate: moment().add(1, 'minutes'),
                widgetPositioning: {
                    horizontal: 'auto',
                    vertical: 'top'
                },
                useCurrent: false
            };

            angular.element('#startDateTime').datetimepicker(vm.startDateOptions).on('dp.change', function(ev) {
                if (ev.date) {
                    vm.flyer.startDate = ev.date.format(format);

                    if (vm.flyer.expirationDate && !checkExpirationDate()) {
                        vm.flyer.expirationDate = undefined;
                        angular.element('#expirationDateTime').data("DateTimePicker").minDate(ev.date || moment().seconds(0).milliseconds(0).add(1, 'minutes'));
                    } else {
                        angular.element('#expirationDateTime').data("DateTimePicker").minDate(moment().seconds(0).milliseconds(0).add(1, 'minutes'));
                    }

                    $scope.$apply(function() {
                        vm.flyerEditForm.startDate.$setValidity('minDate', true);
                    });
                } else {
                    vm.flyer.startDate = undefined;
                }
            });

            angular.element("#expirationDateTime").datetimepicker(vm.expireDateOptions).on('dp.change', function(ev) {
                if (ev.date) {
                    vm.flyer.expirationDate = ev.date.format(format);
                    $scope.$apply(function() {
                        vm.flyerEditForm.expirationDate.$setValidity('required', true);
                    });
                } else {
                    vm.flyer.expirationDate = undefined;
                }
            });

            vm.flyer.startDate = vm.flyer.startDate ? moment(vm.flyer.startDate).format(format) : null;
            vm.flyer.expirationDate = vm.flyer.expirationDate ? moment(vm.flyer.expirationDate).format(format) : null;

        }
    }

})();
