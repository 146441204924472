(function () {
    'use strict';
    angular
        .module('showcaseApp')
        .factory('OnlineRetailFactory', OnlineRetailFactory);

    OnlineRetailFactory.$inject = ['$resource'];

    function OnlineRetailFactory($resource) {
        var flyerResourceUrl = 'flyer/api/online-retail';
        var adminResourceUrl = 'admin/api/erp-configuration';

        return $resource(adminResourceUrl, {}, {
            'getPriceTables': {
                method: 'GET',
                url: adminResourceUrl + '/price-tables/:token',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            }, 
            'getEntities': {
                method: 'GET',
                url: adminResourceUrl + '/entities/:token',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'getBankAccounts': {
                method: 'GET',
                url: adminResourceUrl + '/bank-accounts/:token',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'getSellers': {
                method: 'GET',
                url: adminResourceUrl + '/sellers/:token',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'getCategoryLevelList': {
                method: 'GET',
                url: adminResourceUrl + '/category-level/:token',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'reprocess': {
                method: 'PUT',
                url: adminResourceUrl + '/reprocess/:id',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'syncProductsByStoreAndFlyerIds': {
                method: 'POST',
                url: flyerResourceUrl + '/sync-products/:storeId',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            }
        });
    }

})();
