(function () {
    'use-strict'

    angular
        .module('showcaseApp')
        .controller('ConsumerContactReportController', ConsumerContactReportController);

    //** NgInject */
    function ConsumerContactReportController(ContactReportService, $filter, $translate, $localStorage) {
        var QTD_OF_USERS_KEY = "quantityOfUsers";
        var WEEK_KEY = "week";

        var vm = this;
        vm.genderData = [];

        var storeId = $localStorage.defaultStore.storeId;

        vm.fullScreenMode = false;
        vm.fullscreen = fullscreen;

        // Relatório de quantidade de clientes cadastrados 
        vm.colors = ['#45b7cd', '#ff6384', '#ff8e72'];

        vm.labels = [];

        vm.numberOfUsersData = [];

        vm.datasetOverride = [{
            label: "Número de clientes",
            borderWidth: 3,
            hoverBackgroundColor: "rgba(255,99,132,0.4)",
            hoverBorderColor: "rgba(255,99,132,1)",
            type: 'line'
        }];

        vm.chartOptions = {
            scales: {
                xAxes: [{
                    display: true
                }],
                yAxes: [{
                    display: true,
                    ticks: {
                        min: 0,
                        stepSize: 1,
                        suggestedMax: 10
                    }
                }]
            }
        };

        function getMaxOfArray(numArray) {
            return Math.max.apply(null, numArray);
        }

        loadAllDatas();


        function loadAllDatas() {
            listUsersRegisteredWithContactGeneratedByStore();
            listMostLikedCategories();
            listUsersFirstInteraction();
            listUsersGenerateMoreContact();
        }

        function listUsersRegisteredWithContactGeneratedByStore() {
            vm.loadingUsersRegisteredByGender = true;
            // Percentage of customers that generate contact by gender
            ContactReportService.listUsersRegisteredByGenderWithContactGeneratedByStore(storeId).then(function (genderData) {
                vm.genderData = genderData;
                if (genderData.length >= 2) {
                    vm.genderData = genderData;
                } else {
                    createListGenders(genderData);
                }
            }).finally(function () {
                vm.loadingUsersRegisteredByGender = false;
            });
        }

        function listMostLikedCategories() {
            vm.loadingMostLikedCategories = true;

            ContactReportService.listMostLikedCategories(storeId, $translate.use()).then(function (data) {
                vm.categoryData = data.mostLikedCategories;
                vm.totalBrand = data.total;
            }).finally(function () {
                vm.loadingMostLikedCategories = false;
            });
        }

        function listUsersFirstInteraction() {
            vm.loadingListUserFirstInteraction = true;
            ContactReportService.listUsersFirstInteraction(storeId).then(function (data) {
                if (data.length > 0) {
                    buildChartData(data);
                } else {
                    vm.numberOfUsersData.push([0]);
                }
            }).finally(function () {
                vm.loadingListUserFirstInteraction = false;
            });
        }

        function listUsersGenerateMoreContact() {
            vm.loadingListUsersGenerateMoreContact = true;

            ContactReportService.listUsersGenerateMoreContact(storeId).then(function (contactGeneratorData) {
                vm.contactGeneratorData = contactGeneratorData;
            }).finally(function () {
                vm.loadingListUsersGenerateMoreContact = false;
            });
        }

        function buildChartData(data) {
            var qtdOfUsers = [];
            var myDate = new Date();
            var myWeek = $filter('date')(myDate, 'ww');

            for (var i = 0; i < data.length; i++) {
                if ((data[i][WEEK_KEY] - myWeek) === 0) {
                    vm.labels.push($filter("translate")('reports.tabs.contact.numberUsers.label.week.current'));
                } else {
                    vm.labels.push($filter("translate")('reports.tabs.contact.numberUsers.label.week.past', {
                        value: myWeek - data[i][WEEK_KEY]
                    }));
                }

                qtdOfUsers.push(data[i][QTD_OF_USERS_KEY]);
            }
            vm.numberOfUsersData.push(qtdOfUsers);
            vm.chartOptions.scales.yAxes[0].ticks.suggestedMax = getMaxOfArray(vm.numberOfUsersData[0]) + 3;
        }

        function createListGenders(genderData) {
            var hasMale = false;
            var hasFemale = false;

            for (var i = 0; i < genderData.length; i++) {
                if (genderData[i].gender == 'male') {
                    hasMale = true;
                } else if (genderData[i].gender == 'female') {
                    hasFemale = true;
                }
            }

            if (!hasMale) {
                vm.genderData.push({
                    gender: 'male',
                    percentage: 0
                });
            }

            if (!hasFemale) {
                vm.genderData.push({
                    gender: 'female',
                    percentage: 0
                });
            }
        }

        // Function for full screen
        function fullscreen() {
            vm.fullScreenMode = !vm.fullScreenMode;

            var screen = angular.element('#reportDashboard');
            var button = angular.element('i.fa-expand');
            $('body').toggleClass('fullscreen-ibox-mode');
            if (vm.fullScreenMode) {
                var button = angular.element('i.fa-expand');
                button.toggleClass('fa-expand').toggleClass('fa-compress');
                screen.addClass('height-fullscreen');
            } else {
                var button = angular.element('i.fa-compress');
                button.toggleClass('fa-compress').toggleClass('fa-expand');
                screen.removeClass('height-fullscreen')
            }
            screen.toggleClass('ibox fullscreen');
            setTimeout(function () {
                $(window).trigger('resize');
            }, 100);
        }

    }

})();
