(function() {
    'use strict';
    angular
        .module('showcaseApp')
        .service('MediaDeviceService', MediaDeviceService);

    function MediaDeviceService() {
        var service = {
            getAudioRecorder: getAudioRecorder,
            verifyMicrophonePermission: verifyMicrophonePermission,
            requestMicrophonePermission: requestMicrophonePermission
        };

        function requestMicrophonePermission() {
            return new Promise(function (resolve, reject) {
                navigator.mediaDevices.getUserMedia({ audio: true })
                    .then(function (stream) {
                        resolve(stream != null || stream != undefined);
                    }, function (err) {
                        reject(err.name);
                    });
            });
        }

        function verifyMicrophonePermission() {
            return new Promise(function (resolve, reject) {
                navigator.permissions.query({name: 'microphone'})
                    .then(function(permissionObj) {
                        resolve(permissionObj.state);
                    })
                    .catch(function(error) {
                        reject(error.name);
                    })
            });
        }

        function getAudioRecorder() {
            return new Promise(function (resolve, reject) {
                navigator.mediaDevices.getUserMedia({ audio: true })
                    .then(function (stream) {
                        var mediaRecorder = new MediaRecorder(stream);
                        var audioChunks = [];

                        mediaRecorder.addEventListener('dataavailable', function(event) {
                            audioChunks.push(event.data);
                        });

                        var start = function() {
                            mediaRecorder.start();
                        };

                        var stop = function() {
                            return new Promise(function (resolve, reject) {
                                mediaRecorder.addEventListener('stop', function() {
                                    var audioBlob = new Blob(audioChunks, { type: 'audio/mpeg' });
                                    var audioUrl = URL.createObjectURL(audioBlob);
                                    var audio = new Audio(audioUrl);
                                    var play = function() {
                                        audio.play();
                                    };
                                    // deactivate mic.
                                    stream.getTracks().forEach(function(track) {
                                        track.stop();
                                    });
                                    resolve({ audioBlob: audioBlob, audioUrl: audioUrl, play: play });
                                });
                                mediaRecorder.stop();
                            });
                        };

                        var deactivateMicrophone = function() {
                            stream.getTracks().forEach(function(track) {
                                track.stop();
                            });
                        }

                        resolve({ start: start, stop: stop, deactivateMicrophone: deactivateMicrophone });
                    }, function (err) {
                        console.log(err);
                        reject(err.name);
                    });
            });
        }

        return service;

    }
})();
