(function() {
    'use strict';

    angular
        .module('showcaseApp')
        .controller('SettingsController', SettingsController);

    function SettingsController(toastr, StoreService, Principal, ModalFactory, $localStorage,
        Auth, JhiLanguageService, $translate, moment, $scope, $rootScope, $stateParams, $timeout, SettingsService
    ) {
        var format = 'DD/MM/YYYY';
        var vm = this;

        vm.maskLocale = '99/99/9999';

        vm.stores = [];
        vm.hasShipping = false;

        vm.store = $localStorage.defaultStore;

        vm.error = null;
        vm.settingsAccount = null;
        vm.success = null;

		vm.islocked = $localStorage.isMoipPlanFree;
        $timeout(function() {
            if ($stateParams.tab) {
                vm.tab = $stateParams.tab;
            }
        }, 500);



        StoreService.getMyAllStores().then(function(response) {
            if (response && response.data) {
                response.data.forEach(function(elem) {
                    var store = {};
                    store.nickname = elem.nickname;
                    store.id = elem.id;
                    store.redirectURL = elem.redirectURL;
                    store.fbPixel = elem.fbPixel;
                    store.glAnalytics = elem.glAnalytics;
                    store.fbActivation = elem.fbActivation;
    
                    vm.stores.push(store);
                });
            }
        });

        isUserSubscribed();

        vm.authShipping = function() {
            StoreService.authorizeShipping().then(function(response) {});
        }

        /**
         * Store the "settings account" in a separate variable, and not in the shared "account" variable.
         */
        var copyAccount = function(account) {
            return {
                activated: account.activated,
                email: account.email,
                firstName: account.firstName,
                langKey: account.langKey,
                lastName: account.lastName,
                login: account.login,
                gender: account.gender,
                twoFactorAuth: account.twoFactorAuth,
                dateOfBirth: moment(account.dateOfBirth).format(format),
                phone: account.phone,
                moipAccountInfo: account.moipAccountInfo
            };
        };

        Principal.identity().then(function(account) {
            vm.settingsAccount = copyAccount(account);
            if (vm.settingsAccount.moipAccountInfo) {
                vm.addressDTO = vm.settingsAccount.moipAccountInfo;
            }
            dateTimePicker();

        });

		vm.openModal = function(){
			$localStorage.isLocked = true;
		}


        vm.savePersonalData = function(form, test) {
            if (form.$valid) {
                // if (vm.stores.length > 0) {
                    vm.isSaving = true;
                    var accountToSave = angular.copy(vm.settingsAccount);
                    accountToSave.dateOfBirth = moment(vm.settingsAccount.dateOfBirth, format).toDate();
                    Auth.updateAccount(accountToSave)
                        .then(function() {
                            vm.error = null;
                            vm.success = 'OK';
                            Principal.identity(true).then(function(account) {
                                vm.settingsAccount = copyAccount(account);
                                JhiLanguageService.getCurrent().then(function(current) {
                                    if (vm.settingsAccount && vm.settingsAccount.langKey !== current) {
                                        $translate.use(vm.settingsAccount.langKey)
                                            .then(function(result) { 
                                                processResponse();
                                                vm.isSaving = false; 
                                            }, function(err) { 
                                                processResponse();
                                                vm.isSaving = false;
                                            });
                                    }
                                }, function(err) {
                                    m.isSaving = false;
                                });
                                // $rootScope.$emit('settings-updated');
                            }, function(err) {
                                m.isSaving = false;
                            });
                        }).catch(function(error) {
                            if (error.status === 401) {
                                toastr.success($translate.instant('settings.store.messages.success.login'));
                            }
                            vm.isSaving = false;
                            vm.success = null;
                            vm.error = 'ERROR';
                        });
                // }
            }
        };

        vm.saveURLRedirectDomainsData = function() {
            if (vm.stores.length > 0) {
                vm.isSaving = true;
                vm.stores.forEach(function(elem) {
                    elem.redirectURL = splitAbsoluteUrl('http://www.', elem.redirectURL);
                    elem.redirectURL = splitAbsoluteUrl('http://', elem.redirectURL);
                    elem.redirectURL = splitAbsoluteUrl('https://', elem.redirectURL);
                    elem.redirectURL = splitAbsoluteUrl('https://www.', elem.redirectURL);
                    elem.redirectURL = splitAbsoluteUrl('www.', elem.redirectURL);
                });

                StoreService.saveRedirectDomains(vm.stores).then(
                    function(response) {
                        vm.isSaving = false;
                        vm.error = null;
                        vm.success = 'OK';
                        processResponse();
                    },
                    function(err) {
                        vm.isSaving = false;
                        vm.success = null;
                        vm.error = 'ERROR';
                    }
                );
            }
        };

        vm.saveAnalyticsData = function() {
            vm.isSaving = true;
            StoreService.saveStoreAnalytics(vm.stores).then(
                function(response) {
                    vm.isSaving = false;
                    vm.error = null;
                    vm.success = 'OK';
                    processResponse();
                },
                function(err) {
                    vm.isSaving = false;
                    vm.success = null;
                    vm.error = 'ERROR';
                }
            );
        };

        function splitAbsoluteUrl(url, value) {
            if (value) {
                if (value.includes(url)) {
                    return value.split(url)[1];
                }
            }
            return value;
        }

        function dateTimePicker() {
            vm.dateOfBirthOptions = {
                format: format,
                ignoreReadonly: true,
                locale: moment.locale(),
                allowInputToggle: true,
                maxDate: moment(),
                widgetPositioning: {
                    horizontal: 'right',
                    vertical: 'bottom'
                }
            };

            angular
                .element('#dateOfBirth')
                .datetimepicker(vm.dateOfBirthOptions)
                .on('dp.change', function(ev) {
                    if (ev.date) {
                        $scope.$apply(function() {
                            vm.settingsAccount.dateOfBirth = ev.date.format(
                                format
                            );
                        });
                    } else {
                        $scope.$apply(function() {
                            vm.settingsAccount.dateOfBirth = undefined;
                        });
                    }
                });
        }

        function processResponse() {
            if (vm.error) {
                toastr.error(
                    $translate.instant('settings.store.messages.error.fail')
                );
                vm.error = undefined;
            } else if (vm.success && vm.success == 'OK') {
                vm.success = undefined;
                toastr.success($translate.instant('settings.store.messages.success'));
            }
        }

        function actualyDisconnect(){
            Principal.disconnectFromPaymentGateway().then(function(response){
                if (response.data == 'OK'){
                    vm.moipInfo = {};
                }
            });
        }

        function disconnect(){

            ModalFactory.openSimpleModal(
                $translate.instant('settings.paymentConfiguration.sureDisconnectTitle'),
                $translate.instant('settings.paymentConfiguration.sureDisconnectQuestion'),
                $translate.instant('settings.paymentConfiguration.yes'),
                $translate.instant('settings.paymentConfiguration.no'),
                actualyDisconnect);
        }

        vm.changeWebNotifications = function () {
            if(vm.webNotifications) {
                subscribeButtonHandler();
            } else {
                if (vm.registerId != null) {
                    SettingsService.unregisterToReceiveNotifications(vm.registerId).then(function (data) {
                        navigator.serviceWorker.getRegistration().then( function (data) {
                            data.pushManager.getSubscription().then(function (data) {
                                data.unsubscribe();
                                vm.webNotifications = false;
                            });
                        });
                    });
                }
            }
        }

        vm.changeSystemNotifications = function () {
            SettingsService.updateSystemNotifications(vm.registerId, vm.systemNotifications).then(function (data) {
                $rootScope.$emit('settings-updated');
            });
        }

        vm.changeEmailNotifications = function () {
            SettingsService.updateEmailNotifications(vm.registerId, vm.systemNotifications).then(function (data) {
                $rootScope.$emit('settings-updated');
            });
        }


        function urlB64ToUint8Array(base64String) {
            const padding = '='.repeat((4 - base64String.length % 4) % 4);
            const base64 = (base64String + padding)
                .replace(/\-/g, '+')
                .replace(/_/g, '/');
            const rawData = window.atob(base64);
            const outputArray = new Uint8Array(rawData.length);
            for (var i = 0; i < rawData.length; ++i) {
                outputArray[i] = rawData.charCodeAt(i);
            }
            return outputArray;
        }

        function subscribeButtonHandler() {
            // Prevent the user from clicking the subscribe button multiple times.
            Notification.requestPermission().then(function (result) {
                if (result === 'denied') {
                    toastr.error($translate.instant('settings.notifications.error'));
                    vm.webNotifications = false;
                    return;
                }

                navigator.serviceWorker.getRegistration().then( function (registration) {
                    if (registration) {
                        registration.pushManager.getSubscription().then(function (subscribed) {
                            if (subscribed) {
                                return;
                            }
                            registration.pushManager.subscribe({
                                userVisibleOnly: true,
                                applicationServerKey: urlB64ToUint8Array('BI4I7aPcZIoAL7ZpvzE3vxlpAfBdswMmokjuAoE47H8DLOus99-Rsw5nJQLr93YWXo4Xr5f-jIQZ6ONSSD0eClY')
                            }).then(function (subscription) {
                                const subscriptionJSON = JSON.parse(JSON.stringify(subscription));
                                SettingsService.registerToReceiveNotifications(subscriptionJSON.endpoint,
                                    subscriptionJSON.keys.auth, subscriptionJSON.keys.p256dh, $localStorage.userId, vm.store.storeId).then(function (data) {
                                    vm.registerId = data.id;
                                    vm.webNotifications = true;
                                });
                            });
                        });
                    } else {
                        toastr.error($translate.instant('settings.notifications.error'));
                        vm.webNotifications = false;
                    }
                });

            });
        }

        function isUserSubscribed() {
            vm.webNotifications = false;
            vm.systemNotifications = false;
            vm.emailNotifications = false;

            SettingsService.getRegister($localStorage.userId, vm.store.storeId).then(function (data) {
                vm.systemNotifications = data['soundNotification'];
                vm.emailNotifications = data['emailNotification'];
                vm.webNotifications = data['endpoint'] != null;
                vm.registerId = data['id'];
            });
        }
    }
})();
