(function () {
    'use strict';

    angular
        .module('showcaseApp')
        .controller('BagDetailController', BagDetailController);

    /** @ngInject */
    function BagDetailController($state, $timeout, $stateParams, $q, $window, $localStorage,
                                 StoreService, $scope, $translate, FlyersService, FlyerCategoryService, FlyersCurrencyValue,
                                 ShowcasesService, toastr, Principal, UserInteractModalFactory, ModalFactory, DOMAIN, $rootScope,
                                 BrandService, CustomerService, UserService, paginationConstants, $uibModal) {

        var vm = this;
        var userInteractModal;
        var storeId = $localStorage.defaultStore.storeId;
        var mainColor = getComputedStyle(document.documentElement).getPropertyValue('--main-color');
        vm.showcaseId = $stateParams.showcaseId;
        vm.isEditMode = vm.showcaseId ? true : false;

        vm.isFiltering = false;

        vm.currentPage = 1;
        vm.maxSize = 15;
        vm.numPerPage = vm.maxSize;
        vm.totalFlyers = 0;
        vm.startRegistry = 1;
        vm.endRegistry = vm.numPerPage;
        vm.banners = [];
        vm.haveBanners = false;
        vm.isMobile = detectar_mobile();

        var bagDefaultImg = getDefaultImageBagUrl();
        var bagDefaultImgData = '';

        function getDefaultImageBagUrl() {
            if ($localStorage.defaultStore.hubId == 25) {
                return 'https://showkase-prod.s3.sa-east-1.amazonaws.com/hub/system-images/storepiccadilly/image_default_bag.png'
            } else {
                return 'https://showkase-prod.s3.sa-east-1.amazonaws.com/hub/system-images/showkase/image_default_bag.png';
            }
        }

//		function loadCanvaAPI(c,a,n){
//			var w=c.createElement(a),s=c.getElementsByTagName(a)[0];
//			w.src=n;s.parentNode.insertBefore(w,s);
//		}
//
//		loadCanvaAPI (document,'script','https://sdk.canva.com/designbutton/v2/api.js');

        vm.paginationOptions = {
            page: vm.currentPage - 1,
            size: vm.numPerPage
        };

        vm.showcase = {
            'backgroundColor': false,
            flyers: [],
            ownerId: null,
            hasCover: false
        };

        vm.saveImageCover = true;
        vm.isPrinting = false;
        vm.tab = 1;
        vm.colors = [];
        vm.sizes = [];
        vm.excludes = [];
        vm.featureds = [];
        vm.tags = [];
        vm.genders = [];


        vm.isLoading = false;

        vm.filters = {
            brands: [],
            colors: [],
            configurations: [],
            sizes: [],
            categories: [],
            genders: [],
            tags: [],
            type: null,
            isLaunch: null,
            isBasic: null,
            text: ''
        }

        vm.numberFilterSelected = 0;

        vm.selectedFeatured = [];
        vm.sortOptions = paginationConstants.sortOptions[$translate.use().toLowerCase()];
        vm.selectedSortByOption = "";

        vm.onSelectedSortOption = function () {
            vm.currentPage = 1;
            vm.paginationOptions = {
                page: vm.currentPage - 1,
                size: vm.numPerPage
            };

            vm.filters.sortBy = vm.selectedSortByOption.value;
            vm.filters.sortDirection = vm.selectedSortByOption.direction;
            loadFlyers();
        }

        vm.closeDropDown = function () {
            $("md-backdrop").trigger('click');
        }

        vm.featuredChange = function () {
            if (vm.selectedFeatured.includes('launch')) {
                vm.filters.isLaunch = true;
            } else {
                vm.filters.isLaunch = false;
            }
            if (vm.selectedFeatured.includes('basic')) {
                vm.filters.isBasic = true;
            } else {
                vm.filters.isBasic = false;
            }
        };

        vm.getSelectedTextGender = function (filter) {
            var genderTranslate = '';
            if (filter[0] === 1) {
                genderTranslate = $translate.instant('flyers.new.form.gender.male');
            }
            if (filter[0] === 2) {
                genderTranslate = $translate.instant('flyers.new.form.gender.female');
            }
            if (filter[0] === 3) {
                genderTranslate = $translate.instant('flyers.new.form.gender.both');
            }
            if (filter.length == 1) {
                return '<span class="highlight">' + genderTranslate + '</span>';
            }
            if (filter.length > 1) {
                var temp = '  +';
                temp += filter.length - 1;
                temp += ' selecionados';
                return '<span class="highlight">' + genderTranslate + '</span>' +
                    '<span>&nbsp</span>' +
                    '<span class="highlight">' + temp + '</span>';
            }
            return '';
        }


        vm.getSelectedTextCategory = function (filter) {
            for (var i = 0; i < vm.categories.length; i++) {
				if (vm.categories[i].key === filter[0]){
		            if (filter.length == 1) {
		                return '<span class="highlight">' + vm.categories[i].title + '</span>';
		            }
		            if (filter.length > 1) {
		                var temp = '  +';
		                temp += filter.length - 1;
		                temp += ' selecionados';

		                return '<span class="highlight">' + vm.categories[i].title + '</span>' +
		                    '<span>&nbsp</span>' +
		                    '<span class="highlight">' + temp + '</span>';
		            }
		            return '';
				}
			}
            return '';
        }

        vm.getSelectedTextBrands = function (filter) {
            for (var i = 0; i < vm.brands.length; i++) {
                if (filter[0] && vm.brands[i].name === filter[0]){
                    if (filter.length == 1) {
                        return '<span class="highlight">' + vm.brands[i].name + '</span>';
                    }
                    if (filter.length > 1) {
                        var temp = '  +';
                        temp += filter.length - 1;
                        temp += ' selecionados';

                        return '<span class="highlight">' + vm.brands[i].name + '</span>' +
                            '<span>&nbsp</span>' +
                            '<span class="highlight">' + temp + '</span>';
                    }
                    return '';
                }
            }
            return '';
        }

        vm.getSelectedText = function (filter) {
            var elementTranslate = '';

            if (filter[0] === 'launch' || filter[0] === 'basic') {
                elementTranslate = $translate.instant('flyers.new.form.configurations.' + filter[0].toLowerCase());
            } else {
                elementTranslate = filter[0];
            }
            if (filter.length == 1) {
                return '<span class="highlight">' + elementTranslate + '</span>';
            }
            if (filter.length > 1) {
                var temp = '  +';
                temp += filter.length - 1;
                temp += ' selecionados';

                return '<span class="highlight">' + elementTranslate + '</span>' +
                    '<span>&nbsp</span>' +
                    '<span class="highlight">' + temp + '</span>';
            }
            return '';

        }

        vm.customers = [];

        vm.customer = {};

        vm.users = [];

        vm.user = {};

        vm.checkIfFilterSelected = function (filterKey, filter) {
            return (vm.filters[filterKey] ? vm.filters[filterKey] : []).indexOf(filter) >= 0 ? true : false;
        }

        vm.updateFilterOrRemove = function (filterKey, filter) {
            if (!vm.filters[filterKey]) {
                vm.filters[filterKey] = [];
            }
            var index = vm.filters[filterKey].indexOf(filter);
            if (index === -1) {
                vm.filters[filterKey].push(filter);
                return;
            }
            vm.filters[filterKey].splice(index, 1);
        }

        vm.checkIfLaunchSelected = function () {
            return vm.filters.isLaunch;
        }

        vm.updateLaunchOrRemove = function () {
            vm.filters.isLaunch = !vm.filters.isLaunch;
        }

        vm.checkIfBasicSelected = function () {
            return vm.filters.isBasic;
        }

        vm.updateBasicOrRemove = function () {
            vm.filters.isBasic = !vm.filters.isBasic;
        }

        vm.checkIfFlyerSelected = function (idFlyer) {
            return vm.excludes.includes(idFlyer);
        }

        vm.refreshSelectedFlyers = function (flyer) {
            var index = vm.excludes.indexOf(flyer.id);
            if (index >= 0) {
                vm.excludes.splice(index, 1);
                return;
            }
            vm.excludes.push(flyer.id);
        }

        vm.removeFromSelectedList = function (flyer) {
            var index = vm.excludes.indexOf(flyer);
            if (index > -1) {
                vm.excludes.splice(index, 1);
            }
        }

        vm.hasSome = function (list, arr) {
            if (arr) {
                return arr.some(function (v) {
                    return list.indexOf(v.id) >= 0;
                });
            }
        };

        vm.addPageToList = function () {
            if (vm.hasSome(vm.excludes, vm.flyersFiltered)) {
                for (var i = 0; i < vm.flyersFiltered.length; i++) {
                    vm.removeFromSelectedList(vm.flyersFiltered[i].id);
                }
            } else {


                var notIn = vm.flyersFiltered.filter(function (el) {
                    return vm.excludes.indexOf(el.id) < 0;
                });
                if (notIn && notIn.length) {
                    for (var i = 0; i < notIn.length; i++) {
                        vm.refreshSelectedFlyers(notIn[i]);
                    }
                } else {
                    for (var i = 0; i < vm.flyersFiltered.length; i++) {
                        vm.removeFromSelectedList(vm.flyersFiltered[i].id);
                    }
                }

            }
        }

        vm.makeTagsToFilter = function () {
            vm.filters.tags = vm.filters.tags ? vm.filters.tags.map(function (item) { return item.text ? item.text : item }) : [];
        }

        vm.filterFlyers = function () {
            countFilters();

            vm.flyersFiltered = [];
            vm.isFiltering = true;
            vm.filters.type = null;
            vm.makeTagsToFilter();

            ShowcasesService.findFlyerByFilterBag(vm.paginationOptions, vm.filters).then(function (response) {
                vm.isFiltering = false;
                vm.totalFlyers = response.totalElements;
                vm.flyersFiltered = response.content;

                vm.flyersFiltered.map(function (data) {
                    data.stock = data.inventory ? +data.inventory : 0;
                    if (data.details) {
                        data.stock += +data.details.reduce(function (a, b) {
                            return a + +b.inventory
                        }, 0)
                    }
                    vm.getCategoryName(data);
                })
                /**
                 * Verifica se o flyer possui estoque
                 */
                vm.flyersFiltered.forEach(function (flyer) {
                    if (flyer.details && flyer.details.length > 0) {

                        flyer.details.every(function (detail) {
                            flyer.hasInventory = detail.inventory === null || (detail.inventory !== null && detail.inventory > 0) ? true : false;
                            return !flyer.hasInventory;
                        });
                    } else {
                        flyer.hasInventory = flyer.inventory === null || (flyer.inventory !== null && flyer.inventory > 0) ? true : false;
                    }
                });
            });
        }

        vm.saveBag = function () {
            ShowcasesService.saveBag({
                showcaseTemplateId: 1,
                backgroundColor: mainColor,
                ownerId: 0,
                hasCover: true,
                userInteract: 'SALE',
                flyers: [],
                excludes: [],
                bagFilters: vm.filters
            });
        }

        vm.goToTabTwo = function () {
            if (!vm.showcase.id) {
                vm.saveBag
            }
        }

        vm.showcase = {
            'backgroundColor': false,
            flyers: [],
            ownerId: null,
            hasCover: false
        };

        vm.listFilter = {
            storeId: storeId,
            activated: null,
            type: null
        };

        // if (!vm.showcaseId) {
        //     openShowcaseModalType();
        // }

        vm.openShowcaseModalType = openShowcaseModalType;

        vm.imageSize = 800;

        vm.storeOwnerHasMoipAccount = false;
        storeOwnerHasMoipAccount();
        vm.loadFlyers = loadFlyers;
        vm.dynamicPopover = {
            templateUrl: 'userInteractPopoverTemplate.html',
        };

        vm.coverEdit = true;
        vm.templateIsLoading = true;
        vm.showcases = [1, 2, 3, 4, 5, 6, 7, 8];
        vm.templates = [];
        vm.flyersToLoad = 1;
        vm.isSaving = false;
        vm.isSavingPreview = false;
        vm.showcaseColors = [
            [{
                color: "#201E1E",
                border: 0
            }, {
                color: "#363231",
                border: 0
            }, {
                color: "#4B4544",
                border: 0
            }, {
                color: "#870200",
                border: 0
            }, {
                color: "#05a19c",
                border: 0
            }, {
                color: "#37003C",
                border: 0
            }, {
                color: "#5A1846",
                border: 0
            }, {
                color: "#910D3F",
                border: 0
            }, {
                color: "#C70039",
                border: 0
            }, {
                color: "#263545",
                border: 0
            }],
            [{
                color: "#002A4A",
                border: 0
            }, {
                color: "#01201B",
                border: 0
            }, {
                color: "#015146",
                border: 0
            }, {
                color: "#1A5B28",
                border: 0
            }, {
                color: "#277B25",
                border: 0
            }, {
                color: "#331908",
                border: 0
            }, {
                color: "#D35400",
                border: 0
            }, {
                color: "#DF7F05",
                border: 0
            }, {
                color: mainColor,
                border: 0
            }]
        ];

        loadAll();

        vm.goToSettings = function () {
            $state.go('settings');
        }

        vm.tabClick = function (tab) {
            if (vm.showcaseId && tab === 2) {
                vm.filterFlyers();
            } else if (!vm.showcase.name) {
                toastr.warning($translate.instant('bag.error.no-bag-name'));
                return;
            } else if (tab === 2) {
                vm.saveFlyerPreview();
                return;
            }
            vm.tab = tab;
            vm.loadingTab = true;
        }


        function removeInvalidFilter(key, validOptions, listFilter) {
            var validItens = [];
            if (key && validOptions) {
                validOptions.forEach(function(category) {
                    validItens.push(category[key]);
                });
            } else {
                validItens = validOptions;
            }
            if (listFilter){
                listFilter.forEach(function(obj) {
                    if (!(validItens.includes(obj))) {
                        var index = listFilter.indexOf(obj);
                        listFilter.splice(index, 1);
                    }
                });
            }
        }

        function verifyFilters() {
            removeInvalidFilter('key', vm.categories, vm.filters.categories);
            removeInvalidFilter('name', vm.brands, vm.filters.brands);
            removeInvalidFilter(null, vm.sizes, vm.filters.sizes);
            removeInvalidFilter(null, vm.colors, vm.filters.colors);
        }

        function countFilters(){
            verifyFilters();

            vm.numberFilterSelected = vm.filters.colors.length + vm.filters.categories.length + vm.filters.brands.length +
                vm.filters.sizes.length + vm.filters.genders.length + vm.filters.tags.length;
            if (vm.filters.isLaunch) vm.numberFilterSelected += 1;
            if (vm.filters.isBasic) vm.numberFilterSelected += 1;
        }

        function getLoggedUser() {
            Principal.identity().then(function(account) {
                vm.users.push(account);
            });
        }

        function loadAll() {
			FlyersService.getAllFiltersFlyer().then(function (response) {
				vm.colors = response.data.colors;
				vm.sizes = response.data.sizes;
				vm.featureds = response.data.features;
				vm.tags = response.data.tags;
				vm.genders = response.data.genders;
                getBase64Image(bagDefaultImg);
            });

            BrandService.getBrandsByCurrentStore().then(function (response) {
                vm.brands = response.data;
            });

            FlyerCategoryService.listCategoriesByCurrentStore($translate.use()).then(function (response) {
                vm.categories = response.data;
            });

            vm.templateId = 1; // Chose the first template as default
            ShowcasesService.getTemplates().then(function (response) {
                vm.templates = response;
            });

            if (vm.showcaseId) {
                loadShowcase().then(function () {
                    loadFlyers();
                });
            } else {

                vm.showcase.ownerId = storeId;
                vm.showcase.backgroundColor = mainColor;
                vm.showcase.showcaseTemplateId = vm.templateId;
                vm.templateIsLoading = false;
                if(!vm.showcase.bagFilters || (vm.showcase.bagFilters && !vm.showcase.bagFilters.type)) {
                    vm.filters.type = 'SALE';
                }
                loadFlyers();
                getLoggedUser();
            }

            StoreService.getOwnerCategoryCurrentStore().then(function (response) {
                vm.storeCategory = response.data;
            });

        }

        function loadShowcase() {
            return ShowcasesService.getShowcase(vm.showcaseId).then(function (response) {
                vm.showcase = response;
                vm.customers = vm.showcase.customers;
                vm.users = vm.showcase.users;
                vm.filters = vm.showcase.bagFilters;
                vm.showcase.bagFilters.tags = vm.showcase.bagFilters.tags.map(function (tag) { return { text: tag } });
                vm.excludes = vm.showcase.flyers.map(function (data) { return data.id });
                if (vm.showcase.bagFilters && vm.showcase.bagFilters.isBasic) {
                    vm.selectedFeatured.push('basic')
                }
                if (vm.showcase.bagFilters && vm.showcase.bagFilters.isLaunch) {
                    vm.selectedFeatured.push('launch')
                }
                if(!vm.showcase.bagFilters || (vm.showcase.bagFilters && !vm.showcase.bagFilters.type)) {
                    vm.filters.type = 'SALE';
                }
                vm.excludes = vm.showcase.flyers.map(function(data) {return data.id}) ;

                vm.showcase.userInteract = vm.originalUserInteract ? vm.originalUserInteract : vm.showcase.userInteract;

                if(vm.showcase.banners != null && vm.showcase.banners.length > 0) {
                    vm.banners = vm.showcase.banners;
                    vm.haveBanners = true;
                    vm.showcase.banners.forEach(function(banner, i) {
                        vm.banners[i].image = vm.showcase.coverUrl.split("showcase")[0] + vm.banners[i].cover;

                        if (vm.banners[i].coverMobile) {
                            vm.banners[i].imageMobile = vm.showcase.coverUrl.split("showcase")[0] + vm.banners[i].coverMobile;
                        }
                    })
                } else if (vm.showcase.hasCover && vm.showcase.coverUrl !== '') {
                    checkImage(function (url) {
                        vm.banners.push({'id': 1, 'image': url, 'name': '', 'link': ''});
                        loadCarousel();
                    });
                    vm.haveBanners = true;
                }

                loadCarousel();
                loadShowcasesFlyers();
            });
        }

        vm.getProfileName = function(user) {
            var profiles = user.userProfiles.filter(function (prof) {
                return prof.storeId === storeId; 
            });

            return profiles && profiles.length > 0 ? (" - " + profiles[0].profileName) : "";
        }

        vm.checkFlyerIsSelected = function (id) {
            if (vm.flyersMap) {
                if (vm.flyersMap[id]) {
                    return true;
                } else {
                    return false;
                }
            }
        };

        vm.changeFlyerSelected = function (flyer) {
            if (vm.flyersMap) {
                if (vm.flyersMap[flyer.id]) {
                    vm.showcase.flyers.splice(vm.flyersMap[flyer.id].pos, 1);
                    loadShowcasesFlyers();
                } else {
                    vm.showcase.flyers.splice(0, 0, flyer);
                    loadShowcasesFlyers();
                }
            } else {
                vm.showcase.flyers.splice(0, 0, flyer);
                loadShowcasesFlyers();
            }
        };

        vm.changeTemplate = function (templateId) {
            vm.templateIsLoading = true;
            vm.templateId = templateId;
            loadTemplate().then(function () {
                vm.showcase.showcaseTemplateId = templateId;
                loadFlyersToTemplate(angular.copy(vm.showcase.flyers));
            });
        };

        function changeMaxFlyerPerShowcase() {
            vm.maxFlyersPerShowcase = vm.template.quantityOfRows * (12 / vm.template.row.items[0].size);
        }

        vm.getImageSrc = function (templateId) {
            return 'content/images/templates/' + templateId + '.png';
        };


        function succesPrint(res) {

            var saveByteArray = (function() {
                return function (data, name, mimeType) {
                    var blob = new Blob(data, {
                        type: mimeType
                    });
                    var url = window.URL.createObjectURL(blob);
                    var element = angular.element('<a/>');
                    element.attr({
                        href: url,
                        target: '_blank',
                        download: name
                    });
                    if (document.createEvent) {
                        var ev = document.createEvent("MouseEvent");
                        ev.initMouseEvent("click",
                            true /* bubble */,
                            true /* cancelable */,
                            window,
                            null,
                            0, 0, 0, 0, /* coordinates */
                            false,
                            false,
                            false,
                            false,
                            0 /* left */,
                            null);
                        element[0].dispatchEvent(ev);
                    } else {
                        element[0].fireEvent("onclick");
                    }
                    setTimeout(function () {
                        document.body.removeChild;
                        window.URL.revokeObjectURL(url);
                    }, 200);
                };
            }());
            if (res.headers["content-disposition"]) {
                var filename = res.headers["content-disposition"].match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)[1];
                var mimeType = res.headers["content-type"];
                filename = filename.replace(/['"]/g, '');
                saveByteArray([res.data], filename, mimeType);
            } else {
                toastr.error($translate.instant('showcases.messages.tabloid.error.erroByGenerateCatalog'));
            }

            vm.isPrinting = false;
            $rootScope.$emit('finish-generate-tabloid');
        }

        function errorPrint(response) {
            vm.isPrinting = false;
            $rootScope.$emit('finish-generate-tabloid');
            toastr.error($translate.instant('showcases.messages.tabloid.error.erroByGenerateCatalog'));

        }

        vm.generateTabloid = function () {
            vm.saveFlyer(false, function () {
                if (!vm.showcase.hasCover) {
                    toastr.error($translate.instant('showcases.messages.errors.blankCover'));
                    vm.isSaving = false;
                } else if (vm.showcase.flyers.length < 1) {
                    toastr.error($translate.instant('showcases.messages.errors.blankFlyers'));
                } else if (!vm.showcase.name) {
                    toastr.error($translate.instant('showcases.messages.errors.blankName'));
                } else {
                    vm.isPrinting = true;
                    buildMessageGeneratedTabloid();
                    $rootScope.$emit('waiting-generates-tabloid');
                    ShowcasesService.generateTabloid({
                        showcaseId: Number(vm.showcase.id) || undefined,
                        storeId: (storeId ? storeId + "" : undefined) || undefined,
                        flyers: vm.showcase.flyers.map(function (flyer) {
                            return flyer.id;
                        })
                    }).then(succesPrint, errorPrint);
                }
            });
        }

        function loadShowcasesFlyers() {
            if (vm.showcase.flyers) {
                vm.flyerId = 0;
                if (vm.showcase.showcaseTemplateId) {
                    vm.templateId = vm.showcase.showcaseTemplateId;
                }
                reduceFlyersToMap();
                vm.showcase.flyers.forEach(function (flyer) {
                    vm.getCategoryName(flyer);
                    var random = new Date().getTime();
                    flyer.imagePath = '/flyer/api/' + flyer.id + '/picture?type=large&ts=' + random;
                });
                $q.all(vm.showcase.flyers).then(function (flyers) {
                    loadTemplate().then(function () {
                        loadFlyersToTemplate(flyers);
                    });
                });
            }
        }

        function reduceFlyersToMap() {
            var index = 0;
            vm.flyersMap = vm.showcase.flyers.reduce(function (map, flyer) {
                map[flyer.id] = {
                    name: flyer.name,
                    pos: index
                };
                index++;
                return map;
            }, {})
        }

        function loadFlyerTemplateRows() {
            vm.template.rows = [];
            for (var i = 0; i < vm.template.quantityOfRows; i++) {
                var item = {};
                angular.copy(vm.template.row, item);
                vm.template.rows.push(item);
            };
        }

        function loadFlyersToTemplate(flyers) {
            // loadFlyerTemplateRows();
            $q.all(vm.template.rows).then(function (data) {

                for (var i = 0; i < vm.template.rows.length && flyers.length > 0; i++) {
                    for (var k = 0; k < vm.template.rows[i].items.length && flyers.length > 0; k++) {
                        if (vm.template.rows[i].items[k]) {
                            if (flyers.length > 0) {
                                vm.template.rows[i].items[k].flyer = flyers[0];
                                flyers.splice(0, 1);
                            }
                        }
                    }
                }
            });
            $q.all(vm.template).then(function (data) {
                vm.templateIsLoading = false;
            });

        }

        function loadTemplate() {
            return ShowcasesService.getTemplate(vm.templateId).then(function (response) {
                vm.template = response;
                changeMaxFlyerPerShowcase();
                loadFlyerTemplateRows();

            });
        }


        function openShowcaseModalType() {
            userInteractModal = UserInteractModalFactory.selectUserInterectType(storeId, vm.showcase.userInteract);
            userInteractModal.result.then(function (userInteract) {

                vm.originalUserInteract = angular.copy(vm.showcase.userInteract);
                vm.showcase.userInteract = userInteract;

                vm.listFilter.type = vm.showcase.userInteract;
                if (!(vm.originalUserInteract && vm.originalUserInteract === vm.showcase.userInteract)) {
                    vm.flyersInfo = null;
                    vm.flyersFiltered = [];
                    vm.flyersMap = undefined;
                    vm.showcase.flyers = [];
                    loadFlyers();
                    loadShowcasesFlyers();
                }
            });
        }

        function loadFlyers() {
            vm.filterFlyers();
            vm.isLoading = true;
        }

        vm.getShowcasesFlyer = function () {
            vm.saveFlyer(true);
        }

        vm.goToFlyerByShowkase = function () {
            vm.saveFlyer(true);
        }

        vm.goToList = function () {
            $state.go('bag');
        }

        vm.saveFlyerPreview = function (isRedirectToFlyersScreen, callback) {
            vm.showcase.showcaseTemplateId = 1;
            if (vm.showcase.name) {
                vm.isSaving = true;
                vm.showcase.cover = "";
                vm.saveCover(function () {
                    if (vm.showcase.addImageFileDTO.length > 0) {
                        var banners = angular.copy(vm.showcase.addImageFileDTO);
                        vm.showcase.addImageFileDTO.forEach(function (banner, index) {
                            banners[index].nameFile = vm.banners[index].name;
                            banners[index].action = vm.banners[index].link;
                        });
                        vm.showcase.addImageFileDTO = banners;

                        vm.showcase.flyers = [];
                        vm.filters.excludes = vm.excludes;
                        vm.makeTagsToFilter();
                        vm.showcase.bagFilters = vm.filters;
                        vm.showcase.customers = vm.customers.map(function (item) { return + item.id });
                        vm.showcase.users = vm.users.map(function (item) { return + item.id });

                        if (vm.showcaseId) {
                            vm.isFiltering = true;
                            vm.showcase.bagFilters.apply = true;
                            vm.showcase.customers = vm.customers.map(function (item) { return +item.id });
                            var reversedUsers = vm.users.map(function (item) { return +item.id });
                            vm.showcase.users = reversedUsers.reverse();

                            ShowcasesService.updateShowcase(vm.showcase).then(function (data) {
                                vm.showcase = data;
                                vm.banners = data.banners;
                                vm.showcase.banners.forEach(function(banner, i) {
                                    vm.banners[i].image = vm.showcase.coverUrl.split("showcase")[0] + vm.banners[i].cover;

                                    if (vm.banners[i].coverMobile) {
                                        vm.banners[i].imageMobile = vm.showcase.coverUrl.split("showcase")[0] + vm.banners[i].coverMobile;
                                    }
                                })
                                if (isRedirectToFlyersScreen) {
                                    $state.go('bag', {
                                        showcaseId: data.id
                                    });
                                } else {
                                    vm.showcaseId = data.id;
                                    // toastr.success($translate.instant('showcases.messages.update.success'));
                                    vm.isSaving = false;
                                    vm.tabClick(2);
                                }
                                if (callback) {
                                    callback(true);
                                }
                                // vm.showcase.flyers = flyers;
                                vm.isSavingPreview = false;
                            }, function (data) {
                                toastr.error($translate.instant('bag.error.filter'));
                                vm.isSaving = false;
                            }).finally(function () { vm.isSaving = false; });
                        } else {
                            vm.isFiltering = true;

                            var reversedUsers = vm.users.map(function (item) { return +item.id });
                            ShowcasesService.saveBag({
                                showcaseTemplateId: 1,
                                backgroundColor: mainColor,
                                ownerId: 0,
                                hasCover: true,
                                userInteract: 'SALE',
                                flyers: [],
                                bagFilters: vm.filters,
                                name: vm.showcase.name,
                                addImageFileDTO: vm.showcase.addImageFileDTO,
                                customers: vm.customers.map(function (item) { return +item.id }),
                                users: reversedUsers.reverse()
                            }).then(function (data) {
                                vm.showcase = data;
                                vm.showcaseId = data.id;
                                vm.showcase.customers = vm.customers.map(function (item) { return +item.id });
                                vm.showcase.users = vm.users.map(function (item) { return +item.id });
                                vm.tabClick(2);
                            }, function (data) {
                                toastr.error($translate.instant('bag.error.filter'));
                                vm.isSavingPreview = false;
                            }).finally(function () {  vm.isSaving = false;});
                        }
                    } else if (!vm.showcase.cover) {
                        vm.isSaving = false;
                        toastr.warning($translate.instant('Insira um estilo para a mala'));
                    }
                });
            } else if (!vm.showcase.name) {
                toastr.warning($translate.instant('Insira o nome da Mala'));
            }
        }

        vm.saveFlyer = function (isRedirectToFlyersScreen, callback, isSaving) {
            vm.showcase.flyers = [];
            vm.filters.excludes = vm.excludes;

            vm.makeTagsToFilter()
            vm.showcase.bagFilters = vm.filters;
            vm.showcase.bagFilters.apply = true;
            vm.isFiltering = true;
            if (vm.showcaseId) {
                vm.saveCover(function () {
                    var banners = angular.copy(vm.showcase.addImageFileDTO);
                    vm.showcase.addImageFileDTO.forEach(function (banner, index) {
                        banners[index].nameFile = vm.banners[index].name;
                        banners[index].action = vm.banners[index].link;
                    });
                    vm.showcase.addImageFileDTO = banners;
                    vm.showcase.customers = vm.customers.map(function (item) { return +item.id });
                    vm.showcase.users = vm.users.map(function (item) { return +item.id });
                    ShowcasesService.updateShowcase(vm.showcase).then(function (data) {
                        vm.showcase = data;
                        vm.banners = vm.showcase.banners;
                        vm.showcase.banners.forEach(function(banner, i) {
                            vm.banners[i].image = vm.showcase.coverUrl.split("showcase")[0] + vm.banners[i].cover;

                            if (vm.banners[i].coverMobile) {
                                vm.banners[i].imageMobile = vm.showcase.coverUrl.split("showcase")[0] + vm.banners[i].coverMobile;
                            }
                        })

                        if (isRedirectToFlyersScreen) {
                            toastr.success($translate.instant('Sua mala está pronta!'));
                            $state.go('bag', {
                                showcaseId: data.id
                            });
                        } else {
                            toastr.success($translate.instant('Sua mala está pronta!'));
                            vm.isSaving = false;
                            vm.isFiltering = false;
                            // Follow the instruction of Jackson, when saves the showcase the state doesn't need to be changed
                            // $state.go('showcases');
                        }
                        if (callback) {
                            callback(true);
                            vm.isFiltering = false;
                        }
                        vm.isFiltering = false;

                    }, function (data) {
                        if (isRedirectToFlyersScreen) {
                            toastr.error($translate.instant('Erro ao tentar atualizar sua mala'));
                            vm.isSaving = false;
                            vm.isFiltering = false;
                        }
                    });
                });
            }
        }

        vm.setBackgroundColor = function (color) {
            vm.showcase.backgroundColor = color;
        }

        vm.getCategoryName = function (flyer) {
            var category = _.find(vm.categories, function(el) {return el.key === flyer.categoryKey});
            if (category) {
                flyer.category = category.title;
            }
        }

        vm.getFlyerCurrency = function (currency) {
            return FlyersCurrencyValue[currency];
        }

        vm.getFlyerImageURL = function (flyer) {
            flyer.imagePath = FlyersService.getFlyerImageURL(flyer.id);
        }

        vm.loadFlyer = function () {
            vm.flyerId++;
            vm.flyersToLoad--;
        }

        vm.checkIfShowcasePublished = function () {
            if (vm.showcase.published) {
                return true;
            } else {
                return false;
            }
        }

        vm.getBackgroundImg = function (flyerId) {
            return ('\'/flyer/api/' + flyerId + '/picture\'');
        }

        function getStoreInfo() {
            return StoreService.getStore($localStorage.defaultStore.storeId);
        }

        vm.getTranslateFeatureds = function (featured) {
            return 'flyers.new.form.configurations.' + featured.toLowerCase();
        }

        vm.getTranslateGenders = function (gender) {
            if (gender === 1) {
                return 'flyers.new.form.gender.male';
            }
            if (gender === 2) {
                return 'flyers.new.form.gender.female';
            }
            if (gender === 3) {
                return 'flyers.new.form.gender.both';
            }
            return '';
        }

        function showMessagePublishShowcase() {
            if (!$localStorage.defaultStore.storeNickname) {
                getStoreInfo().then(function (response) {
                    $localStorage.defaultStore.storeNickname = response.data.nickname;
                    buildMessagePublishShowcase();
                });
            } else {
                buildMessagePublishShowcase();
            }
        }

        function buildMessagePublishShowcase() {
            ModalFactory.openModal(null,
                $translate.instant('showcases.messages.publish.successTitle', {
                    showcaseType: $translate.instant('global.showcaseTypes.' + vm.showcase.userInteract)
                }),
                $translate.instant('showcases.messages.publish.successSubtitle', {
                    url: 'http://' + $localStorage.defaultStore.storeNickname.toLowerCase() + "." + getDomainNameSufix($window)
                }),
                null, $translate.instant('showcases.messages.publish.goToShowcase'),
                'OK', function () { $window.open('http://' + $localStorage.defaultStore.storeNickname.toLowerCase() + "." + getDomainNameSufix($window), '_blank'); },
                null, null, 'icon-ksc-showcasepublished');
        }

        function buildMessageGeneratedTabloid() {
            ModalFactory.openModal(null,
                'showcases.messages.tabloid.readyTabloid',
                null, null, 'OK', null, 'close', null, null, null,
                'showcases.messages.tabloid.ifNotDownloaded', 'showcases.messages.tabloid.clickHere',
                function (callback) {
                    ShowcasesService.generateTabloid({
                        showcaseId: Number(vm.showcase.id) || undefined,
                        storeId: (storeId ? storeId + "" : undefined) || undefined,
                        flyers: vm.showcase.flyers.map(function (flyer) {
                            return flyer.id;
                        })
                    }).then(function (res) {
                        var saveByteArray = (function () {
                            return function (data, name, mimeType) {
                                var blob = new Blob(data, {
                                    type: mimeType
                                });
                                var url = window.URL.createObjectURL(blob);
                                var element = angular.element('<a/>');
                                element.attr({
                                    href: url,
                                    target: '_blank',
                                    download: name
                                });
                                if (document.createEvent) {
                                    var ev = document.createEvent("MouseEvent");
                                    ev.initMouseEvent("click", true /* bubble */, true /* cancelable */, window, null, 0, 0, 0, 0, /* coordinates */
                                        false, false, false, false,
                                        /*
                                         * modifier keys
                                         */
                                        0 /* left */, null);
                                    element[0].dispatchEvent(ev);
                                } else {
                                    element[0].fireEvent("onclick");
                                }
                                setTimeout(function () {
                                    document.body.removeChild;
                                    window.URL.revokeObjectURL(url);
                                }, 200);
                            };
                        }());
                        if (res.headers["content-disposition"]) {
                            var filename = res.headers["content-disposition"].match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)[1];
                            var mimeType = res.headers["content-type"];
                            filename = filename.replace(/['"]/g, '');
                            saveByteArray([res.data], filename, mimeType);
                        } else {
                            toastr.error($translate.instant('showcases.messages.tabloid.error.erroByGenerateCatalog'));
                        }
                        vm.isPrinting = false;
                        callback();
                    }, function (response) {
                        vm.isPrinting = false;
                        callback();
                    });
                }, 'width: 143px; height: 170px;', 'https://showkase-prod.s3-sa-east-1.amazonaws.com/hub/system-images/' + $rootScope.urlImage + '/download-tabloide.png');
        }

        function storeOwnerHasMoipAccount() {
            Principal.storeOwnerHasMoipAccount().then(function (responseMoipAccount) {
                vm.storeOwnerHasMoipAccount = responseMoipAccount.data != null && responseMoipAccount.data.moipLogin != null;
            });
        }

        vm.publishShowcase = function () {
            vm.isPublishing = true;
            if (!vm.showcase.hasCover && vm.coverEdit) {
                toastr.warning($translate.instant('showcases.messages.errors.blankCover'));
                vm.isSaving = false;
                vm.isPublishing = false;
            } else if (vm.showcase.flyers.length < 1) {
                toastr.warning($translate.instant('showcases.messages.errors.blankFlyers'));
                vm.isPublishing = false;
                vm.isSaving = false;
            } else if (!vm.showcase.name) {
                toastr.warning($translate.instant('showcases.messages.errors.blankName'));
                vm.isPublishing = false;
                vm.isSaving = false;
            } else {
                vm.saveFlyer(false, function () {
                    ShowcasesService.publishShowcase(vm.showcase.id).then(function (data) {
                        vm.isPublishing = false;
                        showMessagePublishShowcase();
                        $state.go('showcases');
                    }, function (data) {
                        if (data.headers("errorKey")) {
                            toastr.error($translate.instant('showcases.messages.publish.errors.' + data.headers("errorKey")));
                        } else {
                            toastr.error($translate.instant('showcases.messages.publish.errors.genericError'));
                        }

                        vm.isPublishing = false;
                    });
                });
            }
        }

        vm.openInput = function (evt) {
            document.getElementById('fileInput').click();
        }

        vm.getPreviewURL = function (showcase) {
            if (showcase.id) {
                vm.openPreviewURL();
            } else {
                if (vm.saveFlyerPreview(false, function (data) {
                    if (data) {
                        $timeout(function (data) {
                            vm.openPreviewURL();
                        }, 1000);
                    }
                }));
            }
        };

        vm.openPreviewURL = function () {
            $window.open('http://' + $localStorage.defaultStore.storeNickname + '.showkase.com.br/preview/' + vm.showcase.id, '_blank');
        };

        function checkImage(callback) {
            var image = new Image();
            image.onload = function () {
                if (this.width > 0) {
                    callback(vm.showcase.coverUrl);
                }
            }
            image.onerror = function () {
                callback(bagDefaultImg);
            }
            image.src = vm.showcase.coverUrl;
        }

        function loadCarousel() {
            var carousel = document.getElementById("carousel");

            while(carousel.firstChild) {
                carousel.removeChild(carousel.firstChild);
            }

            var counter = vm.banners.length;
            if(counter > 0) {
                carousel.style.display = 'block';
                var reversedBanners = vm.banners.slice().reverse();
                reversedBanners.forEach(function (banner) {
                    var name = "";
                    if(banner.name != null) {
                        name = banner.name;
                    }
                    if (name.length > 20) {
                        name = name.slice(0, 20).concat('...');
                    } else if (name.length == 0) {
                        name = "[SEM NOME]"
                    }

                    var srcImage = banner.image;
                    if (srcImage.split(',')[0].indexOf('base64') == -1) {
                        srcImage += '?dummy=' + new Date().getTime();
                    }
                    carousel.insertAdjacentHTML(
                        "afterbegin",
                        ' <div class="carousel__item" style=" height: 100%; width: 100%;"> ' +
                        '<img id="img" style=" width: 100%; object-fit: cover; max-height: 100%;" src="' + srcImage + '"  alt="">  ' +
                        '<div style="width: 100%; position: absolute; bottom: 0; left: 0; text-align: center;" class="hidden-xs hidden-sm"> ' +
                        '<div class="carousel__title col-md-5">' + counter + 'º Banner <span class="carrousel__banner__name"> - ' + name + ' </span> ' +
                        '<span class="fa fa-edit" style="z-index: 10; color: #5b5b5b !important;"> </span> ' +
                        '<span class="fa fa-trash" style="z-index: 10; color: #5b5b5b !important; margin-left: 8px"> </span> </div> </div> </img> </div> '
                    );
                    counter--;
                });

                var items = carousel.querySelectorAll(".carousel__item");

                var buttonsHtml = Array.from(items, function () {
                    return '<span class="carousel__button"></span>';
                });

                carousel.insertAdjacentHTML(
                    "beforeend",
                    ' <div class="carousel__nav">' + buttonsHtml.join("") + ' </div> ' );

                var carouselButtons = carousel.querySelectorAll(".carousel__button");
                carouselButtons.forEach(function (button, i) {
                    button.addEventListener("click", function() {
                        // un-select all the items
                        items.forEach(function (item) {
                            item.classList.remove("carousel__item--selected")
                        });
                        carouselButtons.forEach(function (button) {
                            button.classList.remove("carousel__button--selected")
                        });

                        items[i].classList.add("carousel__item--selected");
                        button.classList.add("carousel__button--selected");
                        vm.selectedBanner = i;
                    });
                });

                // Select the first item on page load
                items[0].classList.add("carousel__item--selected");
                carouselButtons[0].classList.add("carousel__button--selected");

                // ARROW BUTTONS
                if (reversedBanners.length > 1) {
                    carousel.insertAdjacentHTML(
                        "beforeend",
                        ' <div class="backward_button arrow_buttons col-md-1"> <i class="fa fa-chevron-left carousel_arrows"></i> </div> ' );

                    carousel.insertAdjacentHTML(
                        "beforeend",
                        ' <div class="forward_button arrow_buttons col-md-1" style="margin-left: 91%"> <i class="fa fa-chevron-right carousel_arrows"></i> </div> ' );

                    var backwardButton = carousel.querySelectorAll(".backward_button");
                    backwardButton.forEach(function (button, i) {
                        button.addEventListener("click", function (){
                            // un-select all the items
                            items.forEach(function (item) {
                                item.classList.remove("carousel__item--selected")
                            });
                            carouselButtons.forEach(function (button) {
                                button.classList.remove("carousel__button--selected")
                            });

                            var index = vm.selectedBanner -= 1;
                            if (index < 0) {
                                index = 0;
                            }
                            vm.selectedBanner = index;
                            items[index].classList.add("carousel__item--selected");
                            carouselButtons[index].classList.add("carousel__button--selected");
                        });
                    });

                    var forwardButton = carousel.querySelectorAll(".forward_button");
                    forwardButton.forEach(function (button, i) {
                        button.addEventListener("click", function () {
                            // un-select all the items
                            items.forEach(function (item) {
                                item.classList.remove("carousel__item--selected")
                            });
                            carouselButtons.forEach(function (button){
                                button.classList.remove("carousel__button--selected")
                            });

                            var index = vm.selectedBanner += 1;
                            if (index > reversedBanners.length - 1) {
                                index = reversedBanners.length - 1;
                            }
                            vm.selectedBanner = index;
                            items[index].classList.add("carousel__item--selected");
                            carouselButtons[index].classList.add("carousel__button--selected");
                        });
                    });
                }


                vm.selectedBanner = 0;

                // BUTTON ACTIONS
                var editButtons = carousel.querySelectorAll(".fa-edit");
                editButtons.forEach(function (button, i) {
                    button.addEventListener("click", function() {
                        openBannersModal();
                    });
                });

                var trashButtons = carousel.querySelectorAll(".fa-trash");
                trashButtons.forEach(function (button, i) {
                    button.addEventListener("click", function(){
                        removeBanner(i);
                    });
                });

            }
        }

        function openBannersModal() {
            vm.openAddBannersModal();
        }

        function removeBanner(index) {
            var carousel = document.getElementById("carousel");
            var height = carousel.clientHeight;
            carousel.style.minHeight = height + "px";

            if (vm.banners.length > 1) {
                vm.banners.splice(index, 1);
                if (vm.showcase.banners) {
                    vm.showcase.banners.splice(index, 1);
                }
                loadCarousel();
            }
        }

        var handleFileSelect = function (evt) {

            var file = evt.currentTarget.files[0];
            var reader = new FileReader();
            reader.onload = function (evt) {
                $scope.$apply(function ($scope) {
                    vm.imageUpload = evt.target.result;
                    loadImageToCroppieByBase64(evt.target.result);
                });
            };
            reader.readAsDataURL(file);
        };


        vm.initShowcase = function () {
            angular.element(document.querySelector('#fileInput')).on('change', handleFileSelect);
        };

        var getBase64Image = function (url) {
            var img = new Image();
            img.setAttribute('crossOrigin', 'anonymous');
            img.onload = function () {
                var canvas = document.createElement("canvas");
                canvas.width = img.width;
                canvas.height = img.height;
                var ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0);
                var dataURL = canvas.toDataURL("image/png");
                bagDefaultImgData = dataURL;
            }
            img.src = url
        }

        vm.saveCover = function (callback) {
            vm.showcase.addImageFileDTO = [];
            if(vm.banners && vm.banners.length == 0) {
                vm.banners = [{'image': bagDefaultImgData, 'name': '', 'link': ''}];
            }

            var arr = Array.from(Array(vm.banners.length).keys());
            var aux = [];
            var index = 0;
            var orderNumber = 0;
            var blob = dataURItoBlob(vm.banners[index].image);
            var mobileImage = vm.banners[index].imageMobile;
            saveFlyerMultiImage(blob, arr, aux, index, orderNumber, mobileImage, callback);
        }

        function saveFlyerMultiImage(blob, arr, aux, i, orderNumber, hasMobileImage, callback) {
            if (i === vm.banners.length) return;

            hasMobileImage = vm.banners[i].imageMobile;
            blob = dataURItoBlob(vm.banners[i].image);

            FlyersService.saveFlyerMultiImage(blob, i).then(function (response) {
                $timeout(function () {
                    if (response.data) {
                        aux.push('');
                        vm.showcase.hasCover = true;
                        if (hasMobileImage) {
                            var blobImageMobile = dataURItoBlob(vm.banners[i].imageMobile);
                            orderNumber = orderNumber + 1;
                            FlyersService.saveFlyerMultiImage(blobImageMobile, orderNumber).then(function (responseMobile) {
                                if (responseMobile) {
                                    response.data.mobileImageFile = responseMobile.data;
                                    arr[response.data.orderNumber] = response.data;
                                    if (aux.length === vm.banners.length) {
                                        vm.showcase.addImageFileDTO = arr;
                                        callback();
                                    }
                                    i = i + 1;
                                    saveFlyerMultiImage(blob, arr, aux, i, orderNumber, hasMobileImage, callback);
                                }
                            });
                        } else {
                            response.data.hasMobileImage = false;
                            arr[response.data.orderNumber] = response.data;
                            orderNumber = orderNumber + 1;
                            if (aux.length === vm.banners.length) {
                                vm.showcase.addImageFileDTO = arr;
                                callback();
                            }
                            i = i + 1;
                            saveFlyerMultiImage(blob, arr, aux, i, orderNumber, hasMobileImage, callback);
                        }
                    } else {
                        toastr.error($translate.instant('flyers.messages.create.error'));
                    }
                    vm.loadingImage = false;
                });
            }, function (response) {
                toastr.error($translate.instant('flyers.messages.create.image.uploadError'));
                vm.loadingImage = false;
            });
        }

        function dataURItoBlob(dataURI) {
            var byteString;
            if (dataURI.split(',')[0].indexOf('base64') >= 0)
                byteString = atob(dataURI.split(',')[1]);
            else
                byteString = unescape(dataURI.split(',')[1]);
            var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
            var ia = new Uint8Array(byteString.length);
            for (var i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
            }
            return new Blob([ia], {
                type: mimeString
            });
        }

        vm.changePage = function () {

            var begin = ((vm.currentPage - 1) * vm.numPerPage),
            end = begin + vm.numPerPage;

            vm.paginationOptions.page = vm.currentPage - 1;

            vm.filterFlyers();
            vm.startRegistry = begin + 1;
            vm.endRegistry = end;

        }

        window.onDesignPublishCallback = function (data) {
            var exportUrl = data.exportUrl;
            var designId = data.designId;
            loadImageToCroppieByURLCanvas(exportUrl);
            //sendDataViaAjax (exportUrl, designId);
        }

        $scope.$on("$destroy", function () {
            if (!angular.isUndefined(userInteractModal)) {
                userInteractModal.close();
            }
        });

        vm.changeCustomer = function (value) {
            if (value) {
                vm.customer.fullName = value.trim();
            } else {
                vm.customer.fullName = null;
            }
        }

        vm.selectedCustomer = function (item) {
            if (item) {
                var customerFinded = vm.customers.find(function (c) { return c.id == item.originalObject.id });
                if (!customerFinded) {
                    vm.customers.push(item.originalObject)
                }
            }
        }

        vm.changeUser = function (value) {
            if (value) {
                vm.user.fullName = value.trim();
            } else {
                vm.user.fullName = null;
            }
        }

        vm.selectedUser = function (item) {
            if (item) {
                var userFinded = vm.users.find(function (c) { return c.id == item.originalObject.id });
                if (!userFinded) {
                    vm.users.push(item.originalObject)
                }
            }
        }

        vm.removeUser = function (user) {
            vm.users = vm.users.filter(function(item) { return item.id != user.id })
        }

        vm.requestParams = function (query) {
            return {
                page: 0,
                size: 10,
                storeId: storeId,
                search: query
            }
        }

        vm.removeCustomer = function (customer) {
            vm.customers = vm.customers.filter(function(item) { return item.id != customer.id })
        }

        vm.createCustomer = function () {
            if (vm.customer.fullName && vm.customer.phone) {
                vm.customer.storeId = storeId;
                CustomerService.create(vm.customer).then(function(result) {
                    toastr.success($translate.instant('bag.success.customer-save'))
                    vm.customers.push(result.data);
                    vm.customer = {};
                    vm.clearAutocompleteField();
                })
            }
        }

         vm.clearAutocompleteField = function() {
            var input = document.getElementById("customer_value");
            input.value = "";
        }

        function formatModalFilters(filters) {
            if (!filters) return null;

            var sizes = [];
            if (filters.sizes.length > 0) {
                filters.sizes.map(function(size) { sizes.push(size.label) });
                filters.sizes = sizes;
            }

            vm.selectedFeatured = [];
            if(filters.configurations.length > 0) {
                var configurations = [];
                filters.configurations.map(function(config) { configurations.push(config.code)});
                if(configurations.includes('launch')) {
                    filters.isLaunch = true;
                    vm.selectedFeatured.push('launch');
                }
                if(configurations.includes('basic')) {
                    filters.isBasic = true;
                    vm.selectedFeatured.push('basic');
                }
            } else {
                filters.isLaunch = false;
                filters.isBasic = false;
            }

            var genders = [];
            if (filters.genders.length > 0) {
                filters.genders.map(function(gender) { genders.push(gender.id) });
                filters.genders = genders;
            }

            var brands = [];
            if (filters.brands.length > 0) {
                filters.brands.map(function(brand) { brands.push(brand.label)});
                filters.brands = brands;
            }

            var colors = [];
            if (filters.colors.length > 0) {
                filters.colors.map(function(color) { colors.push(color.label) });
                filters.colors = colors;
            }

            var categories = [];
            if (filters.categories.length > 0) {
                filters.categories.map(function(category) { categories.push(category.key) });
                filters.categories = categories;
            }

            var tags = [];
            if (filters.tags.length > 0) {
                filters.tags.map(function(tag) { tags.push(tag.label) });
                filters.tags = tags;
            }

            return filters;
        }

        function formatOptionsFilters() {
            var sizes = [];
            vm.sizes = vm.sizes.map(function(size) {sizes.push(size.label)});
            vm.sizes = sizes;

            var colors = [];
            vm.colors = vm.colors.map(function(color) {colors.push(color.label)});
            vm.colors = colors;

            var tags = [];
            vm.tags = vm.tags.map(function(tag) {tags.push(tag.label)});
            vm.tags = tags;
        }


        vm.openFiltersModal = function () {
            vm.filtersConversationModal = $uibModal.open({
                templateUrl: 'app/admin/flyers/modal-filter-flyer/flyer-filter-modal.html',
                controller: 'FiltersFlyersController',
                windowClass: 'modal-center',
                windowTopClass: 'center-modal',
                controllerAs: 'vm',
                size: 'lg',
                keyboard: false,
                resolve: {
                    options: function () {
                        return {
                            categories: vm.categories,
                            brands: vm.brands,
                            colors: vm.colors,
                            sizes: vm.sizes,
                            tags: vm.tags,
                            canChangeView: false,
                            isBagFilter: true
                        }
                    },
                    filters: function () {
                        var configurations = [];
                        if(vm.filters.isBasic) configurations.push({"label":"flyers.new.form.configurations.basic","id":2, "code": "basic"})
                        if(vm.filters.isLaunch) configurations.push({"label":"flyers.new.form.configurations.launch","id":1, "code": "launch"})
                        vm.filters.configurations = configurations;
                        return vm.filters
                    },
                    list: function () {
                        return vm.listFilter
                    }
                },
            }).result.then(function (response) {
                formatOptionsFilters();
                if (response) {
                    vm.numberFilterSelected = response.totalFilters;
                    vm.filters = formatModalFilters(response.selectedFilters);
                    loadFlyers();
                }
            });
        }

        vm.openAddBannersModal = function () {
            vm.filtersConversationModal = $uibModal.open({
                templateUrl: 'app/admin/showcases/banner-modal/banners_modal.html',
                controller: 'BannersModalController',
                windowClass: 'modal-center',
                windowTopClass: 'center-modal',
                controllerAs: 'vm',
                size: 'lg',
                keyboard: false,
                resolve: {
                    banners: function () {
                        return vm.banners
                    }
                },
            }).result.then(function (response) {
                if (response) {

                    var carousel = document.getElementById("carousel");
                    var height = carousel.clientHeight;
                    carousel.style.minHeight = height + "px";

                    vm.banners = response.banners;
                    vm.haveBanners = true;
                    loadCarousel();
                }
            });
        }

        vm.getCurrencyPrefix = function(flyer) {
            var key = flyer.currency ? flyer.currency : 'REAL';
            var config = getCurrencyListConfiguration()[key];
            return config.prefix;
        }
    }
})();
