(function() {
    'use strict';
    angular
        .module('showcaseApp')
        .service('ChatUnreadMessageService', ChatUnreadMessageService);

        ChatUnreadMessageService.$inject = ['ChatUnreadMessageFactory'];

    function ChatUnreadMessageService(ChatUnreadMessageFactory) {

        var service = {
            getChatUnreadMessage: getChatUnreadMessage,
            updateChatUnreadMessage: updateChatUnreadMessage,
            getUnreadMessagesSum: getUnreadMessagesSum
        };

        return service;

        function getChatUnreadMessage(chatId, body){
            return ChatUnreadMessageFactory.getChatUnreadMessage({chatId: chatId}, body).$promise;
        }

        function updateChatUnreadMessage(id, body){
            return ChatUnreadMessageFactory.updateChatUnreadMessage({id: id}, body).$promise;
        }

        function getUnreadMessagesSum(socialNetwork){
             return ChatUnreadMessageFactory.getUnreadMessagesSum({socialNetwork: socialNetwork}).$promise;
        }

    }

})();
