(function() {
    'use strict';

    angular.module('showcaseApp').factory('InstagramLoginModalService', InstagramLoginModalService);

    function InstagramLoginModalService($uibModal) {
        var openLoginModal = function openLoginModal() {
            return $uibModal.open({
                templateUrl: 'app/components/modal/instagram-login/instagram-login-modal.html',
                controller: 'InstagramLoginModalController',
                controllerAs: 'vm',
                size: 'md',
                windowClass: 'open-modal-in-forward'
            });
        };

        return {
            openLoginModal: openLoginModal
        }
    }
})();