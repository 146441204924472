(function () {
    'use strict';

    angular
        .module('showcaseApp')
        .controller('MainRegisterController', MainRegisterController);


    function MainRegisterController($state, $translate, $stateParams, $timeout, $location, Auth, toastr, UserService, moment, $scope, Principal, DirectRegisterService, $sessionStorage) {
        var format = 'L';

        toastr.options = {
            closeButton: true
        };

        var vm = this;
        getAndSaveHubToken();
        function getAndSaveHubToken() {
            if ($location.search().client) {
                $sessionStorage.clientHub = $location.search().client;
				vm.hubToken = $sessionStorage.clientHub;
            }else{
				vm.hubToken = $sessionStorage.clientHub;
			}
        }
		vm.urlFromAccount = window.location.href;
        vm.maskLocale = '99/99/9999';

        vm.error = null;
        vm.register = register;
        vm.success = null;
        vm.getGenderKey = getGenderKey;
        vm.checkPasswords = checkPasswords;
        vm.dateTimePicker = dateTimePicker();

        $timeout(function () {
            angular.element('#login').focus();
        });

        loadAll();

        function loadAll() {
            loadUserGenders();

            if (angular.isUndefined(vm.account)) {
                vm.account = {};
            }
            if ($stateParams.email) {
                vm.account.email = $stateParams.email;
            }
        }

        vm.validDate = function validDate(dateHour) {
            var dateHourInput = moment(dateHour.$viewValue, format).isValid() ? moment.utc(dateHour.$viewValue, format) : undefined;
            if (dateHourInput) {
                if (dateHourInput.isAfter(moment.utc(moment().format(), 'YYYY-MM-DDTHH:mm').toDate())) {
                    vm.account.dateOfBirth = undefined;
                    return;
                }
                vm.account.dateOfBirth = dateHour.$viewValue;
                vm.form.dateOfBirth.$setValidity('maxDate', true);
            } else {
                vm.account.dateOfBirth = undefined;
            }
        }

        function checkPasswords() {
            if ((vm.account.password != vm.account.confirmPassword) && vm.account.confirmPassword) {
                vm.form.confirmPassword.$setValidity('notEquals', false);
            } else {
                vm.form.confirmPassword.$setValidity('notEquals', true);
            }
        }

        vm.checkUserName = function () {
            if (vm.account.login) {
                Principal.isAvaliableUsername(vm.account.login).then(function (response) {
                    vm.form.login.$setValidity('usernameAlreadyUsed', response.data);
                });
            } else {
                vm.form.login.$setValidity('usernameAlreadyUsed', true);
            }
        }

        vm.checkEmail = function () {
            var emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
            if (vm.account.email && emailPattern.test(vm.account.email)) {
                Principal.isAvaliableEmail(vm.account.email).then(function (response) {
                    vm.form.email.$setValidity('emailAlreadyUsed', response.data);
                });
            } else {
                vm.form.email.$setValidity('emailAlreadyUsed', true);
            }
        }

        function register() {
            if (vm.form.$valid) {
                vm.isSaving = true;
                var accountToSave = angular.copy(vm.account);
                accountToSave.langKey = $translate.use();
                vm.error = null;
                accountToSave.hubToken = vm.hubToken;
				accountToSave.urlFrom = vm.urlFromAccount;
                Auth.createAccount(accountToSave).then(function () {
                    vm.gender = null;
                    //vm.success = 'OK';
                    vm.isSaving = false;
                    Auth.login({
                        usernameOrEmail: vm.account.email,
                        password: vm.account.password,
                        rememberMe: true
                    }).then(function () {
                        $state.go('store-info');
                    });
                }).catch(function (response) {
                    vm.isSaving = false;
                    vm.success = null;
                    if (response.status === 400 && response.data === 'login already in use') {
                        toastr.warning($translate.instant('register.messages.error.userexists'), toastr.options)
                    } else if (response.status === 400 && response.data === 'e-mail address already in use') {
                        toastr.warning($translate.instant('register.messages.error.emailexists'), toastr.options)
                    } else {
                        vm.error = 'ERROR';
                    }
                });
            }
        }

        function loadUserGenders() {
            UserService.listUserGenders().then(function (genders) {
                vm.genders = genders;
            });
        }

        function getGenderKey(value) {
            return "register".concat(".").concat(value);
        }

        function dateTimePicker() {
            vm.dateOfBirthOptions = {
                format: format,
                sideBySide: true,
                ignoreReadonly: true,
                locale: moment.locale(),
                allowInputToggle: true,
                showClear: true,
                useCurrent: false,
                defaultDate: moment(),
                maxDate: moment(),


            };
        }

        vm.loginNewAccount = function() {
            $state.go('main-login', {'email': vm.account.email, 'password': vm.account.password});
        }

    }
})();
