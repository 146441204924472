(function() {
	'use strict';

	angular.module('showcaseApp').factory('BankDataFactory', BankDataFactory);

	BankDataFactory.$inject = [ '$resource' ];

	function BankDataFactory($resource) {
		return $resource('api/bank-data/:id', {}, {
			'create' : {
				method : 'POST',
				transformResponse : function(data, headers) {
					var response = {};
					if (data) {
						response.data = angular.fromJson(data);
					}
					response.headers = headers();
					return response;
				}
			},
			'update' : {
				method : 'PUT'
			},
			'getBanks' : {
				method : 'GET',
				isArray : true,
				url : 'api/account/get_banks',
				transformResponse : function(data) {
					if (data) {
						data = angular.fromJson(data);
					}
					return data;
				}
			}
		});
	}
})();
