(function () {
    'use strict';
    angular
        .module('showcaseApp')
        .factory('HubCampaignFactory', HubCampaignFactory);

    HubCampaignFactory.$inject = ['$resource'];

    function HubCampaignFactory($resource) {
        var resourceUrl = 'flyer/api/hub-campaign';

        return $resource(resourceUrl, {}, {
            'listAll': {
                method: 'POST',
                url: resourceUrl + '/hub/:hubId',
                transformResponse: transformResponse
            },
            'getOne': {
                method: 'GET',
                url: resourceUrl + '/:id',
                transformResponse: transformResponse
            },
            'create': {
                method: 'POST',
                url: resourceUrl,
                transformResponse: transformResponse
            },
            'update': {
                method: 'PUT',
                url: resourceUrl + '/:id',
                transformResponse: transformResponse
            },
            'remove': {
                method: 'DELETE',
                url: resourceUrl + '/:id',
                transformResponse: transformResponse
            },
            'distribute': {
                method: 'POST',
                url: resourceUrl + '/:id/distribute'
            }
        });

        function transformResponse(data, headers) {
            var response = {};
            response.data = angular.fromJson(data);
            response.headers = headers();
            return response;
        }
    }
})();
