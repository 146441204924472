(function() {
    'use strict';

    angular
        .module('showcaseApp')
        .controller('ConfirmActionModalController', ConfirmActionModalController);

    ConfirmActionModalController.$inject = ['$scope', '$uibModal', '$uibModalInstance'];

    function ConfirmActionModalController($scope, $uibModal, $uibModalInstance) {
        var vm = this;

        vm.confirm = function() {
            $uibModalInstance.close(true);
        }

        vm.closeModal = function() {
            $uibModalInstance.dismiss();
        }
    }
})();
