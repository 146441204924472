(function() {
    'use strict';

    angular
        .module('showcaseApp')
        .factory('PaymentSettings', PaymentSettings);

    PaymentSettings.$inject = ['Account', 'PaymentSettingsFactory'];

    function PaymentSettings(Account, PaymentSettingsFactory) {

        var service = {
            getGatewayWirecardPaymentLinks: getGatewayWirecardPaymentLinks,
            setCodeAndSaveAccessToken: setCodeAndSaveAccessToken,
            disconnectFromPaymentGatewayWirecard: disconnectFromPaymentGatewayWirecard,
            getInstallmenstsByStore: getInstallmenstsByStore,
            addInstallmensts: addInstallmensts,
            setRecipient: setRecipient,
            updateRecipient: updateRecipient,
            getRecipient: getRecipient,
            getOwnerKeys: getOwnerKeys,
            getBalance: getBalance,
            setCheckoutMethod: setCheckoutMethod
        };

        return service;

        function getGatewayWirecardPaymentLinks() {
            return Account.getGatewayWirecardPaymentLinks().$promise;
        }

        function setCodeAndSaveAccessToken(code) {
            return Account.setCodeAndSaveAccessToken({
                code: code
            }).$promise
        }

        function disconnectFromPaymentGatewayWirecard() {
            return Account.disconnectFromPaymentGatewayWirecard().$promise
        }

        function getInstallmenstsByStore() {
            return PaymentSettingsFactory.getInstallmenstsByStore().$promise;
        }

        function addInstallmensts(addInstallmensts) {
            return PaymentSettingsFactory.saveInstallments(addInstallmensts).$promise;
        }
        function setRecipient(pagarmeForm, storeId) {
            return Account.setRecipient({ ownerId : storeId}, pagarmeForm).$promise
        }

        function updateRecipient(pagarmeForm, storeId) {
            return Account.updateRecipient({ ownerId : storeId}, pagarmeForm).$promise
        }

        function getRecipient(storeId) {
            return Account.getRecipient({ ownerId : storeId}).$promise
        }

        function getBalance(storeId, recipientId) {
            return Account.getBalance({ ownerId : storeId, recipientId: recipientId}).$promise
        }

        function setCheckoutMethod(storeId, checkoutMethod) {
            return Account.setCheckoutMethod({ ownerId : storeId, paymentMethod: checkoutMethod}).$promise
        }

        function getOwnerKeys(storeId) {
            return Account.getOwnerKeys({storeId: storeId}).$promise;
        }

    }
})();
