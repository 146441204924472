/**
 * Google My Business service
 * author: Odravison Amaral (odravison.amaral@dce.ufpb.br)
 * 2019
 */

(function() {
	'use strict';

	angular.module('showcaseApp').service('GMBService', GMBService);

	GMBService.$inject = [ 'GMBFactory' , 'BusinessIntegrationFactory'];

	function GMBService(GMBFactory, BusinessIntegrationFactory) {
		var service = {
			authorize : authorize,
			signOut: signOut,
			receiveCode: receiveCode,
			getConfiguration: getConfiguration,
			selectAccount: selectAccount,
			getGMBCategoriesList: getGMBCategoriesList,
			getGMBLocationList: getGMBLocationList,
			selectGMBLocation: selectGMBLocation,
            getGMBInsights: getGMBInsights,
            getGMBQuestions: getGMBQuestions
		};

		return service;

		function authorize(callbackOfflineAccess, callbackOfflineAccessError) {
			return GMBFactory.authorize(callbackOfflineAccess, callbackOfflineAccessError);
		}

		function signOut(params, callback, errorCallback) {
			BusinessIntegrationFactory.disconnectByType(params, {}).$promise.then(callback, errorCallback);
			return GMBFactory.signOut();
		}

		function receiveCode (params, token, callback, errorCallback) {
			BusinessIntegrationFactory.receiveCode(params, token).$promise.then(callback, errorCallback);
		}

		function selectAccount (params, body, callback, errorCallback) {
			BusinessIntegrationFactory.selectAccount(params, body).$promise.then(callback, errorCallback);
		}

		function selectGMBLocation (params, body, callback, errorCallback) {
			BusinessIntegrationFactory.selectGMBLocation(params, body).$promise.then(callback, errorCallback);
		}

		function getGMBCategoriesList (body, callback, errorCallback) {
			return BusinessIntegrationFactory.getGMBCategoriesList(body).$promise;
		}

		function getGMBLocationList (params, callback, errorCallback) {
			BusinessIntegrationFactory.getGMBLocationList(params).$promise.then(callback, errorCallback);
		}

		function getConfiguration (params, callback, errorCallback) {
			BusinessIntegrationFactory.getConfiguration(params).$promise.then(callback, errorCallback);
		}

        function getGMBInsights(params, callback, errorCallback) {
            return BusinessIntegrationFactory.getGMBInsights(params).$promise.then(callback, errorCallback);
        }

        function getGMBQuestions(params, callback, errorCallback) {
            return BusinessIntegrationFactory.getGMBQuestions(params).$promise.then(callback, errorCallback);
        }

	}
})();
