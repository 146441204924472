(function() {
    'use strict';

    angular
        .module('showcaseApp')
        .controller('SyncWithHubModalController', SyncWithHubModalController);

    function SyncWithHubModalController($uibModalInstance, modalTitle, modalMessage, smallText, warningText, 
        checkBoxes, buttonConfirmText, buttonCancelText, buttonConfirmAction) {
        var vm = this;

        vm.modalTitle = modalTitle;
        vm.modalMessage = modalMessage;
        vm.smallText = smallText;
        vm.warningText = warningText;
        vm.checkBoxes = checkBoxes;
        vm.buttonConfirmText = buttonConfirmText;
        vm.buttonCancelText = buttonCancelText;
        vm.buttonConfirmAction = action;

        vm.isConfirmButtonEnabled = function () {
            return vm.checkBoxes
                    .filter(function(checkbox) { return checkbox.value }).length > 0;
        }

        vm.confirm = function() {
            $uibModalInstance.close(true);
        }

        vm.closeModal = function() {
            $uibModalInstance.dismiss(true);
        }

        function action() {
            var params = [];
            if (vm.checkBoxes) {
                params = vm.checkBoxes
                    .filter(function(checkbox) { return checkbox.value })
                    .map(function(checkbox) { return checkbox.syncParam });
            }
            buttonConfirmAction(params);
            vm.confirm();
        }
    }
})();
