(function() {
    'use strict';
    angular
        .module('showcaseApp')
        .service('HubFlyersService', HubFlyersService);

    HubFlyersService.$inject = ['HubFlyersFactory', '$localStorage', 'Upload'];

    function HubFlyersService(HubFlyersFactory, $localStorage, Upload) {

        var service = {
            createFlyer: createFlyer,
            saveFlyerImage: saveFlyerImage,
			saveFlyerMultiImage: saveFlyerMultiImage,
            deleteFlyers: deleteFlyers,
            listFlyers: listFlyers,
            listFlyersPage: listFlyersPage,
            listFlyersFiltered: listFlyersFiltered,
            updateFlyer: updateFlyer,
            getFlyer: getFlyer,
            getNumberOfFlyers: getNumberOfFlyers,
            getFlyerTypes: getFlyerTypes,
            listAllFlyers: listAllFlyers,
            getImageFlyer: getImageFlyer,
            getLinkImageFlyerAsString: getLinkImageFlyerAsString,
            listAllToAdmin: listAllToAdmin,
            listFlyersPageToModal: listFlyersPageToModal,
            flyersImportStatus: flyersImportStatus,
            listAllFlyersByHub: listAllFlyersByHub,
            listAllFlyersByBrand: listAllFlyersByBrand,
            importFlyersFromVtex: importFlyersFromVtex
        };

        return service;

        function createFlyer(flyer) {
            return HubFlyersFactory.save(flyer).$promise;
        }

        function listFlyers(pageable, filter, onSuccess, onError) {
            return HubFlyersFactory.listAll(pageable, filter, onSuccess, onError);
        }

        function listAllToAdmin(pageable, filter, onSuccess, onError) {
            return HubFlyersFactory.listAllToAdmin(pageable, filter, onSuccess, onError);
        }

        function listFlyersPage(pageable, filter, onSuccess, onError) {
            return HubFlyersFactory.listAllPageable(pageable, filter, onSuccess, onError);
        }

        function listFlyersFiltered(pageable, filter, onSuccess, onError) {
            return HubFlyersFactory.listAllFiltered(pageable, filter, onSuccess, onError);
        }

        function listFlyersPageToModal(pageable) {
            return HubFlyersFactory.listAllPageableToModal(pageable, { storeId: $localStorage.defaultStore.storeId }).$promise;
        }

        function deleteFlyers(flyerIds) {
            return HubFlyersFactory.deletebyids({flyerIds: flyerIds}).$promise;
        }

        function getFlyer(flyer) {
            return HubFlyersFactory.get({flyerId: flyer, storeId: $localStorage.defaultStore.storeId}).$promise;
        }

        function updateFlyer(flyer) {
            return HubFlyersFactory.update(flyer).$promise;
        }

        function saveFlyerImage(image) {
            return Upload.upload({url: 'zuul/flyer/api/upload', data: {file: image}});
        }

        function saveFlyerMultiImage(image, orderNumber) {
            return Upload.upload({url: 'zuul/flyer/api/multiUpload', data: {file: image, orderNumber: orderNumber}});
        }

        function getNumberOfFlyers(storeId) {
            return HubFlyersFactory.numberOfFlyers({storeId: storeId}).$promise;
        }

        function getFlyerTypes(storeId) {
            return HubFlyersFactory.getFlyerTypes().$promise;
		}

        function listAllFlyers() {
            return HubFlyersFactory.listAllFlyers().$promise;
        }

        function getImageFlyer(flyerId) {
            return HubFlyersFactory.flyerImage({id: flyerId}).$promise;
        }

        function getLinkImageFlyerAsString(flyerId) {
            return HubFlyersFactory.flyerLinkImageAsString({id: flyerId}).$promise;
        }

        function flyersImportStatus(importId) {
            return HubFlyersFactory.flyersImportStatus({id: $localStorage.hub.id, importId: importId}).$promise;
        }

        function listAllFlyersByHub() {
            return HubFlyersFactory.listAllFlyersByHub().$promise;
        }

        function listAllFlyersByBrand(brand) {
            return HubFlyersFactory.listAllFlyersByBrand({brand: brand}).$promise;
        }

        function importFlyersFromVtex(hub) {
            var param = {
                hub: hub
            }
            return HubFlyersFactory.importFlyersFromVtex(param).$promise;
        }

    }

})();
