(function() {
    'use strict';

    angular
        .module('showcaseApp')
        .factory('ShippingService', ShippingService);

        ShippingService.$inject = ['ShippingSettingsFactory'];

    function ShippingService(ShippingSettingsFactory) {

        var service = {
            disconnectFromShippingGateway: disconnectFromShippingGateway,
            addCustomShipping: addCustomShipping,
            getCustomShipping: getCustomShipping,
            updateCustomShipping: updateCustomShipping,
            getCustomShippingList: getCustomShippingList,
            getCustomShippingById: getCustomShippingById,
            deleteCustomShipping: deleteCustomShipping
        };

        return service;

        function disconnectFromShippingGateway(storeName) {
            return ShippingSettingsFactory.diconnect({store: storeName}).$promise
        }

        function addCustomShipping(customShipping) {
            return ShippingSettingsFactory.saveCustomShipping(customShipping).$promise;
        }

        function getCustomShipping(storeId) {
            return ShippingSettingsFactory.getCustomShipping({storeId: storeId}).$promise;
        }

        function updateCustomShipping(customShipping) {
            return ShippingSettingsFactory.updateCustomShipping({customShippingId: customShipping.id}, customShipping).$promise;
        }

        function getCustomShippingList(storeId) {
            return ShippingSettingsFactory.getCustomShippingList({storeId: storeId}).$promise;
        }

        function getCustomShippingById(id) {
            return ShippingSettingsFactory.getCustomShippingById({id: id}).$promise;
        }

        function deleteCustomShipping(id) {
            return ShippingSettingsFactory.deleteCustomShipping({ id: id }).$promise;
        }
    }
})();
