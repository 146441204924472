(function() {
    'use strict';
    angular
        .module('showcaseApp')
        .factory('FlyersFactory', FlyersFactory);

    FlyersFactory.$inject = ['$resource'];

    function FlyersFactory($resource) {
        var resourceUrl =  'flyer/api/flyer/:flyerId';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data){
                        response.data = angular.fromJson(data);
                    } else{
                        response.data = data;
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'numberOfFlyers': {
                method: 'GET',
                url: 'flyer/api/store/:storeId/numberOfFlyers',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data){
                        try {
                            response.data = angular.fromJson(data);
                        } catch (err) {}
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'getFlyerTypes': {
                method: 'GET',
                url: 'flyer/api/flyer/getFlyerTypes',
                // isArray: true,
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data){
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'deletebyids': {
                method: 'POST',
                url: '/flyer/api/flyer/deletebyids'
            },
            'verifyIfFlyersIsPublished': {
                method: 'POST',
                url: '/flyer/api/flyer/verify-flyer-is-published'
            },
            'fileUpload': {
                method: 'POST',
                url: '/flyer/api/flyer/upload'
            },
            'listAll': {
                method: 'POST',
                url: 'flyer/api/flyer/list',
                isArray: true
            },
            'listAllToAdmin': {
                method: 'POST',
                url: 'flyer/api/flyer/list-to-admin',
                cache: false,
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data){
                        response = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'listAllFlyersIds': {
                method: 'POST',
                url: 'flyer/api/flyer/list-flyers-ids',
                cache: false,
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data){
                        response = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'getSizes': {
                method: 'GET',
                url: 'flyer/api/flyer/size',
                cache: false,
                transformResponse: function (data, headers) {
                    var response = {};
                    response.data = angular.fromJson(data);
                    response.headers = headers();
                    return response;
                }
            },
            'listAllFlyers': {
                method: 'GET',
                url: 'flyer/api/flyer/listall',
                isArray: true,
                cache: false
            },
            'listAllPageable': {
                method: 'POST',
                url: 'flyer/api/flyer/list/page',
                cache: false,
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data){
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'listAllPageableToModal': {
                method: 'POST',
                url: 'flyer/api/flyer/list/page/to-modal',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data){
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'flyerImage': {
                method: 'GET',
                url: 'flyer/api/:id/picture',
                cache: false
            },
            'flyerLinkImageAsString': {
                method: 'GET',
                url: 'flyer/api/:id/picture-link-as-string',
                cache: false,
                transformResponse: function (data, headers) {
                    var response = {};
                    response.data = data;
                    response.headers = headers();
                    return response;
                }
            },
            'update': {
                method:'PUT',
                cache: false,
                timeout: 20000,
                transformResponse: function (data, headers) {
                    if(data){
                        var response = {};
                        response.data = angular.fromJson(data);
                        response.headers = headers();
                        return response;
                    }
                }
            },
            'updateFlyerList': {
                method: 'POST',
                url: 'flyer/api/flyer/updateList',
                cache: false,
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data){
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'getColors': {
                method: 'GET',
                url: 'flyer/api/flyer/color',
                cache: false,
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data){
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'flyersImportStatus': {
                method: 'GET',
                url: 'flyer/api/flyer/import-flyers-from-table/:importId',
                cache: false,
                transformResponse: function (data, headers) {
                    var response = {};
                    response.data = data;
                    response.headers = headers();
                    return response;
                }
            },
            'getTypes': {
                method: 'GET',
                url: 'flyer/api/flyer/types',
                cache: false,
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data){
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'getBrands': {
                method: 'GET',
                url: 'flyer/api/flyer/brands',
                cache: false,
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data){
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'getFeatureds': {
                method: 'GET',
                url: 'flyer/api/flyer/featureds',
                cache: false,
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data){
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'getTags': {
                method: 'GET',
                url: 'flyer/api/flyer/tags',
                cache: false,
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data){
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'getGenders': {
                method: 'GET',
                url: 'flyer/api/flyer/genders',
                cache: false,
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data){
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'getAllFiltersFromFlyersIsPublish': {
                method: 'GET',
                url: 'flyer/api/flyer/filters-flyers-publish',
                cache: false,
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data){
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'getAllFiltersFlyer': {
                method: 'GET',
                url: 'flyer/api/flyer/filters-flyers',
                cache: false,
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data){
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'clearFlyersStock': {
                method: 'PUT',
                url: 'flyer/api/flyer/clear-stock',
                cache: false,
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data){
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'applyDiscountsForFlyers': {
                method: 'PUT',
                url: 'flyer/api/flyer/apply-discounts',
                cache: false,
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data){
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'clearFlyersDiscountedValue': {
                method: 'POST',
                url: 'flyer/api/flyer/clear-discounted-value',
                cache: false,
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data){
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'syncFlyersWithHub': {
                method: 'POST',
                url: 'flyer/api/flyer/sync-flyer-with-hub',
                cache: false,
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data){
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'findFlyersByIds': {
                method: 'POST',
                url: 'flyer/api/flyer/find-by-ids',
                cache: false,
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data){
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
        });
    }
})();
