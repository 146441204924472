(function() {
    'use strict';

    angular
        .module('showcaseApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('hub-management', {
                parent: 'admin',
                url: '/hub-management',
                data: {
                    authorities: ['STORE_READ'],
                    pageTitle: 'global.hub.stores-hub'
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/admin/hub-management/hub-management.html',
                        controller: 'HubManagementController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('store-management');
                        $translatePartialLoader.addPart('flyers');
                        $translatePartialLoader.addPart('settings');
                        return $translate.refresh();
                    }]
                }
            })
            .state('store-type', {
                parent: 'hub-management',
                url: '/store-type',
                data: {
                    authorities: ['STORE_READ', 'ADMIN_MANAGE_SYSTEM'],
                    pageTitle: 'store-management.detail.title'
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/admin/hub-management/store-type/store-type-list.html',
                        controller: 'StoreTypeController',
                        controllerAs: 'vm'
                    }
                },
                // params: {
                //     storeId: null
                // },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('store-management');
                        $translatePartialLoader.addPart('profile');
                        $translatePartialLoader.addPart('error');
                        $translatePartialLoader.addPart('user-management');
                        return $translate.refresh();
                    }]
                }
            })
            // .state('store-detail', {
            //     parent: 'store-management',
            //     url: '^/store/detail',
            //     data: {
            //         authorities: ['STORE_READ'],
            //         pageTitle: 'store-management.detail.title'
            //     },
            //     params: {
            //         fromStep: null
            //     },
            //     views: {
            //         'content@app': {
            //             templateUrl: 'app/admin/store-management/store-management-detail.html',
            //             controller: 'StoreManagementDetailController',
            //             controllerAs: 'vm'
            //         }
            //     },
            //     resolve: {
            //         translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
            //             $translatePartialLoader.addPart('store-management');
            //             $translatePartialLoader.addPart('user-management');
            //             $translatePartialLoader.addPart('profile');
            //             return $translate.refresh();
            //         }],
            //         stateParams: ['$stateParams', '$localStorage', function($stateParams, $localStorage) {
            //             $stateParams.id = $localStorage.defaultStore.storeId;
            //             return $stateParams;
            //         }]
            //     }
            // })
            // .state('store-management.addsalespoint', {
            //     url: '/newsalespoint',
            //     data: {
            //         pageTitle: 'store-management.new.title',
            //         authorities: ['STORE_UPDATE']
            //     },
            //     onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
            //         $uibModal.open({
            //             templateUrl: 'app/admin/store-management/store-management-newsalespoint.html',
            //             controller: 'AddSalesPointManagementController',
            //             controllerAs: 'vm',
            //             backdrop: 'static',
            //             size: 'md',
            //             resolve: {}
            //         });
            //     }],
            //     resolve: {
            //         translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
            //             $translatePartialLoader.addPart('store-management');
            //             return $translate.refresh();
            //         }]

            //     }
            // })
            // .state('store-management.new', {
            //     url: '/new',
            //     data: {
            //         pageTitle: 'store-management.new.title',
            //         authorities: ['STORE_CREATE']
            //     },
            //     views: {
            //         'content@app': {
            //             templateUrl: 'app/admin/store-management/store-management-new.html',
            //             controller: 'StoreManagementNewController',
            //             controllerAs: 'vm'
            //         }
            //     },
            //     resolve: {
            //         translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
            //             $translatePartialLoader.addPart('store-management');
            //             $translatePartialLoader.addPart('global');
            //             return $translate.refresh();
            //         }]
            //     }
            // })
            // .state('store-profile-detail', {
            //     parent: 'store-detail',
            //     url: '^/perfil/{id}',
            //     data: {
            //         authorities: ['STORE_READ_PROFILE'],
            //         pageTitle: 'showcaseApp.profile.detail.title'
            //     },
            //     views: {
            //         'content@app': {
            //             templateUrl: 'app/entities/profile/profile-management-detail.html',
            //             controller: 'ProfileDetailController',
            //             controllerAs: 'vm'
            //         }
            //     },
            //     params: {
            //         'storeId': {
            //             value: null
            //         }
            //     },
            //     resolve: {
            //         translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
            //             $translatePartialLoader.addPart('profile');
            //             return $translate.refresh();
            //         }],
            //         previousState: ["$state", function($state) {
            //             var currentStateData = {
            //                 name: $state.current.name || 'profile-management',
            //                 params: $state.params,
            //                 url: $state.href($state.current.name, $state.params)
            //             };
            //             return currentStateData;
            //         }]
            //     }
            // });
    }
})();
