(function() {
    'use strict';

    angular
        .module('showcaseApp')
        .directive('flyersDescriptionCollapse', FlyersDescriptionCollapse);

    FlyersDescriptionCollapse.$inject = ['$compile'];

    function FlyersDescriptionCollapse($compile) {
        return {
            restrict: 'A',
            scope: true,
            link: function(scope, element, attrs) {
                scope.collapsed = false;

                scope.toggle = function() {
                    scope.collapsed = !scope.collapsed;
                };

                attrs.$observe('flyersDescriptionCollapseText', function(text) {
                    scope.text = text;
                    var maxLength = scope.$eval(attrs.flyersDescriptionCollapseMaxLength);

                    if (text.length > maxLength) {
                        var firstPart = String(text).substring(0, maxLength);
                        var firstSpan = $compile('<span>' + firstPart + '</span>')(scope);
                        var moreIndicatorSpan = $compile('<span>... </span>')(scope);
                        var lineSpan = $compile('<br>')(scope);
                        var toggleButton = $compile('<span ng-click="vm.openDescriptionModal()"><span class="flyer-detail-description" data-translate="flyers.detail.fulldescription"></span></span>')(scope);
                        element.empty();
                        element.append(firstSpan);
                        element.append(moreIndicatorSpan);
                        element.append(lineSpan);
                        element.append(toggleButton);
                    }
                    else {
                        element.empty();
                        element.append(text);
                    }
                });
            }
        };
    }
}());
