(function () {
    'use strict';

    angular
        .module('showcaseApp')
        .factory('OnlineRetailService', OnlineRetailService);

    OnlineRetailService.$inject = ['OnlineRetailFactory'];

    function OnlineRetailService(OnlineRetailFactory) {

        var service = {
            getPriceTables: getPriceTables,
            getEntities: getEntities,
            getBankAccounts: getBankAccounts,
            getSellers: getSellers,
            reprocessOnlineRetail: reprocessOnlineRetail,
            getCategoryLevelList: getCategoryLevelList,
            syncProductsByStoreAndFlyerIds: syncProductsByStoreAndFlyerIds
        };

        return service;

        function getPriceTables(token, entities) {
            return OnlineRetailFactory.getPriceTables({ token: token, entities: entities }).$promise;
        }

        function getEntities(token) {
            return OnlineRetailFactory.getEntities({ token: token }).$promise;
        }

        function getBankAccounts(token, entityIds) {
            return OnlineRetailFactory.getBankAccounts({ token: token, entityIds: entityIds }).$promise;
        }

        function getSellers(token) {
            return OnlineRetailFactory.getSellers({ token: token }).$promise;
        }

        function getCategoryLevelList(token) {
            return OnlineRetailFactory.getCategoryLevelList({ token: token }).$promise;
        }

        function reprocessOnlineRetail(id) {
            return OnlineRetailFactory.reprocessOnlineRetail({ id: id }, {}).$promise;
        }

        function syncProductsByStoreAndFlyerIds(storeId, flyerIds) {
            return OnlineRetailFactory.syncProductsByStoreAndFlyerIds({ storeId: storeId }, flyerIds).$promise;
        }
    }
})();
