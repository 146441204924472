(function() {
    'use strict';

    angular
        .module('showcaseApp')
        .controller('ShowcasesFlyersController', ShowcasesFlyersController);

    ShowcasesFlyersController.$inject = ['$state', '$stateParams', '$q', 'paginationConstants', '$translate', 'FlyersService', 'FlyerCategoryService', 'FlyersCurrencyValue', 'ShowcasesService', 'toastr', 'BrandService', '$localStorage', '$uibModal', 'StoreService'];

    function ShowcasesFlyersController($state, $stateParams, $q, paginationConstants, $translate, FlyersService, FlyerCategoryService, FlyersCurrencyValue, ShowcasesService, toastr, BrandService, $localStorage, $uibModal, StoreService) {
        var vm = this;

        vm.currentPage = 1;
        vm.numPerPage = paginationConstants.itemsPerPage;
        vm.maxSize = 5;
        vm.totalFlyers = 0;
        vm.startRegistry = 1;
        vm.endRegistry = vm.numPerPage;

        vm.paginationOptions = {
            page: vm.currentPage - 1,
            size: vm.numPerPage
        };

        vm.listFilter = {
            activated: null,
            hasInventory: true,
            text: ''
        };

        vm.sortOptions = paginationConstants.sortOptions[$translate.use().toLowerCase()];
        vm.selectedSortByOption = "";

        vm.onSelectedSortOption = function () {
            vm.page = 0;
            vm.currentPage = 1;
            vm.paginationOptions = {
                page: vm.currentPage - 1,
                size: vm.numPerPage
            };

            vm.listFilter.sortBy = vm.selectedSortByOption.value;
            vm.listFilter.sortDirection = vm.selectedSortByOption.direction;

            loadFlyers();
        }

        vm.loadFlyers = function (page, reset) {
            loadFlyers();
        };

        vm.isOpenCalendarStart = false;
        vm.isOpenCalendarExpire = false;

        vm.inactiveArray = [];

        vm.flyersFiltered = [];
        vm.page = 0;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.hasNextPage = false;
        vm.busy = false;

        vm.brands = [];
        vm.brandGroups = {};
        vm.selectedAll = false;
        vm.sizes = [];
        vm.colors = [];
        vm.numberFilterSelected = 0;

        loadAll();

        function loadAll() {
            vm.isLoading = true;

            FlyerCategoryService.listCategoriesByCurrentStore($translate.use(), $localStorage.defaultStore.storeId).then(function(response) {
                vm.categories = response.data;
            });

            ShowcasesService.getShowcase($stateParams.showcaseId, $stateParams.ownerId).then(function(data) {
                vm.showcase = data;
                loadTemplate();
                vm.listFilter.storeId = vm.showcase.ownerId;
                BrandService.getBrandsByCurrentStore().then(function(response) {
                    vm.brands = response.data;
                    vm.listFilter.brand = response.data[0].name;
                    loadFlyers();
                });
            });

            FlyersService.getAllFiltersFlyer().then(function (response) {
                vm.colors = response.data.colors;
                vm.sizes = response.data.sizes;
            });
        }

        function loadTemplate() {
            ShowcasesService.getTemplate(vm.showcase.showcaseTemplateId).then(function(response) {
                vm.maxFlyersPerShowcase =  response.quantityOfRows * (12/response.row.items[0].size);;
            });
        }

        vm.reset = function reset() {
            vm.page = 0;
            vm.flyersFiltered = [];
        };

        vm.changePage = function () {
            var begin = ((vm.currentPage - 1) * vm.numPerPage),
            end = begin + vm.numPerPage;

            vm.paginationOptions.page = vm.currentPage - 1;

            loadFlyers();
            vm.startRegistry = begin + 1;
            vm.endRegistry = end;
        };

        function loadFlyers() {
            if(vm.busy) return;
            vm.busy = true;

            if (!vm.listFilter.colors || !(vm.listFilter.colors.length > 0)) {
                delete vm.listFilter.colors
            }

            if (vm.listFilter.configurations && (vm.listFilter.configurations).includes("launch")) {
                vm.listFilter.launch = true;
            } else {
                delete vm.listFilter.launch;
            }

            if (vm.listFilter.configurations && (vm.listFilter.configurations).includes("basic")) {
                vm.listFilter.basic = true;
            } else {
                delete vm.listFilter.basic;
            }

            if (!vm.listFilter.colors || !(vm.listFilter.colors.length > 0)) {
                delete vm.listFilter.colors
            }

            if (!vm.listFilter.sizes || !(vm.listFilter.sizes.length > 0)) {
                delete vm.listFilter.sizes
            }

            if (!vm.listFilter.genders || !(vm.listFilter.genders.length > 0)) {
                delete vm.listFilter.genders
            }

            if (vm.numberFilterSelected === 0 && vm.listFilter.brand && vm.listFilter.brand !== '' && vm.listFilter.text === '') {
                vm.listFilter.brands = [vm.listFilter.brand]
            } else if (!vm.filters.brands || !(vm.filters.brands.length > 0)) {
                delete vm.listFilter.brands
            }

            if (vm.numberFilterSelected === 0 && vm.listFilter.categoryKey && vm.listFilter.categoryKey !== '' && vm.listFilter.text === '') {
                vm.listFilter.categoryKeys = [vm.listFilter.categoryKey]
            } else if (vm.filters.categories.length > 0) {
                vm.listFilter.categoryKey = vm.listFilter.categoryKeys[0]
            } else {
                delete vm.listFilter.categoryKeys
            }

            FlyersService.listFlyersPage(vm.paginationOptions, vm.listFilter, onSuccess, onError);

            function onSuccess(response, headers) {
                var data = response.data.content;
                vm.busy = false;
                vm.totalFlyers = headers('X-Total-Count');
                vm.flyersFiltered = data.length <= 0 ? [] : data;

                for (var i = 0; i < data.length; i++) {
                    vm.getCategoryName(data[i]);
                }
                vm.isLoading = false;
                reduceFlyersToMap();
                refreshInactiveBoxArray();

                vm.makeBrandGroups();
                vm.refreshFlyersSelected();
            }

            vm.makeBrandGroups = function() {
                vm.brandGroups = {};
                vm.showcase.flyers.forEach(function(flyer) {
                    if(flyer.brand){
                        vm.brandGroups[flyer.brand] = vm.brandGroups[flyer.brand] ? vm.brandGroups[flyer.brand] + 1 : 1;
                    }
                });
            }

            function onError() {
                vm.busy = false;
                toastr.error($translate.instant('flyers.messages.load.error'));
            }
        }

        function refreshInactiveBoxArray(number) {
            vm.flyersStatus = [];
            var qtdActive = vm.showcase.flyers.length;
            for (var i = 0; i < vm.maxFlyersPerShowcase; i++) {
                if (qtdActive >= 1) {
                    vm.flyersStatus.push({
                        status: true
                    });
                } else {
                    vm.flyersStatus.push({
                        status: false
                    });
                }
                qtdActive--;
            }
        }

        function reduceFlyersToMap() {
            var index = 0;
            vm.flyersMap = vm.showcase.flyers.reduce(function(map, flyer) {
                map[flyer.id] = {
                    name: flyer.name,
                    pos: index
                };
                index++;
                return map;
            }, {})
        }

        vm.getCountSelectedByBrand = function(brand) {
            return vm.brandGroups[brand];
        };

        vm.tabClick = function(brand){
            vm.loadingTab = true;

            // Changes the type to the new type Clicked
            vm.listFilter.brand = brand.name;

            // Erases the offer list
            vm.flyersFiltered = [];

            // Turn to 0 the page to give right page on pageable
            vm.page = 0;

            // loadOffers
            loadFlyers();

        };

        vm.changeFlyerSelected = function(flyer) {
            if (vm.flyersMap) {
                if (vm.flyersMap[flyer.id]) {
                    vm.showcase.flyers.splice(vm.flyersMap[flyer.id].pos, 1);
                    reduceFlyersToMap();
                } else {
                    flyer.position = vm.showcase.flyers.length + 1;
                    vm.showcase.flyers.splice(0, 0, flyer);
                    reduceFlyersToMap();
                }
            } else {
                vm.showcase.flyers.splice(0, 0, flyer);
                reduceFlyersToMap();
            }
            refreshInactiveBoxArray();
            vm.refreshFlyersSelected()
        };

        vm.refreshFlyersSelected = function() {
            vm.makeBrandGroups();
        };

        vm.getCategoryName = function(flyer) {
            var category = _.find(vm.categories, function(el) {return el.key === flyer.categoryKey});
            if (category) {
                flyer.category = category.title;
            }
        };

        vm.getFlyerCurrency = function(currency) {
            return FlyersCurrencyValue[currency];
        };

        vm.checkFlyerIsSelected = function(flyer) {
            if (vm.flyersMap) {
                return angular.isDefined(vm.flyersMap[flyer.id]);
            }
        };

        vm.hasActiveFilter = function() {
            return ((vm.numberFilterSelected && vm.numberFilterSelected > 0) || (vm.listFilter.text && vm.listFilter.text !== ''));
        };

        vm.stopPropagation = function(event) {
            event.stopPropagation();
        };

        function checkIsListAll() {
            vm.flyersFiltered = [];
            if (!vm.filters.inactive && !vm.filters.active) {
                vm.listFilter.status = null;
            }
            loadFlyers();

        }

        vm.changeStatusFilterToActive = function() {
            if (vm.filters.active) {
                vm.filters.inactive = false;
                vm.listFilter.status = "ACTIVATED";
            }
            checkIsListAll();
        };

        vm.changeStatusFilterToInactive = function() {
            if (vm.filters.inactive) {
                vm.filters.active = false;
                vm.listFilter.status = "DEACTIVATED";
            }
            checkIsListAll();
        }

        vm.openCalendarStart = function(e) {
            e.preventDefault();
            e.stopPropagation();
            vm.isOpenCalendarStart = true;
        };

        vm.openCalendarExpire = function(e) {
            e.preventDefault();
            e.stopPropagation();
            vm.isOpenCalendarExpire = true;
        };

        vm.saveShowcase = function(isRedirectToShowcaseScreen) {
            var flyersPosition = [];
            vm.showcase.flyers.forEach(function (flyer) {
                flyersPosition.push({
                    flyerId: flyer.id,
                    position: flyer.position
                });
            });
            vm.showcase.flyers = flyersPosition;
            $q.all(vm.showcase.flyers).then(function(data) {
                ShowcasesService.updateShowcase(vm.showcase).then(function(data) {
                    if (isRedirectToShowcaseScreen) {
                        $state.go('showcases.edit', {
                            showcaseId: data.id
                        });
                    }
                });
            });
        }

        function getDefaultFilter() {
            setTimeout(function() {
                //load default filter
                vm.flyersFiltered = [];
                delete vm.listFilter.categoryKey;
                delete vm.listFilter.categoryKeys;
                delete vm.listFilter.brand;
                delete vm.listFilter.brands;
                loadAll();
            }, 1000);
        }

        vm.filters = {
            categories: [],
            brands: [],
            colors: [],
            sizes: [],
            genders: [],
            configurations: [],
            hasInventory : true
        };

        vm.openFiltersModal = function () {
            vm.filtersConversationModal = $uibModal.open({
                templateUrl: 'app/admin/flyers/modal-filter-flyer/flyer-filter-modal.html',
                controller: 'FiltersFlyersController',
                windowClass: 'modal-center',
                windowTopClass: 'center-modal',
                controllerAs: 'vm',
                size: 'lg',
                keyboard: false,
                resolve: {
                    options: function () {
                        return {
                            categories: vm.categories,
                            brands: vm.brands,
                            colors: vm.colors,
                            sizes: vm.sizes,
                            canChangeView: false
                        }
                    },
                    filters: function () {
                        return vm.filters
                    },
                    list: function () {
                        return vm.listFilter
                    }
                },
            }).result.then(function (response) {
                if (response) {
                    vm.numberFilterSelected = response.totalFilters;

                    vm.filters = response.selectedFilters;
                    if (response.totalFilters === 0) {
                        delete vm.listFilter.categoryKey;
                        delete vm.listFilter.categoryKeys;
                        vm.listFilter.brands = [vm.listFilter.brand];
                    } else
                        vm.listFilter = response.listFilter;
                    loadFlyers();
                }
            });
        }

    }

})();
