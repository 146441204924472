/* globals $ */
(function() {
    'use strict';

    angular
        .module('showcaseApp')
        .directive('informativeBalloon', informativeBalloon)
        .directive('warningBalloon', warningBalloon);

    function informativeBalloon() {
        return {
            restrict: 'E',
            templateUrl: 'app/admin/components/balloon/informative-balloon.html',
            scope: {
                messageId: '='
            },
        };
    }

    function warningBalloon() {
        return {
            restrict: 'E',
            templateUrl: 'app/admin/components/balloon/warning-balloon.html',
            scope: {
                messageId: '='
            },
        };
    }
})();
