(function () {
    'use strict';

    angular
        .module('showcaseApp')
        .controller('SelectContactsModalController', SelectContactsModalController);

    SelectContactsModalController.$inject = ['$scope', '$uibModalInstance', '$translate', '$q', '$window',
        'BroadcastListService', 'ForwardMessageService', 'ChatCommerceService', 'allAttendants',
        '$localStorage', 'toastr', 'moment', 'selectedMessages', 'communicationConstants'];

    function SelectContactsModalController($scope, $uibModalInstance, $translate, $q, $window,
                                           BroadcastListService, ForwardMessageService, ChatCommerceService, allAttendants,
                                           $localStorage, toastr, moment, selectedMessages, communicationConstants) {
        var vm = this;

        vm.isMobile = detectar_mobile();
        vm.selectedContacts = [];
        vm.processedMessages = [];

        // Select contacts
        vm.SORTBY_VALUES = {
            'NAME': 1,
            'PHONE': 4
        };
        vm.loadingContacts = false;
        vm.loadingForwardMessages = false;
        vm.numberPerPage = [5, 10, 25, 100]
        vm.totalItems = 0;
        vm.numPerPage = 10;
        vm.maxSize = 10;
        vm.status = {
            'NEW': 'Novo',
            'OPENED': 'Aberto',
            'PENDING': 'Pendente',
            'RESOLVED': 'Resolvido',
            'EXPIRED': 'Expirado'
        }
        vm.filters = {
            "status": [
                "NEW",
                "OPENED",
                "PENDING"
            ],
            "attendants": [],
            "socialMedias": ['WATI'],
            "order": {},
            "rangeDate": null,
            "allAttendants": allAttendants.map(function(attendant) { return attendant.id }),
            "pageNumber": 0,
            "searchString": "",
            "connectedChats": {
                "isWATIConnected": true,
                "isMetaConnected": true
            }
        };
        vm.contacts = [];

        init();

        function init() {
            getContacts();
            processMessages();
            vm.modalHeight = window.innerHeight;
        }

        vm.closeModal = function (response) {
            $uibModalInstance.close(response);
        }

        function getContacts() {
            vm.loadingContacts = true;
            ChatCommerceService.getConversationsByFilter(vm.filters).then(function (response) {
                vm.contacts = vm.contacts.concat(response.data.items);
                vm.loadingContacts = false;
            });
        }

        vm.conversationsScrolledToBottom = function () {
            if (vm.loadingContacts || vm.totalItems === vm.contacts.length) return;

            vm.filters.pageNumber = vm.filters.pageNumber + 1;
            getContacts();
        }

        function processMessages() {
            selectedMessages.forEach(function (messageData, index) {
                messageData.position = index;
                if (messageData.type !== 'text') {
                    passMessageDataToBase64(messageData, function (result) {
                        var settings = makeSettingByBase64(result);
                        vm.processedMessages.push({
                            text: messageData.text ? messageData.text : '',
                            type: messageData.type,
                            media: {
                                caption: messageData.text ? messageData.text : '',
                                data: result.split('base64,')[1],
                                mimeType: settings.mimeType
                            }
                        })
                    });
                } else {
                    vm.processedMessages.push({
                        text: messageData.text,
                        type: messageData.type,
                        created: messageData.created
                    })
                }
            });
        }

        function passMessageDataToBase64(message, callback) {
            var xhr = new XMLHttpRequest();
            xhr.onload = function () {
                var reader = new FileReader();
                reader.onloadend = function () {
                    callback(reader.result);
                }
                reader.readAsDataURL(xhr.response);
            };
            xhr.open('GET', message.data);
            xhr.responseType = 'blob';
            xhr.send();
        }

        function makeSettingByBase64(base64) {
            var base = base64.split(';')[0];
            var types = communicationConstants.FILE_TYPES;
            return {
                type: types.find(function (type) {
                    return base.split('/')[1] === type.source;
                }).target, mimeType: base.split(':')[1]
            };
        }

        vm.sendMessages = function () {
            vm.loadingForwardMessages = true;
            var forwardMessagesData = {
                messages: _.sortBy(vm.processedMessages, 'created'),
                contacts: vm.selectedContacts.map(function (selectedContacts) {
                    return selectedContacts.chatUserId;
                })
            }
            ForwardMessageService.sendMessages(forwardMessagesData).then(function () {
                vm.loadingForwardMessages = false;
                toastr.success($translate.instant('communication.forward-messages-modal.request-messages.forward-messages-success'));
                vm.closeModal(forwardMessagesData);
            }).catch(function (error) {
                vm.loadingForwardMessages = false;
                toastr.error($translate.instant('communication.forward-messages-modal.request-messages.forward-messages-error'));
            });
        }

        vm.refreshContacts = function () {
            vm.contacts = [];
            getContacts();
        }

        vm.sortContactsBy = function (sortBy) {
            if (vm.filters.sortBy === vm.SORTBY_VALUES[sortBy]) {
                if (vm.filters.orderBy === 'asc') {
                    vm.filters.orderBy = 'desc';
                } else {
                    vm.filters.orderBy = 'asc';
                }
            } else {
                vm.filters.orderBy = 'asc';
            }
            vm.filters.sortBy = vm.SORTBY_VALUES[sortBy];
            getContacts();
        }

        vm.numberOfContactsLabel = function () {
            if (vm.selectedContacts.length <= 1) {
                return $translate.instant('communication.broadcast-list-modal.contact');
            } else {
                return $translate.instant('communication.broadcast-list-modal.contacts');
            }
        }

        vm.selectOne = function (contact) {
            var contactFound = vm.checkIfContactSelected(contact);

            if (!contactFound && vm.selectedContacts.length >= 5) return;

            if (contactFound) {
                _.remove(vm.selectedContacts, function (selectedContact) {
                    return selectedContact.conversationId === contact.conversationId;
                });
                contact.isSelected = false;
            } else {
                vm.selectedContacts.push(contact);
                contact.isSelected = true;
            }
        }

        vm.checkIfContactSelected = function(contact) {
            var contactFound = _.find(vm.selectedContacts, function (selectedContact) {
                return selectedContact.conversationId === contact.conversationId;
            });
            return contactFound;
        }

        vm.labelNoContactsAvailable = function () {
            return vm.contacts.length === 0 && !vm.loadingContacts && vm.filters.pageNumber === 0 && vm.filters.searchString.trim() === '';
        }

        vm.selectContactsValidation = function () {
            return vm.totalSelected() > 0;
        }

        vm.totalSelected = function () {
            return vm.selectedContacts.length;
        }
    }
})();
