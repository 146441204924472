(function () {
  'use strict';
  angular
      .module('showcaseApp')
      .controller('WhatsappConfirmationModalController', WhatsappConfirmationModalController);

  function WhatsappConfirmationModalController($uibModalInstance) {

      var vm = this;

      // FUNCTIONS
      vm.closeModalSuccessfully = closeModalSuccessfully;

      function closeModalSuccessfully(){
          $uibModalInstance.close();
      }


  }
})();
