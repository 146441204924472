/**
 * Google My Business factory
 * author: Odravison Amaral (odravison.amaral@dce.ufpb.br)
 * 2019
 */

(function () {
    'use strict';
    angular
        .module('showcaseApp')
        .factory('GMBFactory', GMBFactory);

    GMBFactory.$inject = ['$q'];

    function GMBFactory($q) {
        return {
            authorize: function (callbackOfflineAccess, callbackOfflineAccessError) {
                return gapi.auth2.getAuthInstance().grantOfflineAccess()
                .then(callbackOfflineAccess)
                .catch(callbackOfflineAccessError);
            },
            signOut: function () {
                return gapi.auth2.getAuthInstance().signOut();
            }
        }
    }
})();
