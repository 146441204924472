(function () {
    'use strict';

    angular
        .module('showcaseApp')
        .factory('CustomerModalFactory', CustomerModalFactory);

    /** @ngInject */
    function CustomerModalFactory($uibModal) {

        var factory = {
            addCustomer: addCustomer,
            editCustomer: editCustomer,
            deleteCustomer: deleteCustomer
        }

        return factory;

        function addCustomer() {
            return showAddEditModal();
        }

        function editCustomer(customer) {
            return showAddEditModal(customer);
        }

        function deleteCustomer(customer) {
            return showDeleteModal(customer.fullName);
        }

        function showAddEditModal(customer) {
            return $uibModal.open({
                templateUrl: "app/admin/customer/_modal_add_edit_customer.html",
                size: 'lg',
                windowClass: 'modal-center',
                windowTopClass: 'modal-dialog-center',
                resolve: {
                    customer: function () {
                        return angular.copy(customer);
                    }
                },
                controller: "CustomerModalConstroller",
                controllerAs: "vm"
            }).result;
        }

        function showDeleteModal(fullName) {
            return $uibModal.open({
                templateUrl: "app/admin/customer/_modal_delete_customer.html",
                size: 'md',
                windowClass: 'modal-center',
                windowTopClass: 'modal-dialog-center',
                resolve: {
                    fullName: function () {
                        return fullName;
                    }
                },
                controller: function ($uibModalInstance, fullName) {
                    var vm = this;
                    vm.fullName = fullName;
                    vm.cancel = cancel;
                    vm.ok = ok;

                    function cancel() {
                        $uibModalInstance.dismiss('cancel');
                    }

                    function ok() {
                        $uibModalInstance.close(true);
                    }
                },
                controllerAs: "vm"
            }).result;
        }
    }
})();
