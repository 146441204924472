(function() {
    'use strict';

    angular
        .module('showcaseApp')
        .controller('RemoveModalController', RemoveModalController);

    RemoveModalController.$inject = ['$scope', '$uibModal', '$uibModalInstance', 'toastr', 'itens'];

    function RemoveModalController($scope, $uibModal, $uibModalInstance, toastr, itens) {
        var vm = this;
        vm.itens = itens;

        vm.confirm = function() {
            $uibModalInstance.close(true);
        }

        vm.closeModal = function() {
            $uibModalInstance.dismiss();
        }

    }

})();
