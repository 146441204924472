(function () {
    'use strict';
    angular
        .module('showcaseApp')
        .factory('ChecklistFactory', ChecklistFactory);

    ChecklistFactory.$inject = ['$resource'];

    function ChecklistFactory($resource) {
        var resourceUrl = 'admin/api/activity';

        return $resource(resourceUrl, {}, {
            'getActivitiesList': {
                method: 'GET',
                isArray: false,
                url: 'admin/api/activity/list'
            },
            'getActivitiesListByStatus': {
                method: 'GET',
                isArray: false,
                url: 'admin/api/activity/list/:status'
            },
            'updateActivity': {
                method:'PUT',
                isArray: false,
                url: 'admin/api/activity/:activityId'
            },
        });
    }
})();
