(function() {
    'use strict';

    angular
        .module('showcaseApp')
        .factory('WsService', WsService);

    WsService.$inject = ['WsFactory'];

    function WsService(WsFactory) {

        var service = {
            getInfo: getInfo
        };

        return service;

        function getInfo() {
            return WsFactory.get().$promise;
        }

    }
})();
