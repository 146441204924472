(function () {
    'use strict'


    angular
        .module('showcaseApp')
        .service('ContactReportService', ContactReportService);

    //** NgInject */
    function ContactReportService(ContactReportFactory) {

        var service = {
            listUsersRegisteredByGenderWithContactGeneratedByStore: listUsersRegisteredByGenderWithContactGeneratedByStore,
            listMostLikedCategories: listMostLikedCategories,
            listUsersGenerateMoreContact: listUsersGenerateMoreContact,
            listUsersFirstInteraction: listUsersFirstInteraction
        };

        return service;

        function listUsersRegisteredByGenderWithContactGeneratedByStore(storeId) {
            return ContactReportFactory.listUsersRegisteredByGenderWithContactGeneratedByStore(storeId).$promise;
        }

        function listMostLikedCategories(storeId, langKey) {
            return ContactReportFactory.listMostLikedCategories({
                storeId: storeId,
                langKey: langKey
            }).$promise;
        }

        function listUsersGenerateMoreContact(storeId) {
            return ContactReportFactory.listUsersGenerateMoreContact(storeId).$promise;
        }

        function listUsersFirstInteraction(storeId) {
            return ContactReportFactory.listUsersFirstInteraction(storeId).$promise;
        }
    }


})();
