(function () {
	'use strict';

	angular.module('showcaseApp')
		.controller('ChecklistController', ChecklistController);

    ChecklistController.$inject = ['$uibModalInstance', '$localStorage', 'ChecklistService', 'toastr', '$translate', 'Principal', 'HubService'];

	function ChecklistController($uibModalInstance, $localStorage, ChecklistService, toastr, $translate, Principal, HubService) {
		var vm = this;

        vm.checklistTypes = [{'title': 'Todas', 'key': 'ALL'},
            {'title': 'Não iniciadas', 'key': 'PENDING'},
            {'title': 'Finalizadas', 'key': 'DONE'}];

        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var yyyy = today.getFullYear();
        var month = today.toLocaleString('default', { month: 'long' });
        vm.todayDate = dd + ' de ' + month + ' de ' + yyyy;

        vm.listItems = [];
        vm.doneItemsTotal = 0;

        vm.totalItems= 0;
        vm.doneItems = 0;

        vm.listFilter = { storeId: $localStorage.defaultStore.storeId, type: 'ALL'};

		vm.cancel = function () {
			$uibModalInstance.dismiss('cancel');
		}


        vm.updateItem = function (activity, status) {
            ChecklistService.updateActivity(activity.id, status).then(function (data) {
                vm.listItems = [];
                loadChecklistItems(vm.listFilter.type);
            });
        };

        vm.getFormattedTime = function (dateTime) {
            var date = new Date(dateTime);
            var hoursAndMinutes = date.getHours() + 'h' + date.getMinutes() + 'min';
            return hoursAndMinutes;
        }

        vm.getFormattedName = function (name, sizeLimit) {
            if (name.length > sizeLimit) {
            return name.substr(0, sizeLimit - 3) + '...';
            }
            return name;
        }

        function waitForElementToDisplay(selector, callback, checkFrequencyInMs, timeoutInMs) {
            var startTimeInMs = Date.now();
            (function loopSearch() {
                if (document.getElementById(selector) != null) {
                    callback();
                    return;
                } else {
                    setTimeout(function () {
                        if (timeoutInMs && Date.now() - startTimeInMs > timeoutInMs)
                            return;
                        loopSearch();
                    }, checkFrequencyInMs);
                }
            })();
        }

        function loadAccordion() {
            if (vm.listItems.length > 0) {
            waitForElementToDisplay(vm.listFilter.type + '-' + vm.listItems[0].id,function() {

                var acc = document.getElementById("tabContent");
                var heightFactor = 0.4;
                if (screen.height < 700) {
                    heightFactor = 0.5;
                }
                acc.style.height = screen.height - (screen.height * heightFactor) + 'px';

                var acc = document.getElementsByClassName("accordion");
                var i;
                for (i = 0; i < acc.length; i++) {
                    acc[i].addEventListener("click", function() {
                        this.classList.toggle("active-item");
                        var panel = this.nextElementSibling;
                        if (panel.style.maxHeight) {
                            panel.style.paddingBottom = 0;
                            panel.style.maxHeight = null;
                            panel.style.border = null;
                            var height = document.getElementById(this.id + '-content').clientHeight;
                            if (height <= 40) {
                                this.style.height = 60 + 'px';
                            } else {
                                this.style.height = 80 + 'px';
                            }
                        } else {
                            panel.style.paddingBottom = '5%';
                            panel.style.maxHeight = panel.scrollHeight + "px";
                            panel.style.borderBottom = "1px solid #eeecec";
                            panel.style.borderRight = "1px solid #eeecec";
                            panel.style.borderLeft = "1px solid #eeecec";
                            var height = document.getElementById(this.id + '-content').clientHeight;
                            this.style.height = Math.max((height + 20), 60) + 'px';
                        }
                    });
                    var height = document.getElementById(acc[i].id + '-content').clientHeight;
                    if (height <= 40) {
                        acc[i].style.height = 60 + 'px';
                    } else {
                        acc[i].style.height = 80 + 'px';
                    }
                }
            },50,9000);
            } else {
                vm.loadingTab = false;
            }
        }

        function loadChecklistItems(typeKey) {
            vm.loadingTab = true;

            if (typeKey == 'ALL') {
                ChecklistService.getActivitiesList().then(function (data) {
                    vm.listItems = data['activities'].sort(function(a,b) {
                        return a.id - b.id
                    });
                    vm.doneItems = 0;
                    vm.totalItems = 0;
                    for (var j = 0; j < vm.listItems.length; j++) {
                        vm.listItems.index = j;
                        if (vm.listItems[j].status == 'DONE') {
                            vm.doneItems += 1;
                        }

                        if (vm.listItems[j].status != 'CANCELLED') {
                            vm.totalItems += 1;
                        }
                    }
                    vm.doneItemsTotal = vm.doneItems;

                    loadAccordion();

                    var percentage = ((360/100) * ((vm.doneItems/vm.totalItems) * 100).toFixed(2)) / 2;

                    var acc = document.getElementById("maskFill");
                    acc.style.transform = 'rotate(' + percentage + 'deg)';
                    var acc2 = document.getElementById("maskFillPercentage");
                    acc2.style.transform = 'rotate(' + percentage + 'deg)';
                    var acc3 = document.getElementById("fillPercentage");
                    acc3.style.transform = 'rotate(' + percentage + 'deg)';
                    vm.loadingTab = false;
                });
            } else {
                ChecklistService.getActivitiesListByStatus(typeKey).then(function (data) {
                    vm.listItems = data['activities'];
                    vm.doneItems = 0;
                    for (var j = 0; j < vm.listItems.length; j++) {
                        if (vm.listItems[j].status == 'DONE') {
                            vm.doneItems += 1;
                        }
                    }
                    loadAccordion();
                    vm.loadingTab = false;
                });
            }
        }

        function getAccount() {
            Principal.identity(false).then(function (account) {
                vm.account = account;
                $translate.use(vm.account.langKey);
            });
        }

        vm.tabTypeClick = function(typeKey){
            vm.loadingTab = true;
            vm.listItems = [];
            vm.listFilter.type = typeKey;
            loadChecklistItems(typeKey);
        }



        function loadAll() {
            getAccount();
            loadChecklistItems('ALL');
        }

        loadAll();

	}
})();
