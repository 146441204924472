(function() {
    'use strict';
    angular
        .module('showcaseApp')
        .service('TemplateMessageService', TemplateMessageService);

    TemplateMessageService.$inject = ['TemplateMessageFactory'];

    function TemplateMessageService(TemplateMessageFactory) {

        var service = {
            create: create,
            getAll: getAll,
            remove: remove,
            update: update
        };

        return service;

        function create(body) {
            return TemplateMessageFactory.create(body).$promise;
        }

        function getAll() {
            return TemplateMessageFactory.getAll().$promise;
        }

        function remove(id) {
            return TemplateMessageFactory.deleteTemplate({id: id}).$promise;
        }

        function update(id, body) {
            return TemplateMessageFactory.update({id: id}, body).$promise;
        }

    }

})();
