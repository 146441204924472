(function () {
    'use strict';

    angular
        .module('showcaseApp')
        .factory('UserEngagementFactory', UserEngagementFactory);

    //** NgInject */
    function UserEngagementFactory ($resource) {

        return $resource('', {}, {
            'getUserInfo': {
                method: 'GET',
                url: '/api/users/:id/info',
                transformResponse: function(data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getUserCategoryStatistics': {
                method: 'GET',
                url: '/flyer/api/report/engagement/users/:id/category-statistics',
                params: {
                    id: '@id'
                },
                transformResponse: function(data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getUserOfferEngagements': {
                method: 'GET',
                url: '/flyer/api/report/engagement/users/:id/offers',
                params: {
                    id: '@id'
                },
                transformResponse: function(data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            }
        });
    }
})();
