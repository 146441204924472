(function () {
	'use strict';

	angular.module('showcaseApp')
		.controller('SatisfactionSurveyController', SatisfactionSurveyController);

	SatisfactionSurveyController.$inject = ['$uibModalInstance', '$localStorage', 'SatisfactionSurveyService', 'toastr', '$translate'];

	function SatisfactionSurveyController($uibModalInstance, $localStorage, SatisfactionSurveyService, toastr, $translate) {
		var vm = this;

		vm.showObservation = false;

		vm.subtitle = ''

		vm.satisfactionSurvey = { note: 0 };
		vm.notes = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

		if ($localStorage.hub.id == 4){
			vm.nameHub = $localStorage.hub.name.replaceAll("Hub ", "");
		}else{
			vm.nameHub = "Showkase";
		}
		
		vm.setNote = function (note) {
			if(vm.satisfactionSurvey.note === note){
				vm.satisfactionSurvey.note = 0;
				vm.showObservation = false;
				return;
			}
			vm.satisfactionSurvey.note = note;
			vm.satisfactionSurvey.storeId = $localStorage.defaultStore.storeId;
			vm.satisfactionSurvey.userId = $localStorage.userId;

			if(vm.satisfactionSurvey.note < 10) {
				vm.subtitle = 'showcases.satisfaction-survey.subtitle'
			} else {
				vm.subtitle = 'showcases.satisfaction-survey.second-subtitle'
			}
			vm.showObservation = true;
		}

		vm.cancel = function () {
			$uibModalInstance.dismiss('cancel');
		}

		vm.confirm = function () {
			SatisfactionSurveyService.sendSatisfactionSurvey(vm.satisfactionSurvey).then(function (data) {
			  toastr.success($translate.instant('showcases.satisfaction-survey.message-success'));
			  $uibModalInstance.close(true);
			}, function (data) {
			  toastr.error($translate.instant('showcases.satisfaction-survey.message-error'));
			});
		  }


	}
})();
