(function() {
    'user strict'

    angular
        .module('showcaseApp')
        .controller('EngagementController', EngagementController);

    //** NgInject */
    function EngagementController(EngagementService, $localStorage, FlyersService, FlyersCurrencyValue, $translate) {

        var vm = this;
        vm.storeId = $localStorage.defaultStore.storeId;
        vm.flyersIds = [];
        init();

        function init() {
            loadFlyers();
            loadUsersWithEngagementsFromCoupons();
            loadUsersWithEngagementsFromContacts();
            loadUsersWithEngagementsFromSales();
        }

        function loadUsersWithEngagementsFromCoupons() {
            vm.loadingUsersFromCoupons = true;
            EngagementService.listUsersFromCoupons(vm.storeId, vm.flyersIds).then(function(result) {
                vm.listUsersFromCoupon = result;
                vm.loadingUsersFromCoupons = false;
            }).catch(function() {
                vm.loadingUsersFromCoupons = false;
            });
        }

        function loadUsersWithEngagementsFromContacts() {
            vm.loadingUsersFromContact = true;
            EngagementService.listUsersFromContact(vm.storeId, vm.flyersIds).then(function(result) {
                vm.listUsersFromContact = result;
                vm.loadingUsersFromContact = false;
            }).catch(function() {
                vm.loadingUsersFromContact = false;
            });
        }

        function loadUsersWithEngagementsFromSales() {
            vm.loadingUsersFromContact = true;
            EngagementService.listUsersFromSale(vm.storeId, vm.flyersIds).then(function(result) {
                vm.listUsersFromSale = result;
                vm.loadingUsersFromSale = false;
            }).catch(function() {
                vm.loadingUsersFromSale = false;
            });
        }

        function loadFlyers() {

            FlyersService.listAllFlyers().then(onSuccess);

            function onSuccess(response) {
                vm.flyersFiltered = response;
            }
        }

        vm.toggleSelection = function toggleSelection(flyerId) {
            var idx = vm.flyersIds.indexOf(flyerId);

            loadUsersWithEngagementsFromCoupons();
            loadUsersWithEngagementsFromContacts();
            loadUsersWithEngagementsFromSales();

            // Is currently selected
            if (idx > -1) {
                vm.flyersIds.splice(idx, 1);
            }

            // Is newly selected
            else {
                vm.flyersIds.push(flyerId);
            }
        }

        vm.getFlyerCurrency = function(currency) {
            return FlyersCurrencyValue[currency];
        }

        vm.getGender = function(value) {
            if (value === "messages.gender.male") return $translate.instant('reports.engagement.message.gender.male')
            else return $translate.instant('reports.engagement.message.gender.female')
        }

        // Workaround to fix navigation sidebar
        // $(document).ready(function() {
        //     if (!document.getElementsByClassName('slimScrollDiv').length){
        //         setTimeout(function () {
        //             document.getElementsByClassName('slimScrollDiv')[0].setAttribute('style', 'position: relative; width: auto; height: 690px;');
        //         }, 1000);
        //     }
        //     else {
        //         document.getElementsByClassName('slimScrollDiv').setAttribute('style', 'position: relative; width: auto; height: 690px;');
        //     }

        //     if (!document.getElementById('side-menu').length){
        //         setTimeout(function () {
        //             document.getElementById('side-menu').setAttribute('style', 'height: 690px; width: auto;');
        //         }, 500);
        //     } else {

        //     }

        // });
    }
})();
