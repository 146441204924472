(function() {
    'use strict';

    angular
        .module('showcaseApp')
        .constant('communicationConstants', {
            STEPS_RECOMMENDATION: {
                FIRST: 1,
                SECOND: 2,
                THIRD: 3,
                FOURTH: 4
            },
            'ALL': "ALL",
            TYPES :{
                "TEXT": "TEXT",
                "FILE": "FILE",
                "IMAGE": "IMAGE",
                "VIDEO": "VIDEO",
                "STICKER": "STICKER",
                "DOCUMENT": "DOCUMENT",
                "TEMPLATE": "TEMPLATE",
                "AUDIO": "AUDIO"
            },
            SOCIAL_MEDIAS: {
                "WATI": "WATI",
                "MESSENGER": "MESSENGER",
                "INSTAGRAM": "INSTAGRAM",
                "FACEBOOK": "FACEBOOK"
            },
            FILE_TYPES: [
                {source: 'xml', target: 'application'},
                {source: 'octet-stream', target: 'application'},
                {source: 'png', target: 'image'},
                {source: 'jpg', target: 'image'},
                {source: 'webp', target: 'image'},
                {source: 'jpeg', target: 'image'},
                {source: 'pdf', target: 'document'},
                {source: 'doc', target: 'document'},
                {source: 'docx', target: 'document'},
                {source: 'wav', target: 'audio'},
                {source: 'ogg', target: 'audio'},
                {source: 'mpeg', target: 'audio'},
                {source: 'mpeg-3', target: 'audio'},
                {source: 'mp4', target: 'video'}
            ],
            SUPPORTED_FILES_TYPES: 'video/mp4, video/x-m4v, audio/wav, audio/mpeg, audio/mpeg-3, .opus, image/jpeg, image/png, image/webp, .doc, .docx, .xml, text/plain, application/pdf',
            SUPPORTED_IMAGES_TYPES: 'image/jpeg, image/png, image/webp',
            DEFAULT_BAG: {
                ownerId: 0,
                showcaseTemplateId : 1,
                cover : null,
                coverUrl : "https://showkase-prod.s3.sa-east-1.amazonaws.com/hub/system-images/showkase/image_default_bag.png",
                status : "RELEASED",
                published : false,
                userInteract : "SALE",
                hasCover : true,
            },
            TEMPLATE_MESSAGE_RECOMMENDATION: 'Olha só essa mala que acabamos de montar especialmente para você. \n \n {url}',
            PAGE_SIZE: {
                "MESSAGES": "20",
                "CONVERSATIONS": "10"
            },
            CHAT_STATUS: {
                "OPENED": "OPENED",
                "NEW": "NEW",
                "PENDING": "PENDING",
                "RESOLVED": "RESOLVED" ,
                "EXPIRED": "EXPIRED"
            },
            MESSAGES_TYPES: {
                "TEXT": "text",
                "UNSUPPORTED": "unsupported",
                "STORYMENTION": "story_mention",
                "STORYREPLY": "story_reply",
                "CAROUSEL": "carousel",
                "STICKER": "sticker"
            },
            RECOMMENDATION_TYPES: {
                "TREND": "TREND",
                "BOUGHT_TOGETHER": "BOUGHT_TOGETHER",
                "CLUSTER": "CLUSTER",
                "FUNKSVD": "FUNKSVD"
            },
            FILES: {
                MAX_SIZE: 26214400
            }
        });
})();
