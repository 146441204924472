(function() {
    'use strict';

    angular
        .module('showcaseApp')
        .controller('BagController', BagController);


    /** @ngInject */
    function BagController(   $window, $state, RemoveModalFactory, $localStorage, $translate, StoreService,
                              ShowcasesService, toastr, ModalFactory, paginationConstants, DOMAIN, $rootScope,
                              $uibModal, BrandService, FlyerCategoryService, FlyersService, CustomerService) {

        var vm = this;

        vm.redirectUrl = undefined;

        vm.goToBag = function(bag) {
            vm.getShowcaseURL(bag.id);
        }

        vm.getBagUrl = function(bag) {
            if (vm.redirectUrl) {
                return $window.location.protocol + '//' + vm.redirectUrl + '/bag/' + bag.id;
            } else if (vm.store.nickname) {
                return $window.location.protocol + '//' + vm.store.nickname + '.' + getDomainNameSufix($window, vm.store.storeType) + '/bag/' + bag.id;
            } else {
                return $window.location.protocol + '//' + $localStorage.defaultStore.storeNickname + '.' + getDomainNameSufix($window, vm.store.storeType) + '/bag/' + bag.id;
            }
        }

        vm.copiedUrl = function() {
            toastr.success($translate.instant('bag.success.copied'));
        }

        vm.goToReport = function(bag) {
            $localStorage.defaultStore.currentBagReport = bag.bagReportUrl;
            $state.go('virtual-bag-performance', {
                report: 'virtual-bag'
            });
        }

        init();

        function init() {
            loadStoreData();
        }

        function loadStoreData() {
            StoreService.getStore($localStorage.defaultStore.storeId).then(function(response) {
                vm.storeCategory = response.data.categoryKey;
                vm.redirectUrl = response.data.redirectURL;
            
            });
        }

        vm.filters = {
            categories: [],
            brands: [],
            colors: [],
            sizes: [],
            genders: [],
            customers: [],
            hasInventory : true
        };

        vm.brands = []
        vm.categories = []
        vm.customers = []
        vm.colors = []
        vm.sizes = []

        vm.listFilter = {
            ownerId: $localStorage.defaultStore.storeId,
            rowsForPage: "20"
        };

        vm.numberFilterSelected = 0;

        vm.totalBags = 0;
        vm.maxSize = 5;
        vm.numPerPage = parseInt(vm.listFilter.rowsForPage);
        vm.currentPage = 1;
        vm.startRegistry = 1;
        vm.endRegistry = vm.numPerPage;

        vm.dateRangeOptions = {
            opens: 'right',
            locale: {
                applyClass: 'btn-green',
                applyLabel: $translate.instant('bag.filters.apply'),
                fromLabel: "From",
                format: "DD/MM/YYYY",
                toLabel: "To",
                cancelLabel: $translate.instant('bag.form.buttons.cancel'),
                customRangeLabel: $translate.instant('global.form.period')
            },
            ranges: {
            },
            eventHandlers: {
                'apply.daterangepicker': function (ev, picker) {
                    if(vm.dateRange.startDate === undefined || vm.dateRange.endDate === undefined) {
                        vm.dateRange = {startDate: moment().startOf("day"), endDate: moment().endOf("day")}
                    }
                    vm.listFilter.startDate = vm.dateRange.startDate;
                    vm.listFilter.endDate = vm.dateRange.endDate;
                    vm.resetPage();
                }
            }
        };
        vm.dateRange = '';

        vm.hasntStoreYet = !$localStorage.defaultStore.storeId;

        vm.isPublishing = false;

        vm.isAllSelected = false;
        vm.showcases = [];
        vm.showcasesToRemove = [];
        vm.userHasShowcases = false;

        vm.page = 0;

        vm.hasNextPage = false;
        vm.busy = false;
        var SHOWCASE_PUBLISHED = "RELEASED";

        loadAll();

        /**
         * Load all values and filters for bags
         */
        function loadAll() {
            vm.isLoading = true;

            getDefaultFilter();

            fillRanges();

            loadShowcases();
        }

        /**
         * Prepare the brands array to be used on filters
         * @param array
         *  the brands array
         * @returns {*[]}
         *  the brands in format to be used on filters
         */
        function prepareFilterBrands(array) {
            var response = [];
            for (var i = 0; i < array.length; i++) {
                response.push({key: array[i], name: array[i]});
            }
            return response;
        }

        /**
         * Loads the default values for categories, brands, colors, sizes and customers to be used on filters
         */
        function getDefaultFilter() {

            function onSuccess (data) {

                vm.categories = data.categories;
                vm.categories = sortListByStrAttribute(vm.categories, 'title');

                vm.brands = prepareFilterBrands(data.brands);
                vm.brands = sortListByStrAttribute(vm.brands, 'name');

                vm.colors = data.colors.sort();

                vm.sizes = data.sizes.sort();

                function onSuccessCustomer (data) {
                    vm.customers = data;
                    vm.customers = sortListByStrAttribute(vm.customers, 'fullName');
                }

                var body = {
                    ids: data.customers
                };

                CustomerService.listAllByIds(body, onSuccessCustomer, onError);

            }

            function onError (response) {
                vm.isLoading = false;
                vm.busy = false;

                if (response.headers('X-flyerApp-alert') == 'userHasNoStore'){
                    toastr.error($translate.instant('showcases.messages.errors.noStore'));
                }
            }

            setTimeout(function() {
                var body = {
                    ownerId: $localStorage.defaultStore.storeId
                };

                if (vm.listFilter.ownerId) {
                    ShowcasesService.listBagsFiltersValues(body, onSuccess, onError);
                }

            }, 1000);

        }

        /**
         * Callback to refresh page view when page is changed.
         */
        vm.onPageChanged = function () {
            vm.deselectAllShowcases();

            var begin = ((vm.currentPage - 1) * vm.numPerPage),
                end = begin + vm.numPerPage;

            vm.startRegistry = begin + 1;
            vm.endRegistry = end;
        }

        vm.openDatePicker = function () {
            document.getElementById('dateRangeInput').click()
        }

        /**
         * Refresh the page of bags exhibition
         */
        vm.refreshPage = function () {
            vm.page = vm.currentPage - 1;

            loadShowcases();
            vm.onPageChanged();
        }

        /**
         * Resets the pagination
         */
        vm.resetPage = function () {
            vm.currentPage = 1;
            vm.refreshPage();
        }

        /**
         * Changes by select box the current quantity of bags to be shown for each page and resets the pagination
         */
        vm.changePagination = function() {
            vm.numPerPage = parseInt(vm.listFilter.rowsForPage);
            vm.resetPage();
        };

        /**
         * Loads all the bags with pagination and filtered
         */
        function loadShowcases() {
            if(vm.busy) return;
            vm.busy = true;


            var pageable = {
                page: vm.page,
                size: vm.numPerPage
            };

            if (vm.listFilter.ownerId){

                vm.listFilter.isPrimary = false;

                /**
                 * If the user hasn't a store, this script below cannot be executed
                 */

                ShowcasesService.listShowcases(vm.listFilter, pageable, onSuccess, onError);

                function onSuccess(data, headers) {
                    vm.hasNextPage = headers('X-Has-Next-Page') === "true";
                    vm.totalBags = parseInt(headers('X-Total-Count'));
                    vm.showcases = []
                    for (var i = 0; i < data.length; i++) {
                        vm.showcases.push(data[i]);
                    }
                    vm.isLoading = false;
                    if (vm.showcases.length >= 1) {
                        vm.userHasShowcases = true;
                    }

                    vm.showcases.forEach(function(showcase) {
                        ShowcasesService.getNumberOfFlyers(showcase.id).then(function(response) {
                            showcase.numberOfFlyers = response.data;
                        });
                    });
                    vm.busy = false;
                }

                function onError(response) {
                    vm.isLoading = false;
                    vm.busy = false;

                    if (response.headers('X-flyerApp-alert') == 'userHasNoStore'){
                        toastr.error($translate.instant('showcases.messages.errors.noStore'));
                    }
                }

            } else {
                vm.hasntStoreYet = true;
                vm.busy = false;
                vm.isLoading = false;
            }


        }

        /**
         * Fill dateRangeOptions variable with translated keys and respective date values
         */
        function fillRanges() {
            vm.dateRangeOptions.ranges[$translate.instant('global.form.today')] = [moment(), moment()]
            vm.dateRangeOptions.ranges[$translate.instant('global.form.yesterday')] = [moment().subtract(1, 'days'), moment().subtract(1, 'days')]
            vm.dateRangeOptions.ranges[$translate.instant('global.form.last7Days')] = [moment().subtract(6, 'days'), moment()]
            vm.dateRangeOptions.ranges[$translate.instant('global.form.last15Days')] = [moment().subtract(14, 'days'), moment()]
            vm.dateRangeOptions.ranges[$translate.instant('global.form.last30Days')] = [moment().subtract(29, 'days'), moment()]
            vm.dateRangeOptions.ranges[$translate.instant('global.form.last60Days')] = [moment().subtract(59, 'days'), moment()]
            vm.dateRangeOptions.ranges[$translate.instant('global.form.last90Days')] = [moment().subtract(99, 'days'), moment()]
            vm.dateRangeOptions.ranges[$translate.instant('global.form.lastYear')] = [moment().subtract(365, 'days'), moment()]
        }

        vm.getEditPage = function(showcaseId) {
            $state.go('bag.edit', {
                showcaseId: showcaseId
            });
        }


        vm.updateShowcasesToRemove = function(showcaseId) {
            if (vm.showcasesToRemove.indexOf(showcaseId) != '-1') {
                vm.showcasesToRemove.splice(vm.showcasesToRemove.indexOf(showcaseId), 1);
            } else {
                vm.showcasesToRemove.push(showcaseId);
            }
        }

        vm.checkIfShowcaseSelected = function(showcaseId) {
            if (vm.showcasesToRemove.indexOf(showcaseId) != '-1') {
                return true;
            } else {
                return false;
            }
        }

        vm.checkIsNotLoading = function() {
            return !vm.isLoading;
        }

        vm.checkIfShowcasePublished = function(showcaseStatus) {
            if (showcaseStatus) {
                return true;
            } else {
                return false;
            }
        }

        vm.publishShowcase = function(showcaseId) {
            var showcase = undefined;
            ShowcasesService.getShowcase(showcaseId).then(function (response) {
                showcase = response;
                if (!showcase || !showcase.id){
                    toastr.error($translate.instant('showcases.messages.publish.error'));
                }
                else if (!showcase.hasCover) {
                    toastr.error($translate.instant('showcases.messages.errors.blankCover'));
                    vm.isSaving = false;
                } else if (showcase.flyers.length < 1) {
                    toastr.error($translate.instant('showcases.messages.errors.blankFlyers'));
                } else if (!showcase.name) {
                    toastr.error($translate.instant('showcases.messages.errors.blankName'));
                } else {
                    vm.isPublishing = true;
                    ShowcasesService.publishShowcase(showcaseId).then(function(data) {
                        toastr.success($translate.instant('showcases.messages.publish.success'));
                        vm.isPublishing = false;

                        ModalFactory.openModal(null,
                            $translate.instant('showcases.messages.publish.successTitle', {
                            showcaseType: $translate.instant('global.showcaseTypes.' + showcase.userInteract) }),
                            $translate.instant('showcases.messages.publish.successSubtitle', {
                                url: 'http://' + $localStorage.defaultStore.storeNickname.toLowerCase() + "." + getDomainNameSufix($window, vm.store.storeType) }),
                            null, $translate.instant('showcases.messages.publish.goToShowcase'),
                            'OK', function(){$window.open('http://' + $localStorage.defaultStore.storeNickname.toLowerCase() + "." + getDomainNameSufix($window, vm.store.storeType), '_blank')},
                            null, null, 'icon-ksc-showcasepublished');

                        $state.reload();
                    }, function(data) {
                        vm.isPublishing = false;
                        toastr.error($translate.instant('showcases.messages.publish.error'));
                    });
                }
            }, function (data) {

                if (data.headers("errorKey")){
                    toastr.error($translate.instant('showcases.messages.publish.errors.' + data.headers("errorKey")));
                } else {
                    toastr.error($translate.instant('showcases.messages.publish.errors.genericError'));
                }

                vm.isPublishing = false;
            });

        }

        vm.getPreviewURL = function(showcaseId) {
            if (!$localStorage.defaultStore.storeNickname){
                getStoreInfo().then(function(response) {
                    $localStorage.defaultStore.storeNickname = response.data.nickname;
                    $window.open($window.location.protocol + '//' + $localStorage.defaultStore.storeNickname + '.' + getDomainNameSufix($window, vm.store.storeType) + '/preview/' + showcaseId, '_blank');
                });
            } else {
                $window.open($window.location.protocol + '//' + $localStorage.defaultStore.storeNickname + '.' + getDomainNameSufix($window, vm.store.storeType) + '/preview/' + showcaseId, '_blank');
            }

        }

        vm.getShowcaseURL = function(idBag) {
            if (!$localStorage.defaultStore.storeNickname) {
                getStoreInfo().then(function(response) {
                    $localStorage.defaultStore.storeNickname = response.data.nickname;
                    $window.open($window.location.protocol + '//' + $localStorage.defaultStore.storeNickname + '.' + getDomainNameSufix($window, vm.store.storeType) + '/bag/' + idBag, '_blank');
                });
            } else {
                $window.open($window.location.protocol + '//' + $localStorage.defaultStore.storeNickname + '.' + getDomainNameSufix($window, vm.store.storeType) + '/bag/' + idBag, '_blank');
            }

        }

        vm.generateTabloid = function(showkase) {
            ShowcasesService.getShowcase(showkase.id).then(function (response) {
                showkase = response;
                if (!showkase.hasCover) {
                    toastr.error($translate.instant('showcases.messages.errors.blankCover'));
                    vm.isSaving = false;
                } else if (showkase.flyers.length < 1) {
                    toastr.warning($translate.instant('Sua mala não possui nenhum produto.'));
                } else if (!showkase.name) {
                    toastr.error($translate.instant('showcases.messages.errors.blankName'));
                } else {
                    vm.isPrinting = true;
                    buildMessageGeneratedTabloid(showkase);
                    $rootScope.$emit('waiting-generates-tabloid');
                    ShowcasesService.generateTabloid({
                        showcaseId: Number(showkase.id) || undefined,
                        storeId: ( $localStorage.defaultStore ? $localStorage.defaultStore.storeId + "" : undefined) || undefined,
                        flyers: []
                    }).then(succesPrint, errorPrint);
                }
            })
        }

        function buildMessageGeneratedTabloid(showkase) {
            ModalFactory.openModal(null,
                'A sua mala está sendo preparada',
                null, null, 'OK', null, 'close',null, null, null,
                'showcases.messages.tabloid.ifNotDownloaded', 'showcases.messages.tabloid.clickHere',
            function(callback){
                ShowcasesService.generateTabloid({
                    showcaseId: Number(showkase.id) || undefined,
                    storeId: ( $localStorage.defaultStore ? $localStorage.defaultStore.storeId + "" : undefined) || undefined,
                    flyers: []
                }).then(succesPrint, errorPrint);
            }, 'width: 40%;', 'https://showkase-prod.s3-sa-east-1.amazonaws.com/hub/system-images/'+$rootScope.urlImage+'/mala-m.png');
        }

        function succesPrint(res) {

            var saveByteArray = (function() {
                return function(data, name, mimeType) {
                    var blob = new Blob(data, {
                        type: mimeType
                    });
                    var url = window.URL.createObjectURL(blob);
                    var element = angular.element('<a/>');
                    element.attr({
                        href: url,
                        target: '_blank',
                        download: name
                    });
                    if(document.createEvent) {
                        var ev = document.createEvent("MouseEvent");
                        ev.initMouseEvent("click", true /* bubble */, true /* cancelable */, window, null, 0, 0, 0, 0, /* coordinates */
                        false, false, false, false,
                        /*
                         * modifier keys
                         */
                        0 /* left */, null);
                        element[0].dispatchEvent(ev);
                    } else {
                        element[0].fireEvent("onclick");
                    }
                    setTimeout(function() {
                        document.body.removeChild;
                        window.URL.revokeObjectURL(url);
                    }, 200);
                };
            }());
            if (res.headers["content-disposition"]){
                var filename = res.headers["content-disposition"].match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)[1];
                var mimeType = res.headers["content-type"];
                filename = filename.replace(/['"]/g, '');
                saveByteArray([res.data], filename, mimeType);
            } else {
                toastr.error($translate.instant('showcases.messages.tabloid.error.erroByGenerateCatalog'));
            }

            vm.isPrinting = false;
            $rootScope.$emit('finish-generate-tabloid');
        }

        function errorPrint(response) {
            vm.isPrinting = false;
            $rootScope.$emit('finish-generate-tabloid');
            toastr.error($translate.instant('showcases.messages.tabloid.error.erroByGenerateCatalog'));
        }

        function getStoreInfo() {
            return StoreService.getStore($localStorage.defaultStore.storeId);
        }

        getStoreInfo().then(function(response) {
            $localStorage.defaultStore.storeType = response.data.storeType;
            $localStorage.defaultStore.storeNickname = response.data.nickname;
            vm.store = response.data;
        });

        /**
         * Verify if all showcases are selected
         * @returns {*|boolean} True if all showcases are selected
         */
        vm.isShowAllSelected = function () {
            vm.isAllSelected = vm.showcases.length > 0 && vm.showcasesToRemove.length === vm.showcases.length;
            return vm.isAllSelected;
        }

        /**
         * Select or deselect all bags.
         * If all bags are selected, deselect all the bags
         * If not all bags are selected, then selects all the bags
         */
        vm.changeSelectionOfAllShowcases = function () {
            if (vm.isAllSelected) {
                vm.deselectAllShowcases();
            } else {
                vm.selectAllShowcases();
            }

        }

        /**
         * Select all bags
         */
        vm.selectAllShowcases = function () {
            var showcasesToRemoveTemp = [];
            for (var i = 0; i < vm.showcases.length; i++) {
                showcasesToRemoveTemp.push(vm.showcases[i].id)
            }
            vm.showcasesToRemove = showcasesToRemoveTemp;
            vm.isAllSelected = true;
        }

        /**
         * Deselect all bags
         */
        vm.deselectAllShowcases = function () {
            vm.showcasesToRemove = [];
            vm.isAllSelected = false;
        }

        /**
         * Opens a modal to filter bags
         */
        vm.openFiltersModal = function () {
            vm.filtersConversationModal = $uibModal.open({
                templateUrl: 'app/admin/bag/modal-filter-bag/bag-filter-modal.html',
                controller: 'FiltersBagsController',
                windowClass: 'modal-center',
                windowTopClass: 'center-modal',
                controllerAs: 'vm',
                size: 'lg',
                keyboard: false,
                resolve: {
                    options: function () {
                        return {
                            categories: vm.categories,
                            brands: vm.brands,
                            colors: vm.colors,
                            sizes: vm.sizes,
                            customers: vm.customers,
                            canChangeView: true,
                            viewByCategories: vm.viewByCategories,
                            clearFilters: vm.clearFilters
                        }
                    },
                    filters: function () {
                        return vm.filters
                    },
                    list: function () {
                        return vm.listFilter
                    }
                },
            }).result.then(function (response) {
                if (response) {
                    // Refresh the page with selected filters
                    vm.numberFilterSelected = response.totalFilters;
                    vm.filters = response.selectedFilters;
                    vm.listFilter = response.listFilter;
                    vm.resetPage();
                }
            });
        }

        /**
         * Removes from database the selected bags
         */
        vm.removeShowcases = function() {
            if (vm.showcasesToRemove.length >= 1) {
                RemoveModalFactory.deleteItem(vm.showcasesToRemove.length).then(function(data) {
                    ShowcasesService.deleteShowcases(vm.showcasesToRemove).then(function(data) {
                        toastr.success($translate.instant('Mala removida com sucesso'));
                        vm.showcases = [];
                        vm.showcasesToRemove = [];
                        vm.userHasShowcases = false;
                        vm.resetPage();
                    }, function(data) {
                        toastr.error($translate.instant('showcases.messages.remove.error'));
                    });
                });
            } else {
                toastr.error($translate.instant('showcases.messages.remove.noItens'));
            }
        }

        vm.getImageSrc = function (showcaseId) {
            return "content/images/templates/"+ showcaseId +".png"
        }

    }
})();
