(function () {
    'use strict';
    angular
        .module('showcaseApp')
        .controller('StoreRatingController', StoreRatingController);

    function StoreRatingController(storeQualify, staticStoreData, userXP, $translate, $timeout) {

        var vm = this;

        vm.showkaseColors = {
            backgroundColor: "rgba(30,9,81,0.8)",
            borderColor: "rgba(153,0,0,1)",
            pointBackgroundColor: "rgba(153,0,0,1)",
            pointBorderColor: "#fff",
            pointHoverBackgroundColor: "rgba(30,9,81,0.8)",
            pointHoverBorderColor: "rgba(153,0,0,1)"
        }

        vm.storeQualify = storeQualify || undefined;
        vm.staticStoreData = staticStoreData || undefined;
        vm.userXP = userXP || undefined;
        vm.amountOfOpnions = 0;

        function loadAll(){
            $timeout(function() {
                window.dispatchEvent(new Event('resize'));
            }, 500);
        }

        vm.barChartOptions = {
            tooltips: {
                callbacks: {
                    title: function(tooltipItem, data) {
                        var title = "";
    
                        if (tooltipItem[0] && tooltipItem[0].xLabel) {
                            title = $translate.instant('storeRating.userXP.noteTitle') + tooltipItem[0].xLabel;
                        }
                        return title;
                    }
                }
            },
            scales: {
                yAxes: [{
                    ticks: {
                        // min: 0,
                        // max: 100,
                        // stepSize: 10,
                        beginAtZero: true,
                        callback: function (value) {
                            return value + "%";
                        }
                    },
                    scaleLabel: {
                        display: true,
                        labelString: $translate.instant('storeRating.userXP.answerPercentage')
                    }
                }],
                xAxes: [{
                    scaleLabel: {
                        display: true,
                        labelString: $translate.instant('storeRating.userXP.answerTitle')
                    }
                }]
            }
        }

        if (vm.storeQualify && vm.storeQualify.rateAvg > 0 ){
            vm.amountOfOpnions = 0;
            vm.storeQualify.storeQualifyRatingOptions = [{
                color: '#25d02a',
                allQuantity: 0,
                icon: 'fa fa-star',
                prefixValue: 5,
                percentage: '0%'
            },
            {
                color: '#25ec2b',
                allQuantity: 0,
                icon: 'fa fa-star',
                prefixValue: 4,
                percentage: '0%'
            },
            {
                color: '#edfb1e',
                allQuantity: 0,
                icon: 'fa fa-star',
                prefixValue: 3,
                percentage: '0%'
            },
            {
                color: '#ffb123',
                allQuantity: 0,
                icon: 'fa fa-star',
                prefixValue: 2,
                percentage: '0%'
            },
            {
                color: '#f73f3f',
                allQuantity: 0,
                icon: 'fa fa-star',
                prefixValue: 1,
                percentage: '0%'
            }
        ];

            vm.storeQualify.rating.forEach(function(element) {
                var listable = findElementByAttr(vm.storeQualify.storeQualifyRatingOptions, 'prefixValue', element.rate);
                if (listable) {
                    vm.amountOfOpnions += element.quantity;
                    listable.allQuantity  = element.quantity;
                    listable.percentage = element.ratePercentage + '%';
                }
            });
        }
        


        vm.showUpRecommendation = [
            {
                label: $translate.instant('storeRating.userXP.recommends') + "",
                value: vm.userXP ? vm.userXP.recommendationResult.recommends : 0
            },
            {
                label: $translate.instant('storeRating.userXP.notRecommends') + "",
                value: vm.userXP ? vm.userXP.recommendationResult.notRecommends : 0
            }
            
        ];

        vm.uxpTabs = [{
                name: 'storeRating.userXP.courtesy',
                active: true,
                data: vm.userXP ? vm.userXP.courtesyResult : undefined
            },
            {
                name: 'storeRating.userXP.fastness',
                active: false,
                data: vm.userXP ? vm.userXP.fastnessResult : undefined
            },
            {
                name: 'storeRating.userXP.productQuality',
                active: false,
                data: vm.userXP ? vm.userXP.productQualityResult : undefined
            },
            {
                name: 'storeRating.userXP.livedExperience',
                active: false,
                data: vm.userXP ? vm.userXP.livedExperienceResult : undefined
            }
        ];

        vm.selectTab = function (tab) {
            var tabSelected = findElementByAttr(vm.uxpTabs, 'active', true);
            if (tabSelected) tabSelected.active = false;
            if (tab) tab.active = true;
        }

        loadAll();

    }
})();
