(function () {
    'use strict';

    angular
        .module('showcaseApp')
        .factory('ErpsSettingsService', ErpsSettingsService);

    ErpsSettingsService.$inject = ['ErpsSettingsFactory'];

    function ErpsSettingsService(ErpsSettingsFactory) {

        var service = {
            createERPConfiguration: createERPConfiguration,
            updateERPConfiguration: updateERPConfiguration,
            getERPConfigurationByStoreIdAndPartnerId: getERPConfigurationByStoreIdAndPartnerId,
            getERPConfigurationByStoreId: getERPConfigurationByStoreId,
            getNexaasOrganizationsByToken: getNexaasOrganizationsByToken,
            getNexaasOrganizationStocks: getNexaasOrganizationStocks,
            startNexaasSynchronization: startNexaasSynchronization,
            deleteErpConfiguration: deleteErpConfiguration,
            createERPConfigurationForSoftcom: createERPConfigurationForSoftcom
        };

        return service;

        function createERPConfiguration(erpConfiguration) {
            return ErpsSettingsFactory.createERPConfiguration(erpConfiguration).$promise;
        }

        function updateERPConfiguration(erpConfiguration) {
            return ErpsSettingsFactory.updateERPConfiguration({ erpConfigurationId: erpConfiguration.id }, erpConfiguration).$promise;
        }

        function getERPConfigurationByStoreIdAndPartnerId(storeId, partnerId) {
            return ErpsSettingsFactory.getERPConfigurationByStoreIdAndPartnerId({ storeId: storeId, partnerId: partnerId }).$promise;
        }

        function getERPConfigurationByStoreId(storeId) {
            return ErpsSettingsFactory.getERPConfigurationByStoreId({ storeId: storeId }).$promise;
        }

        function getNexaasOrganizationsByToken(token) {
            return ErpsSettingsFactory.getNexaasOrganizationsByToken({ token: token }).$promise;
        }

        function getNexaasOrganizationStocks(token, organizationId) {
            return ErpsSettingsFactory.getNexaasOrganizationStocks({ token: token, organizationId: organizationId }).$promise;
        }

        function startNexaasSynchronization(storeId) {
            return ErpsSettingsFactory.startNexaasSynchronization(storeId).$promise;
        }

        function deleteErpConfiguration(id) {
            return ErpsSettingsFactory.deleteErpConfiguration({ id: id }).$promise;
        }

        function createERPConfigurationForSoftcom(storeId) {
            return ErpsSettingsFactory.createERPConfigurationForSoftcom({storeId: storeId}).$promise;
        }
    }
})();
