(function() {
    'use strict';
    angular
        .module('showcaseApp')
        .service('IndexedDBCommunication', IndexedDBCommunication);

    function IndexedDBCommunication($q) {
        var db;

        openDB();

        function openDB() {
            var requestDB = indexedDB.open('communicationDB', 1);

            requestDB.onsuccess = function (event) {
                db = event.target.result;

                db.onclose = function (error) {
                    openDB();
                }
            };

            requestDB.onerror = function (event) {
                console.error("Erro ao abrir o banco de dados:", event.target.error);
            };

            requestDB.onupgradeneeded = function (event) {
                db = event.target.result;
                var objectStore = db.createObjectStore('sending-files', { keyPath: 'id', autoIncrement: true });
                objectStore.createIndex('file-index', 'fileIndex', { unique: false });
            };
        }

        var service = {
            addSendingFiles: addSendingFiles,
            removeSendingFile: removeSendingFile,
            updateSendingFileStatus: updateSendingFileStatus,
            getSendingFileByLocalMessageId: getSendingFileByLocalMessageId,
            getSendingFileByFilenameAndConversationId: getSendingFileByFilenameAndConversationId,
            getSendingFileByConversatonId: getSendingFileByConversatonId,
            updateSendingFilesByStatus: updateSendingFilesByStatus,
            updateSendingFileProgress: updateSendingFileProgress
        };

        return service;

       function addSendingFiles(item, callback) {
            var transaction = db.transaction(['sending-files'], 'readwrite');
            var objectStore = transaction.objectStore('sending-files');
            var request = objectStore.add(item);

            request.onsuccess = function (event) {
                if (callback) callback(event.target.result);
            };

            request.onerror = function (event) {
                console.error("Erro ao adicionar item:", event.target.error);
            };
        };

        function updateSendingFilesByStatus(status, callback) {
            try {
                var transaction = db.transaction(['sending-files'], 'readwrite');
                var objectStore = transaction.objectStore('sending-files');
                var request = objectStore.openCursor();

                request.onsuccess = function (event) {
                    var cursor = event.target.result;
                    if (cursor) {
                        cursor.value.status = status;

                        if (cursor.value.message
                                && cursor.value.message.data
                                && cursor.value.message.data.fullData
                                && cursor.value.message.data.fullData.fullBase64) {
                            delete cursor.value.message.data.fullData.base64;
                            delete cursor.value.message.data.fullData.fullBase64;
                        }

                        objectStore.put(cursor.value);
                        cursor.continue();
                    } else {
                        if (callback) callback("Todos os objetos atualizados com sucesso");
                    }
                };

                request.onerror = function (event) {
                    console.error("Erro ao buscar objetos para atualização:", event.target.error);
                };
            } catch (error) {
                console.log(error)
            }
        }

        function updateSendingFileStatus(id, status, callback) {
            var transaction = db.transaction(['sending-files'], 'readwrite');
            var objectStore = transaction.objectStore('sending-files');

            var getRequest = objectStore.get(id);

            getRequest.onsuccess = function (event) {
                var existingItem = event.target.result;
                if (existingItem) {
                    // Atualize os campos desejados do objeto.
                    existingItem.status = status;

                    // Use o método put para atualizar o objeto.
                    var updateRequest = objectStore.put(existingItem);
                    updateReturn(updateRequest, callback);
                } else {
                    console.error("Objeto não encontrado com a chave primária:", id);
                }
            };

            getRequest.onerror = function (event) {
                console.error("Erro ao buscar objeto para atualização:", event.target.error);
            };
        }

        function updateSendingFileProgress(id, sendingProgress, callback) {
            var transaction = db.transaction(['sending-files'], 'readwrite');
            var objectStore = transaction.objectStore('sending-files');

            var getRequest = objectStore.get(id);

            getRequest.onsuccess = function (event) {
                var existingItem = event.target.result;
                if (existingItem) {
                    existingItem.message.sendingProgress = sendingProgress;
                    var updateRequest = objectStore.put(existingItem);
                    updateReturn(updateRequest, callback);
                } else {
                    console.error("Objeto não encontrado com a chave primária:", id);
                }
            };

            getRequest.onerror = function (event) {
                console.error("Erro ao buscar objeto para atualização:", event.target.error);
            };
        }

        function updateReturn(updateRequest, callback) {
            updateRequest.onsuccess = function () {
                if (callback) callback("Objeto atualizado com sucesso");
            };

            updateRequest.onerror = function (event) {
                console.error("Erro ao atualizar o objeto:", event.target.error);
            };
        }

        function getSendingFileByFilenameAndConversationId(filename, conversationId, callback) {
            var transaction = db.transaction(['sending-files'], 'readonly');
            var objectStore = transaction.objectStore('sending-files');
            var request = objectStore.openCursor();

            request.onsuccess = function (event) {
                var cursor = event.target.result;
                if (cursor) {
                    if (cursor.value.conversationId === conversationId &&
                        cursor.value.message.text === filename) {
                        if (callback) callback(cursor.value);
                        return; // Encerre a pesquisa após encontrar um correspondente
                    }
                    cursor.continue();
                } else {
                    if (callback) callback(null); // Se não for encontrado, retorne null
                }
            };

            request.onerror = function (event) {
                console.error("Erro ao buscar objeto por localMessageId:", event.target.error);
                if (callback) callback(null);
            };
        }

        function getSendingFileByLocalMessageId(localMessageId, callback) {
            var transaction = db.transaction(['sending-files'], 'readonly');
            var objectStore = transaction.objectStore('sending-files');
            var request = objectStore.openCursor();

            request.onsuccess = function (event) {
                var cursor = event.target.result;
                if (cursor) {
                    if (cursor.value.localMessageId === localMessageId) {
                        if (callback) callback(cursor.value);
                        return; // Encerre a pesquisa após encontrar um correspondente
                    }
                    cursor.continue();
                } else {
                    if (callback) callback(null); // Se não for encontrado, retorne null
                }
            };

            request.onerror = function (event) {
                console.error("Erro ao buscar objeto por localMessageId:", event.target.error);
                if (callback) callback(null);
            };
        }

        function getSendingFileByConversatonId(conversationId, callback) {
            var transaction = db.transaction(['sending-files'], 'readonly');
            var objectStore = transaction.objectStore('sending-files');
            var request = objectStore.openCursor();
            var items = [];

            request.onsuccess = function (event) {
                var cursor = event.target.result;
                if (cursor) {
                    if (cursor.value.conversationId === conversationId) {
                        items.push(cursor.value);
                    }
                    cursor.continue();
                } else {
                    if (callback) callback(items);
                }
            };

            request.onerror = function (event) {
                console.error("Erro ao buscar objeto por localMessageId:", event.target.error);
            };
        }

        function getSendingFiles(callback) {
            var transaction = db.transaction(['sending-files'], 'readonly');
            var objectStore = transaction.objectStore('sending-files');
            var request = objectStore.openCursor();
            var itens = [];

            request.onsuccess = function (event) {
                var cursor = event.target.result;
                if (cursor) {
                    itens.push(cursor.value);
                    cursor.continue();
                } else {
                    if (callback) callback(itens);
                }
            };

            request.onerror = function (event) {
                console.error("Erro ao listar itens:", event.target.error);
            };
        };

        function removeSendingFile(itemId, callback) {
            var transaction = db.transaction(['sending-files'], 'readwrite');
            var objectStore = transaction.objectStore('sending-files');
            var request = objectStore.delete(itemId);

            request.onsuccess = function (event) {
                if (callback) callback();
            };

            request.onerror = function (event) {
                console.error("Erro ao remover item:", event.target.error);
            };
        };

    }

})();
