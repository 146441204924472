(function() {
    'use strict';

    angular
        .module('showcaseApp')
        .controller('PasswordController', PasswordController);

    PasswordController.$inject = ['$scope', 'Auth', 'Principal', 'toastr', '$translate'];

    function PasswordController ($scope, Auth, Principal, toastr, $translate) {
        var vm = this;

        vm.changePassword = changePassword;

        Principal.identity().then(function(account) {
            vm.account = account;
        });

        function changePassword () {
            if (vm.password === vm.confirmPassword) {
                Auth.changePassword(vm.password).then(function () {
                    toastr.success($translate.instant('password.messages.success'));
                    $scope.form.$setPristine();
                    vm.password = null;
                    vm.confirmPassword = null;
                }).catch(function () {
                    toastr.error($translate.instant('password.messages.error'));
                });
            }
        }
    }
})();
