(function() {
    'use strict';

    angular
        .module('showcaseApp')
        .controller('StoreCategorysEditController', StoreCategorysEditController);

    StoreCategorysEditController.$inject = ['$timeout', '$localStorage', '$state', '$scope', '$stateParams', '$translate', '$uibModalInstance', 'CategoryService', 'toastr', 'category'];

    function StoreCategorysEditController($timeout, $localStorage, $state, $scope, $stateParams, $translate, $uibModalInstance, CategoryService, toastr, category) {
        var vm = this;
        vm.save = save;
        vm.clear = clear;
        vm.category = category;

        function save() {
          CategoryService.updateCategory(vm.category).then(function (data) {
            toastr.success($translate.instant('categorys.messages.edit.success'));
            $uibModalInstance.close(true);
          }, function () {
            toastr.error($translate.instant('categorys.messages.edit.error'));
          });
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

    }

})();
