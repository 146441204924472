(function () {
    'use strict';

    angular
        .module('showcaseApp')
        .controller('FormFirstStoreController', FormFirstStoreController);

    /** @ngInject */

    function FormFirstStoreController($rootScope, $scope, formStepsStates, $state, StoreService, $q, $translate, toastr, Auth, $window, $sessionStorage) {

        resetStateForm();

        $scope.store = {};

        $scope.logo = {
            imageUpload: '',
            imageChanged: false
        };

        $scope.maker = {
            actualMarker: 0
        };
        $scope.markers = [];

        $scope.submitted = false;

        $scope.updateInfoAndLogoStateStep = updateInfoAndLogoStateStep;
        $scope.fillNickname = fillNickname;

        $scope.domainSufix = '.' + getDomainNameSufix($window);

        $scope.isMobile = detectar_mobile();

        var storeDefaultImg = 'https://showkase-prod.s3.sa-east-1.amazonaws.com/hub/system-images/' +$rootScope.urlImage+ '/defaultImageCreateShowkase.png';

        function fillNickname(form) {
            form.nickname.$setTouched(); 
            if($scope.store.name) {
                var nameStore = $scope.store.name;
                nameStore = nameStore.replace(/[ÀÁÂÃÄÅàáâãäå]/g,"a");
                nameStore = nameStore.replace(/[ÈÉÊËèéêë]/g,"e");
                nameStore = nameStore.replace(/[ÍÌÎíìî]/g,"i");
                nameStore = nameStore.replace(/[çÇ]/g,"c");
                nameStore = nameStore.replace(/[^a-z0-9]/gi,'')
                $scope.store.nickname = nameStore.toLowerCase();
            } else {
                $scope.store.nickname = ''
            }
            this.updateInfoAndLogoStateStep(form.$valid);
            this.verifyNicknameIsAvailable(form);
        }

        function updateInfoAndLogoStateStep(value) {
            var stepIndex = _.findIndex(formStepsStates, function (o) {
                return o.uiSref === $state.current.name;
            });
            formStepsStates[stepIndex].valid = value;
            formStepsStates[stepIndex + 1].valid = value;
        }

        function resetStateForm() {
            angular.forEach(formStepsStates, function (state) {
                state.valid = false;
            });
        }

        $scope.verifyNicknameIsAvailable = function (form) {
            if ($scope.store.nickname) {
                StoreService.verifyIfNicknameAvailable($scope.store.nickname).then(function (result) {
                    if (!result.data) {
                        form.nickname.$setValidity('nicknameIsUnavailable', false);
                    } else {
                        form.nickname.$setValidity('nicknameIsUnavailable', true);
                    }
                }, function (error) {
                    form.nickname.$setValidity('nicknameIsUnavailable', false);
                });
            } else {
                form.nickname.$setValidity('nicknameIsUnavailable', true);
            }
        }

        $scope.$watch('store.nickname', function (newVal, oldVal) {
            if (newVal && newVal.length >= 20) {
                $scope.store.nickname = oldVal;
            }
        });

        $scope.verifyCnpjIsAvailable = function (form) {
            if ($scope.store.cnpj) {
                form.cnpj.$setValidity('cnpjInvalid', true);
                StoreService.verifyIfCnpjIsAvailable($scope.store.cnpj).then(function (result) {
                    if (!result.data) {
                        form.cnpj.$setValidity('cnpjIsUnavailable', false);
                    } else {
                        form.cnpj.$setValidity('cnpjIsUnavailable', true);
                    }
                }, function (error) {
                    form.cnpj.$setValidity('cnpjIsUnavailable', false);
                });
            } else {
                form.cnpj.$setValidity('cnpjIsUnavailable', true);
            }
        }

        function toDataURL(url, callback) {
            var xhr = new XMLHttpRequest();
            xhr.onload = function () {
                var reader = new FileReader();
                reader.onloadend = function () {
                    callback(reader.result);
                }
                reader.readAsDataURL(xhr.response);
            };
            xhr.open('GET', url);
            xhr.responseType = 'blob';
            xhr.send();
        }

        function dataURItoBlob(dataURI) {
            // convert base64/URLEncoded data component to raw binary data held in a string
            var byteString;
            if (dataURI.split(',')[0].indexOf('base64') >= 0) {
                byteString = atob(dataURI.split(',')[1]);
            }
            else {
                byteString = unescape(dataURI.split(',')[1]);
            }

            // separate out the mime component
            var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

            // write the bytes of the string to a typed array
            var ia = new Uint8Array(byteString.length);
            for (var i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
            }

            return new Blob([ia], {
                type: mimeString
            });

        }

        $scope.goToStorePoint = function (form) {
            if (form.storeInfoForm.$invalid) {
                form.storeInfoForm.$submitted = true;
                return;
            }
            $state.go('store-point');
        };

        $scope.saveStore = function (form) {
            if (form.$invalid) {
                form.salesPointForm.$submitted = true;
                $rootScope.$emit('form-invalid-sales-point');
                return;
            }
            
            if (!$scope.store.image) {
                toDataURL(storeDefaultImg, function (dataUrl) {
                    $scope.store.image = dataURItoBlob(dataUrl);
                    save();
                });
            } else {
                $scope.store.image = $scope.store.image.type != 'image/png' ? dataURItoBlob(dataUrl) : $scope.store.image;
                save();
            }
        }

        function save() {
            $rootScope.$emit('get-sales-point');

            $scope.isSaving = true;
            $scope.store.logo = $scope.logo.imageChanged ? $scope.store.image : '';
            $scope.store.cnpj = $scope.store.cnpj === "" ? null : $scope.store.cnpj;
            $scope.store.points = [];

            $scope.store.contactWhatsApp = ($scope.store.contactWhatsApp) ? $scope.store.contactWhatsApp : $scope.store.contactPhone;
            
            if ($sessionStorage.clientHub) {
                $scope.store.hubToken = $sessionStorage.clientHub;
            }

            $scope.store.hub
            var point = {
                "name": $rootScope.salespoint.pointlocation.name,
                "contactPhone": $rootScope.salespoint.pointlocation.contactPhone,
                "postalCode": $rootScope.salespoint.pointlocation.postalCode,
                "country": $rootScope.salespoint.pointlocation.country,
                "city": $rootScope.salespoint.pointlocation.city,
                "state": $rootScope.salespoint.pointlocation.state,
                "address": $rootScope.salespoint.pointlocation.address,
                "neighborhood": $rootScope.salespoint.pointlocation.neighborhood,
                "number": $rootScope.salespoint.pointlocation.number,
                "coordinate": {
                    "latitude": $rootScope.salespoint.latitude,
                    "longitude": $rootScope.salespoint.longitude
                },
                "mainPoint": true
            }
            $scope.store.points.push(point);

            $q.all($scope.store.points).then(function () {

                StoreService.saveStoreImage($scope.store.image).then(function (response, error) {
                    $scope.store.image = '';
                    $scope.store.logo = '';
                    $scope.store.addImageFileDTO = response.data;
                    StoreService.addStore($scope.store).then(function (data) {
                        Auth.changeSession(data.id).then(
                            function () {
                                $scope.isSaving = false;
                                toastr.success($translate.instant('store-management.messages.create.success'));
                                $state.transitionTo('home', {}, {
                                    reload: true,
                                    inherit: false,
                                    notify: true
                                });
                            });
                    }, function (data) {
                        $scope.isSaving = false;
                        toastr.error($translate.instant('store-management.messages.create.error'));
                    });
                });
            });
        }
    }
})();
