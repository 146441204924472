(function() {
    'use strict';

    angular
        .module('showcaseApp')
        .controller('DirectRegisterController', DirectRegisterController);


    DirectRegisterController.$inject = ['$translate', '$state', 'AuthServerProvider', 'DirectRegisterService', 'toastr', 'Principal', '$location', '$sessionStorage'];

    function DirectRegisterController($translate, $state, AuthServerProvider, DirectRegisterService, toastr, Principal, $location, $sessionStorage) {
        var vm = this;

        vm.firstStep = true;
        vm.loginOption = "login";

        vm.user = {};
        vm.authenticationError = false;

        function clearToken() {

        }
        getAndSaveHubToken();
        function getAndSaveHubToken() {
            if ($location.search().client) {
                $sessionStorage.clientHub = $location.search().client;
            }
        }

        vm.userRegister = function() {
            if (vm.firstStep) {
                if (vm.loginOrMail) {
                    DirectRegisterService.registerInit(vm.loginOrMail).then(function(response) {
                        var statuscode = response.headers.statuscode;
                        if (statuscode == 41) {
                            var params = {};
                            if (vm.loginOrMail.indexOf("@") != '-1') {
                                var emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
                                var isValidMail = emailPattern.test(vm.loginOrMail);
                                if (isValidMail) {
                                  params.email = vm.loginOrMail;
                                  $state.go('main-register', params);
                                } else {
                                  toastr.error($translate.instant('userManagement.error.invalidEmail'));
                                }
                            } else {
                                toastr.error($translate.instant('userManagement.error.invalidEmail'));
                            };
                        } else if (statuscode == 40) {
                            vm.user.email = response.data.email;
                            vm.user.login = response.data.login;
                            vm.user.firstName = response.data.firstName;
                            vm.userExists = true;
                            vm.firstStep = false;
                        } else {
                            toastr.error();
                        }
                    }, function(response) {
                        toastr.error($translate.instant('register.messages.alreadyHaveAdminAccount'));
                    });
                } else {
                    toastr.warning($translate.instant('register.messages.loginOrMailIsBlank', {
                        option: vm.loginOption
                    }));
                }
            } else if (!vm.firstStep) {
                if (vm.userExists) {
                    AuthServerProvider.logout();
                    DirectRegisterService.registerFinish(vm.user.login, vm.user.password).then(function(response) {
                        var bearerToken = response.headers['authorization'];
                        if (angular.isDefined(bearerToken) && bearerToken.slice(0, 7) === 'Bearer ') {
                            var jwt = bearerToken.slice(7, bearerToken.length);
                            AuthServerProvider.storeAuthenticationToken(jwt, false);
                        }
                        Principal.identity(true).then(function() {
                            $state.go('store-info');
                        });
                    }, function (error) {
                        vm.authenticationError = true;
                    });
                }
            }
        }

    }
})();
