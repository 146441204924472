(function() {
    'use strict';
    angular
        .module('showcaseApp')
        .factory('ShippingSettingsFactory', ShippingSettingsFactory);

    ShippingSettingsFactory.$inject = ['$resource'];

    function ShippingSettingsFactory($resource) {
        var resourceUrl = 'flyer/api/flyer/:flyerId';

        return $resource(resourceUrl, {}, {
            'diconnect': {
                method: 'DELETE',
                headers: { 'Accept': '*/*' },
                url: "/admin/api/shipping/diconnect",
                // transformResponse: function (data, headers) {

                //     var response = {};
                //     response.headers = headers();
                //     return data;
                // }
            },'saveCustomShipping': {
                method: 'POST',
                url: '/admin/api/customShipping',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },'getCustomShipping': {
                method: 'GET',
                url: '/admin/api/customShipping/store/:storeId',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },'getCustomShippingById': {
                method: 'GET',
                url: '/admin/api/customShipping/:id',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'getCustomShippingList': {
                method: 'GET',
                url: '/admin/api/customShipping/list/:storeId',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'updateCustomShipping': {
                method: 'PUT',
                url: '/admin/api/customShipping/:customShippingId',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'deleteCustomShipping': {
                method: 'DELETE',
                url: '/admin/api/customShipping/:id',
                transformResponse: function (data, headers) {
                    var response = {};
                    response.headers = headers();
                    return response;
                }
            }
        });
    }

})();
