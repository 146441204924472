(function() {
    'use strict';
    angular
        .module('showcaseApp')
        .factory('AuthorityFactory', AuthorityFactory);

    AuthorityFactory.$inject = ['$resource'];

    function AuthorityFactory ($resource) {
        var resourceUrl                 =   'api/authority/:id';
        var resourceGetAllWithHierarchy =   'api/authority-with-hierarchy'

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'getAllWithHierarchy': { 
                method:'GET',
                url: resourceGetAllWithHierarchy,
                isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            
            }
        });
    }
})();
