(function () {
    'use strict';

    angular
        .module('showcaseApp')
        .service('FileManagerService', FileManagerService);

    FileManagerService.$inject = ['FileManagerFactory', '$http'];

    function FileManagerService(FileManagerFactory, $http) {

        var service = {
            createEditFolder: createEditFolder,
            deleteFolder: deleteFolder,
            getFolderChildren: getFolderChildren,
            getFolderFiles: getFolderFiles,
            uploadFile: uploadFile,
            updateFile: updateFile,
            deleteFile: deleteFile,
            filterFiles: filterFiles,
            getFileTags: getFileTags
        };

        return service;

        function createEditFolder(hubId, folder) {
            if (folder.id) {
                return FileManagerFactory.editFolder({hubId: hubId}, folder).$promise;
            }
            return FileManagerFactory.createFolder({hubId: hubId}, folder).$promise;
        }

        function deleteFolder(hubId, folderId) {
            return FileManagerFactory.deleteFolder({ hubId: hubId, folderId: folderId }).$promise;
        }

        function getFolderChildren(hubId, folderId) {
            return FileManagerFactory.getFolderChildren({ hubId: hubId, folderId: folderId }).$promise;
        }

        function getFolderFiles (hubId, folderId) {
            return FileManagerFactory.getFolderFiles({ hubId: hubId, folderId: folderId }).$promise;
        }

        function getFileTags(hubId) {
            return FileManagerFactory.getFileTags({ hubId: hubId }).$promise;
        }



        function updateFile(hubId, file) {
            return FileManagerFactory.updateFile({ hubId: hubId }, file).$promise;
        }

        function filterFiles(hubId, name, tags) {
            return $http({
                method: 'GET',
                url: 'admin/api/gcs-manager/hub/' + hubId + '/file?tags=' +  tags + "&name=" + name,
            });
        }

        function uploadFile(hubId, data) {
            return $http({
                method: 'POST',
                url: 'admin/api/gcs-manager/hub/' + hubId + '/file' ,
                data: data,
                headers:{
                    'Content-Type': undefined,
                    'Accept':'application/json'
                }
            });
        }

        function deleteFile(hubId, fileId) {
            return FileManagerFactory.deleteFile({ hubId: hubId, fileId: fileId }).$promise;
        }
    }
})();
