    (function() {
    'use strict';

    angular
        .module('showcaseApp')
        .controller('RefundMagicModalController', RefundMagicModalController);

        RefundMagicModalController.$inject = ['invoice', 'InvoicesService', '$uibModalInstance', '$translate', 'toastr'];

    function RefundMagicModalController(invoice, InvoicesService, $uibModalInstance, $translate, toastr) {
        var vm = this;
        vm.currentStep = 1;
        vm.invoice = invoice;
        vm.refundValue = vm.invoice.totalCost + (vm.invoice.shippingValue ? vm.invoice.shippingValue : 0);

        vm.getDocumentType = function () {
            return vm.invoice.cpf.length === 11 ? 'CPF' : 'CNPJ';
        }

        vm.isRefundValueEqualToTotal = function() {
            if (!vm.refundValue) { return false; }
            if (typeof vm.refundValue !== 'number') { return false; }
            return vm.refundValue > 0 && vm.refundValue == (vm.invoice.totalCost + vm.getShippingValue());
        }

        vm.getShippingValue = function() {
            return vm.invoice.shippingValue ? vm.invoice.shippingValue : 0;
        }

        vm.refundTransaction = function() {
            InvoicesService.refundInvoice(vm.invoice.id, vm.refundValue).then(function(result) {
                console.log(result);
                toastr.success($translate.instant('invoice.refund.message.success.save'));
                $uibModalInstance.close();
            }).catch(function(err) {
                console.log(err);
                toastr.error($translate.instant('invoice.refund.message.error.save'));
            });
        }

        vm.closeModal = function(url) {
            $uibModalInstance.close(url);
        }

        vm.nextStep = function() {
            switch(vm.currentStep) {
                case 1:
                    vm.currentStep = vm.currentStep + 1;
                    break;
                case 2:
                    vm.currentStep = vm.currentStep + 1;
                    break;
            }
        }

        vm.backStep = function() {
            switch(vm.currentStep) {
                case 1:
                     vm.closeModal();
                     return;
                default:
                    vm.currentStep = vm.currentStep - 1;
                    break;
            }

        }

    }

})();
