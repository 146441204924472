(function () {
    'use strict';

    angular
        .module('showcaseApp')
        .factory('BlingService', BlingService);

    BlingService.$inject = ['BlingFactory'];

    function BlingService(BlingFactory) {

        var service = {
            syncProductsByStoreAndFlyerIds: syncProductsByStoreAndFlyerIds,
            refreshToken: refreshToken
        };

        return service;

        function syncProductsByStoreAndFlyerIds(storeId, flyerIds) {
            return BlingFactory.syncProductsByStoreAndFlyerIds({ storeId: storeId }, flyerIds).$promise;
        }

        function refreshToken(storeId) {
            return BlingFactory.refreshToken({ storeId: storeId }).$promise;
        }
    }
})();
