(function () {
    'use strict';

    angular
        .module('showcaseApp')
        .config(stateConfig);

    function stateConfig($stateProvider) {
        $stateProvider
            .state('store-rating', {
                parent: 'admin',
                url: '/reports/store-rating',
                data: {
                    pageTitle: "storeRating.title",
                    // specialClass: "md-skin fixed-nav"
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/admin/reports/store-rating/store-rating.html',
                        controller: 'StoreRatingController as vm'
                    }
                },
                resolve: {
                    userXP: function (StoreRatingService, $q) {
                        var deferred = $q.defer();

                        StoreRatingService.getUserXP().then(function (response) {
                            deferred.resolve(response.data);
                        }, function (error) {
                            deferred.resolve(null);
                        });

                        return deferred.promise;
                    },
                    storeQualify: function (StoreRatingService, $q) {

                        var deferred = $q.defer();

                        StoreRatingService.getStoreQualify().then(function (response) {
                            deferred.resolve(response.data);
                        }, function (error) {
                            deferred.resolve(null);
                        });

                        return deferred.promise;

                    },
                    staticStoreData: function (StoreRatingService, $q) {

                        var deferred = $q.defer();

                        StoreRatingService.getStaticStoreData().then(function (response) {
                            deferred.resolve(response.data);
                        }, function (error) {
                            deferred.resolve(null);
                        });

                        return deferred.promise;

                    },
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('store-rating');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            });

    }
})();
