(function () {
    'use strict'

    angular
        .module('showcaseApp')
        .controller('ModalBrandConstroller', ModalBrandConstroller);

    /** @ngInject */
    function ModalBrandConstroller(BrandService, toastr, $uibModalInstance, $translate) {
        var vm = this;

        vm.filterChange = filterChange
        vm.brands = [];
        vm.init = init;
        vm.brandFilter = { "id": "", "name": "" };
        vm.createBrand = createBrand;
        vm.disableButtonCreate = disableButtonCreate;
        vm.disableButtonEdit = disableButtonEdit;
        vm.loadingBrands = false;
        vm.getBrandById = getBrandById;
        vm.removeBrand = removeBrand;
        vm.editBrand = editBrand;
        vm.clearResults = clearResults;
        vm.editing = false;
        vm.editCancel = editCancel;
        vm.close = close;

        function init() {
            vm.brandFilter = { "id": "", "name": "" };
            filterChange(vm.brandFilter.name);
        }

        function clearResults() {
            vm.brands = [];
        }

        function filterChange() {
            if (vm.editing) return;
            vm.loadingBrands = true;
            BrandService.findBrand(vm.brandFilter.name).then(function (result) {
                vm.brands = result.data.slice();
                vm.loadingBrands = false;
            }, function (_) {
                vm.loadingBrands = false;
            });
        }

        function createBrand() {
            vm.loadingBrands = true;
            BrandService.createBrand(vm.brandFilter.name).then(function (_) {
                vm.init();
                toastr.success($translate.instant('brand.messages.success.create'));
                vm.loadingBrands = false;
            }, function (_) {
                toastr.warning($translate.instant('brand.messages.warning.alreadyExists'));
                vm.loadingBrands = false;
            });
        }

        function getBrandById(brand) {
            BrandService.getBrandById(brand.id).then(function (result) {
                vm.editing = true;
                vm.brandFilter = result.data;
                vm.clearResults();
            }, function (_) {
                toastr.warning($translate.instant('brand.messages.warning.notFound'));
                vm.loadingBrands = false;
            });
        }

        function editBrand() {
            BrandService.editBrand(vm.brandFilter).then(function (_) {
                vm.editing = false;
                vm.init();
                toastr.success($translate.instant('brand.messages.success.edit'));
            }, function (_) {
                toastr.warning($translate.instant('brand.messages.warning.alreadyExists'));
                vm.editing = false;
                vm.init();
            });
        }

        function removeBrand(brand) {
            BrandService.removeBrand(brand.id).then(function (_) {
                vm.brandFilter.name = "";
                vm.init();
                toastr.success($translate.instant('brand.messages.success.remove'));
                vm.loadingBrands = false;
            }, function (_) {
                toastr.warning($translate.instant('brand.messages.warning.withRegisteredProducts'));
                vm.loadingBrands = false;
            });
        }

        function disableButtonCreate() {
            if (vm.loadingBrands || vm.brandFilter.name.length === 0) return true;
        }


        function disableButtonEdit() {
            if (vm.brandFilter.name.length === 0) return true;
            if (vm.brands.id === "") return true;
        }

        function editCancel() {
            vm.editing = false;
            vm.init();
        }

        function close () {
            $uibModalInstance.close();
        }
    }

})();

