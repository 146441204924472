(function() {
    'use strict';

    angular
        .module('showcaseApp')
        .service('UserService', UserService);

    UserService.$inject = ['UserFactory'];

    function UserService(UserFactory) {
        var service = {
                getUsers: getUsers,
                getUser: getUser,
                createUser: createUser,
                addUser: addUser,
                deleteUser: deleteUser,
                updateUser: updateUser,
                listUsers: listUsers,
                listUsersByStoreId: listUsersByStoreId,
                findByEmail: findByEmail,
                listUserGenders: listUserGenders,
                listUserGendersToSelectComponent: listUserGendersToSelectComponent,
                getUsersSellers: getUsersSellers

        };

        return service;

        function getUser(userInfo) {
            return UserFactory.get(userInfo).$promise;
        }

        function getUsers() {
            return UserFactory.query({}).$promise;
        }

        function getUsersSellers(params) {
            return UserFactory.listUserSellers(params).$promise;
        }

        function createUser(user) {
            return UserFactory.save(user).$promise;
        }

        function addUser(email, phone, profileId, storeId){
            return UserFactory.addUser({
                email: email,
                phone: phone,
                profileId: profileId,
                storeId: storeId
            }).$promise;
        }

        function updateUser(user) {
            return UserFactory.update(user).$promise;
        }

        function deleteUser(userInfo) {
            return UserFactory.delete(userInfo).$promise;
        }

        function listUsers(pageable) {
            return UserFactory.get(pageable).$promise;
        }

        function listUsersByStoreId(pageable, userStoreId, params) {
            pageable.storeId = userStoreId;
            return UserFactory.listByParams(pageable, params).$promise;
        }
        
        function findByEmail(email) {
        	return UserFactory.findByEmail(email).$promise;
        }
        
        function listUserGenders() {
        	return UserFactory.listUserGenders().$promise;
        }

        function listUserGendersToSelectComponent() {
            return UserFactory.listUserGendersToSelectComponent().$promise;
        }
    }
})();
