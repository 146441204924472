(function() {
    'use strict';

    angular
        .module('showcaseApp')
        .controller('HubManagementController', HubManagementController);

    function HubManagementController($localStorage, Auth, $q, $translate,  $state, CategoryService, FlyersService, HubService,
        paginationConstants, $timeout, Principal, $location) {
        var vm = this;
        vm.searchFilter = {};
        vm.displayTypeList = ["Cards", "Table"];
        vm.displayTypeListCheck = [];
        vm.selectedStoreList = [];
        vm.storesDataFiltered = [];
        vm.storesData = [];
        vm.categories = [];
        vm.storesToRemove = 0;
        vm.loadingArray = ["1", "2", "3", "4"];
        vm.isLoading = true;
        vm.changeSession = changeSession;
        vm.hub;
        vm.page = 0;
        vm.itemsPerPage = paginationConstants.itemsPerPage - 10;
        vm.hasNextPage = false;
        vm.busy = false;
        loadAll();

        vm.checkType = function(type) {
            vm.createArray("displayType", vm.displayTypeList.length, false);
            vm.displayTypeListCheck[vm.displayTypeList.indexOf(type)] = true;
            vm.displayType = type;
        }

        vm.checkDisplayType = function(type) {
            return (vm.displayType === type);
        }

        vm.isEven = function(number) {
            return (number % 2 == 0);
        };

        vm.changeType = function() {
            vm.displayType = vm.displayType === 'Cards' ? 'Table' : 'Cards';
        };

        vm.stopPropagation = function(event) {
            event.stopPropagation();
        };

        vm.createArray = function(type, number, value, array) {
            if (type === "displayType") {
                vm.displayTypeListCheck = [];
                for (var i = 0; i < number; i++) {
                    vm.displayTypeListCheck.push(value);
                }
            }
        }

        vm.reloadStores = function(page, reset) {
            if(reset) {
                vm.page = 0;
                vm.storesData = [];
            }else {
                vm.page = page;
            }
            loadStores();
        }

        vm.reset = function reset() {
            vm.page = 0;
            vm.flyersFiltered = [];
        };

        vm.storeDetailsPage = function(storeId) {
            $state.go('store-management-detail', {
                id: storeId
            });
        }

        vm.checkStoreClickAction = function(storeId) {
            if ($localStorage.defaultStore.storeId == 0) {
                vm.storeDetailsPage(storeId);
            } else {
                vm.changeSession(storeId)
            }
        }

        vm.loadStoreImage = function(store) {
            var random = new Date().getTime();
            store.image = undefined;
            $timeout(function() {
                store.image = (store.storeLogo + '?r=' + random);
            }, 1000);
        }

        function changeSession(storeId) {
            Auth.changeSession(storeId).then(
                function() {
                    $state.transitionTo('home', {}, {
                        reload: true,
                        inherit: false,
                        notify: true
                    });

                    // previousState was set in the authExpiredInterceptor before being redirected to login modal.
                    // since login is successful, go to stored previousState and clear previousState
                    if (Auth.getPreviousState()) {
                        var previousState = Auth.getPreviousState();
                        Auth.resetPreviousState();
                        $state.go(previousState.name, previousState.params);
                    }
                });
        }

        function loadAll() {
            loadStores();
            loadCategories();
            loadHub();
        }

        function loadHub() {
            vm.hub = $localStorage.hub;
        }

        function loadCategories() {
            CategoryService.listCategories($translate.use()).then(function(response) {
                vm.categories = response.data;
            });
        }

        function loadCategoriesName() {
            vm.storesDataFiltered.forEach(function(store) {
                CategoryService.getCategoryTitleByKey(store.categoryKey, $translate.use()).then(function(response) {
                    store.categoryName = response.data;
                });
            });
        }

        function loadNumberOfFlyers() {
            vm.storesDataFiltered.forEach(function(store) {
                FlyersService.getNumberOfFlyers(store.id, $translate.use()).then(function(response) {
                    store.numberOfFlyers = response.data;
                });
            });
        }


        function loadStores() {
            if(vm.busy) return;
            vm.busy = true;
            vm.searchFilter.hub = true;
            var pageable = {
                page: vm.page,
                size: vm.itemsPerPage,
                name: vm.searchFilter.name
            };

            HubService.getStores(pageable, onSuccess, onError);

            function onSuccess(data, headers) {
                vm.hasNextPage = headers('X-Has-Next-Page') === "true";
                for (var i = 0; i < data.length; i++) {
                    if ($localStorage.userId != data[i].ownerId) {
                        vm.loadStoreImage(data[i]);
                        vm.storesData.push(data[i]);
                    }
                }
                vm.storesDataFiltered = vm.storesData;
                $q.all(vm.storesDataFiltered).then(function() {
                    vm.displayType = vm.storesDataFiltered.length > 1 ? 'Cards' : 'Table';
                    loadCategoriesName();
                    loadNumberOfFlyers();
                    vm.isLoading = false;
                });

                vm.busy = false;
            }

            function onError() {
                vm.busy = false;
            }
        }

        vm.getHubSharedLink = function () {
            return $location.protocol() + '://' + $location.host() + '/direct-register\?' + 'client='+vm.hub.token;;
        }

        vm.share = function () {
            var text_to_share = vm.getHubSharedLink();

            // create temp element
            var copyElement = document.createElement("span");
            copyElement.appendChild(document.createTextNode(text_to_share));
            copyElement.id = 'tempCopyToClipboard';
            angular.element(document.body.append(copyElement));

            // select the text
            var range = document.createRange();
            range.selectNode(copyElement);
            window.getSelection().removeAllRanges();
            window.getSelection().addRange(range);

            // copy & cleanup
            document.execCommand('copy');
            window.getSelection().removeAllRanges();
            copyElement.remove();
        }
    }
})();
