(function() {
    'use strict';
    angular
        .module('showcaseApp')
        .service('RedeemCouponsService', RedeemCouponsService);

    RedeemCouponsService.$inject = ['RedeemCouponsFactory'];

    function RedeemCouponsService(RedeemCouponsFactory) {

        return {
            redeemCoupon: redeemCoupon,
            validateCoupon: validateCoupon
        }

        function redeemCoupon(dynamicCode, redeemSalesPointId) {
            return RedeemCouponsFactory.redeem({
                dynamicCode: dynamicCode,
                redeemSalesPointId: redeemSalesPointId
            }).$promise;
        }

        function validateCoupon(dynamicCode, salesPointId) {
            return RedeemCouponsFactory.validate({
                dynamicCode: dynamicCode,
                salesPointId: salesPointId
            }).$promise;
        }
    }
})();
