(function() {
    'use strict';
    angular
        .module('showcaseApp')
        .value('FlyersCurrencyValue', {
            EURO: "€",
            DOLLAR: "$",
            REAL: "R$",
            LIBRA: "£"
        });
})();
