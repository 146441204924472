(function() {
    'use strict';
    angular
        .module('showcaseApp')
        .service('TeamService', TeamService);

        TeamService.$inject = ['TeamFactory', '$localStorage'];

    function TeamService(TeamFactory, $localStorage) {
        var service = {
                get: get,
                list: list,
                create: create,
                update: update,
                remove: remove
        };

        return service;

        function get(id) {
            return TeamFactory.get({id: id}).$promise;
        }

        function list(params) {
            return TeamFactory.list(params).$promise;
        }

        function create(team) {
            return TeamFactory.create(team).$promise;
        }

        function update(id, team) {
            team.id = id;
            return TeamFactory.update({id: id}, team).$promise;
        }

        function remove(id) {
            return TeamFactory.remove({id: id}, {}).$promise;
        }
    }
})();
