(function () {
    'use strict';
    angular
        .module('showcaseApp')
        .factory('StoreRatingFactory', StoreRatingFactory);

    function StoreRatingFactory($resource) {
        var resourceUrl = '/admin/api/rate';
        var resourceGetUserXP = resourceUrl + '/user-xp-results';
        var resourceGetStaticStoreData = resourceUrl + '/static-store-data-result';
        var resourceGetStoreQualify = resourceUrl + '/store-qualify-result';

        return $resource(resourceUrl, {}, {
        	'getUserXP': {
            	method: 'GET',
            	url: resourceGetUserXP,
            	transformResponse: function (data, headers) {
                    var response = {};
                    
                    if (data){
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();

                    return response;
                }
            },
            'getStaticStoreData': {
            	method: 'GET',
            	url: resourceGetStaticStoreData,
            	transformResponse: function (data, headers) {
                    var response = {};
                    
                    if (data){
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();

                    return response;
                }
            },
            'getStoreQualify': {
            	method: 'GET',
            	url: resourceGetStoreQualify,
            	transformResponse: function (data, headers) {
                    var response = {};
                    
                    if (data){
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();

                    return response;
                }
            }
        });
    }

})();
