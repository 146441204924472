(function() {
    'use strict';

    angular
        .module('showcaseApp')
        .controller('LogoutModalController', LogoutModalController);

        LogoutModalController.$inject = ['$uibModalInstance', 'watiSettingId', 'socialMedia', 'WatiService', 'SocialMediaService', 'communicationConstants'];

    function LogoutModalController($uibModalInstance, watiSettingId, socialMedia, WatiService, SocialMediaService, communicationConstants) {
        var vm = this;

        if(socialMedia == communicationConstants.SOCIAL_MEDIAS.WATI){
            vm.logoutSocialMediaText = "WhatsApp Bussiness API";
            vm.socialMedia = "WATI";
        } else if(socialMedia == communicationConstants.SOCIAL_MEDIAS.FACEBOOK) {
            vm.logoutSocialMediaText = "Facebook/Instagram";
            vm.socialMedia = "FACEBOOK";
        }

        vm.closeModal = function(response) {
            $uibModalInstance.close(response);
        }

        vm.logoutOfWhatsapp = function(){
            WatiService.deleteConfigByStoreId(watiSettingId).then(function(watiConfig){
                vm.closeModal({isLogginOut: true, socialMedia: communicationConstants.SOCIAL_MEDIAS.WATI});
            })
        };

        vm.facebookDisconnect = function(){
            SocialMediaService.facebookDisconnect().then(function(response){
                vm.closeModal({isLogginOut: true, socialMedia: communicationConstants.SOCIAL_MEDIAS.FACEBOOK});
            });
        }

    }

})();
