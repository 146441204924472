(function () {
    'use strict';

    angular
        .module('showcaseApp')
        .controller('FormStorePointController', FormStorePointController);

    /** @ngInject */
    function FormStorePointController($rootScope, $scope, $uibModal, CheckCEPService, GoogleMapsService) {


        var vm = this;

        const ICON_INACTIVE = 'https://showkase-prod.s3-sa-east-1.amazonaws.com/hub/system-images/'+$rootScope.urlImage+'/pointactive.png';

        vm.store = $scope.store;

        vm.formInvalid = false;

        vm.mainMap = {center: { latitude: 0, longitude: 0 }, zoom: 2, control: {}, bounds: {}, markers: $scope.markers};

        getLocation();

        vm.options= {
            fullscreenControl: false
        };
        
        vm.mainMap.window = {
            model: {},
            show: false,
            options: {
                pixelOffset: {width: -1, height: -20}
            },
            closeClick: function() {
                this.show = false;
            }
        }
        vm.mainMap.markerEvents = {
            click: function(marker, eventName, model, args) {
                vm.mainMap.window.model = model;
                vm.mainMap.window.show = true;
            }
        }


        vm.addSalesPoint = function() {
            $uibModal.open({
                templateUrl: 'app/admin/store-management/store-management-newsalespoint.html',
                controller: 'AddSalesPointManagementController',
                controllerAs: 'vm',
                scope: $scope,
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    pointlocation: function() {
                        return $scope.parameter;
                    },
                    pointparent: function() {
                        return $scope.$parent.vm;
                    }
                }
            });
        }

        $scope.$on('salesPointUpdated', function(event, data) {
            vm.mainMap.window.show = false;
            var markerUpdated;
            vm.mainMap.markers.forEach(function (marker) {
                if(marker.id === data.id) {
                    markerUpdated = marker;
                }
            });
            vm.mainMap.markers[vm.mainMap.markers.indexOf(markerUpdated)] = data;
        });

        $scope.$on('salesPointRemoved', function(event, data) {
            vm.mainMap.markers.splice(vm.mainMap.markers.indexOf(data), 1);
            vm.mainMap.window.show = false;
        });

        $scope.$on('salesPointCreated', function(event, data) {
            data.id =  $scope.maker.actualMarker;
            data.options = {icon: ICON_INACTIVE};
            vm.mainMap.markers.push(data);
             $scope.maker.actualMarker++;
            vm.mainMap.window.show = false;
        });


        function getLocationSuccFn(position) {
            vm.mainMap.center.latitude = position.coords.latitude;
            vm.mainMap.center.longitude = position.coords.longitude;
            vm.mainMap.control.refresh({latitude: vm.mainMap.center.latitude, longitude: vm.mainMap.center.longitude});
        }

        function getLocation() {
            if(navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(getLocationSuccFn, function (error) {}, {timeout: 1000});
            }
        }

        vm.checkCEP = function() {
             if (vm.salespoint.pointlocation.postalCode && vm.salespoint.pointlocation.postalCode.length === 8) {
                 CheckCEPService.getAddress(vm.salespoint.pointlocation.postalCode).then(function(response) {
                     if (response.data){
                     vm.salespoint.pointlocation.country = "BRASIL";
                     vm.salespoint.pointlocation.city = response.data.location.name;
                     vm.salespoint.pointlocation.state = response.data.location.uf;
                     vm.salespoint.pointlocation.address = response.data.name;
                     vm.salespoint.pointlocation.neighborhood = response.data.district.name; 
                     vm.refreshMapWithAddress();
                     }
                 });
             }
         }
 
         vm.refreshMapWithAddress = function() {
             if (vm.salespoint.pointlocation){
                 GoogleMapsService.getAddress((vm.salespoint.pointlocation.number + ", " + vm.salespoint.pointlocation.address + ", " + vm.salespoint.pointlocation.neighborhood + ", " + vm.salespoint.pointlocation.city + ", " + vm.salespoint.pointlocation.state + ", " + vm.salespoint.pointlocation.country).replace(/undefined/g, '').replace(/null/g, '')).then(function(data) {
                     vm.salespoint.latitude = data.geometry.location.lat();
                     vm.salespoint.longitude = data.geometry.location.lng();
                 });
             }
         }
         
         function loadCountrysAndStates() {
             CheckCEPService.listStates().then(function (response) {
                 vm.states = response.data;
             });
             CheckCEPService.listCountrys().then(function (response) {
                 vm.countrys = response.data;
             });
             vm.salespoint = {
                "pointlocation" : {
                    "contactPhone" : $scope.store.contactPhone,
                    "name": $scope.store.name
                }
             }
         }

        $rootScope.$on('form-invalid-sales-point', function () {
            vm.formInvalid = true;
        });

        $rootScope.$on('get-sales-point', function () {
            $rootScope.salespoint = vm.salespoint;
        });
        
         loadCountrysAndStates();
    }
})();
