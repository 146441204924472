(function () {
    'use strict'

    angular
        .module('showcaseApp')
        .controller('SelectColorsModalConstroller', SelectColorsModalConstroller);

    /** @ngInject */
    function SelectColorsModalConstroller($uibModalInstance, $scope, colors) {

        var vm = this;
        vm.colorForm = colors;

        setTimeout(function () {
            loadBackgroundColorPicker();
            loadHeaderBackgroundColorPicker();
            loadIconColorPicker();
        });

        function loadBackgroundColorPicker() {
            var backgroundColorContent = document.querySelector('.background-color-picker');
            var backgroundDiv = document.createElement('div');
            backgroundColorContent.appendChild(backgroundDiv);

            vm.backgroundPickr = Pickr.create({
                el: backgroundDiv,
                theme: 'monolith',
                default: vm.colorForm.backgroundColor,
                swatches: ['#F44336', '#9C27B0', '#3F51B5',
                    '#03A9F4', '#009688', '#4CAF50', '#FFEB3B',
                    '#000000', '#3E3E3E', '#787878', '#A0A0A0',
                    '#AAAAAA', '#DEDEDE', '#FFFFFF'],
                components: {
                    opacity: false,
                    hue: true,
                    preview: true,
                    interaction: {
                        input: true,
                        save: true
                    }
                },
                i18n: {
                    'btn:save': 'Alterar',
                }
            });

            vm.backgroundPickr.on('save', function (color, instance) {
                vm.colorForm.backgroundColor = angular.copy(color.toHEXA().toString());
                $scope.$apply();
                vm.backgroundPickr.hide();
            });
        }

        function loadHeaderBackgroundColorPicker() {
            var backgroundColorContent = document.querySelector('.header-color-picker');
            var backgroundDiv = document.createElement('div');
            backgroundColorContent.appendChild(backgroundDiv);

            vm.headerPickr = Pickr.create({
                el: '.header-color-picker',
                theme: 'monolith',
                default: vm.colorForm.headerBackgroundColor ? vm.colorForm.headerBackgroundColor : vm.colorForm.backgroundColor,
                swatches: ['#F44336', '#9C27B0', '#3F51B5',
                    '#03A9F4', '#009688', '#4CAF50', '#FFEB3B',
                    '#000000', '#3E3E3E', '#787878', '#A0A0A0',
                    '#AAAAAA', '#DEDEDE', '#FFFFFF'],
                components: {
                    opacity: false,
                    hue: true,
                    preview: true,
                    interaction: {
                        input: true,
                        save: true
                    }
                },
                i18n: {
                    'btn:save': 'Alterar',
                }
            });

            vm.headerPickr.on('save', function (color, instance) {
                vm.colorForm.headerBackgroundColor = angular.copy(color.toHEXA().toString());
                $scope.$apply();
                vm.headerPickr.hide();
            });
        }

        function loadIconColorPicker() {
            var backgroundColorContent = document.querySelector('.icon-color-picker');
            var backgroundDiv = document.createElement('div');
            backgroundColorContent.appendChild(backgroundDiv);

            vm.iconColorPickr = Pickr.create({
                el: '.icon-color-picker',
                theme: 'monolith',
                default: vm.colorForm.iconColor ? vm.colorForm.iconColor : '#FFFFFF',
                swatches: ['#F44336', '#9C27B0', '#3F51B5',
                    '#03A9F4', '#009688', '#4CAF50', '#FFEB3B',
                    '#000000', '#3E3E3E', '#787878', '#A0A0A0',
                    '#AAAAAA', '#DEDEDE', '#FFFFFF'],
                components: {
                    opacity: false,
                    hue: true,
                    preview: true,
                    interaction: {
                        input: true,
                        save: true
                    }
                },
                i18n: {
                    'btn:save': 'Alterar',
                }
            });

            vm.iconColorPickr.on('save', function (color, instance) {
                vm.colorForm.iconColor = angular.copy(color.toHEXA().toString());
                $scope.$apply();
                vm.iconColorPickr.hide();
            });
        }

        vm.closeModal = function () {
            $uibModalInstance.close();
        }

        vm.save = function() {
            $uibModalInstance.close(vm.colorForm);
        }
    }

})();
