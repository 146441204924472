(function() {
    "use strict";

    angular
        .module("showcaseApp")
        .factory("FBAuthModalFactory", FBAuthModalFactory);


    /** @ngInject */
    function FBAuthModalFactory($uibModal) {

        var factory = {
            connect: connect
        };

        return factory;

        function connect(isCommunication) {
            return $uibModal.open({
                templateUrl: 'app/integrations/facebook-business/auth-modal/fb-auth.modal.html',
                controller: 'FBAuthModalController',
                controllerAs: 'vm',
                size: 'lg',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    isCommunication: function() {
                        return isCommunication;
                    }
                }
            }).result;
        }
    }

})();
