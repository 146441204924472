(function() {
    'user strict'

    angular
        .module('showcaseApp')
        .controller('ShippingSettingsController', ShippingSettingsController);

    //** NgInject */
    function ShippingSettingsController(toastr,  StoreService, $timeout, $localStorage,
        $translate, ModalFactory, ShippingService, $location, RemoveModalFactory) {
        var vm = this;
        vm.linksWirecard = {};
        vm.moipInfo = {};
        vm.hasShipping = false;
        vm.store = $localStorage.defaultStore;
        vm.loading = false;
        vm.serviceUrl = "";
		vm.islocked = $localStorage.isMoipPlanFree;
        vm.customShipping = null;
        vm.customShippingList = [];
        vm.isActive = true;
        vm.isSaving = false;
        vm.isViewForm = false;
        var queryParams = $location.search();
        vm.currencyPrefix = getCurrencyListConfiguration()['REAL'];

        init();

        function init() {
            loadStoreData();
            loadCustomShippingList(vm.store.storeId);
            
            if (queryParams.error) {
                switch (queryParams.error) {
                    case 'MELHOR_ENVIO_NOT_SEND_CODE':
                        toastr.error($translate.instant('settings.shipping.error.MELHOR_ENVIO_NOT_SEND_CODE'));
                        break;
                    case 'MELHOR_ENVIO_NOT_SEND_TOKEN':
                        toastr.error($translate.instant('settings.shipping.error.MELHOR_ENVIO_NOT_SEND_TOKEN'));
                        break;
                    case 'MELHOR_ENVIO_CNPJ_INVALID':
                        toastr.error($translate.instant('settings.shipping.error.MELHOR_ENVIO_CNPJ_INVALID'));
                        break;
                    case 'MELHOR_ENVIO_CNPJ_DIFFERENT':
                        toastr.error($translate.instant('settings.shipping.error.MELHOR_ENVIO_CNPJ_DIFFERENT'));
                        break;
                    case 'UNAVAILABLE':
                        toastr.error($translate.instant('settings.shipping.error.UNAVAILABLE'));
                        break;
                    case 'NONE':
                        toastr.success($translate.instant('settings.shipping.successConnected'));
                        break;
                    default:
                        toastr.error($translate.instant('settings.shipping.error.UNAVAILABLE'));
                        break;
                }
            }
        }

        function actualyDisconnect() {
            vm.loading = true;
            ShippingService.disconnectFromShippingGateway(vm.store.storeId).then(function(response) {
                vm.checkIfIsConnected()
            });
        }

        vm.disconect = function () {
            ModalFactory.openSimpleModal(
                $translate.instant('settings.shipping.sureDisconnectTitle'),
                $translate.instant('settings.shipping.sureDisconnectQuestion'),
                $translate.instant('settings.shipping.yes'),
                $translate.instant('settings.shipping.no'),
                actualyDisconnect);
        }

        vm.checkIfIsConnected = function () {
            vm.loading = true;
            StoreService.checkShippingConnected(vm.store.storeId).then(function(response) {
                vm.loading = false
                if (response){
                    vm.hasShipping = response.hasShipping;
                    vm.serviceUrl = response.serviceUrl;
                }

            })
        }

		vm.openModal = function(){
			$localStorage.isLocked = true;
		}

        vm.authShipping = function() {
            vm.loading = true;
            StoreService.authorizeShipping().then(function(response) {
                // vm.checkIfIsConnected()
            }, function(){
                vm.loading = false;
            });
        }

        vm.checkIfIsConnected();

        vm.createUpdateCustomShipping = function() {
            if (vm.customShipping.id) {
                updateCustomShipping();
            } else {
                saveCustomShipping();
            }
        }

        vm.disableCustomShipping = function (customShipping) {
            ShippingService.updateCustomShipping(customShipping).then(
                function (response) {
                    vm.isSaving = false;
                    vm.error = null;
                    vm.success = 'OK';
                    if (vm.customShipping) {
                        vm.customShipping.isActive = customShipping.isActive
                    }
                    vm.customShipping.isActive = customShipping.isActive;
                    toastr.success($translate.instant('settings.custom-shipping.messages.update'));
                },
                function (err) {
                    vm.isSaving = false;
                    vm.success = null;
                    toastr.error(
                        $translate.instant('settings.store.messages.error.fail')
                    );
                }
            );
        }

        function removeEmptyZipcodes() {
            if (vm.customShipping.zipCodes && vm.customShipping.zipCodes.length > 0) {

                _.remove(vm.customShipping.zipCodes, function (zipcode) {
                    return (!zipcode.initialZipCode && !zipcode.finalZipCode)
                        || (zipcode.initialZipCode === '' && zipcode.finalZipCode === '')
                });
            }
        }

        function saveCustomShipping() {
            vm.isSaving = true;
            vm.customShipping.isActive = true;
            vm.customShipping.storeId = vm.store.storeId;
            if (!vm.customShipping.isActiveTotalWeight) {
                vm.customShipping.isActiveTotalWeight = false;
                vm.customShipping.totalWeight = 0;
            }
            if (!vm.customShipping.isActiveTotalPurchase) {
                vm.customShipping.isActiveTotalPurchase = false;
                vm.customShipping.totalPurchase = 0;
            }

            removeEmptyZipcodes();

            ShippingService.addCustomShipping(vm.customShipping).then(
                function (response) {
                    vm.isSaving = false;
                    vm.error = null;
                    vm.success = 'OK';
                    processResponse('settings.custom-shipping.messages.insert');
                },
                function (err) {
                    vm.isSaving = false;
                    vm.success = null;
                    vm.error = err;
                    processResponse();
                }
            );
        };

        function updateCustomShipping() {
            vm.isSaving = true;
            vm.customShipping.storeId = vm.store.storeId;
            if (!vm.customShipping.isActiveTotalWeight) {
                vm.customShipping.totalWeight = 0;
            }
            if (!vm.customShipping.isActiveTotalPurchase) {
                vm.customShipping.totalPurchase = 0;
            }

            removeEmptyZipcodes();

            ShippingService.updateCustomShipping(vm.customShipping).then(
                function (response) {
                    vm.isSaving = false;
                    vm.error = null;
                    vm.success = 'OK';
                    processResponse('settings.custom-shipping.messages.update');
                },
                function (err) {
                    vm.isSaving = false;
                    vm.success = null;
                    vm.error = err;
                    processResponse();
                }
            );
        };

        function processResponse(message) {
            if (vm.error) {
                if (vm.error && vm.error.data && vm.error.data.headers.statuskey === 'customShippingAlreadyExists'){
                    toastr.error($translate.instant('settings.custom-shipping.already-exists'));
                } else {
                    toastr.error($translate.instant('settings.store.messages.error.fail'));
                }
                vm.error = undefined;
            } else if (vm.success && vm.success == 'OK') {
                vm.isViewForm = false;
                vm.success = undefined;
                toastr.success($translate.instant(message));
                loadCustomShippingList(vm.store.storeId);
            }
        }

        function loadCustomShippingList(storeId) {
            ShippingService.getCustomShippingList(storeId)
                .then(function (response) {
                    vm.customShippingList = response.data
                    if (!vm.customShippingList.length) {
                        vm.isViewForm = true;
                        vm.customShipping = null;
                    }
                });
        }

        function loadStoreData() {
            vm.loading = true;
            StoreService.getStore(vm.store.storeId).then(function(response) {
                vm.loading = false
                if (response){
                    vm.fullStore = response.data;
                    loadCurrencyPrefix(response.data);
                }
            })
        }

        function loadCurrencyPrefix(store) {
            var key = store.currency ? store.currency : 'REAL';
            var config = getCurrencyListConfiguration()[key];
            vm.currencyPrefix = config.prefix;
        }

        vm.updateStoreData = function () {
            vm.loading = true;
            StoreService.updateStore(vm.fullStore).then(function(response) {
                vm.loading = false
                if (response){
                    toastr.success($translate.instant('settings.clique-retire.success'));
                    vm.fullStore = response;
                } else {
                    toastr.success($translate.instant('settings.clique-retire.fail'));
                }
            })
        }

        vm.addZipCode = function () {
            if (vm.customShipping && vm.customShipping.zipCodes) {
                vm.customShipping.zipCodes.push({ initialZipCode: null, finalZipCode: null });
            } else if( vm.customShipping) {
                vm.customShipping.zipCodes = [{ initialZipCode: null, finalZipCode: null }];
            } else {
                vm.customShipping = {zipCodes: [{ initialZipCode: null, finalZipCode: null }]};
            }
        }

        vm.removeZipCode = function (index) {
            if (vm.customShipping.isActive) {
                if (vm.customShipping.zipCodes.length > 1) {
                    vm.customShipping.zipCodes.splice(index, 1);
                } else {
                    vm.customShipping.zipCodes = [{ initialZipCode: null, finalZipCode: null }];
                }
            }
        }

        vm.createCustomShipping = function() {
            vm.isViewForm = true;
            vm.customShippingForm.$setPristine();
            vm.customShipping = {isActive: true};
            vm.customShipping.zipCodes = [{ initialZipCode: null, finalZipCode: null}];
        }

        vm.getCustomShippingById = function (id) {
            ShippingService.getCustomShippingById(id)
                .then(function (response) {
                    vm.isViewForm = true;
                    vm.customShipping = response.data
                    if (!vm.customShipping.zipCodes || !vm.customShipping.zipCodes.length) {
                        vm.customShipping.zipCodes = [{ initialZipCode: null, finalZipCode: null }];
                    }

                    $timeout(function () {
                        vm.goToEndPage();
                    }, 100);
                });

        }

        vm.goToEndPage = function(){
            var scrollingElement = (document.scrollingElement || document.body);
            scrollingElement.scrollTop = scrollingElement.scrollHeight;
        }

        vm.transformPrice = function (price) {
            var decimals = 2;
            var decpoint = ',';
            var thousand = '.';
            var n = Math.abs(price).toFixed(decimals).split('.');
            n[0] = n[0].split('').reverse().map(function (c, i, a){
                return  i > 0 && i < a.length && i % 3 == 0 ? c + thousand : c
            }).reverse().join('');
            var final = (Math.sign(price) < 0 ? '-' : '') + n.join(decpoint);
            return final;
        }

        vm.deleteCustomShipping = function (id) {
            RemoveModalFactory.deleteItem(1).then(function (data) {
                ShippingService.deleteCustomShipping(id).then(function (data) {
                    vm.error = null;
                    vm.success = 'OK';
                    processResponse('settings.custom-shipping.messages.delete');
                },
                function (err) {
                    vm.isSaving = false;
                    vm.success = null;
                    vm.error = 'ERROR';
                    processResponse();
                });
            });
          }

    }
})();
