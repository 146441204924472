(function() {
    'use strict';

    angular
        .module('showcaseApp')
        .controller('StoreManagementNewController', StoreManagementNewController);

    StoreManagementNewController.$inject = ['$translate', '$scope', '$timeout', '$state', '$rootScope', 'StoreService', 'CategoryService', 'CheckCEPService', 'GoogleMapsService', '$q', 'toastr', '$window'];

    function StoreManagementNewController($translate, $scope, $timeout, $state, $rootScope, StoreService, CategoryService, CheckCEPService, GoogleMapsService, $q, toastr, $window) {
        var vm = this;

        const ICON_ACTIVE = 'content/images/pointinactive.png';
        const ICON_INACTIVE = 'https://showkase-prod.s3-sa-east-1.amazonaws.com/hub/system-images/'+$rootScope.urlImage+'/pointactive.png';

        vm.imageUpload = '';
        vm.domainSufix = '.' + getDomainNameSufix($window);
        vm.imageSize = 500;
        vm.imageCroppedActive = false;
        vm.imageChanged = false;
        vm.options= {
            fullscreenControl: false
        };
        vm.store = {
            image: '',
            location: {}
        };
        vm.categories = [];
        vm.showMap = false;
        vm.mainMap = {
            center: {
                latitude: 0,
                longitude: 0
            },
            zoom: 10
        };
        vm.mainMap.controls = {};
        vm.mainMap.bounds = {};
        vm.mainMap.markers = [];
        vm.lastMapKey = 0;
        vm.actualMarkerIndex = 0;
        vm.markerOptions = {};
        vm.mapLoaded = true;
        vm.markerWindow = {
            window: {}
        };
        vm.actualTab = 0;
        vm.tabsState = [{
            active: true
        }, {
            active: false
        }, {
            active: false
        }];
        vm.isSaving = false;
        vm.mainMap.window = {
            model: {},
            show: false,
            options: {
                pixelOffset: {
                    width: -1,
                    height: -20
                }
            },
            closeClick: function() {
                this.show = false;
            }
        }
        vm.mainMap.markerEvents = {
            dragend: function(marker, eventName, model) {
                vm.refreshMapWithCoordinates(marker.position.lat(), marker.position.lng());
            },
            click: function(marker, eventName, model, args) {
                if (model.options.isAdded) {
                    vm.mainMap.window.model = model;
                    vm.mainMap.window.show = true;
                }
            }
        }

        vm.initMap = function() {
            vm.showMap = true;
        }

        vm.firstZoom = true;

        $scope.$watch('vm.mainMap.zoom', function(newVal) {
            if (newVal > 16 && vm.firstZoom) {
                vm.mainMap.zoom = 16;
                vm.firstZoom = false;
            }
        });

        loadCategories();
        loadCountrysAndStates();

        function loadCountrysAndStates() {
            CheckCEPService.listStates().then(function(response) {
                vm.states = response.data;
            });
            CheckCEPService.listCountrys().then(function(response) {
                vm.countrys = response.data;
            });
            if (!vm.haveDefaultPoint) {
                vm.store.location.mainPoint = true;
            }
            navigator.geolocation.getCurrentPosition(function(position) {
                vm.mainMap.center.latitude = position.coords.latitude;
                vm.mainMap.center.longitude = position.coords.longitude;
            });
        }

        function loadCategories() {
            CategoryService.listCategories($translate.use()).then(function(response) {
                vm.categories = response.data;
            });
        }

        $scope.$on('salesPointUpdated', function(event, data) {
            vm.mainMap.window.show = false;
            var marker = vm.mainMap.markers.some(function(marker) {
                return marker.id === data.id;
            });
            vm.mainMap.markers[vm.mainMap.markers.indexOf(marker)] = data;
        });

        $scope.$on('salesPointRemoved', function(event, data) {
            if (vm.mainMap.markers.length > 1) {
                vm.mainMap.markers.splice(vm.mainMap.markers.indexOf(data), 1);
                vm.mainMap.window.show = false;
            } else {
                toastr.error($translate.instant('store-management.messages.remove.minSalesPoints'));
            }
        });

        $rootScope.$on('$translateChangeSuccess', function() {
            loadCategories();
        });

        vm.openInput = function(evt) {
            document.getElementById('fileInput').click();
        }

        vm.createStore = function(store) {
            StoreService.createStore(vm.store).then(function(data) {
                onSaveSuccess();
            });
        }

        function dataURItoBlob(dataURI) {
            // convert base64/URLEncoded data component to raw binary data held in a string
            var byteString;
            if (dataURI.split(',')[0].indexOf('base64') >= 0)
                byteString = atob(dataURI.split(',')[1]);
            else
                byteString = unescape(dataURI.split(',')[1]);

            // separate out the mime component
            var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

            // write the bytes of the string to a typed array
            var ia = new Uint8Array(byteString.length);
            for (var i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
            }

            return new Blob([ia], {
                type: mimeString
            });
        }

        vm.checkCEP = function() {
            if (vm.store.location.postalCode) {
                if (vm.store.location.postalCode.length === 8) {
                    CheckCEPService.getAddress(vm.store.location.postalCode).then(function(response) {
                        var location = response.data;
                        vm.store.location.country = "BRASIL";
                        vm.store.location.city = location.location.name;
                        vm.store.location.state = location.location.uf;
                        vm.store.location.address = location.name;
                        vm.store.location.neighborhood = location.district.name;
                        vm.refreshMapWithAddress();
                    });
                }
            }
        }

        vm.refreshMapWithAddress = function(address) {
            // Checking and delete all pin marker if some information of address are empty
            if (   !vm.store.location.address 
                || !vm.store.location.neighborhood 
                || !vm.store.location.city 
                || !vm.store.location.state 
                || !vm.store.location.country){
                vm.mainMap.markers = [];
            } else {
                GoogleMapsService.getAddress((vm.store.location.number + ", " + vm.store.location.address + ", " + vm.store.location.neighborhood + ", " + vm.store.location.city + ", " + vm.store.location.state + ", " + vm.store.location.country).replace(/undefined/g, '')).then(function(data) {
                    vm.lastMapKey++;
                    if (vm.mainMap.markers.length === vm.actualMarkerIndex + 1) {
                        vm.mainMap.markers[vm.actualMarkerIndex].latitude = data.geometry.location.lat();
                        vm.mainMap.markers[vm.actualMarkerIndex].longitude = data.geometry.location.lng();
                    } else {
                        vm.mainMap.markers.push({
                            latitude: data.geometry.location.lat(),
                            longitude: data.geometry.location.lng(),
                            id: vm.lastMapKey,
                            options: {
                                draggable: true,
                                icon: ICON_ACTIVE,
                                isAdded: false
                            }
                        });
                    }
                    vm.mainMap.zoom = 15;
                });
            }
            
        }

        vm.setMarkerAsAdded = function() {
            vm.mainMap.markers[vm.actualMarkerIndex].pointlocation = {
                name: vm.store.location.name,
                address: vm.store.location.address,
                city: vm.store.location.city,
                country: vm.store.location.country,
                state: vm.store.location.state,
                contactPhone: vm.store.location.contactPhone,
                postalCode: vm.store.location.postalCode,
                number: vm.store.location.number,
                neighborhood: vm.store.location.neighborhood
            };
            vm.mainMap.markers[vm.actualMarkerIndex].options.icon = ICON_INACTIVE;
            vm.mainMap.markers[vm.actualMarkerIndex].options.draggable = false;
            vm.mainMap.markers[vm.actualMarkerIndex].options.isAdded = true
            vm.store.location = {
                address: "",
                city: "",
                country: "",
                neighborhood: "",
                state: "",
                number: "",
                postalCode: "",
                name: ""
            };
            vm.actualMarkerIndex++;
            vm.locationStoreForm.$setPristine();
            vm.locationStoreForm.$setUntouched();
            toastr.success($translate.instant('store-management.messages.salespoint.create.success'));
        }

        vm.refreshMapWithCoordinates = function(lat, long) {
            GoogleMapsService.getAddressByCoordinates(lat, long).then(function(data) {
                var location = data.address_components;
                vm.store.location.country = location[6].long_name.toUpperCase();
                vm.store.location.city = location[3].long_name;
                vm.store.location.state = location[5].short_name;
                vm.store.location.address = location[1].long_name;
                vm.store.location.neighborhood = location[2].long_name;
                vm.store.location.number = location[0].long_name;
            });
        };

        var handleFileSelect = function(evt) {
            var file = evt.currentTarget.files[0];
            var reader = new FileReader();
            reader.onload = function(evt) {
                $scope.$apply(function($scope) {
                    vm.imageUpload = evt.target.result;
                });
            };
            reader.readAsDataURL(file);
        };

        vm.initTabLogoNewStore = function () {
            angular.element(document.querySelector('#fileInput')).on('change', handleFileSelect);
        };

        vm.activeImageCrop = function() {
            vm.imageCroppedActive = false;
            vm.imageCroppedActive = true;
        };

        vm.saveStore = function() {
            vm.saveSubmit = true;
            if (!vm.actualMarkerIndex <= 0) {
                vm.store.points = [];
                var point = {};
                vm.mainMap.markers.forEach(function(marker) {
                    point = marker.pointlocation;
                    point.coordinate = {
                        latitude: "",
                        longitude: ""
                    };
                    point.coordinate.latitude = marker.latitude;
                    point.coordinate.longitude = marker.longitude;
                    vm.store.points.push(point);
                });
                // Define o CNPJ como null caso ele n�o tenha sido realmente informado para evitar erro de valida��o no back-end
                vm.store.cnpj = vm.store.cnpj ? vm.store.cnpj : null;
                $q.all(vm.store.points).then(function(data) {
                    vm.store.logo = vm.imageChanged ? vm.store.image : '';
                    vm.isSaving = true;
                    StoreService.saveStoreImage(dataURItoBlob(vm.store.logo)).then(function (response, error) {
                      vm.store.addImageFileDTO = response.data;
                      vm.store.logo = '', vm.store.image = '';
                      StoreService.addStore(vm.store).then(function(data, error) {
                          vm.isSaving = false;
                          $state.go('store-management');
                          $rootScope.$broadcast('storeChangeSuccess');
                          toastr.success($translate.instant('store-management.messages.create.success'));
                      }, function(data) {
                          vm.isSaving = false;
                          toastr.error($translate.instant('store-management.messages.create.error'));
                      });
                    });
                });
            }
        }

        vm.verifyNicknameIsAvailable = function(form) {
            if (vm.store.nickname) {
                StoreService.verifyIfNicknameAvailable(vm.store.nickname).then(function(result) {
                    if (!result.data) {
                        form.nickname.$setValidity('nicknameIsUnavailable', false);
                    } else {
                        form.nickname.$setValidity('nicknameIsUnavailable', true);
                    }
                }, function(error) {
                    form.nickname.$setValidity('nicknameIsUnavailable', false);
                });
            } else {
                form.nickname.$setValidity('nicknameIsUnavailable', true);
            }
        }

        vm.changeToTab = function(tab) {
            if (tab === 2) {
              if (!vm.store.image) {
                toastr.error($translate.instant('store-management.messages.create.imageError'));
              } else {
                $timeout(function() {
                    vm.showMap = true;
                }, 150);
                getLocation();
                vm.tabsState[tab].active = true;
                vm.actualTab = tab;
              }
            }
            if (tab === 1) {
                vm.imageCroppedActive = true;
                vm.storesInfoSubmitted = true;
                if (!vm.storeInfosForm.$invalid) {
                    vm.tabsState[tab].active = true;
                    vm.actualTab = tab;
                }
            } else {
                vm.imageCroppedActive = false;
            }
        }

        vm.checkIfIsFlyersPage = function() {
            return (vm.currentState === 'flyers-detail');
        }

        function getLocationSuccFn(position) {
            vm.mainMap.center.latitude = position.coords.latitude;
            vm.mainMap.center.longitude = position.coords.longitude;
            vm.mainMap.controls.refresh({
                latitude: vm.mainMap.center.latitude,
                longitude: vm.mainMap.center.longitude
            });
        }

        function getLocation() {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(getLocationSuccFn, function(error) {}, {
                    timeout: 1000
                });
            }
        }

    }

})();

(function() {
    'use strict';

    angular
        .module('showcaseApp')
        .controller('StoreMarkerController', StoreMarkerController);

    StoreMarkerController.$inject = ['$uibModal', '$scope', '$state'];

    function StoreMarkerController($uibModal, $scope, $state) {
        var vm = this;

        vm.currentState = $state.current.name;

        vm.addSalesPoint = function() {
            $uibModal.open({
                templateUrl: 'app/admin/store-management/store-management-newsalespoint.html',
                controller: 'AddSalesPointManagementController',
                controllerAs: 'vm',
                backdrop: 'static',
                scope: $scope,
                size: 'lg',
                resolve: {
                    pointlocation: function() {
                        return angular.copy($scope.parameter);
                    }
                }
            }).result.then(function(data) {
                if (data) {
                    $scope.parameter = data;
                }
            });
        }

        vm.checkIfIsFlyersPage = function() {
            return (vm.currentState === 'flyers-detail');
        }

        vm.removeSalesPoint = function() {
            $scope.$emit('salesPointRemoved', $scope.parameter);
        }
    }

})();
