(function() {
    'use strict';

    angular
        .module('showcaseApp')
        .controller('ProfileManagementController', ProfileManagementController);

    ProfileManagementController.$inject = ['$scope', '$state', 'uiGridBuilder', 'uiGridConstants', 'UserProfileService', '$translate', '$localStorage'];

    function ProfileManagementController ($scope, $state, uiGridBuilder, uiGridConstants, UserProfileService, $translate, $localStorage) {
        var vm = this;
        vm.startRegistry = 0;
        vm.endRegistry = 0;
        vm.tableDivisor = 5;
        vm.filterFields = {};
        vm.pagesSize = [];
        vm.defaultSearchIndex = 0;
        vm.columnDefs = getColumnDefs();
        vm.checkIsFirstPage = false;
        vm.checkIsLastPage = false;

        loadAll();

        function loadAll() {
            vm.gridTableOptions = getGridOptions();
        }

        function getColumnDefs() {
            return [
                { field: 'name', displayName: 'showcaseApp.profile.name', headerCellFilter: 'translate' }
            ];
        }

        function getGridOptions() {
            return uiGridBuilder.withDefaultOptions()
                .withActionColumn(100)
                .addActionToActionColumn("edit", "vm.getEditPage", "fa fa-edit", "text-primary", true)
                .addActionToActionColumn("delete", "vm.getDeletePage", "fa fa-trash", "text-primary", true)
                .withColumns(vm.columnDefs).build('search');
        }

        $scope.search = function () {
            var params = null;
            var pageable = vm.gridTableOptions.getPageAndSortOptions().getPageAbleObj();
            UserProfileService.listProfiles(pageable, $localStorage.defaultStore.storeId, vm.filterFields).then(function (response) {
                vm.totalItems = response.headers['x-total-count'];
                vm.gridTableOptions.virtualizationThreshold = response.data.length;
                vm.gridTableOptions.update(response.data, vm.totalItems);
                vm.gridTableOptions.getArrayPagination(function (arrayPagination) {
                    vm.arrayPagination = arrayPagination;
                });
                refreshDataInfos();
                vm.checkIsLastPage = vm.gridTableApi.pagination.getPage() === vm.gridTableApi.pagination.getTotalPages();
                vm.checkIsFirstPage = vm.gridTableApi.pagination.getPage() === 1;
                vm.pagesSize = createArray(vm.totalItems, vm.tableDivisor);
            });
        }

        function createArray(range, divisor) {
            if(!divisor)divisor = 0;
            if (divisor!=0) {
                range = range/divisor;
            }
            var values = [];
            var iteratorNumber = 0;
            for (var i = 0; i < range; i++) {
              iteratorNumber = divisor!=0 ? i*divisor : i+1;
              values.push(iteratorNumber);
            }
            return values;
        };

        function refreshDataInfos(){
            vm.gridTableApi = vm.gridTableOptions.getApiGridTable();
            vm.startRegistry = (vm.gridTableApi.pagination.getPage() * vm.gridTableOptions.paginationPageSize) - vm.gridTableOptions.paginationPageSize;
            vm.endCalc = vm.gridTableOptions.paginationPageSize * vm.gridTableApi.pagination.getPage();
            vm.endRegistry = vm.endCalc > vm.totalItems ? vm.totalItems : vm.endCalc;
        };

        vm.getEditPage = function(row) {
            $state.go('profile-detail', {storeId: $localStorage.defaultStore.storeId, id:row.id});
        }

        vm.getDeletePage = function(row) {
            $state.go('profile-management.delete', {id:row.id});
        }

        vm.changePageSize = function(pageSize) {
            vm.gridTableOptions.paginationPageSize = pageSize;
        };

        vm.filterField = function(valueFilter, fieldTOFilter) {
            vm.gridTableApi.grid.getColumn(fieldTOFilter).filters[0].term = valueFilter;
            vm.gridTableOptions.enableFiltering = true;
            vm.gridTableApi.grid.refresh();
        };

        vm.reloadProfiles = function() {
            $scope.search();
        }

        vm.stopPropagation = function(event) {
            event.stopPropagation();
        };

        vm.changeColumnView = function($index) {
            vm.gridTableOptions.columnDefs[$index].visible = !(vm.gridTableOptions.columnDefs[$index].visible || vm.gridTableOptions.columnDefs[$index].visible === undefined);
            vm.gridTableApi.core.notifyDataChange(uiGridConstants.dataChange.COLUMN);
        };

        vm.exporterTable = function(type) {
            if (type === 'pdf') {
                vm.gridTableApi.exporter.pdfExport('visible', 'visible');
            } else if (type === 'csv') {
                vm.gridTableApi.exporter.csvExport('visible', 'visible');
            }
        };
    }
})();
