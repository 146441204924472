(function() {
    'use strict';
    angular
        .module('showcaseApp')
        .factory('SalesPointFactory', SalesPointFactory);

    SalesPointFactory.$inject = ['$resource'];

    function SalesPointFactory($resource) {
        var resourceUrl =  'admin/api/sales-point/:id';

        return $resource(resourceUrl, {}, {
            'query': {
                method: 'GET',
                isArray: false,
                transformResponse: function (data, headers) {
                    var response = {};
                    response.data = angular.fromJson(data);
                    response.headers = headers();
                    return response;
                }
            },
            'get': {
                method: 'GET',
                transformResponse: function (data, headers) {
                    var response = {};
                    response.data = angular.fromJson(data);
                    response.headers = headers();
                    return response;
                }
            },
            'update': { method:'PUT' },
            'delete': { method:'DELETE' }
        });
    }
})();
