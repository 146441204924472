(function() {
    'use strict';

    angular
        .module('showcaseApp')
        .factory('AbandonedCartNotificationService', AbandonedCartNotificationService);

    AbandonedCartNotificationService.$inject = ['AbandonedCartNotificationFactory'];

    function AbandonedCartNotificationService(AbandonedCartNotificationFactory) {

        var service = {
            saveNotification: saveNotification,
            findAllNotifications: findAllNotifications,
            findNotificationById: findNotificationById,
            editNotification: editNotification,
            removeNotification: removeNotification
        };

        return service;

        function saveNotification(body) {
            return AbandonedCartNotificationFactory.saveNotification(body).$promise;
        }

        function findAllNotifications() {
            return AbandonedCartNotificationFactory.findAllNotifications().$promise;
        }

        function findNotificationById(notificationId) {
            return AbandonedCartNotificationFactory.findNotificationById({"notificationId": notificationId}).$promise;
        }

        function editNotification(notificationId, body) {
            return AbandonedCartNotificationFactory.editNotification({"notificationId": notificationId}, body).$promise;
        }

        function removeNotification(notificationId) {
            return AbandonedCartNotificationFactory.removeNotification({"notificationId": notificationId}).$promise;
        }
    }
})();
