(function() {
    'use strict';

    angular
        .module('showcaseApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('wati-settings', {
                parent: 'admin',
                url: '/wati-settings',
                data: {
                    authorities: [],
                    pageTitle: 'Configuração Whatsapp'
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/admin/wati/wati.html',
                        controller: 'WatiController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('showcases');
                        $translatePartialLoader.addPart('flyers');
                        $translatePartialLoader.addPart('store-management');
                        return $translate.refresh();
                    }]

                }
            })
    }
})();
