/* globals $ */
(function () {
    'use strict';

    angular
        .module('showcaseApp')
        .filter('tel', function () {
            return function (input) {
                if (input.startsWith('+55')) {
                    var str = input + '';
                    str = str.replace(/\D/g, '');
                    if (str.length === 13) {
                        str = str.replace(/^(\d\d)(\d\d)(\d{5})(\d{4}).*/, "+$1 ($2) $3-$4");
                    } else {
                        str = str.replace(/^(\d\d)(\d\d)(\d{4})(\d{4}).*/, '+$1 ($2) $3-$4');
                    }
                    return str;
                } else {
                    return input
                }

            };
        });
})();

