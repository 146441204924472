(function() {
    'use strict';

    angular
        .module('showcaseApp')
        .controller('RemoveCouponModalController', RemoveCouponModalController);

        RemoveCouponModalController.$inject = ['$scope', '$uibModal', '$uibModalInstance', 'toastr'];

    function RemoveCouponModalController($scope, $uibModal, $uibModalInstance, toastr) {
        var vm = this;

        vm.confirm = function() {
            $uibModalInstance.close(true);
        }

        vm.closeModal = function() {
            $uibModalInstance.dismiss();
        }

    }

})();
