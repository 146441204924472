(function() {
    'use strict';
    angular
        .module('showcaseApp')
        .service('FlyersService', FlyersService);

    FlyersService.$inject = ['FlyersFactory', '$localStorage', 'Upload'];

    function FlyersService(FlyersFactory, $localStorage, Upload) {

        var service = {
            createFlyer: createFlyer,
            saveFlyerImage: saveFlyerImage,
			saveFlyerMultiImage: saveFlyerMultiImage,
            deleteFlyers: deleteFlyers,
            verifyIfFlyersIsPublished: verifyIfFlyersIsPublished,
            listFlyers: listFlyers,
            listFlyersPage: listFlyersPage,
            updateFlyer: updateFlyer,
            getFlyer: getFlyer,
            getNumberOfFlyers: getNumberOfFlyers,
            getFlyerTypes: getFlyerTypes,
            listAllFlyers: listAllFlyers,
            getImageFlyer: getImageFlyer,
            getLinkImageFlyerAsString: getLinkImageFlyerAsString,
            listAllToAdmin: listAllToAdmin,
            listFlyersPageToModal: listFlyersPageToModal,
            updateFlyerList: updateFlyerList,
            getSizes: getSizes,
            getColors: getColors,
            flyersImportStatus: flyersImportStatus,
            getTypes: getTypes,
            getBrands: getBrands,
            getFeatureds: getFeatureds,
            getTags: getTags,
            getGenders: getGenders,
			getAllFiltersFromFlyersIsPublish: getAllFiltersFromFlyersIsPublish,
			getAllFiltersFlyer: getAllFiltersFlyer,
            listAllFlyersIds: listAllFlyersIds,
            clearFlyersStock: clearFlyersStock,
            applyDiscountsForFlyers: applyDiscountsForFlyers,
            clearFlyersDiscountedValue: clearFlyersDiscountedValue,
            syncFlyersWithHub: syncFlyersWithHub,
            findFlyersByIds: findFlyersByIds
        };

        return service;

        function createFlyer(flyer) {
            return FlyersFactory.save(flyer).$promise;
        }

        function listFlyers(pageable, filter, onSuccess, onError) {
            return FlyersFactory.listAll(pageable, filter, onSuccess, onError);
        }

        function listAllToAdmin(pageable, filter, onSuccess, onError) {
            return FlyersFactory.listAllToAdmin(pageable, filter, onSuccess, onError);
        }

        function listAllFlyersIds(filter) {
            return FlyersFactory.listAllFlyersIds(filter).$promise;
        }

        function listFlyersPage(pageable, filter, onSuccess, onError) {
            return FlyersFactory.listAllPageable(pageable, filter, onSuccess, onError);
        }

        function listFlyersPageToModal(pageable) {
            return FlyersFactory.listAllPageableToModal(pageable, { storeId: $localStorage.defaultStore.storeId }).$promise;
        }

        function verifyIfFlyersIsPublished(flyerIds) {
            return FlyersFactory.verifyIfFlyersIsPublished({flyerIds: flyerIds}).$promise;
        }

        function deleteFlyers(flyerIds) {
            return FlyersFactory.deletebyids({flyerIds: flyerIds}).$promise;
        }

        function getFlyer(flyer) {
            return FlyersFactory.get({flyerId: flyer, storeId: $localStorage.defaultStore.storeId}).$promise;
        }

        function updateFlyer(flyerToSave) {
            return FlyersFactory.update(flyerToSave).$promise;
        }

        function saveFlyerImage(image) {
            return Upload.upload({url: 'zuul/flyer/api/upload', data: {file: image}});
        }

        function saveFlyerMultiImage(image, orderNumber) {
            return Upload.upload({url: 'zuul/flyer/api/multiUpload', data: {file: image, orderNumber: orderNumber}});
        }

        function getNumberOfFlyers(storeId) {
            return FlyersFactory.numberOfFlyers({storeId: storeId}).$promise;
        }

        function getFlyerTypes(storeId) {
            return FlyersFactory.getFlyerTypes().$promise;
		}

        function listAllFlyers() {
            return FlyersFactory.listAllFlyers().$promise;
        }

        function getImageFlyer(flyerId) {
            return FlyersFactory.flyerImage({id: flyerId}).$promise;
        }

        function getLinkImageFlyerAsString(flyerId) {
            return FlyersFactory.flyerLinkImageAsString({id: flyerId}).$promise;
        }

        function updateFlyerList(flyerImport) {
            return FlyersFactory.updateFlyerList(flyerImport).$promise;
        }

        function getSizes() {
            return FlyersFactory.getSizes().$promise;
        }

        function getColors() {
            return FlyersFactory.getColors().$promise;
        }

        function flyersImportStatus(importId) {
            return FlyersFactory.flyersImportStatus({importId: importId}).$promise;
        }

        function getTypes() {
            return FlyersFactory.getTypes().$promise;
        }

        function getBrands() {
            return FlyersFactory.getBrands().$promise;
        }

        function getFeatureds() {
            return FlyersFactory.getFeatureds().$promise;
        }

        function getTags() {
            return FlyersFactory.getTags().$promise;
        }

        function getGenders() {
            return FlyersFactory.getGenders().$promise;
        }

        function getAllFiltersFromFlyersIsPublish() {
            return FlyersFactory.getAllFiltersFromFlyersIsPublish().$promise;
        }

        function getAllFiltersFlyer() {
            return FlyersFactory.getAllFiltersFlyer().$promise;
        }

        function findFlyersByIds(flyerIds) {
            return FlyersFactory.findFlyersByIds(flyerIds).$promise;
        }

        function clearFlyersStock(flyersId) {
            return FlyersFactory.clearFlyersStock(flyersId).$promise;
        }

        function applyDiscountsForFlyers(flyersId, discountPercentage) {
            return FlyersFactory.applyDiscountsForFlyers({ flyersId: flyersId, discountPercentage: discountPercentage }).$promise;
        }

        function clearFlyersDiscountedValue() {
            return FlyersFactory.clearFlyersDiscountedValue().$promise;
        }

        function syncFlyersWithHub(flyerIds, params) {
            return FlyersFactory.syncFlyersWithHub({ flyerIds: flyerIds, syncParams: params }).$promise;
        }
    }

})();
