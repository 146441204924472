(function() {
    'use strict';

    angular
        .module('showcaseApp')
        .controller('ChatSkeletonController', ChatSkeletonController);

    ChatSkeletonController.$inject = ['$scope'];

    function ChatSkeletonController($scope) {
        var vm = this;

        vm.hasIcon = true;
        vm.messageCount = 1;

        function init() {
            vm.hasIcon = $scope.hasIcon;
            vm.messageCount = $scope.messageCount;
        }

        init();
    }

})();
