(function() {
    'use strict';

    angular
        .module('showcaseApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('hub-campaigns', {
                parent: 'admin',
                url: '/hub-campaigns',
                data: {
                    authorities: ['ADMIN_HUB'],
                    pageTitle: 'global.hub.campaigns'
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/admin/hub-management/campaign/hub-campaign.html',
                        controller: 'HubCampaignController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('showcases');
                        $translatePartialLoader.addPart('flyers');
                        $translatePartialLoader.addPart('store-management');
                        $translatePartialLoader.addPart('campaigns');
                        return $translate.refresh();
                    }]

                }
            })
            .state('hub-campaigns.new', {
                parent: 'hub-campaigns',
                url: '/new',
                data: {
                    authorities: ['ADMIN_HUB'],
                    pageTitle: 'campaign.actions.create'
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/admin/hub-management/campaign/hub-campaign-form.html',
                        controller: 'HubCampaignFormController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('showcases');
                        $translatePartialLoader.addPart('flyers');
                        $translatePartialLoader.addPart('campaigns');
                        return $translate.refresh();
                    }]

                }
            })
            .state('hub-campaigns.edit', {
                parent: 'hub-campaigns',
                url: '/{id}',
                data: {
                    authorities: ['ADMIN_HUB'],
                    pageTitle: 'campaign.actions.edit'
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/admin/hub-management/campaign/hub-campaign-form.html',
                        controller: 'HubCampaignFormController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('showcases');
                        $translatePartialLoader.addPart('flyers');
                        $translatePartialLoader.addPart('campaigns');
                        return $translate.refresh();
                    }]

                }
            })
            .state('hub-campaigns.distribute', {
                parent: 'hub-campaigns',
                url: '/{id}/distribute',
                data: {
                    authorities: ['ADMIN_HUB'],
                    pageTitle: 'campaign.actions.distribute'
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/admin/hub-management/campaign/hub-campaign-distribute.html',
                        controller: 'HubCampaignDistributeController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('showcases');
                        $translatePartialLoader.addPart('flyers');
                        $translatePartialLoader.addPart('campaigns');
                        return $translate.refresh();
                    }]

                }
            })
    }
})();
