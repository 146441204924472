(function() {
    'use strict';
    angular
        .module('showcaseApp')
        .factory('StoreFactoryGateway', StoreFactoryGateway);

    StoreFactoryGateway.$inject = ['$resource'];

    function StoreFactoryGateway($resource) {
        var resourceUrl =  'api/store/:id';
        return $resource(resourceUrl, {}, {
            'query': {
                method: 'GET',
                isArray: false,
                transformResponse: function (data, headers) {
                    var response = {};
                    response.data = angular.fromJson(data);
                    response.headers = headers();
                    return response;
                }
            },
            'get': {
                method: 'GET',
                transformResponse: function (data, headers) {
                    var response = {};
                    response.data = angular.fromJson(data);
                    response.headers = headers();
                    return response;
                }
            },
            'deletebyids': {
                method: 'POST',
                url: '/api/store/deletebyids'
            },
            'listAll': {
                method: 'POST',
                url: '/api/store/list',
                isArray: true
            },
            'saveRedirectDomains': {
                method: 'POST',
                url: '/admin/api/store/save-url-domains',
                transformResponse: function (data, headers) {
                    var response = {};
                    response.data = angular.fromJson(data);
                    response.headers = headers();
                    return response;
                }
            },
            'saveStoreAnalytics': {
                method: 'POST',
                url: '/admin/api/store/save-store-analytics',
                transformResponse: function (data, headers) {
                    var response = {};
                    response.data = angular.fromJson(data);
                    response.headers = headers();
                    return response;
                }
            },
            'getMyStores': {
                url: '/api/store/my',
                method: 'GET',
                transformResponse: function (data, headers) {
                    var response = {};
                    response.data = angular.fromJson(data);
                    response.headers = headers();
                    return response;
                }
            },
            'getMyAllStores': {
                url: '/admin/api/store/list-all',
                method: 'GET',
                transformResponse: function (data, headers) {
                    var response = {};
                    response.data = angular.fromJson(data);
                    response.headers = headers();
                    return response;
                }
            },
            'update': { method:'PUT' },
            'delete': { method:'DELETE' }
        });
    }
})();
