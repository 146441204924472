(function() {
    'use strict';

    angular
        .module('showcaseApp')
        .controller('HubFlyersEditImageController', HubFlyersEditImageController);

    HubFlyersEditImageController.$inject = ['$translate', '$timeout', '$scope', 'HubFlyersService', 'toastr', '$uibModalInstance', 'flyer'];

    function HubFlyersEditImageController($translate, $timeout, $scope, HubFlyersService, toastr, $uibModalInstance, flyer) {
        var vm = this;
        vm.isSaving = false;
        vm.imageSize = 400;
        vm.flyer = flyer;
        vm.flyerImage = '';

        vm.closeModal = function() {
            $uibModalInstance.close();
        }

        vm.uploadPreview = function(img) {
            var reader = new FileReader();
            reader.onload = vm.imageIsLoaded;
            reader.readAsDataURL(img)
        }

        vm.openInput = function(evt) {
            document.getElementById('fileInput').click();
        }

        var handleFileSelect = function(evt) {
            var file = evt.currentTarget.files[0];
            var reader = new FileReader();
            reader.onload = function(evt) {
                $scope.$apply(function($scope) {
                    vm.imageUpload = evt.target.result;
                });
            };
            reader.readAsDataURL(file);
        };

        vm.imageIsLoaded = function(e) {
            $scope.$apply(function() {
                vm.flyer.imagePath = e.target.result;
            });
        }

        function dataURItoBlob(dataURI) {
            // convert base64/URLEncoded data component to raw binary data held in a string
            var byteString;
            if (dataURI.split(',')[0].indexOf('base64') >= 0)
                byteString = atob(dataURI.split(',')[1]);
            else
                byteString = unescape(dataURI.split(',')[1]);

            // separate out the mime component
            var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

            // write the bytes of the string to a typed array
            var ia = new Uint8Array(byteString.length);
            for (var i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
            }

            return new Blob([ia], {
                type: mimeString
            });
        }

        vm.save = function() {
            HubFlyersService.saveFlyerImage(dataURItoBlob(vm.flyerImage)).then(function(response) {
                $timeout(function() {
                    vm.flyer.addImageFileDTO = response.data;
                    vm.isSaving = true;
                    HubFlyersService.updateFlyer(vm.flyer).then(function(data) {
                        vm.isSaving = false;
                        $uibModalInstance.close(data);
                        toastr.success($translate.instant('flyers.messages.update.success'));
                    }, function(error) {
                        $uibModalInstance.dismiss(error);
                    });
                });
            });
        }

        vm.initFlyerImageModal = function() {
            angular.element(document.querySelector('#fileInput')).on('change', handleFileSelect);
        }

    }

})();
