(function() {
    'use strict';

    angular
        .module('showcaseApp')
        .factory('Principal', Principal);

    Principal.$inject = ['$q', 'Account', '$localStorage', 'StoreFactoryAdmin', '$rootScope', '$location', 'CheckoutService'];

    function Principal($q, Account, $localStorage, StoreFactoryAdmin, $rootScope, $location, CheckoutService) {
        var _identity = undefined,
            _authenticated = false;
        var userPlanName = '';

        var service = {
            authenticate: authenticate,
            hasAnyAuthority: hasAnyAuthority,
            hasAuthority: hasAuthority,
            hasPlan: hasPlan,
            identity: identity,
            isAuthenticated: isAuthenticated,
            isIdentityResolved: isIdentityResolved,
            storeOwnerHasMoipAccount: storeOwnerHasMoipAccount,
            disconnectFromPaymentGatewayWirecard: disconnectFromPaymentGatewayWirecard,
            isAvaliableUsername: isAvaliableUsername,
            isAvaliableEmail: isAvaliableEmail,
            savePushNotificationId: savePushNotificationId,
            isStoreOwner: isStoreOwner,
			forceLogout: forceLogout
        };

        return service;

        function authenticate(identity) {
            _identity = identity;
            _authenticated = identity !== null;
        }

        function hasAnyAuthority(authorities) {
            if (!_authenticated || !_identity || !_identity.authorities) {
                return false;
            }

            for (var i = 0; i < authorities.length; i++) {
                if (_identity.authorities.indexOf(authorities[i]) !== -1) {
                    return true;
                }
            }

            return false;
        }

        function hasAuthority(authority) {
            if (!_authenticated) {
                return $q.when(false);
            }

            return this.identity().then(function(_id) {
                return _id.authorities && _id.authorities.indexOf(authority) !== -1;
            }, function() {
                return false;
            });
        }

        function hasPlan(plans) {
            if (!_authenticated) {
                return $q.when(false);
            }

            return this.identity().then(function(_id) {
                if ((_id.remainingTime && _id.remainingTime > 0) ||
                    plans.indexOf(userPlanName) !== -1) {
                    return true;
                }
            }, function() {
                return false;
            });
        }

        function storeOwnerHasMoipAccount() {
            return Account.storeOwnerHasMoipAccount().$promise;
        }

        function isStoreOwner() {
            return StoreFactoryAdmin.isStoreOwner().$promise;
        }

        function identity(force) {
            var deferred = $q.defer();

            if (force === true) {
                _identity = undefined;
            }

            // check and see if we have retrieved the identity data from the server.
            // if we have, reuse it by immediately resolving
            if (angular.isDefined(_identity)) {
                deferred.resolve(_identity);

                return deferred.promise;
            }

            // retrieve the identity data from the server, update the identity object, and then resolve.
            Account.get().$promise
                .then(getAccountThen)
                .catch(getAccountCatch);

            return deferred.promise;

            function getAccountThen(account) {
                if (account && account.data) {
                    _identity = account.data;
                    _authenticated = true;
                    $localStorage.userId = _identity.id;
                    $localStorage.defaultStore = _identity.userProfiles[0];
                    $localStorage.profiles = account.userProfiles;
                    $localStorage.profileAuthorities = account.data.authorities;

                    $localStorage.userIsExpired = account.isExpired;
                    $localStorage.statusPaymentSignature = account.statusPaymentSignature;
                    $localStorage.isExpired = account.isExpired;
                    $localStorage.isMoipPlanStarter = account.data.isMoipPlanStarter;
                    $localStorage.isMoipPlanFree = account.data.isMoipPlanFree;
                    var currentEnv = $location.absUrl().split('.')[1];
                    CheckoutService.getPlans(currentEnv).then(function(result) {
                        result.data.forEach(function(plan) {
                            if (plan.id == _identity.planId) {
                                userPlanName = plan.name.toUpperCase();
                            }
                        })
                        deferred.resolve(_identity);
                    }, function (error) {
                        deferred.resolve(error);
                    });
                } else {
                    deferred.resolve(_identity);
                }
            }

            function getAccountCatch() {
                _identity = null;
                _authenticated = false;
                deferred.resolve(_identity);
            }
        }


        function isAuthenticated() {
            return _authenticated;
        }

        function isIdentityResolved() {
            return angular.isDefined(_identity);
        }

        function isAvaliableUsername(username) {
            return Account.isAvaliableUsername(username).$promise;
        }

        function isAvaliableEmail(email) {
            return Account.isAvaliableEmail(email).$promise;
        }
        function savePushNotificationId(pushNotificationId) {
            return Account.savePushNotificationId(pushNotificationId).$promise;
        }
        function disconnectFromPaymentGatewayWirecard() {
            return Account.disconnectFromPaymentGatewayWirecard().$promise;
        }
		function forceLogout(){
			return Account.forceLogout().$promise;
		}
    }
})();
