(function() {
    'use strict';
    angular
        .module('showcaseApp')
        .service('StoreService', StoreService);

    StoreService.$inject = ['StoreFactoryAdmin', 'StoreFactoryGateway', 'SalesPointFactory', 'Upload'];

    function StoreService(StoreFactoryAdmin, StoreFactoryGateway, SalesPointFactory, Upload) {
        var service = {
            addStore: addStore,
            updateStore: updateStore,
            authorizeShipping: authorizeShipping,
            authorizeBling: authorizeBling,
            deleteStore: deleteStore,
            deleteStores: deleteStores,
            setStoreNotSample: setStoreNotSample,
            getStore: getStore,
            getSalesPoints: getSalesPoints,
            listStores: listStores,
            listHubStores: listHubStores,
            createSalesPoint: createSalesPoint,
            updateSalesPoint: updateSalesPoint,
            deleteSalesPoint: deleteSalesPoint,
            getMyStores: getMyStores,
            getMyAllStores: getMyAllStores,
            checkShippingConnected: checkShippingConnected,
            saveStoreImage: saveStoreImage,
            verifyIfNicknameAvailable: verifyIfNicknameAvailable,
            verifyIfCnpjIsAvailable: verifyIfCnpjIsAvailable,
            saveBankInfo: saveBankInfo,
            getPicPayConfiguration: getPicPayConfiguration,
			getDupayConfiguration: getDupayConfiguration,
            getTBanksConfiguration: getTBanksConfiguration,
            savePicPayConfiguration: savePicPayConfiguration,
			saveDupayConfiguration: saveDupayConfiguration,
            saveTBanksConfiguration: saveTBanksConfiguration,
            verifyTBanksConfirmationToken: verifyTBanksConfirmationToken,
            resendTBanksConfirmationToken: resendTBanksConfirmationToken,
            isStoreOwner: isStoreOwner,
            saveRedirectDomains: saveRedirectDomains,
            saveStoreAnalytics: saveStoreAnalytics,
            getSteps: getSteps,
            listStoresByCurrentHub: listStoresByCurrentHub,
            countStoresByCurrentHubAndStoreType: countStoresByCurrentHubAndStoreType,
			getCssHub: getCssHub,
			getOwnerCategoryCurrentStore: getOwnerCategoryCurrentStore,
			updateDefaultFilter: updateDefaultFilter,
            getPaymentSettings: getPaymentSettings,
            updateToBeAgreedValue: updateToBeAgreedValue,
            updateRedirectToSellerValue: updateRedirectToSellerValue
        };

        return service;

        function getStore(storeId) {
            return StoreFactoryAdmin.get({
                id: storeId
            }).$promise;
        }

        function getMyStores() {
            return StoreFactoryGateway.getMyStores().$promise;
        }

        function getMyAllStores() {
            return StoreFactoryGateway.getMyAllStores().$promise;
        }

        function saveRedirectDomains(stores) {
            return StoreFactoryGateway.saveRedirectDomains(stores).$promise;
        }

        function saveStoreAnalytics(stores) {
            return StoreFactoryGateway.saveStoreAnalytics(stores).$promise;
        }

        function addStore(store) {
            return StoreFactoryGateway.save(store).$promise;
        }

        function updateStore(store) {
            return StoreFactoryAdmin.update(store).$promise;
        }

        function deleteStore(storeId) {
            return StoreFactoryAdmin.delete({
                id: storeId
            }).$promise;
        }

        function deleteStores(storeIds) {
            return StoreFactoryGateway.deletebyids({
                storeIds: storeIds
            }).$promise;
        }

        function listStores(params, pageable, onSuccess, onError) {
            return StoreFactoryGateway.listAll(pageable, params, onSuccess, onError);
        }

        function listHubStores(params, pageable, onSuccess, onError) {
            return StoreFactoryGateway.listAll(pageable, params, onSuccess, onError);
        }

        function listStoresByCurrentHub(params, pageable, onSuccess, onError) {
            return StoreFactoryAdmin.listAllStoreByHub(pageable, params, onSuccess, onError);
        }

        function createSalesPoint(salesPoint) {
            return SalesPointFactory.save(salesPoint).$promise;
        }

        function updateSalesPoint(salesPoint) {
            return SalesPointFactory.update(salesPoint).$promise;
        }

        function deleteSalesPoint(salesPointId) {
            return SalesPointFactory.delete({
                id: salesPointId
            }).$promise;
        }

        function getSalesPoints(store) {
            return SalesPointFactory.get({
                storeId: store
            }).$promise;
        }

        function verifyIfNicknameAvailable(nickname) {
            return StoreFactoryAdmin.isNicknameAvailable({
                nickname: nickname
            }).$promise
        }

        function verifyIfCnpjIsAvailable(cnpj) {
            return StoreFactoryAdmin.isCnpjAvailable({
                cnpj: cnpj
            }).$promise
        }

        function saveStoreImage(image) {
            return Upload.upload({
                url: 'zuul/admin/api/upload',
                data: {
                    file: image
                }
            });
        }

        function saveBankInfo(storeId, bankInfoDTO) {
            return StoreFactoryAdmin.saveBankInfo({
                storeId: storeId
            }, bankInfoDTO).$promise;
        }

        function getPicPayConfiguration(storeId) {
            return StoreFactoryAdmin.getPicPayConfiguration({
                storeId: storeId
            }).$promise;
        }

        function getDupayConfiguration(storeId) {
            return StoreFactoryAdmin.getDupayConfiguration({
                storeId: storeId
            }).$promise;
        }

        function getPaymentSettings(storeId) {
            return StoreFactoryAdmin.getPaymentSettings({
                storeId: storeId
            }).$promise;
        }

        function updateToBeAgreedValue(storeId, value) {
            return StoreFactoryAdmin.updateToBeAgreedValue({'storeId': storeId}, value).$promise;
        }

        function updateRedirectToSellerValue(storeId, value) {
            return StoreFactoryAdmin.updateRedirectToSellerValue({'storeId': storeId}, value).$promise;
        }

        function getTBanksConfiguration(storeId) {
            return StoreFactoryAdmin.getTBanksConfiguration({
                storeId: storeId
            }).$promise;
        }

        function savePicPayConfiguration(picPayConfigurationDTO) {
            return StoreFactoryAdmin.savePicPayConfiguration(picPayConfigurationDTO).$promise;
        }

        function saveDupayConfiguration(dupayConfigurationDTO) {
            return StoreFactoryAdmin.saveDupayConfiguration(dupayConfigurationDTO).$promise;
        }

        function saveTBanksConfiguration(tBanksConfigurationDTO) {
            return StoreFactoryAdmin.saveTBanksConfiguration(tBanksConfigurationDTO).$promise;
        }

        function verifyTBanksConfirmationToken(tBanksConfirmationTokenDTO) {
            return StoreFactoryAdmin.verifyTBanksConfirmationToken(tBanksConfirmationTokenDTO).$promise;
        }

        function resendTBanksConfirmationToken() {
            return StoreFactoryAdmin.resendTBanksConfirmationToken().$promise;
        }

        function isStoreOwner() {
            return StoreFactoryAdmin.isStoreOwner().$promise;
        }

        function getSteps() {
            return StoreFactoryAdmin.getStepsData().$promise;
        }

        function setStoreNotSample(store) {
            return StoreFactoryAdmin.setStoreNotSample(store, {}).$promise;
        }

        function countStoresByCurrentHubAndStoreType(storeTypeId) {
            return StoreFactoryAdmin.countStoresByCurrentHubAndStoreType({
                storeTypeId: storeTypeId
            }).$promise;
        }

        function checkShippingConnected(storeName) {
            return StoreFactoryAdmin.checkShippingConnected({
                store: storeName
            }).$promise;
        }

        function authorizeShipping() {
            return StoreFactoryAdmin.authorizeShipping().$promise;
        }

        function authorizeBling() {
            return StoreFactoryAdmin.authorizeBling().$promise;
        }

        function getCssHub(hubName) {
            return StoreFactoryAdmin.cssHub({
                name: hubName
            }).$promise;
        }

		function getOwnerCategoryCurrentStore() {
			return StoreFactoryAdmin.getOwnerCategoryCurrentStore().$promise;
		}

        function updateDefaultFilter(defaultFilter) {
			return StoreFactoryAdmin.updateDefaultFilter(defaultFilter).$promise;
        }
    }
})();
