(function() {
    'use strict';
    angular
        .module('showcaseApp')
        .factory('CheckCEPStateFactory', CheckCEPStateFactory);

    CheckCEPStateFactory.$inject = ['$resource'];

    function CheckCEPStateFactory($resource) {
        var resourceUrl =  'zipcode/api/state';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data, headers) {
                    var response = {};
                    response.data = angular.fromJson(data);
                    response.headers = headers();
                    return response;
                }
            }
        });
    }
})();
