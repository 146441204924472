(function () {
    'use strict';

    angular
        .module('showcaseApp')
        .directive("moveNextOnMaxlength", function() {
            return {
                restrict: "A",
                link: function($scope, element) {
                    element.on("input", function(e) {
                        if(element.val().length == element.attr("maxlength")) {
                            var $nextElement = element.next();
                            if($nextElement.length) {
                                $nextElement[0].focus();
                            }
                        }
                    });
                    element.on("keydown keypress", function(event) {
                        if (event.which === 8 || event.which === 46) {
                          if (element.val().length == 0) {
                               var $prevElement = element.prev();
                               if ($prevElement.length) {
                                   $prevElement[0].focus();
                               } 
                          }
                        }
                    });
                }
            }
        })
        .controller('MainLoginController', MainLoginController);
        

    MainLoginController.$inject = ['$rootScope', '$state', '$timeout', '$localStorage', 'Auth', '$location', '$stateParams', '$scope' ];

    function MainLoginController($rootScope, $state, $timeout, $localStorage, Auth, $location, $stateParams, $scope) {
        var vm = this;

        vm.authenticationError = false;
        vm.accountExpiredError = false;
        vm.credentials = {};
        vm.login = login;
        vm.loginWithCode = loginWithCode;
        vm.backToLogin = backToLogin;
        vm.resendCode = resendCode;
        vm.password = null;
        vm.rememberMe = true;
        vm.usernameOrEmail = null;
        vm.isAuthenticating = false;
        vm.isHubShowkase = false;
        vm.hasTwoFactorAuth = false;
        vm.code1 = '';
        vm.code2 = '';
        vm.code3 = '';
        vm.code4 = '';
        vm.code5 = '';
        vm.code6 = '';
        vm.twoFactorAuthData = {
            code: null,
            token: ''
        };
        vm.authEmail = '';

        vm.isToValidateCupom = '';

        if (window.location.search.includes('cpm')) {
            var queryString = window.location.search;
            vm.isToValidateCupom = queryString.substr(queryString.indexOf("=") + 1);
        }

        if (window.location.host.includes('showkase')) {
            vm.isHubShowkase = true;
        }

        angular.element('body').removeClass('mini-navbar');
        $timeout(function () {
            angular.element('#usernameOrEmail').focus();
            localStorage.removeItem('satellizer_token');
        });

        vm.applyValue = function (event) {
            window.navigator.clipboard
                .readText()
                .then(function(clipText){
                    if (clipText) {
                        $scope.$apply(function() {
                            vm.code1 = isNaN(clipText[0]) ? null : +clipText[0];
                            vm.code2 = isNaN(clipText[1]) ? null : +clipText[1];
                            vm.code3 = isNaN(clipText[2]) ? null : +clipText[2];
                            vm.code4 = isNaN(clipText[3]) ? null : +clipText[3];
                            vm.code5 = isNaN(clipText[4]) ? null : +clipText[4];
                            vm.code6 = isNaN(clipText[5]) ? null : +clipText[5];
                        });
                    }
                });
        }

        function login() {
            if (hasStateParams() || vm.loginForm.$valid) {
                vm.isAuthenticating = true;
                vm.authenticationError = false;
                vm.accountExpiredError = false;
                $timeout(function () {
                    Auth.login({
                        usernameOrEmail: vm.usernameOrEmail,
                        password: vm.password,
                        rememberMe: vm.rememberMe
                    }).then(function (data) {
                        vm.isAuthenticating = false;
                        vm.authenticationError = false;
                        vm.accountExpiredError = false;

                        if (data && data.data.tokenTwoFactorAuth) {
                            vm.hasTwoFactorAuth = true;
                            vm.authEmail = data.data.email;
                            vm.twoFactorAuthData.token = data.data.id_token;
                        } else {
                            processAuthentication();
                        }
                    }).catch(function (error) {
                        vm.isAuthenticating = false;
                        var accountExpiredCode = '25';
                        if (error.headers('statuscode') && error.headers('statuscode') === accountExpiredCode) {
                            vm.accountExpiredError = true;
                            $state.go('checkoutInner');
                            return;
                        } else {
                            vm.authenticationError = true;
                        }
                    });
                }, 500);
            }
        }

        function loginWithCode() {
            if (vm.twoAuthForm.$valid) {
                vm.isAuthenticating = true;
                vm.authenticationError = false;
                vm.accountExpiredError = false;

                var code = vm.code1 + '' + vm.code2 + '' + vm.code3 + '' + vm.code4 + '' + vm.code5 + '' + vm.code6;

                $timeout(function () {
                    Auth.loginAuthCode({
                        token: vm.twoFactorAuthData.token,
                        code: code,
                    }).then(function (data) {
                        vm.isAuthenticating = false;
                        vm.authenticationError = false;
                        vm.accountExpiredError = false;
                        processAuthentication();
                    }).catch(function (error) {
                        vm.isAuthenticating = false;
                        var accountExpiredCode = '25';
                        if (error.headers('statuscode') && error.headers('statuscode') === accountExpiredCode) {
                            vm.accountExpiredError = true;
                            $state.go('checkoutInner');
                            return;
                        } else {
                            vm.authenticationError = true;
                        }
                    });
                }, 500);
            }
        }

        function resendCode() { 
            vm.isAuthenticating = true;
            Auth.generateNewCode({
                token: vm.twoFactorAuthData.token,
                code: '000000'
            }).then(function (data) {
                vm.isAuthenticating = false;
                clearCode();
            }).catch(function (error) {
                vm.isAuthenticating = false;
                vm.authenticationError = true;
            });
        }

        function backToLogin() {
            vm.hasTwoFactorAuth = false;
            clearCode();
        }

        function clearCode() {
            vm.code1 = null;
            vm.code2 = null;
            vm.code3 = null;
            vm.code4 = null;
            vm.code5 = null;
            vm.code6 = null;
            vm.authEmail = '';
        }

        function processAuthentication() {
            $rootScope.$broadcast('authenticationSuccess');
            if (isUserHasStores()) {
                if (vm.isToValidateCupom) {
                    $state.go('redeem-coupons', {cupom: vm.isToValidateCupom});
                } else {
                    $state.go('home');
                }
            } else {
                $state.go('store-info');
            }
            if (Auth.getPreviousState()) {
                var previousState = Auth.getPreviousState();
                Auth.resetPreviousState();
                $state.go(previousState.name, previousState.params);
            }
        }

        function isUserHasStores() {
            if ($localStorage.defaultStore) {
                vm.defaultStore = $localStorage.defaultStore.storeId;
                return vm.defaultStore != null;
            }
            
            return null;
        }


        function hasStateParams(){
            return $stateParams.email != undefined && $stateParams.password != undefined;
        }


        function loadParams() {
            vm.usernameOrEmail = $stateParams.email;
            vm.password = $stateParams.password;
            login();
        }

        if(hasStateParams()) loadParams();
    }
})();
