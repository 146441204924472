(function() {
    'use strict';

    angular
        .module('showcaseApp')
        .factory('ChatAssignmentRuleService', ChatAssignmentRuleService);

        ChatAssignmentRuleService.$inject = ['ChatAssignmentRuleFactory'];

    function ChatAssignmentRuleService(ChatAssignmentRuleFactory) {

        var service = {
            save: save,
            get: get,
            list: list,
            update: update,
            remove: remove
        };

        return service;

        function save(body) {
            return ChatAssignmentRuleFactory.save(body).$promise;
        }

        function list(storeId) {
            return ChatAssignmentRuleFactory.list({storeId: storeId}).$promise;
        }

        function get(id, storeId) {
            return ChatAssignmentRuleFactory.get({id: id, storeId: storeId}).$promise;
        }

        function update(id, body) {
            return ChatAssignmentRuleFactory.update({id: id}, body).$promise;
        }

        function remove(id, storeId) {
            return ChatAssignmentRuleFactory.remove({id: id, storeId: storeId}).$promise;
        }
    }
})();
