(function () {
    'use strict';

    angular.module('showcaseApp').factory('SelectOfferModalService', SelectOfferModalService);

    function SelectOfferModalService($uibModal, FlyersService, $q) {
        var selectOffers = function selectOffers(singleSelection, title) {
            return $uibModal.open({
                templateUrl: 'app/components/modal/select-offer/select-offer-modal.html',
                controller: 'SelectOfferModalController',
                controllerAs: 'vm',
                size: 'md',
                windowClass: 'open-modal-in-forward',
                resolve: {
                    translatePartialLoader: ['$translate',
                        '$translatePartialLoader',
                        function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('select-offer-modal');
                            return $translate.refresh();
                        }
                    ],
                    singleSelection: function () {
                        return singleSelection;
                    },
                    title: function () {
                        return title;
                    }
                }
            });
        };

        return {
            selectOffers: selectOffers
        }
    }
})();
