(function() {
    'use strict';
    angular
        .module('showcaseApp')
        .factory('GoogleMapsAddressFactory', GoogleMapsAddressFactory);

    GoogleMapsAddressFactory.$inject = ['$resource', '$http', '$q'];

    function GoogleMapsAddressFactory($resource, $http, $q) {
        return {
            getAddress: getAddress,
            getAddressByCoordinates: getAddressByCoordinates
        }

        function getAddress(userAddress) {
            var deferred = $q.defer();
            var geocoder = new google.maps.Geocoder();
            var geocoderRequest = { address: userAddress };
            geocoder.geocode(geocoderRequest, function(results, status){
                deferred.resolve(results[0]);
            });
            return deferred.promise;
        }

        function getAddressByCoordinates(markerLatLong) {
            var deferred = $q.defer();
            var geocoder = new google.maps.Geocoder();
            var geocoderRequest = { location: markerLatLong };
            geocoder.geocode(geocoderRequest, function(results, status){
                deferred.resolve(results[0]);
            });
            return deferred.promise;
        }

    }
})();
