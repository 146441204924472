(function() {
    'use strict';

    angular
        .module('showcaseApp')
        .controller('UserManagementDialogController', UserManagementDialogController);

    UserManagementDialogController.$inject = ['$scope', '$uibModalInstance', 'UserService', 'JhiLanguageService', 'UserProfileService', 'storeId', 'store' ,'user', 'toastr', '$translate', 'ADMIN_SYSTEM_ID', 'moment'];

    function UserManagementDialogController ($scope, $uibModalInstance, UserService, JhiLanguageService, UserProfileService, storeId, store, user, toastr, $translate, ADMIN_SYSTEM_ID, moment) {
        var format = 'L';
        var vm = this;

        vm.maskLocale = '99/99/9999';
        
        vm.clear = clear;
        vm.languages = null;
        vm.save = save;
        vm.users = findUserByEmail;
        vm.userDescription = userDescription;
        vm.storeId = storeId;
        vm.store = store;
        vm.user = user ? user.data : {};
        if (user){
            vm.user.dateOfBirth = moment(vm.user.dateOfBirth).format(format);
        }
        vm.paste = paste;
        vm.isSaving = false;
        vm.getGenderKey = getGenderKey;
        vm.initDateTimePicker = dateTimePicker;
        

        vm.hasProfileSupport = hasProfileSupport;

        loadAll();

        function loadAll() {
            JhiLanguageService.getAll().then(function (languages) {
                vm.languages = languages;
            });
            if(vm.user && vm.storeId !== ADMIN_SYSTEM_ID){
                loadUserProfiles();                
            }
            loadUserGenders();
            dateTimePicker();
        }

        function loadUserProfiles() {
            UserProfileService.getProfiles(vm.storeId).then(function (response) {
                vm.profiles = response.data;
            });
        }
        
        function loadUserGenders() {
        	UserService.listUserGendersToSelectComponent().then(function (genders) {
                vm.genders = genders;
            });
        }
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function onSaveSuccess (result) {
            vm.isSaving = false;
            $scope.$emit('userUpdated', result);
            toastr.success($translate.instant(result.headers['x-showcaseapp-alertkey']));
            $uibModalInstance.close(result);
        }
        
        function onUpdateSuccess (result) {
            vm.isSaving = false;
            $scope.$emit('userUpdated', result);
            toastr.success($translate.instant('userManagement.updated'));
            $uibModalInstance.close(result);
        }

        function onSaveError (error) {
            vm.isSaving = false;
            toastr.error($translate.instant(error.data.headers['x-showcaseapp-params'].concat(".").concat(error.data.headers['x-showcaseapp-error'])));
        }

        function save (form) {
            if(form.$valid){
                vm.isSaving = true;

                var userToSave = angular.copy(vm.user);
                userToSave.dateOfBirth = moment(vm.user.dateOfBirth, format).toDate();
                userToSave.domain = vm.store.nickname;
                if (userToSave.id && !vm.registeredUser) {
                    UserService.updateUser(userToSave).then(function (result) {
                        onUpdateSuccess(result);
                    }, function (error) {
                        onSaveError(error);
                    });
                } else {
                    // Se o perfil não for de administrador
                    if (vm.storeId != 0) {
                        if(!angular.isUndefined(userToSave.userProfiles[0])) {
                            var profileId =  userToSave.userProfiles[0].profileId;
                            if(vm.registeredUser){
                                UserService.addUser(userToSave.email, userToSave.phone ,profileId, vm.storeId).then(function (result) {
                                    onSaveSuccess(result);
                                }, function (error) {
                                    onSaveError(error);
                                });
                            }else {
                                userToSave.userProfiles = [];
                                userToSave.userProfiles.push({profileId: profileId, storeId: vm.storeId, isDefault: !vm.registeredUser});
                                UserService.createUser(userToSave).then(function (result) {
                                    onSaveSuccess(result);
                                }, function (error) {
                                    onSaveError(error);
                                });
                            }
                        }
                    } else {
                        if(angular.isUndefined(userToSave.userProfiles)){
                            userToSave.userProfiles = [];
                        }
                        // Perfil de administrador
                        if (userToSave.profileType === "1") {
                            userToSave.userProfiles.push({profileId: 1, storeId: 0, isDefault: true});
                        } else {
                            // Perfil de Store Manager
                            userToSave.userProfiles.push({profileId: 2});
                        }
                        UserService.createUser(userToSave).then(function (result) {
                            onSaveSuccess(result);
                        }, function (error) {
                            onSaveError(error);
                        });
                    }
                }
            }
        }
        
        $scope.$watch('vm.user.email', function(user) {
        	if(angular.isObject(user)) {
                vm.user = user;
                vm.user.dateOfBirth = moment(user.dateOfBirth).format(format);
                vm.registeredUser = true;
            }
        	else if(angular.isUndefined(user)) {
                $scope.editForm.$setPristine();
        	    // vm.user.id = null;
            	vm.registeredUser = false;
            }
        });
        
        function findUserByEmail(email) {
            var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        	var mailIsValid = re.test(email);
            if(mailIsValid && vm.storeId != ADMIN_SYSTEM_ID) {
            	return UserService.findByEmail({
            		email : email
                }).then(function(response) {
                    if (response.data) {
                    	return response.data;
                    }
                    return [];
                });
            } else {
                return [];
            }
        };
        
        function userDescription(user) {
        	if (angular.isObject(user)) {
                return user.email + ' - ' + user.firstName + ' ' + user.lastName;
            }
        }
        
        function paste(e){
            e.preventDefault();
            toastr.warning($translate.instant('userManagement.enterEmail'));
            return false;
        }

        function getGenderKey(value) {
            return "userManagement".concat(".").concat(value);
        }

        function hasProfileSupport(user){
            if(user.userProfiles) {
                if(user.id && vm.storeId !== ADMIN_SYSTEM_ID){
                    //Profile id from server
                    var profileId = 9;
                    return user.userProfiles[0].profileId === profileId;     
                   }
            }
            return false;

        }

        function dateTimePicker() {
            vm.dateOfBirthOptions = {
                format: format,
                sideBySide: true,
                ignoreReadonly: true,
                locale: moment.locale(),
                allowInputToggle: true,
                showClear: true,
                widgetPositioning: {
                    horizontal: 'auto',
                    vertical: 'bottom'
                },
                maxDate: moment()
            };

            angular.element('#dateOfBirth').datetimepicker(vm.dateOfBirthOptions).on('dp.change', function(ev) {
                if (ev.date) {
                    $scope.$apply(function() {
                        vm.user.dateOfBirth = ev.date.format(format);
                    });
                } else {
                    $scope.$apply(function() {
                        vm.user.dateOfBirth = undefined;
                    });
                }
            });
        }
    }
})();
