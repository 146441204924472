(function () {
    'use strict';
    angular
        .module('showcaseApp')
        .controller('InvoiceModalDetailController', InvoiceModalDetailController);

    function InvoiceModalDetailController(invoice, InvoicesService, $uibModalInstance, toastr, $translate, $localStorage, StoreService) {

        var vm = this;

        init();

        function init() {
            vm.invoice = invoice;
            vm.orders = [];
            vm.discounts = [];
            vm.subTotal = 0;
            vm.discount = 0;
            vm.hasDiscount = false;
            vm.isBrl = false;
            loadStore();
            loadDiscount();
            loadTransactionOrderList(vm.invoice.id)
        }

        function loadDiscount() {
            if (vm.invoice && vm.invoice.discounts) {
                vm.discounts = vm.invoice.discounts.filter(function(item) {
                    return item.discountValueType != 'FREE_SHIPPING';
                });
                vm.discount = vm.invoice.discounts.filter(function(item) {
                        return item.discountValueType != 'FREE_SHIPPING';
                    })
                    .map(function(item) {
                        return item.calculatedDiscountValue;
                    })
                    .reduce(function(a, b) { return a + b},  0);
                vm.hasDiscount = vm.invoice.discounts.filter(function(item) {
                    return item.discountValueType != 'FREE_SHIPPING';
                }).length > 0;
            }
        }

        function loadStore() {
            StoreService.getStore($localStorage.defaultStore.storeId).then(function(response) {
                vm.storeCategory = response.data.categoryKey;
                vm.isBrl = response.data.langKey == 'pt-br';
            });
        }

        function loadTransactionOrderList(transactionId) {
            InvoicesService.getTransactionOrders(transactionId).then(function (result) {
                if (result.data) {
                    for (var i = 0; i < result.data.length; i++) {
                        var order = result.data[i];
                        order.size = order.size ? order.size : 'Padrão';
                        order.size = order.size.toUpperCase() === 'DEFAULT' ? 'Padrão' : order.size;
                        vm.orders.push(order);

                        if(order.discountedValue) {
                            vm.subTotal += (order.discountedValue * order.quantity);
                        } else {
                            vm.subTotal += (order.purchaseValue * order.quantity);
                        }
                    }
                }
            }, function (error) {
                vm.orders = [];
            });
        }

        vm.getPhoneNumber = function (phone) {
            return phone.includes('null') ? phone.split('null')[1] : phone.includes('+') ? phone.split('+')[1] : phone;
        }

        vm.getShippingValue = function() {
            return vm.invoice.shippingValue ? vm.invoice.shippingValue : 0;
        }

        vm.getDocumentType = function () {
            if (!vm.invoice.cpf) {
                return 'CPF';
            }

            return vm.invoice.cpf.length === 11 ? 'CPF' : 'CNPJ';
        }

        vm.printInvoice = function() {
            var idContainer = 'invoice-detail-modal';

            var container = document.getElementById(idContainer).innerHTML,
            screenPrint = window.open('', '_blank', 'width=600,height=600');
            screenPrint.document.write('<!DOCTYPE html>\
            <head>\
                <link rel="stylesheet" type="text/css" href="/content/css/vendor.css" />\
                <link href="https://fonts.googleapis.com/css?family=Roboto:400,300,500,700|Montserrat|Cabin" rel="stylesheet">\
                <link rel="stylesheet" type="text/css" href="/content/css/main.css" />\
                <style>\
                    #btn-actions {display:none;}\
                    @media print {\
                        .text-navy {\
                            color: #2F0E7F !important;\
                            -webkit-print-color-adjust: exact;\
                        }\
                    }\
                    h5, h4, p, table, th, address {\
                        font-size: 12px;\
                    }\
                    .printable-table {\
                        max-height: none !important;\
                    }\
                </style>\
            </head>\
            <body>' + container + '</body>\
            <script>\
                window.print();\
            </script>\
            </html>');
        }

        vm.cancelInvoice = function() {
            vm.isSaving = true;
            InvoicesService.updateTransactionStatus(vm.invoice.id, 'CANCELLED').then(function(result) {
                toastr.success($translate.instant('invoice.home.messages.saveSuccess'));
                vm.isSaving = false;
                $uibModalInstance.close();
            }).catch(function(err) {
                toastr.error($translate.instant('invoice.home.messages.noItens'));
                vm.isSaving = false;
            });
        }

        vm.close = function() {
            $uibModalInstance.close();
        }

        vm.openRefundModal = function() {
            $uibModalInstance.close({ openRefundModal: true, invoice: invoice });
        }

        vm.maskPrice = function (value, currencyKey) {
            var currencyConfiguration = getCurrencyListConfiguration()[currencyKey];
            return formatMoney(value, currencyConfiguration.prefix, currencyConfiguration.decimalQuant, currencyConfiguration.centsSeparator, currencyConfiguration.thousandsSeparator);
        }
    }
  })();
