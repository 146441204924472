(function() {
    'use strict';

    angular
        .module('showcaseApp')
        .controller('StoreCategorysNewController', StoreCategorysNewController);

    StoreCategorysNewController.$inject = ['$timeout', '$localStorage', '$state', '$scope', '$stateParams', '$translate', '$uibModalInstance', 'CategoryService', 'toastr'];

    function StoreCategorysNewController($timeout, $localStorage, $state, $scope, $stateParams, $translate, $uibModalInstance, CategoryService, toastr) {
        var vm = this;

        vm.category = { key: "", languages: [ {langKey: "pt-BR", title: ""}, {langKey: "en", title: ""}, {langKey: "es", title: ""} ]};

        vm.save = save;

        function save () {
          CategoryService.createCategory(vm.category).then(function (data) {
            toastr.success($translate.instant('categorys.messages.new.success'));
            $uibModalInstance.close(true);
          }, function () {
            toastr.error($translate.instant('categorys.messages.new.error'));
          });
        }
    }

})();
