(function() {
    'use strict';

    angular
        .module('showcaseApp')
        .controller('FiltersConversationController', FiltersConversationController);

        FiltersConversationController.$inject = ['$uibModalInstance', '$translate', 'WatiService', '$localStorage', 'toastr', 'MessengerService', 'attendants', 'filters', 'isWatiConnected', 'isFacebookConnected'];

    function FiltersConversationController($uibModalInstance, $translate, WatiService, $localStorage, toastr, MessengerService, attendants, filters, isWatiConnected, isFacebookConnected) {

        var vm = this;

        vm.filters = filters;
        vm.isFacebookConnected = isFacebookConnected;
        vm.isWatiConnected = isWatiConnected;
        vm.maxDate = moment();

        vm.status = [
            {value: "EXPIRED", label: "Expirado", id: 1},
            {value: "NEW", label: "Novo", id: 2},
            {value: "OPENED", label: "Aberto", id: 3},
            {value: "PENDING", label: "Pendente", id: 4},
            {value: "RESOLVED", label: "Resolvido", id: 5}
        ];

        vm.orderFields = [
            {label: "Não lidas", value: {field: "unread_messages", direction: "DESC"}, id: 1},
            {label: "Lidas", value: {field: "unread_messages", direction: "ASC"}, id: 2},
            {label: "Mais antigas", value: {field: "timestamp", direction: "ASC"}, id: 3},
            {label: "Mais recentes", value: {field: "timestamp", direction: "DESC"}, id: 4}
        ];

        vm.attendants = attendants;

        var dateFormat = "DD/MM/YYYY";
        vm.dateRangeOptions = {
            locale: {
                applyClass: 'btn-green',
                applyLabel: "Aplicar",
                fromLabel: "From",
                format: dateFormat,
                toLabel: "To",
                cancelLabel: 'Cancelar',
                customRangeLabel: 'Personalizado'
            },
            ranges: {
                'Desde de ontem': [moment().subtract(1, 'days'), moment(new Date())],
                'Últimos 7 dias': [moment().subtract(7, 'days'), moment()],
                'Últimos 30 dias': [moment().subtract(30, 'days'), moment()],
                'Último ano': [moment().subtract(1, 'year'), moment()]
            },
            eventHandlers: {
                'apply.daterangepicker': function (ev, picker) {
                }
            },
            alwaysShowCalendars: true
        };
        vm.dateRange = null;

        vm.closeModal = function(response) {
            $uibModalInstance.close(response);
        }

        vm.closeDropDown = function () {
            $("md-backdrop").trigger('click');
        }

        vm.getSelectedText = function (filter) {

            if (filter.length === 0) return '';
            var elementTranslate = filter[0].label;

            if (filter.length == 1) {
                return '<span class="highlight">' + elementTranslate + '</span>';
            }
            if (filter.length > 1) {
                var temp = '  +';
                temp += filter.length - 1;
                temp += ' selecionados';

                return '<span class="highlight">' + elementTranslate + '</span>' +
                    '<span>&nbsp</span>' +
                    '<span class="highlight">' + temp + '</span>';
            }
            return '';
        }

        function makeLists() {
            var attendants = []
            vm.attendants.forEach(function(attendant) {
                attendants.push({label: attendant.firstName + " " + attendant.lastName, value: attendant.id})
            });
            vm.attendants = attendants;

            var socialMedias = []

            if (isWatiConnected) {
                socialMedias.push({ label: "WhatsApp", value: "WATI", id: 1 })
            }
            if (isFacebookConnected) {
                socialMedias.push({ label: "Messenger", value: "MESSENGER", id: 2 })
                socialMedias.push({ label: "Instagram", value: "INSTAGRAM", id: 3 })
            }

            vm.socialMedias = socialMedias;
        }

        makeLists();

        vm.clearFilters = function () {
            vm.filters = {
                status: [{
                    "value": "EXPIRED",
                    "label": "Expirado",
                    "id": 1
                },
                    {
                        "value": "NEW",
                        "label": "Novo",
                        "id": 2
                    },
                    {
                        "value": "OPENED",
                        "label": "Aberto",
                        "id": 3
                    },
                    {
                        "value": "PENDING",
                        "label": "Pendente",
                        "id": 4
                    }],
                attendants: [],
                socialMedias: [],
                rangeDate: {startDate: null, endDate: null},
                order: {}
            };
        }

        vm.apllyFilters = function () {
            var filters = angular.copy(vm.filters);
            filters.order = filters.order.value ? filters.order.value : {};
            filters.attendants = filters.attendants.map(function(attendant){ return attendant.value });
            filters.status = filters.status.map(function(status){ return status.value });
            filters.socialMedias = filters.socialMedias.map(function(socialMedia){ return socialMedia.value });
            filters.rangeDate = filters.rangeDate.startDate && filters.rangeDate.endDate ? formatDate(filters.rangeDate.startDate, filters.rangeDate.endDate) : null;
            filters.allAttendants = attendants.map(function(attendant) { return attendant.id })
            vm.closeModal({filtered: filters, selectedFilters: angular.copy(vm.filters)});
        }

        function formatDate(startDate, endDate) {
            var start = startDate.format('YYYY-MM-DD');
            var end = endDate.format('YYYY-MM-DD');
            return start + "->" + end;
        }

    }

})();
