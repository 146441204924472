(function() {
    'use strict';
    angular
        .module('showcaseApp')
        .factory('InvoicesFactory', InvoicesFactory);

    InvoicesFactory.$inject = ['$resource'];

    function InvoicesFactory($resource) {
        var resourceUrl =  'flyer/api/transaction/list?type=SALE';

        return $resource(resourceUrl, {id: '@id'}, {
            'listAll': {
                method: 'GET',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data){
                        response.data = angular.fromJson(data);
                    } else{
                        response.data = data;
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'updateStatus': {
                method: 'PUT',
                url: 'flyer/api/transaction/:id/update-status',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data){
                        response.data = angular.fromJson(data);
                    } else{
                        response.data = data;
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'list': {
                method: 'POST',
                url: 'flyer/api/transaction/list',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data){
                        response.data = angular.fromJson(data);
                    } else{
                        response.data = data;
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'getTransactionOrders': {
                method: 'GET',
                url: 'flyer/api/transaction/orders/:transactionId',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data){
                        response.data = angular.fromJson(data);
                    } else{
                        response.data = data;
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'createInvoice': {
                method: 'POST',
                url: 'flyer/api/invoice',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    } else {
                        response.data = data;
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'refundInvoice': {
                method: 'POST',
                url: 'flyer/api/transaction/:id/refund',
                transformResponse: function (data, headers) {
                    var response = {};
                    response.data = data;
                    response.headers = headers();
                    return response;
                }
            },
            'calculateDiscount': {
                method: 'POST',
                url: 'flyer/api/invoice/calculate-discount',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data){
                        response.data = angular.fromJson(data);
                    } else {
                        response.data = data;
                    }
                    response.headers = headers();
                    return response;
                }
            },
        });
    }

})();
