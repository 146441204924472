(function() {
    'use strict';

    angular
        .module('showcaseApp')
        .controller('RedeemCouponsController', RedeemCouponsController);

    RedeemCouponsController.$inject = ['TimeService', '$localStorage', '$translate', 'RedeemCouponsService', 'StoreService', 'FlyersCurrencyValue',
    'toastr', '$stateParams', '$location'];

    function RedeemCouponsController(TimeService, $localStorage, $translate, RedeemCouponsService, StoreService, FlyersCurrencyValue,
        toastr, $stateParams, $location) {

        var vm = this;

        vm.coupon = {};

        vm.salesPoints = [];

        vm.storeId = $localStorage.defaultStore.storeId;

        vm.redeemed = false;

        if ( ($stateParams && $stateParams.cupom) || $location.search().cpm) {
            vm.codeCoupon = $stateParams.cupom || $location.search().cpm;
            $location.url($location.path());
        }

        loadAll();

        vm.checkRedeemed = function() {
            if (vm.redeemed) {
                vm.flyer = null;
                vm.redeemed = false;
            }
        }

        vm.validateCoupon = function() {
            RedeemCouponsService.validateCoupon(vm.codeCoupon, vm.salesPointId).then(function(data) {
                vm.couponCode = vm.codeCoupon;
                vm.flyer = data.flyer;
                vm.redeemed = false;
            }, function(error) {
                toastr.error($translate.instant('redeem-coupons.home.messages.error'.concat(".").concat(error.headers()['statuskey'])));
            });
        }

        vm.redeemCoupon = function() {
            RedeemCouponsService.redeemCoupon(vm.couponCode, vm.salesPointId).then(function(data) {
                vm.redeemed = true;
                vm.coupon = {};
                vm.codeCoupon = "";
                vm.salesPointId = "";
            });
        }


        vm.getImagePath = function(flyerId) {
            return ('/flyer/api/' + flyerId + '/picture');
        }

        vm.getFlyerCurrency = function(currency) {
            return FlyersCurrencyValue[currency];
        }

        vm.calcTimeDifference = function(expirationDate) {
            return TimeService.calcTimeDifference(expirationDate);
        }

        vm.getExpirationDate = function(expirationDate) {
            return new Date(expirationDate).toLocaleDateString();
        }

        function loadAll() {
            StoreService.getSalesPoints(vm.storeId).then(function(response) {
                vm.salesPoints = response.data;
                vm.salesPointId = vm.salesPoints[0] ? vm.salesPoints[0].id + '' : '';
             });
        }

    }

})();
