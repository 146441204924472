(function() {
    "use strict";

    angular
        .module("showcaseApp")
        .factory("RemoveModalFactory", RemoveModalFactory);


    /** @ngInject */
    function RemoveModalFactory($uibModal) {

        var factory = {
            deleteItem: deleteItem,
            deleteCoupon: deleteCoupon
        };

        return factory;


        function deleteItem(itens) {
            return $uibModal.open({
                templateUrl: 'app/services/global/remove/remove.modal.html',
                controller: 'RemoveModalController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    itens: function() {
                        return itens;
                    }
                }
            }).result;
        }

        function deleteCoupon() {
            return $uibModal.open({
                templateUrl: 'app/services/global/remove/remove-coupon.modal.html',
                controller: 'RemoveCouponModalController',
                controllerAs: 'vm',
                size: 'md'
            }).result;
        }


    }

})();
