(function() {
    'use strict';
    angular
        .module('showcaseApp')
        .service('ChatTicketService', ChatTicketService);

        ChatTicketService.$inject = ['ChatTicketFactory'];

    function ChatTicketService(ChatTicketFactory) {

        var service = {
            getChatTicket: getChatTicket,
            updateChatTicket: updateChatTicket,
            updateChatTicketStatus: updateChatTicketStatus,
            assignTicketToAttendant: assignTicketToAttendant,
            getAnExistChatTicket: getAnExistChatTicket,
            searchByName: searchByName,
            searchByFilter: searchByFilter,
            getSumBySocialMediaName: getSumBySocialMediaName,
            changeTicketFavorite: changeTicketFavorite
        };

        return service;

        function getChatTicket(chatId, socialMediaName, socialMediaId, lastMessage, lastUpdate, messageType, customerId, lastIncomingDate){
            return ChatTicketFactory.getChatTicket({chatId: chatId, socialMediaName: socialMediaName, socialMediaId: socialMediaId, lastMessage: lastMessage, lastUpdate: lastUpdate, messageType: messageType, customerId: customerId, lastIncomingDate: lastIncomingDate}).$promise;
        }

        function updateChatTicket(id, body){
            return ChatTicketFactory.updateChatTicket({id: id}, body).$promise;
        }

        function updateChatTicketStatus(id, body){
            return ChatTicketFactory.updateChatTicketStatus({id: id}, body).$promise;
        }

        function changeTicketFavorite(id){
            return ChatTicketFactory.changeTicketFavorite({id: id}).$promise;
        }

        function assignTicketToAttendant(chatTicketId, attendantId) {
            return ChatTicketFactory.assignTicketToAttendant({id: chatTicketId}, attendantId).$promise;
        }

        function getAnExistChatTicket(chatId, socialMediaName, socialMediaId, lastMessage, lastUpdate, messageType, customerId, attendantId) {
            return ChatTicketFactory.getAnExistChatTicket({chatId: chatId, socialMediaName: socialMediaName, socialMediaId: socialMediaId,
                lastMessage: lastMessage, lastUpdate: lastUpdate, messageType: messageType, customerId: customerId, attendantId: attendantId}).$promise;
        }

        function searchByName(searchText) {
            return ChatTicketFactory.searchByName({searchText: searchText}).$promise;
        }

        function searchByFilter(filters) {
            return ChatTicketFactory.searchByFilter(filters).$promise;
        }

        function getSumBySocialMediaName(socialMediaName) {
            return ChatTicketFactory.getSumBySocialMediaName({socialMediaName: socialMediaName}).$promise;
        }
    }

})();
