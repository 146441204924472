(function () {
    'use strict';

    angular
        .module('showcaseApp')
        .controller('FormStoreLogoController', FormStoreLogoController);

    /** @ngInject */
    function FormStoreLogoController($scope) {

        var vm = this;
        vm.store = $scope.store;

        vm.imageSize = 500;
        vm.store.image = '';

        vm.openInput = function(evt) {
            document.getElementById('fileInput').click();
        }
        
        var handleFileSelect = function(evt) {
            var file = evt.currentTarget.files[0];
            var reader = new FileReader();
            reader.onload = function (evt) {
                $scope.$apply(function($scope){
                    $scope.logo.imageUpload = evt.target.result;
                });
            };
            reader.readAsDataURL(file);
        };

        angular.element(document.querySelector('#fileInput')).on('change', handleFileSelect);
    }
})();
