'use strict';

//Directive used to set metisMenu and minimalize button
angular.module('showcaseApp')
    .directive('sideNavigation',sideNavigation)
    .directive('minimalizaSidebar', minimalizaSidebar)
    .directive('landingScrollspy', landingScrollspy)
    .directive('numbersOnly', numbersOnly)
    .directive('alphanumericOnly', alphanumericOnly)
    .directive('lettersOnly', lettersOnly)
    .directive('pageTitle', pageTitle)
    .directive('resizable', resizable)
    .directive('slimScroll', slimScroll)
    .directive('fullScroll', fullScroll)
    .directive('backImg', backImg)
    .directive('imgSrc', imgSrc)
    .directive('uppercase', uppercase);

function numbersOnly() {
    return {
        require: 'ngModel',
        link: function (scope, element, attr, ngModelCtrl) {
            function fromUser(text) {
                if (text) {
                    var transformedInput = text.replace(new RegExp(/[^\d]/g), '');

                    if(attr.exclude){
                        transformedInput = transformedInput.replace(attr.exclude, '')
                    }

                    if (transformedInput !== text) {
                        ngModelCtrl.$setViewValue(transformedInput);
                        ngModelCtrl.$render();
                    }
                    return transformedInput;
                }
                return undefined;
            }
            ngModelCtrl.$parsers.push(fromUser);
        }
    };
}


function alphanumericOnly() {
    return {
        require: 'ngModel',
        link: function(scope, element, attr, ngModelCtrl) {
            element.bind('keydown', function(e) {
                if (e.which === 32) {
                    e.preventDefault();
                }
            });

            function fromUser(text) {
                if(text) {
                    var transformedInput = text.replace(/[^a-zA-Z0-9]/g, '');

                    if(attr.exclude){
                        transformedInput = transformedInput.replace(attr.exclude, '')
                    }

                    if(transformedInput !== text) {
                        ngModelCtrl.$setViewValue(transformedInput);
                        ngModelCtrl.$render();
                    }
                    return transformedInput;
                }
                return undefined;
            }
            ngModelCtrl.$parsers.push(fromUser);
        }
    };
}

function lettersOnly() {
    return {
        require: 'ngModel',
        link: function(scope, element, attr, ngModelCtrl) {
            function fromUser(text) {
                if(text) {
                    var transformedInput = text.replace(/[^a-zA-Z]/g, '');

                    if(transformedInput !== text) {
                        ngModelCtrl.$setViewValue(transformedInput);
                        ngModelCtrl.$render();
                    }
                    return transformedInput;
                }
                return undefined;
            }
            ngModelCtrl.$parsers.push(fromUser);
        }
    };
}

function resizable() {
    return {
        restrict: 'A',
        link: function(scope, element, attrs) {
            function makeResizable(element){
                if (element && jQuery(element).length){
                    var $el = jQuery(element);
                    var elHeight = $el.outerHeight();
                    var elWidth = $el.outerWidth();

                    var $wrapper = $el.parent();

                    var starterData = {
                        size: {
                            width: $wrapper.width(),
                            height: $wrapper.height()
                        }
                    }
                    var scale = Math.min(
                        starterData.size.width / $el.outerWidth(),
                        starterData.size.height / $el.outerHeight()
                    );
                    if (scale > 1){
                        scale = 1;
                    }
                    var elMarginBottom = (scale * elHeight) - starterData.size.height ;
                    $el.css({
                        transform: "translate3d(-50%, 0, 0) " + "scale(" + scale + ")",
                        'margin-bottom': elMarginBottom
                    });
                }
            }
            jQuery(document).ready(function() {
                makeResizable('#very-specific-design');
            });

            jQuery(window).resize(function() {
                makeResizable('#very-specific-design');
            });
        }
    };
}

function pageTitle($timeout) {
    return {
        link: function(scope, element) {
            var listener = function(event, toState, toParams, fromState, fromParams) {
                // Default title - load on Dashboard 1
                var title = 'KAZTOR | Showcase';
                // Create your own title pattern
                if (toState.data && toState.data.pageTitle) title = 'Showcase | ' + toState.data.pageTitle;
                $timeout(function() {
                    element.text(title);
                });
            };
            $rootScope.$on('$stateChangeStart', listener);
        }
    }
}

function landingScrollspy() {
    return {
        restrict: 'A',
        link: function (scope, element, attrs) {
            element.scrollspy({
                target: '.navbar-fixed-top',
                offset: 80
            });
        }
    }
}

function minimalizaSidebar($timeout, $window) {
    return {
        restrict: 'A',
        template: '<a class="navbar-minimalize minimalize-styl-2 btn btn-primary " href="" ng-click="minimalize()"><i class="fa fa-bars"></i></a>',
        controller: function($scope) {

            $scope.minimalize = function() {
                // angular.element('body').toggleClass('mini-navbar');
                if(angular.element('body').hasClass('body-small') && !angular.element('body').hasClass('mini-navbar')){
                    angular.element('body').addClass('mini-navbar');
                } else if (angular.element('body').hasClass('mini-navbar')) {
                    angular.element('body').removeClass('mini-navbar');
                    angular.element('body').addClass('fixed-sidebar');
                } else {
                    angular.element('body').addClass('mini-navbar');
                    angular.element('body').removeClass('fixed-sidebar');
                };
                if (angular.element('#list-order-ul').hasClass("in")) {

                    angular.element('#list-order-ul').removeClass("in")
                    angular.element('#list-order-li').removeClass("active")
                }
                if (angular.element('#list-reports-ul').hasClass("in")) {

                    angular.element('#list-reports-ul').removeClass("in")
                    angular.element('#list-reports-li').removeClass("active")
                }

                if(!angular.element('body').hasClass('mini-navbar') || angular.element('body').hasClass('body-small')) {
                    // Hide menu in order to smoothly turn on when maximize menu
                    // angular.element('#side-menu').hide();
                    // angular.element('#side-menu').attr('style', 'overflow: hidden; width: auto;');
                    // For smoothly turn on menu
                    $timeout(function() {
                        angular.element('#side-menu').fadeIn(400);
                    }, 200);
                } else {
                    // Remove all inline style from jquery fadeIn function to
                    // reset menu state
                    angular.element('#side-menu').removeAttr('style');
                }

                $(document).ready(function() {

                    $(".slimScrollShowkaseMenu").slimScroll({
                        height: $window.innerHeight
                    });
                    $(".slimScrollDiv").slimScroll({
                        height: $window.innerHeight
                    });
                });

            };
            // $scope.minimalize();
        }
    };
}

function sideNavigation($timeout) {
    return {
        restrict: 'A',
        link: function (scope, element) {
            // Call metsi to build when user signup
            scope.$watch('authentication.user', function() {
                $timeout(function() {
                    element.metisMenu();
                });
            });

            // Colapse menu in mobile mode after click on element
            var menuElement = angular.element('#side-menu a:not([href$="\\#"])');
            menuElement.click(function(){
                if (angular.element(window).width() < 769) {
                    angular.element("body").toggleClass("mini-navbar");
                }
            });

            // Enable initial fixed sidebar
            // if (angular.element("body").hasClass('fixed-sidebar')) {
                var sidebar = element.parent();
                sidebar.slimScroll({
                    height: '100%',
                    railOpacity: 0.9
                });
            // }
        }
    };
}

function slimScroll(){
    return {
        restrict: 'A',
        link: function (scope, element, attrs) {
            element.slimScroll({
                height: attrs.maxHeight || '100%',
                railOpacity: 0.9
            });


        }
    };
}

/**
 * fullScroll - Directive for slimScroll with 100%
 */
function fullScroll($timeout){
    return {
        restrict: 'A',
        link: function(scope, element) {
            $timeout(function(){
                element.slimscroll({
                    height: '100%',
                    railOpacity: 0.9
                });

            });
        }
    };
}

var flyerDefaultImg = 'https://showkase-prod.s3.sa-east-1.amazonaws.com/hub/system-images/showkase/no-image.jpeg';

function backImg() {
    return function (scope, element, attrs) {
        var url = attrs.backImg;
        var image = new Image();
        image.src = url;
        image.onload = function () {
            // Image loaded - set the background image to it
            element.css("background-image", "url(" + url + ")");
        };
        image.onerror = function () {
            // Image failed to load - use default
            element.css("background-image", "url(" + flyerDefaultImg + ")");
        };
    };
}

function imgSrc() {
    return function (scope, element, attrs) {
        var url = attrs.imgSrc;
        var image = new Image();
        image.src = url;
        image.onload = function () {
            // Image loaded - set the src image to it
            element.attr('src', url);
        };
        image.onerror = function () {
            // Image failed to load - use default
            element.attr('src', flyerDefaultImg);
        };
    };
}

function uppercase() {
    return {
        require: 'ngModel',
        link: function(scope, element, attr, ngModelCtrl) {
            function fromUser(text) {
                console.log(text);
                if (text) {
                    var transformedInput = text.toUpperCase();;
                    console.log(transformedInput);
                    if(transformedInput !== text) {
                        ngModelCtrl.$setViewValue(transformedInput);
                        ngModelCtrl.$render();
                    }
                    return transformedInput;
                }
                return undefined;
            }
            ngModelCtrl.$parsers.push(fromUser);
        }
    };
}
