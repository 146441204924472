(function() {
    'use strict';
    angular
        .module('showcaseApp')
        .service('InvoicesService', InvoicesService);

        InvoicesService.$inject = ['InvoicesFactory', '$localStorage', 'Upload'];

    function InvoicesService(InvoicesFactory) {

        var service = {
            listAll: listAll,
            updateStatus: updateStatus,
            list: list,
            updateTransactionStatus: updateTransactionStatus,
            getTransactionOrders: getTransactionOrders,
            createInvoice: createInvoice,
            refundInvoice: refundInvoice,
            calculateDiscount: calculateDiscount
        };

        return service;

        function listAll() {
            return InvoicesFactory.listAll().$promise;
        }

        function updateStatus(transation) {
            return InvoicesFactory.updateStatus({id: transation.transaction_id, status: transation.status}).$promise;
        }

        function updateTransactionStatus(transationId, status) {
            return InvoicesFactory.updateStatus({id: transationId, status: status}).$promise;
        }

        function list(pageable, customerFilter) {
            return InvoicesFactory.list(pageable, customerFilter).$promise;
        }

        function getTransactionOrders(transactionId) {
            return InvoicesFactory.getTransactionOrders({transactionId: transactionId}).$promise;
        }

        function createInvoice(invoice) {
            return InvoicesFactory.createInvoice(invoice).$promise;
        }

        function refundInvoice(transactionId, amount) {
            return InvoicesFactory.refundInvoice({id: transactionId, amount: amount}).$promise;
        }

        function calculateDiscount(invoice) {
            return InvoicesFactory.calculateDiscount(invoice).$promise;
        }
    }
})();
