(function () {
    'use strict';

    angular.module('showcaseApp').factory('NotificationService', NotificationService);
    NotificationService.$inject = ['NotificationFactory', '$uibModal'];

    function NotificationService(NotificationFactory) {
        var service = {
            getNotificationsListByStoreAndReadIsFalse: getNotificationsListByStoreAndReadIsFalse,
            readNotification: readNotification,
            getNotificationInfo: getNotificationInfo,
            updateNotificationsAsReadByStoreIdAndConversationId: updateNotificationsAsReadByStoreIdAndConversationId
        };

        return service;

        function getNotificationsListByStoreAndReadIsFalse(id, pagination) {
            var params = {'storeId': id, 'page': pagination.page, 'size': pagination.limit};
            return NotificationFactory
                .getNotificationsListByStoreAndReadIsFalse(params).$promise;
        }

        function readNotification(id) {
            return NotificationFactory.readNotification({'id': id}, {}).$promise;
        }

        function updateNotificationsAsReadByStoreIdAndConversationId(storeId, conversationId) {
            var params = {'storeId': storeId, 'conversationId': conversationId};
            return NotificationFactory.updateNotificationsAsReadByStoreIdAndConversationId(params, {}).$promise;
        }

        function getNotificationInfo(storeId, accountId) {
            var params = {'storeId': storeId, 'accountId': accountId};
            return NotificationFactory.getNotificationInfo(params, {}).$promise;
        }
    }
})();
