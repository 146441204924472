/* globals $ */
(function() {
	'use strict';

	angular
		.module('showcaseApp')
		.directive('detectScrollToBottom', function () {

			return{

				restrict: 'A',
				link: function (scope, element, attrs) {
					var fn = scope.$eval(attrs.detectScrollToBottom);
					element.on('scroll', function (e) {
						if ((e.target.scrollHeight - e.target.scrollTop) <= e.target.clientHeight + 1) {
							scope.$apply(fn);
						}
					});
				}

			};
		})
})();
