(function() {
    'use strict';

    angular
        .module('showcaseApp')
        .controller('SendImagesModalController', SendImagesModalController);

    SendImagesModalController.$inject = ['$uibModalInstance', '$timeout', '$scope', '$window', 'images', 'conversation', 'communicationConstants',
                                        'ImageManagerService'];

    function SendImagesModalController($uibModalInstance, $timeout, $scope, $window, images, conversation, communicationConstants, ImageManagerService) {

        var vm = this;

        vm.imagesToSend = images;
        vm.selectedImage = vm.imagesToSend[0];
        vm.conversation = conversation;
        vm.supportedImagesType = communicationConstants.SUPPORTED_IMAGES_TYPES;

        vm.closeAndSendFile = function() {
            clearInput();
            $uibModalInstance.close(vm.imagesToSend);
        }

        vm.dismiss = function() {
            clearInput();
            $uibModalInstance.dismiss();
        }

        vm.openImageSelect = function () {
            angular.element('#more-image-upload-sheet').trigger('click');
        }

        vm.removeImage = function (index) {
            _.remove(vm.imagesToSend, function (image) {
               return image.index === index;
            });

            if (vm.imagesToSend.length === 0) {
                clearInput();
                $uibModalInstance.dismiss();
            }
        }

        vm.addMoreImages = function (event) {
            var images = event.target.files;
            Array.from(images).forEach(function (file, index) {
                var reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = function () {
                    var imageBlob = dataURItoBlob(reader.result);
                    var setting = makeSettingByBase64(reader.result);

                    var img = new Image();
                    img.onload = function (evt) {
                        var dataurl = reader.result;

                        if (img.width > 2560 || img.height > 1440) {
                            dataurl = ImageManagerService.handleImageSize(img, file);
                        }

                        vm.imagesToSend.push({
                            index: vm.imagesToSend[vm.imagesToSend.length - 1].index + 1,
                            name: file.name,
                            base64: dataurl.split(',')[1],
                            fullBase64: dataurl,
                            type: setting.type,
                            mimeType: setting.mimeType,
                            blob: imageBlob
                        });

                        $scope.$apply();
                    };
                    img.src = reader.result;
                }
            });
        }

        function makeSettingByBase64(base64) {
            var base = base64.split(';')[0];
            var types = communicationConstants.FILE_TYPES;
            return {
                type: types.find(function (type) {
                    return base.split('/')[1] === type.source;
                }).target, mimeType: base.split(':')[1]
            };
        }

        function dataURItoBlob(dataURI) {
            var byteString;
            if (dataURI.split(',')[0].indexOf('base64') >= 0)
                byteString = atob(dataURI.split(',')[1]);
            else
                byteString = unescape(dataURI.split(',')[1]);
            var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
            var ia = new Uint8Array(byteString.length);
            for (var i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
            }
            var blob = new Blob([ia], {
                type: mimeString
            });

            return $window.URL.createObjectURL(blob);
        }

        function clearInput() {
            var fileInput = document.getElementById('file-upload');
            var fileInputInSheet = document.getElementById('file-upload-sheet');
            if (fileInput) {
                fileInput.value = null;
            } else {
                fileInputInSheet.value = null;
            }

            angular.forEach(
                angular.element("input[type='file']"),
                function(inputElem) {
                    angular.element(inputElem).val(null);
                }
            );
        }

    }

})();
