(function() {
    'use strict';
    angular
        .module('showcaseApp')
        .factory('PaymentSetupFactory', PaymentSetupFactory);

        PaymentSetupFactory.$inject = ['$resource'];

    function PaymentSetupFactory($resource) {
        var resourceUrl = 'admin/api/payment-setup';

        return $resource(resourceUrl, {}, {
            'getByStoreId': {
                method: 'GET',
                url: resourceUrl + '/store/:storeId',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'activateDeactivate': {
                method: 'PUT',
                url: resourceUrl + '/:id/store/:storeId/activate-deactivate',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'disconnect': {
                method: 'DELETE',
                url: resourceUrl + '/:id/store/:storeId/disconnect/:paymentGateway',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            }
        });
    }

})();
