(function () {
    'use strict';
    angular
        .module('showcaseApp')
        .service('FacebookService', FacebookService);

    FacebookService.$inject = ['FacebookFactory'];

    function FacebookService(FacebookFactory) {

        return {
            me: me,
            login: login,
            logout: logout,
            status: status,
            addFeed: addFeed,
            getPage: getPage,
            business: business,
            getBusiness: getBusiness,
            addPayment: addPayment,
            adAccounts: adAccounts,
            searchCity: searchCity,
            businessPages: businessPages,
            createCatalog: createCatalog,
            createAdAccount: createAdAccount,
            instagramAccounts: instagramAccounts
        }

        function me() {
            return FacebookFactory.me();
        }

        function login() {
            return FacebookFactory.login();
        }

        function addPayment(id) {
            return FacebookFactory.addPayment();
        }

        function logout() {
            return FacebookFactory.logout();
        }

        function status() {
            return FacebookFactory.status();
        }

        function addFeed(catalogId, feedUrl) {
            return FacebookFactory.addFeed(catalogId, feedUrl);
        }

        function business(nextPage) {
            return FacebookFactory.business(nextPage);
        }

        function getBusiness(token, id) {
            return FacebookFactory.getBusiness(token, id);
        }

        function getPage(token, id) {
            return FacebookFactory.getPage(token, id);
        }

        function searchCity(query, token) {
            return FacebookFactory.searchCity(query, token);
        }

        function businessPages(businessId) {
            return FacebookFactory.businessPages(businessId);
        }

        function adAccounts(businessId) {
            return FacebookFactory.adAccounts(businessId);
        }

        function createAdAccount(businessId, token) {
            return FacebookFactory.createAdAccount(businessId, token);
        }

        function createCatalog(businessId, catalogData) {
            return FacebookFactory.createCatalog(businessId, catalogData);
        }

        function instagramAccounts(businessId) {
            return FacebookFactory.instagramAccounts(businessId);
        }
    }

})();
