(function() {
    "use strict";

    angular
        .module("showcaseApp")
        .factory("ChangeStateModalFactory", ChangeStateModalFactory);


    /** @ngInject */
    function ChangeStateModalFactory($uibModal) {

        var factory = {
            update: update
        };

        return factory;

        function update(entity) {
            return $uibModal.open({
                templateUrl: 'app/services/global/change-state/change-state.modal.html',
                controller: 'ChangeStateModalController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    entity: function() {
                        return entity;
                    }
                }
            }).result;
        }
    }

})();
