(function () {
    'use strict';
    angular
        .module('showcaseApp')
        .factory('HomeFactory', HomeFactory);

    HomeFactory.$inject = ['$resource'];

    function HomeFactory($resource) {
        var resourceUrl = 'admin/api/home';

        return $resource(resourceUrl, {}, {
            'getReport': {
                method: 'GET',
                url: resourceUrl + '/report/store/:storeId',
                isArray: false,
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'getStoreInfo': {
                method: 'GET',
                url: resourceUrl + '/store-info/store/:storeId',
                isArray: false,
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            }
        });
    }
})();
