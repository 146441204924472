/* globals $ */
(function() {
    'use strict';

    angular
        .module('showcaseApp')
        .directive('chatSkeleton', chatSkeleton)
        .directive('chatMessageSkeleton', chatMessageSkeleton)
        .directive('chatContactsSkeleton', chatContactsSkeleton)
        .directive('chatContactInfoSkeleton', chatContactInfoSkeleton)
        .directive('row', skeletonRow)
        .directive('column', skeletonColumn);

    function chatSkeleton() {
        return {
            restrict: 'E',
            templateUrl: 'app/admin/communication/directives/chat-skeleton/chat-skeleton.html',
            controller: 'ChatSkeletonController',
            controllerAs: 'vm',
            scope: {
                hasIcon: '='
            },
        };
    }

    function chatContactInfoSkeleton() {
        return {
            restrict: 'E',
            templateUrl: 'app/admin/communication/directives/chat-skeleton/chat-contact-info-skeleton.html',
        };
    }

    function chatContactsSkeleton() {
        return {
            restrict: 'E',
            templateUrl: 'app/admin/communication/directives/chat-skeleton/chat-contacts-skeleton.html',
            controller: 'ChatContactsSkeletonController',
            controllerAs: 'vm',
            scope: {
                cardQtt: '='
            },
        };
    }

    function chatMessageSkeleton() {
        return {
            restrict: 'E',
            templateUrl: 'app/admin/communication/directives/chat-skeleton/chat-message-skeleton.html',
            controller: 'ChatMessageSkeletonController',
            controllerAs: 'vm',
            scope: {
                hasIcon: '=',
                side: '='
            },
        };
    }

    function skeletonRow() {
        return {
            restrict: 'E',
            replace: true,
            scope: {
                columns: '='
            },
            template:
                '<div class="row">' +
                '<column ng-repeat="column in columns" classes="column.classes">' +
                '</column>' +
                '</div>'
        }
    }

    /**
     * <column classes="<Skeleton CSS grid classes>"
     *
     *    Examples:
     *
     *      <column classes="one column">[content]</column>
     *      <column classes="nine columns">[content]</column>
     *      <column classes="one-third column">[content]</column>
     *
     *  Content within the directive will be transcluded within
     *  the generated <div> element. HTML cam be passed as content
     *  and will be rendered in the DOM accordingly via the <dynamic>
     *  directive
     *
     */
    function skeletonColumn() {
        return {
            restrict: 'E',
            replace: true,
            transclude: true,
            scope: {
                classes: '=',
            },
            template: '<div class="{{classes}}"></div>'
        }
    }

})();
