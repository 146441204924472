/* globals $ */
(function() {
    'use strict';

    angular
        .module('showcaseApp')
        .directive('messageContact', messageContact);

    function messageContact() {
        return {
            restrict: 'E',
            templateUrl: 'app/admin/communication/directives/message-contact/message-contact.html'
        };
    }
})();
