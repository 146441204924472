(function() {
    'use strict';
    angular
        .module('showcaseApp')
        .service('RecommendationService', RecommendationService);

    RecommendationService.$inject = ['RecommendationFactory', '$localStorage'];

    function RecommendationService(RecommendationFactory, $localStorage) {

        var service = {
            trendRec: trendRec,
            boughtTogetherRec: boughtTogetherRec,
            clusterRec: clusterRec,
            svdRec: svdRec,
            addToRecommendationHistory: addToRecommendationHistory
        };

        return service;

        function trendRec(setting) {
            return RecommendationFactory.trendRec(setting).$promise;
        }

        function boughtTogetherRec(setting) {
            return RecommendationFactory.boughtTogetherRec(setting).$promise;
        }

        function clusterRec(setting) {
            return RecommendationFactory.cluster(setting).$promise;
        }

        function svdRec(setting) {
            return RecommendationFactory.SVD(setting).$promise;
        }

        function addToRecommendationHistory(recommendation) {
            return RecommendationFactory.recommendationHistory(recommendation).$promise;
        }
    }

})();
