(function() {
    'use strict';
    angular
        .module('showcaseApp')
        .factory('RecommendationFactory', RecommendationFactory);

        RecommendationFactory.$inject = ['$resource', '$localStorage', '$window'];

    function RecommendationFactory($resource, $localStorage, $window) {
        var PORT = 5000;
        var resourceRecommendationAPI =  $window.location.protocol + '//admin.' + getDomainNameSufix($window) + ':' + PORT;
        var resourceRecommendationTrend =  resourceRecommendationAPI + '/api/v1.0/algorithm/trend';
        var resourceRecommendationBt =  resourceRecommendationAPI + '/api/v1.0/algorithm/bt';
        var resourceRecommendationCluster =  resourceRecommendationAPI + '/api/v1.0/algorithm/cluster';
        var resourceRecommendationSVD =  resourceRecommendationAPI + '/api/v1.0/algorithm/funk_svd';
        var resourceRecommendationHistory = 'flyer/api/recommendation-history';

        return $resource(resourceRecommendationAPI, {}, {
            'cluster': {
                method: 'POST',
                url: resourceRecommendationCluster,
                isArray: true
             },
             'SVD': {
                method: 'POST',
                url: resourceRecommendationSVD,
                isArray: true
             },
            'trendRec': {
                method: 'POST',
                url: resourceRecommendationTrend,
                isArray: true
            },
            'boughtTogetherRec': {
                method: 'POST',
                url: resourceRecommendationBt,
                isArray: true
            },
            'recommendationHistory': {
                method: 'POST',
                url: resourceRecommendationHistory
            },
        });
    }

})();
