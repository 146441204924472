(function() {
    'use strict';
    angular
        .module('showcaseApp')
        .service('WatiService', WatiService);

    WatiService.$inject = ['WatiFactory', '$localStorage', 'WatiConstants', '$http'];

    function WatiService(WatiFactory, $localStorage, WatiConstants, $http) {

        var service = {
            create: create,
            findConfigByCurrentStore: findConfigByCurrentStore,
            getConfigByCurrentStore: getConfigByCurrentStore,
            deleteConfigByStoreId: deleteConfigByStoreId,
            update: update,
            sendMessage: sendMessage,
            findMessagesByNumber: findMessagesByNumber,
            findConversations: findConversations,
            findFileByName: findFileByName,
            findAudioFileByName: findAudioFileByName,
            findStickers: findStickers,
            makeUriToShowImageByWati: makeUriToShowImageByWati,
            makeUriToShowVideoByWati: makeUriToShowVideoByWati,
            replaceWatiToken: replaceWatiToken,
            searchTemplateMessages: searchTemplateMessages,
            searchTemplateMessagesByText: searchTemplateMessagesByText,
            getTemplateMessages: getTemplateMessages,
            sendTemplateMessage: sendTemplateMessage,
            findContact: findContact,
            sendMultipleMessages: sendMultipleMessages,
            uploadByChunk: uploadByChunk,
            getWATICredits: getWATICredits
        };

        return service;

        function create(model) {
            return WatiFactory.create(model).$promise;
        }

        function getConfigByCurrentStore() {
            return WatiFactory.findConfigByCurrentStore().$promise;
        }

        function findConfigByCurrentStore(onSuccess, onError) {
            return WatiFactory.findConfigByCurrentStore(onSuccess, onError);
        }

        function deleteConfigByStoreId(params) {
            return WatiFactory.deleteConfigByStoreId({id: params}).$promise;
        }

        function update(model) {
            return WatiFactory.update(model).$promise;
        }

        function sendMessage(message) {
            return WatiFactory.sendMessage(message).$promise;
        }

        function sendMultipleMessages(messagesList) {
            return WatiFactory.sendMultipleMessages(messagesList).$promise;
        }

        function findMessagesByNumber(params) {
            return WatiFactory.findMessagesByNumber(params).$promise;
        }

        function findConversations(filter) {
            return WatiFactory.findConversations(filter).$promise;
        }

        function findFileByName(params) {
            return WatiFactory.findFileByName(params).$promise;
        }

        function findAudioFileByName(params) {
            return WatiFactory.findAudioFileByName(params).$promise;
        }

        function findStickers(params) {
            return WatiFactory.findStickers(params).$promise;
        }

        function makeUriToShowImageByWati(watiUri, fileName, watiToken) {
            if (fileName.includes('/data')) fileName = fileName.replace('/data', 'data');
            return WatiConstants.TEMPLATE_URI_TO_SHOW_MEDIA_BY_WATI.replace('{watiUri}', watiUri).replace('{fileName}', fileName).replace('{watiToken}', replaceWatiToken(watiToken));
        }

        function makeUriToShowVideoByWati(watiUri, fileName, watiToken) {
            return WatiConstants.TEMPLATE_URI_TO_SHOW_MEDIA_BY_WATI
                .replace('{watiUri}', watiUri)
                .replace('{fileName}', fileName)
                .replace('{watiToken}', replaceWatiToken(watiToken));
        }

        function replaceWatiToken(token) {
            return token.substr(7);
        }

        function searchTemplateMessages(pageSize, pageNumber) {
            return WatiFactory.searchTemplateMessages({pageSize: pageSize, pageNumber: pageNumber}).$promise;
        }

        function getTemplateMessages() {
            return WatiFactory.getTemplateMessages().$promise;
        }

        function sendTemplateMessage(template_name, params, whatsappNumber) {
            return WatiFactory.sendTemplateMessage({template_name: template_name, parameters: params, broadcast_name: 'sk_brod_' + template_name, number: whatsappNumber}).$promise;
        }

        function findContact(id) {
            return WatiFactory.findContact({id: id}).$promise;
        }

        function uploadByChunk(data) {
            return WatiFactory.uploadByChunk(data).$promise;
        }

        function searchTemplateMessagesByText(text) {
            return WatiFactory.searchTemplateMessagesByText({ searchString: text }).$promise;
        }

        function getWATICredits() {
            return WatiFactory.getWATICredits().$promise;
        }
    }

})();
