(function() {
    'use strict';
    angular
        .module('showcaseApp')
        .factory('MessengerFactory', MessengerFactory);

        MessengerFactory.$inject = ['$resource'];

    function MessengerFactory($resource) {
        var resourceFacebook =  '/admin/api/facebook';
        var resourceSendMessage =  resourceFacebook + '/send-message';
        var resourceMakeCredentials =  resourceFacebook + '/:id';
        var resourceGetProfile =  resourceFacebook + '/profile';
        var resourceFindConversations =  resourceFacebook + '/conversations';
        var resourceFindMessages =  resourceFacebook + '/messages';
        var resourceFindAttachment =  resourceFacebook + '/attachment';

        return $resource(resourceFacebook, {}, {
            'sendMessage': {
                method: 'POST',
                url: resourceSendMessage
            },
            'makeMessengerCredentials': {
                method: 'POST',
                url: resourceMakeCredentials
            },
            'getFbProfileById': {
                method: 'GET',
                url: resourceGetProfile
            },
            'findAttachment': {
                method: 'GET',
                url: resourceFindAttachment
            },
            'findConversations': {
                method: 'GET',
                url: resourceFindConversations
            },
            'findMessages': {
                method: 'GET',
                url: resourceFindMessages
            }
        });
    }

})();
