(function() {
    'use strict';

    angular
        .module('showcaseApp')
        .controller('BlockPlanModalController', BlockPlanModalController);

        BlockPlanModalController.$inject = ['$uibModalInstance', '$state'];

    function BlockPlanModalController($uibModalInstance, $state) {
        var vm = this;

        vm.goToPlan = function() {
            $state.go('checkoutInner');
            $uibModalInstance.close(true);
        }

        vm.closeModal = function() {
            $state.go('home');
            $uibModalInstance.close(false);
        }

    }

})();
