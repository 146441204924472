(function () {
    'use strict';
    angular
        .module('showcaseApp')
        .factory('GiftCardFactory', GiftCardFactory);

    GiftCardFactory.$inject = ['$resource'];

    function GiftCardFactory($resource) {
        var resourceUrl = '';
        return $resource(resourceUrl, {}, {
            'cardsAvailable': {
                method: 'GET',
                url: 'admin/api/gift-card/available/:hubId',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'sendGiftCard': {
                method: 'POST',
                url: 'admin/api/gift-card/send',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            }//,
//            'validateGiftCard': {
//                method: 'GET',
//                url: 'admin/api/gift-card/validate/:token',
//                transformResponse: function (data, headers) {
//                    var response = {};
//                    if (data) {
//                        response.data = angular.fromJson(data);
//                    }
//                    response.headers = headers();
//                    return response;
//                }
//            }
        });
    }

})();
