(function() {
    'use strict';
    angular
        .module('showcaseApp')
        .factory('CategoryFactory', CategoryFactory);

    CategoryFactory.$inject = ['$resource'];

    function CategoryFactory ($resource) {
        var resourceUrl =  'admin/api/category/:key';

        return $resource(resourceUrl, {}, {
            'query': {
                method: 'GET',
                isArray: false,
                transformResponse: function (data, headers) {
                    var response = {};
                    response.data = angular.fromJson(data);
                    response.headers = headers();
                    return response;
                }
            },
            'getJSON': {
                method: 'GET',
                transformResponse: function (data, headers) {
                    var response = {};
                    response.data = angular.fromJson(data);
                    response.headers = headers();
                    return response;
                }
            },
            'listAll': {
                url: 'admin/api/category/list',
                method: 'POST',
                transformResponse: function (data, headers) {
                    var response = {};
                    response.data = angular.fromJson(data);
                    response.headers = headers();
                    return response;
                }
            },
            'save': {
              url: 'admin/api/category',
              method: 'POST'
            },
            'getData': {
                method: 'GET',
                transformResponse: function (data, headers) {
                    var response = {};
                    response.data = data;
                    response.headers = headers();
                    return response;
                }
            },
            'update': { method:'PUT' },
            'delete': { method:'DELETE' }
        });
    }
})();
