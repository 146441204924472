(function() {
    "use strict";

    angular
        .module("showcaseApp")
        .factory("FBCampaignModalFactory", FBCampaignModalFactory);


    /** @ngInject */
    function FBCampaignModalFactory($uibModal) {

        var factory = {
            update: update
        };

        return factory;

        function update(data) {
            return $uibModal.open({
                templateUrl: 'app/integrations/facebook-business/campaign-modal/fb-campaign.modal.html',
                controller: 'FBCampaignModalController',
                controllerAs: 'vm',
                size: 'lg',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    data: function() {
                        return data;
                    }
                }
            }).result;
        }
    }

})();
