(function () {
    'use strict';

    angular
        .module('showcaseApp')
        .controller('HubCampaignController', HubCampaignController);


    /** @ngInject */
    function HubCampaignController(HubCampaignService, toastr, $translate, $localStorage) {

        var vm = this;

        vm.hubId = $localStorage.hub.id;
        vm.loading = false;
        vm.campaigns = [];

        vm.filter = {
            hubId: vm.hubId,
            name: ''
        }

        vm.listCampaigns = function () {
            vm.loading = true;
            HubCampaignService.listAll(vm.hubId, vm.filter)
                .then(function (result) {
                    vm.campaigns = result.data;
                }, function () {
                    toastr.error($translate.instant('campaign.message.error-to-load-campaign'));
                }).finally(function (){ vm.loading = false});
        }

        vm.listCampaigns();

    }

})();
