(function() {
    'use strict';

    angular
        .module('showcaseApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('bag', {
                parent: 'admin',
                url: '/bags',
                data: {
                    authorities: ['STORE_READ_SHOWCASE'],
                    pageTitle: 'global.menu.admin.bags'
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/admin/bag/bag.html',
                        controller: 'BagController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    }
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort)
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('showcases');
                        $translatePartialLoader.addPart('flyers');
                        $translatePartialLoader.addPart('store-management');
                        $translatePartialLoader.addPart('bag');
                        return $translate.refresh();
                    }]

                }
            }).state('bag.new', {
                parent: 'bag',
                url: '/new',
                data: {
                    authorities: ['STORE_UPDATE_SHOWCASE'],
                    pageTitle: 'bag.title.build',
                    specialClass: "md-skin fixed-nav"
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/admin/bag/bag-detail.html',
                        controller: 'BagDetailController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    }
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort)
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('showcases');
                        $translatePartialLoader.addPart('flyers');
                        return $translate.refresh();
                    }]

                }
            })
            .state('bag.edit', {
                parent: 'bag',
                url: '/{showcaseId}',
                data: {
                    authorities: ['STORE_UPDATE_SHOWCASE'],
                    pageTitle: 'bag.title.details',
                    specialClass: "md-skin fixed-nav"
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/admin/bag/bag-detail.html',
                        controller: 'BagDetailController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    }
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort)
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('showcases');
                        $translatePartialLoader.addPart('flyers');
                        return $translate.refresh();
                    }]

                }
            })
    }
})();
