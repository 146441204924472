(function() {
    'use strict';
    angular
        .module('showcaseApp')
        .service('AuthorityService', AuthorityService);

    AuthorityService.$inject = ['AuthorityFactory'];

    function AuthorityService(AuthorityFactory) {
        var service = {
                getAuthorities: getAuthorities,
                getAllWithHierarchy: getAllWithHierarchy
        };

        return service;

        function getAuthorities(storeId) {
            return AuthorityFactory.query({storeId: storeId}).$promise;
        }

        function getAllWithHierarchy(storeId) {
            return AuthorityFactory.getAllWithHierarchy({storeId: storeId}).$promise;
        }


    }
})();
