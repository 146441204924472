(function() {
    'use strict';
    angular
        .module('showcaseApp')
        .service('CheckCEPService', CheckCEPService);

    CheckCEPService.$inject = ['CheckCEPFactory', 'CheckCEPStateFactory', 'CheckCEPCountryFactory'];

    function CheckCEPService(CheckCEPFactory, CheckCEPStateFactory, CheckCEPCountryFactory, $localStorage) {
        var service = {
                getAddress: getAddress,
                listCountrys: listCountrys,
                listStates: listStates
        };

        return service;

        function getAddress(cepAddress) {
            return CheckCEPFactory.get({cep: cepAddress}).$promise;
        }

        function listCountrys() {
            return CheckCEPCountryFactory.get().$promise;
        }

        function listStates() {
            return CheckCEPStateFactory.get().$promise;
        }
    }
})();
