(function() {
    'use strict';

    angular
        .module('showcaseApp')
        .controller('HubFlyersController', HubFlyersController);

    function HubFlyersController(FlyersCurrencyValue, Principal, $translate, $localStorage, $state, HubFlyersService,
        toastr, RemoveModalFactory, paginationConstants, StoreService, $stateParams, HubService, BrandService, StoreTypeService,
        ConfirmPublishModalFactory) {

        var vm = this;

        vm.currentPage = 1;
        vm.numPerPage = 70;
        vm.maxSize = 5;
        vm.totalFlyers = 0;
        vm.startRegistry = 1;
        vm.endRegistry = vm.numPerPage;

        vm.paginationOptions = {
            page: vm.currentPage - 1,
            size: vm.numPerPage
        };

        vm.requestParamsStore = {
            page: 0,
            size: 25
        }
        vm.isStoresAvailable = true;
        vm.quantStoresSelected = 0;

        vm.colors = getAllColors();
        vm.flyerName = '';
        vm.flyersFiltered = [];
        vm.flyersTypes = [];
        vm.displayType = "Table";
        vm.zeroOffers = false;
        vm.displayTypeList = ["Cards", "Table"];
        vm.startDate = {
            startDate: null,
            endDate: null
        };
        vm.tabInfo = {
            existsCouponsFlyer: false,
            existsRentFlyer: false,
            existsContactFlyer: false,
            existsSaleFlyer: false
        };
        vm.showCouponsWithActive = false;
        vm.isLoading = true;
        vm.listFlyers = true;
        vm.loadingArray = ["1", "2", "3", "4"];
        vm.selectedFlyers = [];
        vm.listFilter = {
            hub: $localStorage.defaultStore.hubId
        };
        vm.selectedAllHub = false;
        vm.brandGroups = {};
        vm.filters = {
            active: false,
            inactive: false
        };
        vm.userIsAdmin = false;

        vm.flyersToRemove = [];

        vm.mySalePoints = [];

        vm.page = 0;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.hasNextPage = false;
        vm.busy = false;
        vm.loadingTab = true;
        vm.isAvailable = false;

        vm.hubId = $localStorage.defaultStore.hubId;
        vm.time_stamp_to_image = '';

        checkUserIsAdmin();
        getFlyersTypes();
        vm.storeTypes = [];
        StoreTypeService.getAllStoreTypesByOwner().then(function(response) {
            vm.storeTypes = response.data;
        });

        vm.stores = [];
        vm.storesSelected = [];
        vm.storeName = '';

        vm.changePage = function() {

            var begin = ((vm.currentPage - 1) * vm.numPerPage),
                end = begin + vm.numPerPage;

            vm.paginationOptions.page = vm.currentPage - 1;

            // vm.customers = listCustomers(vm.paginationOptions, vm.filter, true);
            loadFlyers();
            vm.startRegistry = begin + 1;
            vm.endRegistry = end;

        };

        vm.currencyConfiguration = getCurrencyListConfiguration();

        vm.tabClick = function(brand) {
            vm.loadingTab = true;

            // Changes the type to the new type Clicked
            vm.listFilter.brand = brand.name;

            // Erases the offer list
            vm.flyersFiltered = [];

            // Turn to 0 the page to give right page on pageable
            vm.page = 0;

            // loadOffers
            loadFlyers();

        };

        function checkUserIsAdmin() {
            Principal.hasAuthority("ADMIN_MANAGE_SYSTEM").then(function(result) {
                if (result) {
                    vm.userIsAdmin = true;
                } else {
                    vm.userIsAdmin = false;
                }
            });
        }

        vm.stopPropagation = function(event) {
            event.stopPropagation();
        };

        vm.changeStatusFilterToActive = function() {
            vm.loadingTab = true;
            vm.page = 0;
            if (vm.filters.active) {
                vm.filters.inactive = false;
                vm.listFilter.status = "ACTIVATED";
            }
            checkIsListAll();
            loadFlyers();
        };

        function getMySalePoints() {
            if (vm.listFilter.storeId) {
                StoreService.getSalesPoints(vm.listFilter.storeId).then(function(response) {
                    vm.mySalePoints = response.data;
                });
            }
        }

        vm.changeStatusFilterToInactive = function() {
            vm.loadingTab = true;
            vm.page = 0;
            if (vm.filters.inactive) {
                vm.filters.active = false;
                vm.listFilter.status = "DEACTIVATED";
            }
            checkIsListAll();
            loadFlyers();
        };

        function checkIsListAll() {
            vm.loadingTab = true;
            vm.page = 0;
            vm.flyersFiltered = [];
            if (!vm.filters.inactive && !vm.filters.active) {
                vm.listFilter.status = null;
                loadFlyers();
            }
        }


        vm.checkType = function(type) {
            vm.createArray("displayType", vm.displayTypeList.length, false);
            vm.displayTypeListCheck[vm.displayTypeList.indexOf(type)] = true;
            vm.displayType = type;
        };

        vm.isEven = function(number) {
            return (number % 2 === 0);
        };

        vm.refreshSelectedFlyers = function (flyer) {
            vm.brandGroups[flyer.brand] = vm.brandGroups[flyer.brand] ? { size: vm.brandGroups[flyer.brand].size } : { size: 0 };
            if (vm.selectedFlyers.indexOf(flyer.id) != '-1') {
                vm.brandGroups[flyer.brand].size = vm.brandGroups[flyer.brand].size - 1;
                vm.selectedFlyers.splice(vm.selectedFlyers.indexOf(flyer.id), 1);
            } else {
                vm.brandGroups[flyer.brand].size = vm.brandGroups[flyer.brand].size + 1;
                vm.selectedFlyers.push(flyer.id);
            }
        };

        vm.getCountSelectedByBrand = function (brand) {
            if (vm.selectedFlyers && vm.selectedFlyers.length && !vm.selectedAllHub) {
                return vm.brandGroups[brand] ? vm.brandGroups[brand].size : vm.brandGroups[brand];
            }

            return 0;
        };

        vm.importFromVtex = function() {
            HubFlyersService.importFlyersFromVtex(vm.hubId).then(function (data) {
                toastr.success($translate.instant('flyers.import.vtex-success-msg'));
            });
        };

        vm.existsSelectedItems = function() {
            return vm.selectedFlyers.length >= 1;
        };

        vm.cleanSelection = function () {
            vm.selectedFlyers = [];
            vm.selectedAllHub = false;
            vm.brandGroups = {};
        }

        vm.selectAllPage = function () {
            if (vm.selectedAllHub) {
                vm.selectedFlyers = [];
                vm.selectedAllHub = false;
            }
            if (vm.brandGroups[vm.listFilter.brand] && vm.brandGroups[vm.listFilter.brand].selectedAllBrand) {
                HubFlyersService.listAllFlyersByBrand(vm.listFilter.brand).then(function (data) {
                    angular.forEach(data, function (flyer) {
                        vm.selectedFlyers.pop(flyer.id);
                    });
                    selectItemOfPage();
                });

            } else {
                selectItemOfPage();
            }
        };

        function selectItemOfPage() {
            vm.brandGroups[vm.listFilter.brand] = { size: vm.flyersFiltered.length, selectedAllBrand: false, selectedAllPage: true }
            angular.forEach(vm.flyersFiltered, function (flyer) {
                if (vm.selectedFlyers.indexOf(flyer.id) === -1) {
                    vm.selectedFlyers.push(flyer.id);
                }
            });
        }

        vm.selectAllItemsAllHub = function () {
            vm.loadingTab = true;
            vm.selectedFlyers = [];
            vm.brandGroups = {};
            HubFlyersService.listAllFlyersByHub().then(function (data) {
                vm.selectedAllHub = true;
                angular.forEach(data, function (flyer) {
                    vm.selectedFlyers.push(flyer.id);
                });
                vm.loadingTab = false;
            });
        }

        vm.selectAllItemsAllBrand = function () {
            HubFlyersService.listAllFlyersByBrand(vm.listFilter.brand).then(function (data) {
                if (vm.selectedAllHub) {
                    vm.selectedFlyers = [];
                    vm.selectedAllHub = false;
                }
                vm.brandGroups[vm.listFilter.brand] = { size: data.length, selectedAllBrand: true, selectedAllPage: false }
                angular.forEach(data, function (flyer) {
                    if (vm.selectedFlyers.indexOf(flyer.id) === -1) {
                        vm.selectedFlyers.push(flyer.id);
                    }
                });
            });
        }

        vm.changeDisplayType = function() {
            vm.displayType = vm.displayType === 'Table' ? 'Cards' : 'Table';
        };

        vm.checkDisplayType = function(type) {
            return (vm.displayType === type);
        };

        vm.changeActivated = function() {
            vm.loadingTab = true;
            vm.page = 0;
            if (vm.listFilter.activated === null) {
                vm.listFilter.activated = true;
            } else {
                vm.listFilter.activated = !vm.listFilter.activated;
            }
            loadFlyers();
        };


        vm.openCalendarStart = function(e) {
            e.preventDefault();
            e.stopPropagation();
            vm.isOpenCalendarStart = true;
        };

        vm.openCalendarExpire = function(e) {
            e.preventDefault();
            e.stopPropagation();
            vm.isOpenCalendarExpire = true;
        };

        vm.flyerDetailPage = function(flyerId) {
            $state.go('hub-flyers.edit', {
                id: flyerId
            });
        };

        vm.migrateFlyers = function() {
            vm.isAvailable = true;

            if (vm.selectedFlyers.length >= 1) {
                var cleanOptions = function() {
                    vm.isAvailable = false;
                    // vm.selectedFlyers = [];
                    // vm.brandGroups = {};
                    vm.storesSelected = [];
                }
                var stores = vm.stores.filter(function(store) {
                    return vm.storesSelected.includes(store.storeId);
                });

                if (vm.storeTypeId && vm.storeTypeId > 0) {
                    HubService.migrateFlyersToStoresByStoreType(vm.selectedFlyers, vm.storeTypeId).then(function() {
                        toastr.success($translate.instant('flyers.messages.global.distribution-started'));
                        cleanOptions();
                    });
                } else if (stores.length > 0) {
                    HubService.migrateFlyersToStores(vm.selectedFlyers, stores).then(function(data) {
                        toastr.success($translate.instant('flyers.messages.global.distribution-started'));
                        cleanOptions();
                    });
                } else {
                    ConfirmPublishModalFactory.publishItem().then(function(data) {
                        if(data) {
                            HubService.migrateFlyersToStores(vm.selectedFlyers, stores).then(function(data) {
                                toastr.success($translate.instant('flyers.messages.global.distribution-started'));
                                cleanOptions();
                            });
                        }
                    }, function(err) {
                        vm.isAvailable = false;
                    });
                }
            }
        };

        vm.removeFlyers = function() {
            if (vm.selectedFlyers.length >= 1) {
                RemoveModalFactory.deleteItem(vm.selectedFlyers.length).then(function(data) {
                    HubFlyersService.deleteFlyers(vm.selectedFlyers).then(function(data) {
                        toastr.success($translate.instant('flyers.messages.remove.success'));
                        vm.selectedFlyers = [];
                        $state.reload();
                    }, function(data) {
                        toastr.error($translate.instant('flyers.messages.remove.error'));
                    });
                });
            } else {
                toastr.error($translate.instant('flyers.messages.remove.noItens'));
            }
        };

        vm.getFlyerCurrency = function(currency) {
            return FlyersCurrencyValue[currency];
        };

        vm.checkStoreIsSelected = function(id) {
            return vm.storesSelected.includes(id);
        }

        vm.changeStoreSelected = function(store) {
            if (vm.checkStoreIsSelected(store.storeId)) {
                vm.storesSelected = vm.storesSelected.filter(function(id) {
                    return store.storeId !== id;
                });
            } else {
                vm.storesSelected.push(store.storeId);
            }
        }

        vm.markAllStoreType = function() {
            if (!vm.storeTypeId || vm.storeTypeId == '') {
                vm.storesSelected = [];
                vm.quantStoresSelected = 0;
                return;
            }
            StoreService.countStoresByCurrentHubAndStoreType(vm.storeTypeId).then(function(response) {
                vm.quantStoresSelected = response.count;
            });

            var storeType = vm.storeTypes.find(function(storeType) {
                return vm.storeTypeId == storeType.id;
            });

            vm.storesSelected = [];
            vm.stores.forEach(function(store) {
                if (store.storeType === storeType.name) vm.changeStoreSelected(store);
            });
        }

        vm.hasStoreSelected = function() {
            return vm.quantStoresSelected > 0;
        }

        vm.fetchMoreStores = function() {
            vm.requestParamsStore.page = vm.requestParamsStore.page + 1;
            loadStores(vm.requestParamsStore);
        }

        function loadStores(pageOptions) {
            vm.isStoresAvailable = false;
            StoreService.listStoresByCurrentHub({}, pageOptions, function(data, headers) {
                vm.stores = data;
                vm.markAllStoreType();
                vm.isStoresAvailable = true;
            }, function(error) {
                vm.isStoresAvailable = true;
            });
        }

        vm.onChangeStoreName = function() {
            vm.requestParamsStore.name = vm.storeName;
            loadStores(vm.requestParamsStore);
        }

        function loadAll() {
            vm.time_stamp_to_image = '?' + Date.now();
            vm.requestParamsStore.name = vm.storeName;
            loadStores(vm.requestParamsStore);

            BrandService.getBrandsByCurrentStore().then(function(response) {
                vm.brands = response.data;

                if ($stateParams.brand) {
                    vm.listFilter.brand = $stateParams.brand;
                }
                else {
                    vm.listFilter.brand = response.data[0].name;
                }
                loadFlyers();
            });
            if ($stateParams.type) {
                vm.loadingTab = true;

                // Changes the type to the new type Clicked
                // vm.listFilter.type = $stateParams.type;

                // Erases the offer list
                vm.flyersFiltered = [];

                // Turn to 0 the page to give right page on pageable
                vm.page = 0;
            }
            getMySalePoints();
        }

        function getFlyersTypes() {
            HubFlyersService.getFlyerTypes().then(function(response) {
                var removeRent = response.data.map(function(item) {
                    return item.code;
                }).indexOf('RENT');
                response.data.splice(removeRent, 1);
                var removeContact = response.data.map(function(item) {
                    return item.code;
                }).indexOf('CONTACT');
                response.data.splice(removeContact, 1);
                vm.flyersTypes = response.data;
            });
        }

        vm.loadFlyers = function(page, reset) {
            if (reset) {
                vm.reset();
            } else vm.page = page;

            getFlyersTypes();
            loadFlyers();
        };

        vm.reset = function reset() {
            vm.page = 0;
            vm.flyersFiltered = [];
        };

        vm.checkIfFlyerSelected = function(flyerId) {
            return vm.selectedFlyers.indexOf(flyerId) != '-1';
        };

        vm.getFlyerTooltip = function(status) {
            switch (status) {
                case 'ACTIVATED':
                    return 'flyers.offer.active';
                case 'DEACTIVATED':
                    return 'flyers.offer.disabled';
                case 'EXPIRED':
                    return 'flyers.offer.expired';
                case 'CLOSED':
                    return 'flyers.offer.closed';
            }
        };

        vm.onChangeFlyerName = function() {
            loadFlyers();
        };

        function loadFlyers() {
            if (vm.busy) return;
            vm.busy = true;

            // if (!vm.listFilter.type){
            //     vm.listFilter.type = 'COUPON';
            // }

            var pageable = {
                page: vm.page,
                size: 50
                // size: 1000 || vm.itemsPerPage
            };

            vm.listFilter.hub = $localStorage.defaultStore.hubId;

            HubFlyersService.listFlyers(vm.paginationOptions, vm.listFilter, onSuccess, onError);

            function onSuccess(data, headers) {
                vm.zeroOffers = data.length <= 0;
                if (vm.zeroOffers) {
                    vm.flyersFiltered = [];
                } else {
                    vm.flyersFiltered = data;
                }
                vm.totalFlyers = headers('X-Total-Count');

                vm.isLoading = false;
                vm.busy = false;
                vm.loadingTab = false;
            }

            function onError(error) {
                vm.busy = false;
                vm.loadingTab = false;
                vm.isLoading = false;
                toastr.error($translate.instant('flyers.messages.load.error'));
            }
        }

        loadAll();
    }

})();
