(function() {
    'use strict';

    angular
        .module('showcaseApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('checkout-result', {
            url: '/checkout-result',
            data: {
                pageTitle: 'store-info.pageTitle'
            },
            templateUrl: 'app/account/checkout/checked.html',
            controller: 'CheckedController',
            controllerAs: 'vm',
            params: {
                'success': null,
                'billetLink' : null
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('checkout');
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        });
    }
})();
