(function() {
    "use strict";

    angular
        .module("showcaseApp")
        .factory("ConfirmActionModalFactory", ConfirmActionModalFactory);


    /** @ngInject */
    function ConfirmActionModalFactory($uibModal) {

        var factory = {
            confirmAction: confirmAction
        };

        return factory;

        function confirmAction() {
            return $uibModal.open({
                templateUrl: 'app/services/global/confirm-action/confirm-action.modal.html',
                controller: 'ConfirmActionModalController',
                controllerAs: 'vm',
                size: 'md'
            }).result;
        }
    }
})();
