(function () {
    'user strict'

    angular
        .module('showcaseApp')
        .controller('UserEngagementsController', UserEngagementsController);

    //** NgInject */
    function UserEngagementsController($stateParams, UserEngagementService, $translate, FlyerCategoryService) {

        var vm = this;
        var from = $stateParams.from;

        vm.loadingUserInfo = false;
        vm.loadingUserCategoryStatistics = false;
        vm.loadingUserOfferEngagements = false;

        vm.userId = $stateParams.id;
        
        vm.currentPage = 1;
        vm.numPerPage = 5;
        vm.maxSize = 5;
        vm.totalElements = 0;

        vm.paginationOptions = {
            page: vm.currentPage - 1,
            size: vm.numPerPage,
            sort: null
        };

        init();

        function init() {
            loadUserDetails();
            loadUserCategoryStatistics();
            loadUserOfferEngagements();
        }

        function loadUserDetails() {
            vm.loadingUserInfo = true;
            UserEngagementService.getUserInfo(vm.userId).then(function (result) {
                vm.user = result;
                vm.loadingUserInfo = false;
            }, function (err) {
                vm.loadingUserInfo = false;
            });
        }

        vm.changePage = function () {
            
            vm.paginationOptions.page = vm.currentPage - 1;

            loadUserOfferEngagements();
        }

        function getCategoryName(flyer) {
            FlyerCategoryService.getCategoryTitleByKey(flyer.categoryKey, $translate.use()).then(function(response) {
                flyer.category = response.data;
            });
        }

        function loadUserCategoryStatistics() {
            vm.loadingUserCategoryStatistics = true;
            UserEngagementService.getUserCategoryStatistics(vm.userId, from).then(function (result) {
                vm.categoryStatistics = result.mostLikedCategories;
                vm.totalBrand = result.total;
                vm.loadingUserCategoryStatistics = false;
            }, function (err) {
                vm.loadingUserCategoryStatistics = false;
            });
        }

        function loadUserOfferEngagements() {
            vm.loadingUserOfferEngagements = true;
            UserEngagementService.getUserOfferEngagements(vm.userId, from, vm.paginationOptions).then(function (result) {
                vm.offers = result.content;
                angular.forEach(vm.offers, function(offer) {
                    getCategoryName(offer.flyer);
                });

                vm.totalElements = result.totalElements;
                vm.startElement = (result.number * result.size) + 1;
                vm.ofQuantityElements = (result.number * result.size) + result.numberOfElements;

                vm.loadingUserOfferEngagements = false;
            }, function (err) {
                vm.loadingUserOfferEngagements = false;
            });
        }
    }
})();