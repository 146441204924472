(function() {
    'use strict';
    angular
        .module('showcaseApp')
        .service('CategoryService', CategoryService);

    CategoryService.$inject = ['CategoryFactory', '$localStorage'];

    function CategoryService(CategoryFactory, $localStorage) {
        var service = {
                createCategory: createCategory,
                updateCategory: updateCategory,
                deleteCategory: deleteCategory,
                listAllCategories: listAllCategories,
                listCategories: listCategories,
                getCategoryTitleByKey: getCategoryTitleByKey
        };

        return service;

        function getCategoryTitleByKey(categoryKey, categoryLang) {
            return CategoryFactory.getData({key: categoryKey, langKey: categoryLang}).$promise;
        }

        function createCategory(category) {
            return CategoryFactory.save(category).$promise;
        }

        function updateCategory(category) {
            return CategoryFactory.update(category).$promise;
        }

        function deleteCategory(categoryId) {
            return CategoryFactory.delete({id: categoryId}).$promise;
        }

        function listCategories(userLang) {
            return CategoryFactory.getJSON({langKey: userLang}).$promise;
        }

        function listAllCategories(pageable, params) {
            return CategoryFactory.listAll(pageable, params).$promise;
        }
    }
})();
