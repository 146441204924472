(function () {
    'use strict';
    angular
        .module('showcaseApp')
        .factory('ColorFactoryAdmin', ColorFactoryAdmin);

    ColorFactoryAdmin.$inject = ['$resource'];

    function ColorFactoryAdmin($resource) {
        var resourceUrl = 'admin/api/color';

        return $resource(resourceUrl, {}, {
            'getColorsByCurrentSTore': {
                method: 'GET',
                isArray: false,
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'findColor': {
                method: 'POST',
                url: 'admin/api/color/search',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'createColor': {
                method: 'POST',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'getColorById': {
                method: 'GET',
                url: 'admin/api/color/:colorId',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'removeColor': {
                method: 'DELETE',
                url: 'admin/api/color/:colorId',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'editColor': {
                method: 'PUT',
                url: 'admin/api/color/:colorId',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            }
        });
    }
})();
