/* globals $ */
(function() {
    'use strict';
    angular
        .module('showcaseApp')
        .component('messageReferral', {
            bindings: {
                value: '<'
            },
            controller: MessageReferralController,
            templateUrl: 'app/admin/communication/directives/message-referral/message-referral.html',
            controllerAs: 'vm'
        });

    MessageReferralController.$inject = ['$scope', '$window'];

    function MessageReferralController($scope, $window) {
        var vm = this;

        vm.openUrl = function () {
            $window.open(vm.value.url, '_blank');
        }

    }
})();
