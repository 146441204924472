(function() {
    'use strict';
    angular
        .module('showcaseApp')
        .factory('BusinessIntegrationFactory', BusinessIntegrationFactory);

    BusinessIntegrationFactory.$inject = ['$resource'];

    function BusinessIntegrationFactory($resource) {
        var resourceUrl =  'flyer/api/flyer/:flyerId';

        return $resource(resourceUrl, {}, {
            'getOwnerKeys': {
                method: 'GET',
                url: '/flyer/api/payment/owner/keys/:storeId',
                transformResponse: function(data, headers) {
                    var response = {};
                    if(data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
              },
            'createCampaign': {
                method: 'POST',
                url: '/flyer/api/facebookBusiness/campaign',
                transformResponse: function(data, headers) {
                    var response = {};
                    if(data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
              },
            'facebookIsConnected': {
                method: 'GET',
                url: '/admin/api/store/facebook',
                transformResponse: function(data, headers) {
                    var response = {};
                    if(data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
              },
              'receiveCode': {
                method: 'POST',
                url: '/admin/api/business-integration/google-my-business/receive-code/:storeId',
                isArray: false,
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'selectAccount': {
                method: 'POST',
                url: '/admin/api/business-integration/google-my-business/select-account/:storeId',
                isArray: false,
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'selectGMBLocation': {
                method: 'POST',
                url: '/admin/api/business-integration/google-my-business/select-location/:storeId',
                isArray: false,
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'getGMBCategoriesList': {
                method: 'POST',
                url: '/admin/api/business-integration/google-my-business/get-categories',
                isArray: false,
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'getGMBLocationList': {
                method: 'GET',
                url: '/admin/api/business-integration/google-my-business/get-location/:storeId',
                isArray: false,
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'getConfiguration': {
                method: 'GET',
                url: '/admin/api/business-integration/get-configuration/:storeId/:type',
                isArray: false,
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'disconnectByType': {
                method: 'POST',
                url: '/admin/api/business-integration/disconnect/:storeId/:type',
                isArray: false,
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'initiateWhatsappSync': {
                method: 'POST',
                url: '/admin/api/business-integration/whatsapp/initiate-sync',
                isArray: false,
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'createWhatsappJob': {
                method: 'POST',
                url: '/admin/api/business-integration/whatsapp/create-job',
                isArray: false,
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'clearWhatsappData': {
                method: 'POST',
                url: '/admin/api/business-integration/whatsapp/clear',
                isArray: false,
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'getWhatsappResumes': {
                method: 'POST',
                url: '/admin/api/business-integration/whatsapp-job/all/:storeId',
                isArray: false,
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'getFbCampaingns': {
                method: 'GET',
                url: '/flyer/api/facebookBusiness/list',
                isArray: false,
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'resumeWhatsAppJob': {
                method: 'GET',
                url: '/admin/api/business-integration/whatsapp/resume-job/:token',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },

            'existsWhatsappJob': {
                method: 'GET',
                url: '/admin/api/business-integration/whatsapp/first/:storeId',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'getGMBInsights': {
                method: 'GET',
                url: '/admin/api/business-integration/google-my-business/insights/:storeId',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'getGMBQuestions': {
                method: 'GET',
                url: '/admin/api/business-integration/google-my-business/questions/:reportId',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'getIgInsights': {
                method: 'GET',
                url: '/admin/api/social/instagram/insights?start=:start&end=:end',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'getFbInsights': {
                method: 'GET',
                url: '/admin/api/social/facebook/insights?start=:start&end=:end',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'getFbCampaignInsights': {
                method: 'GET',
                url: '/flyer/api/facebookBusiness/campaign/:campaignId/insights',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
        });
    }

})();
