(function() {
    'use strict';

    angular
        .module('showcaseApp')
        .controller('FlyersController', FlyersController);

    function FlyersController(FlyersCurrencyValue, Principal, Constants, $scope, $translate, $localStorage, $state, FlyersService,
                              FlyerCategoryService, toastr, $timeout, RemoveModalFactory, ModalFactory, paginationConstants,
                              StoreService, $stateParams, BrandService, ModalBrandFactory, ShowcasesService,
                              ErpsSettingsService, OnlineRetailService, BlingService, NexaasService, $uibModal) {

        var vm = this;

        vm.currentPage = 1;
        vm.numPerPage = paginationConstants.itemsPerPage;
        vm.maxSize = 5;
        vm.totalFlyers = 0;
        vm.startRegistry = 1;
        vm.endRegistry = vm.numPerPage;
        vm.time_stamp_to_image = '';

        vm.selectedFlyers = [];

        vm.paginationOptions = {
            page: vm.currentPage - 1,
            size: vm.numPerPage
        };
        vm.configuration = {};

        vm.flyersFiltered = [];
        vm.flyersTypes = [];
        vm.displayType = "Table";
        vm.zeroOffers = false;
        vm.displayTypeList = ["Cards", "Table"];
        vm.startDate = {
            startDate: null,
            endDate: null
        };
        vm.tabInfo = {
            existsCouponsFlyer: false,
            existsRentFlyer: false,
            existsContactFlyer: false,
            existsSaleFlyer:false
        };
        vm.showCouponsWithActive = false;
        vm.isLoading = true;
        vm.listFlyers = true;
        vm.loadingArray = ["1", "2", "3", "4"];
        vm.removeCouponsWithIds = [];
        vm.listFilter = {
            storeId: $localStorage.defaultStore.storeId,
            activated: null,
            text: '',
            hasInventory: false
        };
        vm.userIsAdmin = false;

        vm.flyersToRemove = [];

        vm.page = 0;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.hasNextPage = false;
        vm.busy = false;
        vm.loadingTab = true;
        vm.brands = [];
        vm.brandGroups = {};
        vm.colors = [];
        vm.viewByCategories = false;
        vm.categoryGroups = {};
        vm.numberFilterSelected = 0;

        vm.sortOptions = paginationConstants.sortOptions[$translate.use().toLowerCase()];
        
        vm.selectedSortByOption = "";

        vm.loadingActionRequest = false;
        vm.erpProductsCount = 0;
        vm.actions = [
            {
                value: 'REMOVE',
                translation: 'flyers.selection-actions.remove',
                actionFunc: function () {
                    return vm.verifyIfFlyersIsPublished();
                }
            },
            {
                value: 'PUBLISH',
                translation: 'flyers.selection-actions.publish',
                actionFunc: function () {
                    ModalFactory.openWarningModal($translate.instant('flyers.selection-actions.modals.publish-modal.title'),
                        $translate.instant('flyers.selection-actions.modals.publish-modal.subtitle', { amount: vm.selectedFlyers.length }),
                        $translate.instant('flyers.selection-actions.modals.publish-modal.small-text'),
                        null,
                        $translate.instant('flyers.selection-actions.modals.publish-modal.confirm-button'),
                        $translate.instant('flyers.selection-actions.modals.cancel'),
                        vm.publishFlyers
                    );
                }
            },
            {
                value: 'UNPUBLISH',
                translation: 'flyers.selection-actions.unpublish',
                actionFunc: function () {
                    ModalFactory.openWarningModal($translate.instant('flyers.selection-actions.modals.unpublish-modal.title'),
                        $translate.instant('flyers.selection-actions.modals.unpublish-modal.subtitle', { amount: vm.selectedFlyers.length }),
                        $translate.instant('flyers.selection-actions.modals.unpublish-modal.small-text'),
                        null,
                        $translate.instant('flyers.selection-actions.modals.unpublish-modal.confirm-button'),
                        $translate.instant('flyers.selection-actions.modals.cancel'),
                        vm.unpublishFlyers
                    );
                }
            },
            {
                value: 'CLEAR_STOCK',
                translation: 'flyers.selection-actions.clear-stock',
                actionFunc: function () {
                    ModalFactory.openWarningModal($translate.instant('flyers.selection-actions.modals.clear-stock-modal.title'),
                        $translate.instant('flyers.selection-actions.modals.clear-stock-modal.subtitle', { amount: vm.selectedFlyers.length }),
                        $translate.instant('flyers.selection-actions.modals.clear-stock-modal.small-text'),
                        null,
                        $translate.instant('flyers.selection-actions.modals.clear-stock-modal.confirm-button'),
                        $translate.instant('flyers.selection-actions.modals.cancel'),
                        vm.clearStock
                    );
                }
            },
            {
                value: 'APPLY_DISCOUNTS',
                translation: 'flyers.selection-actions.apply-discounts',
                actionFunc: function () {
                    ModalFactory.openDiscountModal($translate.instant('flyers.selection-actions.modals.discount-modal.title'),
                        $translate.instant('flyers.selection-actions.modals.discount-modal.subtitle'),
                        $translate.instant('flyers.selection-actions.modals.discount-modal.input-label'),
                        $translate.instant('flyers.selection-actions.modals.discount-modal.input-footer'),
                        $translate.instant('flyers.selection-actions.modals.discount-modal.confirm-button'),
                        $translate.instant('flyers.selection-actions.modals.cancel'),
                        applyDiscounts
                    );
                }
            },
            {
                value: 'SYNC_ERP',
                translation: 'flyers.selection-actions.sync-with-erp',
                actionFunc: function () {
                    ModalFactory.openWarningModal($translate.instant('flyers.selection-actions.modals.sync-erp-modal.title'),
                        $translate.instant('flyers.selection-actions.modals.sync-erp-modal.subtitle', { amount: vm.selectedFlyers.length }),
                        $translate.instant('flyers.selection-actions.modals.sync-erp-modal.small-text'),
                        $translate.instant('flyers.selection-actions.modals.sync-erp-modal.warning-text'),
                        $translate.instant('flyers.selection-actions.modals.sync-erp-modal.confirm-button'),
                        $translate.instant('flyers.selection-actions.modals.cancel'),
                        vm.syncronizeProducts
                    );
                }
            },
            {
                value: 'SYNC_IMAGE_FLYERS',
                translation: 'flyers.selection-actions.sync-flyers-with-hub',
                actionFunc: function () {
                    ModalFactory.openSyncWithHubModal($translate.instant('flyers.selection-actions.modals.sync-flyers-with-hub-modal.title'),
                        $translate.instant('flyers.selection-actions.modals.sync-flyers-with-hub-modal.subtitle', { amount: vm.selectedFlyers.length }),
                        $translate.instant('flyers.selection-actions.modals.sync-flyers-with-hub-modal.small-text'),
                        null,
                        [
                            {
                                translateId: 'flyers.selection-actions.modals.sync-flyers-with-hub-modal.param-name',
                                value: false,
                                syncParam: "SYNC_PARAM_NAME"
                            },
                            {
                                translateId: 'flyers.selection-actions.modals.sync-flyers-with-hub-modal.param-description',
                                value: false,
                                syncParam: "SYNC_PARAM_DESCRIPTION"
                            },
                            {
                                translateId: 'flyers.selection-actions.modals.sync-flyers-with-hub-modal.param-image',
                                value: false,
                                syncParam: "SYNC_PARAM_IMAGE"
                            }
                        ],
                        $translate.instant('flyers.selection-actions.modals.sync-flyers-with-hub-modal.confirm-button'),
                        $translate.instant('flyers.selection-actions.modals.cancel'),
                        vm.syncronizeProductsWithHub
                    );
                }
            }
        ]

        vm.selectionTypes = [
            {
                value: 'ALL',
                translation: 'flyers.selection-filters.all'
            },
            {
                value: 'ALL_FROM_PAGE',
                translation: 'flyers.selection-filters.select-all-from-page'
            },
            {
                value: 'ALL_FROM_TAB',
                translation: 'flyers.selection-filters.select-all-from-tab'
            },
            {
                value: 'PUBLISHED',
                translation: 'flyers.selection-filters.published'
            },
            {
                value: 'UNPUBLISHED',
                translation: 'flyers.selection-filters.unpublished'
            },
            {
                value: 'OUT_OF_STOCK',
                translation: 'flyers.selection-filters.out-of-stock'
            },
            {
                value: 'WITH_STOCK',
                translation: 'flyers.selection-filters.with-stock'
            },
            {
                value: 'NONE',
                translation: 'flyers.selection-filters.select-none'
            }
        ];

        vm.selectionTypesData = {
            currentSelection: 'NONE',
            'ALL': {
                isActivated: false,
                selectFunc: function () {
                    return vm.selectAllFromStore();
                }
            },
            'ALL_FROM_PAGE': {
                isActivated: false,
                selectFunc: function () {
                    return vm.selectAllItems();
                }
            },
            'ALL_FROM_TAB': {
                isActivated: false,
                selectFunc: function () {
                    return vm.selectAllFromTab();
                }
            },
            'PUBLISHED': {
                isActivated: false,
                selectFunc: function () {
                    return vm.selectAllPublished();
                }
            },
            'UNPUBLISHED': {
                isActivated: false,
                selectFunc: function () {
                    return vm.selectAllUnpublished();
                }
            },
            'OUT_OF_STOCK': {
                isActivated: false,
                selectFunc: function () {
                    return vm.selectAllOutOfStock();
                }
            },
            'WITH_STOCK': {
                isActivated: false,
                selectFunc: function () {
                    return vm.selectAllWithStock();
                }
            },
            'NONE': {
                isActivated: false,
                selectFunc: function () {
                    return vm.unselectAll();
                }
            }
        };

        vm.onSelectedSortOption = function () {
            clearPaginationInfo();
            vm.listFilter.sortBy = vm.selectedSortByOption.value;
            vm.listFilter.sortDirection = vm.selectedSortByOption.direction;
            loadFlyers();
        }

        checkUserIsAdmin();
        //getFlyersTypes();

        vm.loadFlyers  = loadFlyers;

        vm.currencyConfiguration = getCurrencyListConfiguration();

        vm.randomTime = new Date().getTime();

        function clearPaginationInfo(){
            // Turn to 0 the page to give right page on pageable
            vm.page = 0;
            vm.currentPage = 1;
            vm.paginationOptions = {
                page: vm.currentPage - 1,
                size: vm.numPerPage
            };
        }

        vm.tabClick = function(brandName){

            vm.loadingTab = true;

            // Changes the type to the new type Clicked
            vm.listFilter.brand = brandName;
            // Erases the offer list
            vm.flyersFiltered = [];

            clearPaginationInfo();

            // loadOffers
            loadFlyers();

        }

        vm.tabCategoryClick = function(categoryKey){

            vm.loadingTab = true;

            // Changes the type to the new type Clicked
            vm.listFilter.categoryKey = categoryKey;
            // Erases the offer list
            vm.flyersFiltered = [];

            // Turn to 0 the page to give right page on pageable
            vm.page = 0;
			vm.currentPage = 1;
	        vm.paginationOptions = {
	            page: vm.currentPage - 1,
	            size: vm.numPerPage
	        };

            // loadOffers
            loadFlyers();

        }

//        function loadFilter() {
//            vm.getSizes();
//            vm.getColors();
//        }
//
//        vm.getSizes = function() {
//            console.log(FlyersService.getSizes());
//            FlyersService.getSizes().then(function(response) {
//                vm.sizes = response.data;
//            });
//        }
//
//        vm.getColors = function() {
//            FlyersService.getColors().then(function(response) {
//                vm.colors = response.data;
//            });
//        }

        function checkUserIsAdmin() {
            Principal.hasAuthority("ADMIN_MANAGE_SYSTEM").then(function(result) {
                if (result) {
                    vm.userIsAdmin = true;
                } else {
                    vm.userIsAdmin = false;
                }
            });
        }

        vm.stopPropagation = function(event) {
            event.stopPropagation();
        };

        vm.checkType = function(type) {
            vm.createArray("displayType", vm.displayTypeList.length, false);
            vm.displayTypeListCheck[vm.displayTypeList.indexOf(type)] = true;
            vm.displayType = type;
        }


        vm.isEven = function(number) {
            return (number % 2 == 0);
        };

        vm.refreshCouponToRemove = function(id) {
            if (vm.removeCouponsWithIds.indexOf(id) != '-1') {
                vm.removeCouponsWithIds.splice(vm.removeCouponsWithIds.indexOf(id), 1);
            } else {
                vm.removeCouponsWithIds.push(id);
            }
        };

        vm.existsItensToRemove = function() {
            return vm.removeCouponsWithIds.length >= 1;
        };

        vm.changeDisplayType = function() {
            vm.displayType = vm.displayType === 'Table' ? 'Cards' : 'Table';
        };

        vm.checkDisplayType = function(type) {
            return (vm.displayType === type);
        };

        vm.changeActivated = function() {
            vm.loadingTab = true;
            vm.page = 0;
            if (vm.listFilter.activated === null) {
                vm.listFilter.activated = true;
            } else {
                vm.listFilter.activated = !vm.listFilter.activated;
            }
            loadFlyers();
        };


        vm.openCalendarStart = function(e) {
            e.preventDefault();
            e.stopPropagation();
            vm.isOpenCalendarStart = true;
        };

        vm.openCalendarExpire = function(e) {
            e.preventDefault();
            e.stopPropagation();
            vm.isOpenCalendarExpire = true;
        };

        vm.flyerDetailPage = function(flyerId) {
            $state.go('flyers.edit', {
                id: flyerId
            });
        };

        vm.verifyIfFlyersIsPublished = function () {
            FlyersService.verifyIfFlyersIsPublished(vm.selectedFlyers).then(function (response) {
                ModalFactory.openWarningModal($translate.instant('flyers.selection-actions.modals.remove-modal.title'),
                    $translate.instant('flyers.selection-actions.modals.remove-modal.subtitle', { amount: vm.selectedFlyers.length }),
                    $translate.instant('flyers.selection-actions.modals.remove-modal.small-text'),
                    null,
                    $translate.instant('flyers.selection-actions.modals.remove-modal.confirm-button'),
                    $translate.instant('flyers.selection-actions.modals.cancel'),
                    vm.removeFlyers
                );
            }, function(response) {
                ModalFactory.openWarningModal($translate.instant('flyers.selection-actions.modals.remove-published-modal.title'),
                    $translate.instant('flyers.selection-actions.modals.remove-published-modal.subtitle',
                        { amount: response.data.data.amount, amountPublished: response.data.data.publishedAmount }),
                    $translate.instant('flyers.selection-actions.modals.remove-published-modal.small-text'),
                    null,
                    $translate.instant('flyers.selection-actions.modals.remove-published-modal.confirm-button'),
                    $translate.instant('flyers.selection-actions.modals.cancel'),
                    vm.removeFlyers
                );
            });
        }

        vm.removeFlyers = function() {
            if (vm.existsSelectedItems()) {
                vm.removalLoading = true;
                vm.loadingActionRequest = true;
                FlyersService.deleteFlyers(vm.selectedFlyers).then(function(data) {
                    vm.removalLoading = false;
                    vm.loadingActionRequest = false;
                    toastr.success($translate.instant('flyers.messages.remove.success'));
                    vm.selectedFlyers = [];
                    $state.reload();
                }, function(data) {
                    vm.removalLoading = false;
                    vm.loadingActionRequest = false;
                    if (data.data.message.includes('errorShowcase')){
                        toastr.error($translate.instant('flyers.messages.remove.errorShowcase'));
                    }else{
                        toastr.error($translate.instant('flyers.messages.remove.error'));
                    }
                });
            } else {
                toastr.error($translate.instant('flyers.messages.remove.noItens'));
            }
        };

        vm.getCategoryName = function(flyer) {
            var category = _.find(vm.categories, function(el) {return el.key === flyer.categoryKey});
            if (category) {
                flyer.category = category.title;
            }
        }


        vm.getFlyerCurrency = function(currency) {
            return FlyersCurrencyValue[currency];
        };

        vm.hasActiveFilter = function() {
            return vm.numberFilterSelected && vm.numberFilterSelected > 0;
        };

        vm.changeView = function () {
            vm.flyersFiltered = [];
            delete vm.listFilter.categoryKey;
            delete vm.listFilter.categoryKeys;

            delete vm.listFilter.brand;
            delete vm.listFilter.brands;

            StoreService.updateDefaultFilter(
                {
                    id: $localStorage.defaultStore.storeId,
                    defaultFilter: vm.viewByCategories ? 'CATEGORY' : 'BRAND'
                }
            )

            $localStorage.defaultStore.defaultFilter = vm.viewByCategories ? 'CATEGORY' : 'BRAND';

            if (vm.viewByCategories) {
                getCategories();
            } else {
                getBrands();
            }
        }

        function loadAll() {
            vm.time_stamp_to_image = '?' + Date.now();
            loadErpConfiguration();
            getDefaultFilter();
            getFlyersTypes();
            getCategories();

            ShowcasesService.getAllFlyerIdsFromShowcasesPublished().then(function (data) {
                vm.publishedProducts = data;
            });

            if ($stateParams.type) {
                vm.loadingTab = true;

                // Changes the type to the new type Clicked
                vm.listFilter.type = $stateParams.type;

                // Erases the offer list
                vm.flyersFiltered = [];

                // Turn to 0 the page to give right page on pageable
                vm.page = 0;
            }

			FlyersService.getAllFiltersFlyer().then(function (response) {
				vm.colors = response.data.colors;
				vm.sizes = response.data.sizes;
            });
        }

        function getCategories() {
            if (!vm.categories || !vm.categories.length) {
                FlyerCategoryService.listCategoriesByCurrentStore($translate.use(), $localStorage.defaultStore.storeId).then(function (response) {
                    vm.categories = response.data.filter(function (category) {
                        return category.countFlyer > 0;
                    });
                    vm.categories = _.uniqBy(vm.categories, 'key');
                    vm.categories.forEach(function (category) {
                        vm.categoryGroups[category.key] = {
                            count: 0,
                            total: category.countFlyer
                        };
                    });
                    sortCategoriesByName();
                });
            } else {
                selectTabCategory();
            }
        }

        function selectTabCategory() {
            if (vm.viewByCategories && vm.categories.length > 0) {
                if ($stateParams.category) {
                    vm.listFilter.categoryKey = $stateParams.category;
                    vm.tabCategoryClick($stateParams.category)
                }
                else {
                    vm.listFilter.categoryKey = vm.categories[0].key;
                    vm.tabCategoryClick(vm.categories[0].key);
                }
            }
        }

        function getBrands() {
            if (!vm.brands || !vm.brands.length) {
                BrandService.getBrandsByCurrentStore().then(function (response) {
                    vm.brands = response.data.filter(function (brand) {
                        return brand.quantFlyer > 0;
                    });
                    vm.brands = _.uniqBy(vm.brands, 'name');
                    vm.brands.forEach(function (brand) {
                        vm.brandGroups[brand.name] = {
                            count: 0,
                            total: brand.quantFlyer
                        };
                    });
                    selectTabBrand();
                });
            } else {
                selectTabBrand();
            }
        }

        function selectTabBrand() {
            if ((!vm.viewByCategories) && vm.brands.length > 0) {
                if ($stateParams.brand) {
                    vm.listFilter.brand = $stateParams.brand;
                    vm.tabClick($stateParams.brand);
                }
                else {
                    vm.listFilter.brand = vm.brands[0].name;
                    vm.tabClick(vm.brands[0].name);
                }
            }
        }

        function getFlyersTypes() {
            FlyersService.getFlyerTypes().then(function (response) {
                var removeRent = response.data.map(function(item) { return item.code; }).indexOf('RENT');
                response.data.splice(removeRent, 1);
                var removeContact = response.data.map(function(item) { return item.code; }).indexOf('CONTACT');
                response.data.splice(removeContact, 1);
                vm.flyersTypes = response.data;
            });
        }

        function loadErpConfiguration() {
            ErpsSettingsService.getERPConfigurationByStoreId($localStorage.defaultStore.storeId)
                .then(function (response) {
                    if (response.data && response.data.id) {
                        vm.configuration = response.data;
                    }
                });
        }

        function getDefaultFilter() {
            setTimeout(function() {
                vm.viewByCategories = $localStorage.defaultStore.defaultFilter === 'CATEGORY';

                //load default filter
                vm.flyersFiltered = [];
                delete vm.listFilter.categoryKey;
                delete vm.listFilter.categoryKeys;

                delete vm.listFilter.brand;
                delete vm.listFilter.brands;

                getCategories();
                getBrands();


            }, 1000);
        }

        function sortCategoriesByName() {
            vm.categories = vm.categories.sort(function(a, b) {
                if (a.title.toLowerCase() < b.title.toLowerCase()) {
                    return -1;
                }
                if (a.title.toLowerCase() > b.title.toLowerCase()) {
                    return 1;
                }
                return 0;
            });
        }

        vm.refreshSelectedFlyers = function(flyer) {
            vm.brandGroups[flyer.brand] = vm.brandGroups[flyer.brand] ? vm.brandGroups[flyer.brand] : { count: 0 };
            vm.categoryGroups[flyer.categoryKey] = vm.categoryGroups[flyer.categoryKey] ? vm.categoryGroups[flyer.categoryKey] : { count: 0 };
            if (vm.selectedFlyers.indexOf(flyer.id) != '-1') {
                vm.brandGroups[flyer.brand].count = vm.brandGroups[flyer.brand].count - 1;
                vm.categoryGroups[flyer.categoryKey].count = vm.categoryGroups[flyer.categoryKey].count - 1;
                flyer.isSelected = false;
                vm.selectedFlyers.splice(vm.selectedFlyers.indexOf(flyer.id), 1);
            } else {
                vm.brandGroups[flyer.brand].count = vm.brandGroups[flyer.brand].count + 1;
                vm.categoryGroups[flyer.categoryKey].count = vm.categoryGroups[flyer.categoryKey].count + 1;
                flyer.isSelected = true;
                vm.selectedFlyers.push(flyer.id);
            }
            vm.selectedAll = true;
            angular.forEach(vm.flyersFiltered, function(flyer) {
                if (vm.selectedFlyers.indexOf(flyer.id) === -1) {
                    vm.selectedAll = false;
                    flyer.isSelected = false;
                }
            });
        };

        vm.existsSelectedItems = function() {
            return vm.selectedFlyers.length >= 1;
        };

        vm.isIndeterminate = function () {
            var filteredSelected = vm.flyersFiltered.filter(function(flyer) { return flyer.isSelected });
            return vm.selectedFlyers.length > 0
                && filteredSelected.length >= 1
                && filteredSelected.length !== vm.flyersFiltered.length;
        }

        vm.isAllFromPageSelected = function () {
            return vm.selectedFlyers.length > 0 && vm.flyersFiltered.every(function(flyer) { return flyer.isSelected });
        }

        vm.getCountSelectedByBrand = function(brand) {
            return vm.brandGroups[brand].count;
        };

        vm.getCountSelectedByCategory = function(category) {
            return vm.categoryGroups[category].count;
        };

        vm.refreshFlyersSelected = function() {
            vm.makeBrandGroups();
        }

        vm.selectAllItems = function() {
            vm.selectedAll = !vm.isAllFromPageSelected();
            angular.forEach(vm.flyersFiltered, function(flyer) {
                if (vm.selectedAll) {
                    if (vm.selectedFlyers.indexOf(flyer.id) === -1) {
                        if (!vm.brandGroups[flyer.brand]) {
                            vm.brandGroups[flyer.brand] = {count: 0}
                        }

                        if (!vm.categoryGroups[flyer.categoryKey]) {
                            vm.categoryGroups[flyer.categoryKey] = { count: 0 }
                        }
                        vm.brandGroups[flyer.brand].count = vm.brandGroups[flyer.brand].count + 1;
                        vm.categoryGroups[flyer.categoryKey].count = vm.categoryGroups[flyer.categoryKey].count + 1;
                        flyer.isSelected = true;
                        vm.selectedFlyers.push(flyer.id);
                    }
                } else {
                    if (vm.selectedFlyers.indexOf(flyer.id) !== -1) {
                        vm.brandGroups[flyer.brand].count = vm.brandGroups[flyer.brand].count - 1;
                        vm.categoryGroups[flyer.categoryKey].count = vm.categoryGroups[flyer.categoryKey].count - 1;
                        flyer.isSelected = false;
                        vm.selectedFlyers.splice(vm.selectedFlyers.indexOf(flyer.id), 1);
                    }
                }
            });
        };

        vm.reset = function reset() {
            vm.page = 0;
            vm.flyersFiltered = [];
        };

        vm.checkIfFlyerSelected = function(flyer) {
            return vm.selectedFlyers.indexOf(flyer.id) != '-1';
        }

        vm.selectItemsBy = function (selectBy, isFromSelection) {
            isFromSelection = isFromSelection ? isFromSelection : false;
            if (selectBy === 'ALL_FROM_PAGE' && vm.isAllFromPageSelected() && !vm.isIndeterminate() && !isFromSelection) {
                vm.unselectAllFromPage();
                vm.selectionTypesData[vm.selectionTypesData.currentSelection].isActivated = false;
                vm.selectionTypesData.currentSelection = 'NONE'
                return;
            }

            if (isFromSelection && selectBy !== 'ALL_FROM_TAB' && selectBy !== 'ALL_FROM_PAGE') vm.unselectAll();
            vm.selectionTypesData[vm.selectionTypesData.currentSelection].isActivated = false;

            vm.selectionTypesData[selectBy].selectFunc();

            vm.selectionTypesData.currentSelection = selectBy;
            vm.selectionTypesData[selectBy].isActivated = true;
        }

        vm.isErpIntegrated = function(flyer) {
            if (flyer.erpProductId) {
                return true;
            } else if (flyer.details && flyer.details.length) {
                for (var index in flyer.details){
                    var detail = flyer.details[index];
                    if (detail.erpProductId) {
                        return true;
                    }
                }
            } else {
                return false;
            }
        }

        vm.showAction = function(action) {
            if (action.value === 'SYNC_ERP') {
                return vm.configuration && vm.configuration.id && vm.configuration.id != Constants.ErpPartnerId.SETA;
            }
            return true;
        }

        function setSelectedProductsByBrand(brandSet) {
            vm.brands.forEach(function (brand) {
                if (brandSet[brand.name]) {
                    var flyersId = brandSet[brand.name].map(function (product) {
                        var flyerSelected = vm.flyersFiltered.find(function (flyer) {
                            return flyer.id === product.id;
                        });
                        if (flyerSelected) flyerSelected.isSelected = true;
                        return product.id;
                    })
                    vm.selectedFlyers = vm.selectedFlyers.concat(flyersId);
                    vm.brandGroups[brand.name].count = brandSet[brand.name].length;
                }
            });
            vm.selectedFlyers = _.uniq(vm.selectedFlyers);
        }

        function setSelectedProductsByCategory(categorySet) {
            vm.categories.forEach(function (category) {
                if (categorySet[category.key]) {
                    var flyersId = categorySet[category.key].map(function (product) {
                        var flyerSelected = vm.flyersFiltered.find(function (flyer) {
                            return flyer.id === product.id;
                        });
                        if (flyerSelected) flyerSelected.isSelected = true;
                        return product.id;
                    })
                    vm.selectedFlyers = vm.selectedFlyers.concat(flyersId);
                    vm.categoryGroups[category.key].count = categorySet[category.key].length;
                }
            });
            vm.selectedFlyers = _.uniq(vm.selectedFlyers);
        }

        vm.selectAllFromStore = function () {
            vm.loadingSelection = true;
            FlyersService.listAllFlyersIds({
                storeId: $localStorage.defaultStore.storeId,
                brandSelected: !!vm.listFilter.brand
            }).then(function (response) {
                vm.unselectAll();
                if (vm.listFilter.brand) {
                    setSelectedProductsByBrand(response);
                } else {
                    setSelectedProductsByCategory(response)
                }
            });
        }

        vm.selectAllFromTab = function () {
            var payload = {
                storeId: $localStorage.defaultStore.storeId,
                brandSelected: !!vm.listFilter.brand
            };

            if (vm.listFilter.brand) {
                payload.brand = vm.listFilter.brand;
                payload.brands = [vm.listFilter.brand];
            } else {
                payload.categoryKey = vm.listFilter.categoryKey;
                payload.categoryKeys = [vm.listFilter.categoryKey];
            }

            vm.loadingSelection = true;
            FlyersService.listAllFlyersIds(payload).then(function (response) {
                vm.loadingSelection = false;
                if (vm.listFilter.brand) {
                    setSelectedProductsByBrand(response);
                } else {
                    setSelectedProductsByCategory(response)
                }
            });
        }

        vm.selectAllPublished = function () {
            vm.loadingSelection = true;
            FlyersService.listAllFlyersIds({
                storeId: $localStorage.defaultStore.storeId,
                brandSelected: !!vm.listFilter.brand,
                isPublished: true
            }).then(function (response) {
                vm.unselectAll();
                if (vm.listFilter.brand) {
                    setSelectedProductsByBrand(response);
                } else {
                    setSelectedProductsByCategory(response)
                }
            });
        }

        vm.selectAllUnpublished = function () {
            vm.loadingSelection = true;
            FlyersService.listAllFlyersIds({
                storeId: $localStorage.defaultStore.storeId,
                brandSelected: !!vm.listFilter.brand,
                isPublished: false
            }).then(function (response) {
                vm.unselectAll();
                if (vm.listFilter.brand) {
                    setSelectedProductsByBrand(response);
                } else {
                    setSelectedProductsByCategory(response)
                }
            });
        }

        vm.selectAllWithStock = function () {
            vm.loadingSelection = true;
            FlyersService.listAllFlyersIds({
                storeId: $localStorage.defaultStore.storeId,
                brandSelected: !!vm.listFilter.brand,
                hasInventory: true
            }).then(function (response) {
                vm.unselectAll();
                if (vm.listFilter.brand) {
                    setSelectedProductsByBrand(response);
                } else {
                    setSelectedProductsByCategory(response)
                }
            });
        }

        vm.selectAllOutOfStock = function () {
            vm.loadingSelection = true;
            FlyersService.listAllFlyersIds({
                storeId: $localStorage.defaultStore.storeId,
                hasNoInventory: true,
                brandSelected: !!vm.listFilter.brand
            }).then(function (response) {
                vm.unselectAll();
                if (vm.listFilter.brand) {
                    setSelectedProductsByBrand(response);
                } else {
                    setSelectedProductsByCategory(response)
                }
            });
        }

        vm.unselectAll = function () {
            vm.selectedFlyers = [];
            vm.brands.forEach(function (brand) {
                vm.brandGroups[brand.name].count = 0;
            });
            vm.categories.forEach(function (category) {
                vm.categoryGroups[category.key].count = 0;
            });
            vm.loadingSelection = false;
        }

        vm.unselectAllFromPage = function () {
            vm.flyersFiltered.forEach(function (flyer) {
                if (vm.selectedFlyers.indexOf(flyer.id) !== -1) {
                    vm.brandGroups[flyer.brand].count = vm.brandGroups[flyer.brand].count - 1;
                    vm.categoryGroups[flyer.categoryKey].count = vm.categoryGroups[flyer.categoryKey].count - 1;
                    flyer.isSelected = false;
                    vm.selectedFlyers.splice(vm.selectedFlyers.indexOf(flyer.id), 1);
                }
            });
            vm.loadingSelection = false;
        }

        vm.publishFlyers = function () {
            vm.loadingActionRequest = true;
            ShowcasesService.addPublishedFlyers(vm.selectedFlyers).then(function () {
                vm.selectedFlyers.forEach(function (flyerId) {
                    addFlyerPublished(flyerId);
                    vm.loadingActionRequest = false;
                });
                vm.unselectAll();
            }, function (response) {
                vm.loadingActionRequest = false;
                toastr.error($translate.instant("flyers.messages.publishFlyers.error"));
            });
        }

        vm.unpublishFlyers = function () {
            vm.loadingActionRequest = true;
            ShowcasesService.deletePublishedFlyers(vm.selectedFlyers).then(function () {
                vm.selectedFlyers.forEach(function (flyerId) {
                    removeFlyerPublished(flyerId);
                });
                vm.loadingActionRequest = false;
                vm.unselectAll();
            }, function (response) {
                vm.loadingActionRequest = false;
                toastr.error($translate.instant("flyers.messages.unpublishFlyers.error"));
            });
        }

        vm.clearStock = function () {
            vm.loadingActionRequest = true;
            FlyersService.clearFlyersStock(vm.selectedFlyers).then(function () {
                vm.selectedFlyers.forEach(function (flyerId) {
                    clearFlyerStock(flyerId);
                });
                vm.unselectAll();
                vm.loadingActionRequest = false;
            }, function (response) {
                vm.loadingActionRequest = false;
                toastr.error($translate.instant("flyers.messages.clearStock.error"));
            });
        }

        vm.syncronizeProductsWithHub = function (params) {
            if (!vm.selectedFlyers) {
                return;
            }
            
            FlyersService.syncFlyersWithHub(vm.selectedFlyers, params).then(function () {
                vm.unselectAll();
                vm.loadingActionRequest = false;
                toastr.success($translate.instant("flyers.messages.syncFlyersWithHub.success"));
            }, function (response) {
                vm.loadingActionRequest = false;
                toastr.error($translate.instant("flyers.messages.syncFlyersWithHub.error"));
            });
        }

        vm.syncronizeProducts = function () {
            if (!vm.selectedFlyers) {
                return;
            }

            if (vm.selectedFlyers.length > 100) {
                toastr.error($translate.instant("flyers.messages.syncErp.hundred-error"));
                return;
            }

            if (!vm.configuration || !vm.configuration.id || !vm.configuration.partnerId) {
                toastr.error($translate.instant("flyers.messages.syncErp.configuration-error"));
                return;
            }

            switch (vm.configuration.partnerId) {
                case Constants.ErpPartnerId.VAREJO_ONLINE:
                    synchronizeProductsWithVarejoOnline();
                    break;
                case Constants.ErpPartnerId.NEXAAS:
                    synchronizeProductsWithNexaas();
                    break;
                case Constants.ErpPartnerId.BLING:
                    synchronizeProductsWithBling();
                    break;
                default:
                    console.log("Sincronização de produtos indisponível com parceiro " + vm.configuration.partnerId);
            }
        }

        function synchronizeProductsWithVarejoOnline() {
            vm.removalLoading = true;
            vm.loadingActionRequest = true;
            OnlineRetailService.syncProductsByStoreAndFlyerIds($localStorage.defaultStore.storeId, vm.selectedFlyers)
                .then(function (response) {
                    vm.removalLoading = false;
                    vm.loadingActionRequest = false;
                    toastr.success($translate.instant('flyers.messages.syncErp.success'));
                    vm.unselectAll();
                    loadFlyers();
                }, function (error) {
                    vm.removalLoading = false;
                    vm.loadingActionRequest = false;
                    toastr.error($translate.instant("flyers.messages.syncErp.general-error"));
                });
        }

        function synchronizeProductsWithNexaas() {
            vm.removalLoading = true;
            vm.loadingActionRequest = true;
            NexaasService.syncProductsByStoreAndFlyerIds($localStorage.defaultStore.storeId, vm.selectedFlyers)
                .then(function (response) {
                    vm.removalLoading = false;
                    vm.loadingActionRequest = false;
                    toastr.success($translate.instant('flyers.messages.syncErp.success'));
                    vm.unselectAll();
                    loadFlyers();
                }, function (error) {
                    vm.removalLoading = false;
                    vm.loadingActionRequest = false;
                    toastr.error($translate.instant("flyers.messages.syncErp.general-error"));
                });
        }

        function synchronizeProductsWithBling() {
            vm.removalLoading = true;
            vm.loadingActionRequest = true;
            BlingService.syncProductsByStoreAndFlyerIds($localStorage.defaultStore.storeId, vm.selectedFlyers)
                .then(function (response) {
                    vm.removalLoading = false;
                    vm.loadingActionRequest = false;
                    toastr.success($translate.instant('flyers.messages.syncErp.success'));
                    vm.unselectAll();
                    loadFlyers();
                }, function (error) {
                    vm.removalLoading = false;
                    vm.loadingActionRequest = false;
                    toastr.error($translate.instant("flyers.messages.syncErp.general-error"));
                });
        }

        function applyDiscounts (discountApplied) {
            vm.loadingActionRequest = true;
            FlyersService.applyDiscountsForFlyers(vm.selectedFlyers, discountApplied).then(function () {
                loadFlyers();
                vm.unselectAll();
                vm.loadingActionRequest = false;
            }, function (response) {
                vm.loadingActionRequest = false;
                toastr.error($translate.instant("flyers.messages.applyDiscounts.error"));
            });
        }

        vm.getFlyerTooltip = function (status) {
            switch (status) {
                case 'ACTIVATED':
                    return 'flyers.offer.active';
                case 'DEACTIVATED':
                    return 'flyers.offer.disabled';
                case 'EXPIRED':
                    return 'flyers.offer.expired';
                case 'CLOSED':
                    return 'flyers.offer.closed';
            }
        }

        vm.changePage = function () {

            var begin = ((vm.currentPage - 1) * vm.numPerPage),
            end = begin + vm.numPerPage;

            vm.paginationOptions.page = vm.currentPage - 1;

            loadFlyers();
            vm.startRegistry = begin + 1;
            vm.endRegistry = end;

        }

        function loadFlyers() {
            if(vm.busy) return;
            vm.busy = true;

            if (vm.listFilter.configurations && (vm.listFilter.configurations).includes("launch")) {
                vm.listFilter.launch = true;
            } else {
                delete vm.listFilter.launch;
            }

            if (vm.listFilter.configurations && (vm.listFilter.configurations).includes("basic")) {
                vm.listFilter.basic = true;
            } else {
                delete vm.listFilter.basic;
            }

            if (!vm.listFilter.colors || !(vm.listFilter.colors.length > 0)) {
                delete vm.listFilter.colors
            }

            if (!vm.listFilter.sizes || !(vm.listFilter.sizes.length > 0)) {
                delete vm.listFilter.sizes
            }

            if (!vm.listFilter.genders || !(vm.listFilter.genders.length > 0)) {
                delete vm.listFilter.genders
            }

            if (vm.numberFilterSelected === 0 && vm.listFilter.categoryKey && vm.listFilter.categoryKey !== '' && vm.listFilter.text === '') {
                vm.listFilter.categoryKeys = [vm.listFilter.categoryKey]
            } else if (vm.filters.categories.length > 0) {
                vm.listFilter.categoryKey = vm.listFilter.categoryKeys[0]
            } else {
                delete vm.listFilter.categoryKeys
            }

            if (vm.numberFilterSelected === 0 && vm.listFilter.brand && vm.listFilter.brand !== '' && vm.listFilter.text === '') {
                vm.listFilter.brands = [vm.listFilter.brand]
            } else if (!vm.filters.brands || !(vm.filters.brands.length > 0)) {
                delete vm.listFilter.brands
            }

            if (vm.selectedSortByOption.label === "Sem estoque")
                vm.listFilter.hasInventory = false;
            FlyersService.listAllToAdmin(vm.paginationOptions, vm.listFilter, onSuccess, onError);

            function onSuccess(data, headers) {

                //vm.tabInfo.existsCouponsFlyer = data.existsCouponsFlyer;
                //vm.tabInfo.existsRentFlyer = data.existsRentFlyer;
                //vm.tabInfo.existsContactFlyer = data.existsContactFlyer;
                //vm.tabInfo.existsSaleFlyer = data.existsSaleFlyer;

                vm.zeroOffers = data.content.length <= 0;

                if(vm.zeroOffers) {
                    vm.flyersFiltered = [];
                    vm.selectedAll = false;
                } else {
                    vm.flyersFiltered = data.content;
                    vm.selectedAll = true;
                    angular.forEach(vm.flyersFiltered, function(flyer) {
                        var isSelected = vm.selectedFlyers.indexOf(flyer.id);
                        flyer.isSelected = isSelected > -1;
                        if (isSelected === -1) {
                            vm.selectedAll = false;
                        }
                    })
                }

                vm.flyersFiltered = data.content;
                vm.flyersFiltered = vm.flyersFiltered.map(function(flyer) {
                    vm.getCategoryName(flyer);
                    flyer.currencyInstance = vm.currencyConfiguration[flyer.currency];
                    return flyer;
                });

                vm.totalFlyers = headers('X-Total-Count');

                vm.isLoading = false;
                vm.busy = false;
                vm.loadingTab = false;

            }

            function onError(_) {
                vm.busy = false;
                vm.loadingTab = false;
                vm.isLoading = false;
                toastr.error($translate.instant('flyers.messages.load.error'));
            }
        }

        loadAll();

        function checkForCurrentBrand(brands) {
            var result = false;
            brands.forEach(function (brand) {
                if (brand.name == vm.listFilter.brand)
                    result = true;
            });
            return result;
        }

        function loadBrands() {
            BrandService.getBrandsByCurrentStore().then(function(response) {
                vm.brands = response.data;
                if (!vm.viewByCategories && !checkForCurrentBrand(response.data))
                    vm.listFilter.brand = response.data[0].name;
                loadFlyers();
            });
        }

        vm.showModalBrand = function showModalBrand() {
            ModalBrandFactory.listModal().then(function (_) {
                BrandService.getBrandsByCurrentStore().then(function(_) {
                    loadBrands();
                });
            });
        }

        vm.checkFlyerIsSelected = function(flyer) {
            if (vm.publishedProducts) {
                if (vm.publishedProducts.includes(flyer.id))
                    return true;
                return false
            }
        };


        function removeFlyerPublished(flyerId) {
            vm.publishedProducts = vm.publishedProducts.filter(function(id) {
                    return flyerId !== id;
                });
        }

        function clearFlyerStock(flyerId) {
            var flyer = vm.flyersFiltered.find(function(filterdFlyer) {
                return flyerId === filterdFlyer.id;
            });
            if (flyer) {
                if (flyer.details && flyer.details.length) {
                    flyer.details.forEach(function (detail) {
                        detail.inventory = 0;
                    })
                } else {
                    flyer.inventory = 0;
                }
            }
        }

        function addFlyerPublished(flyerId) {
            vm.publishedProducts.push(flyerId)
        }

        vm.unpublishFlyer = function (flyerId) {
            ShowcasesService.deletePublishedFlyer(flyerId).then(function (data) {
                removeFlyerPublished(flyerId);
            }, function () {});
        }

        vm.publishFlyer = function (flyerId) {
            ShowcasesService.addPublishedFlyer(flyerId).then(function (data) {
                addFlyerPublished(flyerId);
            }, function (response) {
                if (response.status === 404 || response.status === 400) {
                    toastr.warning($translate.instant('flyers.messages.published.warning'));
                } else {
                    toastr.error($translate.instant("flyers.messages.published.error"));
                }
            });
        }

        //loadFilter();

        vm.filters = {
            categories: [],
            brands: [],
            colors: [],
            sizes: [],
            genders: [],
            configurations: [],
            hasInventory : false
        };

        vm.openFiltersModal = function () {
            vm.filtersConversationModal = $uibModal.open({
                templateUrl: 'app/admin/flyers/modal-filter-flyer/flyer-filter-modal.html',
                controller: 'FiltersFlyersController',
                windowClass: 'modal-center',
                windowTopClass: 'center-modal',
                controllerAs: 'vm',
                size: 'lg',
                keyboard: false,
                resolve: {
                    options: function () {
                        return {
                            categories: vm.categories,
                            brands: vm.brands,
                            colors: vm.colors,
                            sizes: vm.sizes,
                            canChangeView: true,
                            viewByCategories: vm.viewByCategories,
                            clearFilters: vm.clearFilters
                        }
                    },
                    filters: function () {
                        return vm.filters
                    },
                    list: function () {
                        return vm.listFilter
                    }
                },
            }).result.then(function (response) {
                vm.unselectAll();
                if (response) {
                    vm.numberFilterSelected = response.totalFilters;
                    if (response.changeView) {
                        vm.viewByCategories = response.selectedFilters.viewByCategories;
                        vm.changeView();
                    }
                    vm.filters = response.selectedFilters;
                    if (response.totalFilters === 0 && !(response.changeView)) {
                        getDefaultFilter();
                    } else
                        vm.listFilter = response.listFilter;
                    loadFlyers();
                }
            });
        }
    }

})();
