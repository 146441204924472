/* globals $ */
(function() {
    'use strict';

    angular
        .module('showcaseApp')
        .directive('messageLoading', messageLoading);

    function messageLoading() {
        return {
            restrict: 'E',
            scope: {
                size: '=',
                alignSide: '=',
                margin: '='
            },
            templateUrl: 'app/admin/communication/directives/message-loading/message-loading.html'
        };
    }
})();
