(function () {
    'use strict';
    angular
        .module('showcaseApp')
        .controller('BusinessIntegrationController', BusinessIntegrationController);

    function BusinessIntegrationController(FacebookService, $timeout, FBAuthModalFactory,
        InstaStepsModalFactory, GMBService, $scope, $localStorage, toastr, $translate, FBCampaignModalFactory,
        SocialMediaService, ModalFactory, BusinessIntegrationService, CustomerService, $rootScope, CampaignInsightsModalFactory) {

        var vm = this;

        vm.tabSelected = 'instagramBusiness';
        vm.facebook = {};
        vm.isLoadingGMB = false;
        vm.isLoadingFB = false;

        vm.filterFbCampaigns = {};
        vm.currentPageFbCampaigns = 1;
        vm.numPerPageFbCampaigns = 10;
        vm.endRegistryFbCampaigns = vm.numPerPageFbCampaigns;
        vm.maxSizeFbCampaign = 5;
        vm.paginationOptionsFbCampaigns = {
            page: vm.currentPageFbCampaigns - 1,
            size: vm.numPerPageFbCampaigns,
            sort: null
        };
        vm.totalFBCampaigns = 0;

        vm.startRegistryFbCampaigns = 1;

        vm.gmbInsights = {};
        vm.totalGMBQuestions = 0;
        vm.gmbQuestions = [];
        vm.loadingGMBQuestions = false;

        vm.currentPageGMB = 1;
        vm.numPerPageGMB = 5;
        vm.maxSizeGMB = 5;
        vm.startRegistryGMB = 1;
        vm.endRegistryGMB = vm.numPerPageGMB;
        vm.paginationOptionsGMB = {
            page: vm.currentPageGMB - 1,
            size: vm.numPerPageGMB,
            sort: 'DESC'
        };

        function loadFacebookCampaigns(pagination, filter) {
            vm.loadingFacebookCamps = true;

            BusinessIntegrationService.getFbCampaingns(pagination, filter)
                .then(function (data) {
                    vm.totalFBCampaigns = data.headers['x-total-count'];
                    vm.facebookCampaigns = data.data.slice();
                    vm.loadingFacebookCamps = false;
                }).catch(function (err) {
                    vm.loadingFacebookCamps = false;
                })
        }

        vm.changePageFacebookCampaign = function () {

            var beginFacebookCampaign = ((vm.currentPageFbCampaigns - 1) * vm.numPerPageFbCampaigns),
                endFbCampaigns = beginFacebookCampaign + vm.numPerPageFbCampaigns;

            vm.paginationOptionsFbCampaigns.page = vm.currentPageFbCampaigns - 1;

            loadFacebookCampaigns(vm.paginationOptionsFbCampaigns, vm.filter);
            vm.startRegistryFbCampaigns = beginFacebookCampaign + 1;
            vm.endRegistryFbCampaigns = endFbCampaigns;

        }
        vm.changePageFacebookCampaign();
        vm.fbLogin = function () {
            FacebookService.login().then(updateStatus);
        };

        vm.fbLogout = function () {
            FacebookService.logout().then(updateStatus);
        };

        vm.changeTab = function (tab) {
            vm.tabSelected = tab;
            if (tab == 'instagramBusiness') {
                vm.checkFbStatus();
            } else if (tab == 'googleBusiness') {
                loadGMB();
            } else if (tab == 'whatsapp') {
                loadWhatsapp();
            } else if (tab == 'facebook') {
                vm.checkFbStatus();
            } else if (tab == 'facebookBusiness') {
                vm.checkFbStatus();
                loadFacebookCampaigns(vm.paginationOptionsFbCampaigns, vm.filter);
            }
        };

        vm.checkFbStatus = function () {
            vm.isLoadingFB = true;
            SocialMediaService.isFacebookConnected().then(updateStatus, function (err) {
                vm.isLoadingFB = false;
            });
        }

        vm.facebookDisconnect = function () {
            vm.isLoadingFB = true;
            SocialMediaService.facebookDisconnect().then(onSuccessFacebookDisconnect, function (err) {
                vm.isLoadingFB = false;
            });
        }

        function onSuccessFacebookDisconnect(response) {
            vm.isLoadingFB = false;
            if (response.data) {
                vm.facebook.connected = false;
                vm.igAccountConnected = false;
                toastr.success($translate.instant('socialMedia.success.successFacebookDisconnect'));
            } else {
                toastr.warning($translate.instant('socialMedia.error.cannotLoggout'));
            }
        }

        vm.isFistLoadSocialContent = false;

        vm.facebookAuth = function () {
            vm.isFistLoadSocialContent = false;
            FBAuthModalFactory.connect(false).then(function (data) {
                vm.isFistLoadSocialContent = true;
                if (data) {
                    vm.checkFbStatus();
                }
            });
        }

        vm.createCampain = function () {
            FBCampaignModalFactory.update(vm.facebook.data).then(function () {
                loadFacebookCampaigns(vm.paginationOptionsFbCampaigns, vm.filter);
            });
        }

        vm.createCatalog = function () {
            InstaStepsModalFactory.update().then(function () {
                loadFacebookCampaigns(vm.paginationOptionsFbCampaigns, vm.filter);
            });
        }

        /**
         * On Init
         */
        $timeout(function () {
            // vm.checkFbStatus();
        }, 500);


        function updateStatus(response) {
            vm.isLoadingFB = false;
            var result = response.data;
            if (result && result.accessToken && result.page) {
                vm.facebook.connected = true;
                vm.facebook.data = result;
                if (vm.tabSelected == 'instagramBusiness') {
                    updateIgStatus(result);
                }
                if (vm.tabSelected == 'facebook') {
                    getFbInsights();
                }
            } else {
                vm.facebook.connected = false;
                vm.facebook.data = {};
            }
        }

        vm.getCampaignInsights = function (campaign) {
            CampaignInsightsModalFactory.connect(campaign.id);
        }


        /**
         * GOOGLE MY BUSINESS CONTROLLER DATA
         */

        // Variables
        vm.isLoadingGMB = false;

        // This variable is to this controller know if the user just open this page
        vm.justArrive = true;

        vm.isGoogleMyBusinessConnected = false;
        vm.authorizeGMB = authorizeGMB;
        vm.signOutGMB = signOutGMB;
        vm.GMBConfiguration = null;

        function chooseAccounts() {
            vm.isLoadingGMB = true;
            vm.isGoogleMyBusinessConnected = false;
            if (vm.GMBConfiguration && (!vm.GMBConfiguration.googleAccountTarget || !vm.GMBConfiguration.googleLocationTarget)) {
                ModalFactory.openSelectGMBAccountAndCategoryModal(vm.GMBConfiguration.accounts)
                    .then(function (response) {
                        if (response) {
                            toastr.success($translate.instant('integrations.googleMyBusiness.modalSelectConfigurations.locations.messages.' + response));
                        }
                        getGMBInsights();
                    }, function (error) {
                        console.log("it's necessary to select an account");
                        if (error && error != 'backdrop click') {
                            toastr.error($translate.instant('integrations.googleMyBusiness.modalSelectConfigurations.locations.messages.' + error));
                        }
                        errorSelectAccount();
                        vm.isLoadingGMB = false;
                    });
            }
        }

        function getGMBInsights() {
            vm.isLoadingGMB = true;
            vm.isGoogleMyBusinessConnected = false;
            GMBService.getGMBInsights(
                { storeId: $localStorage.defaultStore.storeId },
                function (response) {
                    vm.gmbInsights = response.data;
                    vm.isLoadingGMB = false;
                    vm.isGoogleMyBusinessConnected = true;
                    getGMBQuestions(vm.gmbInsights.id, vm.paginationOptionsGMB)
                },
                function () {
                    vm.isLoadingGMB = false;
                    vm.isGoogleMyBusinessConnected = false;
                }
            )
        }

        function getGMBQuestions(reportId, pagination) {
            vm.loadingGMBQuestions = true;
            var params = pagination;
            params.reportId = reportId
            GMBService.getGMBQuestions(params, null).then(function (result) {
                vm.totalGMBQuestions = parseInt(result.headers['x-total-count']);
                vm.gmbQuestions = result.data.slice();
                vm.loadingGMBQuestions = false;
            }, function (error) {
                vm.loadingGMBQuestions = false;
            });
        }

        vm.changePageGMB = function () {
            var begin = ((vm.currentPageGMB - 1) * vm.numPerPageGMB),
                end = begin + vm.numPerPageGMB;
            vm.paginationOptionsGMB.page = vm.currentPageGMB - 1;

            getGMBQuestions(vm.gmbInsights.id, vm.paginationOptionsGMB);
            vm.startRegistryGMB = begin + 1;
            vm.endRegistryGMB = end;
        }

        function errorSelectAccount() {
            signOutGMB();
        }

        function successReceiveCodeCallback(response) {
            vm.isGoogleMyBusinessConnected = true;
            if (!$scope.$$phase) {
                $scope.$apply();
            }
            if (response.data && response.data.accounts && response.data.accounts.length > 0) {
                toastr.success($translate.instant('integrations.googleMyBusiness.messages.successConnected'));
                vm.GMBConfiguration = response.data;
                chooseAccounts();
            } else {
                vm.isLoadingGMB = false;
            }

        }

        function errorReceiveCodeCallback() {
            vm.isGoogleMyBusinessConnected = false;
            vm.isLoadingGMB = false;
            if (!$scope.$$phase) {
                $scope.$apply();
            }
            toastr.error($translate.instant('integrations.googleMyBusiness.messages.errorConnected'));
        }

        function callbackLoginError() {
            vm.isLoadingGMB = false;
            if (!$scope.$$phase) {
                $scope.$apply();
            }
        }

        function callbackLogin(response) {
            /**
             * 1. verify if the user logged already has an access_token
             * 2. If not, add the new access_token to the user
             */
            GMBService.receiveCode({
                storeId: ($localStorage.defaultStore ? ($localStorage.defaultStore.storeId || '') : '')
            }, response.code, successReceiveCodeCallback, errorReceiveCodeCallback);

            if (!$scope.$$phase) {
                $scope.$apply();
            }
        }

        function authorizeGMB() {
            vm.isLoadingGMB = true;
            GMBService.authorize(callbackLogin, callbackLoginError);
        }

        function erroSignOut() {
            vm.isGoogleMyBusinessConnected = true;
            if (!$scope.$$phase) {
                $scope.$apply();
            }
        }

        function successSignOut() {
            vm.isGoogleMyBusinessConnected = false;
            if (!$scope.$$phase) {
                $scope.$apply();
            }
        }

        function signOutGMB() {
            GMBService.signOut({
                storeId: ($localStorage.defaultStore ? ($localStorage.defaultStore.storeId || '') : ''),
                type: 'GOOGLE_MY_BUSINESS'
            }, successSignOut, erroSignOut);
        }

        function getInitialConfigurationCallback(response) {
            vm.GMBConfiguration = response ? response.data : null;
            vm.isGoogleMyBusinessConnected = (vm.GMBConfiguration && vm.GMBConfiguration.connected);
            if (vm.isGoogleMyBusinessConnected) {
                getGMBInsights();
            } else {
                vm.isLoadingGMB = false;
            }
        }

        function getInitialConfigurationErrorCallback(error) {
            vm.isGoogleMyBusinessConnected = (vm.GMBConfiguration && vm.GMBConfiguration.connected);
            vm.isLoadingGMB = false;
        }

        function getInitialConfiguration() {
            vm.isLoadingGMB = true;
            GMBService.getConfiguration({
                storeId: ($localStorage.defaultStore ? ($localStorage.defaultStore.storeId || '') : ''),
                type: 'GOOGLE_MY_BUSINESS'
            }, getInitialConfigurationCallback, getInitialConfigurationErrorCallback);
        }

        function loadGMB() {
            vm.isLoadingGMB = true;
            getInitialConfiguration();
        }

        /**
         *  WHATSAPP INTEGRATION CONTROLLER DATA
         */

        // 20 seconds in milliseconds
        vm.SPEND_TIME_PER_PHONE = 20000;
        vm.WHATSAPPRESUME_FETCH_INTERVAL = 2000;

        vm.whatsappConf = null;
        vm.isWhatsappTabLoading = false;
        vm.loadingWhatsappJobResumes = true;
        vm.isSending = false;
        vm.loadingCustomers = false;
        vm.selectedCustomers = [];
        vm.unselectedCustomers = [];
        vm.customers = [];
        vm.whatsappJobResumes = [];

        vm.thereIsWhatsappJob = false;

        vm.wappDTO = {};
        vm.wappDTO.phones = [];
        vm.wappDTO.excludedPhones = [];
        vm.wappDTO.isAllCustomers = false;


        // Customer pagination configuration
        vm.filter = {};
        vm.currentPage = 1;
        vm.numPerPage = 5;
        vm.maxSize = 5;
        vm.totalCustomers = 0;
        vm.startRegistry = 1;
        vm.endRegistry = vm.numPerPage;
        vm.isRetrying = false;


        vm.paginationOptions = {
            page: vm.currentPage - 1,
            size: vm.numPerPage,
            sort: null
        };

        // WhatsappJob Pagination configuration
        vm.filterWhatsappJob = {};
        vm.currentPageWhatsappJob = 1;
        vm.numPerPageWhatsappJob = 10;
        vm.maxSizeWhatsappJob = 5;
        vm.startRegistryWhatsappJob = 1;
        vm.endRegistryWhatsappJob = vm.numPerPageWhatsappJob;
        vm.totalWhatsappJob = 0;

        vm.paginationOptionsWhatsappJob = {
            page: vm.currentPageWhatsappJob - 1,
            size: vm.numPerPageWhatsappJob,
            sort: null
        };

        // Functions
        vm.selectCustomer = selectCustomer;
        vm.changePageCustomer = changePageCustomer;
        vm.changePageWhatsappJob = changePageWhatsappJob;
        vm.selectAllCustomers = selectAllCustomers;
        vm.existsWhatsappJob = existsWhatsappJob;

        vm.reconectWhatsapp = function () {
            vm.getQrCode();
        }
        vm.openWhatsappCampaingModal = function () {
            ModalFactory.openWhatsappQRCodeModal(vm.whatsappConf).then(successQrCodeModalCallback, errorQrCodeModalCallback);
        }

        function successQrCodeModalCallback(response) {
            clearInterval($rootScope.fetchQrCodeInterval);
            loadWhatsapp();
        }

        function errorQrCodeModalCallback(response) {
            clearInterval($rootScope.fetchQrCodeInterval);
            loadWhatsapp();
        }

        function clearPageSelections() {
            vm.customers.forEach(function (elem) {
                if (elem.selected) {
                    selectCustomer(elem);
                }
            });
        }

        function selectAllCustomers() {
            if (vm.wappDTO.isAllCustomers) {
                clearAllSelections();
            } else {
                vm.wappDTO.isAllCustomers = true;
                vm.selectedCustomers = [];
                vm.unselectedCustomers = [];
                vm.customers.forEach(function (elem) {
                    if (!elem.selected) {
                        selectCustomer(elem);
                    }
                });
            }

        }

        function clearAllSelections() {
            vm.wappDTO.isAllCustomers = false;
            vm.selectedCustomers = [];
            vm.unselectedCustomers = [];
            clearPageSelections();
        }

        function whatsappGetConfigurationCallback(response) {
            vm.whatsappConf = response.data;
            vm.isWhatsappTabLoading = false;
        }

        function whatsappGetConfigurationErrorCallback() {
            toastr.error($translate.instant('integrations.whatsapp.messages.errorGetConfiguration'));
        }


        function loadWhatsapp() {
            vm.isWhatsappTabLoading = true;
            BusinessIntegrationService.getConfiguration(($localStorage.defaultStore ? ($localStorage.defaultStore.storeId || '') : ''),
                'WHATSAPP',
                whatsappGetConfigurationCallback,
                whatsappGetConfigurationErrorCallback);

            listCustomers(vm.paginationOptions, vm.filter);
            loadWhatsappJobResumes(vm.paginationOptionsWhatsappJob, vm.filterWhatsappJob);
            vm.existsWhatsappJob();
        }

        vm.orderByBirthdayWeek = function () {
            vm.orderedByBirthdayWeek = !vm.orderedByBirthdayWeek;

            vm.filter.orderedByBirthdayWeek = vm.orderedByBirthdayWeek;
            vm.currentPage = 1;
            vm.paginationOptions.page = 0;
            listCustomers(vm.paginationOptions, vm.filter);

        }

        // Select or unselect the customer
        function selectCustomer(customer) {

            // Switch the customer selected variable
            // Here the customers must be unselected if the all customers option is true
            // otherwise, if customers is unselected by user and all customers option is true
            // then we have to add the customer to excludedCustomers list in wappDTO
            customer.selected = !customer.selected;

            // If customer is selected and allCustomer is true, so we don't need to insert customer into selectedCustomers list
            if (customer.selected && !vm.wappDTO.isAllCustomers) {
                var isPresent = (findIndexByAttr(vm.selectedCustomers, 'id', customer.id) > -1);
                if (!isPresent) vm.selectedCustomers.push(customer);
            } else if (!customer.selected && vm.wappDTO.isAllCustomers) {
                vm.unselectedCustomers.push(customer);
            } else {
                removeElementFromArrayByAttr(vm.selectedCustomers, 'id', customer.id);
            }
        }


        // Sync the page with all previous selected customers
        function syncCustomerSelectedWithCustomers(customers, selectedCustomers) {

            // If all customers is selected is only to turn selected variable to true in each customer
            if (vm.wappDTO.isAllCustomers) {
                customers.forEach(function (element) {
                    // Verify if customer is in unselected list
                    var isPresent = (findIndexByAttr(vm.unselectedCustomers, 'id', element.id) > -1);
                    if (!isPresent) {
                        // Here we turn selected variable to true or false;
                        selectCustomer(element);
                    }
                });
            } else {
                selectedCustomers.forEach(function (elm) {
                    var targetIndex = findIndexByAttr(customers, 'id', elm.id);
                    if (targetIndex > -1) {
                        customers[targetIndex].selected = true;
                    }
                });
            }

            vm.loadingCustomers = false;

        }


        // List the customer using pagination
        function listCustomers(pagination, filter) {
            vm.loadingCustomers = true;
            CustomerService.list(pagination, filter).then(function (result) {
                vm.totalCustomers = result.headers['x-total-count'];
                vm.customers = result.data.slice();

                // Sync all the new customers with selected customers
                syncCustomerSelectedWithCustomers(vm.customers, vm.selectedCustomers);
            }, function (error) {
                vm.loadingCustomers = false;
            });
        }

        function changePageCustomer() {

            var begin = ((vm.currentPage - 1) * vm.numPerPage),
                end = begin + vm.numPerPage;

            vm.paginationOptions.page = vm.currentPage - 1;

            vm.customers = listCustomers(vm.paginationOptions, vm.filter);
            vm.startRegistry = begin + 1;
            vm.endRegistry = end;

        }

        function changePageWhatsappJob() {

            var beginWhatsappJob = ((vm.currentPageWhatsappJob - 1) * vm.numPerPageWhatsappJob),
                endWhatsappJob = beginWhatsappJob + vm.numPerPageWhatsappJob;

            vm.paginationOptionsWhatsappJob.page = vm.currentPageWhatsappJob - 1;

            loadWhatsappJobResumes(vm.paginationOptionsWhatsappJob, vm.filter);
            vm.startRegistryWhatsappJob = beginWhatsappJob + 1;
            vm.endRegistryWhatsappJob = endWhatsappJob;

        }

        function whatsappGetResumesCallback(response) {
            vm.totalWhatsappJob = response.headers['x-total-count'];
            vm.whatsappJobResumes = response.data.slice();
            vm.loadingWhatsappJobResumes = false;
        }

        function whatsappGetResumesErrorCallback(error) {
            toastr.error($translate.instant('integrations.whatsapp.messages.errorGetResumesWhatsappJob'));
            vm.loadingWhatsappJobResumes = false;
        }

        function loadWhatsappJobResumes(pagination, filter) {
            vm.loadingWhatsappJobResumes = true;
            BusinessIntegrationService.getWhatsappResumes(($localStorage.defaultStore ? ($localStorage.defaultStore.storeId || '') : ''),
                pagination, filter, whatsappGetResumesCallback, whatsappGetResumesErrorCallback);
        }

        function existsWhatsappJobCallbak(response) {
            vm.thereIsWhatsappJob = response.data;
        }

        function existsWhatsappJob() {
            BusinessIntegrationService.existsWhatsappJob(($localStorage.defaultStore ? ($localStorage.defaultStore.storeId || '') : '')).then(existsWhatsappJobCallbak);
        }

        vm.getToolTip = function (resume) {
            var totalPendding = resume.targets.length - (resume.totalMessagesFailed + resume.totalMessagesSent);
            var tooltip = $translate.instant('integrations.whatsapp.messages.percentageInfo', {
                total: resume.targets.length,
                failed: resume.totalMessagesFailed,
                sent: resume.totalMessagesSent,
                pending: totalPendding
            });
            return tooltip;
        }

        vm.getSentPercentage = function (resume) {
            return (resume.totalMessagesSent / resume.targets.length) * 100;
        }

        vm.getFailedPercentage = function (resume) {
            return (resume.totalMessagesFailed / resume.targets.length) * 100;
        }

        vm.getTotalPercentage = function (resume) {
            var total = ((resume.totalMessagesFailed + resume.totalMessagesSent) / resume.targets.length);
            return (total * 100).toFixed(2);
        }

        vm.getPendingPercentage = function (resume) {
            var totalProcessed = resume.totalMessagesFailed + resume.totalMessagesSent;
            return ((resume.targets.length - totalProcessed) / resume.targets.length) * 100;
        }

        vm.getResumeFBStatusClass = function (resume) {
            //TODO REFACTOR
            return 'badge badge-finished';
            if (resume.status == 'PAID') return 'badge badge-finished';
            else if (resume.status == 'WAITING_PAYMENT') return 'badge badge-warning';
            else if (resume.status == 'FAILED') return 'badge badge-danger';
            else if (resume.status == 'NOT_PAID') return 'badge badge-danger';
            else if (resume.status == 'PROCESSING') return 'badge badge-success';
        }

        vm.getResumeStatusClass = function (resume) {
            if (resume.status == 'DONE') return 'badge badge-finished';
            else if (resume.status == 'PENDING') return 'badge badge-warning';
            else if (resume.status == 'FAILED') return 'badge badge-danger';
            else if (resume.status == 'PROCESSING') return 'badge badge-success';
        }
        vm.printBillet = function (data) {
            var win = window.open(data.redirectHrefBoleto + '/print', '_blank');
            win.focus();
        }

        vm.detailWhatsappJob = function (resume) {

        }

        vm.resumeWhatsappJob = function (resume) {
            vm.selectedResume = resume;
            vm.isRetrying = true;
            BusinessIntegrationService.resumeWhatsappJob(resume.token)
                .then(function (data) {
                    toastr.success(
                        $translate.instant('integrations.whatsapp.messages.successRetried')
                    );
                    vm.filterWhatsappJob.campaingName = resume.campaingName;
                    loadWhatsappJobResumes(vm.paginationOptionsWhatsappJob, vm.filterWhatsappJob);
                    vm.isRetrying = false;
                })
                .catch(function (error) {
                    vm.isRetrying = false;
                    if (error.data.headers["x-adminapp-params"] === 'notLoggedIn') {
                        ModalFactory.openWhatsappResumeQrCodeModal().then(function (mResp) {
                            if (mResp === 'connected') {
                                toastr.success($translate.instant('integrations.whatsapp.messages.successRetried'));
                                BusinessIntegrationService.resumeWhatsappJob(resume.token);
                            }
                            if (mResp === 'expired') {
                                toastr.error($translate.instant('integrations.whatsapp.messages.errorRetried'));
                            }
                        }).catch(function (err) {
                            toastr.error($translate.instant('integrations.whatsapp.messages.errorRetried'));
                        });
                    }
                })
        }

        vm.removeWhatsappJob = function (resume) {

        }

        $scope.$on('refreshListCustomer', function (env) {
            listCustomers(vm.paginationOptions, vm.filter);
        });

        $scope.$on('refreshListWhatsappJob', function (env) {
            loadWhatsappJobResumes(vm.paginationOptionsWhatsappJob, vm.filterWhatsappJob);
        });

        vm.searchWaJobsByFilter = function () {
            vm.paginationOptionsWhatsappJob = {
                page: 0,
                size: vm.numPerPageWhatsappJob,
                sort: null
            };
            loadWhatsappJobResumes(vm.paginationOptionsWhatsappJob, vm.filterWhatsappJob);
        };

        // TODO: Better ot implement a refresh button
        // loadWhatsappJobResumes();
        // $rootScope.fetchWhatsappJobResumes = setInterval(
        //     loadWhatsappJobResumes,
        //     vm.WHATSAPPRESUME_FETCH_INTERVAL
        // );


        /**
         * INSIGHTS BUSINESS CONTROLLER DATA
         */

        // Variables
        vm.isLoadingIg = false;
        vm.igAccountMediaInsightsList = [];
        vm.igAccountInsights = {};
        vm.igAccountMediaInsights = {};
        vm.lineChartOptions = {
            maintainAspectRatio: false,
            responsive: true,
            scales: {
                xAxes: [{
                    ticks: {
                        display: false
                    }
                }],
                yAxes: [
                    {
                      type: 'linear',
                      display: true,
                      position: 'left'
                    }
                ]
            }
        }

        vm.barChartOptions = {
            maintainAspectRatio: false,
            responsive: true
        }

        vm.isLoadingFBInsights = false;
        vm.fbAccountInsights = {};
        vm.fbAccountMediaInsightsList = [];

        vm.dateRange = {
            startDate: moment().subtract(30, "days"),
            endDate: moment()
        };

        vm.audienceAgeLabels = ['13-17', '18-24', '25-34', '35-44', '45-54', '55-64', '65+'];

        var dateFormat = "DD-MM-YYYY";

        vm.dateRangeOptions = {
            locale: {
                applyClass: 'btn-green',
                applyLabel: "Aplicar",
                fromLabel: "From",
                format: dateFormat,
                toLabel: "To",
                cancelLabel: 'Cancelar',
                customRangeLabel: 'Personalizado'
            },
            ranges: {
                'Ontem': [moment().subtract(1, 'days'), moment()],
                'Últimos 7 dias': [moment().subtract(7, 'days'), moment()],
                'Últimos 30 dias': [moment().subtract(30, 'days'), moment()],
                'Último ano': [moment().subtract(1, 'year'), moment()]
            },
            eventHandlers: {
                'apply.daterangepicker': function (ev, picker) {
                    getIgInsights();
                    getFbInsights();
                }
            }
        };

        angular.element("#insigthsDateRange").on("click", function(event){
            event.preventDefault();
            angular.element("#dateRangeInput").data("daterangepicker").show()
        });

        function updateIgStatus(result) {
            if (result.business && result.business.igBusinessAccount) {
                vm.igAccountConnected = true;
                vm.igAccountData = result.business.igBusinessAccount;

                getIgInsights();
            } else {
                vm.igAccountConnected = false;
                vm.igAccountData = {};
            }
        }

        function getIgInsights() {
            clearData();
            vm.isLoadingIg = true;
            if (vm.dateRange.endDate) {
                vm.dateRange.endDate = vm.dateRange.endDate.add(1, 'minutes');
            }
            BusinessIntegrationService.getIgInsights(vm.dateRange.startDate, vm.dateRange.endDate)
                .then(function (response) {
                    vm.igAccountMediaInsightsList = response.data;
                    if (vm.igAccountMediaInsightsList.length > 0) {
                        vm.igAccountInsights = vm.igAccountMediaInsightsList[0];
                        vm.igAccountMediaInsights = vm.igAccountInsights.igMediaAccountInsights;
                        setData();
                    }
                    vm.isLoadingIg = false;
                }).catch(function (err) {
                    vm.isLoadingIg = false;
                    console.log(err)
                })
        }

        function getFbInsights() {
            // clearData(); TODO
            vm.isLoadingFBInsights = true;
            BusinessIntegrationService.getFbInsights(vm.dateRange.startDate, vm.dateRange.endDate)
                .then(function (response) {
                    vm.fbAccountMediaInsightsList = response.data;
                    if (vm.fbAccountMediaInsightsList.length > 0) {
                        vm.fbAccountInsights = vm.fbAccountMediaInsightsList[0];
                        setData();
                    }
                    vm.isLoadingFBInsights = false;
                }).catch(function (err) {
                    vm.isLoadingFBInsights = false;
                    console.log(err)
                })
        }

        vm.getFormattedValue = function (value) {
            return value < 1000 ? value : (value / 1000).toFixed(0) + 'k';
        }

        vm.igHasContent = function () {
            if (vm.igAccountInsights && vm.igAccountInsights.followersCount) {
                    return true
            }
            return false;

        }

        function getRate(value, divisor) {
            return (value > 0 && divisor > 0) ? ((value / divisor) * 100).toFixed(2) + '%' : '0%';
        }

        function setAudienceAgeData() {
            try {
                if (vm.igAccountInsights.audienceGenderAge) {

                    var audienceGenderAge = JSON.parse(vm.igAccountInsights.audienceGenderAge);
                    var totalByAge;

                    vm.audienceAgeLabels.forEach(function (label) {
                        totalByAge = 0;
                        Object.keys(audienceGenderAge).forEach(function (key) {
                            if (key.includes(label)) {
                                totalByAge += audienceGenderAge[key];
                            }
                        });
                        vm.audienceAgeData.push(totalByAge);
                    })
                }
            } catch (error) {
                console.log(error);
            }
        }
        function setFbAudienceAgeData() {
            try {
                if (vm.fbAccountInsights.audienceGenderAge) {

                    var audienceGenderAge = JSON.parse(vm.fbAccountInsights.audienceGenderAge);
                    var totalByAge;

                    vm.audienceAgeLabels.forEach(function (label) {
                        totalByAge = 0;
                        Object.keys(audienceGenderAge).forEach(function (key) {
                            if (key.includes(label)) {
                                totalByAge += audienceGenderAge[key];
                            }
                        });
                        vm.fbAudienceAgeData.push(totalByAge);
                    })
                }
            } catch (error) {
                console.log(error);
            }
        }

        function getGenderValue(gender, data) {
            var total = 0;
            var totalByGender = 0;
            try {
                var audienceGenderAge = JSON.parse(data);
                Object.keys(audienceGenderAge).forEach(function (key) {
                    total += audienceGenderAge[key];
                    if (key.includes(gender)) {
                        totalByGender += audienceGenderAge[key];
                    }
                })
            } catch (error) {
                console.log(error);
            }
            return getRate(totalByGender, total);
        }

        function setAudienceLocationData() {
            try {
                if (vm.igAccountInsights.audienceCity) {
                    var audienceCity = JSON.parse(vm.igAccountInsights.audienceCity);
                    var audienceCityMapOrdered = new Map(Object.entries(audienceCity).sort(function (a, b){return b[1] - a[1];}));
                    var audienceCityNames = Array.from(audienceCityMapOrdered.keys());
                    var audienceCityValues = Array.from(audienceCityMapOrdered.values());

                    vm.audienceLocationLabels = audienceCityNames.slice(0, 6);
                    vm.audienceLocationData = audienceCityValues.slice(0, 6);
                }
            } catch (error) {
                console.log(error);
            }
        }

        function setFbAudienceLocationData() {
            try {
                if (vm.fbAccountInsights.audienceCity) {
                    var audienceCity = JSON.parse(vm.fbAccountInsights.audienceCity);
                    var audienceCityMapOrdered = new Map(Object.entries(audienceCity).sort(function (a, b){return b[1] - a[1];}));
                    var audienceCityNames = Array.from(audienceCityMapOrdered.keys());
                    var audienceCityValues = Array.from(audienceCityMapOrdered.values());
                    vm.fbAudienceLocationLabels = audienceCityNames.slice(0, 6);
                    vm.fbAudienceLocationData = audienceCityValues.slice(0, 6);
                }

            } catch (error) {
                console.log(error);
            }
        }

        function setAudienceGenderData() {
            if (vm.igAccountInsights.audienceGenderAge) {
                vm.followersFemale = getGenderValue('F', vm.igAccountInsights.audienceGenderAge);
                vm.followersMale = getGenderValue('M', vm.igAccountInsights.audienceGenderAge);
            }
            if (vm.fbAccountInsights.audienceGenderAge) {
                vm.fbFollowersFemale = getGenderValue('F', vm.fbAccountInsights.audienceGenderAge);
                vm.fbFollowersMale = getGenderValue('M', vm.fbAccountInsights.audienceGenderAge);
            }
        }

        function setEngagement() {
            vm.igAccountMediaInsights.storyEngagement = vm.igAccountMediaInsights.replies + vm.igAccountMediaInsights.tapsBack;
            vm.igAccountMediaInsights.postEngagementRate = getRate(vm.igAccountMediaInsights.postEngagement, vm.igAccountMediaInsights.postReach);
            vm.igAccountMediaInsights.storyEngagementRate = getRate(vm.igAccountMediaInsights.storyEngagement, vm.igAccountMediaInsights.storyReach);
        }

        function setFollowersData() {
            var igFollowers = getFollowersData(vm.igAccountInsights.follows);
            vm.followersLabels = igFollowers.followersLabels;
            vm.followersData = igFollowers.followersData;
        }

        function setFbFollowersData() {
            var fbFollowers = getFollowersData(vm.fbAccountInsights.follows);
            vm.fbFollowersLabels = fbFollowers.followersLabels;
            vm.fbFollowersData = fbFollowers.followersData;
        }

        function getFollowersData(data) {
            var startDate = new Date(vm.dateRange.startDate);
            var endDate = new Date(vm.dateRange.endDate)
            var followersLabels = [];
            var followersData = [];
            try {
                if (data) {
                    data.forEach(function (item) {
                        var date = new Date(item.timestamp);
                        if (date <= endDate && date >= startDate) {
                            var formattedDate = moment(date).format(dateFormat);
                            var total = item.followersCount;
                            var itemIndex = followersLabels.indexOf(formattedDate); // check if date has already been added
                            if (itemIndex >= 0) {
                                followersData[itemIndex] = total; // replace value
                            } else {
                                followersLabels.push(formattedDate);
                                followersData.push(total);
                            }
                        }
                    });
                }
            } catch (error) {
                console.log(error);
            }
            return {
                followersLabels: followersLabels,
                followersData: followersData
            }
        }

        function setData() {
            setEngagement();
            setAudienceAgeData();
            setAudienceGenderData();
            setAudienceLocationData();
            setFollowersData();
            setFbAudienceLocationData();
            setFbAudienceAgeData()
            setFbFollowersData();
        }

        function clearData() {
            vm.audienceAgeData = [];
            vm.fbAudienceAgeData = [];
            vm.followersData = [];
            vm.fbFollowersData = [];
            vm.audienceLocationData = [];
            vm.fbAudienceLocationData = [];
            vm.followersFemale = vm.followersMale = vm.fbFollowersFemale = vm.fbFollowersMale = 0;
        }

        clearData();

    }
})();
