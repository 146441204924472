(function() {
    'use strict';
    angular
    .module('showcaseApp')
    .filter('rentStatusFilter', function($translate) {

        return function(value) {
            var newValue;

            switch (value) {
                case 'WAITING_PAYMENT':
                    newValue = $translate.instant('rent.states.waiting-payment');
                    break;
                case 'PAID':
                    newValue = $translate.instant('rent.states.paid');
                    break;
                case 'CANCELLED':
                    newValue = $translate.instant('rent.states.cancelled');
                    break;
                case 'ACCEPTED':
                    newValue = $translate.instant('rent.states.accepted');
                    break;
                case 'REFUSED':
                    newValue = $translate.instant('rent.states.refused');
                    break;
                case 'ARCHIVED':
                    newValue = $translate.instant('rent.states.archived');
                    break;
                case 'REVERTED':
                    newValue = $translate.instant('rent.states.reverted');
                    break;
                default:
                    break;
            }
            return newValue;
        }
    })
})();
