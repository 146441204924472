(function() {
    'use strict';

    angular
        .module('showcaseApp')
        .controller('ChatMessageSkeletonController', ChatMessageSkeletonController);

    ChatMessageSkeletonController.$inject = ['$scope'];

    function ChatMessageSkeletonController($scope) {
        var vm = this;

        vm.side = 'left';
        vm.hasIcon = true;

        function init() {
            vm.side = $scope.side;
            vm.hasIcon = $scope.hasIcon;
        }

        init();
    }

})();
