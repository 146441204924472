(function() {
    'use strict';

    angular
        .module('showcaseApp')
        .controller('RentInvoicesController', RentInvoicesController);

    function RentInvoicesController(RentInvoicesService, ChangeStateModalFactory, toastr, $translate) {
        var vm = this;

        vm.flyersFiltered = [];

        vm.isSaving = false;

        vm.startDate = {
            startDate: null,
            endDate: null
        };

        vm.currentPage = 1;
        vm.numPerPage = 10;
        vm.maxSize = 5;
        vm.totalCustomers = 0;

        vm.startRegistry = 1;
        vm.endRegistry = vm.numPerPage;

        vm.paginationOptions = {
            page: vm.currentPage - 1,
            size: vm.numPerPage,
            sort: null
        };

        vm.gridOptions = {
            expandableRowTemplate:
                '<div ui-grid="row.entity.subGridOptions" style="height:150px;"></div>',
            showExpandAllButton: false,
            multiSelect:false ,
            enableRowSelection: true,
            enablePaginationControls: false,
            paginationPageSize: vm.numPerPage,
            onDblClickExpand: false,
            enableSelectAll: false,
            enableColumnMenus: false,
            enableExpandableRowHeader: false,
            enableFiltering: true,
            rowTemplate:'<div ng-click="grid.appScope.fnOne(row)" ng-repeat="col in colContainer.renderedColumns track by col.colDef.name" class="kct-class ui-grid-cell mx-auto" ui-grid-cell></div>',
            expandableRowScope: {
                subGridVariable: 'subGridScopeVariable'
            }
        };

        var linkCellTemplate =
              '<span ng-if="row.entity[col.field] === \'CANCELLED\'" ng-click="alert(row, col)" class="label label-info cancelled" >{{ row.entity[col.field] | rentStatusFilter }}</span>'
            + '<span ng-if="row.entity[col.field] === \'PAID\'" class="label label-info paid" >{{ row.entity[col.field] | rentStatusFilter }}</span>'
            + '<span ng-if="row.entity[col.field] === \'ARCHIVED\'" class="label label-info archived" >{{ row.entity[col.field] | rentStatusFilter }}</span>'
            + '<span ng-if="row.entity[col.field] === \'ACCEPTED\'" class="label label-info accepted" >{{ row.entity[col.field] | rentStatusFilter }}</span>'
            + '<span ng-if="row.entity[col.field] === \'REFUSED\'" class="label label-info refused" >{{ row.entity[col.field] | rentStatusFilter }}</span>'
            + '<span ng-if="row.entity[col.field] == \'WAITING_PAYMENT\'" class="label label-info waiting_payment" >{{ row.entity[col.field] | rentStatusFilter }}</span>';

        vm.gridOptions.columnDefs = [
            {
                name: 'Actions',
                displayName: '',
                width: '1%',
                headerCellClass: 'header-noborder',
                cellClass: 'noborder',
                onDblClickExpand: false,
                enableCellEdit: false,
                enableFiltering: false,
                enableColumnMenu: false,
                cellTemplate:
                    ' <div  class="ui-grid-cell-contents"> \
                                <i \
                                ng-class="{ \'ui-grid-icon-right-dir\' : !row.isExpanded, \'ui-grid-icon-down-dir\' : row.isExpanded }" \
                                ng-click="grid.api.expandable.toggleRowExpansion(row.entity)"> \
                                </i> \
                            </div>',
                enableSorting: false
            },
            {
                name: 'Order Id',
                field: 'transaction_id',
                cellClass: 'noborder',
                width: '10%',
                enableFiltering: false,
                headerCellClass: 'header-noborder',
                displayName: 'rent.home.table.id',
                headerCellFilter: 'translate',
                onDblClickExpand: false,
                enableCellEdit: false
            },
            {
                name: 'Customer',
                field: 'customer',
                cellClass: 'noborder',
                enableFiltering: false,
                headerCellClass: 'header-noborder',
                displayName: 'rent.home.table.customer',
                headerCellFilter: 'translate',
                onDblClickExpand: false,
                enableCellEdit: false
            },
            {
                name: 'Amount',
                field: 'totalCost',
                headerCellClass: 'header-noborder',
                cellClass: 'noborder',
                enableFiltering: false,
                displayName: 'rent.home.table.total',
                headerCellFilter: 'translate',
                cellFilter: 'currency',
                type: 'number',
                onDblClickExpand: false,
                enableCellEdit: false
            },
            {
                name: 'Date',
                field: 'dateTransaction',
                enableFiltering: false,
                cellClass: 'noborder',
                headerCellClass: 'header-noborder',
                displayName: 'rent.home.table.date',
                headerCellFilter: 'translate',
                cellFilter: 'date:"dd/MM/yyyy"',
                onDblClickExpand: false,
                enableCellEdit: false
            },
            {
                name: 'Status',
                field: 'status',
                cellClass: 'noborder',
                enableFiltering: false,
                onDblClickExpand: false,
                headerCellClass: 'header-noborder',
                enableCellEdit: false,
                cellFilter: 'statusFilter',
                displayName: 'rent.home.table.status',
                headerCellFilter: 'translate',
                cellTemplate: linkCellTemplate
            },
            {
                name: 'Recommended action',
                cellClass: 'noborder',
                width: '25%',
                enableFiltering: false,
                onDblClickExpand: false,
                headerCellClass: 'header-noborder',
                enableCellEdit: false,
                displayName: 'rent.home.table.recommended-action',
                headerCellFilter: 'translate',
                cellTemplate: 'app/admin/rent-invoices/rent-row-detail.html'
            },
        ];



        function loadSubRowData(data) {
            for (var i = 0; i < data.length; i++) {
                data[i].subGridOptions = {
                    enableColumnMenus: false,
                    enableExpandableRowHeader: false,
                    columnDefs: [
                        {
                            name: 'Product',
                            headerCellClass: 'header-noborder',
                            field: 'flyerName',
                            cellClass: 'noborder',
                            displayName: 'rent.home.subTable.product',
                            headerCellFilter: 'translate'
                        },
                        {
                            name: 'Inicio',
                            field: 'initialRent',
                            headerCellClass: 'header-noborder',
                            cellClass: 'noborder',
                            cellFilter: 'date:"dd/MM/yyyy"',
                            displayName: 'rent.home.subTable.initialRent',
                            headerCellFilter: 'translate'
                        },
                        {
                            name: 'Fim',
                            field: 'finalRent',
                            cellClass: 'noborder',
                            headerCellClass: 'header-noborder',
                            cellFilter: 'date:"dd/MM/yyyy"',
                            displayName: 'rent.home.subTable.finalRent',
                            headerCellFilter: 'translate'
                        },
                        {
                            name: 'Quantity',
                            headerCellClass: 'header-noborder',
                            displayName: 'rent.home.subTable.quantity',
                            cellClass: 'noborder',
                            headerCellFilter: 'translate',
                            field: 'quantity'
                        }
                    ],
                    data: data[i].products
                };
            }
        }
        loadInvoices();
        function loadInvoices() {
            RentInvoicesService.listAll().then(function(result) {
                var data = result.data;
                var list = [];
                if (data && data.length) {
                    list = _.chain(result.data)
                        .groupBy('transaction.id')
                        .toPairs()
                        .map(function(pair) {
                            return _.zipObject(['transaction_id', 'data'], pair);
                        })
                        .value();
                    vm.gridData = parseResult(list);

                    vm.gridOptions.data = vm.gridData;
                    vm.totalCustomers = vm.gridData.length;
                    loadSubRowData(vm.gridData);
                }
            });
        }

        vm.updateStatus = function(row, status) {
            row.entity.status = status;
            vm.isSaving = true;

            RentInvoicesService.updateStatus(row.entity).then(function(result) {
                loadInvoices();
                toastr.success($translate.instant('rent.home.messages.saveSuccess'));
                vm.isSaving = false;
            }).catch(function(err) {
                toastr.error($translate.instant('rent.home.messages.noItens'));
                vm.isSaving = false;
            });
        }

        vm.changePage = function () {
            vm.gridApi.pagination.seek(vm.currentPage)
            var begin = ((vm.currentPage - 1) * vm.numPerPage),
                end = begin + vm.numPerPage;

            vm.paginationOptions.page = vm.currentPage - 1;

            vm.startRegistry = begin + 1;
            vm.endRegistry = end;

        }

        function parseResult(list) {
            var resultList = [];
            for (var i = 0; i < list.length; i++) {
                var current = {
                    transaction_id: list[i].transaction_id
                };
                current.products = [];
                for (var ind = 0; ind < list[i].data.length; ind++) {
                    current.products.push({
                        flyerName: list[i].data[ind].flyerName,
                        initialRent: list[i].data[ind].transaction.initialRent,
                        finalRent: list[i].data[ind].transaction.finalRent,
                        quantity: list[i].data[ind].quantity
                    });
                    current.customer =
                        list[i].data[ind].transaction.customer.fullName;
                    current.totalCost = list[i].data[ind].transaction.totalCost;
                    current.dateTransaction =
                        list[i].data[ind].transaction.dateTransaction;
                    current.status = list[i].data[ind].transaction.status;
                }
                resultList.push(current);
            }
            return resultList;
        }

        vm.gridOptions.onRegisterApi = function(gridApi) {
            vm.gridApi = gridApi;
        };

        vm.filter = function() {
            vm.gridApi.grid.refresh();
        };

        vm.state = {};

        vm.saveState = function() {
            vm.state = vm.gridApi.saveState.save();
        };

        vm.filterClient = function() {
            vm.state = vm.gridApi.saveState.save();
            vm.state.columns[2].filters[0] = { term: vm.clientFilter || '*' };
            vm.gridApi.saveState.restore(vm, vm.state);
        };

        vm.filterId = function() {
            vm.state = vm.gridApi.saveState.save();
            vm.state.columns[1].filters[0] = { term: vm.idFilter || '*' };
            vm.gridApi.saveState.restore(vm, vm.state);
        };

        vm.filterValue = function() {
            vm.state = vm.gridApi.saveState.save();
            vm.state.columns[3].filters[0] = { term: vm.valueFilter || '*' };
            vm.gridApi.saveState.restore(vm, vm.state);
        };

        vm.filterState = function() {
            vm.state = vm.gridApi.saveState.save();
            vm.state.columns[5].filters[0] = { term: vm.stateFilter || '*' };
            vm.gridApi.saveState.restore(vm, vm.state);
        };

        vm.declineOrders = function() {

            if (!vm.gridApi.selection.getSelectedCount()) {
                return toastr.error($translate.instant('rent.home.messages.noItens'));
            }

            var entity = vm.gridApi.selection.getSelectedGridRows()[0].entity;
            entity.status = "REFUSED";
            ChangeStateModalFactory.update().then(function(data) {
                if (data) {
                    RentInvoicesService.updateStatus(entity).then(function(result) {
                        loadInvoices();
                        toastr.success($translate.instant('rent.home.messages.saveSuccess'));
                        vm.gridApi.selection.clearSelectedRows()
                        vm.isSaving = false;
                    }).catch(function(err) {
                        toastr.error($translate.instant('rent.home.messages.noItens'));
                        vm.isSaving = false;
                    });
                }
            }).catch(function(err) {

            });
        }
    }
})();
