(function () {
    'use strict';

    angular
        .module('showcaseApp')
        .controller('LocationChatController', LocationChatController);

    LocationChatController.$inject = ['$uibModalInstance', '$localStorage', 'StoreService'];

    function LocationChatController($uibModalInstance, $localStorage, StoreService) {

        var vm = this;
        var storeId = $localStorage.defaultStore.storeId;
        vm.pointSelected = null;
        const URL_MAP = 'https://www.google.com/maps?saddr=My+Location&daddr='


        loadAll();

        function loadAll() {
            StoreService.getSalesPoints(storeId).then(function (response) {
                vm.salesPoint = response.data;
            });
        }

        vm.closeModal = function () {
            $uibModalInstance.close();
        }

        vm.addPoint = function () {
            const number = vm.pointSelected.number  ? vm.pointSelected.number + ', ' : '';
            const neighborhood = vm.pointSelected.neighborhood ? vm.pointSelected.neighborhood + ', ' : '';
            const andress = vm.pointSelected.name + '\n' + 
            vm.pointSelected.address + ', ' + number + 
            neighborhood + vm.pointSelected.city + '/' + 
            vm.pointSelected.state + ', ' + vm.zipCodeMask(vm.pointSelected.postalCode) + '\n' +
            '\nAcesse a rota através do link:\n';
            $uibModalInstance.close(andress + buildUrlMap());
        }

        vm.selectPoint = function (point) {
            point.selected = true;
            vm.salesPoint.forEach(function (salesPoint) {
                if (salesPoint.id != point.id) {
                    salesPoint.selected = false;
                }
            });

            vm.pointSelected = point;
        }

        function buildUrlMap() {
            const point = vm.pointSelected.coordinate.latitude + ',' + vm.pointSelected.coordinate.longitude;
            return URL_MAP + point;
        }

        vm.zipCodeMask = function (value) {
            if (!value) return ""
            value = value.replace(/\D/g,'')
            value = value.replace(/(\d{5})(\d)/,'$1-$2')
            return value
          }
    }
})();
