(function () {
    'use strict';

    angular
        .module('showcaseApp')
        .controller('PaymentDiscountFormModalController', PaymentDiscountFormModalController);

    function PaymentDiscountFormModalController(paymentCondition, paymentDiscount, currency, $rootScope, $localStorage, $translate, $uibModalInstance, $location, toastr, 
        UserService, StoreService, PaymentDiscountService) {

        var vm = this;
        vm.currency = currency;
        vm.currencyConfigurations = getCurrencyListConfiguration();
        vm.configutaionsPrice = vm.currencyConfigurations[currency];
        vm.discountTypeList = [
            { name: $translate.instant('global.form.discount-type.fixed-value'), key: "DISCOUNT_VALUE" },
            { name: $translate.instant('global.form.discount-type.percentage'), key: "PERCENTAGE" }
        ];
        vm.paymentDiscount = paymentDiscount ? paymentDiscount : {
            paymentCondition: paymentCondition,
            minValue: 0,
            discountValue: 0,
            discountType: "DISCOUNT_VALUE",
            isEnabled: true,
            storeId: $localStorage.defaultStore.storeId
        };
        vm.regexForValueZero = '([1-9]{1}[0-9]{0,2})(\.[0-9]{3})*\,[0-9]{2}|[0-9]{0,3}(\.[0-9]{3})*\,([0-9][1-9]|[1-9][0-9])';
        vm.discountTypes = [
            { name: $translate.instant('global.form.discount-type.percentage'), key: "PERCENTAGE" },
            { name: $translate.instant('global.form.discount-type.fixed-value'), key: "DISCOUNT_VALUE" }
        ];

        vm.height = window.innerHeight;
        
        function setDynamicDivHeight() {
            vm.height = window.innerHeight;
        }

        window.addEventListener('load', setDynamicDivHeight);
        window.addEventListener('resize', setDynamicDivHeight);

        StoreService.getStore($localStorage.defaultStore.storeId).then(function (response) {
            vm.store = response.data;
            vm.storeCategory = response.data.categoryKey;
        });

        init();

        function init() {
            vm.selectedDiscountType = vm.paymentDiscount && vm.paymentDiscount.discountType && vm.paymentDiscount.discountType == 'DISCOUNT_VALUE'
                ? { name: $translate.instant('global.form.discount-type.fixed-value'), key: "DISCOUNT_VALUE" }
                : { name: $translate.instant('global.form.discount-type.percentage'), key: "PERCENTAGE" };
        }

        vm.cancel = function () {
			$uibModalInstance.dismiss('cancel');
		}

        vm.save = function () {
            vm.formPaymentDiscount.$submitted = true;
            // if (!vm.selectedBankAccount || !vm.selectedBankAccount.codigo) {
            //     toastr.error($translate.instant('settings.erps.online-retail.modal.order.message.error-bank-account-required'));
            //     return;
            // }

            // if (!vm.selectedSeller || !vm.selectedSeller.id) {
            //     toastr.error($translate.instant('settings.erps.online-retail.modal.order.message.error-default-seller-required'));
            //     return;
            // }

            if (vm.formPaymentDiscount.$invalid) {
                return;
            }
            
            vm.paymentDiscount.discountType = vm.selectedDiscountType.key;

            console.log(vm.paymentDiscount);
            console.log(vm.formPaymentDiscount);

            if (vm.paymentDiscount.id) {
                PaymentDiscountService.update(vm.paymentDiscount.id, vm.paymentDiscount)
                    .then(
                        function(response) {
                            console.log(response);
                            toastr.success($translate.instant('settings.store.payments.payment-discount.messages.update-success'));
                            $uibModalInstance.close({ data: vm.paymentDiscount });
                        },
                        function(err) {
                            console.log(err);
                            toastr.error($translate.instant('settings.store.payments.payment-discount.messages.update-error'));
                        }
                    );
            } else {
                PaymentDiscountService.create(vm.paymentDiscount)
                    .then(
                        function(response) {
                            console.log(response);
                            toastr.success($translate.instant('settings.store.payments.payment-discount.messages.create-success'));
                            $uibModalInstance.close({ data: vm.paymentDiscount });
                        },
                        function(err) {
                            console.log(err);
                            toastr.error($translate.instant('settings.store.payments.payment-discount.messages.create-error'));
                        }
                    );
            }

            
            
            
            // $uibModalInstance.close({ data: vm.paymentDiscount });
        }
    }

})();
