(function() {
    'use strict';
    angular
        .module('showcaseApp')
        .service('NoteTagService', NoteTagService);

        NoteTagService.$inject = ['NoteTagFactory'];

    function NoteTagService(NoteTagFactory) {

        var service = {
            createNoteTag: createNoteTag,
            getNotesTags: getNotesTags,
            deleteNoteTag: deleteNoteTag,
            updateNote: updateNote
        };

        return service;

        function createNoteTag(NoteTag) {
            return NoteTagFactory.createNoteTag(NoteTag).$promise;
        }

        function getNotesTags(customerId){
            return NoteTagFactory.getNotesTags({customerId: customerId}).$promise;
        }

        function deleteNoteTag(noteTagId){
            return NoteTagFactory.deleteNoteTag({noteTagId: noteTagId}).$promise;
        }

        function updateNote(newNote){
            return NoteTagFactory.updateNote({noteId: newNote.noteId, text: newNote.text}).$promise;
        }
    }

})();
