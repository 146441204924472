(function() {
    'use strict';
    angular
        .module('showcaseApp')
        .service('MagicService', MagicService);

    MagicService.$inject = ['MagicFactory', '$localStorage'];

    function MagicService(MagicFactory, $localStorage) {

        var service = {
            trendRec: trendRec,
            boughtTogetherRec: boughtTogetherRec,
            clusterRec: clusterRec,
            svdRec: svdRec,
            addToRecommendationHistory: addToRecommendationHistory
        };

        return service;

        function trendRec(setting) {
            return MagicFactory.trendRec(setting).$promise;
        }

        function boughtTogetherRec(setting) {
            return MagicFactory.boughtTogetherRec(setting).$promise;
        }

        function clusterRec(setting) {
            return MagicFactory.cluster(setting).$promise;
        }

        function svdRec(setting) {
            return MagicFactory.SVD(setting).$promise;
        }

        function addToRecommendationHistory(recommendation) {
            return MagicFactory.recommendationHistory(recommendation).$promise;
        }
    }

})();
