(function() {
    'use strict';
    angular
        .module('showcaseApp')
        .factory('PaymentSettingsFactory', PaymentSettingsFactory);

    PaymentSettingsFactory.$inject = ['$resource'];

    function PaymentSettingsFactory($resource) {
        var resourceUrl = 'flyer/api/flyer/:flyerId';

        return $resource(resourceUrl, {}, {
            'getInstallmenstsByStore': {
                method: 'GET',
                url: '/admin/api/installment',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'saveInstallments': {
                method: 'POST',
                url: '/admin/api/installment',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            }
        });
    }

})();
