(function () {
    'user strict'

    angular
        .module('showcaseApp')
        .controller('PerformanceController', PerformanceController);

    //** NgInject */
    function PerformanceController($scope, $localStorage, $sce, $state) {

        var vm = this;

        vm.loading = true;

        window.setTimeout(function () {
            if ($state.params.report === 'hub-performance') {
                vm.dashboard = $sce.trustAsResourceUrl($localStorage.hub.performanceDashboard);
            } else if ($state.params.report === 'subscriptions') {
                vm.dashboard = $sce.trustAsResourceUrl($localStorage.hub.subscriptionsDashboard);
            } else if ($state.params.report === 'malwee-panel-multibrand') {
                vm.dashboard = $sce.trustAsResourceUrl($localStorage.hub.malweePanelMultibrandDashboard);
            } else if ($state.params.report === 'chat-performance') {
                vm.dashboard = $sce.trustAsResourceUrl($localStorage.defaultStore.chatPerformanceDashboard);
            } else if ($state.params.report === 'seller-performance') {
                vm.dashboard = $sce.trustAsResourceUrl($localStorage.defaultStore.sellerPerformanceDashboard);
            } else if ($state.params.report === 'abandoned-cart') {
                vm.dashboard = $sce.trustAsResourceUrl($localStorage.defaultStore.abandonedCartDashboard);
            } else if ($state.params.report === 'virtual-bag') {
                vm.dashboard = $sce.trustAsResourceUrl($localStorage.defaultStore.currentBagReport);
            } else if ($state.params.report === 'crm') {
                vm.dashboard = $sce.trustAsResourceUrl($localStorage.defaultStore.crmDashboard);
            } else {
                vm.dashboard = $sce.trustAsResourceUrl($localStorage.defaultStore.performanceDashboard);
            }
			vm.loading = false;

            $scope.$apply(vm.loading);
            $scope.$apply(vm.dashboard);

        }, 1500)
    }
})();
