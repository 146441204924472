(function () {
    'use strict';
    angular
        .module('showcaseApp')
        .factory('FacebookFactory', FacebookFactory);

    FacebookFactory.$inject = ['$q' ];

    function FacebookFactory($q) {
        var path = window.location.hostname.includes('showkase.online') ? 'http://admin.showkase.online/' : 'http://admin.showkase.com.br/';
        return {
            login: function () {
                var deferred = $q.defer();
                FB.login(function (response) {
                    if (response.status === 'connected') {
                        deferred.resolve(response);
                    } else {
                        deferred.reject('Error occured');
                    }
                }, {
                    scope: 'email,business_management,ads_management,pages_show_list,catalog_management,instagram_manage_insights,instagram_basic,read_insights,pages_messaging,instagram_manage_messages, pages_manage_metadata, ads_read, pages_read_engagement',
                    auth_type: 'reauthorize',
                    enable_profile_selector: true,
                    return_scopes: true
                });
                return deferred.promise;
            },
            addPayment: function (id) {
                var deferred = $q.defer();
                FB.ui(function (response) {
                    if (response.status === 'connected') {
                        deferred.resolve(response);
                    } else {
                        deferred.reject('Error occured');
                    }
                }, {
                    account_id: id,
                    display: 'popup',
                    method: 'ads_payment'
                });
                return deferred.promise;
            },
            status: function () {
                var deferred = $q.defer();
                FB.getLoginStatus(function (response) {
                    if (response.status === 'connected') {
                        // var uid = response.authResponse.userID;
                        deferred.resolve(response);
                    } else if (response.status === 'authorization_expired') {
                        deferred.reject('Token Expirado');
                    } else if (response.status === 'not_authorized') {
                        deferred.reject('Não autorizado');
                    } else {
                        deferred.reject('Erro Desconhecido');
                    }
                });
                return deferred.promise;
            },
            business: function (nextPage) {
                var deferred = $q.defer();
                // var nextPage = nextPage ? '&after=' + nextPage : '';
                FB.api('/me/businesses?limit=100', {
                    after: nextPage,
                    fields: 'name, profile_picture_uri, id'
                }, function (response) {
                    if (response.error) {
                        return deferred.reject(response.error);
                    }
                    deferred.resolve(response);
                });
                return deferred.promise;
            },
            getBusiness: function (token, id) {
                var deferred = $q.defer();
                FB.api(id, {
                    access_token: token,
                    fields: 'name, profile_picture_uri'
                }, function (response) {
                    if (response.error) {
                        return deferred.reject(response.error)
                    }
                    deferred.resolve(response);
                });
                return deferred.promise;
            },
            getPage: function (token, id) {
                var deferred = $q.defer();
                FB.api(id, {
                    access_token: token,
                    fields: 'page_token,access_token,picture{url},name'
                }, function (response) {
                    if (response.error) {
                        return deferred.reject(response.error)
                    }
                    deferred.resolve(response);
                });
                return deferred.promise;
            },
            getInsagram: function (token, id) {
                var deferred = $q.defer();
                FB.api(id, {
                    access_token: token,
                    fields: 'name, profile_picture_uri'
                }, function (response) {
                    if (response.error) {
                        return deferred.reject(response.error)
                    }
                    deferred.resolve(response);
                });
                return deferred.promise;
            },
            businessPages: function (businessId) {
                var deferred = $q.defer();
                FB.api('/' + businessId + '/owned_pages', {
                    fields: 'id, cover, name, instagram_business_account{id,name,username,profile_picture_url}'
                }, function (response) {
                    deferred.resolve(response);
                });
                return deferred.promise;
            },
            instagramAccounts: function (businessId) {
                var deferred = $q.defer();
                FB.api('/' + businessId + '/instagram_business_accounts', {
                    fields: 'id,username,profile_picture_url'
                }, function (response) {
                    deferred.resolve(response);
                });
                return deferred.promise;
            },
            adAccounts: function (businessId) {
                var deferred = $q.defer();
                FB.api('/' + businessId + '/owned_ad_accounts', {
                    fields: 'business_name,balance,name,owner,currency,funding_source,funding_source_details,created_time,account_status,account_id'
                }, function (response) {
                    deferred.resolve(response);
                });
                return deferred.promise;
            },
            searchCity: function (query, token) {
                var deferred = $q.defer();
                FB.api('/search?location_types=["city"]&type=adgeolocation&q=' + query, {access_token: token },
                function (response) {
                    deferred.resolve(response.data);
                });
                return deferred.promise;
            },
            createCatalog: function (businessId, catalogName) {
                var deferred = $q.defer();
                FB.api('/' + businessId + '/owned_product_catalogs', 'POST', {
                    name: catalogName
                }, function (response) {
                    if (response && response.id) {
                        deferred.resolve(response);
                    } else {
                        deferred.reject('Não autorizado, possivelmente você  não tem permissão para criar um catálogo nessa conta.');
                    }
                });
                return deferred.promise;
            },
            createAdAccount: function (businessId, token) {
                var deferred = $q.defer();
                FB.api('/' + businessId + '/adaccount'+ '?access_token='+ token, 'POST', {
                    name: "Showkase - Ad Account" + '_' + moment().format("DD-MM-YYYY_HH:MM"),
                    end_advertiser: "UNFOUND",
                    media_agency: "UNFOUND",
                    timezone_id: 25,
                    currency: "BRL",
                    partner: "NONE",
                    access_token: token
                }, function (response) {
                    if (response && response.id) {
                        deferred.resolve(response);
                    } else {
                        deferred.reject('Erro ao criar conta.');
                    }
                });
                return deferred.promise;
            },
            addFeed: function (catalogId, feedUrl) {
                var deferred = $q.defer();
                FB.api('/' + catalogId + '/product_feeds', 'POST', {
                    name: 'showkase_catalog',
                    default_currency: 'BRL',
                    schedule: {
                        interval: 'HOURLY',
                        url: path+"flyer/api/showcase/rss?domain=" + feedUrl,
                        interval_count: 1
                    }
                }, function (response) {
                    console.log('addFeed: ', response);
                    if (response && response.id) {
                        deferred.resolve(response);
                    } else {
                        deferred.reject('Não autorizado');
                    }
                });
                return deferred.promise;
            },
            me: function () {
                var deferred = $q.defer();
                FB.api('/me', {
                    fields: 'id, first_name, last_name, picture'
                }, function (response) {
                    if (response.status === 'connected') {
                        deferred.resolve(response);
                    } else {
                        deferred.reject('Error occured');
                    }
                });
                return deferred.promise;
            },
            logout: function () {
                var deferred = $q.defer();
                FB.logout(function (response) {
                    deferred.resolve(response);
                });
                return deferred.promise;
            }
        }
    }
})();
