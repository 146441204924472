(function () {
    'use strict';

    angular
        .module('showcaseApp')
        .controller('FBCampaignModalController', FBCampaignModalController);

        FBCampaignModalController.$inject = ['$uibModalInstance', '$timeout',
        '$localStorage', 'FacebookService', 'ShowcasesService', '$translate',
        '$state', 'toastr', '$q', '$scope', 'BusinessIntegrationService', 'data'
    ];

    function FBCampaignModalController($uibModalInstance, $timeout,
        $localStorage, FacebookService, ShowcasesService, $translate,
         $state, toastr, $q, $scope, BusinessIntegrationService, data) {
        var vm = this;
        vm.catalogName = "";
        vm.project = {};
        vm.project.campaignType = 'cpm_message';
        vm.project.total = 0;
        vm.selectedCity;
        vm.checkout = {};
        vm.facebook = {
            catalogGenerated: false,
            catalog: {
                id: ''
            },
            feed: {
                id: ''
            },
            connected: false
        };
        vm.isBussy = false;
        vm.userBusinesses = [];
        vm.userInstagramAccounts = [];
        vm.user = {};
        vm.currentStep = 0;
        vm.totalOfSteps = 2;
        vm.selectedBusiness;
        vm.selectedAdAccount;
        vm.selectedInstagram;
        vm.selectedBusinessPage;
        vm.facebookData = data;

        vm.showcase = null;
        vm.searchText = null;

        vm.confirm = function () {
            $uibModalInstance.close(true);
        };

        vm.closeModal = function () {
            $uibModalInstance.close(false);
        };

        vm.fbLogin = function () {
            vm.processing = true;
            FacebookService.login().then(updateStatus);
        };

        vm.fbLogout = function () {
            FacebookService.logout().then(updateStatus);
        };

        vm.checkFbStatus = function () {
            vm.processing = true;
            FacebookService.status()
            .then(updateStatus)
            .catch(function(err){
            }).finally(function(err){
                vm.processing = false;
            });
        };

        vm.loadUserData = function () {
            vm.processing = true;
            if (vm.facebook.connected) {
                FacebookService.me().then(function (data) {
                    vm.user = data;
                    vm.processing = false;
                })
            }
        };

        vm.loadShowcases = function () {
            vm.processing = true;
            ShowcasesService.getPublishedShowcaseByStoreId().then(
                onSuccess,
                onError
            );

            function onSuccess(res) {
                vm.showcase = res.data;
                console.log('vm.facebookData:', vm.facebookData)
                vm.processing = false;
            }

            function onError(response) {
                vm.processing = false;
                vm.busy = false;
                console.log('vm.facebookData:', vm.facebookData)
                if (response.headers('X-flyerApp-alert') == 'userHasNoStore') {
                    toastr.error(
                        $translate.instant('showcases.messages.errors.noStore')
                    );
                }
            }
        };

        vm.loadShowcases();

        vm.goToShowcases = function () {
            $state.go('showcases');
            $uibModalInstance.close(true);
        };

        vm.loadBusiness = function () {
            vm.selectedBusiness = null;
            vm.processing = true;
            FacebookService.business().then(function (res) {
                vm.userBusinesses = res.data;
            }).catch(function(err) {
                console.log("ERR::", err);
            }).finally(function(){
                vm.processing = false;
            });
        };

        vm.loadBusinessInsta = function () {
            vm.userInstagramAccounts = null;
            vm.processing = true;
            FacebookService.instagramAccounts(vm.selectedBusiness.id).then(function (res) {
                vm.userInstagramAccounts = res.data;
                vm.processing = false;
            }).catch(function(err) {
                console.log("ERR::", err);
            }).finally(function(){
                vm.processing = false;
            });
        };

        vm.querySearch = function (query) {
            vm.deferred = $q.defer();
            FacebookService.searchCity(query, vm.facebookData.accessToken)
                .then(function (res) {
                    vm.deferred.resolve(res)
                });
            return vm.deferred.promise;
        }

        /**
         * On Init
         */
        $timeout(function () {
            vm.checkFbStatus();
        }, 500);

        vm.next = function () {

            if (vm.currentStep === 0) {
                console.log("FINAL")
                vm.loadPreviewInfo();

            }
            vm.currentStep < vm.totalOfSteps ?
                (vm.currentStep = vm.currentStep + 1) : '';
        };

        vm.previous = function () {
            vm.currentStep !== 0 ? (vm.currentStep = vm.currentStep - 1) : '';
        };

        vm.nextDisabled = function () {
            switch (vm.currentStep) {
                case 0:
                    return (vm.selectedCity && vm.project.value && vm.project.days && !vm.project.value < 3 && !vm.project.days < 8) ? false : true;
                case 1:
                    return false;
                default:
                    return false;
            }
        };

        vm.previousDisabled = function () {
            switch (vm.currentStep) {
                case 2:
                    return vm.facebook.catalog.id ? true : false;
                case 3:
                    return vm.campaingResult ? true : false;
                case 4:
                    return true;

                default:
                    return false;
            }
        };

        $scope.$watch('vm.project.days', function (newValue, oldValue) {
            vm.project.total = vm.project.value > 0 ? vm.project.value * vm.project.days : 0;
        });
        $scope.$watch('vm.project.value', function (newValue, oldValue) {
            vm.project.total = vm.project.value > 0 ? vm.project.value * vm.project.days : 0;
        });

        vm.getFormattedPrice = function (price) {
            price = price.toString();
            return price.substring(0, price.length - 2) + ',' + price.substring(price.length - 2, price.length);
        }

        function updateStatus(response) {
            if (response.status === 'connected') {
                vm.facebook.connected = true;
                vm.facebook.data = response;
                vm.loadBusiness();
                vm.loadUserData();
            } else {
                vm.facebook.connected = false;
                vm.userBusinesses = [];
                vm.facebook.data = {};
            }

        }
        vm.lockCreateCampaign = function () {

            if ( !vm.selectedCity || !vm.project.value || !vm.project.days || !vm.project.total || !vm.project.campaignType) {
                return status = true;
            }

            return false;
        }

        vm.modalTitle = function () {
            switch (vm.currentStep) {
                case 0:
                    return {title: 'Configure sua campanha', subTitle: 'Insira as informações que serão usadas na sua campanha'};
                case 1:
                    return {title: 'Confirme as informações', subTitle: 'Revise as informações antes de seguir com pagamento'};
                default:
                    return false;
            }
        };

        vm.loadBusinessPages = function () {
            vm.selectedBusinessPage = null;
            vm.processing = true;
            FacebookService.getPage(vm.selectedBusiness.id).then(function (res) {
                vm.selectedBusinessPage = res;
                vm.processing = false;
            }).catch(function(err) {
                console.log("ERR::", err);
            }).finally(function(){
                vm.processing = false;
            });
        };

        vm.loadPreviewInfo = function() {
            vm.processing = true;
            FacebookService.getBusiness(vm.facebookData.accessToken, vm.facebookData.business.businessId).then(function (res) {
                vm.selectedBusiness = res;
            }).catch(function(err) {
                console.log("ERR::", err);
            }).finally(function(){
                vm.processing = false;
            });

            FacebookService.getPage(vm.facebookData.accessToken, vm.facebookData.page.pageId).then(function (res) {
                vm.selectedBusinessPage = res;
                vm.processing = false;
            }).catch(function(err) {
                console.log("ERR::", err);
            }).finally(function(){
                vm.processing = false;
            });
        }

        vm.createCampaign = function () {
            vm.isBuying = true;

            // Commons things to do
            vm.checkout.catalogId = vm.facebook.catalog.id;
            vm.checkout.feedId = vm.facebook.feed.id;
            vm.checkout.cityKey = vm.selectedCity.key;
            vm.checkout.businessId = vm.selectedBusiness.id;
            vm.checkout.pageId = vm.selectedBusinessPage.id;
            vm.checkout.catalogName = vm.catalogName;
            vm.checkout.storeName = $localStorage.defaultStore.storeNickname;
            vm.checkout.campaignValue = vm.project.total;
            vm.checkout.campaignDailyValue = vm.project.value;
            vm.checkout.campaignDays = vm.project.days;
            vm.checkout.campaignType = vm.project.campaignType;
            vm.checkout.token = vm.facebookData.accessToken;

			var url = window.location.host;
			vm.checkout.storeUrl = url.replace("admin", vm.checkout.storeName);

            BusinessIntegrationService.createCampaign(vm.checkout)
                    .then(success, error)
                    .catch(function (err) {
                        error(err);
                    });
        }

        function success(result) {
            vm.campaingResult = result.data;
            vm.isBuying = false;
            vm.closeModal();
        }

        function error(result) {
            vm.isBuying = false;
            if (result.data && result.data.headers && result.data.headers["x-flyerapp-alert"]) {
                toastr.error(result.data.headers["x-flyerapp-alert"]);
            } else {
                toastr.error($translate.instant('integrations.facebookBusiness.errors.createCampain'));
            }
        }

        vm.loadAdAcconunts = function () {
            vm.selectedAdAccount = null;
            vm.processing = true;
            FacebookService.adAccounts(vm.selectedBusiness.id).then(function (res) {
                vm.userAdAccounts = res.data;
                vm.processing = false;
            });
        };

        vm.createAdAccount = function () {
            vm.processing = true;
            FacebookService.createAdAccount(vm.selectedBusiness.id).then(function (res) {
                vm.processing = false;
                vm.loadAdAcconunts();
            }).catch(function(err) {
                vm.processing = false;
                toastr.error($translate.instant('integrations.facebookBusiness.errors.createCampain'));
            });
        };

        vm.addPaymentMethod = function() {
            FB.ui(
                {
                    account_id: vm.selectedAdAccount.account_id,
                    display: 'popup',
                    method: 'ads_payment',
                },   // callback
                function(response) {
                    if (response && !response.error_message) {
                        toastr.success("Adicionado com sucesso!");
                    } else {
                        toastr.error("Erro ao adicionar meio de pagamento!");
                    }
              });
        }

    }
})();
