(function(){
    'use strict'

    angular.module('showcaseApp')
    .factory('StoreInfoFactory', StoreInfoFactory);

    /** @ngInject */
    function StoreInfoFactory($localStorage){

        var factory = {
            getStoreId: getStoreId
        }

        function getStoreId(){
            return $localStorage.defaultStore.storeId;
        }

        return factory;
    }
})();