(function() {
    'use strict';

    angular
        .module('showcaseApp')
        .controller('MessageModalSimpleController', MessageModalSimpleController);

    function MessageModalSimpleController($uibModalInstance, modalTitle, modalMessage, 
                                    buttonConfirmText, buttonCancelText, buttonConfirmAction) {
        var vm = this;

        vm.modalTitle = modalTitle;
        vm.modalMessage = modalMessage;
        vm.buttonConfirmText = buttonConfirmText;
        vm.buttonCancelText = buttonCancelText;
        vm.buttonConfirmAction = action;

        vm.confirm = function() {
            $uibModalInstance.close(true);
        }

        vm.closeModal = function() {
            $uibModalInstance.dismiss(true);
        }

        function action(){
            buttonConfirmAction();
            vm.confirm();
        }




        

    }

})();
