(function() {
    'use strict';
    angular
        .module('showcaseApp')
        .factory('BroadcastListFactory', BroadcastListFactory);

    BroadcastListFactory.$inject = ['$resource'];

    function BroadcastListFactory($resource) {
        var resourceBroadcastList =  'admin/api/wati/broadcast-list';
        var resourceGetContacts = resourceBroadcastList + '/contacts';
        var resourceImportContacts = resourceBroadcastList + '/import-contacts';
        var resourceAddBroadcastList = resourceBroadcastList + '/insert';
        var resourceGetBroadcastDashboard = resourceBroadcastList + '/broadcast-dashboard'

        return $resource(resourceBroadcastList, {}, {
            'getContacts': {
                method: 'POST',
                url: resourceGetContacts,
                isArray: false,
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data){
                        response = angular.fromJson(data);
                    }
                    return response;
                }
            },
            'importContacts':{
                method: 'POST',
                url: resourceImportContacts,
                transformRequest: angular.identity,
                headers: {'Content-Type': undefined},
                isArray: true
            },
            'addBroadcastList':{
                method: 'POST',
                url: resourceAddBroadcastList,
                isArray: false,
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data){
                        response = angular.fromJson(data);
                    }
                    return response;
                }
            },
            'getBroadcastDashboard': {
                method: 'POST',
                url: resourceGetBroadcastDashboard,
                isArray: false,
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data){
                        response = angular.fromJson(data);
                    }
                    return response;
                }
            }
        });
    }

})();
