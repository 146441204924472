'use strict';

/**
 * @ngdoc function
 * @name kscFinaluserApp.controller:MainCtrl
 * @description
 * # MainCtrl
 * Controller of the kscFinaluserApp
 */
angular.module('showcaseApp')
    .service('TimeService', function() {
        return {
            calcTimeDifference: calcTimeDifference
        }

        function calcTimeDifference(expirationDate) {
            var now = new Date();
            var expiration = new Date(expirationDate);
            var ms = moment(expiration, "DD/MM/YYYY HH:mm:ss").diff(moment(now, "DD/MM/YYYY HH:mm:ss"));
            var d = moment.duration(ms);
            return (d.days() + "d " + d.minutes() + "m " + d.seconds() + "s");
        }

    });
