(function() {
    'use strict';

    angular
        .module('showcaseApp')
        .controller('StoreManagementEditImageController', StoreManagementEditImageController);

    StoreManagementEditImageController.$inject = ['$rootScope', '$scope', 'StoreService', '$uibModalInstance', 'store', 'toastr', '$translate'];

    function StoreManagementEditImageController($rootScope, $scope, StoreService, $uibModalInstance, store, toastr, $translate) {
        var vm = this;
        vm.store = store;

        vm.imageUpload = '';
        vm.croppedImage = '';
        vm.imageSize = 500;
        vm.imageCroppedActive = false;

        var handleFileSelect = function(evt) {
           var file = evt.currentTarget.files[0];
           var reader = new FileReader();
           reader.onload = function (evt) {
             $scope.$apply(function($scope){
               vm.imageUpload = evt.target.result;
               vm.imageCroppedActive = true;
             });
           };
           reader.readAsDataURL(file);
        };

       vm.openInput = function(evt) {
           document.getElementById('fileInput').click();
       }

       vm.closeModal = function() {
           $uibModalStack.dismissAll();
       }

       vm.save = function() {
           if(!vm.croppedImage) {
            toastr.error($translate.instant('store-management.messages.update.missing-img'));
           } else {
             vm.isSaving = true;
             StoreService.saveStoreImage(dataURItoBlob(vm.croppedImage)).then(function (response, error) {
              vm.store.addImageFileDTO = response.data;
               StoreService.updateStore(vm.store).then(function (data) {
                   $rootScope.$broadcast('storeChangeSuccess');
                   vm.isSaving = false;
                   $uibModalInstance.close(data);
               });
             });
           }
       }

       vm.closeModal = function() {
           $uibModalInstance.close();
       }

       vm.initModal = function() {
            angular.element(document.querySelector('#fileInput')).on('change',handleFileSelect);
            // vm.imageUpload = vm.store.logo;
       }

       function dataURItoBlob(dataURI) {
           // convert base64/URLEncoded data component to raw binary data held in a string
           var byteString;
           if (dataURI.split(',')[0].indexOf('base64') >= 0)
               byteString = atob(dataURI.split(',')[1]);
           else
               byteString = unescape(dataURI.split(',')[1]);

           // separate out the mime component
           var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

           // write the bytes of the string to a typed array
           var ia = new Uint8Array(byteString.length);
           for (var i = 0; i < byteString.length; i++) {
               ia[i] = byteString.charCodeAt(i);
           }

           return new Blob([ia], {
               type: mimeString
           });
       }

    }

})();
