(function () {
    'use strict';

    angular
        .module('showcaseApp')
        .controller('AddManagingPartnerController', AddManagingPartnerController);

    function AddManagingPartnerController(partnerIndex, partner, $scope, $translate, $uibModalInstance, $timeout, toastr, PaymentSetupService) {

        var vm = this;
    
        vm.isNew = !partner;
        vm.occupations = PaymentSetupService.getProfessionalOccupations();
        vm.partner = partner ? partner : {};
        vm.partnerIndex = partnerIndex;
        if  (!partner) {
            vm.partner.address = {};
        }

        vm.height = window.innerHeight;
        
        function setDynamicDivHeight() {
            vm.height = window.innerHeight;
        }

        window.addEventListener('load', setDynamicDivHeight);
        window.addEventListener('resize', setDynamicDivHeight);

        init();

        function init() {
        }

        vm.cancel = function () {
			$uibModalInstance.dismiss('cancel');
		}

        vm.addPartner = function () {
            var data = {
                partner: vm.partner,
                partnerIndex: vm.partnerIndex
            }
            $uibModalInstance.close({ data: data });
        }

        angular.element(function () {
            $timeout(function(){
                initAutocomplete();
            }, 2000);
        });

        function initAutocomplete() {
            // Crie o campo de entrada para o autocomplete
            var input = document.getElementById('autocomplete');
            // Inicialize o autocomplete passando o campo de entrada e configurando os tipos de lugares que deseja sugerir
            var autocomplete = new google.maps.places.Autocomplete(input, {
              types: ['geocode'], // Pode alterar para ['(regions)'] para sugerir áreas mais amplas
              componentRestrictions: { country: 'br' } // Restringe as sugestões ao Brasil
            });
          

            // Adicione um listener para lidar com o evento de seleção
            autocomplete.addListener('place_changed', function() {
                var place = autocomplete.getPlace();
                
                if (!place.geometry) {
                    // Se o usuário inseriu um lugar que não existe, exiba uma mensagem
                    console.log("Nenhuma informação disponível para o local inserido: '" + place.name + "'");
                    return;
                }
          
                // Faça algo com o resultado selecionado
                populateAddressFields(place);
            });
          }
          

        function populateAddressFields(result) {
            var addressComponents = result.address_components;
            for (var i = 0; i < addressComponents.length; i++) {
              var component = addressComponents[i];
              var types = component.types;
              for (var j = 0; j < types.length; j++) {
                var type = types[j];
                if (type === "route") {
                    vm.partner.address.street = component.long_name;
                } else if (type === "sublocality_level_1" || type === "locality") {
                    vm.partner.address.neighborhood = component.long_name;
                } else if (type === "administrative_area_level_2") {
                    vm.partner.address.city = component.long_name;
                } else if (type === "administrative_area_level_1") {
                    vm.partner.address.state = component.short_name;
                } else if (type === "country") {
                    vm.partner.address.country = component.long_name;
                } else if (type === "postal_code") {
                    vm.partner.address.zipcode = component.long_name;
                } else if (type === "street_number") {
                    vm.partner.address.streetNumber = component.long_name;
                }
              }
            }
            $scope.$apply();
        }
    }

})();
