(function() {
    'use strict';
    angular
        .module('showcaseApp')
        .factory('ChatCommerceFactory', ChatCommerceFactory);

    ChatCommerceFactory.$inject = ['$resource'];

    function ChatCommerceFactory($resource) {

        var CHAT_COMMERCE = 'admin/api/chat-commerce';
        var CHAT_COMMERCE_CONNECT_CHAT = CHAT_COMMERCE + '/connect-chat';
        var CHAT_COMMERCE_SYNCHRONIZE_CHAT = CHAT_COMMERCE + '/sync-chat';
        var CHAT_COMMERCE_GET_CONVERSATIONS = CHAT_COMMERCE + '/get-conversations';
        var CHAT_COMMERCE_GET_CONVERSATION_BY_CONVERSATION_ID = CHAT_COMMERCE + '/get-conversation/:conversationId';
        var CHAT_COMMERCE_GET_CONVERSATIONS_BY_FILTER = CHAT_COMMERCE_GET_CONVERSATIONS + '/filter';

        return $resource(CHAT_COMMERCE, {}, {
            'getConversations': {
                method: 'POST',
                url: CHAT_COMMERCE_GET_CONVERSATIONS,
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data){
                        response.data = angular.fromJson(data);
                    } else{
                        response.data = data;
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'getConversationsByFilter': {
                method: 'POST',
                url: CHAT_COMMERCE_GET_CONVERSATIONS_BY_FILTER,
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data){
                        response.data = angular.fromJson(data);
                    } else{
                        response.data = data;
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'synchronizeChat': {
                method: 'POST',
                url: CHAT_COMMERCE_SYNCHRONIZE_CHAT,
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data){
                        response.data = angular.fromJson(data);
                    } else{
                        response.data = data;
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'connectToChat': {
                method: 'POST',
                url: CHAT_COMMERCE_CONNECT_CHAT
            },
            'getConversationByConversationId': {
                method: 'GET',
                url: CHAT_COMMERCE_GET_CONVERSATION_BY_CONVERSATION_ID
            }
        });
    }

})();
