(function() {
    'use strict';

    angular
        .module('showcaseApp')
        .factory('PaymentDiscountService', PaymentDiscountService);

    PaymentDiscountService.$inject = ['PaymentDiscountFactory'];

    function PaymentDiscountService(PaymentDiscountFactory) {

        var service = {
            getById: getById,
            getByStoreId: getByStoreId,
            create: create,
            update: update,
            remove: remove
        };

        return service;

        function getById(id) {
            return PaymentDiscountFactory.getById({id: id}).$promise;
        }

        function getByStoreId(storeId) {
            return PaymentDiscountFactory.getByStoreId({storeId: storeId}).$promise;
        }

        function create(paymentDiscount) {
            return PaymentDiscountFactory.create(paymentDiscount).$promise;
        }

        function update(id, paymentDiscount) {
            return PaymentDiscountFactory.update({id: id}, paymentDiscount).$promise;
        }

        function remove(id) {
            return PaymentDiscountFactory.remove({id: id}).$promise;
        }
    }
})();
