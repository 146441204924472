(function () {
    'user strict'

    angular
        .module('showcaseApp')
        .controller('MarketingSettingsController', MarketingSettingsController);

    function MarketingSettingsController(toastr, $state, $localStorage, $translate, Constants,
                                    ModalFactory, StoreService, ConfirmActionModalFactory, WatiService,
                                         AbandonedCartNotificationService) {

        var vm = this;

        vm.notifyTimes = notifyTimes();
        vm.isMobile = detectar_mobile();

        vm.store = $localStorage.defaultStore;
        vm.form = {
            notifyTime: {
                code: "1",
                time: 15,
                unity: 'MINUTES'
            },
            template: null,
            discountCoupon: null
        };
        vm.templates = [];
        vm.loadingTemplates = true;
        vm.notifications = [];
        vm.templateHasCoupon = false;
        vm.isSaving = false;
        vm.activeForm = false;

        vm.WATICredits = undefined;
        vm.isWATIConnected = false;

        init();

        function init() {
            getTemplates();
            getNotifications();
            setNotifyTimeLabel();
            getStoreInfo();
            getWATIConnection();
            getWATICredits();
        }

        function getWATIConnection() {
            WatiService.getConfigByCurrentStore().then(function (result) {
                getWATICredits();
                if (result.data.id) {
                    vm.isWATIConnected = true;
                } else {
                    vm.isWATIConnected = false;
                }
            })
        }

        function getStoreInfo() {
            return StoreService.getStore($localStorage.defaultStore.storeId).then(function (response) {
                vm.store = response.data;
                setBuyWATICreditsMessage();
                setConnectWATIMessage();
            });
        }

        function getWATICredits() {
            WatiService.getWATICredits().then(function (result) {
                if (result && result.creditCustomer) {
                    vm.WATICredits = result.creditCustomer.credit;
                }
            });
        }

        vm.selectTemplate = function () {
            if (vm.form.template && vm.form.template.customParams) {
                var findCouponParam = vm.form.template.customParams.find(function (param) {
                    return param.paramName === 'discount_code';
                });
                vm.templateHasCoupon = !!findCouponParam;
            }
        }

        vm.openCreateNotificationForm = function () {
            vm.activeForm = !vm.activeForm;
            scrollToBottom();
        }

        vm.cancelForm = function() {
            vm.activeForm = false;
            refreshNotifications();
        }

        function setNotifyTimeLabel() {
            vm.notifyTimes.forEach(function (item) {
               item.label = getNotifyOptionLabel(item);
            });
        }

        function getNotifyOptionLabel(item) {
            return item.time + ' ' + $translate.instant('settings.marketing.abandoned-cart.form.' + item.unity.toLowerCase());
        }

        function getTemplates() {
            WatiService.searchTemplateMessagesByText('ca_').then(function (result) {
                if (result && result.data) {
                    vm.loadingTemplates = false;
                    vm.templates = result.data.items;
                    vm.form.template = vm.templates[0];
                    vm.selectTemplate();
                }
            }).catch(function (err) {
                vm.loadingTemplates = false;
            });
        }

        function getNotifications() {
            AbandonedCartNotificationService.findAllNotifications().then(function (result) {
                vm.notifications = _.sortBy(result.data, ['id']);
                vm.notifications.forEach(function (notification) {
                    setTimeAndUnity(notification);
                });
            });
        }

        vm.getNotificationById = function(id) {
            AbandonedCartNotificationService.findNotificationById(id).then(function (result) {
                vm.form = result.data;
                vm.form.template = vm.templates.find(function (template) {
                    return template.id === vm.form.templateId;
                });

                vm.form.notifyTime = vm.notifyTimes.find(function (notifyTime) {
                    return String(notifyTime.code) === vm.form.notifyTimeCode;
                });

                vm.selectTemplate();
                vm.activeForm = true;
                scrollToBottom();
            });
        }

        vm.createUpdateAbandonedCartNotifier = function () {
            if (vm.form.id){
                updateAbandonedCartNotifier();
            } else {
                createAbandonedCartNotifier();
            }
        }

        function createAbandonedCartNotifier() {
            var body = {
                isEnabled: true,
                templateId: vm.form.template.id,
                notifyTimeCode: vm.form.notifyTime.code,
                discountCoupon: vm.form.discountCoupon
            }
            ModalFactory.openWarningModal(
                null,
                $translate.instant('settings.marketing.abandoned-cart.create-modal.title'),
                $translate.instant('settings.marketing.abandoned-cart.create-modal.small-text'),
                $translate.instant('settings.marketing.abandoned-cart.create-modal.warning-text'),
                $translate.instant('entity.action.continue'),
                $translate.instant('entity.action.cancel'),
                function () {
                    AbandonedCartNotificationService.saveNotification(body).then(function (result) {
                        var newNotification = result.data;
                        setTimeAndUnity(newNotification);
                        vm.notifications.push(newNotification);
                        refreshNotifications();
                        toastr.success($translate.instant('settings.marketing.abandoned-cart.success-to-create'));
                    }).catch(function (err) {
                        vm.isSaving = false;
                        toastr.error($translate.instant('settings.marketing.abandoned-cart.failed-to-create'));
                    });
                }
            );
        }

        vm.disableNotification = function (notification) {
            var body = {
                id: notification.id,
                isEnabled: notification.isEnabled,
                templateId: notification.templateId,
                notifyTimeCode: notification.notifyTimeCode,
                discountCoupon: notification.discountCoupon
            }
            var action = notification.isEnabled ? 'active' : 'disable';

            ModalFactory.openWarningModal(
                null,
                $translate.instant('settings.marketing.abandoned-cart.' + action + '-modal.title'),
                $translate.instant('settings.marketing.abandoned-cart.' + action + '-modal.small-text'),
                $translate.instant('settings.marketing.abandoned-cart.' + action + '-modal.warning-text'),
                $translate.instant('entity.action.continue'),
                $translate.instant('entity.action.cancel'),
                function () {
                    vm.isSaving = true;
                    AbandonedCartNotificationService.editNotification(notification.id, body)
                        .then(function (result) {
                            vm.isSaving = false;
                            toastr.success($translate.instant('settings.marketing.abandoned-cart.success-to-' + action));
                        })
                        .catch(function (err) {
                            vm.isSaving = false;
                            notification.isEnabled = !notification.isEnabled;
                            toastr.error($translate.instant('settings.marketing.abandoned-cart.failed-to-' + action));
                        });
                },
                function () {
                    notification.isEnabled = !notification.isEnabled;
                }
            );
        }

        function updateAbandonedCartNotifier() {
            var body = {
                id: vm.form.id,
                isEnabled: vm.form.isEnabled,
                templateId: vm.form.template.id,
                notifyTimeCode: vm.form.notifyTime.code,
                discountCoupon: vm.form.discountCoupon
            }

            ModalFactory.openWarningModal(
                null,
                $translate.instant('settings.marketing.abandoned-cart.update-modal.title'),
                $translate.instant('settings.marketing.abandoned-cart.update-modal.small-text'),
                $translate.instant('settings.marketing.abandoned-cart.update-modal.warning-text'),
                $translate.instant('entity.action.continue'),
                $translate.instant('entity.action.cancel'),
                function () {
                    vm.isSaving = true;
                    AbandonedCartNotificationService.editNotification(vm.form.id, body).then(function (result) {
                        var newNotification = result.data;
                        setTimeAndUnity(newNotification);
                        var index = _.findIndex(vm.notifications, {id: newNotification.id});
                        vm.notifications.splice(index, 1, newNotification);
                        refreshNotifications();
                        toastr.success($translate.instant('settings.marketing.abandoned-cart.success-to-update'));
                    }).catch(function (err) {
                        vm.isSaving = false;
                        toastr.error($translate.instant('settings.marketing.abandoned-cart.failed-to-update'));
                    });
                }
            );
        }

        function refreshNotifications() {
            vm.isSaving = false;
            vm.activeForm = false;
            vm.form = {
                notifyTime: {
                    code: "1",
                    time: 15,
                    unity: 'MINUTES'
                },
                template: vm.templates[0],
                discountCoupon: null
            };
            vm.notifications = _.sortBy(vm.notifications, ['id']);
            vm.selectTemplate();
        }

        function setTimeAndUnity(notification) {
            var notifyItemInfo = _.find(vm.notifyTimes, function (notifyTime) {
                return notification.notifyTimeCode === String(notifyTime.code);
            });

            notification.time = notifyItemInfo.time;
            notification.unity = $translate.instant('settings.marketing.abandoned-cart.form.' + notifyItemInfo.unity.toLowerCase());
        }

        vm.deleteNotification = function (id) {
            ModalFactory.openWarningModal(
                null,
                $translate.instant('settings.marketing.abandoned-cart.delete-modal.title'),
                $translate.instant('settings.marketing.abandoned-cart.delete-modal.small-text'),
                $translate.instant('settings.marketing.abandoned-cart.delete-modal.warning-text'),
                $translate.instant('entity.action.continue'),
                $translate.instant('entity.action.cancel'),
                function () {
                    AbandonedCartNotificationService.removeNotification(id).then(function (result) {
                        getNotifications();
                        toastr.success($translate.instant('settings.marketing.abandoned-cart.success-to-remove'));
                    }).catch(function (err) {
                        toastr.error($translate.instant('settings.marketing.abandoned-cart.failed-to-remove'));
                    });
                }
            );
        }

        function scrollToBottom() {
            setTimeout(function() {
                var element = document.getElementById('abandoned-cart-form');
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth', block: 'center' });
                }
            }, 100);
        }

        function setBuyWATICreditsMessage() {
            var storeName = vm.store.name;
            var message = $translate.instant('settings.marketing.abandoned-cart.buy-credits-message',
                { storeName: storeName });
            vm.buyWATICreditsMessage = 'https://api.whatsapp.com/send?phone=+558398931539&text='.concat(message);
        }

        function setConnectWATIMessage() {
            var storeName = vm.store.name;
            var message = $translate.instant('settings.marketing.abandoned-cart.connect-wati-request',
                { storeName: storeName });
            vm.connectWATIMessage = 'https://api.whatsapp.com/send?phone=+558398931539&text='.concat(message);
        }
    }
})();
