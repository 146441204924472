(function() {
    'use strict';

    angular
        .module('showcaseApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('flyer-categorys', {
                parent: 'admin',
                url: '/flyer-categorys',
                data: {
                    authorities: ['STORE_READ'],
                    pageTitle: 'categorys.flyer.home.title'
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/admin/category/categorys-table.html',
                        controller: 'FlyerCategorysController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('categorys');
                        return $translate.refresh();
                    }]

                }
            })
    }
})();
