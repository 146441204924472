(function () {
    'user strict'

    angular
        .module('showcaseApp')
        .controller('ReportsDashboardController', ReportsDashboardController);

    //** NgInject */
    function ReportsDashboardController($timeout) {

        var vm = this;
        vm.onTabSelected = onTabSelected;

        function onTabSelected(tab) {
            vm.selectedTab = tab;
        }
        
    }
})();
