(function () {
    'use strict';

    angular
        .module('showcaseApp')
        .controller('HubShowcasesDetailController', HubShowcasesDetailController);

    /** @ngInject */
    function HubShowcasesDetailController($state, $timeout, $stateParams, $q, $window, $localStorage,
                                          $scope, $translate, FlyersService, FlyersCurrencyValue, HubFlyersService,
                                          HubShowcasesService, toastr, UserInteractModalFactory, ModalFactory) {

        var vm = this;
        var userInteractModal;
        var ownerId = $localStorage.hub.id;
        var mainColor = getComputedStyle(document.documentElement).getPropertyValue('--main-color');
        vm.showcaseId = $stateParams.showcaseId;

        vm.showcase = {
            backgroundColor: false,
            flyers: [],
            ownerId: null,
            hasCover: false,
            userInteract: "SALE"
        };

        vm.listFilter = {
            storeId: ownerId,
            activated: null,
            type: null
        };


        vm.openShowcaseModalType = openShowcaseModalType;

        vm.imageSize = 800;
        vm.coverEdit = true;
        vm.isSaving = false;
        vm.showcaseColors = [
            [{
                color: "#201E1E",
                border: 0
            }, {
                color: "#363231",
                border: 0
            }, {
                color: "#4B4544",
                border: 0
            }, {
                color: "#870200",
                border: 0
            }, {
                color: "#05a19c",
                border: 0
            }, {
                color: "#37003C",
                border: 0
            }, {
                color: "#5A1846",
                border: 0
            }, {
                color: "#910D3F",
                border: 0
            }, {
                color: "#C70039",
                border: 0
            }, {
                color: "#263545",
                border: 0
            }],
            [{
                color: "#002A4A",
                border: 0
            }, {
                color: "#01201B",
                border: 0
            }, {
                color: "#015146",
                border: 0
            }, {
                color: "#1A5B28",
                border: 0
            }, {
                color: "#277B25",
                border: 0
            }, {
                color: "#331908",
                border: 0
            }, {
                color: "#D35400",
                border: 0
            }, {
                color: "#DF7F05",
                border: 0
            }, {
                color: "#2F0E7F",
                border: 0
            }]
        ];

        loadAll();

        vm.goToSettings = function () {
            $state.go('settings');
        }

        function loadAll() {
            if (vm.showcaseId) {
                loadShowcase();
            } else {
                vm.showcase.ownerId = ownerId;
                vm.showcase.backgroundColor = mainColor;
            }
        }

        function loadShowcase() {
            return HubShowcasesService.getShowcase(vm.showcaseId, ownerId).then(function (response) {
                vm.showcase = response;
                vm.showcase.userInteract = vm.originalUserInteract ? vm.originalUserInteract : vm.showcase.userInteract;
                if (vm.showcase.hasCover) {
                    createCroppieStatic();
                    loadImageToCroppieByURL();
                }
                loadShowcasesFlyers();
            });
        }

        vm.checkFlyerIsSelected = function (id) {
            if (vm.flyersMap) {
                return angular.isDefined(vm.flyersMap[id]);
            }
        };

        vm.changeFlyerSelected = function (flyer) {
            if (vm.flyersMap) {
                if (vm.flyersMap[flyer.id]) {
                    vm.showcase.flyers.splice(vm.flyersMap[flyer.id].pos, 1);
                    loadShowcasesFlyers();
                } else {
                    vm.showcase.flyers.splice(0, 0, flyer);
                    loadShowcasesFlyers();
                }
            } else {
                vm.showcase.flyers.splice(0, 0, flyer);
                loadShowcasesFlyers();
            }
        };

        function loadShowcasesFlyers() {
            if (vm.showcase.flyers) {
                vm.flyerId = 0;
                vm.showcase.flyers.forEach(function (flyer) {
                    vm.getCategoryName(flyer);
                    var random = new Date().getTime();
                    flyer.imagePath = '/flyer/api/' + flyer.id + '/hub-picture?type=large&ts=' + random;
                });
            }
        }

        function openShowcaseModalType() {
            userInteractModal = UserInteractModalFactory.selectUserInterectType(null, vm.showcase.userInteract, 'hub-showcases');
            userInteractModal.result.then(function (userInteract) {

                vm.originalUserInteract = angular.copy(vm.showcase.userInteract);
                vm.showcase.userInteract = userInteract;

                vm.listFilter.type = vm.showcase.userInteract;
                if (!(vm.originalUserInteract && vm.originalUserInteract === vm.showcase.userInteract)) {
                    vm.flyersInfo = null;
                    vm.flyersFiltered = [];
                    vm.flyersMap = undefined;
                    vm.showcase.flyers = [];
                    loadShowcasesFlyers();
                }
            });
        }

        vm.getShowcasesFlyer = function () {
            vm.saveFlyer(true);
        }

        vm.goToFlyerByShowcase = function () {
            if (vm.showcase && vm.showcase.id) {
                vm.preSaveFlyer(true);
            } else {
                vm.saveFlyer(true);
            }
        }

        vm.preSaveFlyer = function (isToRedirect) {
            if (vm.showcase && vm.showcase.id) {
                return HubShowcasesService.getShowcase(vm.showcase.id, ownerId).then(
                    function (response) {
                        vm._showcase = response;

                        var isAfter = moment(vm._showcase.updatedAt).isAfter(moment(vm.showcase.updatedAt)); // true

                        if (isAfter) {
                            ModalFactory.openSimpleModal(
                                "ATENÇÃO!",
                                "Identificamos alterações recentes na vitrine que você está prestes a alterar, deseja realmente confirmar suas alterações e descartar a versão anteriormente criada?",
                                "Confirmar",
                                "Cancelar",
                                function () {
                                    return vm.saveFlyer(isToRedirect);
                                }
                            );
                        } else {
                            vm.saveFlyer(isToRedirect);
                        }
                    }
                );
            } else {
                vm.saveFlyer();
            }
        };

        vm.saveFlyer = function (isRedirectToFlyersScreen, callback, isSaving) {
            vm.isSaving = angular.isUndefined(isSaving) ? true : isSaving;
            vm.showcase.cover = "";
            vm.saveCover(function () {
                var flyers = angular.copy(vm.showcase.flyers);
                var flyersPosition = [];
                vm.showcase.flyers.forEach(function (flyer, index) {
                    flyersPosition.push({
                        flyerId: flyer.id,
                        position: index + 1
                    });
                });
                vm.showcase.flyers = flyersPosition;
                $q.all(vm.showcase.flyers).then(function (data) {
                    if (vm.showcaseId) {
                        HubShowcasesService.updateShowcase(vm.showcase).then(function (data) {
                            vm.showcase = data;

                            if (isRedirectToFlyersScreen) {
                                $state.go('hub-showcases-flyers', {
                                    showcaseId: data.id,
                                    ownerId: data.ownerId
                                });
                            } else {
                                toastr.success($translate.instant('showcases.messages.update.success'));
                                vm.isSaving = false;
                                // Follow the instruction of Jackson, when saves the showcase the state doesn't need to be changed
                                // $state.go('showcases');
                            }
                            if (callback) {
                                callback(true);
                            }
                            vm.showcase.flyers = flyers;

                        }, function (data) {
                            if (isRedirectToFlyersScreen) {
                                toastr.error($translate.instant('showcases.messages.update.error'));
                                vm.isSaving = false;
                            }
                        });
                    } else {
                        HubShowcasesService.saveShowcase(vm.showcase).then(function (data) {
                            vm.isSaving = false;
                            vm.showcase = data;
                            if (isRedirectToFlyersScreen) {
                                $state.go('hub-showcases-flyers', {
                                    showcaseId: data.id,
                                    ownerId: data.ownerId
                                });
                            } else {
                                toastr.success($translate.instant('showcases.messages.create.success'));
                                // Follow the instruction of Jackson, when saves the showcase the state doesn't need to be changed
                                // $state.go('showcases');
                            }
                            if (callback) {
                                callback(true);
                                vm.isSaving = false;
                            }
                            vm.showcase.flyers = flyers;
                        }, function (data) {
                            vm.isSaving = false;
                            if (isRedirectToFlyersScreen) {
                                toastr.error($translate.instant('showcases.messages.create.error'));
                            }
                        });
                    }
                });
            });
        }

        vm.setBackgroundColor = function (color) {
            vm.showcase.backgroundColor = color;
        }

        vm.getCategoryName = function (flyer) {
            var category = _.find(vm.categories, function (el) {
                return el.key === flyer.categoryKey
            });
            if (category) {
                flyer.category = category.title;
            }
        }

        vm.getFlyerCurrency = function (currency) {
            return FlyersCurrencyValue[currency];
        }

        vm.getBackgroundImg = function (flyerId) {
            return ('\'/flyer/api/' + flyerId + '/picture\'');
        }

        vm.openModalConfirmCatalogChange = function (showcaseId) {
            ModalFactory.openModal(null,
                $translate.instant('showcases.messages.publish.msgModal'),
                null,
                null, $translate.instant('showcases.home.buttons.publishShowcase'),
                $translate.instant('showcases.home.buttons.no'), function () {
                    vm.publishShowcase(showcaseId);
                },
                null, null, 'icon-warning', null, null, null, null, null, {'font-size': '1.6em'}, true);
        }

        vm.openInput = function (evt) {
            document.getElementById('fileInput').click();
        }

        function createCroppie() {
            if (!vm.basic) {
                var cover = document.getElementById('cover_image_id');
                cover.setAttribute('crossOrigin', 'anonymous');
                vm.basic = new Croppie(cover, {
                    viewport: {
                        width: '100%',
                        height: parseInt(window.innerHeight * 0.38)
                    },
                    boundary: {
                        width: '100%',
                        height: parseInt(window.innerHeight * 0.38)
                    },
                    showZoomer: false,
                    enableResize: false,
                    enableOrientation: true
                });
            }
        }

        function createCroppieStatic() {
            if (!vm.basic) {
                var cover = document.getElementById('cover_image_id');
                cover.setAttribute('crossOrigin', 'anonymous');
                vm.basic = new Croppie(cover, {
                    viewport: {
                        width: '100%',
                        height: parseInt(window.innerHeight * 0.38)
                    },
                    boundary: {
                        width: '100%',
                        height: parseInt(window.innerHeight * 0.38)
                    },
                    showZoomer: false,
                    enableResize: false,
                    enableOrientation: false,
					mouseWheelZoom: false
                });
            }
        }

        function loadImageToCroppieByURLCanvas(canvasURL) {
            var urlRefresh = canvasURL;
            vm.basic.bind({
                crossOrigin: "anonymous",
                url: urlRefresh
            });
            vm.coverEdit = false;
			vm.saveImageCover = true;
        }

        function loadImageToCroppieByURL() {
            var urlRefresh = '/flyer/api/'+ vm.showcaseId +'/hubCover?type=x_large&t=' + new Date().getTime();
            vm.basic.bind({
                crossOrigin: "anonymous",
                url: urlRefresh
            });
            vm.coverEdit = false;
			vm.saveImageCover = false;
        }

        function loadImageToCroppieByBase64(showcaseImage) {
            vm.basic.bind({
                crossOrigin: "anonymous",
                url: showcaseImage
            });
            vm.coverEdit = false;
			vm.saveImageCover = true;
	    }

        var handleFileSelect = function (evt) {

            createCroppie();

            var file = evt.currentTarget.files[0];
            var reader = new FileReader();
            reader.onload = function (evt) {
                $scope.$apply(function ($scope) {
                    vm.imageUpload = evt.target.result;
                    loadImageToCroppieByBase64(evt.target.result);
                });
            };
            reader.readAsDataURL(file);
        };


        vm.initShowcase = function () {
            angular.element(document.querySelector('#fileInput')).on('change', handleFileSelect);
        };

        vm.saveCover = function (callback) {
            if (!vm.coverEdit && vm.saveImageCover) {
                vm.basic.result({type: 'blob', size:'original', quality: 1}).then(function (blob) {
                    FlyersService.saveFlyerImage(blob).then(function (response) {
                        $timeout(function () {
                            vm.showcase.addImageFileDTO = response.data;
                            if (vm.showcase.addImageFileDTO) {
                                vm.showcase.hasCover = true;
                                callback();
                            } else {
                                toastr.error($translate.instant('flyers.messages.create.error'));
                            }
                            vm.loadingImage = false;
                        });
                    }, function (response) {
                        toastr.error($translate.instant('flyers.messages.create.image.uploadError'));
                        vm.loadingImage = false;
                    });
                });
            } else {
                if (callback) {
                    callback();
                }
            }
        }

        $scope.$on("$destroy", function () {
            if (!angular.isUndefined(userInteractModal)) {
                userInteractModal.close();
            }
        });
    }
})();
