(function() {
    'use strict';

    angular
        .module('showcaseApp')
        .controller('ApplyDiscountModalController', ApplyDiscountModalController);

    function ApplyDiscountModalController($uibModalInstance, modalTitle, modalSubtitle, inputLabel, inputFooter,
                                          buttonConfirmText, buttonCancelText, buttonConfirmAction) {
        var vm = this;

        vm.modalTitle = modalTitle;
        vm.modalSubtitle = modalSubtitle;
        vm.inputLabel = inputLabel;
        vm.inputFooter = inputFooter;
        vm.buttonConfirmText = buttonConfirmText;
        vm.buttonCancelText = buttonCancelText;
        vm.buttonConfirmAction = action;

        vm.confirm = function() {
            $uibModalInstance.close(true);
        }

        vm.closeModal = function() {
            $uibModalInstance.dismiss(true);
        }

        function action(){
            var discountPercentage = vm.discountValue / 100;
            buttonConfirmAction(discountPercentage);
            vm.confirm();
        }

    }

})();
