(function() {
    'use strict';
    angular
        .module('showcaseApp')
        .factory('StoreTypeFactory', StoreTypeFactory);

        StoreTypeFactory.$inject = ['$resource'];

    function StoreTypeFactory($resource) {
        var resourceUrl =  'admin/api/storetype';

        return $resource(resourceUrl, {}, {
            'getAllStoreTypesByToken': {
                method: 'GET',
                isArray: true,
                url: 'admin/api/storetype/list/by-token'
            },
            'getAllStoreTypesByOwner': {
                method: 'GET',
                isArray: false,
                url: 'admin/api/storetype/list/by-owner',
                transformResponse: function (data, headers) {
                    var response = {};
                    response.data = angular.fromJson(data);
                    response.headers = headers();
                    return response;
                }
            }
        });
    }
})();
