(function() {
    'use strict';
    angular
        .module('showcaseApp')
        .factory('DirectRegisterFactory', DirectRegisterFactory);

    DirectRegisterFactory.$inject = ['$resource'];

    function DirectRegisterFactory($resource) {
        var resourceUrl = '';

        return $resource(resourceUrl, {}, {
            'init': {
                method: 'POST',
                url: '/api/register/init',
                transformResponse: function(data, headers) {
                    var response = {};
                    response.headers = headers();
                    try {
                        response.data = angular.fromJson(data);
                        return response
                    } catch (err){
                        return response;
                    }
                }
            },
            'finish': {
                method: 'POST',
                url: '/api/register/finish',
                transformResponse: function(data, headers) {
                    var response = {};
                    response.headers = headers();
                    try{
                        response.data = angular.fromJson(data);
                        return response
                    }catch (err){
                        return response;
                    }
                }
            }
        });
    }
})();
