(function() {
    "use strict";

    angular
        .module("showcaseApp")
        .factory("BlockPlanModalFactory", BlockPlanModalFactory);


    /** @ngInject */
    function BlockPlanModalFactory($uibModal) {

        var factory = {
            choosePlan: choosePlan
        };

        return factory;

        function choosePlan(entity) {
            return $uibModal.open({
                templateUrl: 'app/services/global/block-plan/block-plan.modal.html',
                controller: 'BlockPlanModalController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                keyboard : false,
                resolve: {
                    entity: function() {
                        return entity;
                    }
                }
            }).result;
        }
    }

})();
