(function() {
    'use strict';
    angular
        .module('showcaseApp')
        .factory('HubFlyersFactory', HubFlyersFactory);

        HubFlyersFactory.$inject = ['$resource'];

    function HubFlyersFactory($resource) {
        var resourceUrl =  'flyer/api/hub-flyer/:flyerId';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data){
                        response.data = angular.fromJson(data);
                    } else{
                        response.data = data;
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'numberOfFlyers': {
                method: 'GET',
                url: 'flyer/api/store/:storeId/numberOfFlyers',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data){
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'getFlyerTypes': {
                method: 'GET',
                url: 'flyer/api/flyer/getFlyerTypes',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data){
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'deletebyids': {
                method: 'POST',
                url: '/flyer/api/hub-flyer/deletebyids'
            },
            'fileUpload': {
                method: 'POST',
                url: '/flyer/api/flyer/upload'
            },
            'listAll': {
                method: 'POST',
                url: 'flyer/api/hub-flyer/list',
                isArray: true
            },
            'listAllToAdmin': {
                method: 'GET',
                url: 'flyer/api/hub-flyer/listall',
                cache: false,
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data){
                        response = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'listAllFlyers': {
                method: 'GET',
                url: 'flyer/api/hub-flyer/listall',
                isArray: true,
                cache: false
            },
            'listAllPageable': {
                method: 'POST',
                url: 'flyer/api/hub-flyer/list/page',
                cache: false,
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data){
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'listAllFiltered': {
                method: 'POST',
                url: 'flyer/api/hub-flyer/filtered',
                cache: false,
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data){
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'listAllPageableToModal': {
                method: 'POST',
                url: 'flyer/api/flyer/list/page/to-modal',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data){
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'flyerImage': {
                method: 'GET',
                url: 'flyer/api/:id/hub-picture',
                cache: false
            },
            'flyerLinkImageAsString': {
                method: 'GET',
                url: 'flyer/api/:id/hub-picture-link-as-string',
                cache: false,
                transformResponse: function (data, headers) {
                    var response = {};
                    response.data = data;
                    response.headers = headers();
                    return response;
                }
            },
            'update': {
                method:'PUT',
                cache: false,
                timeout: 20000,
                transformResponse: function (data, headers) {
                    if(data){
                        var response = {};
                        response.data = angular.fromJson(data);
                        response.headers = headers();
                        return response;
                    }
                }
            },
            'flyersImportStatus': {
                method: 'GET',
                url: 'flyer/api/hub-flyer/hub/:id/import-flyers-from-table/:importId',
                cache: false,
                transformResponse: function (data, headers) {
                    var response = {};
                    response.data = data;
                    response.headers = headers();
                    return response;
                }
            },
            'listAllFlyersByHub': {
                method: 'GET',
                url: 'flyer/api/hub-flyer/list-all-by-hub',
                isArray: true,
                cache: false
            },
            'listAllFlyersByBrand': {
                method: 'GET',
                url: 'flyer/api/hub-flyer/list-all-by-brand/:brand',
                isArray: true,
                cache: false
            },
            'importFlyersFromVtex': {
                method: 'POST',
                url: 'flyer/api/hub/:hub/import-flyers/',
                params: {
                    hub: '@hub'
                }
            }
        });
    }

})();
