/* globals $ */
(function() {
    'use strict';

    angular
        .module('showcaseApp')
        .directive('detectScrollToTop', function() {

            return{

                restrict: 'A',
                link: function (scope, element, attrs) {
                    var fn = scope.$eval(attrs.detectScrollToTop);
                    element.on('scroll', function (e) {
                        if (!e.target.scrollTop) {
                            scope.$apply(fn);
                        }
                    });
                }

            }
        })
})();
