(function() {
    'use strict';

    angular
        .module('showcaseApp', [
            'ngStorage',
            'tmh.dynamicLocale',
            'pascalprecht.translate',
            'ngResource',
            'ngCookies',
            'ngAria',
            'ngFileUpload',
            'ui.bootstrap',
            'ui.bootstrap.popover',
            'ui.bootstrap.pagination',
            'ui.router',
            'infinite-scroll',
            'ui.grid',
            'ui.grid.pagination',
            'ui.grid.exporter',
            'ui.grid.edit',
            'ui.grid.rowEdit',
            'ui.grid.cellNav',
            'ui.grid.saveState',
            'ui.grid.expandable',
            'ui.grid.autoResize',
            'ngImgCrop',
            'daterangepicker',
            'angularUtils.directives.uiBreadcrumbs',
            'uiGmapgoogle-maps',
            'ui.mask',
            'toastr',
            'ngCpfCnpj',
            'angular-ladda',
            'ui.bootstrap.datetimepicker',
            'betsol.intlTelInput',
            'ngFitText',
            'ui.select',
            'ngSanitize',
            'ngMaterial',
            'angularMoment',
            'star-rating',
            'satellizer',
            'chart.js',
            'angular.morris',
            'oitozero.ngSweetAlert',
            'ngMessages',
            'ngTagsInput',
            'ui.sortable',
			'ngCsv',
            'angucomplete-alt',
            'ui.tree',
            'color.picker',
            'ui.utils.masks'
        ]).constant('_', window._)
        .run(facebookConfig)
        .run(loadGoogleAPIClient)
        .run(run)

    run.$inject = ['$localStorage', 'stateHandler', 'StoreService', 'translationHandler', '$rootScope', '$state', '$translate', 'uiDatetimePickerConfig', '$translatePartialLoader', 'Principal', '$window', 'amMoment'];
    facebookConfig.$inject = ['$rootScope', 'FacebookFactory'];

    function run($localStorage, stateHandler, StoreService, translationHandler, $rootScope, $state, $translate, uiDatetimePickerConfig, $translatePartialLoader, Principal, $window, amMoment) {
        $rootScope.loading = true;
		$rootScope.$state = $state;
        $rootScope._ = window._;

        var DOMAIN = document.URL.split('.')[1];
        
        StoreService.getCssHub(DOMAIN).then(function(response) {
                if (response){
                   	$rootScope.hubStyle = response;
					document.documentElement.style.setProperty('--main-color', response.data.mainColor);
            		document.documentElement.style.setProperty('--main-color-light', response.data.mainColorLight);
            		document.documentElement.style.setProperty('--main-color-dark', response.data.mainColorDark);
            		$rootScope.mainColor = response.mainColor;
					$rootScope.loading = false;
					$rootScope.bucket = response.data.bucketString;
                }

            }).catch(function(e) {
			  document.location.reload(true);
			});
        if (!$rootScope.title) {
            $rootScope.title = DOMAIN.charAt(0).toUpperCase() + DOMAIN.slice(1);
        }
        $rootScope.urlImage = DOMAIN;
        $rootScope.hubNameUpper = DOMAIN.charAt(0).toUpperCase() + DOMAIN.slice(1);
        $rootScope.currentYear = new Date().getFullYear();
        stateHandler.initialize();
        translationHandler.initialize();

        $rootScope.$on('$countMessages', function (ev, next, current) {
            $window.document.title = '(' + next + ') ' + DOMAIN.charAt(0).toUpperCase() + DOMAIN.slice(1);
        });

        $rootScope.$on('$translateChangeSuccess', function(ev, next, current) {
            amMoment.changeLocale($translate.use());
            $translatePartialLoader.addPart('calendar');
            uiDatetimePickerConfig.buttonBar.close.text = $translate.instant('calendar.buttons.close');
            uiDatetimePickerConfig.buttonBar.now.text = $translate.instant('calendar.buttons.now');
            uiDatetimePickerConfig.buttonBar.now.show = false;
            uiDatetimePickerConfig.buttonBar.date.text = $translate.instant('calendar.buttons.date');
            uiDatetimePickerConfig.buttonBar.time.text = $translate.instant('calendar.buttons.time');
            uiDatetimePickerConfig.buttonBar.clear.text = $translate.instant('calendar.buttons.clear');
            uiDatetimePickerConfig.buttonBar.today.text = $translate.instant('calendar.buttons.today');
        });

        $rootScope.$on('$stateChangeSuccess', function(ev, toState, toParams, fromState) {

            if (!Principal.isAuthenticated()) {
                var browserLanguage = $window.navigator.language || $window.navigator.userLanguage;
                browserLanguage = browserLanguage.toLowerCase();

                if (browserLanguage.startsWith('en')) {
                    browserLanguage = 'en';
                }

                if (browserLanguage.startsWith('es')) {
                    browserLanguage = 'es';
                }

                if (browserLanguage.startsWith('pt')) {
                    browserLanguage = 'pt-br';
                }

                amMoment.changeLocale(browserLanguage);

                var languagesAccepted = ["pt-br", "en", "es"];
                if (languagesAccepted.indexOf(browserLanguage) === '-1') {
                    $translate.use('en');
                } else {
                    $translate.use(browserLanguage);
                }
            }
        });

        var localStoreChanged = $rootScope.$watch(function(localStorage) {
            return $localStorage.defaultStore ? $localStorage.defaultStore.storeId : null;
        }, function(newValue, oldValue) {
            if (newValue) {
                $rootScope.$emit('local-storage-changed');
            }
        });

        var whenLogout = $rootScope.$watch(function(localStorage) {
            return $localStorage.defaultStore;
        }, function(newValue, oldValue) {
            if (!newValue) {
                $rootScope.$emit('user-logged-out');
            }
        });

    }

    function facebookConfig(FacebookFactory) {
        console.log('Running FB conf');

        var fbConnectUrl = window.location.protocol + '//connect.facebook.net/pt_BR/sdk.js';

        (function(d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            js = d.createElement(s);
            js.id = id;
            js.src = fbConnectUrl;
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));

        window.fbAsyncInit = function() {
            FB.init({
                appId: '1927510520841177', // App ID  - PROD
                //appId: '312782249584097', // App ID  - HOMOLOG
                status: true, // check login status
                version: 'v17.0',
                cookie: true, // enable cookies to allow the server to access the session
                xfbml: true // parse XFBML
            });

        };
    }

    function loadGoogleAPIClient() {
        // Load the API client and auth2 library
        gapi.load('auth2', initGoogleAPI);
    }

    function initGoogleAPI() {
        gapi.auth2.init({
            apiKey: 'AIzaSyA1Lq1zqXv5pPd5DL-y10EuBZ_LyCLsB2E', // Put this in a way to get
            clientId: '711607015396-v345970tftnkv9tqkiqu2v15ra6t0jo2.apps.googleusercontent.com', // Put this in a way to get
            scope: 'https://www.googleapis.com/auth/plus.business.manage'
            // prompt: 'consent'
        });
    }

})();

