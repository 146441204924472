(function () {
    'use strict'

    angular
        .module('showcaseApp')
        .service('CategoryFlyerService', CategoryFlyerService);

    /** @ngInject */
    function CategoryFlyerService(CategoryFlyerFactory, $uibModal) {

        var service = {
            create: create,
            update: update,
            listCategoriesByCurrentStore: listCategoriesByCurrentStore,
            deleteCategory: deleteCategory,
            showAddEditModal: showAddEditModal
        };

        return service;


        function create(category) {
            return CategoryFlyerFactory.create(category).$promise;
        }

        function update(category) {
            return CategoryFlyerFactory.update(category).$promise;
        }

        function deleteCategory(id) {
            return CategoryFlyerFactory.deleteCategory({ id: id }).$promise;
        }

        function listCategoriesByCurrentStore(userLang) {
            return CategoryFlyerFactory.listAllByCurrentStore({ langKey: userLang }).$promise;
        }

        function showAddEditModal(category) {
            return $uibModal.open({
                templateUrl: "app/admin/flyers/category-flyer/category-flyer.modal.html",
                size: 'md',
                windowClass: 'modal-center',
                windowTopClass: 'modal-dialog-center',
                resolve: {
                    category: function () {
                        return angular.copy(category);
                    }
                },
                controller: "CategoryFlyerModalController",
                controllerAs: "vm"
            }).result;
        }
    }

})();
