(function() {
    'use strict';

    angular
        .module('showcaseApp')
        .controller('TeamManagementController', TeamManagementController);

    TeamManagementController.$inject = ['$scope', '$state', 'uiGridConstants', 'uiGridBuilder', 'TeamService', '$localStorage', '$uibModal'];

    function TeamManagementController($scope, $state, uiGridConstants, uiGridBuilder, TeamService, $localStorage, $uibModal) {
        var vm = this;
        vm.arrayPagination = [];
        vm.startRegistry = 0;
        vm.endRegistry = 0;
        vm.tableDivisor = 5;
        vm.filterFields = {};
        vm.defaultSearchIndex = 0;
        vm.checkIsFirstPage = false;
        vm.checkIsLastPage = false;
        vm.columnDefs = getColumnDefs();
        vm.searchTeamName = undefined;

        loadAll();

        function loadAll() {
            vm.gridTableOptions = getGridOptions();
        }

        function getColumnDefs() {
            return [{
                    field: 'Name',
                    displayName: 'team-management.name',
                    headerCellFilter: 'translate'
                }
            ];
        }

        function getGridOptions() {
            return uiGridBuilder.withDefaultOptions()
                .withActionColumn(100)
                .addActionToActionColumn("edit", "vm.getEditPage", "fa fa-edit", "text-primary", true)
                .addActionToActionColumn("delete", "vm.getDeletePage", "fa fa-trash", "text-primary", true)
                .withColumns(vm.columnDefs).build('search');
        }

        $scope.search = function() {
            var params = {
                "name": vm.searchTeamName,
                "storeId": $localStorage.defaultStore.storeId,
                "isEnabled": true
            }
            var pageable = vm.gridTableOptions.getPageAndSortOptions().getPageAbleObj();

            TeamService.list(params, pageable).then(function(response) {
                if (response.data) {
                    vm.totalItems = response.data.totalElements;
                    vm.gridTableOptions.virtualizationThreshold = response.data.totalElements;
                    vm.gridTableOptions.update(response.data.content, vm.totalItems);
                    
                    vm.gridTableOptions.getArrayPagination(function(arrayPagination) {
                        vm.arrayPagination = arrayPagination;
                    });
                    refreshDataInfos();
                    vm.checkIsLastPage = vm.gridTableApi.pagination.getPage() === vm.gridTableApi.pagination.getTotalPages();
                    vm.checkIsFirstPage = vm.gridTableApi.pagination.getPage() === 1;
                    vm.pagesSize = createArray(vm.totalItems, vm.tableDivisor);
                }
            });
        }

        function createArray(range, divisor) {
            if(!divisor)divisor = 0;
            if (divisor != 0) {
                range = range / divisor;
            }
            var values = [];
            var iteratorNumber = 0;
            for (var i = 0; i < range; i++) {
                iteratorNumber = divisor != 0 ? i * divisor : i + 1;
                values.push(iteratorNumber);
            }
            return values;
        };

        function refreshDataInfos() {
            vm.gridTableApi = vm.gridTableOptions.getApiGridTable();
            vm.startRegistry = (vm.gridTableApi.pagination.getPage() * vm.gridTableOptions.paginationPageSize) - vm.gridTableOptions.paginationPageSize;
            vm.endCalc = vm.gridTableOptions.paginationPageSize * vm.gridTableApi.pagination.getPage();
            vm.endRegistry = vm.endCalc > vm.totalItems ? vm.totalItems : vm.endCalc;
        };

        vm.filterField = function(valueFilter, fieldTOFilter) {
            vm.gridTableApi.grid.getColumn(fieldTOFilter).filters[0].term = valueFilter;
            vm.gridTableOptions.enableFiltering = true;
            vm.gridTableApi.grid.refresh();
        };

        vm.changePageSize = function(pageSize) {
            vm.gridTableOptions.paginationPageSize = pageSize;
        };

        vm.reloadTeams = function() {
            $scope.search();
        }

        vm.stopPropagation = function(event) {
            event.stopPropagation();
        };

        vm.changeColumnView = function($index) {
            vm.gridTableOptions.columnDefs[$index].visible = !(vm.gridTableOptions.columnDefs[$index].visible || vm.gridTableOptions.columnDefs[$index].visible === undefined);
            vm.gridTableApi.core.notifyDataChange(uiGridConstants.dataChange.COLUMN);
        };

        vm.exporterTable = function(type) {
            if (type === 'pdf') {
                vm.gridTableApi.exporter.pdfExport('visible', 'visible');
            } else if (type === 'csv') {
                vm.gridTableApi.exporter.csvExport('visible', 'visible');
            }
        };

        vm.addTeam = function() {
            $uibModal.open({
                templateUrl: 'app/admin/user-management/team-management-dialog.html',
                controller: 'TeamManagementDialogController',
                controllerAs: 'vm',
                scope: $scope,
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    team: function() {
                        return undefined;
                    },
                    storeId: function() {
                        return $localStorage.defaultStore.storeId;
                    }
                }
            });
        }

        vm.getEditPage = function(row) {
            $uibModal.open({
                templateUrl: 'app/admin/user-management/team-management-dialog.html',
                controller: 'TeamManagementDialogController',
                controllerAs: 'vm',
                scope: $scope,
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    storeId: function() {
                        return $localStorage.defaultStore.storeId;
                    },
                    team: function() {
                        return row;
                    }
                }
            });
        }

        vm.getDeletePage = function(row) {
            $uibModal.open({
                templateUrl: 'app/admin/user-management/team-management-delete-dialog.html',
                controller: 'TeamManagementDeleteController',
                controllerAs: 'vm',
                scope: $scope,
                size: 'md',
                resolve: {
                    storeId: function() {
                        return $localStorage.defaultStore.storeId;
                    },
                    userLogin: function() {
                        return row;
                    }
                }
            });
        }

        $scope.$on('userUpdated', function(event, data) {
            $scope.search();
        });

        $scope.$on('$localeChangeSuccess', function(e, locale) {
            vm.gridTableApi.core.notifyDataChange(uiGridConstants.dataChange.ALL);
            vm.gridTableApi.grid.refresh();
        });
    }
})();
