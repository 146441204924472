function notifyTimes() {
    return [
        {
            code: 1,
            time: 15,
            unity: 'MINUTES'
        },
        {
            code: 2,
            time: 30,
            unity: 'MINUTES'
        },
        {
            code: 3,
            time: 60,
            unity: 'MINUTES'
        },
        {
            code: 4,
            time: 2,
            unity: 'HOURS'
        },
        {
            code: 5,
            time: 5,
            unity: 'HOURS'
        },
        {
            code: 6,
            time: 12,
            unity: 'HOURS'
        },
        {
            code: 7,
            time: 1,
            unity: 'DAY'
        },
        {
            code: 8,
            time: 3,
            unity: 'DAYS'
        },
        {
            code: 9,
            time: 5,
            unity: 'DAYS'
        }
    ];
}
