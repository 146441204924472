(function() {
    'use strict';

    angular
        .module('showcaseApp')
        .controller('FormStoreInfoController', FormStoreInfoController);

    /** @ngInject */
    function FormStoreInfoController($scope, CategoryService, $translate, HubService, StoreTypeService, Principal) {

        var vm = this;
        vm.store = $scope.store;
        vm.storeTypes = [];
        vm.storeTypeDefault;

        loadAll();


        Principal.identity().then(function(account) {
            if (vm.store.email == null || vm.store.email == "") vm.store.email = account.email; 
            if (vm.store.contactPhone == null || vm.store.contactPhone == "") vm.store.contactPhone = account.phone; 
        });

        function loadAll() {
            CategoryService.listCategories($translate.use()).then(function(response) {
                vm.categories = response.data;
            });
            StoreTypeService.getAllStoreTypesByToken().then(function(response) {
                vm.storeTypes = response;
                if (vm.store && !vm.store.storeType) {
                vm.storeTypeDefault = vm.storeTypes.find(function (type) {
                    return type.name == 'Loja Própria';         
                  });
                  vm.store.storeType = vm.storeTypeDefault.id.toString();
                }
            });
        }
    }
})();
