(function() {
    'use strict';
    angular
        .module('showcaseApp')
        .factory('WatiFactory', WatiFactory);

        WatiFactory.$inject = ['$resource'];

    function WatiFactory($resource) {
        var resourceWati =  'admin/api/wati';
        var resourceConfigByStore =  resourceWati + '/config/store';
        var resourceSendMessage =  resourceWati + '/send-message';
        var resourceSendAudioMessage =  resourceWati + '/send-message/audio';
        var resourceSendMultipleMessages =  resourceWati + '/send-multiple-messages';
        var resourceFindMessagesByNumber =  resourceWati + '/messages';
        var resourceFindConversations =  resourceWati + '/conversations';
        var resourceFindFileByName =  resourceWati + '/file';
        var resourceFindStickers = resourceWati + '/stickers';
        var resourceSearchTemplateMessages =  resourceWati + '/template-messages';
        var resourceSearchTemplateMessagesByText = resourceWati + '/search-template-messages';
        var resourceGetTemplateMessages =  resourceWati + '/get-template-messages';
        var resourceSendTemplateMessage =  resourceWati + '/send-template-message';
        var resourceFindContactByConversationId =  resourceWati + '/contact';
        var resourceDeleteConfigByStore = resourceWati + '/:id';
        var resourceUploadByChunk = resourceWati + '/uploadChunk/:isLastChunk';
        var resourceGetWATICredits = resourceWati + '/getWATICredits';

        return $resource(resourceWati, {}, {
            'sendMessage': {
                method: 'POST',
                url: resourceSendMessage
            },
            'sendAudioMessage': {
                method: 'POST',
                headers: {'Content-Type': undefined},
                url: resourceSendAudioMessage
            },
            'sendMultipleMessages': {
                method: 'POST',
                url: resourceSendMultipleMessages
            },
            'findContact': {
                method: 'GET',
                url: resourceFindContactByConversationId
            },
            'getWATICredits': {
                method: 'GET',
                url: resourceGetWATICredits
            },
            'uploadByChunk': {
                method: 'POST',
                url: resourceUploadByChunk
            },
            'sendTemplateMessage': {
                method: 'POST',
                url: resourceSendTemplateMessage
            },
            'findConfigByCurrentStore': {
                method: 'GET',
                url: resourceConfigByStore,
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data){
                        response.data = angular.fromJson(data);
                    } else{
                        response.data = data;
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'deleteConfigByStoreId':{
                method: 'DELETE',
                url: resourceDeleteConfigByStore,
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data){
                        response.data = angular.fromJson(data);
                    } else{
                        response.data = data;
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'create': {
                method: 'POST',
                url: resourceWati
            },
            'update': {
                method:'PUT',
                url: resourceConfigByStore,
                cache: false,
                timeout: 20000,
                transformResponse: function (data, headers) {
                    if(data){
                        var response = {};
                        response.data = angular.fromJson(data);
                        response.headers = headers();
                        return response;
                    }
                }},
            'findMessagesByNumber': {
                method: 'GET',
                url: resourceFindMessagesByNumber,
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data){
                        response.data = angular.fromJson(data);
                    } else{
                        response.data = data;
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'findConversations': {
                method: 'GET',
                url: resourceFindConversations,
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data){
                        response.data = angular.fromJson(data);
                    } else{
                        response.data = data;
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'findFileByName': {
                method: 'GET',
                url: resourceFindFileByName,
                responseType: 'blob',
                transformResponse: function (data, headers) {
                    var response = {};
                    response.data = data;
                    response.headers = headers();
                    return response;
                }
            },
            'findAudioFileByName': {
                method: 'GET',
                url: 'admin/api/wati/file/audio',
                headers: { 'Accept': '*/*', 'Content-Type': 'audio/wav' },
                responseType: 'arraybuffer',
                transformResponse: function (data, headers) {
                    var response = {};
                    response.data = data;
                    response.headers = headers();
                    return response;
                }
            },

            'findStickers': {
                method: 'GET',
                url: resourceFindStickers,transformResponse: function (data, headers) {
                    var response = {};
                    if (data){
                        response.data = angular.fromJson(data);
                    } else{
                        response.data = data;
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'searchTemplateMessagesByText': {
                method: 'POST',
                url: resourceSearchTemplateMessagesByText,
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data){
                        response.data = angular.fromJson(data);
                        response.data = angular.fromJson(response.data.result);
                    } else{
                        response.data = data;
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'searchTemplateMessages': {
                method: 'GET',
                url: resourceSearchTemplateMessages,
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data){
                        response.data = angular.fromJson(data);
                        response.data = angular.fromJson(response.data);
                    } else{
                        response.data = data;
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'getTemplateMessages': {
                method: 'GET',
                url: resourceGetTemplateMessages,
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data){
                        response.data = angular.fromJson(data);
                        response.data = angular.fromJson(response.data);
                    } else{
                        response.data = data;
                    }
                    response.headers = headers();
                    return response;
                }
            }
        });
    }

})();
