(function () {
    'use strict';

    angular
        .module('showcaseApp')
        .controller('DigitalPosterController', DigitalPosterController);

    function DigitalPosterController($translate, toastr, DigitalPosterService, ModalFactory, $rootScope, SelectOfferModalService, $timeout) {

        var vm = this;
        vm.poster = {};
        vm.isGeneratingPoster = false;
        vm.integerSize = 1;
        vm.preview = {};
        vm.control = {};

        vm.productNameSize = 20;
        vm.descriptionSize = vm.productCodeSize = 40;

        vm.getIntegerCount = function() {
            return vm.preview.integerDiscounted ? vm.preview.integerDiscounted.length : '';
        }


        vm.formatMoney = function (value, currencySymbol) {
            vm.processAllData();
            return formatMoney(value, currencySymbol, vm.poster.currencyInstance.decimalQuant, vm.poster.currencyInstance.centsSeparator, vm.poster.currencyInstance.thousandsSeparator);
        };

        function formatMoneyInternally(value, currencySymbol) {
            return formatMoney(value, currencySymbol, vm.poster.currencyInstance.decimalQuant, vm.poster.currencyInstance.centsSeparator, vm.poster.currencyInstance.thousandsSeparator);
        };

        vm.isToDisplayMe = function (value) {
            return value == vm.poster.discountType;
        }

        vm.selectTemplate = function(template) {
            vm.poster.selectedTemplate = template;
        }

        vm.pagesTypes = [{
            title: "A4 - 210 X 297mm",
            key: "A4",
            description: "A4 (210mm X 297mm)"
        }];

        vm.templatesKeys = ['DE_POR_1x1', 'DE_POR_2x1', 'DE_POR_4x1'];

        vm.currencyConfigurations = getCurrencyListConfiguration();

        vm.templatesTypes = {
            DE_POR_1x1: {
                iconImg: 'https://showkase-prod.s3-sa-east-1.amazonaws.com/hub/system-images/'+$rootScope.urlImage+'/1-page-template.png',
                htmlTemplate: 'app/admin/digital-poster/components/posters/poster-1x1.html',
                key: 'DE_POR_1x1'
            },
            DE_POR_2x1: {
                iconImg: 'https://showkase-prod.s3-sa-east-1.amazonaws.com/hub/system-images/'+$rootScope.urlImage+'/2-page-template.png',
                htmlTemplate: 'app/admin/digital-poster/components/posters/poster-1x2.html',
                key: 'DE_POR_2x1'
            },
            DE_POR_4x1: {
                iconImg: 'https://showkase-prod.s3-sa-east-1.amazonaws.com/hub/system-images/'+$rootScope.urlImage+'/3-page-template.png',
                htmlTemplate: 'app/admin/digital-poster/components/posters/poster-1x4.html',
                key: 'DE_POR_4x1'
            }
        };

        vm.campaignTypes = [{
            title: $translate.instant('digitalPoster.creater.campaignTypes.offer'),
            key: "OFFER"
        }];

        function loadAll() {
            vm.poster.pageSize = "A4";
            vm.poster.campaign = "OFFER";
            vm.poster.currencyInstance = vm.currencyConfigurations.REAL;
            vm.poster.discountType = "DISCOUNT_FIXED";
            vm.poster.selectedTemplate = vm.templatesTypes['DE_POR_1x1'];
        }

        function canGeneratePoster(){
            if (vm.posterDTO){
                if (vm.poster.campaign == 'OFFER'){
                    if (!vm.posterDTO.purchaseValue) {
                        toastr.warning($translate.instant('digitalPoster.messages.error.emptyPurchaseValue'));
                        return false;
                    } else if (!vm.posterDTO.discountedValue && !vm.posterDTO.centsInfo) {
                        toastr.warning($translate.instant('digitalPoster.messages.error.emptyDiscountValue'));
                        return false;
                    } else if (vm.poster.discountValue > vm.poster.purchaseValue) {
                        toastr.warning($translate.instant('digitalPoster.messages.error.discountBiggerThan'));
                        return false;
                    } else if (Number(vm.posterDTO.discountedValue) < 1) {
                        toastr.warning($translate.instant('digitalPoster.messages.error.negativePrice'));
                        return false;
                    } else if (!vm.posterDTO.discount) {
                        toastr.warning($translate.instant('digitalPoster.messages.error.emptyDiscountValue'));
                        return false;
                    } else if (vm.poster.discount <= 0){
                        toastr.warning($translate.instant('digitalPoster.messages.error.discountValueCannotLessThanOrZero'));
                        return false;
                    } else if (!vm.posterDTO.productName) {
                        toastr.warning($translate.instant('digitalPoster.messages.error.productNameEmpty'));
                        return false;
                    } else if (vm.posterDTO.productName.length > vm.productNameSize) {
                        toastr.warning($translate.instant('digitalPoster.messages.error.nameSize'));
                        return false;
                    } else if (vm.posterDTO.code && vm.posterDTO.code.length > vm.productCodeSize) {
                        toastr.warning($translate.instant('digitalPoster.messages.error.codeSize'));
                        return false;
                    } else if (vm.posterDTO.description && vm.posterDTO.description.length > vm.descriptionSize) {
                        toastr.warning($translate.instant('digitalPoster.messages.error.descriptionSize'));
                        return false;
                    }
                }
            } else {
                toastr.warning($translate.instant('digitalPoster.messages.error.emptyPoster'));
                return false;
            }

            return true;

        }

        function prepareDTO() {

            vm.posterDTO = {}
            vm.posterDTO.productName = vm.poster.productName ? vm.poster.productName.toUpperCase() : vm.poster.productName ;
            vm.posterDTO.description = vm.poster.description ? vm.poster.description.toUpperCase() : vm.poster.description ;
            vm.posterDTO.code = vm.poster.code ? vm.poster.code.toUpperCase() : vm.poster.code ;
            vm.posterDTO.centsInfo = vm.poster.centsInfo ? vm.poster.centsInfo.toUpperCase() : vm.poster.centsInfo ;

            vm.posterDTO.currency = vm.poster.currencyInstance.prefix.trim();
            vm.posterDTO.purchaseValue = formatMoneyInternally(vm.poster.purchaseValue) == "0,00" ? undefined : formatMoneyInternally(vm.poster.purchaseValue);
            vm.posterDTO.discountedValue = vm.preview.integerDiscounted;
            vm.posterDTO.discount = vm.poster.discount ? formatMoneyInternally(vm.poster.discount) : undefined;
            vm.posterDTO.posterType = vm.poster.selectedTemplate.key;

        }

        function succesPrint(res) {

            var saveByteArray = (function () {
                return function (data, name, mimeType) {
                    var blob = new Blob(data, {
                        type: mimeType
                    });
                    var url = window.URL.createObjectURL(blob);
                    var element = angular.element('<a/>');
                    var dynamicName = "";
                    if ($translate.instant('digitalPoster.home.buttons.generatePoster')
                        !== 'digitalPoster.home.buttons.generatePoster' ) {
                        dynamicName = $translate.instant('digitalPoster.home.buttons.generatePoster').split(" ")[1];
                    }
                    element.attr({
                        href: url,
                        target: '_blank',
                        download: dynamicName.toUpperCase() + '-' + vm.posterDTO.productName + '.pdf'
                    });
                    if (document.createEvent) {
                        var ev = document.createEvent("MouseEvent");
                        ev.initMouseEvent("click", true /* bubble */ , true /* cancelable */ , window, null, 0, 0, 0, 0, /* coordinates */
                            false, false, false, false,
                            /*
                             * modifier keys
                             */
                            0 /* left */ , null);
                        element[0].dispatchEvent(ev);
                    } else {
                        element[0].fireEvent("onclick");
                    }
                    setTimeout(function () {
                        document.body.removeChild;
                        window.URL.revokeObjectURL(url);
                    }, 200);
                };
            }());
            if (res.headers["content-disposition"]) {
                var filename = res.headers["content-disposition"].match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)[1];
                var mimeType = res.headers["content-type"];
                filename = filename.replace(/['"]/g, '');
                saveByteArray([res.data], filename, mimeType);
            } else {
                toastr.error($translate.instant('digitalPoster.messages.error.generalError'));
            }

            vm.isGeneratingPoster = false;
            $rootScope.$emit('finish-generate-poster');
        }

        function errorPrint(response) {
            vm.isGeneratingPoster = false;
            toastr.error($translate.instant('digitalPoster.messages.error.generalError'));
            $rootScope.$emit('finish-generate-poster');
        }

        function buildMessageGeneratedPoster() {
            ModalFactory.openModal(null,
                'digitalPoster.messages.readyPoster',
                null, null, 'OK', null, 'close', null, null, null,
                'digitalPoster.messages.ifNotDownloaded', 'digitalPoster.messages.clickHere',
                function (callback) {
                    DigitalPosterService.generatePoster(vm.posterDTO).then(function (res) {
                        var saveByteArray = (function () {
                            return function (data, name, mimeType) {
                                var blob = new Blob(data, {
                                    type: mimeType
                                });
                                var url = window.URL.createObjectURL(blob);
                                var element = angular.element('<a/>');
                                element.attr({
                                    href: url,
                                    target: '_blank',
                                    download: name
                                });
                                if (document.createEvent) {
                                    var ev = document.createEvent("MouseEvent");
                                    ev.initMouseEvent("click", true /* bubble */ , true /* cancelable */ , window, null, 0, 0, 0, 0, /* coordinates */
                                        false, false, false, false,
                                        /*
                                         * modifier keys
                                         */
                                        0 /* left */ , null);
                                    element[0].dispatchEvent(ev);
                                } else {
                                    element[0].fireEvent("onclick");
                                }
                                setTimeout(function () {
                                    document.body.removeChild;
                                    window.URL.revokeObjectURL(url);
                                }, 200);
                            };
                        }());
                        if (res.headers["content-disposition"]) {
                            var filename = res.headers["content-disposition"].match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)[1];
                            var mimeType = res.headers["content-type"];
                            filename = filename.replace(/['"]/g, '');
                            saveByteArray([res.data], filename, mimeType);
                        } else {
                            toastr.error($translate.instant('digitalPoster.messages.error.generalError'));
                        }
                        vm.isGeneratingPoster = false;
                        callback();
                    }, function (response) {
                        vm.isGeneratingPoster = false;
                        callback();
                    });
                }, 'width: 143px; height: 170px;', 'https://showkase-prod.s3-sa-east-1.amazonaws.com/hub/system-images/'+$rootScope.urlImage+'/download-cartaz.png');
        }

        vm.generatePoster = function () {
            prepareDTO();
            if (canGeneratePoster()){
                vm.isGeneratingPoster = true;
                buildMessageGeneratedPoster();
                $rootScope.$emit('waiting-generates-tabloid');
                vm.posterDTO.unity = vm.getUnitType();
                DigitalPosterService.generatePoster(vm.posterDTO).then(succesPrint, errorPrint);
            }
        }

        vm.checkMaxAndMinDiscountValue = function () {
            checkNegativeValues();

            if (vm.poster.discountValue && (!vm.poster.discountType)) {
                vm.poster.discountType = 'DISCOUNT_FIXED';
            }
            if (vm.poster.discountType === 'DISCOUNT_FIXED' && vm.posterEditForm.discountValue) {
                vm.posterEditForm.discountValue.$setValidity('max', true);
                if (vm.poster.discountValue > vm.poster.purchaseValue) {
                    vm.posterEditForm.discountValue.$setValidity('discountGreaterThanValue', false);
                } else {
                    vm.posterEditForm.discountValue.$setValidity('discountGreaterThanValue', true);
                    vm.processAllData();
                }
            } else {
                vm.poster.discountValue = undefined;
            }
        }

        function checkNegativeValues() {
            if (vm.poster.purchaseValue < 0) {
                vm.poster.purchaseValue = 0;
            }
            if (vm.poster.discountValue < 0) {
                vm.poster.discountValue = 0;
            }
        }

        function transformValueStringInDouble(value) {
            return Number(value.replace(/[^0-9.-]+/g, ""));
        }

        vm.selectFlyer = function (){
            SelectOfferModalService.selectOffers(true, 'digitalPoster.messages.singleSelectionTitle').result.then(function (flyer) {
                vm.poster.productName = flyer.name;
                vm.poster.description = flyer.description;
                vm.poster.currencyInstance = vm.currencyConfigurations[flyer.currency];
                vm.poster.discountType = flyer.discountType || 'DISCOUNT_FIXED';
                vm.poster.purchaseValue = flyer.purchaseValue;
                vm.poster.discountValue = vm.poster.discountType == 'DISCOUNT_FIXED' ? flyer.discountValue : undefined;
                vm.poster.discountedValue = flyer.discountedValue;
                vm.poster.discountValuePercentage = vm.poster.discountType == 'DISCOUNT_PERCENTAGE' ? flyer.discountValue : undefined;
                vm.processAllData();
            });
        }

        vm.getIntegerDiscountFromPrice = function () {
            if (!vm.poster.discountedValue) return '';
            var discountedValue = formatMoneyInternally(vm.poster.discountedValue);
            return discountedValue.substr(0, discountedValue.indexOf(vm.poster.currencyInstance.centsSeparator));
        }

        vm.getCentsDiscountFromPrice = function () {
            if (!vm.poster.discountedValue) return '';
            var discountedValue = formatMoneyInternally(vm.poster.discountedValue);
            vm.poster.centsInfo = discountedValue.substr(discountedValue.indexOf(vm.poster.currencyInstance.centsSeparator));
            return vm.poster.centsInfo;
        }

        vm.getModeOldPrefix = function () {
            // MOCKED BECAUSE HAS ONLY ONE TYPE OF OFFER
            return 'DE: ';
        }

        vm.getUnitType = function () {
            switch (vm.poster.unitType) {
                case 'UNITY_UNITY':
                    return $translate.instant('digitalPoster.creater.fields.unity.units.unity');
                case 'UNITY_LIB':
                    return $translate.instant('digitalPoster.creater.fields.unity.units.lib');
                case 'UNITY_METER':
                    return $translate.instant('digitalPoster.creater.fields.unity.units.meter');
                case 'UNITY_PACKAGE':
                    return $translate.instant('digitalPoster.creater.fields.unity.units.package');
                default:
                    break;
            }
        }

        vm.getModeNewPrefix = function () {
            // MOCKED BECAUSE HAS ONLY ONE TYPE OF OFFER
            return 'POR: ';
        }

        vm.selectTemplate = function (selectedTemplate) {
            vm.poster.selectedTemplate = selectedTemplate;
        }


        loadAll();

        vm.getHtmlTemplate = function () {
            var templateType = findElementByAttr(vm.templatesTypes, 'templateKey', vm.poster.selectedTemplate);
            return templateType ? templateType.htmlTemplate : '';
        }

        vm.getPageConfiguration = function () {
            var pageType = findElementByAttr(vm.pagesTypes, 'key', vm.poster.pageSize);
            return pageType ? pageType.description : '';
        }

        function getDiscountedValueByDiscountType(value, discountType, discountValue) {
            if (discountType == 'DISCOUNT_PERCENTAGE') {
                return value - (value * ((discountValue || 100) * 0.01));

            } else if (discountType == 'DISCOUNT_FIXED') {
                return value - (discountValue || 0);
            }
        }

        function getDiscountValueByDiscountType(value, discountType, discountValue) {
            if (discountType == 'DISCOUNT_PERCENTAGE') {
                return value * ((discountValue || 100) * 0.01);

            } else if (discountType == 'DISCOUNT_FIXED') {
                return discountValue;
            }
        }

        vm.processAllData = function () {
            if( (vm.posterEditForm.discountValuePercentage && vm.posterEditForm.discountValuePercentage.$valid)
                    || (vm.posterEditForm.discountValue && vm.posterEditForm.discountValue.$valid)) {
                if(vm.poster.purchaseValue > 9999.99){
                    vm.poster.purchaseValue = 9999.99;
                }
                if (vm.poster.discountValue > 9999.99){
                    vm.poster.discountValue = 9999.99;
                }
                var discountType = vm.poster.discountType;
                var currencyType = vm.poster.currencyInstance.key;
                var purchaseValue = vm.poster.purchaseValue;
                var discountValue = discountType == 'DISCOUNT_PERCENTAGE' ? vm.poster.discountValuePercentage : vm.poster.discountValue;
                var discountedValue = undefined;
                var integerDiscounted = undefined;
                var centsDiscounted = undefined;
                if (!discountType || !currencyType) return;

                discountedValue = getDiscountedValueByDiscountType((purchaseValue || 0.00), discountType, discountValue);
                discountValue = getDiscountValueByDiscountType((purchaseValue || 0.00), discountType, discountValue);

                // Setting up to posterDTO
                vm.poster.discount = discountValue;
                vm.poster.discountedValue = discountedValue;

                // Setting up to preview variables
                vm.preview.discountedValue = discountedValue;
                vm.preview.discountValue = discountValue;

                vm.preview.integerDiscounted = vm.getIntegerDiscountFromPrice();
                vm.preview.centsDiscounted = vm.getCentsDiscountFromPrice();
            }


        }

    }

})();
