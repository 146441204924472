(function () {
    'use strict';
    angular
        .module('showcaseApp')
        .factory('SatisfactionSurveyFactory', SatisfactionSurveyFactory);

    SatisfactionSurveyFactory.$inject = ['$resource'];

    function SatisfactionSurveyFactory($resource) {
        var resourceUrl = 'admin/api';

        return $resource(resourceUrl, {}, {
            
            'sendSatisfactionSurvey': {
                method: 'POST',
                url: '/admin/api/satisfactionSurvey',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'checkIfNotExpiredEvaluation': {
                method: 'GET',
                url: '/admin/api/check-expired-evaluation/:storeId/:userId',
                isArray: false,
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }

            },
            'checkIfStoreNew': {
                method: 'GET',
                url: '/admin/api/check-if-store-new/:storeId',
                isArray: false,
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }

            }
        });
    }
})();
