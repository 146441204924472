(function () {
    'use strict'

    angular
        .module('showcaseApp')
        .service('DiscountCouponService', DiscountCouponService);

    /** @ngInject */
    function DiscountCouponService(DiscountCouponFactory) {

        var service = {
            create: create,
            update: update,
            list: list,
            search: search,
            deleteCoupon: deleteCoupon
        };

        return service;

        function create(discountCoupon) {
            return DiscountCouponFactory.create(discountCoupon).$promise;
        }

        function update(discountCoupon) {
            return DiscountCouponFactory.update({ id: discountCoupon.id }, discountCoupon).$promise;
        }

        function list(pageable, discountCouponFilter) {
            return DiscountCouponFactory.list(pageable, discountCouponFilter).$promise;
        }

        function search(pageable, discountCouponFilter) {
            return DiscountCouponFactory.search(pageable, discountCouponFilter).$promise;
        }

        function deleteCoupon(discountCouponId) {
            return DiscountCouponFactory.deleteCoupon({discountCouponId: discountCouponId}).$promise;
        }
    }

})();
