(function() {
    'use strict';

    angular
        .module('showcaseApp')
        .controller('SocialNetworksConnectModalController', SocialNetworksConnectModalController);

    function SocialNetworksConnectModalController($uibModalInstance, $state, $localStorage, communicationConstants, $window,
                                                  ChatCommerceService, toastr, FBAuthModalFactory, SocialMediaService, $translate, token, $scope) {
        var vm = this;

        vm.step = 0;

        var SOURCE = 'WATI';

        vm.whatsapp = false;
        vm.faceinsta = false;

        vm.webhook = document.location.protocol + '//admin.' + document.location.hostname.split('.').slice(1).join('.') + '/admin/api/wati/hook?accessToken=' + token;

        vm.popoverIsOpen = false

        vm.SOCIAL_NETWORKS = {
            FACE_INSTA: "FACE_INSTA",
            WHATSAPP: "WHATSAPP"
        }

        vm.watiAccountData = {};

        vm.isSaving = false;

        vm.syncProgress = 0;

        vm.progressInterval;

        vm.closeModal = function(url) {
            $uibModalInstance.close(url);
        }

        vm.nextStep = function() {
            if (vm.whatsapp) {
                vm.step = vm.step + 1;
            } else {
                vm.closeModal('facebook');
            }

        }

        vm.previousStep = function() {
            if (vm.step == 0) {
                vm.closeModal();
            } else {
                vm.step = vm.step - 1;
            }
        }

        vm.selectSocialNetwork = function(socialNetwork) {
            if (socialNetwork == vm.SOCIAL_NETWORKS.WHATSAPP) {
                vm.whatsapp = true;
                vm.faceinsta = false;
            } else {
                vm.whatsapp = false;
                vm.faceinsta = true;
            }
        }

        vm.changeTextPopover = function() {
            vm.popoverIsOpen = true;
            setTimeout(function() {
                vm.popoverIsOpen = false;
            }, 5000)
        }

        var validateSetting = function() {
            if (vm.watiAccountDataForm.$invalid) {
                toastr.warning("Campos obrigatórios não foram preenchidos");
                return false;
            }
            return true;
        }

        var makeBodyToRequest = function() {
            vm.isSaving = true;
            return angular.copy(vm.watiAccountData);
        }

        vm.saveSetting = function() {
            if(!validateSetting()) return;

            vm.isSaving = true;
            vm.watiAccountData.accessToken = token;

            vm.progressInterval = setInterval(function () {
                if (vm.syncProgress === 100) {
                    clearInterval(vm.progressInterval);
                    return;
                }
                vm.syncProgress = vm.syncProgress + 5;
                $scope.$apply();
            }, 1000);

            ChatCommerceService.connectToChat(
                {
                    watiAccountData: makeBodyToRequest(),
                    chatPlatform: SOURCE
                }
            ).then(function(response) {
                vm.syncProgress = 100;
                clearInterval(vm.progressInterval);
                setTimeout(function () {
                    vm.isSaving = false;
                    toastr.success("Configuração criada com sucesso");
                    vm.closeModal({data: response, source: 'whatsapp'})
                }, 1000);
            });
        }

    }

})();

