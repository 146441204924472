(function () {
    'use strict';

    angular
        .module('showcaseApp')
        .controller('BroadcastListController', BroadcastListController);

    BroadcastListController.$inject = ['$scope', '$uibModalInstance', '$translate', '$q', '$window', 'WatiService',
        'BroadcastListService', '$localStorage', 'toastr', 'moment', 'watiSetting'];

    function BroadcastListController($scope, $uibModalInstance, $translate, $q, $window, WatiService,
                                     BroadcastListService, $localStorage, toastr, moment, watiSetting) {
        var vm = this;
        var format = 'DD/MM/YYYY HH:mm';
        var SCHEDULED_SENDING = 'SCHEDULE';
        var SENDING_NOW = 'NOW';

        vm.isMobile = detectar_mobile();

        vm.watiSetting = watiSetting;
        vm.currentStep = 1;
        vm.form = {
            broadcastName: 'Untitled_' + moment().format('DDMMYYYYHHmm'),
            templateId: '',
            whenSend: SENDING_NOW,
            selectedContactIds: [],
            deselectedContactIds: [],
            IsAllSelected: false,
        };

        // Broadcast list form
        vm.searchMessageTemplate = '';
        vm.messageTemplates = [];
        vm.messageTemplate = null;
        vm.loadingTemplates = false;

        vm.broadcastDashboard = [];
        var broadcastDashboardFilter = {
            "dateFrom": moment().subtract(7, 'd').format(),
            "dateTo": moment().format(),
            "searchString": "",
            "sortBy": 0,
            "filterStatus": [],
            "pageSize": 7,
            "pageNumber": 0,
            "isUpdate": false
        }

        // Select contacts
        vm.SORTBY_VALUES = {
            'NAME': 1,
            'PHONE': 4
        };
        vm.loadingContacts = false;
        vm.loadingImportContacts = false;
        vm.loadingAddBroadcast = false;
        vm.maskLocale = '99/99/9999 99:99';
        vm.scheduledAt = moment().format(format);
        vm.numberPerPage = [5, 10, 25, 100]
        vm.totalItems = 0;
        vm.numPerPage = 10;
        vm.maxSize = 5;
        vm.filters = {
            filterAttributes: [],
            includeInvalidContact: true,
            orderBy: 'asc',
            pageSize: 5,
            searchString: '',
            sortBy: 1,
            pageNumber: 1
        };
        vm.contacts = [];

        vm.initDateTimePicker = dateTimePicker;

        init();

        function init() {
            dateTimePicker();
            searchTemplateMessages();
            getBroadcastDashboard();
        }

        vm.selectMessageTemplate = function (item) {
            if (item) {
                vm.form.templateId = item.id;
            }
        }

        function getBroadcastDashboard () {
            vm.dashboardLoading = true;
            BroadcastListService.getBroadcastDashboard(broadcastDashboardFilter).then(function (response) {
                vm.broadcastDashboard = response.result.items;
                vm.dashboardLoading = false;
            });
        }

        function setMediaFromTemplate (messageTemplate) {
            if (messageTemplate.header) {
                if (messageTemplate.header.type === 2) {
                    messageTemplate.data = messageTemplate.header.link !== '' ? messageTemplate.header.link
                        : WatiService.makeUriToShowImageByWati(vm.watiSetting.watiUri,
                            'data/images/' + messageTemplate.header.mediaFromPC, vm.watiSetting.watiToken);
                } else if (messageTemplate.header.type === 3) {
                    messageTemplate.data = messageTemplate.header.link !== '' ? messageTemplate.header.link
                        : WatiService.makeUriToShowImageByWati(vm.watiSetting.watiUri,
                            'data/videos/' + messageTemplate.header.mediaFromPC, vm.watiSetting.watiToken);
                } else if (messageTemplate.header.type === 4) {
                    messageTemplate.data = messageTemplate.header.link !== '' ? messageTemplate.header.link
                        : 'data/documents/' + messageTemplate.header.mediaFromPC;
                }
            }
        }

        vm.nextStep = function () {
            vm.currentStep = 2;
            if (!vm.contacts.length) {
                getContacts();
            }
        }

        vm.previousStep = function () {
            vm.currentStep = 1;
        }

        vm.closeModal = function (response) {
            $uibModalInstance.close(response);
        }

        function searchTemplateMessages() {
            vm.loadingTemplates = true;
            var promise = WatiService.getTemplateMessages();
            var callback = function(result) {
                vm.messageTemplates = result.data.messageTemplates;

                vm.messageTemplates.forEach(function (messageTemplate) {
                    setMediaFromTemplate(messageTemplate);
                });

                vm.originalTemplates = angular.copy(vm.templates);
                vm.loadingTemplates = false;
            }
            promise.then(callback).catch(function(err) {
                promise.then(callback);
            });

        }

        vm.querySearchTemplate = function(query) {
            var results = query ? vm.messageTemplates.filter(createFilterFor(query)) : vm.messageTemplates;
            var defer = $q.defer();
            defer.resolve(results);
            return defer.promise;
        }

        function createFilterFor(query) {
            var lowercaseQuery = query.toLowerCase();

            return function filterFn(template) {
                return (template.elementName.toLowerCase().indexOf(lowercaseQuery) === 0);
            };
        }

        vm.formatWhatsAppMessage = function (text) {
            return text.replace(/(?:\*)(?:(?!\s))((?:(?!\*|\n).)+)(?:\*)/g,'<b>$1</b>')
                .replace(/(?:_)(?:(?!\s))((?:(?!\n|_).)+)(?:_)/g,'<i>$1</i>')
                .replace(/(?:~)(?:(?!\s))((?:(?!\n|~).)+)(?:~)/g,'<s>$1</s>')
                .replace(/(?:--)(?:(?!\s))((?:(?!\n|--).)+)(?:--)/g,'<u>$1</u>')
                .replace(/(?:```)(?:(?!\s))((?:(?!\n|```).)+)(?:```)/g,'<tt>$1</tt>');
        }

        vm.getDownloadLinkToTemplate = function () {
            return $window.location.protocol + "//" + $window.location.host + '/content/docs/template_contatos.xlsx';
        }

        function getContacts(importedContacts) {
            vm.loadingContacts = true;
            vm.contacts = [];

            vm.filters.pageNumber = vm.filters.pageNumber - 1;
            BroadcastListService.getContacts(vm.filters).then(function (response) {
                console.log(response);
                vm.contacts = response.result.items;
                selectContacts(importedContacts);
                vm.filters.filterAttributes = response.result.filterAttributes;
                vm.filters.sortBy = response.result.sortBy;
                vm.filters.orderBy = response.result.orderBy;
                vm.filters.pageSize = response.result.pageSize;
                vm.filters.pageNumber = response.result.pageNumber + 1;
                vm.totalItems = response.result.total;
                vm.loadingContacts = false;
            });
        }

        function selectContacts(importedContacts) {
            if (importedContacts) {
                importedContacts.forEach(function (contact) {
                    vm.form.selectedContactIds.push(contact.id)
                });
            }
        }

        vm.refreshContacts = function () {
            getContacts();
        }

        vm.sortContactsBy = function (sortBy) {
            if (vm.filters.sortBy === vm.SORTBY_VALUES[sortBy]) {
                if (vm.filters.orderBy === 'asc') {
                    vm.filters.orderBy = 'desc';
                } else {
                    vm.filters.orderBy = 'asc';
                }
            } else {
                vm.filters.orderBy = 'asc';
            }
            vm.filters.sortBy = vm.SORTBY_VALUES[sortBy];
            getContacts();
        }

        vm.addBroadcastList = function () {
            vm.loadingAddBroadcast = true;
            if (vm.form.whenSend === SCHEDULED_SENDING) {
                vm.form.scheduledAt = moment(vm.scheduledAt, format).toDate();
            } else {
                vm.form.scheduledAt = moment().add(1, 'minutes').toDate();
            }
            BroadcastListService.addBroadcastList(vm.form).then(function (response) {
                vm.loadingAddBroadcast = false;
                vm.closeModal(response);
            }).catch(function (err) {
                vm.loadingAddBroadcast = false;
                toastr.error($translate.instant('communication.broadcast-list-modal.request-messages.broadcast-error'));
            });
        }

        vm.loadFile = function (file, errFiles) {
            var formData = new FormData();
            vm.loadingImportContacts = true;
            formData.append('File', file);
            formData.append('RewriteAll', true);
            file.inProgress = true;
            BroadcastListService.importContacts(formData).then(function (response) {
                console.log(response);
                if (response) {
                    getContacts(response);
                } else {
                    getContacts();
                }
                
                vm.loadingImportContacts = false;
                toastr.success($translate.instant('communication.broadcast-list-modal.request-messages.import-success'));
            }).catch(function (err) {
                vm.loadingImportContacts = false;
                toastr.error($translate.instant('communication.broadcast-list-modal.request-messages.import-error'));
            });
        };

        function dateTimePicker() {
            vm.scheduleDateOptions = {
                sideBySide: true,
                ignoreReadonly: true,
                locale: moment.locale(),
                allowInputToggle: true,
                showClear: true,
                widgetPositioning: {
                    horizontal: 'auto',
                    vertical: 'top'
                },
                minDate: moment()
            };

            angular.element('#scheduleDate').datetimepicker(vm.scheduleDateOptions).on('dp.change', function(ev) {
                if (ev.date) {
                    $scope.$apply(function() {
                        vm.scheduledAt = ev.date.format(format);
                    });
                } else {
                    $scope.$apply(function() {
                        vm.scheduledAt = undefined;
                    });
                }
            });
        }

        vm.numberOfContactsLabel = function () {
            if (vm.form.selectedContactIds.length <= 1) {
                return $translate.instant('communication.broadcast-list-modal.contact');
            } else {
                return $translate.instant('communication.broadcast-list-modal.contacts');
            }
        }

        vm.selectOne = function (contact) {
            if (vm.form.IsAllSelected) {
                if (vm.form.deselectedContactIds.indexOf(contact.id) != -1) {
                    vm.form.deselectedContactIds.splice(vm.form.deselectedContactIds.indexOf(contact.id), 1);
                    contact.isSelected = true;
                } else {
                    vm.form.deselectedContactIds.push(contact.id);
                    contact.isSelected = false;
                }

                if (vm.form.deselectedContactIds.length === vm.totalItems) {
                    vm.form.selectedContactIds = [];
                    vm.form.deselectedContactIds = [];
                    vm.form.IsAllSelected = false;
                }
            } else {
                if (vm.form.selectedContactIds.indexOf(contact.id) != '-1') {
                    vm.form.selectedContactIds.splice(vm.form.selectedContactIds.indexOf(contact.id), 1);
                    contact.isSelected = false;
                } else {
                    vm.form.selectedContactIds.push(contact.id);
                    contact.isSelected = true;
                }
            }
        }

        vm.selectAll = function () {
            if (vm.form.IsAllSelected && vm.form.deselectedContactIds.length > 0) {
                vm.form.selectedContactIds = [];
                vm.form.deselectedContactIds = [];
                vm.form.IsAllSelected = true;
            } else if (vm.form.IsAllSelected || vm.isAllSelected()) {
                vm.form.IsAllSelected = false;
                vm.form.selectedContactIds = [];
                vm.form.deselectedContactIds = [];
            } else {
                vm.form.IsAllSelected = true;
            }
        }

        vm.isAllSelected = function () {
            return vm.form.selectedContactIds.length > 0 && vm.contacts.every(function(contact) { return contact.isSelected })
                || vm.form.IsAllSelected && vm.form.deselectedContactIds.length === 0;
        }

        vm.isIndeterminate = function () {
            return vm.form.selectedContactIds.length > 0 && vm.form.selectedContactIds.length < vm.totalItems
                || vm.totalSelected() > 0 && vm.totalSelected() < vm.totalItems;
        }

        vm.checkIfContactSelected = function(contactId) {
            return vm.form.selectedContactIds.indexOf(contactId) != '-1'
                || vm.form.IsAllSelected && vm.form.deselectedContactIds.indexOf(contactId) === -1;
        }

        vm.totalSelected = function () {
            return vm.form.IsAllSelected ? vm.totalItems - vm.form.deselectedContactIds.length : vm.form.selectedContactIds.length;
        }

        vm.selectContactsValidation = function () {
            return vm.totalSelected() > 0 && (vm.form.whenSend === SCHEDULED_SENDING ? moment(vm.scheduledAt, format).isValid() : true);
        }

        vm.selectTemplateValidation = function () {
            return vm.form.broadcastName && vm.form.templateId;
        }
    }
})();
