(function() {
    'use strict';

    angular
        .module('showcaseApp')
        .factory('Account', Account);

    Account.$inject = ['$resource'];

    function Account($resource) {
        var service = $resource('api/account', {}, {
            'get': {
                method: 'GET',
                params: {},
                isArray: false,
                interceptor: {
                    response: function(response) {
                        return response;
                    }
                }
            },
            'isAvaliableUsername': {
                method: 'POST',
                params: {},
                isArray: false,
                url: 'api/account/is-avaliable-username',
                interceptor: {
                    response: function(response) {
                        return response;
                    }
                }
            },
            'savePushNotificationId': {
                method: 'POST',
                params: {},
                isArray: false,
                url: 'api/account/save-push-notification-id',
                interceptor: {
                    response: function(response) {
                        return response;
                    }
                }
            },
            'getGatewayWirecardPaymentLinks': {
                method: 'GET',
                isArray: false,
                url: 'api/gateway/get-payment-gateway-links',
                interceptor: {
                    response: function(response) {
                        return response.data;
                    }
                }
            },
            'isAvaliableEmail': {
                method: 'POST',
                params: {},
                isArray: false,
                url: 'api/account/is-avaliable-email',
                interceptor: {
                    response: function(response) {
                        return response;
                    }
                }
            },
            'storeOwnerHasMoipAccount': {
                method: 'GET',
                params: {},
                isArray: false,
                url: 'api/account/te',
                interceptor: {
                    response: function(response) {
                        return response;
                    }
                }
            },
            'setCodeAndSaveAccessToken': {
                method: 'GET',
                isArray: false,
                url: 'api/account/gateway-payment/receive-code/:code',
                interceptor: {
                    response: function(response) {
                        return response;
                    }
                }
            },
            'disconnectFromPaymentGatewayWirecard': {
                method: 'GET',
                isArray: false,
                url: 'api/account/gateway-payment/disconnect',
                interceptor: {
                    response: function(response) {
                        return response;
                    }
                }
            },
            'updateTerm': {
                method: 'POST',
                url: 'api/account/accepted-term'
            },
            'setRecipient': {
                method: 'POST',
                params: {},
                isArray: false,
                url: '/api/owner/:ownerId/recipient',
                interceptor: {
                    response: function(response) {
                        return response;
                    }
                }
            },
            'updateRecipient': {
                method: 'PUT',
                params: {},
                isArray: false,
                url: '/api/owner/:ownerId/recipient',
                interceptor: {
                    response: function(response) {
                        return response;
                    }
                }
            },
            'getRecipient': {
                method: 'GET',
                isArray: false,
                url: '/api/owner/:ownerId/recipient',
                 interceptor: {
                    response: function(response) {
                        return response;
                    }
                }
            },
            'getBalance': {
                method: 'GET',
                params: {},
                isArray: false,
                url: '/api/owner/:ownerId/recipient/:recipientId/balance',
                 interceptor: {
                    response: function(response) {
                        return response;
                    }
                }
            },
            'setCheckoutMethod': {
                method: 'PUT',
                params: {},
                isArray: false,
                url: 'api/account/checkout-method',
                transformResponse: function(data, headers) {
                    var response = {};
                    response.headers = headers();
                    try{
                        response.data = data;
                        return response
                    }catch (err){
                        return response;
                    }
                }
            },
            'forceLogout': {
                method: 'POST',
                params: {},
                isArray: false,
                url: 'api/account/forceLogout',
                interceptor: {
                    response: function(response) {
                        return response;
                    }
                }
            },
            'getOwnerKeys': {
                method: 'GET',
                url: '/flyer/api/payment/owner/keys/:storeId',
                transformResponse: function(data, headers) {
                    var response = {};
                    if(data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            }

        });

        return service;
    }
})();
