(function() {
    'use strict';

    angular
        .module('showcaseApp')
        .controller('ResetFinishController', ResetFinishController);

    ResetFinishController.$inject = ['$scope', '$state', '$stateParams', '$timeout', 'Auth'];

    function ResetFinishController ($scope, $state, $stateParams, $timeout, Auth) {
        var vm = this;

        vm.keyMissing = angular.isUndefined($stateParams.key);
        vm.error = null;
        vm.finishReset = finishReset;
        vm.login = login;
        vm.success = null;

        $timeout(function (){angular.element('#password').focus();});

        function finishReset() {
            vm.error = null;
            if (vm.password === vm.confirmPassword) {
                Auth.resetPasswordFinish({key: $stateParams.key, newPassword: vm.password}).then(function () {
                    vm.success = 'OK';
                }).catch(function () {
                    vm.success = null;
                    vm.error = 'ERROR';
                    $scope.form.$setPristine();
                    vm.password = null;
                    vm.confirmPassword = null;
                });
            }
        }
        
        function login() {
            $state.go('main-login')
        }
    }
})();
