(function () {
    'user strict'

    angular
        .module('showcaseApp')
        .controller('ConsumersController', ConsumersController);

    //** NgInject */
    function ConsumersController() {

        var vm = this;
        vm.onTabSelected = onTabSelected;

        function onTabSelected(tab) {
            vm.selectedTab = tab;
        }
    }
})();
