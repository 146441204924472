(function() {
    'use strict';
    angular
        .module('showcaseApp')
        .factory('StoreFactoryAdmin', StoreFactoryAdmin);

    StoreFactoryAdmin.$inject = ['$resource'];

    function StoreFactoryAdmin($resource) {
        var resourceUrl = 'admin/api/store/:id';

        return $resource(resourceUrl, {}, {
            'countStoresByCurrentHubAndStoreType': {
                method: 'GET',
                isArray: false,
                url: 'admin/api/store/count/current-hub/store-type'
            },
            'listAllStoreByHub': {
                method: 'GET',
                isArray: true,
                url: 'admin/api/store/store-and-sales-by-hub/current-hub'
            },
            'query': {
                method: 'GET',
                isArray: false,
                transformResponse: function(data, headers) {
                    var response = {};
                    response.data = angular.fromJson(data);
                    response.headers = headers();
                    return response;
                }
            },
            'deletebyids': {
                method: 'POST',
                url: '/admin/api/store/deletebyids'
            },
            'listAll': {
                method: 'POST',
                url: '/admin/api/store/list',
                transformResponse: function(data, headers) {
                    var response = {};
                    response.data = angular.fromJson(data);
                    response.headers = headers();
                    return response;
                }
            },
            'saveBankInfo': {
                method: 'POST',
                url: '/admin/api/store/save-bank-info',
                transformResponse: function(data, headers) {
                    var response = {};
                    if (angular.isObject(data)) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'getPicPayConfiguration': {
                method: 'GET',
                url: '/admin/api/payment-configuration/:storeId',
                transformResponse: function(data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'getDupayConfiguration': {
                method: 'GET',
                url: '/admin/api/dupayPaymentConfiguration/payment-configuration/:storeId',
                transformResponse: function(data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'getPaymentSettings': {
                method: 'GET',
                url: '/admin/api/payment-settings/:storeId',
                transformResponse: function(data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'updateToBeAgreedValue': {
                method:'POST',
                isArray: false,
                url: 'admin/api/payment-settings/:storeId/updateToBeAgreed'
            },
            'updateRedirectToSellerValue': {
                method:'POST',
                isArray: false,
                url: 'admin/api/payment-settings/:storeId/updateRedirectToSeller'
            },
            'savePicPayConfiguration': {
                method: 'POST',
                url: '/admin/api/payment-configuration',
                transformResponse: function(data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'saveDupayConfiguration': {
                method: 'POST',
                url: '/admin/api/dupayPaymentConfiguration/payment-configuration',
                transformResponse: function(data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'getTBanksConfiguration': {
                method: 'GET',
                url: '/admin/api/tBanksPaymentConfiguration/payment-configuration/:storeId',
                transformResponse: function(data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'saveTBanksConfiguration': {
                method: 'POST',
                url: '/admin/api/tBanksPaymentConfiguration/payment-configuration',
                transformResponse: function(data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'verifyTBanksConfirmationToken': {
                method: 'POST',
                url: '/admin/api/tBanksPaymentConfiguration/payment-configuration/verify-token',
                transformResponse: function(data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'resendTBanksConfirmationToken': {
                method: 'POST',
                url: '/admin/api/tBanksPaymentConfiguration/payment-configuration/resend-token',
                transformResponse: function(data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'get': {
                method: 'GET',
                transformResponse: function(data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'isCnpjAvailable': {
                method: 'GET',
                url: "/admin/api/store/is-cnpj-available",
                transformResponse: function(data, headers) {
                    var response = {};
                    response.data = angular.fromJson(data);
                    response.headers = headers();
                    return response;
                }
            },
            'authorizeShipping': {
                method: 'GET',
                headers: {
                    'Accept': '*/*'
                },
                url: "/admin/api/shipping/authorize",
                transformResponse: function(data, headers) {

                    if (headers() && headers().location) {
                        window.location.href = headers().location
                    }
                    var response = {};
                    response.headers = headers();
                    return data;
                }
            },
            'authorizeBling': {
                method: 'GET',
                headers: {
                    'Accept': '*/*'
                },
                url: "/admin/api/bling/authorize",
                transformResponse: function(data, headers) {

                    if (headers() && headers().location) {
                        window.location.href = headers().location
                    }
                    var response = {};
                    response.headers = headers();
                    return data;
                }
            },
            'checkShippingConnected': {
                method: 'GET',
                headers: {
                    'Accept': '*/*'
                },
                url: "/admin/api/shipping/isConnected",
                // transformResponse: function (data, headers) {

                //     var response = {};
                //     response.headers = headers();
                //     return data;
                // }
            },
            'isNicknameAvailable': {
                method: 'GET',
                url: "/admin/api/store/isNicknameAvailable",
                transformResponse: function(data, headers) {
                    var response = {};
                    response.data = angular.fromJson(data);
                    response.headers = headers();
                    return response;
                }
            },
            'isStoreOwner': {
                method: 'GET',
                url: "/admin/api/store/is-store-owner",
                transformResponse: function(data, headers) {
                    var response = {};
                    response.data = angular.fromJson(data);
                    response.headers = headers();
                    return response;
                }
            },
            'getStepsData': {
                url: 'admin/api/store/getStoreStepByStep',
                method: 'GET',
                transformResponse: function(data, headers) {
                    var response = {};
                    response.data = angular.fromJson(data);
                    response.headers = headers();
                    return response;
                }
            },
            'setStoreNotSample': {
                method: 'PUT',
                url: '/admin/api/store/store-sample/:id',
                transformResponse: function(data, headers) {
                    var response = {};
                    response.data = angular.fromJson(data);
                    response.headers = headers();
                    return response;
                }
            },
            'update': {
                method: 'PUT'
            },
            'delete': {
                method: 'DELETE'
            },
            'cssHub': {
                url: 'admin/api/cssHub/:name',
                method: 'GET',
                transformResponse: function(data, headers) {
                    var response = {};
                    response.data = angular.fromJson(data);
                    response.headers = headers();
                    return response;
                }
            },
			'getOwnerCategoryCurrentStore': {
                url: 'admin/api/category/owner/current-store',
                method: 'GET',
                transformResponse: function(data, headers) {
                    var response = {};
                    response.data = data;
                    response.headers = headers();
                    return response;
                }
            },
            'updateDefaultFilter': {
                url: 'admin/api/store/default-filter',
                method: 'PUT',
                transformResponse: function(data, headers) {
                    var response = {};
                    response.data = data;
                    response.headers = headers();
                    return response;
                }
            },
        });
    }
})();
