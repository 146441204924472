(function() {
    'use strict';

    angular
        .module('showcaseApp')
        .constant('WatiConstants', {
            TEMPLATE_URI_TO_SHOW_MEDIA_BY_WATI: '{watiUri}/api/file/showFile?fileName={fileName}&token={watiToken}',
            TEMPLATE_URI_TO_UPLOAD_CHUNK: '{watiUri}/api/v1/conversations/whatsapp/uploadChunk{isLastChunk}'
        });
})();
