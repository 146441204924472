(function() {
    'use strict';

    angular
        .module('showcaseApp')
        .controller('NewConversationController', NewConversationController);

        NewConversationController.$inject = ['watiSetting', '$uibModalInstance', '$translate', 'WatiService', '$localStorage', 'toastr', 'MessengerService'];

    function NewConversationController(watiSetting, $uibModalInstance, $translate, WatiService, $localStorage, toastr, MessengerService) {

        var vm = this;
        vm.watiSetting = watiSetting;

        vm.specialKey = '{{ }}';

        vm.templates = [];
        vm.originalTemplates = [];

        vm.step = 0;

        vm.currentTemplate;

        vm.customer = {};

        vm.isFb = false;

        vm.phone = '';

        vm.isConnectedToWati = vm.watiSetting;

        vm.loadingTemplates = false;

        var storeId = $localStorage.defaultStore.storeId;

        searchTemplateMessages();

        function searchTemplateMessages() {
            vm.loadingTemplates = true;
            var promise = WatiService.searchTemplateMessages(100, 0);
            var callback = function(result) {
                vm.templates = result.data.messageTemplates;
                vm.originalTemplates = angular.copy(vm.templates);
                vm.loadingTemplates = false;
            }
            promise.then(callback).catch(function(err) {
                promise.then(callback);
            });

        }

        vm.nextStep = function() {
            if(vm.step === 0 && (vm.phone || vm.selectedCustomerToSendMessage.phone) ) {
                vm.step = 1;
                return;
            } else if(vm.step === 1 && vm.currentTemplate) {
                vm.step = 2;
                return;
            }
        }

        vm.previousStep = function() {
            if(vm.step === 2 && vm.isFb) {
                vm.step = vm.step - 2;
                vm.isFb = false;
            } else {
                vm.step = vm.step - 1;
            }

        }

        vm.closeModal = function(response) {
            $uibModalInstance.close(response);
        }

        vm.onSelectTemplate = function(template) {
            if(vm.currentTemplate && vm.currentTemplate.id === template.id) {
                vm.currentTemplate = null;
                return;
            }
            vm.currentTemplate = template;
            vm.bodyOriginal = vm.currentTemplate.bodyOriginal;
            vm.params = {};
        }

        vm.makeParamNameWithKeys = function(paramName) {
            return '{{' + paramName + '}}';
        }

        vm.changeParamValue = function(paramName) {
            updateTemplateBody(vm.params[paramName]);
        }

        function updateTemplateBody() {
            var keys = Object.keys(vm.params).join('|');
            var regex = new RegExp(keys, 'gi');
            vm.currentTemplate.bodyOriginal = vm.bodyOriginal.replace(regex, function(matched) {
                return vm.params[matched].value || matched;
            });
        }

        vm.selectedCustomer = function (item) {
            vm.selectedCustomerToSendMessage = item.originalObject;
            if(vm.phone || vm.selectedCustomerToSendMessage.phone || vm.selectedCustomerToSendMessage.facebookInstagramId) {
                if(vm.selectedCustomerToSendMessage.facebookInstagramId) {
                    vm.isFb = true;
                    vm.step = 2;
                    return;
                }
                if (!vm.isConnectedToWati) {
                    toastr.warning($translate.instant('communication.new-conversation.connectToWati'));
                    return;
                } else {
                    vm.step = 1;
                    return;
                }

            }
            toastr.warning($translate.instant('communication.new-conversation.noFbAndWhatsapp'));
        }

        vm.changeCustomer = function (value) {
            if (value) {
                vm.customer.fullName = value.trim();
            } else {
                vm.customer.fullName = null;
            }
        }

        vm.requestParams = function (query) {
            return {
                page: 0,
                size: 10,
                storeId: storeId,
                search: query
            }
        }

        function removeNine(numberWithNine) {
            if (numberWithNine.includes('+55') && numberWithNine.length === 14) {
                numberWithNine = numberWithNine.substring(0, 5) + numberWithNine.substring(6, numberWithNine.length);
            }
            return numberWithNine;
        }

        vm.sendTemplate = function() {
            if(vm.isFb && vm.tagMessage) {
                MessengerService.sendMessage(vm.tagMessage, vm.selectedCustomerToSendMessage.facebookInstagramId, 'MESSAGE_TAG').then(function() {
                    toastr.success($translate.instant('communication.new-conversation.success'));
                    vm.closeModal({user: vm.selectedCustomerToSendMessage.facebookInstagramId, message: vm.tagMessage, type: 'facebok'});
                });
                return;
            }

            var parameters = [];
            vm.currentTemplate.customParams.forEach(function(param) {
                parameters.push({name: param.paramName, value: vm.params[vm.makeParamNameWithKeys(param.paramName)] ? vm.params[vm.makeParamNameWithKeys(param.paramName)].value : param.paramValue});
            });

            WatiService.sendTemplateMessage(vm.currentTemplate.elementName, parameters, vm.phone || vm.selectedCustomerToSendMessage.phone).then(function(result) {
                toastr.success($translate.instant('communication.new-conversation.success'));
                vm.closeModal({user: vm.phone || vm.selectedCustomerToSendMessage.phone, message: vm.currentTemplate, type: 'wati'});
            });
        }

        vm.localSearch = function() {
            vm.templates = vm.originalTemplates.filter(function(template) {
                return template.elementName.replaceAll('_', ' ').toUpperCase().includes(vm.search.toUpperCase());
            });
        }

        vm.paramIsInBody = function(param) {
            return vm.currentTemplate.bodyOriginal.includes('{{' + param.paramName + '}}');
        }
    }

})();
