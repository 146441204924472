(function () {
    'use strict';
    angular
        .module('showcaseApp')
        .controller('SocialMediaController', SocialMediaController);

    function SocialMediaController($scope, moment, FlyersService, FlyersCurrencyValue, $translate, SocialMediaService,
        toastr, Upload, $auth, SelectOfferModalService, facebookConnected, ModalFactory, InstagramLoginModalService, instagramConnected, $localStorage, FBAuthModalFactory) {

        var vm = this;

        var format = 'L LT';
        vm.isSaving = false;

        vm.postDTOForm = {};
        vm.facebookDataConnected = facebookConnected.facebookDataConnected || undefined;

        vm.postDTO = facebookConnected.postDTO || {};
        vm.postDTO.sendImmediately = true;
        vm.postDTO.picture = null;
        vm.postDTO.schedule = null;
        vm.postDTO.message = "";
        vm.postDTO.pageSelected = vm.facebookDataConnected ? angular.toJson(vm.facebookDataConnected.pages[0]) : undefined;

        vm.isToSchedule = false;
        vm.isToNow = true;

        vm.facebookConnected = facebookConnected.facebookConnected || false;
        vm.facebookSelected = facebookConnected.facebookSelected || false;

        vm.flyerId = undefined;

        vm.image = undefined;

        vm.firstTry = true;

        vm.init = init;

        vm.instagramConnected = instagramConnected.instagramConnected || false;
        vm.instagramAccountConnected = instagramConnected.instagramAccountConnected ? instagramConnected.instagramAccountConnected.username : null;
        vm.instagramSelected = false;

        function onSuccessPost(response) {
            toastr.success($translate.instant('socialMedia.success.successPost'));
            vm.isSaving = false;
            vm.postDTO = {};
            vm.postDTO.sendImmediately = true;
            vm.postDTO.picture = null;
            vm.postDTO.schedule = null;
            vm.postDTO.message = "";
        }

        function onErrorPost(response) {
            toastr.error($translate.instant('socialMedia.error.errorPost'));
            vm.isSaving = false;
            if (vm.facebookSelected) {
                vm.isFacebookConnected();
            } else if (vm.instagramSelected) {
                vm.isInstagramConnected();
            }

        }

        function onSuccessFacebookConnect(response) {
                vm.isFacebookConnected();
                toastr.success($translate.instant('socialMedia.success.successFacebookConnect'));
        }

        function onErrorFacebookConnect(error){
            if (error.data) {
                if (error.data.headers['x-adminapp-alert'] == 'noPagesFound'){
                    ModalFactory.openSimpleModal(
                        $translate.instant('socialMedia.error.noPagesFoundTitle'),
                        $translate.instant('socialMedia.error.noPagesFoundMessage'),
                        $translate.instant('socialMedia.error.noPagesFoundConfirmText'),
                        null,
                        vm.facebookDisconnect).then(null, function (result){
                            // If backdrop click, disconnect facebook;
                            vm.facebookDisconnect();
                        });
                }
            }
        }

        function onSuccessIsFacebookConnect(response) {
            if (response.data && response.data.accessToken) {
                vm.facebookConnected = true;
                vm.facebookDataConnected = response.data;
                if (response.data.pages && response.data.pages[0]){
                    vm.postDTO.pageId = response.data.pages[0].pageId;
                    vm.postDTO.pageSelected = angular.toJson(response.data.pages[0]);
                }
            } else {
                if (!vm.firstTry) {
                    vm.facebookConnected = false;
                    toastr.warning($translate.instant('socialMedia.error.facebookSessionExpired'));
                    vm.facebookSelected = false;
                    $('#button_facebook').css("background-color", "grey");
                }

            }

            vm.connectOrSelectFacebook(true);
        }


        function onSuccessFacebookDisconnect(response) {
            if (response.data) {
                vm.facebookConnected = false;
                vm.facebookSelected = false;
            } else {
                toastr.warning($translate.instant('socialMedia.error.cannotLoggout'));
            }

        }

        vm.openSelectionFlyers = function () {
            SelectOfferModalService.selectOffers(true, 'selectOfferModal.singleSelectionTitle').result.then(function (flyer) {
                if (flyer) {
                    vm.flyerId = flyer.id;
                    vm.postDTO.message = flyer.description;
                    vm.getImageFlyer();
                }
            });
        }

        vm.changePage = function () {
            if (!vm.postDTO.pageSelected) return;
            vm.postDTO.page = angular.fromJson(vm.postDTO.pageSelected);
            vm.postDTO.facebookPageId = vm.postDTO.page.pageId;
        }


        vm.doPost = function () {
            if (!vm.isInvalidForm()) {
                vm.isSaving = true;
                if (vm.postDTO.schedule && !checkStartDateHasPassed()) {
                    toastr.error($translate.instant('socialMedia.error.startDateHasPassed'));
                    vm.isSaving = false;
                    return;
                }
                if (vm.postDTO.schedule) {
                    if (moment(vm.postDTO.schedule, "DD/MM/YYYY hh:mm")._isValid) {
                        vm.postDTO.schedule = moment(vm.postDTO.schedule, "DD/MM/YYYY hh:mm");
                    } else if (moment(vm.postDTO.schedule, "MM/DD/YYYY hh:mm")._isValid){
                        var date = moment(vm.postDTO.schedule, "MM/DD/YYYY hh:mm A");
                        vm.postDTO.schedule = date;
                    }
                }
                if (vm.facebookSelected) {
                    SocialMediaService.doAFacebookPost(vm.postDTO, onSuccessPost, onErrorPost);
                } else {
                    vm.postDTO.post_type = 'feed';
                    SocialMediaService.doAInstagramPost(vm.postDTO, onSuccessPost, onErrorPost);
                }
            }
        }

        vm.getImageFlyer = function () {
            //TODO: Here it will be done a get to image if the flyer will be selected and no image uploaded!
            if (vm.flyerId) {
                FlyersService.getLinkImageFlyerAsString(vm.flyerId).then(function (response) {
                    vm.image = undefined;
                    vm.postDTO.picture = response.data;
                });
            }
        }

        function dataURItoBlob(dataURI) {
            // convert base64/URLEncoded data component to raw binary data held in a string
            var byteString;
            if (dataURI.split(',')[0].indexOf('base64') >= 0)
                byteString = atob(dataURI.split(',')[1]);
            else
                byteString = unescape(dataURI.split(',')[1]);

            // separate out the mime component
            var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

            // write the bytes of the string to a typed array
            var ia = new Uint8Array(byteString.length);
            for (var i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
            }

            return new Blob([ia], {
                type: mimeString
            });
        }

        vm.facebookDisconnect = function(){
            SocialMediaService.facebookDisconnect().then(onSuccessFacebookDisconnect);
        }

        vm.selectedImage = function (file) {
            if (file) {
                Upload.base64DataUrl(file).then(function (url) {
                    vm.postDTO.picture = url;
                });
            }
        }

        vm.isFacebookConnected = function () {
            SocialMediaService.isFacebookConnected().then(onSuccessIsFacebookConnect);
        }

        function loadAll() {
            loadFlyers();
            vm.connectOrSelectFacebook(true);
            vm.connectOrSelectInstagram(true);
            vm.firstTry = false;
        }

        vm.excludeImageUploaded = function () {
            vm.image = undefined;
            vm.postDTO.picture = undefined;
        }

        vm.isInvalidForm = function () {
            vm.changePage();
            if (!vm.postDTO.message ||
                (!vm.facebookSelected && !vm.instagramSelected) ||
                (!vm.postDTO.picture && !vm.image) ||
                (vm.isToSchedule && !vm.postDTO.schedule) ||
                (vm.isToNow && !vm.postDTO.sendImmediately) ||
                (vm.facebookSelected && !vm.postDTO.page)) {
                return true;
            }
            return false;
        }

        function init() {

            dateTimePicker();
        }

        vm.toNow = function () {
            if (vm.isToNow) {
                vm.isToSchedule = false;
                vm.postDTO.sendImmediately = true;
            } else if (!vm.isToSchedule) {
                vm.isToNow = true;
            }

            vm.postDTO.schedule = undefined;
        }


        vm.toSchedule = function () {
            if (vm.isToSchedule) {
                vm.isToNow = false;
                vm.postDTO.sendImmediately = false;
            } else if (!vm.isToNow) {
                vm.isToSchedule = true;
            }
        }

        vm.connect = function (provider) {
            return $auth.authenticate(provider);
        }

        vm.connectOrSelectFacebook = function (toSelect) {
            if (vm.facebookConnected) {
                if (vm.facebookSelected) {
                    vm.facebookSelected = false;
                    $('#button_facebook').css("background-color", "grey");
                } else {
                    vm.facebookSelected = true;
                    vm.instagramSelected = false;
                    $('#button_facebook').css("background-color", "#4267b2");
                }
            } else if (!toSelect) {
                FBAuthModalFactory.connect(false).then(onSuccessFacebookConnect, onErrorFacebookConnect);
                // TODO: Aqui vai connectar o facebook e deixar selecionado
            }
        }

        function checkStartDateHasPassed() {
            if (!vm.postDTO.schedule) return false;
            return moment(vm.postDTO.schedule, format).isAfter(moment());
        }

        function dateTimePicker() {

            vm.scheduleOptions = {
                format: format,
                sideBySide: true,
                ignoreReadonly: true,
                locale: moment.locale(),
                allowInputToggle: true,
                useCurrent: false,
                defaultDate: vm.postDTO.schedule ? moment(vm.postDTO.schedule) : false,
                widgetPositioning: {
                    horizontal: 'auto',
                    vertical: 'top'
                }
            };

            angular.element('#schedulePicker').datetimepicker(vm.scheduleOptions).on('dp.change', function (ev) {
                angular.element('#schedulePicker').data("DateTimePicker").minDate(checkStartDateHasPassed() ? moment() : vm.postDTO.schedule);
                if (ev.date) {
                    vm.postDTO.schedule = ev.date.format(format);
                    $scope.$apply(function () {
                        vm.postDTOForm.schedule.$setValidity('minDate', true);
                    });
                } else {
                    vm.postDTO.schedule = undefined;
                }
            });

            vm.postDTO.schedule = vm.postDTO.schedule ? moment(vm.postDTO.schedule).format(format) : null;

            angular.element('#schedulePicker').data("DateTimePicker").minDate(checkStartDateHasPassed() ? moment() : vm.postDTO.schedule);

        }

        function loadFlyers() {

            FlyersService.listAllFlyers().then(onSuccess);

            function onSuccess(response) {
                vm.flyersFiltered = response;
            }
        }

        vm.getFlyerCurrency = function (currency) {
            return FlyersCurrencyValue[currency];
        }

        vm.toggleSelection = function toggleSelection(flyerId) {
            // Cannot have more than one, so follow the implementation
            if (vm.flyerId) {
                vm.flyerId = undefined;
                vm.postDTO.picture = undefined;
            } else {
                vm.flyerId = undefined;
                vm.getImageFlyer();
            }

        }

        function onSuccessIsInstagramConnect(response) {
            vm.instagramConnected = true;
        }

        function onErrorIsInstagramConnect(error) {
            if (error.status === 403) {
                vm.instagramConnected = vm.instagramSelected = false;
                toastr.warning($translate.instant('socialMedia.error.instagramSessionExpired'));
            } else {
                toastr.warning($translate.instant('socialMedia.error.errorInstagramConnect.connection'));
            }
        }

        vm.isInstagramConnected = function () {
            SocialMediaService.isInstagramConnected().then(onSuccessIsInstagramConnect, onErrorIsInstagramConnect);
        }

        vm.connectOrSelectInstagram = function (toSelect) {
            if (vm.instagramConnected) {
                if (vm.instagramSelected) {
                    vm.instagramSelected = false;
                } else {
                    vm.instagramSelected = true;
                    vm.facebookSelected = false;
                    vm.isToSchedule = false;
                    vm.isToNow = true;
                }
            } else if (!toSelect) {
                InstagramLoginModalService.openLoginModal(true, 'Instagram').result.then(function (result) {
                    if (result && result.data) {
                        vm.instagramConnected = true;
                        vm.instagramAccountConnected = result.data.username;
                        toastr.success($translate.instant('socialMedia.success.successInstagramConnect'));
                    } else {
                        toastr.warning($translate.instant('socialMedia.error.errorInstagramConnect.connection'));
                    }
                });
                }
        }

        function onSuccessInstagramDisconnect(response) {
            if (response.data) {
                vm.instagramConnected = false;
                vm.instagramSelected = false;
                vm.instagramAccountConnected = null;
            } else {
                toastr.warning($translate.instant('socialMedia.error.cannotLoggout'));
            }
        }

        function onErrorInstagramDisconnect() {
            toastr.warning($translate.instant('socialMedia.error.cannotLoggout'));
        }

        vm.instagramDisconnect = function () {
            SocialMediaService.instagramDisconnect().then(onSuccessInstagramDisconnect, onErrorInstagramDisconnect);
        }

        loadAll();

    }
})();
