(function () {
    'use strict';

    angular.module('showcaseApp').factory('SatisfactionSurveyService', SatisfactionSurveyService);

    SatisfactionSurveyService.$inject = ['SatisfactionSurveyFactory', '$uibModal'];

    function SatisfactionSurveyService(SatisfactionSurveyFactory, $uibModal) {
        var service = {
            sendSatisfactionSurvey: sendSatisfactionSurvey,
            rateStore: rateStore,
            checkIfNotExpiredEvaluation: checkIfNotExpiredEvaluation,
            checkIfStoreNew: checkIfStoreNew

        };

        return service;

        function rateStore() {
            return $uibModal.open({
                templateUrl: 'app/admin/satisfaction-survey/satisfaction-survey.html',
                controller: 'SatisfactionSurveyController',
                controllerAs: 'vm',
                size: 'lg',
                windowClass: 'open-modal-in-forward',
                backdrop: 'static',
            }).result;
        };

        function sendSatisfactionSurvey(satisfactionSurvey) {
            return SatisfactionSurveyFactory.sendSatisfactionSurvey(satisfactionSurvey).$promise;
        }

        function checkIfNotExpiredEvaluation(storeId, userId) {
            return SatisfactionSurveyFactory.checkIfNotExpiredEvaluation({ "storeId": storeId, "userId": userId }).$promise;
        }
        function checkIfStoreNew(storeId) {
            return SatisfactionSurveyFactory.checkIfStoreNew({ "storeId": storeId }).$promise;
        }

    }

})();
