(function() {
    'use strict';
    angular
        .module('showcaseApp')
        .service('StoreTypeService', StoreTypeService);

    StoreTypeService.$inject = ['StoreTypeFactory'];

    function StoreTypeService(StoreTypeFactory) {
        var service = {
            getAllStoreTypesByOwner: getAllStoreTypesByOwner,
            getAllStoreTypesByToken: getAllStoreTypesByToken
        };

        return service;

        function getAllStoreTypesByOwner() {
            return StoreTypeFactory.getAllStoreTypesByOwner().$promise;
        }

        function getAllStoreTypesByToken(token) {
            return StoreTypeFactory.getAllStoreTypesByToken(token).$promise;
        }

    }
})();
