(function() {
    'use strict';
    angular
        .module('showcaseApp')
        .service('HubShowcasesService', HubShowcasesService);

    HubShowcasesService.$inject = ['HubShowcasesFactory'];

    function HubShowcasesService(HubShowcasesFactory) {

        var service = {
            listShowcases: listShowcases,
            getShowcase: getShowcase,
            saveShowcase: saveShowcase,
            updateShowcase: updateShowcase,
            deleteShowcases: deleteShowcases,
        };

        return service;

        function saveShowcase(showcase) {
            return HubShowcasesFactory.save(showcase).$promise;
        }

        function updateShowcase(showcase) {
            return HubShowcasesFactory.update(showcase).$promise;
        }

        function listShowcases(filter) {
            return HubShowcasesFactory.listAll(filter).$promise;
        }

        function getShowcase(id, ownerId) {
            return HubShowcasesFactory.get({
                id: id,
                ownerId: ownerId
            }).$promise;
        }

        function deleteShowcases(showcaseIds) {
            return HubShowcasesFactory.deletebyids({
                showcaseIds: showcaseIds
            }).$promise;
        }
    }

})();
