(function() {
    'use strict';

    angular
        .module('showcaseApp')
        .value('formStepsStates', values());


    function values() {
        return [
            {uiSref: 'store-info', valid: false},
            {uiSref: 'store-point', valid: false}
        ]
    }
})();
