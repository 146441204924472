(function() {
    'use strict';

    angular
        .module('showcaseApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('hub-flyers', {
            parent: 'admin',
            url: '/hub-flyers',
            data: {
                authorities: ['STORE_READ_FLYER'],
                pageTitle: 'global.hub.products-distribute'
            },
            views: {
                'content@app': {
                    templateUrl: 'app/admin/hub-management/flyers/flyers.html',
                    controller: 'HubFlyersController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                type: null,
                brand: null
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('flyers');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('hub-flyers.new', {
            url: '/hub-new',
            data: {
                pageTitle: 'flyers.new.title',
                authorities: ['STORE_CREATE_FLYER']
            },
            views: {
                'content@app': {
                    templateUrl: 'app/admin/hub-management/flyers/flyers-form.html',
                    controller: 'HubFlyersFormController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('flyers');
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('brand');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('hub-flyers.edit', {
            url: '/hub-edit/{id}',
            data: {
                pageTitle: 'flyers.edit.title',
                authorities: ['STORE_UPDATE_FLYER']
            },
            views: {
                'content@app': {
                    templateUrl: 'app/admin/hub-management/flyers/flyers-form.html',
                    controller: 'HubFlyersFormController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('flyers');
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('brand');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('hub-flyers-detail', {
            parent: 'hub-flyers',
            url: '/{id}',
            data: {
                authorities: ['STORE_READ_FLYER', 'STORE_UPDATE_FLYER'],
                pageTitle: 'flyers.detail.title'
            },
            views: {
                'content@app': {
                    templateUrl: 'app/admin/hub-management/flyers/flyers-detail.html',
                    controller: 'HubFlyersDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('flyers');
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('store-management');
                    return $translate.refresh();
                }]
            }
        })
        .state('hub-flyers.import', {
            parent: 'admin',
            url: '/hub-flyers-import',
            data: {
                pageTitle: 'flyers.import.title',
                authorities: ['STORE_CREATE_FLYER'],
                specialClass: "md-skin fixed-nav"
            },
            views: {
                'content@app': {
                    templateUrl: 'app/admin/hub-management/flyers/import/hub-flyer-import.html',
                    controller: 'HubFlyerImportController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('flyers');
                    return $translate.refresh();
                }]
            }
        });
    }
})();
