(function () {
    'use strict';

    angular
        .module('showcaseApp')
        .factory('DiscountCouponModalFactory', DiscountCouponModalFactory);

    /** @ngInject */
    function DiscountCouponModalFactory($uibModal) {

        var factory = {
            addDiscountCoupon: addDiscountCoupon,
            editDiscountCoupon: editDiscountCoupon,
            deleteDiscountCoupon: deleteDiscountCoupon
        }

        return factory;

        function addDiscountCoupon(discountCoupon, currency) {
            return showAddEditModal(discountCoupon, currency);
        }

        function editDiscountCoupon(discountCoupon, currency) {
            return showAddEditModal(discountCoupon, currency);
        }

        function deleteDiscountCoupon(discountCoupon) {
            return showDeleteModal(discountCoupon.fullName);
        }

        function showAddEditModal(discountCoupon, currency) {
            return $uibModal.open({
                templateUrl: "app/admin/discount-coupon/_modal_add_edit_discount_coupon.html",
                size: 'md',
                windowClass: 'modal-center',
                windowTopClass: 'modal-dialog-center',
                resolve: {
                    discountCoupon: function () {
                        return angular.copy(discountCoupon);
                    },
                    currency: function () {
                        return currency;
                    }
                },
                controller: "DiscountCouponModalConstroller",
                controllerAs: "vm"
            }).result;
        }

        function showDeleteModal(fullName) {
            return $uibModal.open({
                templateUrl: "app/admin/discount-coupon/_modal_delete_discount-coupon.html",
                size: 'md',
                windowClass: 'modal-center',
                windowTopClass: 'modal-dialog-center',
                resolve: {
                    fullName: function () {
                        return fullName;
                    }
                },
                controller: function ($uibModalInstance, fullName) {
                    var vm = this;
                    vm.fullName = fullName;
                    vm.cancel = cancel;
                    vm.ok = ok;

                    function cancel() {
                        $uibModalInstance.dismiss('cancel');
                    }

                    function ok() {
                        $uibModalInstance.close(true);
                    }
                },
                controllerAs: "vm"
            }).result;
        }
    }
})();
