(function () {
    'use strict';

    angular
        .module('showcaseApp')
        .config(stateConfig)
        .config(intlTelInputConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('app', {
                url: '/app',
                abstract: true,
                controller: function($scope, $localStorage, $state, BlockPlanModalFactory) {
                    $scope.$watch(function() {
                        return $localStorage.isLocked && $localStorage.userId;
                    }, function() {
                        if ($localStorage.userId && $localStorage.isLocked) {
                            BlockPlanModalFactory.choosePlan().then(function() {
                                $localStorage.isLocked = false;
                            });
                        }
                    }, true);

                    $scope.goToAsign = function() {
                        $state.go('checkoutInner');
                    }
                },
                templateUrl: "app/inspinia/components/common/content.html",
                data: {
                    specialClass: "fixed-sidebar md-skin fixed-nav pace-done pace-done pace-done"
                },
                resolve: {
                    authorize: ['Auth',
                    function (Auth) {
                            return Auth.authorize();
                    }
                ],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                }]
                }
            });
    }

    function intlTelInputConfig(intlTelInputOptions){
        angular.extend(intlTelInputOptions, {
            // preferredCountries: [navigator.language.split("-").pop(-1)],
            preferredCountries: ["br"],
            autoPlaceholder: true,
            // initial country
            initialCountry: "auto",
            // geoIp lookup function
            geoIpLookup: function(success, failure) {
                $.get("https://ipinfo.io", function() {}, "jsonp").always(function(resp) {
                    var countryCode = (resp && resp.country) ? resp.country : "br";"" +
                    success(countryCode);
                });
            }
        });
    }
})();
