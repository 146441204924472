(function() {
    'use strict';
    angular
        .module('showcaseApp')
        .factory('ChatTicketFactory', ChatTicketFactory);

        ChatTicketFactory.$inject = ['$resource'];

    function ChatTicketFactory($resource) {
        var resourceChatTicket =  'admin/api/chat-ticket';
        var resourceChatTicketById = resourceChatTicket + '/:id';
        var resourceChatTicketChangeStatusById = resourceChatTicket + '/change-status/:id';
        var resourceChatTicketChangeFavoriteById = resourceChatTicket + '/change-favorite/:id';
        var resourceAssignTicketToAttendant = resourceChatTicket + '/assign-attendant/:id';
        var resourceGetChatTicket = resourceChatTicket + '/:chatId';
        var resourceGetChatTicketExist = resourceChatTicket + '/:chatId/exist';
        var resourceChatTicketFilter =  resourceChatTicket + '/filter';
        var resourceChatTicketUnreadMessages =  resourceChatTicket + '/unread-messages/:socialMediaName';

        return $resource(resourceChatTicket, {}, {
            'getChatTicket': {
                method: 'GET',
                url: resourceGetChatTicket,
                isArray: false,
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data){
                        response = angular.fromJson(data);
                    }
                    return response;
                }
            },
            'updateChatTicket':{
                method: 'PUT',
                url: resourceChatTicketById,
                isArray: false,
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data){
                        response = angular.fromJson(data);
                    }
                    return response;
                }
            },
            'updateChatTicketStatus':{
                method: 'PUT',
                url: resourceChatTicketChangeStatusById,
                isArray: false,
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data){
                        response = angular.fromJson(data);
                    }
                    return response;
                }
            },
            'changeTicketFavorite':{
                method: 'GET',
                url: resourceChatTicketChangeFavoriteById,
                isArray: false,
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data){
                        response = angular.fromJson(data);
                    }
                    return response;
                }
            },
            'assignTicketToAttendant':{
                method: 'PUT',
                url: resourceAssignTicketToAttendant,
                isArray: false,
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data){
                        response = angular.fromJson(data);
                    }
                    return response;
                }
            },
            'getAnExistChatTicket':{
                method: 'GET',
                url: resourceGetChatTicketExist,
                isArray: false,
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data){
                        response = angular.fromJson(data);
                    }
                    return response;
                }
            },
            'searchByName':{
                method: 'GET',
                url: resourceChatTicket,
                isArray: true,
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data){
                        response = angular.fromJson(data);
                    }
                    return response;
                }
            },
            'searchByFilter':{
                method: 'POST',
                url: resourceChatTicketFilter,
                isArray: true,
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data){
                        response = angular.fromJson(data);
                    }
                    return response;
                }
            },
            'getSumBySocialMediaName':{
                method: 'GET',
                url: resourceChatTicketUnreadMessages,
                isArray: false,
                transformResponse: function (data, headers) {
                    var response = {};
                    response.sum = data;
                    return response;
                }
            },
        });
    }

})();
