(function() {
    'use strict';
    angular
        .module('showcaseApp')
        .factory('DigitalPosterFactory', DigitalPosterFactory);

    function DigitalPosterFactory($resource) {

        var resourceUrl = '';

        return $resource(resourceUrl, {}, {
            'generatePoster': {
                method: 'POST',
                url: '/flyer/api/poster/generate-poster',
                responseType: 'arraybuffer',
                transformResponse: function (data, headers) {
                    var response = {};
                    response.data = angular.fromJson(data);
                    response.headers = headers();
                    return response;
                }
            }
        });
    }

})();
