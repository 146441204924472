(function () {
    'use strict';

    angular
        .module('showcaseApp')
        .controller('StoreTypeController', StoreTypeController);

    /** @ngInject */
    function StoreTypeController($rootScope, $scope, HubService, HubModalFactory, toastr, $translate) {

        var vm = this;
        vm.filter = {};
        vm.storeTypes = [];

        vm.originalOrder = [];

        vm.currentPage = 1;
        vm.numPerPage = 10;
        vm.maxSize = 5;
        vm.totalCustomers = 0;

        vm.startRegistry = 1;
        vm.endRegistry = vm.numPerPage;

        vm.paginationOptions = {
            page: vm.currentPage - 1,
            size: vm.numPerPage,
            sort: null
        };

        vm.orderedByBirthdayWeek = false;

        vm.showModalAddStoreType = showModalAddStoreType;
        vm.selectStoreType = selectStoreType;

        vm.filterChange = filterChange;

        init();

        function init() {
            listStoreTypes(vm.paginationOptions, vm.filter, true);
        }

        function listStoreTypes() {
            vm.loadingStoreTypes = true;
            HubService.getStoreTypes().then(function (result) {
                vm.storeTypes = result;
                vm.loadingStoreTypes = false;
            }, function (error) {
                vm.loadingStoreTypes = false;
            });
        }

        function showModalAddStoreType() {
            HubModalFactory.addStoreType().then(function (result) {
                toastr.success($translate.instant(result.headers["x-adminapp-alert"]));
                listStoreTypes(vm.paginationOptions, {}, true);
            });
        }

        function selectStoreType(storeType) {

            console.log('StoreType:::', angular.copy(storeType));

            // $rootScope.$broadcast('selectedCustomer', angular.copy(customer));
        }

        function filterChange() {
            vm.currentPage = 1;
            vm.paginationOptions.page = 0;
            listStoreTypes(vm.paginationOptions, vm.filter, true);
        }

        $scope.$on('refreshListCustomer', function (env) {
            listStoreTypes(vm.paginationOptions, vm.filter, false);
        });

        $scope.$on('deletedCustomer', function (env) {
            listStoreTypes(vm.paginationOptions, vm.filter, true);
        });
    }
})();
