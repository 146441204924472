/* globals $ */
(function() {
    'use strict';

    angular
        .module('showcaseApp')
        .directive('onLongPress', onLongPress);

    function onLongPress($timeout) {
        return {
            restrict: 'A',
            link: function($scope, $elm, $attrs) {
                $elm.bind('touchstart', function(evt) {
                    // Locally scoped variable that will keep track of the long press
                    $scope.longPress = true;
                    $scope.pressed = false;

                    // We'll set a timeout for 600 ms for a long press
                    $timeout(function() {
                        if ($scope.longPress) {
                            $scope.pressed = true;
                            // If the touchend event hasn't fired,
                            // apply the function given in on the element's on-long-press attribute
                            $scope.$apply(function() {
                                $scope.$eval($attrs.onLongPress)
                            });
                        }
                    }, 600);
                });
        
                $elm.bind('touchend', function(evt) {
                    $scope.longPress = false;
                    if ($attrs.onTouchEnd && $scope.pressed) {
                        $scope.pressed = false;
                        $scope.$apply(function() {
                            $scope.$eval($attrs.onTouchEnd)
                        });
                    }
                });
            }
        };
    }
})();
