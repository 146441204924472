(function() {
    'use strict';
    angular
        .module('showcaseApp')
        .factory('ChatAssignmentRuleFactory', ChatAssignmentRuleFactory);

        ChatAssignmentRuleFactory.$inject = ['$resource'];

    function ChatAssignmentRuleFactory($resource) {
        var resourceUrl = 'admin/api/chat-assignment-rule';

        return $resource(resourceUrl, {}, {
            'save': {
                method: 'POST',
                url: resourceUrl,
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'list': {
                method: 'GET',
                url: resourceUrl + '/list',
                transformResponse: function (data, headers) {
                    var response = {};
                    response.data = angular.fromJson(data);
                    response.headers = headers();
                    return response;
                }
            },
            'get': {
                method: 'GET',
                url: resourceUrl + '/:id',
                transformResponse: function (data, headers) {
                    var response = {};
                    response.data = angular.fromJson(data);
                    response.headers = headers();
                    return response;
                }
            },
            'remove': {
                method: 'DELETE',
                url: resourceUrl + '/:id',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'update': {
                method: 'PUT',
                url: resourceUrl + '/:id',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            }
        });
    }
})();
