(function() {
    'use strict';

    angular
        .module('showcaseApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('hub-showcases', {
                parent: 'admin',
                url: '/hub-showcases',
                data: {
                    authorities: ['ADMIN_HUB'],
                    pageTitle: 'showcases.home.title'
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/admin/hub-management/showcases/hub-showcases.html',
                        controller: 'HubShowcasesController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    }
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort)
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('showcases');
                        $translatePartialLoader.addPart('flyers');
                        $translatePartialLoader.addPart('store-management');
                        return $translate.refresh();
                    }]

                }
            })
            .state('hub-showcases.new', {
                parent: 'hub-showcases',
                url: '/new',
                data: {
                    authorities: ['ADMIN_HUB'],
                    pageTitle: 'showcases.detail.title',
                    specialClass: "md-skin fixed-nav"
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/admin/hub-management/showcases/hub-showcases-detail.html',
                        controller: 'HubShowcasesDetailController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    }
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort)
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('showcases');
                        $translatePartialLoader.addPart('flyers');
                        return $translate.refresh();
                    }]

                }
            })
            .state('hub-showcases.edit', {
                parent: 'hub-showcases',
                url: '/{showcaseId}',
                data: {
                    authorities: ['ADMIN_HUB'],
                    pageTitle: 'showcases.detail.title',
                    specialClass: "md-skin fixed-nav"
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/admin/hub-management/showcases/hub-showcases-detail.html',
                        controller: 'HubShowcasesDetailController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    }
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort)
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('showcases');
                        $translatePartialLoader.addPart('flyers');
                        return $translate.refresh();
                    }]

                }
            })
            .state('hub-showcases-flyers', {
                parent: 'hub-showcases',
                url: '/{showcaseId}/flyers',
                data: {
                    authorities: ['ADMIN_HUB'],
                    pageTitle: 'showcases.home.title'
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/admin/hub-management/showcases/hub-showcases-flyers.html',
                        controller: 'HubShowcasesFlyersController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    flyersId: null,
                    ownerId: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort)
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('showcases');
                        $translatePartialLoader.addPart('flyers');
                        return $translate.refresh();
                    }]

                }
            })
    }
})();
