(function() {
    'use strict';

    angular
        .module('showcaseApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('team-management', {
            parent: 'entity',
            url: '/team-management',
            data: {
                authorities: ['ADMIN_MANAGE_SYSTEM'],
                pageTitle: 'showcaseApp.team.home.title'
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                }
            },
            views: {
                'content@app': {
                    templateUrl: 'app/admin/team-management/team-management.html',
                    controller: 'TeamManagementController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('team-management');
                    $translatePartialLoader.addPart('profile');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('team-management.edit', {
            url: '/{id}/edit',
            data: {
                authorities: ['STORE_READ_PROFILE', 'STORE_UPDATE_PROFILE']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/admin/team-management/team-management-dialog.html',
                    controller: 'TeamDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('team-management.new', {
            parent: 'team-management',
            url: '/new',
            data: {
                authorities: ['STORE_CREATE_PROFILE']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/admin/team-management/team-management-dialog.html',
                    controller: 'TeamDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {

                    }
                }).result.then(function() {
                    $state.go('team-management', null, { reload: 'team-management' });
                }, function() {
                    $state.go('team-management');
                });
            }]
        })
        .state('team-delete', {
            url: '/{id}/delete',
            data: {
                authorities: ['STORE_READ_PROFILE']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/admin/team-management/team-management-delete-dialog.html',
                    controller: 'TeamManagementDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                    }
                });
            }]
        });
    }

})();
