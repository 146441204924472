(function() {
    'use strict';

    angular
        .module('showcaseApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('profile-management', {
            parent: 'entity',
            url: '/profile-management',
            data: {
                authorities: ['ADMIN_MANAGE_SYSTEM'],
                pageTitle: 'showcaseApp.profile.home.title'
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                }
            },
            views: {
                'content@app': {
                    templateUrl: 'app/entities/profile/profile-management.html',
                    controller: 'ProfileManagementController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('profile');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('profile-detail', {
            parent: 'profile-management',
            url: '/profile/{id}',
            data: {
                authorities: ['STORE_READ_PROFILE', 'STORE_CREATE_PROFILE'],
                pageTitle: 'showcaseApp.profile.detail.title'
            },
            views: {
                'content@app': {
                    templateUrl: 'app/entities/profile/profile-management-detail.html',
                    controller: 'ProfileDetailController',
                    controllerAs: 'vm'
                }
            },
            params: {
                'storeId': {
                    value: null
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('profile');
                    return $translate.refresh();
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'profile-management',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('profile-management.edit', {
            url: '/{id}/edit',
            data: {
                authorities: ['STORE_READ_PROFILE', 'STORE_UPDATE_PROFILE']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/profile/profile-management-dialog.html',
                    controller: 'ProfileDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('profile-management.new', {
            parent: 'profile-management',
            url: '/new',
            data: {
                authorities: ['STORE_CREATE_PROFILE']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/profile/profile-management-dialog.html',
                    controller: 'ProfileDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {

                    }
                }).result.then(function() {
                    $state.go('profile-management', null, { reload: 'profile-management' });
                }, function() {
                    $state.go('profile-management');
                });
            }]
        })
        .state('profile-delete', {
            url: '/{id}/delete',
            data: {
                authorities: ['STORE_READ_PROFILE']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/profile/profile-management-delete-dialog.html',
                    controller: 'ProfileManagementDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                    }
                });
            }]
        });
    }

})();
