(function () {
    'use strict';

    angular
        .module('showcaseApp')
        .controller('ViewChatOrderModalController', ViewChatOrderModalController);

    ViewChatOrderModalController.$inject = ['$scope', '$uibModalInstance', '$translate', 'ordersList'];

    function ViewChatOrderModalController($scope, $uibModalInstance, $translate, ordersList) {
        var vm = this;

        vm.ordersList = ordersList

        vm.getOrderAmount = function (orders) {
            return _.sumBy(orders, function (order) {
                return order.price;
            }).toFixed(2);
        }

        vm.closeModal = function () {
            $uibModalInstance.close();
        }

    }
})();
