(function () {
    'use strict';

    angular.module('showcaseApp')
    .config(function($authProvider) {

    $authProvider.facebook({
      clientId: getDomain(window),
      responseType: 'token',
      url: '/auth/facebook',
      authorizationEndpoint: 'https://www.facebook.com/v18.0/dialog/oauth',
      scope: ['email', 'user_likes', 'pages_manage_posts',
      'business_management', 'ads_management', 'pages_show_list', 'catalog_management', 'read_insights', 'ads_read',
      'instagram_manage_insights', 'instagram_manage_comments', 'instagram_basic', 'instagram_content_publish', 'pages_messaging' ],
    });

  });

})();
