(function () {
    'use strict';

    angular
        .module('showcaseApp')
        .controller('DiscountCouponListController', DiscountCouponListController);

    /** @ngInject */
    function DiscountCouponListController($rootScope, $scope, $localStorage, $translate, toastr,
        DiscountCouponService, StoreService, DiscountCouponModalFactory, RemoveModalFactory) {

        var vm = this;
        vm.filter = {};
        vm.discountCoupons = [];

        vm.originalOrder = [];

        vm.currentPage = 1;
        vm.numPerPage = 10;
        vm.maxSize = 5;
        vm.totalDiscoutCoupon = 0;

        vm.startRegistry = 1;
        vm.endRegistry = vm.numPerPage;

        vm.paginationOptions = {
            page: vm.currentPage - 1,
            size: vm.numPerPage,
            sort: null
        };

        vm.filtring = false;

        vm.orderedByBirthdayWeek = false;

        vm.showModalAddDiscountCoupon = showModalAddDiscountCoupon;
        vm.showModalEditDiscountCoupon = showModalEditDiscountCoupon;
        vm.selectCustomer = selectCustomer;
        vm.searchByFilter = searchByFilter;
        vm.filterChange = filterChange;

        init();

        function init() {
            loadStoreData();
            listDiscountCoupon(vm.paginationOptions, vm.filter, true);
        }

        function loadStoreData() {
            StoreService.getStore($localStorage.defaultStore.storeId).then(function(response) {
                if (response){
                    loadCurrencyPrefix(response.data);
                }
            })
        }

        function loadCurrencyPrefix(store) {
            var key = store.currency ? store.currency : 'REAL';
            var config = getCurrencyListConfiguration()[key];
            vm.currencyPrefix = config.prefix;
            vm.currency = key;
        }

        vm.orderByBirthdayWeek = function () {
            vm.orderedByBirthdayWeek = !vm.orderedByBirthdayWeek;

            vm.filter.orderedByBirthdayWeek = vm.orderedByBirthdayWeek;
            vm.currentPage = 1;
            vm.paginationOptions.page = 0;
            listDiscountCoupon(vm.paginationOptions, vm.filter, true);

        }

        function parseDates(elements) {
            elements.forEach(function (element) {
                if (element.expirationDate) {
                    element.expirationDate = moment.utc(element.expirationDate);
                }
                if (element.startDate) {
                    element.startDate = moment.utc(element.startDate);
                }
            });
        }

        function listDiscountCoupon(pagination, filter, selectFirst) {
            vm.loadingCustomers = true;
            DiscountCouponService.search(pagination, filter).then(function (result) {
                parseDates(result.data);
                vm.totalDiscoutCoupon = result.headers['x-total-count'];
                vm.discountCoupons = result.data.slice();
                vm.originalOrder = result.data;
                if (selectFirst) {
                    $rootScope.$broadcast('selectedCustomer', angular.copy(vm.discountCoupons[0]));
                }
                vm.loadingCustomers = false;
            }, function (error) {
                vm.loadingCustomers = false;
            });
        }

        function showModalAddDiscountCoupon() {
            DiscountCouponModalFactory.addDiscountCoupon(undefined, vm.currency).then(function (result) {
                toastr.success($translate.instant('global.messages.success.created-coupon'));
                listDiscountCoupon(vm.paginationOptions, {}, true);
            });
        }

        function showModalEditDiscountCoupon(discountCoupon) {
            DiscountCouponModalFactory.editDiscountCoupon(discountCoupon, vm.currency).then(function (result) {
                toastr.success($translate.instant('global.messages.success.edited-coupon'));
                listDiscountCoupon(vm.paginationOptions, {}, true);
            });
        }

        vm.showModalRemoveDiscountCoupon = function (couponId) {
            RemoveModalFactory.deleteCoupon().then(function (data) {
                DiscountCouponService.deleteCoupon(couponId).then(function (data) {
                    toastr.success($translate.instant('global.messages.success.removed-coupon'));
                    listDiscountCoupon(vm.paginationOptions, {}, true);
                }, function (error) {
                    toastr.error($translate.instant('global.messages.error.remove-coupon'));
                });
            });
        }

        vm.copyDiscountCoupon = function (discountCoupon) {
            var textoCopiado = document.getElementById("code_" + discountCoupon.id);
            textoCopiado.select();
            textoCopiado.setSelectionRange(0, 99999);
            document.execCommand("copy");
        }

        function selectCustomer(customer) {
            $rootScope.$broadcast('selectedCustomer', angular.copy(customer));
        }

        vm.stopPropagation = function(event) {
            event.stopPropagation();
        };

        function searchByFilter() {
            if (vm.filter.text) {
                var filter = {
                    "name": vm.filter.text,
                    "code": vm.filter.text
                }
                vm.currentPage = 1;
                vm.paginationOptions.page = 0;
                listDiscountCoupon(vm.paginationOptions, filter, true);
            }
        }

        function filterChange() {
            if (vm.filter.text === null || vm.filter.text === '') {
                vm.filtring = false;
            } else {
                vm.filtring = true;
            }
            var filter = {
                "name": vm.filter.text,
                "code": vm.filter.text
            }
            vm.currentPage = 1;
            vm.paginationOptions.page = 0;
            listDiscountCoupon(vm.paginationOptions, filter, true);
        }

        vm.changePage = function () {

            var begin = ((vm.currentPage - 1) * vm.numPerPage),
                end = begin + vm.numPerPage;

            vm.paginationOptions.page = vm.currentPage - 1;

            vm.discountCoupons = listDiscountCoupon(vm.paginationOptions, vm.filter, true);
            vm.startRegistry = begin + 1;
            vm.endRegistry = end;

        }

        $scope.$on('refreshListCustomer', function (env) {
            listDiscountCoupon(vm.paginationOptions, vm.filter, false);
        });

        $scope.$on('deletedCustomer', function (env) {
            listDiscountCoupon(vm.paginationOptions, vm.filter, true);
        });

    }
})();
