(function() {
    'use strict';
    angular
        .module('showcaseApp')
        .service('UserProfileService', UserProfileService);

    UserProfileService.$inject = ['UserProfileFactory', '$localStorage'];

    function UserProfileService(UserProfileFactory, $localStorage) {
        var service = {
                getProfiles: getProfiles,
                getProfile: getProfile,
                createProfile: createProfile,
                updateProfile: updateProfile,
                deleteProfile: deleteProfile,
                listProfiles: listProfiles
        };

        return service;

        function getProfile(profileId, storeId) {
            return UserProfileFactory.get({id: profileId, owner: storeId}).$promise;
        }

        function getProfiles(storeId) {
            return UserProfileFactory.listByParams({owner: storeId}, {}).$promise;
        }

        function createProfile(profile) {
            return UserProfileFactory.save(profile).$promise;
        }

        function updateProfile(profile) {
            return UserProfileFactory.update(profile).$promise;
        }

        function deleteProfile(profileId, storeId) {
            return UserProfileFactory.delete({id: profileId, owner: storeId}, {}).$promise;
        }

        function listProfiles(pageable, storeId, params) {
            pageable.owner = storeId;
            return UserProfileFactory.listByParams(pageable, params).$promise;
        }

    }
})();
