(function() {
    'use strict';
    angular
        .module('showcaseApp')
        .factory('NoteTagFactory', NoteTagFactory);

        NoteTagFactory.$inject = ['$resource'];

    function NoteTagFactory($resource) {
        var resourceNoteTag =  'admin/api/note-tag';
        var resourceNewNoteTag = resourceNoteTag + '/new-note-tag';
        var resourceGetCustomerNotes = resourceNoteTag + '/customer-notes/:customerId';
        var resourceDeleteNoteTag = resourceNoteTag + '/:noteTagId';
        var resourceUpdateNote = resourceNoteTag + '/update-note';

        return $resource(resourceNoteTag, {}, {

            'createNoteTag': {
                method: 'POST',
                url: resourceNewNoteTag,
            },

            'getNotesTags':{
                method: 'GET',
                url: resourceGetCustomerNotes,
                isArray: true,
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data){
                        response = angular.fromJson(data);
                    }                    
                    response.headers = headers();
                    return response;
                }
            },

            'deleteNoteTag': {
                method: 'DELETE',
                url: resourceDeleteNoteTag
                
            },

            'updateNote': {
                method: 'PUT',
                url: resourceUpdateNote
            }
        });
    }

})();
