(function() {
    'use strict';

    angular
        .module('showcaseApp')
        .controller('WatiController', WatiController)
        .directive('copyToClipboard', function () {
            return {
                restrict: 'A',
                link: function (scope, elem, attrs) {
                    elem.click(function () {
                        if (attrs.copyToClipboard) {
                            var $temp_input = $("<input>");
                            $("body").append($temp_input);
                            $temp_input.val(attrs.copyToClipboard).select();
                            document.execCommand("copy");
                            $temp_input.remove();
                        }
                    });
                }
            };
        });

    /** @ngInject */
    function WatiController(   $window, $state, RemoveModalFactory, $localStorage, $translate, StoreService,
                                    ShowcasesService, toastr, ModalFactory, paginationConstants, DOMAIN, $rootScope, WatiService) {

        var vm = this;

        vm.webhook = document.location.protocol + '//admin.' + document.location.hostname.split('.').slice(1).join('.') + '/admin/api/wati/hook?accessToken=' + $localStorage.authenticationToken;

        vm.watiAccountData = {};

        vm.init = function() {
            WatiService.findConfigByCurrentStore(function(response) {
                if(response.data && response.data.id) {
                    vm.watiAccountData = response.data;
                    vm.callbackRequest = updateSetting;
                } else {
                    vm.callbackRequest = saveSetting;
                }
            }, function(error) {
                console.log(error)
            });
        }


        vm.init();

        vm.saveOrUpdate = function() {
            if(!vm.watiAccountData.id) {
                saveSetting();
                return;
            }
            updateSetting();
        }

        var saveSetting = function() {
            if(!validateSetting()) return;

            vm.isSaving = true;


            vm.watiAccountData.accessToken = token;

            WatiService.create(makeBodyToRequest()).then(function(response) {
                vm.isSaving = false;
                toastr.success("Configuração criada com sucesso");
            });
        }

        var updateSetting = function() {
            if(!validateSetting()) return;

            WatiService.update(makeBodyToRequest()).then(function(response) {
                vm.isSaving = false;
                toastr.success("Configuração atualizada com sucesso");
            });
        }

        var validateSetting = function() {
            if (vm.watiAccountDataForm.$invalid) {
                toastr.warning("Campos obrigatórios não foram preenchidos");
                return false;
            }
            return true;
        }

        var makeBodyToRequest = function() {
            vm.isSaving = true;

            return angular.copy(vm.watiAccountData);
        }

    }
})();
