(function() {
    'use strict';

    angular
        .module('showcaseApp')
        .controller('HubShowcasesFlyersController', HubShowcasesFlyersController);

    HubShowcasesFlyersController.$inject = ['$state', '$stateParams', '$q', 'paginationConstants', '$translate',
        'HubFlyersService', 'FlyerCategoryService', 'FlyersCurrencyValue', 'HubShowcasesService', 'toastr', 'BrandService', '$localStorage'];

    function HubShowcasesFlyersController($state, $stateParams, $q, paginationConstants, $translate, HubFlyersService,
                                          FlyerCategoryService, FlyersCurrencyValue, HubShowcasesService, toastr, BrandService, $localStorage) {
        var vm = this;

        vm.currentPage = 1;
        vm.numPerPage = paginationConstants.itemsPerPage;
        vm.maxSize = 5;
        vm.totalFlyers = 0;
        vm.startRegistry = 1;
        vm.endRegistry = vm.numPerPage;

        vm.paginationOptions = {
            page: vm.currentPage - 1,
            size: vm.numPerPage
        };

        vm.listFilter = {
            activated: null
        };

        vm.loadFlyers = function (page, reset) {
            loadFlyers();
        };

        vm.inactiveArray = [];

        vm.flyersFiltered = [];
        vm.page = 0;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.hasNextPage = false;
        vm.busy = false;

        vm.brands = [];
        vm.brandGroups = {};
        vm.selectedAll = false;
        vm.sizes = getAllSizes();
        vm.colors = getAllColorsToSearch();
        vm.flyersTypes = [];

        getFlyersTypes();
        loadAll();

        function loadAll() {
            vm.isLoading = true;

            FlyerCategoryService.listCategoriesByCurrentStore($translate.use(), $localStorage.defaultStore.storeId).then(function(response) {
                vm.categories = response.data;
            });

            HubShowcasesService.getShowcase($stateParams.showcaseId, $stateParams.ownerId).then(function(data) {
                vm.showcase = data;
                vm.listFilter.hub = vm.showcase.ownerId;
                BrandService.getBrandsByCurrentStore().then(function(response) {
                    vm.brands = response.data;
                    vm.listFilter.brand = response.data[0].name;
                    loadFlyers();
                });
            });
        }


        vm.reset = function reset() {
            vm.page = 0;
            vm.flyersFiltered = [];
        };

        vm.changePage = function () {
            var begin = ((vm.currentPage - 1) * vm.numPerPage),
            end = begin + vm.numPerPage;

            vm.paginationOptions.page = vm.currentPage - 1;

            loadFlyers();
            vm.startRegistry = begin + 1;
            vm.endRegistry = end;
        };

        function loadFlyers() {
            if(vm.busy) return;
            vm.busy = true;

            vm.listFilter.type = vm.showcase.userInteract;

            if (!vm.listFilter.color ) {
                delete vm.listFilter.color
            }

            if (!vm.listFilter.categoryKey) {
                delete vm.listFilter.categoryKey
            }

            HubFlyersService.listFlyersFiltered(vm.paginationOptions, vm.listFilter, onSuccess, onError);

            function onSuccess(response, headers) {
                var data = response.data.content;
                vm.busy = false;
                vm.totalFlyers = headers('X-Total-Count');
                vm.flyersFiltered = data.length <= 0 ? [] : data;

                for (var i = 0; i < data.length; i++) {
                    vm.getCategoryName(data[i]);
                }
                vm.isLoading = false;
                reduceFlyersToMap();
                refreshInactiveBoxArray();

                vm.makeBrandGroups();
                vm.refreshFlyersSelected();
            }

            vm.makeBrandGroups = function() {
                vm.brandGroups = {};
                vm.showcase.flyers.forEach(function(flyer) {
                    if(flyer.brand){
                        vm.brandGroups[flyer.brand] = vm.brandGroups[flyer.brand] ? vm.brandGroups[flyer.brand] + 1 : 1;
                    }
                });
            }

            function onError() {
                vm.busy = false;
                toastr.error($translate.instant('flyers.messages.load.error'));
            }
        }

        function refreshInactiveBoxArray(number) {
            vm.flyersStatus = [];
            var qtdActive = vm.showcase.flyers.length;
            for (var i = 0; i < vm.maxFlyersPerShowcase; i++) {
                if (qtdActive >= 1) {
                    vm.flyersStatus.push({
                        status: true
                    });
                } else {
                    vm.flyersStatus.push({
                        status: false
                    });
                }
                qtdActive--;
            }
        }

        function reduceFlyersToMap() {
            var index = 0;
            vm.flyersMap = vm.showcase.flyers.reduce(function(map, flyer) {
                map[flyer.id] = {
                    name: flyer.name,
                    pos: index
                };
                index++;
                return map;
            }, {})
        }

        function getFlyersTypes() {
            HubFlyersService.getFlyerTypes().then(function (response) {
                var removeRent = response.data.map(function(item) { return item.code; }).indexOf('RENT');
                response.data.splice(removeRent, 1);
                var removeContact = response.data.map(function(item) { return item.code; }).indexOf('CONTACT');
                response.data.splice(removeContact, 1);
                vm.flyersTypes = response.data;
            });
        }

        vm.getCountSelectedByBrand = function(brand) {
            return vm.brandGroups[brand];
        };

        vm.tabClick = function(brand){
            vm.loadingTab = true;
            vm.listFilter.brand = brand.name;
            vm.flyersFiltered = [];
            vm.page = 0;
            loadFlyers();
        };

        vm.changeFlyerSelected = function(flyer) {
            if (vm.flyersMap) {
                if (vm.flyersMap[flyer.id]) {
                    vm.showcase.flyers.splice(vm.flyersMap[flyer.id].pos, 1);
                    reduceFlyersToMap();
                } else {
                    flyer.position = vm.showcase.flyers.length + 1;
                    vm.showcase.flyers.splice(0, 0, flyer);
                    reduceFlyersToMap();
                }
            } else {
                vm.showcase.flyers.splice(0, 0, flyer);
                reduceFlyersToMap();
            }
            refreshInactiveBoxArray();
            vm.refreshFlyersSelected()
        };

        vm.refreshFlyersSelected = function() {
            vm.makeBrandGroups();
        };

        vm.getCategoryName = function(flyer) {
            var category = _.find(vm.categories, function(el) {return el.key === flyer.categoryKey});
            if (category) {
                flyer.category = category.title;
            }
        };

        vm.getFlyerCurrency = function(currency) {
            return FlyersCurrencyValue[currency];
        };

        vm.checkFlyerIsSelected = function(flyer) {
            if (vm.flyersMap) {
                return angular.isDefined(vm.flyersMap[flyer.id]);
            }
        };

        vm.hasActiveFilter = function() {
            return vm.listFilter && (vm.listFilter.categoryKey || vm.listFilter.color || vm.listFilter.size
                || vm.listFilter.gender);
        };

        vm.stopPropagation = function(event) {
            event.stopPropagation();
        };

        function checkIsListAll() {
            vm.flyersFiltered = [];
            if (!vm.filters.inactive && !vm.filters.active) {
                vm.listFilter.status = null;
            }
            loadFlyers();

        }

        vm.changeStatusFilterToActive = function() {
            if (vm.filters.active) {
                vm.filters.inactive = false;
                vm.listFilter.status = "ACTIVATED";
            }
            checkIsListAll();
        };

        vm.changeStatusFilterToInactive = function() {
            if (vm.filters.inactive) {
                vm.filters.active = false;
                vm.listFilter.status = "DEACTIVATED";
            }
            checkIsListAll();
        }

        vm.saveShowcase = function(isRedirectToShowcaseScreen) {
            var flyersPosition = [];
            vm.showcase.flyers.forEach(function (flyer) {
                flyersPosition.push({
                    flyerId: flyer.id,
                    position: flyer.position
                });
            });
            vm.showcase.flyers = flyersPosition;
            $q.all(vm.showcase.flyers).then(function() {
                HubShowcasesService.updateShowcase(vm.showcase).then(function(data) {
                    if (isRedirectToShowcaseScreen) {
                        $state.go('hub-showcases.edit', {
                            showcaseId: data.id
                        });
                    }
                });
            });
        }

    }

})();
