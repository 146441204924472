(function() {
    'use strict';
    angular
        .module('showcaseApp')
        .service('ReportService', ReportService);

    ReportService.$inject = ['ReportFactory'];

    function ReportService(ReportFactory) {

        var service = {
            listUsersRegisteredByGenderWithCouponGeneratedByStore: listUsersRegisteredByGenderWithCouponGeneratedByStore,
        	listMostLikedCategories: listMostLikedCategories,
            listUsersGenerateMoreCoupons: listUsersGenerateMoreCoupons,
            listUsersWithMoreCouponsRedeemed: listUsersWithMoreCouponsRedeemed,
            listUsersFirstInteraction: listUsersFirstInteraction
        };
        
        return service;
        
        function listUsersRegisteredByGenderWithCouponGeneratedByStore(storeId) {
            return ReportFactory.listUsersRegisteredByGenderWithCouponGeneratedByStore(storeId).$promise;
        }
        
        function listMostLikedCategories(storeId, langKey) {
            return ReportFactory.listMostLikedCategories({storeId: storeId, langKey: langKey}).$promise;
        }
        
        function listUsersGenerateMoreCoupons(storeId) {
            return ReportFactory.listUsersGenerateMoreCoupons(storeId).$promise;
        }
        
        function listUsersWithMoreCouponsRedeemed(storeId) {
            return ReportFactory.listUsersWithMoreCouponsRedeemed(storeId).$promise;
        }

        function listUsersFirstInteraction(storeId) {
            return ReportFactory.listUsersFirstInteraction(storeId).$promise;
        }

    }

})();
