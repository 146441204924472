(function() {
    'use strict';

    angular
        .module('showcaseApp')
        .controller('ModalFileController', ModalFileController);

    ModalFileController.$inject = ['$uibModalInstance', 'file', 'conversation'];

    function ModalFileController($uibModalInstance, file, conversation) {

        var vm = this;

        vm.file = file;
        vm.file.caption = '';
        vm.conversation = conversation;

        vm.closeAndSendFile = function() {
            clearInput();
            $uibModalInstance.close(vm.file);
        }

        vm.dismiss = function() {
            clearInput();
            $uibModalInstance.dismiss();
        }

        function clearInput() {
            var fileInput = document.getElementById('file-upload');
            var fileInputInSheet = document.getElementById('file-upload-sheet');
            if (fileInput) {
                fileInput.value = null;
            } else {
                fileInputInSheet.value = null;
            }
        }

    }

})();
