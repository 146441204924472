(function() {
    'use strict';
    angular
        .module('showcaseApp')
        .service('ShowcasesService', ShowcasesService);

    ShowcasesService.$inject = ['ShowcasesFactory', '$localStorage', 'Upload'];

    function ShowcasesService(ShowcasesFactory, $localStorage, Upload) {

        var service = {
            getTemplates: getTemplates,
            getTemplate: getTemplate,
            listShowcases: listShowcases,
            listBagsFiltersValues: listBagsFiltersValues,
            getShowcase: getShowcase,
            saveShowcase: saveShowcase,
            updateShowcase: updateShowcase,
            getPublishedShowcaseByStoreId: getPublishedShowcaseByStoreId,
            deleteShowcases: deleteShowcases,
            publishShowcase: publishShowcase,
            getNumberOfVisualization: getNumberOfVisualization,
            generateTabloid: generateTabloid,
            saveBag: saveBag,
            findFlyerByFilterBag: findFlyerByFilterBag,
            getNumberOfFlyers: getNumberOfFlyers,
            getAllFlyerIdsFromShowcasesPublished: getAllFlyerIdsFromShowcasesPublished,
            deletePublishedFlyer: deletePublishedFlyer,
            deletePublishedFlyers: deletePublishedFlyers,
            addPublishedFlyer: addPublishedFlyer,
            addPublishedFlyers: addPublishedFlyers,
            isFlyerPublished: isFlyerPublished,
            createBagByRecommendation: createBagByRecommendation
        };

        return service;

        function saveBag(bag) {
            return ShowcasesFactory.saveBag(bag).$promise;
        }

        function findFlyerByFilterBag(pageable, bag) {
            return ShowcasesFactory.findFlyerByFilterBag(pageable, bag).$promise;
        }

        function saveShowcase(showcase) {
            return ShowcasesFactory.save(showcase).$promise;
        }

        function updateShowcase(showcase) {
            return ShowcasesFactory.update(showcase).$promise;
        }

        function getTemplate(templateId) {
            return ShowcasesFactory.getTemplate({
                templateId: templateId
            }).$promise;
        }

        function getTemplates() {
            return ShowcasesFactory.getTemplates().$promise;
        }

        function listShowcases(showcase, pageable, onSuccess, onError) {
            return ShowcasesFactory.listAll(pageable, showcase, onSuccess, onError);
        }

        function listBagsFiltersValues(showcase, onSuccess, onError) {
            return ShowcasesFactory.listBagsFiltersValues(showcase, onSuccess, onError);
        }

        function getShowcase(id, ownerId) {
            return ShowcasesFactory.get({
                id: id,
                ownerId: ownerId
            }).$promise;
        }

        function getAllFlyerIdsFromShowcasesPublished() {
            return ShowcasesFactory.getAllFlyerIdsFromShowcasesPublished().$promise;
        }

        function publishShowcase(showcaseId) {
            return ShowcasesFactory.publish({
                id: showcaseId
            }, {}).$promise;
        }
        function getPublishedShowcaseByStoreId() {
            return ShowcasesFactory.getPublishedShowcaseByStoreId().$promise;
        }

        function deleteShowcases(showcaseIds) {
            return ShowcasesFactory.deletebyids({
                showcaseIds: showcaseIds
            }).$promise;
        }

        function getNumberOfVisualization(id) {
            return ShowcasesFactory.getNumberOfVisualization(id).$promise;
        }

        function getNumberOfFlyers(id) {
            return ShowcasesFactory.getNumberOfFlyers({id: id}).$promise;
        }

        function generateTabloid(dto){
            return ShowcasesFactory.generateTabloid(dto).$promise;
        }

        function addPublishedFlyer(id){
            return ShowcasesFactory.getPublishedFlyer({id: id}).$promise;
        }

        function addPublishedFlyers(flyersId){
            return ShowcasesFactory.getPublishedFlyers(flyersId).$promise;
        }

        function deletePublishedFlyer(id){
            return ShowcasesFactory.deletePublishedFlyer({id: id}).$promise;
        }

        function deletePublishedFlyers(flyersId){
            return ShowcasesFactory.deletePublishedFlyers(flyersId).$promise;
        }

        function isFlyerPublished(id){
            return ShowcasesFactory.isFlyerPublished({id: id}).$promise;
        }

        function createBagByRecommendation(showkase) {
            return ShowcasesFactory.createBagByRecommendation(showkase).$promise;
        }

    }

})();
