(function() {
    'use strict'

    'use strict';
    angular
        .module('showcaseApp')
        .factory('EngagementFactory', EngagementFactory);

    //** NgInject */
    function EngagementFactory($resource) {
        var resourceUrl = '';

        return $resource(resourceUrl, {}, {

            'listUsersFromCoupons': {
                method: 'POST',
                isArray: true,
                url: '/flyer/api/report/engagement/bycoupon',
                transformResponse: function(data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'listUsersFromContact': {
                method: 'POST',
                isArray: true,
                url: '/flyer/api/report/engagement/bycontact',
                transformResponse: function(data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'listUsersFromSale': {
                method: 'POST',
                isArray: true,
                url: '/flyer/api/report/engagement/bysale',
                transformResponse: function(data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            }
        });
    }

})();
