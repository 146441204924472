(function () {
    'use strict';

    angular
        .module('showcaseApp')
        .factory('UserInteractModalFactory', UserInteractModalFactory);

    /** @ngInject */
    function UserInteractModalFactory($uibModal) {

        var factory = {
            selectUserInterectType: selectUserInterectType
        }

        return factory;

        function selectUserInterectType(storeId, selectedType, backUrl) {
            return showUserInterectModal(storeId, selectedType, backUrl);
        }

        function showUserInterectModal(storeId, selectedType, backUrl) {
            return $uibModal.open({
                templateUrl: "app/admin/showcases/modal/_modal_user_interact.html",
                size: 'lg',
                backdrop: 'static',
                keyboard : false,
                windowClass: 'modal-center',
                windowTopClass: 'modal-dialog-center',
                controller: "UserInteractModalConstroller",
                controllerAs: "vm",
                resolve: {
                    storeId: function () {
                        return storeId;
                    },
                    selectedType: function() {
                        return angular.copy(selectedType);
                    },
                    isStoreOwner: function(Principal) {
                        return Principal.isStoreOwner();
                    },
                    storeOwnerHasMoipAccount: function(Principal, StoreService, $q) {
                        var deferred = $q.defer();

                        Principal.storeOwnerHasMoipAccount().then(function (responseMoipAccount) {
                            deferred.resolve(responseMoipAccount.data != null
                                                && responseMoipAccount.data.moipLogin != null);
                        },function(){
                            deferred.resolve(false);
                        });

                        return deferred.promise;
                    },
                    backUrl: function () {
                        return backUrl ? backUrl : 'showcases';
                    }
                }
            });
        }
    }
})();
