(function() {
    'use strict';
    angular
        .module('showcaseApp')
        .service('HubCampaignService', HubCampaignService);

    HubCampaignService.$inject = ['HubCampaignFactory'];

    function HubCampaignService(HubCampaignFactory) {

        var service = {
            listAll: listAll,
            getOne: getOne,
            create: create,
            update: update,
            remove: remove,
            distribute: distribute
        };

        return service;


        function listAll(hubId, filter) {
            return HubCampaignFactory.listAll({hubId: hubId}, filter).$promise;
        }

        function getOne(id) {
            return HubCampaignFactory.getOne({id: id}).$promise;
        }

        function create(campaign) {
            return HubCampaignFactory.create(campaign).$promise;
        }

        function update(id, campaign) {
            return HubCampaignFactory.update({id: id}, campaign).$promise;
        }

        function remove(id) {
            return HubCampaignFactory.remove({id: id}).$promise;
        }

        function distribute(id, request) {
            return HubCampaignFactory.distribute({id: id}, request).$promise;
        }
    }

})();
