(function () {
    'use strict';

    angular
        .module('showcaseApp')
        .factory('NexaasService', NexaasService);

    NexaasService.$inject = ['NexaasFactory'];

    function NexaasService(NexaasFactory) {

        var service = {
            syncProductsByStoreAndFlyerIds: syncProductsByStoreAndFlyerIds
        };

        return service;

        function syncProductsByStoreAndFlyerIds(storeId, flyerIds) {
            return NexaasFactory.syncProductsByStoreAndFlyerIds({ storeId: storeId }, flyerIds).$promise;
        }
    }
})();
