(function () {
    'use strict';
    angular
        .module('showcaseApp')
        .service('HomeService', HomeService);

    HomeService.$inject = ['HomeFactory'];

    function HomeService(HomeFactory) {

        var service = {
            getReport: getReport,
            getStoreInfo: getStoreInfo,
        };

        return service;

        function getReport(storeId) {
            return HomeFactory.getReport({"storeId": storeId}).$promise;
        }

        function getStoreInfo(storeId) {
            return HomeFactory.getStoreInfo({"storeId": storeId}).$promise;
        }

    }
})();
