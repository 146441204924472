(function () {
    'use strict';
    angular
        .module('showcaseApp')
        .factory('BrandFactoryAdmin', BrandFactoryAdmin);

    BrandFactoryAdmin.$inject = ['$resource'];

    function BrandFactoryAdmin($resource) {
        var resourceUrl = 'admin/api/brand';

        return $resource(resourceUrl, {}, {
            'getBrandsByCurrentSTore': {
                method: 'GET',
                isArray: false,
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'findBrand': {
                method: 'POST',
                url: 'admin/api/brand/search',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'createBrand': {
                method: 'POST',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'getBrandById': {
                method: 'GET',
                url: 'admin/api/brand/:brandId',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'removeBrand': {
                method: 'DELETE',
                url: 'admin/api/brand/:brandId',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'editBrand': {
                method: 'PUT',
                url: 'admin/api/brand/:brandId',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            }
        });
    }
})();
