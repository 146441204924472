(function() {
    'use strict';

    angular
        .module('showcaseApp')
        .controller('BagChatController', BagChatController);

        BagChatController.$inject =['$uibModalInstance', '$window', '$stateParams', '$localStorage',
                                    'StoreService', '$translate', 'FlyersService', '$timeout',
                                    'FlyerCategoryService', 'ShowcasesService', 'toastr',
                                    'Principal', 'communicationConstants', 'currentCustomer', 'currentSellerId'];

    function BagChatController($uibModalInstance, $window, $stateParams, $localStorage,
        StoreService, $translate, FlyersService, $timeout, FlyerCategoryService,
        ShowcasesService, toastr, Principal, communicationConstants, currentCustomer, currentSellerId) {

        var vm = this;
        var storeId = $localStorage.defaultStore.storeId;
        var mainColor = getComputedStyle(document.documentElement).getPropertyValue('--main-color');
        vm.showcaseId = $stateParams.showcaseId;

        StoreService.getStore($localStorage.defaultStore.storeId).then(function(response) {
            vm.storeCategory = response.data.categoryKey;
        });

        vm.currentPage = 1;
        vm.maxSize = 15;
        vm.numPerPage = vm.maxSize;
        vm.paginationOptions = {
            page: vm.currentPage - 1,
            size: vm.numPerPage,
            sort: null
        };

        vm.totalAvailableFlyers = 0;

        vm.colors = [];
        vm.sizes = [];
        vm.featureds = [];
        vm.tags = [];
        vm.genders = [];
        vm.isActive = false;
        vm.totalPages = 0;

        vm.showcase = {
            'backgroundColor': false,
            flyers: [],
            ownerId: null,
            hasCover: false,
            name: ''
        };
        vm.selected = {};
        vm.originalFlyers = [];
        vm.allSelected = false;

        vm.filters = {
            brands: [],
            colors: [],
            sizes: [],
            categories: [],
            genders: [],
            tags: [],
            storeId: storeId
        }
 
        var bagDefaultImg = getDefaultImageBagUrl();
        var bagDefaultImgData = '';

        function getDefaultImageBagUrl() {
            if ($localStorage.defaultStore.hubId == 25) {
                return 'https://showkase-prod.s3.sa-east-1.amazonaws.com/hub/system-images/storepiccadilly/image_default_bag.png'
            } else {
                return 'https://showkase-prod.s3.sa-east-1.amazonaws.com/hub/system-images/showkase/image_default_bag.png';
            }
        }

        vm.filtersLen = 0;
        vm.getFiltersLen = function() {
            vm.filtersLen = 0;
            for (var filter in vm.filters) {
                if (Array.isArray(vm.filters[filter])) {
                    vm.filtersLen += vm.filters[filter].length;
                }
            }

        }

        vm.changeActive = function () {
            vm.isActive = !vm.isActive;
        }

        vm.closePreferences = function () {
            $("btn-group preferences open").removeClass("open");
        }

        vm.closeDropDown = function (event, level) {
            $("md-backdrop").trigger('click');
        }

        vm.getSelectedTextGender = function (filter) {
            var genderTranslate = '';
            if (filter[0] === 1) {
                genderTranslate = $translate.instant('flyers.new.form.gender.male');
            }
            if (filter[0] === 2) {
                genderTranslate = $translate.instant('flyers.new.form.gender.female');
            }
            if (filter[0] === 3) {
                genderTranslate = $translate.instant('flyers.new.form.gender.both');
            }
            if (filter.length == 1) {
                return '<span class="highlight">' + genderTranslate + '</span>';
            }
            if (filter.length > 1) {
                var temp = '  +';
                temp += filter.length - 1;
                temp += ' selecionados';
                return '<span class="highlight">' + genderTranslate + '</span>' +
                    '<span>&nbsp</span>' +
                    '<span class="highlight">' + temp + '</span>';
            }
            return '';
        }


        vm.getSelectedTextCategory = function (filter) {
            for (var i = 0; i < vm.categories.length; i++) {
				if (vm.categories[i].key === filter[0]){
		            if (filter.length == 1) {
		                return '<span class="highlight">' + vm.categories[i].title + '</span>';
		            }
		            if (filter.length > 1) {
		                var temp = '  +';
		                temp += filter.length - 1;
		                temp += ' selecionados';

		                return '<span class="highlight">' + vm.categories[i].title + '</span>' +
		                    '<span>&nbsp</span>' +
		                    '<span class="highlight">' + temp + '</span>';
		            }
		            return '';
				}
			}
            return '';
        }

        vm.getSelectedText = function (filter) {
            var elementTranslate = '';

            if (filter[0] === 'launch' || filter[0] === 'basic') {
                elementTranslate = $translate.instant('flyers.new.form.configurations.' + filter[0].toLowerCase());
            } else {
                elementTranslate = filter[0];
            }
            if (filter.length == 1) {
                return '<span class="highlight">' + elementTranslate + '</span>';
            }
            if (filter.length > 1) {
                var temp = '  +';
                temp += filter.length - 1;
                temp += ' selecionados';

                return '<span class="highlight">' + elementTranslate + '</span>' +
                    '<span>&nbsp</span>' +
                    '<span class="highlight">' + temp + '</span>';
            }
            return '';

        }


        vm.checkIfFilterSelected = function (filterKey, filter) {
            return (vm.filters[filterKey] ? vm.filters[filterKey] : []).indexOf(filter) >= 0 ? true : false;
        }

        vm.updateFilterOrRemove = function (filterKey, filter) {
            if (!vm.filters[filterKey]) {
                vm.filters[filterKey] = [];
            }
            var index = vm.filters[filterKey].indexOf(filter);
            if (index === -1) {
                vm.filters[filterKey].push(filter);
                return;
            }
            vm.filters[filterKey].splice(index, 1);
        }

        vm.hasSome = function (list, arr) {
            if (arr) {
                return arr.some(function (v) {
                    return list.indexOf(v.id) >= 0;
                });
            }
        };

        vm.makeTagsToFilter = function () {
            vm.filters.tags = vm.filters.tags ? vm.filters.tags.map(function (item) { return item.text ? item.text : item }) : [];
        }

        vm.getCategoryName = function (flyer) {
            var category = _.find(vm.categories, function(el) {return el.key === flyer.categoryKey});
            if (category) {
                flyer.category = category.title;
            }
        }

        vm.saveBag = function () {
            ShowcasesService.saveBag({
                showcaseTemplateId: 1,
                backgroundColor: mainColor,
                ownerId: 0,
                hasCover: true,
                userInteract: 'SALE',
                flyers: [],
                excludes: [],
                bagFilters: vm.filters
            });
        }


        vm.templateIsLoading = true;
        vm.templates = [];

        vm.storeOwnerHasMoipAccount = false;
        storeOwnerHasMoipAccount();

        function storeOwnerHasMoipAccount() {
            Principal.storeOwnerHasMoipAccount().then(function (responseMoipAccount) {
                vm.storeOwnerHasMoipAccount = responseMoipAccount.data != null && responseMoipAccount.data.moipLogin != null;
            });
        }

        vm.getTranslateType = function (type) {
            return 'flyers.new.form.userInteract.types.' + type.toLowerCase();
        }

        vm.getTranslateFeatureds = function (featured) {
            return 'flyers.new.form.configurations.' + featured.toLowerCase();
        }

        vm.getTranslateGenders = function (gender) {
            if (gender === 1) {
                return 'flyers.new.form.gender.male';
            }
            if (gender === 2) {
                return 'flyers.new.form.gender.female';
            }
            if (gender === 3) {
                return 'flyers.new.form.gender.both';
            }
            return '';
        }
        loadAll();

        function loadAll() {
			FlyersService.getAllFiltersFlyer().then(function (response) {
                vm.brands = response.data.brands;
				vm.colors = response.data.colors;
				vm.sizes = response.data.sizes;
				vm.types = response.data.types;
				vm.featureds = response.data.features;
				vm.tags = response.data.tags;
				vm.genders = response.data.genders;
            });

            FlyerCategoryService.listCategoriesWithFlyers($translate.use()).then(function (response) {
                vm.categories = response.data;
            });


            vm.templateId = 1; // Chose the first template as default
            ShowcasesService.getTemplates().then(function (response) {
                vm.templates = response;
            });

            if (vm.showcaseId) {
                loadShowcase().then(function () {
                    loadFlyers();
                });
            } else {
                vm.showcase.ownerId = storeId;
                vm.showcase.backgroundColor = mainColor;
                vm.showcase.showcaseTemplateId = vm.templateId;
                vm.templateIsLoading = false;
                if(!vm.showcase.bagFilters || (vm.showcase.bagFilters && !vm.showcase.bagFilters.type)) {
                    vm.filters.type = 'SALE';
                }

            }

            StoreService.getOwnerCategoryCurrentStore().then(function (response) {
                vm.storeCategory = response.data;
            });
            vm.loading = true;
            ShowcasesService.findFlyerByFilterBag(vm.paginationOptions, vm.filters).then(
                function (response) {
                    vm.currentPage = response.page;
                    vm.totalPages = response.totalPages;
                    vm.flyersFiltered = response.content;
                    vm.totalAvailableFlyers = response.totalElements;
                    vm.loading = false
                    vm.verifyAllPageChecked();
                },
                function (err) {
                    vm.loading = false;
                }
            );
        }

        vm.verifyAllPageChecked = function() {
            var count = 0;
            vm.flyersFiltered.forEach(function(flyer) {
                if (vm.selected[flyer.id]) {
                    count++;
                }
            });
            vm.allSelected = vm.flyersFiltered.length == count;
        }

        vm.onSelectAll = function() {
            vm.allSelected = !vm.allSelected;
            vm.flyersFiltered.forEach(function(flyer) {
                if (vm.allSelected) {
                    if (!(flyer.id in vm.selected)) {
                        vm.selected[flyer.id] = flyer;
                    }
                } else {
                    delete vm.selected[flyer.id];
                }
            });
            vm.totalFlyers = vm.getTotalFlyers();
        }

        vm.getCurrencyPrefix = function(flyer) {
            var key = flyer.currency ? flyer.currency : 'REAL';
            var config = getCurrencyListConfiguration()[key];
            return config.prefix;
        }

        vm.totalFlyers = 0;
        vm.getTotalFlyers = function () {
            if (vm.flyersFiltered !== undefined && vm.selected !== undefined) {
                return vm.flyersFiltered.length - (vm.flyersFiltered.length - Object.keys(vm.selected).length);
            }

            return 0;
        }

        vm.flyersSelected = function () {
            return Object.keys(vm.selected).length === vm.totalAvailableFlyers;
        }

        vm.refreshSelectedFlyers = function (flyer) {
            if (flyer.id in vm.selected) {
                delete vm.selected[flyer.id]
            } else {
                vm.selected[flyer.id] = flyer
            }
            vm.totalFlyers = vm.getTotalFlyers();
            vm.allSelected = vm.flyersSelected();
        }

        vm.checkIfFlyerSelected = function (idFlyer) {
            return idFlyer in vm.selected;
        }

        vm.filterFlyers = function () {
            scrollToTop();
            vm.currentPage = 1;
            if (hasTextFilter()) {
                loadFlyersFromFlyerService(vm.currentPage);
            } else {
                loadFlyersFromShowcaseService(vm.currentPage);
            }

            vm.getFiltersLen();
            $('.btn-group.preferences').removeClass('open');
            $('.dropdown-backdrop').css('display', 'none');
            vm.changeActive();
        };

        vm.loadFlyers = function () {
            vm.allSelected = false;
            if (vm.loading) {
                return;
            }

            if (vm.totalPages == (vm.currentPage - 1)) {
                return;
            }

            if (hasTextFilter()) {
                loadFlyersFromFlyerService(vm.currentPage);
            } else {
                loadFlyersFromShowcaseService(vm.currentPage);
            }
        }

        vm.closeModal = function(url) {
            $uibModalInstance.close(url);
        }

        function scrollToTop() {
            $timeout(function () {
                var scroller = document.getElementById("scroll-div");
                if (scroller) {
                    scroller.scrollTop = 0;
                }
            }, 0, false);
        }

        function hasTextFilter() {
            return vm.filters.text && vm.filters.text.length > 0;
        }

        function loadFlyersFromFlyerService(page) {
            vm.paginationOptions.page = page - 1;
            vm.loading = true;
            FlyersService.listAllToAdmin(vm.paginationOptions, vm.filters, function(data, headers) {
                vm.flyersFiltered = data.content;
                vm.totalAvailableFlyers = data.totalElements;
                vm.currentPage = data.page + 1;
                vm.totalPages = data.totalPages;
                vm.loading = false;
                vm.verifyAllPageChecked();
            }, function(_) {
                vm.loading = false;
            });
        }

        function loadFlyersFromShowcaseService(page) {
            vm.paginationOptions.page = page - 1;
            vm.loading = true;
            ShowcasesService.findFlyerByFilterBag(vm.paginationOptions, vm.filters).then(function (response) {
                vm.flyersFiltered = response.content;
                vm.currentPage = response.number + 1;
                vm.totalPages = response.totalPages;
                vm.totalAvailableFlyers = response.totalElements;
                vm.loading = false;
                vm.verifyAllPageChecked();
            }, function(_) {
                vm.loading = false;
            });
        }


        function getStoreInfo() {
            return StoreService.getStore($localStorage.defaultStore.storeId);
        }

        getStoreInfo().then(function(response) {
            $localStorage.defaultStore.storeType = response.data.storeType;
            vm.store = response.data;
        });

        vm.getShowcaseURL = function(idBag) {
            if (!$localStorage.defaultStore.storeNickname) {
                getStoreInfo().then(function(response) {
                    $localStorage.defaultStore.storeNickname = response.data.nickname;
                    vm.closeModal({flyers: vm.finalFlyers, url: $window.location.protocol + '//' + $localStorage.defaultStore.storeNickname + '.' + getDomainNameSufix($window, vm.store.storeType) + '/bag/' + idBag});
                });
            } else {
                vm.closeModal({flyers: vm.finalFlyers, url: $window.location.protocol + '//' + $localStorage.defaultStore.storeNickname + '.' + getDomainNameSufix($window, vm.store.storeType) + '/bag/' + idBag});
            }

        }

        var getBase64Image = function (url) {
            var img = new Image();
            img.setAttribute('crossOrigin', 'anonymous');
            img.onload = function () {
                var canvas = document.createElement("canvas");
                canvas.width = img.width;
                canvas.height = img.height;
                var ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0);
                var dataURL = canvas.toDataURL("image/png");
                bagDefaultImgData = dataURL;
            }
            img.src = url
        }
        getBase64Image(bagDefaultImg);

        function dataURItoBlob(dataURI) {
            var byteString;
            if (dataURI.split(',')[0].indexOf('base64') >= 0)
                byteString = atob(dataURI.split(',')[1]);
            else
                byteString = unescape(dataURI.split(',')[1]);
            var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
            var ia = new Uint8Array(byteString.length);
            for (var i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
            }
            return new Blob([ia], {
                type: mimeString
            });
        }

        vm.createBagWithProducts = function() {
            var bagWithProducts = communicationConstants.DEFAULT_BAG;
            bagWithProducts.coverUrl = getDefaultImageBagUrl();
            var count = 1;

            bagWithProducts.flyers = [];

            for (var flyerId in vm.selected) {
                bagWithProducts.flyers.push({flyerId: flyerId, position: count++})
            }
            
            bagWithProducts.isAllProductsSelected = false;
            bagWithProducts.backgroundColor = mainColor;
            bagWithProducts.name = vm.showcase.name;
            bagWithProducts.isPrimary = false;
            bagWithProducts.bagFilters = vm.filters;
            bagWithProducts.customers = [currentCustomer.id];
            bagWithProducts.users = [currentSellerId];

            if(vm.showcase.name) {
                vm.loadingImage = true;
                FlyersService.saveFlyerMultiImage(dataURItoBlob(bagDefaultImgData), 0).then(function (response) {
                    $timeout(function () {
                        if (response.data) {
                            response.data.action = '';
                            response.data.nameFile = '';
                            bagWithProducts.addImageFileDTO = [response.data];
                            ShowcasesService.createBagByRecommendation(bagWithProducts).then(function (result) {
                                vm.getShowcaseURL(result.id);
                            });
                            return;
                        } else {
                            toastr.error($translate.instant('flyers.messages.create.error'));
                        }
                        vm.loadingImage = false;
                    });
                }, function (response) {
                    toastr.error($translate.instant('flyers.messages.create.image.uploadError'));
                    vm.loadingImage = false;
                });
            } else {
                vm.getShowcaseURL('');
            }
        }
    }
})();
