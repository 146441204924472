(function () {
    'use strict';
    angular
        .module('showcaseApp')
        .service('CheckoutService', CheckoutService);

    CheckoutService.$inject = ['$localStorage', 'MoipPlansFactory'];

    function CheckoutService($localStorage, MoipPlansFactory) {

        var service = {
            getPlans: getPlans,
            resetBillet: resetBillet,
            getPlanByCode: getPlanByCode,
            getSubscriber: getSubscriber,
            generateBillet: generateBillet,
            validateCoupon: validateCoupon,
            isBilletOverdue: isBilletOverdue,
            updateSubscriber: updateSubscriber,
            createSubscriber: createSubscriber,
            createSubscriberFree: createSubscriberFree,
            updateSubscriberCard: updateSubscriberCard
        };

        return service;

        function getPlans(environment) {
            return MoipPlansFactory.getPlans({environment: environment}).$promise;
        }

        function getPlanByCode(code) {
            return MoipPlansFactory.getPlanByCode({
                code: code
            }).$promise;
        }

        function getSubscriber(code) {
            return MoipPlansFactory.getSubscriber({
                code: code
            }).$promise;
        }

        function updateSubscriber(subscriberDTO) {
            return MoipPlansFactory.updateSubscriber(subscriberDTO).$promise;
        }

        function createSubscriber(subscriberDTO) {
            return MoipPlansFactory.createSubscriber(subscriberDTO).$promise;
        }

        function createSubscriberFree() {
            return MoipPlansFactory.createSubscriberFree().$promise;
        }

        function updateSubscriberCard(cardDTO) {
            return MoipPlansFactory.updateSubscriberCard(cardDTO).$promise;
        }

        function isBilletOverdue(cardDTO) {
            return MoipPlansFactory.isBilletOverdue().$promise;
        }

        function generateBillet() {
            return MoipPlansFactory.generateBillet().$promise;
        }

        function resetBillet() {
            return MoipPlansFactory.resetBillet().$promise;
        }

        function validateCoupon(coupon) {
            return MoipPlansFactory.validateCoupon({'coupon': coupon }).$promise;
        }

    }

})();
