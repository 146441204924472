/* globals $ */
(function() {
    'use strict';

    angular
        .module('showcaseApp')
        .directive('recordingTimer', recordingTimer);

    function recordingTimer() {
        return {
            restrict: 'E',
            templateUrl: 'app/admin/communication/directives/audio-recording/recording-timer.html',
            controller: 'RecordingTimerController'
        };
    }

})();
