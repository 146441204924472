    (function() {
    'use strict';

    angular
        .module('showcaseApp')
        .controller('RecommendationModalController', RecommendationModalController);

        RecommendationModalController.$inject = ['$uibModalInstance', '$state', '$localStorage', 'conversation',
                                                 'RecommendationService', 'FlyersService', 'ShowcasesService',
                                                 'communicationConstants', 'StoreService', '$window', 'currentCustomer'];

    function RecommendationModalController($uibModalInstance, $state, $localStorage, conversation, RecommendationService,
                                            FlyersService, ShowcasesService, communicationConstants, StoreService,
                                            $window, currentCustomer) {
        var vm = this;

        StoreService.getStore($localStorage.defaultStore.storeId).then(function(response) {
            vm.storeCategory = response.data.categoryKey;
        });


        var mainColor = getComputedStyle(document.documentElement).getPropertyValue('--main-color');
        var NUMBER_REC = 10;
        vm.isWati = conversation.source === communicationConstants.SOCIAL_MEDIAS.WATI;

        vm.currentStep = 0;

        vm.trendRec = false;
        vm.boughtTogetherRec = false;
        vm.clusterRec = false;
        vm.SVDRec = false;

        vm.recommendIds = [];
        vm.recommendedFlyers = [];
        vm.excludes = [];

        vm.page = 0;
        vm.currentPage = 1;
        vm.paginationOptions = {
            page: vm.currentPage - 1,
            size: 15
        };

        vm.flyers = [];
        vm.listFilter = {
            activated: null,
            brand: "",
            type: "SALE",
            brands: [],
            storeId: $localStorage.defaultStore.storeId,
            hasInventory: true
        };

        vm.bagName = '';

        vm.closeModal = function(url) {
            $uibModalInstance.close(url);
        }

        vm.getShowcaseURL = function(idBag) {
            if (!$localStorage.defaultStore.storeNickname) {
                getStoreInfo().then(function(response) {
                    $localStorage.defaultStore.storeNickname = response.data.nickname;
                    vm.closeModal({flyers: vm.recommendedFlyers, url: $window.location.protocol + '//' + $localStorage.defaultStore.storeNickname + '.' + getDomainNameSufix($window, vm.store.storeType) + '/bag/' + idBag, isCarousel: vm.isCarousel});
                });
            } else {
                vm.closeModal({flyers: vm.recommendedFlyers, url: $window.location.protocol + '//' + $localStorage.defaultStore.storeNickname + '.' + getDomainNameSufix($window, vm.store.storeType) + '/bag/' + idBag, isCarousel: vm.isCarousel});
            }

        }

        vm.loadFlyers = function() {
            FlyersService.listAllToAdmin(vm.paginationOptions, vm.listFilter, function(data, headers) {
                vm.recommendedFlyers = data.content;
                vm.recommendedFlyers.forEach(function(flyer) {
                    vm.recommendIds.push(flyer.id);
                    vm.excludes.push(flyer.id);
                });
            }, function(_) {});
        }

        vm.hasSome = function (list, arr) {
            if (arr) {
                return arr.some(function (v) {
                    return list.indexOf(v) >= 0;
                });
            }
        };

        vm.selectedAll = false;
        vm.onSelectAll = function() {
            if(!vm.selectedAll) {
                vm.selectedAll = true;
                vm.excludes = [];
            } else {
                vm.selectedAll = false;
                vm.excludes = angular.copy(vm.recommendIds);
            }
        }

        vm.loadFlyers();

        function getStoreInfo() {
            return StoreService.getStore($localStorage.defaultStore.storeId);
        }

        getStoreInfo().then(function(response) {
            $localStorage.defaultStore.storeType = response.data.storeType;
            vm.store = response.data;
        });

        function addBagFilters(bagByRecommendation) {
            bagByRecommendation.bagFilters = {}
            bagByRecommendation.bagFilters.brands = [];
            bagByRecommendation.bagFilters.categories = [];
            bagByRecommendation.bagFilters.colors = [];
            bagByRecommendation.bagFilters.excludes = [];
            bagByRecommendation.bagFilters.genders = [];
            bagByRecommendation.bagFilters.sizes = [];
            bagByRecommendation.bagFilters.tags = [];

            return bagByRecommendation;
        }

        function getDefaultImageBagUrl() {
            if ($localStorage.defaultStore.hubId == 25) {
                return 'https://showkase-prod.s3.sa-east-1.amazonaws.com/hub/system-images/storepiccadilly/image_default_bag.png'
            } else {
                return 'https://showkase-prod.s3.sa-east-1.amazonaws.com/hub/system-images/showkase/image_default_bag.png';
            }
        }

        vm.createBagByRecommendation = function() {
            var bagByRecommendation = communicationConstants.DEFAULT_BAG;
            bagByRecommendation.converUrl = getDefaultImageBagUrl();
            var count = 1;
            vm.recommendedFlyers = vm.recommendedFlyers.filter(function(flyer) {
                return vm.checkIfFlyerSelected(flyer.id);
            });
            bagByRecommendation.flyers = vm.recommendedFlyers.map(function(flyer) {
                return {flyerId: flyer.id, position: count++}
            });
            bagByRecommendation.backgroundColor = mainColor;
            bagByRecommendation.name = vm.bagName;
            bagByRecommendation.isPrimary = false;
            bagByRecommendation = addBagFilters(bagByRecommendation);
            if(vm.bagName) {
                ShowcasesService.createBagByRecommendation(bagByRecommendation).then(function(result) {
                    vm.getShowcaseURL(result.id);
                });
                return;
            }
            vm.getShowcaseURL('');
        }

        vm.getCurrencyPrefix = function(flyer) {
            var key = flyer.currency ? flyer.currency : 'REAL';
            var config = getCurrencyListConfiguration()[key];
            return config.prefix;
        }

        vm.nextStep = function() {
            switch(vm.currentStep) {
                case communicationConstants.STEPS_RECOMMENDATION.FIRST:
                    /**
                     *
                     */
                    vm.currentStep = vm.currentStep + 3;
                    break;
                case communicationConstants.STEPS_RECOMMENDATION.SECOND:
                    vm.execRecommendation();
                    vm.currentStep = vm.currentStep + 1;
                    break;
                case communicationConstants.STEPS_RECOMMENDATION.THIRD:
                    vm.currentStep = vm.currentStep + 1;
                    break;
            }
        }

        vm.backStep = function() {
            switch(vm.currentStep) {
                case communicationConstants.STEPS_RECOMMENDATION.FIRST:
                    /**
                     *
                     */
                     vm.closeModal();
                     return;
                case communicationConstants.STEPS_RECOMMENDATION.FOURTH:
                    vm.currentStep = vm.currentStep - 3;
                    break;
                default:
                    vm.currentStep = vm.currentStep - 1;
                    break;
            }

        }

        vm.recommendIds = [];
        vm.recommendedFlyers = [];

        vm.postRecommendationHistory = function(algo, recommendations) {
            vm.recommendationHistory = {
                algoritmo: algo,
                recomendacoes: recommendations,
                userId: currentCustomer.id,
                timestamp: new Date()
            };
            RecommendationService.addToRecommendationHistory(vm.recommendationHistory);
        }

        vm.execRecommendation = function() {

            RecommendationService.trendRec({rec_number: NUMBER_REC, user_id: currentCustomer.id}).then(function(items) {
                vm.postRecommendationHistory(communicationConstants.RECOMMENDATION_TYPES.TREND, items);
                if(vm.trendRec && vm.boughtTogetherRec) {
                    var requests = items.map(function(id) {
                        vm.recommendIds.push(id);
                        return RecommendationService.boughtTogetherRec({rec_number: NUMBER_REC, flyer_id: id, user_id: currentCustomer.id});
                    });

                    Promise.all(requests)
                        .then(function(result) {
                            var btIds = [];
                            result.forEach(function(item) {
                                vm.recommendIds = vm.recommendIds.concat(item);
                                btIds = btIds.concat(item)
                            });
                            vm.postRecommendationHistory(communicationConstants.RECOMMENDATION_TYPES.BOUGHT_TOGETHER, btIds);

                            FlyersService.findFlyersByIds(vm.recommendIds).then(function(flyers) {
                                vm.recommendedFlyers = vm.recommendedFlyers.concat(flyers);
                            });
                        });
                } else {
                    FlyersService.findFlyersByIds(items).then(function(flyers) {
                        flyers.forEach(function(flyer) {
                            vm.recommendIds.push(flyer.id);
                        });
                        vm.recommendedFlyers = vm.recommendedFlyers.concat(flyers);
                    });
                }
            });

            if (vm.clusterRec) {
                RecommendationService.clusterRec({rec_number: NUMBER_REC, user_id: currentCustomer.id}).then(function(items) {
                    vm.postRecommendationHistory(communicationConstants.RECOMMENDATION_TYPES.CLUSTER, items);
                    vm.recommendIds = vm.recommendIds.concat(items);
                    FlyersService.findFlyersByIds(vm.recommendIds).then(function(flyers) {
                        vm.recommendedFlyers = vm.recommendedFlyers.concat(flyers);
                    });
                });
            }

            if (vm.SVDRec) {
                RecommendationService.svdRec({rec_number: NUMBER_REC, user_id: currentCustomer.id}).then(function(items) {
                    vm.postRecommendationHistory(communicationConstants.RECOMMENDATION_TYPES.FUNKSVD, items);
                    vm.recommendIds = vm.recommendIds.concat(items);
                    FlyersService.findFlyersByIds(vm.recommendIds).then(function(flyers) {
                        vm.recommendedFlyers = vm.recommendedFlyers.concat(flyers);
                    });
                });
            }

        }

        vm.checkIfFlyerSelected = function(idFlyer) {
            var result = !vm.excludes.includes(idFlyer);
            return result;
        }

        vm.refreshSelectedFlyers = function(flyer) {
            var index = vm.excludes.indexOf(flyer.id);
            if(index >= 0) {
                vm.excludes.splice(index, 1);
                return;
            }
            vm.selectedAll = false;
            vm.excludes.push(flyer.id);
        }

    }

})();
