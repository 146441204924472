(function () {
    'use strict';

    angular
        .module('showcaseApp')
        .controller('FileManagerController', FileManagerController);

    function FileManagerController(FileManagerService, $uibModal, $scope, $localStorage, $translate, toastr) {

        var vm = this;

        vm.hubId = $localStorage.hub.id;
        vm.currentFolder = {id: -1};
        vm.children = [];
        vm.files = [];
        vm.tags = [];
        vm.breadcrumbs = [];
        vm.filterName = "";
        vm.filterTags = [];
        vm.loadingChildren = true;
        vm.loadingFiles = true;
        vm.loadingBreadcrumb = false;

        getFolderChildren(vm.currentFolder);
        getFolderFiles(vm.currentFolder);
        getFileTags();

        vm.enterInFolder = function (folder) {
            vm.filtring = false;
            vm.currentFolder = folder;
            clearFilter();
            getFolderChildren(vm.currentFolder);
            getFolderFiles(vm.currentFolder);
            if (vm.currentFolder.id === -1) {
                vm.breadcrumbs = [{id: -1, name: $translate.instant("file-manager.folder.home")}];
                vm.loadingBreadcrumb = false;
            } else {
                vm.loadingBreadcrumb = true;
                vm.breadcrumbs = mountBreadCrumb(folder, [{id: -1, name: $translate.instant("file-manager.folder.home")}]);
                setTimeout(function () {
                    vm.loadingBreadcrumb = false;
                    $scope.$apply();
                }, 650);
            }
        }

        vm.createEditFolder = function (folder) {
            return $uibModal.open({
                templateUrl: "app/admin/hub-management/file-manager/_modal_add_edit_folder.html",
                size: 'md',
                windowClass: 'modal-center',
                windowTopClass: 'modal-dialog-center',
                backdrop: 'static',
                resolve: {
                    folder: function () {
                        if (!folder.id) {
                            return  {
                                hubId: vm.hubId,
                                parent: vm.currentFolder.id === -1 ? null : vm.currentFolder,
                                name: ''
                            }
                        } else {
                            return folder;
                        }
                    }
                },
                controller: function ($uibModalInstance, FileManagerService, toastr, $translate, folder) {
                    var vm = this;

                    vm.folder = folder

                    vm.cancel = function () {
                        $uibModalInstance.dismiss('cancel');
                    };

                    vm.save = function () {
                        vm.submitting = true;
                        if (vm.formFolder.$valid) {
                            FileManagerService.createEditFolder(vm.folder.hubId, vm.folder)
                                .then(function () {
                                    toastr.success($translate.instant("file-manager.message.folder-created"));
                                    $uibModalInstance.close();
                                }, function () {
                                    toastr.error($translate.instant("file-manager.message.error-to-create-folder"));
                                }).finally(function () { vm.submitting = false; });
                        } else {
                            touchForm(vm.formFolder)
                            vm.submitting = false;
                        }
                    }
                },
                controllerAs: "vm"
            }).result.then(function() {
                getFolderChildren(vm.currentFolder);
            });
        }

        vm.editFile = function (file) {
            return $uibModal.open({
                templateUrl: "app/admin/hub-management/file-manager/_modal_edit_file.html",
                size: 'lg',
                windowClass: 'modal-center',
                windowTopClass: 'modal-dialog-center',
                backdrop: 'static',
                resolve: {
                    file: function () {
                        return angular.copy(file);
                    },
                    tags: function () {
                        return angular.copy(vm.tags);
                    }
                },
                controller: function ($uibModalInstance, FileManagerService, toastr, $translate, file, tags) {
                    var vm = this;

                    var fileExtension = "." + file.name.substring(file.name.lastIndexOf(".") + 1)

                    vm.file = file;
                    vm.file.name = vm.file.name.replace(fileExtension, '');
                    vm.currentTags = tags;


                    vm.searchTags = function (search) {
                        return vm.currentTags.filter( function (tag) {
                            return tag.name.toLowerCase().indexOf(search.toLocaleLowerCase()) >= 0;
                        });
                    }

                    vm.cancel = function () {
                        $uibModalInstance.dismiss('cancel');
                    };

                    vm.save = function () {
                        if (vm.formFile.$valid) {
                            vm.submitting = true;
                            vm.file.tags = vm.file.tags.map(function (tag) {
                                return {id: tag.id, name: tag.name}
                            })
                            vm.file.name = vm.file.name + fileExtension;
                            FileManagerService.updateFile(vm.file.hubId, vm.file)
                                .then(function () {
                                    toastr.success($translate.instant("file-manager.message.file-updated"));
                                    $uibModalInstance.close();
                                }, function () {
                                    vm.file.name = vm.file.name.replace(fileExtension, '');
                                    toastr.error($translate.instant("file-manager.message.error-to-update-file"));
                                }).finally(function () { vm.submitting = false; });
                        } else {
                            touchForm(vm.formFile)
                            vm.submitting = false;
                        }
                    }
                },
                controllerAs: "vm"
            }).result.then(function() {
                getFolderFiles(vm.currentFolder);
                getFileTags();
            });
        }

        vm.uploadFiles = function () {
            return $uibModal.open({
                templateUrl: "app/admin/hub-management/file-manager/_modal_upload_files.html",
                size: 'lg',
                windowClass: 'modal-center',
                windowTopClass: 'modal-dialog-center',
                backdrop: 'static',
                resolve: {
                    parent: function () {
                        return vm.currentFolder;
                    },
                    hubId: function () {
                        return vm.hubId;
                    },
                    tags: function () {
                        return vm.tags;
                    }
                },
                controller: function ($uibModalInstance, FileManagerService, toastr, $translate,  parent, hubId, tags) {
                    var vm = this;

                    vm.files = [];
                    vm.currentTags = tags;
                    vm.tags = []

                    vm.searchTags = function (search) {
                        return vm.currentTags.filter( function (tag) {
                            return tag.name.toLowerCase().indexOf(search.toLocaleLowerCase()) >= 0;
                        });
                    }


                    vm.cancel = function () {
                        $uibModalInstance.dismiss('cancel');
                    };

                    vm.remove = function (index) {
                        if (vm.submitting) {
                            return;
                        }
                        vm.files.splice(index, 1);
                    }

                    vm.upload = function () {
                        vm.submitting = true;
                        uploadSync(0);
                    }

                    function uploadSync(index) {
                        if (index > vm.files.length -1) {
                            vm.submitting = false;
                            toastr.success($translate.instant("file-manager.message.files-uploaded"));
                            $uibModalInstance.close();
                            return;
                        }

                        var tagsObject = vm.tags.map(function (tag) {
                            return {id: tag.id, name: tag.name}
                        })

                        var file = vm.files[index];

                        if (file && file.status === 'SUCCESS') {
                            uploadSync(index + 1);
                        }

                        file.status = "IN_PROGRESS";
                        var fileInfo = {
                            hubId: hubId,
                            parent: parent.id === -1 ? null : parent,
                            name: file.name,
                            size: file.size,
                            type: file.blob.type,
                            tags: tagsObject
                        }

                        var data = new FormData();
                        data.append('file', file.blob, file.name)
                        data.append('fileInfo', new Blob([JSON.stringify(fileInfo)], {type: "application/json"}));

                        FileManagerService.uploadFile(hubId, data)
                            .then(function () {
                                file.status = "SUCCESS";
                                uploadSync(index + 1);
                            }, function () {
                                vm.submitting = false;
                                file.status = "ERROR";
                                toastr.error($translate.instant("file-manager.message.error-to-upload-file"));
                            })
                    }
                },
                controllerAs: "vm"
            }).result.then(function() {
                getFolderFiles(vm.currentFolder);
                getFileTags();
            });
        }

        vm.deleteConfirmation = function (isFolder, object, callback) {
            return $uibModal.open({
                templateUrl: "app/admin/hub-management/file-manager/_modal_delete_confirmation.html",
                size: 'md',
                windowClass: 'modal-center',
                windowTopClass: 'modal-dialog-center',
                backdrop: 'static',
                resolve: {
                    isFolder: function () {
                        return isFolder;
                    },
                    objectName: function () {
                        return object.name;
                    }
                },
                controller: function ($uibModalInstance, FileManagerService, $translate, isFolder, objectName) {
                    var vm = this;

                    vm.objectName = objectName;
                    vm.isFolder = isFolder;

                    vm.confirm = function (confirm) {
                        $uibModalInstance.close(confirm);
                    }
                },
                controllerAs: "vm"
            }).result.then(function(confirmation) {
                if (confirmation) {
                    callback(object.id);
                }
            });
        }

        vm.deleteFolder = function (folderId) {
            FileManagerService.deleteFolder(vm.hubId, folderId)
                .then(function () {
                    getFolderChildren(vm.currentFolder);
                    getFileTags();
                    toastr.success($translate.instant("file-manager.message.folder-deleted"));
                }, function () {
                    toastr.error($translate.instant("file-manager.message.error-to-delete-folder"));
                });
        }

        vm.deleteFile = function (fileId) {
            FileManagerService.deleteFile(vm.hubId, fileId)
                .then(function () {
                    getFolderFiles(vm.currentFolder);
                    getFileTags();
                    toastr.success($translate.instant("file-manager.message.file-deleted"));
                }, function () {
                    toastr.error($translate.instant("file-manager.message.error-to-delete-file"));
                });
        }

        vm.getFileType = function (type) {
            type = type.split('/')[0];
            switch (type){
                case 'image':
                    return 'IMAGE'
                case 'video':
                    return 'VIDEO';
                default:
                    return 'OTHER';
            }
        }

        vm.getFileName = function (originalName) {
            var extension = "." + originalName.substring(originalName.lastIndexOf(".") + 1);
            return originalName.substring(0, 14) + '(...)' + extension;
        }

        vm.filterByName = function () {
            filterFiles();
        }

        vm.filterByTag = function (tag) {
            var index = _.findIndex(vm.filterTags,function (t) { return t  === tag.id })
            if (index >= 0) {
                tag.selected = false;
                vm.filterTags.splice(index, 1);
            } else {
                tag.selected = true;
                vm.filterTags.push(tag.id)
            }
            filterFiles();
        }

        function getFolderChildren(folder) {
            vm.loadingChildren = true;
            FileManagerService.getFolderChildren(vm.hubId, folder.id)
                .then(function (result) {
                    vm.children = result.data;
                    vm.loadingChildren = false;
                }, function () {
                    toastr.error($translate.instant("file-manager.message.error-to-load-folder-children"));
                    vm.loadingChildren = false;
                });
        }

        function getFolderFiles(folder) {
            vm.loadingFiles = true;
            FileManagerService.getFolderFiles(vm.hubId, folder.id)
                .then(function (result) {
                    vm.files = result.data;
                    vm.loadingFiles = false;
                }, function () {
                    toastr.error($translate.instant("file-manager.message.error-to-load-folder-files"));
                    vm.loadingFiles = false;
                });
        }

        function getFileTags() {
            vm.loadingTags = true;
            FileManagerService.getFileTags(vm.hubId)
                .then(function (result) {
                    vm.tags = result.data;
                    vm.loadingTags = false;
                }, function () {
                    toastr.error($translate.instant("file-manager.message.error-to-load-tags"));
                    vm.loadingTags = false;
                });
        }

        function mountBreadCrumb(folder, breadcrumbs) {
            if (folder.id === -1) {
                return [];
            }

            if (folder.parent) {
                mountBreadCrumb(folder.parent, breadcrumbs)
            }

            breadcrumbs.push({id: folder.id, name: folder.name, parent: folder.parent})
            return breadcrumbs;
        }

        function touchForm(form) {
            angular.forEach(form.$error, function (field) {
                angular.forEach(field, function (errorField) {
                    errorField.$setTouched();
                });
            });
            toastr.warning($translate.instant("file-manager.form.check-form"));
        }

        function filterFiles() {
            if (!vm.filterName && !vm.filterTags.length) {
                getFolderFiles(vm.currentFolder);
                vm.filtring = false;
                return;
            }

            vm.filtring = true;

            vm.loadingFiles = true;
            FileManagerService.filterFiles(vm.hubId, vm.filterName, vm.filterTags)
                .then(function (result) {
                    vm.files = result.data;
                }, function () {
                    toastr.error($translate.instant("file-manager.message.error-to-load-files"));
                }).finally(function () { vm.loadingFiles = false});
        }

        function clearFilter () {
            vm.filterName = "";
            vm.filterTags = [];
            angular.forEach(vm.tags, function (tag) {
                tag.selected = false;
            })
        }

    }
})();
