(function () {
    'use strict';

    angular
        .module('showcaseApp')
        .controller('InstaModalController', InstaModalController);

        InstaModalController.$inject = ['$uibModalInstance', '$timeout',
        '$localStorage', 'FacebookService', 'ShowcasesService', '$translate',
        '$state', 'toastr', '$q', '$scope', 'BusinessIntegrationService', '$window'
    ];

    function InstaModalController($uibModalInstance, $timeout,
        $localStorage, FacebookService, ShowcasesService, $translate,
         $state, toastr, $q, $scope, BusinessIntegrationService, $window) {
        var vm = this;
        vm.catalogName = "";
        vm.project = {};
        vm.project.total = 0;
        vm.selectedCity;
        vm.checkout = {};
        vm.facebook = {
            catalogGenerated: false,
            catalog: {
                id: ''
            },
            feed: {
                id: ''
            },
            connected: false
        };
        vm.isBussy = false;
        vm.userBusinesses = [];
        vm.user = {};
        vm.currentStep = 0;
        vm.totalOfSteps = 4;
        vm.selectedBusiness;
        vm.selectedBusinessPage;
        vm.showcase = null;
        vm.searchText = null;

        vm.confirm = function () {
            $uibModalInstance.close(true);
        };

        vm.closeModal = function () {
            $uibModalInstance.close(false);
        };

        vm.fbLogin = function () {
            vm.processing = true;
            FacebookService.login().then(updateStatus);
        };

        vm.fbLogout = function () {
            FacebookService.logout().then(updateStatus);
        };

        vm.checkFbStatus = function () {
            vm.processing = true;
            FacebookService.status().then(updateStatus);
        };

        vm.generateCatalog = function () {
            vm.isBussy = true;

            FacebookService.createCatalog(vm.selectedBusiness.id, vm.catalogName)
                .then(function (result) {
                    vm.facebook.catalog.id = result.id;
                    return FacebookService.addFeed(vm.facebook.catalog.id, $localStorage.defaultStore.storeNickname)
                }).then(function (result) {
                    vm.isBussy = false;
                    vm.facebook.feed.id = result.id;
                }).catch(function (err) {
                    toastr.error(err)
                    vm.catalogName = '';
                    vm.isBussy = false
                });
        };

        vm.loadUserData = function () {
            vm.processing = true;
            if (vm.facebook.connected) {
                FacebookService.me().then(function (data) {
                    vm.user = data;
                    vm.processing = false;
                });
            }
        };

        vm.loadShowcases = function () {
            vm.processing = true;
            ShowcasesService.getPublishedShowcaseByStoreId().then(
                onSuccess,
                onError
            );

            function onSuccess(res) {
                vm.showcase = res.data;
                vm.processing = false;
            }

            function onError(response) {
                vm.processing = false;
                vm.busy = false;

                if (response.headers('X-flyerApp-alert') == 'userHasNoStore') {
                    toastr.error(
                        $translate.instant('showcases.messages.errors.noStore')
                    );
                }
            }
        };
        vm.loadShowcases();

        vm.goToShowcases = function () {
            $state.go('showcases');
            $uibModalInstance.close(true);
        };

        vm.loadBusiness = function () {
            vm.selectedBusiness = null;
            vm.processing = true;
            FacebookService.business().then(function (res) {
                vm.processing = false;
                vm.userBusinesses = res.data;
            });
        };

        vm.loadBusinessPages = function () {
            vm.selectedBusinessPage = null;
            vm.processing = true;
            FacebookService.businessPages(vm.selectedBusiness.id).then(function (res) {
                vm.userBusinessesPages = res.data;
                vm.processing = false;
            });
        };

        vm.querySearch = function (query) {
            vm.deferred = $q.defer();
            FacebookService.searchCity(query)
                .then(function (res) {
                    vm.deferred.resolve(res)
                });
            return vm.deferred.promise;
        }

        function validateExpirationDate() {
            return Moip.Validator.isExpiryDateValid(vm.expirationDate.substring(0,2), vm.expirationDate.substring(2,4));
        }

        function encryptCreditCardData() {


            var encryptCreditCard = new Moip.CreditCard({
                number: vm.checkout.cardNumber,
                cvc: vm.checkout.cvc,
                expMonth: vm.expirationDate.substring(0,2),
                expYear: vm.expirationDate.substring(2,4),
                pubKey: vm.keys.encryption
            });

            if (encryptCreditCard.isValid()) {
                vm.checkout.hash = encryptCreditCard.hash();

                return true;
            }

            return false;
        }

        /**
         * On Init
         */
        $timeout(function () {
            vm.checkFbStatus();
            BusinessIntegrationService.getOwnerKeys($localStorage.defaultStore.storeId).then(function (response) {
                vm.keys = response.data;
            });
        }, 500);

        /**
         * Pagination control section
         */
        vm.currentStep

        vm.next = function () {
            vm.currentStep < vm.totalOfSteps ?
                (vm.currentStep = vm.currentStep + 1) :
                '';
        };

        vm.previous = function () {
            if (vm.currentStep === 1) {
                vm.selectedBusiness = null;
            }
            if (vm.currentStep === 2) {
                vm.selectedBusinessPage = null;
            }
            vm.currentStep !== 0 ? (vm.currentStep = vm.currentStep - 1) : '';
        };

        vm.modalTitle = function () {
            switch (vm.currentStep) {
                case 0:
                    return {title: $translate.instant("integrations.instagramBusiness.modal.step1.title"), subTitle: $translate.instant("integrations.instagramBusiness.modal.step1.subTitle")} ;
                case 1:
                    return {title: $translate.instant("integrations.instagramBusiness.modal.step2.title"), subTitle: $translate.instant("integrations.instagramBusiness.modal.step2.subTitle")};
                case 2:
                    return {title: $translate.instant("integrations.instagramBusiness.modal.step3.title"), subTitle: $translate.instant("integrations.instagramBusiness.modal.step3.subTitle")};
                case 3:
                    return {title: $translate.instant("integrations.instagramBusiness.modal.step4.title"), subTitle: $translate.instant("integrations.instagramBusiness.modal.step4.subTitle")};
                default:
                    return false;
            }
        };
        vm.nextDisabled = function () {
            switch (vm.currentStep) {
                case 0:
                    return false;
                case 1:
                    return vm.facebook.connected && vm.selectedBusiness ? false : true;
                case 2:
                    return vm.facebook.catalog.id ? false : true;
                case 3:
                    return false;
                default:
                    return false;
            }
        };

        vm.previousDisabled = function () {
            switch (vm.currentStep) {
                case 3:
                    return vm.facebook.catalog.id ? true : false;
                case 4:
                    return vm.campaingResult ? true : false;
                case 5:
                    return true;

                default:
                    return false;
            }
        };

        $scope.$watch('vm.project.days', function (newValue, oldValue) {
            vm.project.total = vm.project.value > 0 ? vm.project.value * vm.project.days : 0;
        });
        $scope.$watch('vm.project.value', function (newValue, oldValue) {
            vm.project.total = vm.project.value > 0 ? vm.project.value * vm.project.days : 0;
        });

        vm.getFormattedPrice = function (price) {
            price = price.toString();
            return price.substring(0, price.length - 2) + ',' + price.substring(price.length - 2, price.length);
        }

        function updateStatus(response) {
            if (response.status === 'connected') {
                vm.facebook.connected = true;
                vm.facebook.data = response;
                vm.loadBusiness();
                vm.loadUserData();
            } else {
                vm.facebook.connected = false;
                vm.userBusinesses = [];
                vm.facebook.data = {};
            }
            vm.processing = false;
        }

        vm.verifyExpirationDate = function () {
            if (vm.expirationDate && vm.expirationDate.length == 4) {
                vm.invalidExpirationDate = !Moip.Validator.isExpiryDateValid(vm.expirationDate.substring(0, 2), vm.expirationDate.substring(2, 4));
            }
        }

        vm.verifyCardNumber = function () {
            vm.invalidCardNumber = !Moip.Validator.isValid(vm.checkout.cardNumber);
            vm.brandCard = Moip.Validator.cardType(vm.checkout.cardNumber) ? Moip.Validator.cardType(vm.checkout.cardNumber).brand : null;
        }

        vm.lockCreateCampaign = function () {

            if ( !vm.checkout.cpf ) {
                return status = true;
            }

            if ( (!vm.checkedCreditCard && !vm.checkedBoleto) ) {
                return status = true;
            }

            if ( vm.checkedCreditCard && (vm.paymentForm.$invalid || vm.invalidCardNumber) ) {
                return status = true;
            }

            return false;


        }

        vm.finishPurchase = function () {
            vm.isBuying = true;

            // Commons things to do
            vm.checkout.catalogId = vm.facebook.catalog.id;
            vm.checkout.feedId = vm.facebook.feed.id;
            vm.checkout.cityKey = vm.selectedCity.key;
            vm.checkout.businessId = vm.selectedBusiness.id;
            vm.checkout.pageId =  vm.selectedBusinessPage.id;
            vm.checkout.catalogName = vm.catalogName;
            vm.checkout.storeName = $localStorage.defaultStore.storeNickname;
            vm.checkout.campaignValue = vm.project.total;
            vm.checkout.campaignDailyValue = vm.project.value;
            vm.checkout.campaignDays = vm.project.days;
            vm.checkout.token = vm.facebook.data.authResponse.accessToken;
 			var url = window.location.host;
			vm.checkout.storeUrl = url.replace("admin", vm.checkout.storeName);
//            if ((window.location.host.indexOf("showkase") !== -1) === true) {
//                vm.checkout.storeUrl = 'https://' + vm.checkout.storeName + '.showkase.com.br'
//            } else {
//                vm.checkout.storeUrl = 'https://' + vm.checkout.storeName + '.showkase.online'
//            }

            if (vm.checkedCreditCard) {
                vm.checkout.payment = 'CREDIT_CARD';
                // Verify and does all checks
                if (validateExpirationDate()) {
                    var hashIsValid = encryptCreditCardData();
                    if (hashIsValid) {
                        BusinessIntegrationService.createCampaign(vm.checkout)
                            .then(success, error)
                            .catch(function (err) {
                                error(err);
                            });
                    } else {
                        toastr.error($translate.instant('integration.rent.error.cardDataInvalid'));
                        vm.isBuying = false;
                    }
                } else {
                    toastr.error($translate.instant('flyers.rent.error.expirationDateInvalid'));
                    vm.isBuying = false;
                }

            } else if (vm.checkedBoleto) {
                vm.checkout.payment = 'BOLETO';
                BusinessIntegrationService.createCampaign(vm.checkout)
                    .then(success, error)
                    .catch(function (err) {
                        error(err);
                    });
            }

        }

        function success(result) {
            vm.campaingResult = result.data;
            vm.isBuying = false;
            $window.open(vm.campaingResult.redirectHrefBoleto + '/print');
        }

        function error(result) {
            toastr.error($translate.instant('integrations.facebookBusiness.errors.createCampain'));
        }


    }
})();
