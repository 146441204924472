(function () {
    'use strict';

    angular
        .module('showcaseApp')
        .factory('PaymentSetupService', PaymentSetupService);

    PaymentSetupService.$inject = ['PaymentSetupFactory'];

    function PaymentSetupService(PaymentSetupFactory) {

        var service = {
            getByStoreId: getByStoreId,
            activateDeactivate: activateDeactivate,
            getProfessionalOccupations: getProfessionalOccupations,
            disconnect: disconnect
        };

        return service;

        function getByStoreId(storeId) {
            return PaymentSetupFactory.getByStoreId({ storeId: storeId }).$promise;
        }

        function activateDeactivate(id, storeId, dto) {
            return PaymentSetupFactory.activateDeactivate({ id: id, storeId: storeId }, dto).$promise;
        }

        function disconnect(id, storeId, paymentGateway) {
            return PaymentSetupFactory.disconnect({ id: id, storeId: storeId, paymentGateway: paymentGateway }).$promise;
        }

        function getProfessionalOccupations() {
            return [
                'OUTROS',
                'Administrador',
                'Advogado',
                'Agente de Segurança',
                'Agente de Turismo',
                'Agricultor',
                'Analista de Marketing',
                'Analista de Sistemas',
                'Animador',
                'Arquiteto',
                'Artista Plástico',
                'Assistente Social',
                'Ator',
                'Auditor',
                'Biólogo',
                'Bartender',
                'Bibliotecário',
                'Bombeiro',
                'Chef de Cozinha',
                'Cientista de Dados',
                'Cinegrafista',
                'Comissário de Bordo',
                'Consultor',
                'Contador',
                'Coreógrafo',
                'Corretor de Imóveis',
                'Corretor de Seguros',
                'Costureiro',
                'Dentista',
                'Designer',
                'Desenvolvedor de Jogos',
                'Diretor de Cinema',
                'Economista',
                'Editor de Vídeo',
                'Eletricista',
                'Encanador',
                'Enfermeiro',
                'Engenheiro Civil',
                'Engenheiro Elétrico',
                'Engenheiro Mecânico',
                'Engenheiro de Software',
                'Enólogo',
                'Estatístico',
                'Estilista',
                'Farmacêutico',
                'Fisioterapeuta',
                'Físico',
                'Fotógrafo',
                'Garçom',
                'Geógrafo',
                'Geólogo',
                'Gestor de Projetos',
                'Gestor de Recursos Humanos',
                'Historiador',
                'Instrutor de Autoescola',
                'Jornalista',
                'Locutor',
                'Maquiador',
                'Marceneiro',
                'Massoterapeuta',
                'Matemático',
                'Mecânico de Automóveis',
                'Médico',
                'Meteorologista',
                'Militar',
                'Motorista',
                'Motorista de Ônibus',
                'Músico',
                'Nutricionista',
                'Oceanógrafo',
                'Operador de Telemarketing',
                'Padeiro',
                'Pedagogo',
                'Pedreiro',
                'Personal Trainer',
                'Pescador',
                'Piloto de Avião',
                'Policial',
                'Produtor Musical',
                'Programador',
                'Psicólogo',
                'Publicitário',
                'Químico',
                'Radialista',
                'Recepcionista',
                'Serralheiro',
                'Silvicultor',
                'Sociólogo',
                'Sommelier',
                'Tapeceiro',
                'Taxista',
                'Tecnólogo em Gestão Ambiental',
                'Técnico em Eletrônica',
                'Técnico em Enfermagem',
                'Técnico em Informática',
                'Tradutor',
                'Urbanista',
                'Veterinário',
                'Vidraceiro',
                'Zootecnista']
        }
    }
})();
