(function() {
    'use strict';

    angular
        .module('showcaseApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('payment-settings', {
                parent: 'admin',
                url: '/settings/payments',
                data: {
                    authorities: ['STORE_MENU_SETTINGS_PAYMENTS'],
                    pageTitle: "settings.store.payments.title",
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/admin/settings/payments/payment-settings.html',
                        controller: 'PaymentSettingsController as vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('settings');
                        return $translate.refresh();
                    }]
                }
            }).state('shipping-settings', {
                parent: 'admin',
                url: '/settings/shippings',
                data: {
                    authorities: ['STORE_MENU_SETTINGS_SHIPPING'],
                    pageTitle: "settings.shipping.menuTitle",
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/admin/settings/shippings/shipping-settings.html',
                        controller: 'ShippingSettingsController as vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('settings');
                        return $translate.refresh();
                    }]
                }
            }).state('erps-settings', {
                parent: 'admin',
                url: '/settings/erps',
                data: {
                    authorities: ['STORE_MENU_SETTINGS'],
                    pageTitle: "global.menu.admin.erp",
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/admin/settings/erps/erps-settings.html',
                        controller: 'ErpsSettingsController as vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('settings');
                        return $translate.refresh();
                    }]
                }
            })
            .state('marketing-settings', {
                parent: 'admin',
                url: '/settings/marketing',
                data: {
                    authorities: ['SEND_MESSAGE_TO_WATI', 'SEND_MESSAGE_TO_FACEBOOK'],
                    pageTitle: "global.menu.admin.marketing-settings",
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/admin/settings/marketing/marketing-settings.html',
                        controller: 'MarketingSettingsController as vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('settings');
                        return $translate.refresh();
                    }]
                }
            })
            .state('communication-settings', {
                parent: 'admin',
                url: '/settings/communication',
                data: {
                    authorities: ['SEND_MESSAGE_TO_WATI', 'SEND_MESSAGE_TO_FACEBOOK'],
                    pageTitle: "global.menu.admin.communication-settings",
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/admin/settings/communication/communication-settings.html',
                        controller: 'CommunicationSettingsController as vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('settings');
                        return $translate.refresh();
                    }]
                }
            })
            .state('showcase-settings', {
                parent: 'admin',
                url: '/settings/showcase',
                data: {
                    authorities: ['STORE_MENU_SETTINGS_PAYMENTS'],
                    pageTitle: "global.menu.admin.showcase",
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/admin/settings/showcase/showcase.html',
                        controller: 'ShowcaseSettingsController as vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('settings');
                        return $translate.refresh();
                    }]
                }
            });
    }
})();
