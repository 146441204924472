(function () {
    'use strict'

    angular
        .module('showcaseApp')
        .controller('DiscountCouponModalConstroller', DiscountCouponModalConstroller);

    /** @ngInject */
    function DiscountCouponModalConstroller(discountCoupon, currency, $uibModalInstance, $scope, toastr, $translate, moment, FlyerCategoryService, DiscountCouponService, $timeout) {
        var format = 'L';

        var vm = this;

        vm.maskLocale = '99/99/9999';
        vm.currency = currency;
        vm.discountCoupon = discountCoupon || {};
        vm.cancel = cancel;
        vm.init = init;
        vm.save = save;
        vm.usedCoupons = 0;
        const DATE_FORMAT = 'L';
        vm.discountTypes = [
            { name: $translate.instant('global.form.discount-type.percentage'), key: "PERCENTAGE" },
            { name: $translate.instant('global.form.discount-type.fixed-value'), key: "DISCOUNT_VALUE" },
            { name: $translate.instant('global.form.discount-type.free-shipping'), key: "FREE_SHIPPING" }
        ]

        if (vm.discountCoupon.isEnabled == null) {
            vm.discountCoupon.isEnabled = true;
        }

        if (vm.discountCoupon.hasUsageLimit) {
            vm.hasUsageLimit = true;
        } else {
            vm.hasUsageLimit = false;
        }

        vm.currencyConfigurations = getCurrencyListConfiguration();
        vm.configutaionsPrice = vm.currencyConfigurations[vm.currency];
        
        function init() {

            FlyerCategoryService.listCategoriesWithFlyers($translate.use()).then(function (response) {
                vm.categories = response.data;
            });

            if (vm.discountCoupon.startDate || vm.discountCoupon.expirationDate) {
                if (vm.discountCoupon.startDate) {
                    vm.discountCoupon.startDate = moment(vm.discountCoupon.startDate).format(DATE_FORMAT)
                }
                if (vm.discountCoupon.expirationDate) {
                    vm.discountCoupon.expirationDate = moment(vm.discountCoupon.expirationDate).format(DATE_FORMAT);
                }
                $timeout(function () {
                    dateTimePicker();
                }, 300)
            } else {
                dateTimePicker();
            }

            if(!vm.discountCoupon.discountType) {
                vm.discountCoupon.discountType = "PERCENTAGE";
            }

            if (!vm.discountCoupon.categoryKeys) {
                vm.discountCoupon.categoryKeys = [];
            }

            vm.usedCoupons = vm.discountCoupon.maxUsages - vm.discountCoupon.couponsAvailable;
        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }

        vm.closeDropDown = function () {
            $("md-backdrop").trigger('click');
        }

        function save() {
            if (vm.formAddEditDiscountCoupon.$valid) {
                vm.isSaving = true;

                if (vm.discountCoupon.maxUsages < vm.usedCoupons) {
                    vm.isSaving = false;
                    toastr.error($translate.instant('global.messages.error.quantity-used-discount-coupon'));
                    return "";
                }

                vm.discountCoupon.hasUsageLimit = vm.discountCoupon.hasUsageLimit ? vm.discountCoupon.hasUsageLimit : false;
                var discountCouponToSave = angular.copy(vm.discountCoupon);
                discountCouponToSave.expirationDate = discountCouponToSave.expirationDate ? moment.utc(discountCouponToSave.expirationDate, format).toDate() : undefined;
                discountCouponToSave.startDate = discountCouponToSave.startDate ? moment.utc(discountCouponToSave.startDate, format).toDate() : undefined;
                if (vm.discountCoupon.id) {
                    DiscountCouponService.update(discountCouponToSave).then(onSuccess, onError);
                } else {
                    DiscountCouponService.create(discountCouponToSave).then(onSuccess, onError);
                }

                function onSuccess(result) {
                    vm.isSaving = false;
                    $uibModalInstance.close(result);
                }

                function onError(error) {
                    vm.isSaving = false;
                    if (error.data.headers["x-adminapp-params"]) {
                        toastr.error($translate.instant(error.data.headers["x-adminapp-params"].concat(".").concat(error.data.headers["x-adminapp-error"])));
                    } else {
                        toastr.error($translate.instant("global.messages.somethingWrong"));
                    }
                }
            }
        }

        vm.checkDiscountValue = function() {
            vm.formAddEditDiscountCoupon.discount.$setValidity('min', !(vm.discountCoupon && vm.discountCoupon.discount == 0));
        }

        vm.getSelectedTextCategory = function (filter) {
            for (var i = 0; i < vm.categories.length; i++) {
                if (vm.categories[i].key === filter[0]) {
                    if (filter.length == 1) {
                        return '<span class="highlight">' + vm.categories[i].title + '</span>';
                    }
                    if (filter.length > 1) {
                        var temp = '  +';
                        temp += filter.length - 1;
                        temp += ' selecionados';

                        return '<span class="highlight">' + vm.categories[i].title + '</span>' +
                            '<span>&nbsp</span>' +
                            '<span class="highlight">' + temp + '</span>';
                    }
                    return '';
                }
            }
            return '';
        }

        vm.changeDiscountType = function () {
            vm.discountCoupon.discount = null;
        }

        vm.changehasUsageLimit = function () {
            if (!vm.hasUsageLimit) {
                vm.discountCoupon.maxUsages = null;
                vm.discountCoupon.categoryKeys = null;
                vm.discountCoupon.startDate = null;
                vm.discountCoupon.expirationDate = null;
                vm.discountCoupon.usageLimitPerClient = null;
            }
            vm.discountCoupon.hasUsageLimit = vm.hasUsageLimit;
            $timeout(function () {
            }, 500);
        }

        function dateTimePicker() {

            vm.startDateOptions = {
                format: DATE_FORMAT,
                sideBySide: true,
                ignoreReadonly: true,
                locale: moment.locale(),
                allowInputToggle: true
            };

            vm.expireDateOptions = {
                format: DATE_FORMAT,
                sideBySide: true,
                ignoreReadonly: true,
                locale: moment.locale(),
                allowInputToggle: true,
                useCurrent: false
            };

            angular.element('#startDate').datetimepicker(vm.startDateOptions).on('dp.change', function (ev) {
                if (ev.date) {
                    $scope.$apply(function () {
                        vm.discountCoupon.startDate = ev.date.format(format);
                    });
                } else {
                    $scope.$apply(function () {
                        vm.discountCoupon.startDate = undefined;
                    });
                }
            });

            angular.element('#expirationDate').datetimepicker(vm.expireDateOptions).on('dp.change', function (ev) {
                if (ev.date) {
                    vm.discountCoupon.expirationDate = ev.date.format(DATE_FORMAT);
                } else {
                    vm.discountCoupon.expirationDate = undefined;
                }
            });

            if(vm.discountCoupon.startDate) {
                vm.discountCoupon.startDate = discountCoupon.startDate ? discountCoupon.startDate : null;
            }
            if(vm.discountCoupon.startDate) {
                vm.discountCoupon.expirationDate = discountCoupon.expirationDate ? discountCoupon.expirationDate : null;
            }
        }

        vm.validateValueCoupom = function() {
            return false;
        }

        vm.makecoupon = function () {
            var length = 10;
            var result = '';
            var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
            var charactersLength = characters.length;
            for (var i = 0; i < length; i++) {
                result += characters[Math.floor(Math.random() * charactersLength)];
            }
            vm.discountCoupon.code = result;
        }
    }


})();
