(function() {
    'use strict';

    angular
        .module('showcaseApp')
        .controller('ModalAudioController', ModalAudioController);

    ModalAudioController.$inject = ['$scope', '$uibModal', '$uibModalInstance'];

    function ModalAudioController($scope, $uibModal, $uibModalInstance) {
        var vm = this;

        vm.confirm = function() {
            $uibModalInstance.close(true);
        }

        vm.exclude = function() {
            $uibModalInstance.close(false);
        }

        vm.closeModal = function() {
            $uibModalInstance.dismiss();
        }
    }

})();
