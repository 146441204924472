(function () {
    'use strict';
    angular
        .module('showcaseApp')
        .service('GiftCardService', GiftCardService);

    GiftCardService.$inject = ['GiftCardFactory', '$localStorage', 'MoipPlansFactory'];

    function GiftCardService(GiftCardFactory, $localStorage, MoipPlansFactory) {

        var service = {
            cardsAvailable: cardsAvailable,
            sendGiftCard: sendGiftCard,
			validateGiftCard: validateGiftCard,
			updateSubscriptionGiftCard: updateSubscriptionGiftCard
        };

        return service;

        function cardsAvailable(hubId) {
            return GiftCardFactory.cardsAvailable({ hubId: hubId }).$promise;
        }

        function sendGiftCard(giftCardReceiverDTO) {
            return GiftCardFactory.sendGiftCard(giftCardReceiverDTO).$promise;
        }

        function validateGiftCard(token) {
            return GiftCardFactory.validateGiftCard({token: token}).$promise;
        }

        function updateSubscriptionGiftCard(giftCardSubscriptionDTO) {
            return MoipPlansFactory.updateSubscriptionGiftCard(giftCardSubscriptionDTO).$promise;
        }



    }

})();
