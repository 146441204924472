(function () {
    'user strict'

    angular.module('showcaseApp')
        .factory('CategoryFlyerFactory', CategoryFlyerFactory);

    /** ngInject */
    function CategoryFlyerFactory($resource) {

        var resourceUrl = 'flyer/api/category';

        return $resource(resourceUrl, {}, {
            'create': {
                method: 'POST',
                url: 'flyer/api/category/store',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'update': {
                method: 'PUT',
                url: 'flyer/api/category/store',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'deleteCategory': {
                method: 'DELETE',
                url: 'flyer/api/category/:id',
                transformResponse: function (data, headers) {
                    var response = {};
                    response.headers = headers();
                    return response;
                }
            },
            'listAllByCurrentStore': {
                url: 'flyer/api/category/current-store',
                method: 'GET',
                transformResponse: function (data, headers) {
                    var response = {};
                    response.data = angular.fromJson(data);
                    response.headers = headers();
                    return response;
                }
            }          
        });
    }
})();
