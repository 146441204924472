(function () {
    'use strict';

    angular
        .module('showcaseApp')
        .controller('NavigationController', NavigationController);

    function NavigationController($state, Auth, Principal, ProfileService, LoginService, $rootScope, $localStorage, StoreService, $window) {
        var vm = this;

        vm.isNavbarCollapsed = true;
        vm.isAuthenticated = Principal.isAuthenticated;

        ProfileService.getProfileInfo().then(function (response) {
            vm.inProduction = response.inProduction;
            vm.swaggerEnabled = response.swaggerEnabled;
        });

        vm.login = login;
        vm.logout = logout;
        vm.toggleNavbar = toggleNavbar;
        vm.collapseNavbar = collapseNavbar;
        vm.$state = $state;
        vm.settingsAccount = null;
        vm.hubId = $localStorage.defaultStore.hubId;
        vm.isHubShowkase = false;

        setTimeout(function () {
            vm.storeCategory = $localStorage.defaultStore.category;
        }, 1000);

        if (window.location.host.includes('showkase')) {
            vm.isHubShowkase = true;
        }

        Principal.identity().then(function (account) {
            if (account) {
                vm.settingsAccount = angular.copy(account);
                vm.userProfile = vm.settingsAccount.userProfiles[0];
				vm.isMoipPlanStarter = account.isMoipPlanStarter;
				vm.isMoipPlanFree = 	account.isMoipPlanFree;
            }
        });

        vm.disableOverflow = function () {
            event.stopPropagation();
        }

        vm.openMenu = function() {
            if(!angular.element('body').hasClass('body-small')){
                angular.element('body').addClass('fixed-sidebar');
                angular.element('body').removeClass('mini-navbar');
            }

        }
        vm.closeMenu = function() {
            if(!angular.element('body').hasClass('body-small')){
                angular.element('body').removeClass('fixed-sidebar');
                angular.element('body').addClass('mini-navbar');
            }
        }


        vm.enableOverflow = function () {}

        function login() {
            collapseNavbar();
            LoginService.open();
        }

        function logout() {
            collapseNavbar();
            Auth.logout();
            $state.go('main-login');
        }

        function toggleNavbar() {
            vm.isNavbarCollapsed = !vm.isNavbarCollapsed;
        }

        function collapseNavbar() {
            vm.isNavbarCollapsed = true;
        }

        $rootScope.$on('settings-updated', function () {
            Principal.identity().then(function (account) {
                if (account) {
                    vm.settingsAccount = angular.copy(account);
                    vm.userProfile = vm.settingsAccount.userProfiles[0];
                }
            });
        });

        getStoreInfo().then(function(response) {
            $localStorage.defaultStore.storeType = response.data.storeType;
            $localStorage.defaultStore.defaultFilter = response.data.defaultFilter;
            vm.store = response.data;
            setStoreLogo();
        });

        function getStoreInfo() {
            return StoreService.getStore($localStorage.defaultStore.storeId);
        }

        vm.getShowcaseURL = function() {
            if (!$localStorage.defaultStore.storeNickname) {
                getStoreInfo().then(function(response) {
                    $localStorage.defaultStore.storeNickname = response.data.nickname;
                    $localStorage.defaultStore.storeType = response.data.storeType;
                    vm.store = response.data;
                    setStoreLogo();
                    $window.open($window.location.protocol + '//' + $localStorage.defaultStore.storeNickname + '.' + getDomainNameSufix($window, vm.store.storeType), '_blank');
                });
            } else {
                $window.open($window.location.protocol + '//' + $localStorage.defaultStore.storeNickname + '.' + getDomainNameSufix($window, vm.store.storeType), '_blank');
            }

        }

        function setStoreLogo() {
            vm.store.logo = vm.store.logo ? vm.store.logo.replace('%25s', 'logo') : '/content/images/profile_small.png';
            $localStorage.defaultStore.performanceDashboard = vm.store.performanceDashboard;
            $localStorage.defaultStore.chatPerformanceDashboard = vm.store.chatPerformanceDashboard;
            $localStorage.defaultStore.sellerPerformanceDashboard = vm.store.sellerPerformanceDashboard;
            $localStorage.defaultStore.abandonedCartDashboard = vm.store.abandonedCartDashboard;
            $localStorage.defaultStore.crmDashboard = vm.store.crmDashboard;
        }
    }
})();
