(function () {
    'use strict';

    angular
        .module('showcaseApp')
        .controller('OnlineRetailOrderConfigController', OnlineRetailOrderConfigController);

    function OnlineRetailOrderConfigController(config, showkaseUsers, onlineRetailUsers, bankAccounts, $rootScope, $localStorage, $translate, $uibModalInstance, $location, toastr, 
        UserService, StoreService) {

        var vm = this;

        vm.selectedBankAccount = undefined;
        vm.selectedSeller = undefined;
        vm.selectedShowkaseUser = undefined;
        vm.selectedOnlineRetailUser = undefined;
        vm.userLinks = [];
        vm.userLinksView = [];
        vm.showkaseUsers = showkaseUsers ? showkaseUsers : [];
        vm.onlineRetailUsers = onlineRetailUsers ? onlineRetailUsers : [];
        vm.bankAccounts = bankAccounts;
        vm.config = config;


        vm.height = window.innerHeight;
        
        function setDynamicDivHeight() {
            vm.height = window.innerHeight;
        }

        window.addEventListener('load', setDynamicDivHeight);
        window.addEventListener('resize', setDynamicDivHeight);

        StoreService.getStore($localStorage.defaultStore.storeId).then(function (response) {
            vm.store = response.data;
            vm.storeCategory = response.data.categoryKey;
        });

        init();

        function init() {
            console.log(vm.config);
            loadConfig();
            loadUserLinks();
        }

        function loadConfig() {
            if (vm.config) {
                selectBankAcount(vm.config.accountCode);
                selectDefaultSeller(vm.config.sellerCode);
            }
        }

        function selectBankAcount(code) {
            if (code && vm.bankAccounts) {
                var filtered = vm.bankAccounts.filter(function(account) { return account.codigo == code; });
                vm.selectedBankAccount = filtered && filtered.length > 0 ? filtered[0] : undefined;
            }
        }

        function selectDefaultSeller(sellerId) {
            if (sellerId && vm.onlineRetailUsers) {
                var filtered = vm.onlineRetailUsers.filter(function(seller) { return seller.id == sellerId; });
                vm.selectedSeller = filtered && filtered.length > 0 ? filtered[0] : undefined;
            }
        }

        function loadUserLinks() {
            if (vm.config && vm.config.userLinks) {
                console.log(vm.showkaseUsers);
                console.log(vm.onlineRetailUsers);
                vm.userLinks = vm.config.userLinks.map(function(link) {
                    var shkUser = getUserById(link.userId, vm.showkaseUsers);
                    var orUser = getUserById(link.erpUserId, vm.onlineRetailUsers);
                    if (shkUser && orUser) {
                        return {
                            showkaseId: shkUser.id,
                            showkaseName: shkUser.fullName,
                            onlineRetailId: orUser.id,
                            onlineRetailName: orUser.nome
                        };
                    }

                    return null;
                }).filter(function(item) { return item !== null });
                vm.userLinksView = getUserLinksView();
                console.log(vm.userLinksView);
            }
        }

        function getUserLinksView() {
            return vm.userLinks.map(function(link) {
                var shkUser = getUserById(link.showkaseId, vm.showkaseUsers);
                var orUser = getUserById(link.onlineRetailId, vm.onlineRetailUsers);
                if (shkUser && orUser) {
                    return {
                        showkaseId: shkUser.id,
                        showkaseName: shkUser.fullName,
                        onlineRetailId: orUser.id,
                        onlineRetailName: orUser.nome
                    };
                }

                return null;
            }).filter(function(item) { return item !== null });
        }

        
        vm.cancel = function () {
			$uibModalInstance.dismiss('cancel');
		}

        vm.linkUsers = function() {
            if (!vm.selectedShowkaseUser || !vm.selectedOnlineRetailUser) {
                toastr.error($translate.instant('settings.erps.online-retail.modal.order.message.error-link-users'));
                return;
            }

            if (containsShowkaseUser(vm.selectedShowkaseUser.id)) {
                toastr.error($translate.instant('settings.erps.online-retail.modal.order.message.error-link-user-in-use'));
                return;
            }

            var link = {
                showkaseId: vm.selectedShowkaseUser.id,
                showkaseName: vm.selectedShowkaseUser.fullName,
                onlineRetailId: vm.selectedOnlineRetailUser.id,
                onlineRetailName: vm.selectedOnlineRetailUser.nome
            }
            vm.userLinks.push(link);
            vm.selectedShowkaseUser = undefined;
            vm.selectedOnlineRetailUser = undefined;
            vm.userLinksView = getUserLinksView();
        }

        vm.deleteLink = function(link) {
            if (link && vm.userLinks && vm.userLinks.length > 0) {
                var index = vm.userLinks.map(function(item) { return item.showkaseId; }).indexOf(link.showkaseId);
                vm.userLinks.splice(index, 1);
                vm.userLinksView = getUserLinksView();
            }
        }

        function containsShowkaseUser(showkaseId) {
            if (showkaseId && vm.userLinks && vm.userLinks.length > 0) {
                var index = vm.userLinks.map(function(item) { return item.showkaseId; }).indexOf(showkaseId);
                return index >= 0;
            }

            return false;
        }

        function getUserById(id, entityList) {
            if (entityList) {
                var filtered = entityList.filter(function(item) { return item.id === id });
                return filtered && filtered.length > 0 ? filtered[0] : undefined;
            }
            
            return undefined;
        }

        function userLinkMapper() {
            return vm.userLinks.map(function(link) {
                return {
                    userId: link.showkaseId,
                    erpUserId: link.onlineRetailId,
                    isEnabled: true
                };  
            });
        }

        vm.save = function () {
            if (!vm.selectedBankAccount || !vm.selectedBankAccount.codigo) {
                toastr.error($translate.instant('settings.erps.online-retail.modal.order.message.error-bank-account-required'));
                return;
            }

            if (!vm.selectedSeller || !vm.selectedSeller.id) {
                toastr.error($translate.instant('settings.erps.online-retail.modal.order.message.error-default-seller-required'));
                return;
            }
            
            vm.config.userLinksView = getUserLinksView(); 
            vm.config.userLinks = userLinkMapper();            
            vm.config.accountCode = vm.selectedBankAccount.codigo;
            vm.config.sellerCode = vm.selectedSeller.id;
            vm.config.selectedSeller = vm.selectedSeller;
            vm.config.selectedBankAccount = vm.selectedBankAccount;

            console.log(vm.config);

            $uibModalInstance.close({ data: vm.config });
        }
    }

})();
