(function () {
    'use strict';

    angular
        .module('showcaseApp')
        .factory('DeleteTemplateModalFactory', DeleteTemplateModalFactory);

    /** @ngInject */
    function DeleteTemplateModalFactory($uibModal) {

        var factory = {
            deleteTemplate: deleteTemplate
        }

        return factory;

        function deleteTemplate(id) {
            return showDeleteModal(id);
        }

        function showDeleteModal(template) {
            return $uibModal.open({
                templateUrl: 'app/admin/communication/template-message/_modal_delete_template_message.html',
                size: 'md',
                windowClass: 'modal-center delete-template-modal',
                windowTopClass: 'modal-dialog-center',
                resolve: {
                    template: function () {
                        return template;
                    }
                },
                controller: function ($uibModalInstance, template) {
                    var vm = this;
                    vm.template = template;
                    vm.cancel = cancel;
                    vm.ok = ok;

                    function cancel() {
                        $uibModalInstance.dismiss('cancel');
                    }

                    function ok() {
                        $uibModalInstance.close(true);
                    }
                },
                controllerAs: "vm"
            }).result;
        }
    }
})();
