(function() {
    'use strict';

    angular
        .module('showcaseApp')
        .controller('MessageModalController', MessageModalController);

    function MessageModalController($uibModalInstance, modalTitle, modalMessage, 
                                    modalSubMessage, buttonMessage, withHeader, withFooter,
                                    buttonBodyMessage, buttonOk, buttonAction, centralIcon, interactiveTranslate, 
                                    functionTranslate, interactiveFunction, imageStyle, imageCentralIcon, fontSizeMessage, closeAfterAction, $rootScope) {
        var vm = this;

        vm.confirm = function() {
            $uibModalInstance.close(true);
        }

        vm.closeModal = function() {
            $uibModalInstance.dismiss();
        }

        vm.functionWaiter = true;

        function callback() {
            vm.functionWaiter = false;
        }

        $rootScope.$on('waiting-generates-tabloid', function(ev, next, current) {
            vm.functionWaiter = true;
        });

        $rootScope.$on('finish-generate-tabloid', function(ev, next, current) {
            vm.functionWaiter = false;
        });

        $rootScope.$on('waiting-generate-poster', function(ev, next, current) {
            vm.functionWaiter = true;
        });

        $rootScope.$on('finish-generate-poster', function(ev, next, current) {
            vm.functionWaiter = false;
        });

        vm.modalTitle = modalTitle;

        vm.fontSizeMessage = fontSizeMessage;
        
        vm.buttonMessage = buttonMessage;
        vm.buttonBodyMessage = buttonBodyMessage;
        vm.buttonOk = buttonOk;

        vm.modalMessage = modalMessage;
        vm.modalSubMessage = modalSubMessage;

        vm.withFooter = withFooter;
        vm.withHeader = withHeader;

        vm.doAction = buttonAction == 'close' ? vm.closeModal : function () {
            buttonAction();
            if (closeAfterAction) 
                vm.closeModal();
        };

        vm.centralIcon = centralIcon;

        vm.interactiveTranslate = interactiveTranslate;

        vm.functionTranslate = functionTranslate;

        vm.imageCentralIcon = imageCentralIcon;

        vm.imageStyle = imageStyle;

        vm.interactiveFunction = interactiveFunction ? function(){
            vm.functionWaiter = true;
            interactiveFunction(callback);
        } : undefined;

    }

})();
