(function() {
    'use strict';
    angular
        .module('showcaseApp')
        .service('SettingsService', SettingsService);

    SettingsService.$inject = ['SettingsFactory'];

    function SettingsService(SettingsFactory) {

        var service = {
            getRegisterKey: getRegisterKey,
            registerToReceiveNotifications: registerToReceiveNotifications,
            unregisterToReceiveNotifications: unregisterToReceiveNotifications,
            updateSystemNotifications: updateSystemNotifications,
            updateEmailNotifications: updateEmailNotifications,
            getRegister: getRegister
        };

        return service;

        function getRegisterKey(id, endpoint) {
            return SettingsFactory.getKey({'accountId': id, 'registerEndpoint': endpoint}).$promise;
        }

        function getRegister(accountId, storeId) {
            return SettingsFactory.getRegister({'accountId': accountId, 'storeId': storeId}).$promise;
        }

        function registerToReceiveNotifications(endpoint, auth, p256dh, accountId, storeId) {
            return SettingsFactory.registerToReceiveNotifications({}, {'endpoint': endpoint, 'auth': auth,
                'p256dh':p256dh, 'accountId':accountId, 'storeId':storeId}).$promise;
        }

        function updateSystemNotifications(id, value) {
            return SettingsFactory.updateSystemNotifications({'accountId': id}, value).$promise;
        }

        function updateEmailNotifications(id, value) {
            return SettingsFactory.updateEmailNotifications({'accountId': id}, value).$promise;
        }

        function unregisterToReceiveNotifications(id) {
            return SettingsFactory.unregisterToReceiveNotifications({'accountId': id}).$promise;
        }
    }
})();
