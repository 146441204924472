(function() {
    'use strict';
    angular
        .module('showcaseApp')
        .service('ForwardMessageService', ForwardMessageService);

    ForwardMessageService.$inject = ['ForwardMessageFactory'];

    function ForwardMessageService(ForwardMessageFactory) {

        var service = {
            sendMessages: sendMessages
        };

        return service;

        function sendMessages(filters){
            return ForwardMessageFactory.sendMessages(filters).$promise;
        }
    }
})();
