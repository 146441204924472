(function () {
    'use strict';
    angular
        .module('showcaseApp')
        .service('BusinessIntegrationService', BusinessIntegrationService);

    BusinessIntegrationService.$inject = ['BusinessIntegrationFactory'];

    function BusinessIntegrationService(BusinessIntegrationFactory) {

        var service = {
            getOwnerKeys: getOwnerKeys,
            createCampaign: createCampaign,
            getFbCampaingns: getFbCampaingns,
            getConfiguration: getConfiguration,
            createWhatsappJob: createWhatsappJob,
            resumeWhatsappJob: resumeWhatsappJob,
            existsWhatsappJob: existsWhatsappJob,
            clearWhatsappData: clearWhatsappData,
            getWhatsappResumes: getWhatsappResumes,
            disconnectWhatsapp: disconnectWhatsapp,
            facebookIsConnected: facebookIsConnected,
            initiateWhatsappSync: initiateWhatsappSync,
            getIgInsights: getIgInsights,
            getFbInsights: getFbInsights,
            getFbCampaignInsights: getFbCampaignInsights,
        };

        return service;

        function getOwnerKeys(storeId) {
            return BusinessIntegrationFactory.getOwnerKeys({
                storeId: storeId
            }).$promise;
        }
        function createCampaign(campaign) {
            return BusinessIntegrationFactory.createCampaign(campaign).$promise;
        }

        function getConfiguration(storeId, type, callback, errorCallback) {
            BusinessIntegrationFactory.getConfiguration({storeId: storeId, type: type, timestamp: new Date().getTime()}).$promise.then(callback, errorCallback);
        }

        function disconnectWhatsapp(storeId, callback, errorCallback){
            BusinessIntegrationFactory.disconnectByType({storeId: storeId, type: 'WHATSAPP'}, {}).$promise.then(callback, errorCallback);
        }

        function initiateWhatsappSync(storeId, mode, callback, errorCallback) {
            BusinessIntegrationFactory.initiateWhatsappSync({storeId: storeId, operation: mode}).$promise.then(callback, errorCallback);
        }

        function createWhatsappJob(whatsappDTO, callback, errorCallback) {
            BusinessIntegrationFactory.createWhatsappJob(whatsappDTO).$promise.then(callback, errorCallback);
        }

        function clearWhatsappData(storeId, callback, errorCallback) {
            BusinessIntegrationFactory.clearWhatsappData({storeId: storeId}).$promise.then(callback, errorCallback);
        }

        function getWhatsappResumes(storeId, pagination, filter, callback, errorCallback){
            BusinessIntegrationFactory.getWhatsappResumes({storeId: storeId, timestamp: new Date().getTime(), page: pagination.page, size: pagination.size}, filter).$promise.then(callback, errorCallback);
        }

        function getFbCampaingns(pagination, filter) {
            return BusinessIntegrationFactory.getFbCampaingns({timestamp: new Date().getTime(), page: pagination.page, size: pagination.size, filter: filter}).$promise;
        }

        function resumeWhatsappJob(token){
            return BusinessIntegrationFactory.resumeWhatsAppJob({token: token}).$promise;
        }

        function facebookIsConnected(){
            return BusinessIntegrationFactory.facebookIsConnected().$promise;
        }

        function existsWhatsappJob(storeId){
            return BusinessIntegrationFactory.existsWhatsappJob({storeId: storeId}).$promise;
        }

        function getIgInsights(startDate, endDate){
            return BusinessIntegrationFactory.getIgInsights({start: startDate, end: endDate}).$promise;
        }

        function getFbInsights(startDate, endDate){
            return BusinessIntegrationFactory.getFbInsights({start: startDate, end: endDate}).$promise;
        }

        function getFbCampaignInsights(campaignId){
            return BusinessIntegrationFactory.getFbCampaignInsights({campaignId: campaignId}).$promise;
        }

    }

})();
