(function() {
    'use strict';
    angular
        .module('showcaseApp')
        .controller(
            'WhatsappResumeQrCodeController',
            WhatsappResumeQrCodeController
        );

    function WhatsappResumeQrCodeController(
        $uibModalInstance,
        resume,
        $translate,
        BusinessIntegrationService,
        $localStorage,
        $scope,
        $rootScope
    ) {
        var WHATSAPP_FETCH_INTERVAL = 3000;
        var vm = this;
        vm.resume = resume;
        vm.whatsappConf = null;
        vm.isWhatsappTabLoading = false;
        vm.isSending = false;

        function whatsappGetConfigurationCallback(response) {
            vm.whatsappModalConf = response.data;
            if (vm.whatsappModalConf && vm.whatsappModalConf.whatsappQRCode) {
                vm.isLoading = false;
                vm.isRefreshing = false;
            }
            if (
                vm.whatsappModalConf &&
                vm.whatsappModalConf.whatsappConnected
            ) {
                clearInterval($rootScope.fetchQrCodeInterval);
                $uibModalInstance.close('connected');
            } else if (
                vm.whatsappModalConf &&
                vm.whatsappModalConf.whatsappWaitTooLong
            ) {
                $uibModalInstance.close('expired');
                clearInterval($rootScope.fetchQrCodeInterval);
            }

            if (!$scope.$$phase) {
                $scope.$apply();
            }
        }

        function loadModal() {
            vm.isLoading = true;
            initiateLoginMode();

            if (!$scope.$$phase) {
                $scope.$apply();
            }
        }
        loadModal();

        function initiateLoginMode() {
            vm.isLoading = true;
            if (
                !vm.whatsappModalConf ||
                !vm.whatsappModalConf.whatsappConnected
            ) {
                BusinessIntegrationService.initiateWhatsappSync(
                    $localStorage.defaultStore
                        ? $localStorage.defaultStore.storeId || ''
                        : '',
                    'LOGIN_MODE',
                    function() {
                        fetchQrCode();
                        $rootScope.fetchQrCodeInterval = setInterval(
                            fetchQrCode,
                            WHATSAPP_FETCH_INTERVAL
                        );
                        if (!$scope.$$phase) {
                            $scope.$apply();
                        }
                    },
                    function() {
                        toastr.error(
                            $translate.instant(
                                'integrations.whatsapp.messages.errorInitiateWhatsappLoginMode'
                            )
                        );
                        vm.isRefreshing = false;
                        vm.isLoading = false;
                        if (!$scope.$$phase) {
                            $scope.$apply();
                        }
                    }
                );
            }
        }

        function fetchQrCode() {
            // Geeting initial configuration
            BusinessIntegrationService.getConfiguration(
                $localStorage.defaultStore
                    ? $localStorage.defaultStore.storeId || ''
                    : '',
                'WHATSAPP',
                whatsappGetConfigurationCallback,
                whatsappGetConfigurationErrorCallback
            );
        }

        function refreshWhatsappQrCode() {
            vm.isRefreshing = true;
            vm.isLoading = true;
            clearInterval($rootScope.fetchQrCodeInterval);
            BusinessIntegrationService.clearWhatsappData(
                $localStorage.defaultStore
                    ? $localStorage.defaultStore.storeId || ''
                    : '',
                function() {
                    initiateLoginMode();
                },
                function() {
                    initiateLoginMode();
                }
            );
        }

        function whatsappGetConfigurationErrorCallback() {
            toastr.error(
                $translate.instant(
                    'integrations.whatsapp.messages.errorGetConfiguration'
                )
            );
            clearInterval($rootScope.fetchQrCodeInterval);
            vm.isLoading = false;
        }
    }
})();
