(function () {
    'use strict';

    angular.module('showcaseApp')
        .config(function (ChartJsProvider) {
            var mainColor = getComputedStyle(document.documentElement).getPropertyValue('--main-color');
            ChartJsProvider.setOptions('Bar', {
                colors: [mainColor]
            });
        });
})();
