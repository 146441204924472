(function () {
    'use strict';

    angular
        .module('showcaseApp')
        .controller('HubShowcasesController', HubShowcasesController);


    /** @ngInject */
    function HubShowcasesController($window, $state, RemoveModalFactory, $localStorage, $translate, StoreService,
                                 HubShowcasesService, toastr) {

        var vm = this;

        vm.listFilter = {
            ownerId: $localStorage.hub.id,
            name: ''
        };

        vm.hasntStoreYet = !$localStorage.hub.id;

        vm.isPublishing = false;

        vm.showcases = [];
        vm.showcasesToRemove = [];
        vm.userHasShowcases = false;

        vm.store = $localStorage.defaultStore;

        vm.loadShowcases = function() {
            if (vm.listFilter.ownerId) {
                vm.isLoading = true;
                HubShowcasesService.listShowcases(vm.listFilter)
                    .then(function (result) {
                        vm.showcases = result.data;
                        vm.isLoading = false;
                        vm.userHasShowcases = vm.showcases.length >= 1
                    }, function () {
                        vm.isLoading = false;
                    });
            } else {
                vm.hasntStoreYet = true;
                vm.isLoading = false;
            }
        }

        vm.loadShowcases();

        vm.getEditPage = function (showcaseId) {
            $state.go('hub-showcases.edit', {
                showcaseId: showcaseId
            });
        }

        vm.updateShowcasesToRemove = function (showcaseId) {
            if (vm.showcasesToRemove.indexOf(showcaseId) !== -1) {
                vm.showcasesToRemove.splice(vm.showcasesToRemove.indexOf(showcaseId), 1);
            } else {
                vm.showcasesToRemove.push(showcaseId);
            }
        }

        vm.checkIfShowcaseSelected = function (showcaseId) {
            return vm.showcasesToRemove.indexOf(showcaseId) > -1;
        }

        vm.removeShowcases = function () {
            if (vm.showcasesToRemove.length >= 1) {
                RemoveModalFactory.deleteItem(vm.showcasesToRemove.length).then(function (data) {
                    HubShowcasesService.deleteShowcases(vm.showcasesToRemove).then(function (data) {
                        toastr.success($translate.instant('showcases.messages.remove.success'));
                        vm.showcasesToRemove = [];
                        vm.listFilter.name = '';
                        vm.loadShowcases();
                    }, function () {
                        toastr.error($translate.instant('showcases.messages.remove.error'));
                    });
                });
            } else {
                toastr.error($translate.instant('showcases.messages.remove.noItens'));
            }
        }

        vm.getImageSrc = function (showcaseId) {
            return "content/images/templates/" + showcaseId + ".png"
        }

    }

})();
