(function() {
    'use strict';

    angular
        .module('showcaseApp')
        .controller('WarningMessageModalController', WarningMessageModalController);

    function WarningMessageModalController($uibModalInstance, modalTitle, modalMessage, smallText, warningText,
                                          buttonConfirmText, buttonCancelText, buttonConfirmAction, buttonCancelAction) {
        var vm = this;

        vm.modalTitle = modalTitle;
        vm.modalMessage = modalMessage;
        vm.smallText = smallText;
        vm.warningText = warningText;
        vm.buttonConfirmText = buttonConfirmText;
        vm.buttonCancelText = buttonCancelText;
        vm.buttonConfirmAction = action;

        vm.confirm = function() {
            $uibModalInstance.close(true);
        }

        vm.closeModal = function() {
            if (buttonCancelAction) {
                buttonCancelAction();
            }
            $uibModalInstance.dismiss(true);
        }

        function action(){
            buttonConfirmAction();
            vm.confirm();
        }
    }
})();
