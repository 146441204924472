(function () {
    'use strict';
    angular
        .module('showcaseApp')
        .service('StoreRatingService', StoreRatingService);

    function StoreRatingService(StoreRatingFactory) {

        var service = {
            getUserXP: getUserXP,
            getStoreQualify: getStoreQualify,
            getStaticStoreData: getStaticStoreData
        };

        return service;

        function getUserXP() {
            return StoreRatingFactory.getUserXP().$promise;
        }

        function getStoreQualify() {
            return StoreRatingFactory.getStoreQualify().$promise;
        }

        function getStaticStoreData() {
            return StoreRatingFactory.getStaticStoreData().$promise;
        }
    }


})();
