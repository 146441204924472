(function () {
    'use strict';

    angular
        .module('showcaseApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {

        var resolve = {
            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                $translatePartialLoader.addPart('invoice');
                $translatePartialLoader.addPart('global');
                $translatePartialLoader.addPart('customers');
                return $translate.refresh();
            }]
        }

        $stateProvider
            .state('invoices', {
                parent: 'admin',
                url: '/invoices',
                data: {
                    authorities: ['STORE_READ_FLYER'],
                    pageTitle: 'invoice.home.title'
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/admin/invoices/invoices.html',
                        controller: 'InvoicesController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    type: null
                },
                resolve: resolve
            })
            .state('invoices.new', {
                url: '/new',
                data: {
                    authorities: ['STORE_READ_FLYER'],
                    pageTitle: 'invoice.new.title'
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/admin/invoices/invoice-new.html',
                        controller: 'InvoiceNewController',
                        controllerAs: 'vm'
                    }
                },
                resolve: resolve
            })



    }
})();
