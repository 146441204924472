(function() {
    'use strict';
    angular
        .module('showcaseApp')
        .factory('ForwardMessageFactory', ForwardMessageFactory);

    ForwardMessageFactory.$inject = ['$resource'];

    function ForwardMessageFactory($resource) {
        var resourceForwardMessage =  'admin/api/wati/forward-message';
        var resourceSendMessages = resourceForwardMessage + '/send';

        return $resource(resourceForwardMessage, {}, {
            'sendMessages': {
                method: 'POST',
                url: resourceSendMessages,
                isArray: false,
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data){
                        response = angular.fromJson(data);
                    }
                    return response;
                }
            }
        });
    }

})();
