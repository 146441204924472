(function() {
    'use strict';
    angular
        .module('showcaseApp')
        .factory('TemplateMessageFactory', TemplateMessageFactory);

        TemplateMessageFactory.$inject = ['$resource'];

    function TemplateMessageFactory($resource) {
        var resourceTemplateMessage =  '/admin/api/template-message';
        var resourceTemplateMessageById =  resourceTemplateMessage + '/:id';

        return $resource(resourceTemplateMessage, {}, {
            'create': {
                method: 'POST',
                url: resourceTemplateMessage
            },
            'update': {
                method: 'PUT',
                url: resourceTemplateMessageById
            }, 
            'getAll': {
                method: 'GET',
                url: resourceTemplateMessage,
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data){
                        response.data = angular.fromJson(data);
                    } else{
                        response.data = data;
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'deleteTemplate': {
                method: 'DELETE',
                url: resourceTemplateMessageById,
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            }
        });
    }

})();
