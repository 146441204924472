/* globals $ */
(function() {
    'use strict';
    angular
        .module('showcaseApp')
        .component('quotedMessageContainer', {
            bindings: {
                replySourceMessage: '<',
                messageOwnerName: '<'
            },
            controller: QuotedMessageContainerController,
            templateUrl: 'app/admin/communication/directives/quoted-message-container/quoted-message-container.html',
            controllerAs: 'vm'
        });

    QuotedMessageContainerController.$inject = ['$scope', '$rootScope', '$translate', '$uibModal', 'WatiService', '$window'];

    function QuotedMessageContainerController($scope, $rootScope, $translate, $uibModal, WatiService, $window) {
        var vm = this;

        vm.downloadDocument = function (message) {
            if (message.downloadedData) {
                $window.open(message.downloadedData);
            } else {
                message.loadingDownload = true;
                if (!message.data.includes('/data')) message.data = '/' + message.data;
                WatiService.findFileByName({ fileName: message.data }).then(function (result) {
                    var blob = new Blob([result.data], { type: result.data.type });
                    var url = $window.URL.createObjectURL(blob);
                    message.downloadedData = url;
                    message.loadingDownload = false;
                });
            }
        }
    }
})();
