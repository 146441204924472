(function () {
    'use strict';

    angular.module('showcaseApp')
        .controller('InstagramLoginModalController', InstagramLoginModalController);

    function InstagramLoginModalController($uibModalInstance, SocialMediaService) {
        var vm = this;

        var vm = this;
        vm.login = login;
        vm.cancel = cancel;

        vm.isAuthenticating
        vm.authenticationError = false;
        vm.confirmAccessRequired = false;
        vm.usernameOrEmail = null;
        vm.password = null;

        function login(event) {
            vm.isAuthenticating = true;
            vm.authenticationError = false;
            vm.confirmAccessRequired = false
            var credentials = {
                username: vm.usernameOrEmail,
                password: vm.password
            };
            event.preventDefault();
            SocialMediaService.connectWithInstagram(credentials).then(function (data) {
                vm.isAuthenticating = false;
                $uibModalInstance.close(data);
            }).catch(function (error) {
                vm.isAuthenticating = false;
                var errorString = JSON.stringify(error);
                if (error.status === -1 || error.status === 500) {
                    $uibModalInstance.close();
                } else if (errorString.includes('challenge')) {
                    vm.confirmAccessRequired = true;
                } else {
                    vm.authenticationError = true;
                }
                
            });
        }

        function cancel() {
            vm.authenticationError = false;
            $uibModalInstance.dismiss('cancel');
        }

    }
})();