(function() {
    'use strict';

    angular
        .module('showcaseApp')
        .controller('StoreManagementController', StoreManagementController);

    function StoreManagementController($localStorage, Auth, $q, $translate, $rootScope, $state,
                                       StoreService, CategoryService, FlyersService, toastr,
                                       RemoveModalFactory, paginationConstants, $timeout, $scope) {
        var vm = this;
        vm.searchFilter = {};
        vm.displayTypeList = ["Cards", "Table"];
        vm.displayTypeListCheck = [];
        vm.selectedStoreList = [];
        vm.storesDataFiltered = [];
        vm.storesData = [];
        vm.categories = [];
        vm.storesToRemove = 0;
        vm.loadingArray = ["1", "2", "3", "4"];
        vm.isLoading = true;
        vm.changeSession = changeSession;

        vm.page = 0;
        vm.itemsPerPage = paginationConstants.itemsPerPage - 30;
        vm.hasNextPage = false;
        vm.busy = false;
        loadAll();

        vm.checkType = function(type) {
            vm.createArray("displayType", vm.displayTypeList.length, false);
            vm.displayTypeListCheck[vm.displayTypeList.indexOf(type)] = true;
            vm.displayType = type;
        }

        vm.checkDisplayType = function(type) {
            return (vm.displayType === type);
        }

        vm.isEven = function(number) {
            return (number % 2 == 0);
        };

        vm.changeType = function() {
            vm.displayType = vm.displayType === 'Cards' ? 'Table' : 'Cards';
        };


        vm.checkStoresToRemove = function() {
            vm.storesToRemove = 0;
            vm.storesDataFiltered.forEach(function(store) {
                if (store.checked) {
                    vm.storesToRemove++;
                }
            });
        }

        vm.removeStores = function() {
            if (vm.storesToRemove >= 1) {
                RemoveModalFactory.deleteItem(vm.storesToRemove).then(function(data) {
                    var storeIdList = [];
                    for (var i = 0; i < vm.storesDataFiltered.length; i++) {
                        if (vm.storesDataFiltered[i].checked) {
                            storeIdList.push(vm.storesDataFiltered[i].id);
                        }
                    }
                    $q.all(storeIdList).then(function(data) {
                        StoreService.deleteStores(data).then(function(data) {
                                if (($localStorage.defaultStore.storeId != data.newStoreIdDefault) && (data.newStoreIdDefault != null)) {
                                    Auth.changeSession(data.newStoreIdDefault).then(
                                        function() {
                                            $state.transitionTo('home', {}, {
                                                reload: true,
                                                inherit: false,
                                                notify: true
                                            });
                                        });
                                } else if (data.newStoreIdDefault == null) {
                                    $localStorage.defaultStore.storeId = data.newStoreIdDefault;
                                    toastr.info($translate.instant('store-management.messages.remove.default'));
                                    $state.go('store-info');
                                } else {
                                    $rootScope.$broadcast('storeChangeSuccess');
                                    toastr.success($translate.instant('store-management.messages.remove.success'));
                                    vm.storesToRemove = 0;
                                    vm.storesData = [];
                                    loadStores();
                                }
                            },
                            function() {
                                toastr.error($translate.instant('store-management.messages.remove.error'));
                            });
                    });
                });
            } else {
                toastr.error($translate.instant('store-management.messages.remove.noItens'));
            }
        }

        vm.stopPropagation = function(event) {
            event.stopPropagation();
        };

        vm.createArray = function(type, number, value, array) {
            if (type === "displayType") {
                vm.displayTypeListCheck = [];
                for (var i = 0; i < number; i++) {
                    vm.displayTypeListCheck.push(value);
                }
            }
        }

        vm.reloadStores = function(page, reset) {
            if(reset) {
                vm.page = 0;
                vm.storesData = [];
            }else {
                vm.page = page;
            }
            loadStores();
        }

        vm.reset = function reset() {
            vm.page = 0;
            vm.flyersFiltered = [];
        };

        vm.storeDetailsPage = function(storeId) {
            $state.go('store-management-detail', {
                id: storeId
            });
        }

        vm.checkStoreClickAction = function(storeId) {
            if ($localStorage.defaultStore.storeId == 0) {
                vm.storeDetailsPage(storeId);
            } else {
                vm.changeSession(storeId)
            }
        }

        vm.loadStoreImage = function(store) {
            store.image = undefined;
            $timeout(function() {
                vm.getImageFromUrl(store);
            }, 1000);
        }

        vm.getImageFromUrl = function (store) {
            var xhr = new XMLHttpRequest();

            xhr.onreadystatechange = function (oEvent) {
                if (xhr.readyState === 4) {
                    if (xhr.status !== 200) {
                        store.image = "content/images/defaultShowcaseImage.png";
                        $scope.$apply();
                    } else if (xhr.status === 200) {
                        var random = new Date().getTime();
                        store.image = (store.storeLogo + '?r=' + random);
                    }
                }
            }

            xhr.open('GET', store.storeLogo);
            xhr.responseType = 'blob';

            try {
                xhr.send()
            } catch (error) {
                console.log(error)
            }
        }

        function changeSession(storeId) {
            Auth.changeSession(storeId).then(
                function() {
                    $state.transitionTo('home', {}, {
                        reload: true,
                        inherit: false,
                        notify: true
                    });

                    // previousState was set in the authExpiredInterceptor before being redirected to login modal.
                    // since login is successful, go to stored previousState and clear previousState
                    if (Auth.getPreviousState()) {
                        var previousState = Auth.getPreviousState();
                        Auth.resetPreviousState();
                        $state.go(previousState.name, previousState.params);
                    }
                });
        }

        function loadAll() {
            loadStores();
            loadCategories();
        }

        function loadCategories() {
            CategoryService.listCategories($translate.use()).then(function(response) {
                vm.categories = response.data;
            });
        }

        function loadCategoriesName(data) {
            data.forEach(function(store) {
                CategoryService.getCategoryTitleByKey(store.categoryKey, $translate.use()).then(function(response) {
                    store.categoryName = response.data;
                });
            });
        }

        function loadNumberOfFlyers(data) {
            data.forEach(function(store) {
                FlyersService.getNumberOfFlyers(store.id, $translate.use()).then(function(response) {
                    store.numberOfFlyers = response.data;
                });
            });
        }


        function loadStores() {
            if(vm.busy) return;
            vm.busy = true;

            var pageable = {
                page: vm.page,
                size: vm.itemsPerPage
            };
            StoreService.listStores(vm.searchFilter, pageable, onSuccess, onError);

            function onSuccess(data, headers) {
                vm.hasNextPage = headers('X-Has-Next-Page') === "true";
                var newStores = [];
                for (var i = 0; i < data.length; i++) {
                    vm.loadStoreImage(data[i]);
                    vm.storesData.push(data[i]);
                    newStores.push(data[i]);
                }
                vm.storesDataFiltered = vm.storesData;
                $q.all(newStores).then(function() {
                    vm.displayType = vm.storesDataFiltered.length > 1 ? 'Cards' : 'Table';
                    loadCategoriesName(newStores);
                    loadNumberOfFlyers(newStores);
                    vm.isLoading = false;
                });

                vm.busy = false;
            }

            function onError() {
                vm.busy = false;
            }
        }
    }
})();
