(function() {
    'use strict';

    var jhiAlertError = {
        template: '<div class="alerts" ng-cloak="" role="alert">' +
            '<div ng-repeat="alert in $ctrl.alerts" ng-class="[alert.position, {\'toast\': alert.toast}]">' +
            '<uib-alert ng-cloak="" type="{{alert.type}}" close="alert.close($ctrl.alerts)"><pre>{{ alert.msg }}</pre></uib-alert>' +
            '</div>' +
            '</div>',
        controller: jhiAlertErrorController
    };

    angular
        .module('showcaseApp')
        .component('kscError', jhiAlertError);

    jhiAlertErrorController.$inject = ['$scope', '$rootScope', '$translate', 'toastr'];

    function jhiAlertErrorController($scope, $rootScope, $translate, toastr) {
        var vm = this;

        var cleanHttpErrorListener = $rootScope.$on('showcaseApp.httpError', function(event, httpResponse) {
            var i;
            event.stopPropagation();
            var headers = Object.keys(httpResponse.headers()).filter(function(header) {
                return header.indexOf('app-error', header.length - 'app-error'.length) !== -1 || header.indexOf('app-params', header.length - 'app-params'.length) !== -1;
            }).sort();
            var statusKey = httpResponse.headers(headers[1]).statuskey;
            toastr.error($translate.instant('error.' + statusKey));
        });

        $scope.$on('$destroy', function() {
            if (angular.isDefined(cleanHttpErrorListener) && cleanHttpErrorListener !== null) {
                cleanHttpErrorListener();
                vm.alerts = [];
            }
        });
    }
})();
