(function () {
    'use strict';
    angular
        .module('showcaseApp')
        .factory('HubShowcasesFactory', HubShowcasesFactory);

    HubShowcasesFactory.$inject = ['$resource'];

    function HubShowcasesFactory($resource) {
        var resourceUrl = 'flyer/api/hub-showcase/:id';

        return $resource(resourceUrl, {}, {
            'get': {},
            'listAll': {
                method: 'POST',
                url: 'flyer/api/hub-showcase/list',
                transformResponse: transformResponse
            },
            'update': {
                method: 'PUT'
            },
            'deletebyids': {
                method: 'POST',
                url: '/flyer/api/hub-showcase/deleteByIds'
            }
        });

        function transformResponse(data, headers) {
            var response = {};
            response.data = angular.fromJson(data);
            response.headers = headers();
            return response;
        }
    }
})();
