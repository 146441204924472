(function () {
    'use strict';

    angular
        .module('showcaseApp')
        .service('UserEngagementService', UserEngagementService);

    //** NgInject */
    function UserEngagementService(UserEngagementFactory) {

        var service = {
            getUserInfo: getUserInfo,
            getUserCategoryStatistics: getUserCategoryStatistics,
            getUserOfferEngagements: getUserOfferEngagements
        };

        return service;

        function getUserInfo(userId) {
            return UserEngagementFactory.getUserInfo({id: userId}).$promise;
        }

        function getUserCategoryStatistics(userId, from) {
            return UserEngagementFactory.getUserCategoryStatistics({from: from}, {id: userId}).$promise;
        }

        function getUserOfferEngagements(userId, from, options) {
            options.from = from;
            return UserEngagementFactory.getUserOfferEngagements(options, {id: userId}).$promise;
        }
    }
})();