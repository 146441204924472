(function () {
    'user strict'

    angular.module('showcaseApp')
        .factory('DiscountCouponFactory', DiscountCouponFactory);

    /** ngInject */
    function DiscountCouponFactory($resource) {

        var resourceUrl = 'admin/api/discount-coupon';

        return $resource(resourceUrl, {}, {
            'create': {
                method: 'POST',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'update': {
                method: 'PUT',
                url: resourceUrl + '/:id',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'list': {
                method: 'GET',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'search': {
                method: 'POST',
                url: resourceUrl + 's',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'deleteCoupon': {
                method: 'DELETE',
                url: resourceUrl + '/:discountCouponId',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            }

        });
    }
})();
