(function() {
    'use strict';

    angular
        .module('showcaseApp')
        .controller('HubCampaignDistributeController', HubCampaignDistributeController);

    function HubCampaignDistributeController(HubCampaignService, StoreService, StoreTypeService, toastr, $translate, $localStorage, $state, $stateParams) {

        var vm = this;

        vm.hubId = $localStorage.hub.id;
        vm.campaignId = $stateParams.id;
        vm.storesSelected = [];
		vm.storesSelectedFullData = [];
        vm.storeTypes = [];
        vm.requestParams = {
            page: 0,
            size: 25,
            name: ''
        }

        initLoad();

        vm.checkStoreIsSelected = function(id) {
            return vm.storesSelected.includes(id);
        }

        vm.changeStoreSelected = function(store) {
            if (vm.checkStoreIsSelected(store.storeId)) {
                vm.storesSelected = vm.storesSelected.filter(function(id) {
                    return store.storeId !== id;
                });
				vm.storesSelectedFullData = vm.storesSelectedFullData.filter(function (storeRemove) {
					return store !== storeRemove;
				});
            } else {
                vm.storesSelected.push(store.storeId);
				//Stores the data of the stores that were selected at some point
				vm.storesSelectedFullData.push(store);
            }
        }

        vm.markAllStoreType = function () {
            if (!vm.storeTypeId || vm.storeTypeId === '') {
                if (!vm.storesSelected.length) {
                    vm.storesSelected = [];
                    vm.quantStoresSelected = 0;
                }
                return;
            }

            StoreService.countStoresByCurrentHubAndStoreType(vm.storeTypeId).then(function (response) {
                vm.quantStoresSelected = response.count;
            });

            var storeType = vm.storeTypes.find(function (storeType) {
                return vm.storeTypeId === storeType.id.toString();
            });

            vm.storesSelected = [];
            if (vm.storeTypeId === 'all') {
                vm.stores.forEach(function (store) {
                    vm.changeStoreSelected(store);
                });
            } else {
                vm.stores.forEach(function (store) {
                    if (store.storeType === storeType.name) {
                        vm.changeStoreSelected(store);
                    }
                });
            }
        }

        vm.onChangeStoreName = function() {
            vm.requestParams.name = vm.storeName;
            loadStores(vm.requestParams);
        }

        vm.distribute = function() {
            var stores = vm.storesSelectedFullData.filter(function(store) {
                return vm.storesSelected.includes(store.storeId);
            });

            var request = (vm.storeTypeId && vm.storeTypeId > 0) ? { hubId: vm.hubId, storeTypeId: vm.storeTypeId } : { hubId: vm.hubId, stores: stores };
            HubCampaignService.distribute(vm.campaignId, request)
                .then(function () {
                    toastr.success($translate.instant('campaign.message.distribution-started'));
                    $state.go('hub-campaigns');
                }, function () {
                    toastr.error($translate.instant('campaign.message.error-to-distribute'));
                })
        };

        function initLoad() {
            loadStores(vm.requestParams);
            StoreTypeService.getAllStoreTypesByOwner().then(function(response) {
                vm.storeTypes = response.data;
            });
        }

        function loadStores(pageOptions) {
            StoreService.listStoresByCurrentHub({}, pageOptions, function(data) {
                vm.stores = data;
                vm.markAllStoreType();
            }, function() {
                toastr.error($translate.instant('campaign.message.error-to-stores'));
            });
        }
    }
})();
