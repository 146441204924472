(function() {
    'use strict';

    angular
        .module('showcaseApp')
        .controller('HubFlyersEditInfoController', HubFlyersEditInfoController);

    HubFlyersEditInfoController.$inject = ['$translate', 'HubFlyersService', 'toastr','$uibModalInstance', 'flyer'];

    function HubFlyersEditInfoController( $translate, HubFlyersService,toastr, $uibModalInstance, flyer) {
        var vm = this;
        vm.flyer = flyer;
        vm.saveFlyer = function() {
            vm.isSaving = true;
            HubFlyersService.updateFlyer(vm.flyer).then(function(data) {
                    vm.isSaving = false;
                    toastr.success($translate.instant('flyers.messages.update.success'));
                    $uibModalInstance.close(data);
                },
                function(data) {
                    vm.isSaving = false;
                    toastr.success($translate.instant('ERROR'));
                    $uibModalInstance.dismiss(data);
                });
        }

        vm.closeModal = function() {
            $uibModalInstance.close();
        }
    }

})();
