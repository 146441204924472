(function () {
    'use strict';

    angular
        .module('showcaseApp')
        .config(stateConfig);

    function stateConfig($stateProvider) {
        $stateProvider
            .state('social-medias', {
                parent: 'admin',
                url: '/social-medias',
                data: {
                    authorities: ['STORE_READ_CUSTOMER', 'SOCIAL_MEDIA_POST'],
                    pageTitle: 'socialMedia.title',
                    // specialClass: "md-skin fixed-nav mini-navbar"
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/social-media/social-media.html',
                        controller: 'SocialMediaController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('social-media');
                        $translatePartialLoader.addPart('integrations');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],

                    facebookConnected: function (SocialMediaService) {

                        function onSuccessIsFacebookConnect(response) {

                            var result = {};

                            if (response.data && response.data.accessToken) {
                                result = {

                                    facebookConnected: true,
                                    facebookDataConnected: response.data,

                                };

                                if (response.data.pages && response.data.pages[0]) {
                                    result.postDTO = {
                                        pageId: response.data.pages[0].pageId
                                    };
                                }

                                return result;

                            } else if (!response.data) {
                                result.facebookConnected = false;
                                result.facebookSelected = false;

                                return result;
                            }
                        }

                        function onErrorIsFacebookConnect(error){
                            var result = {
                                facebookConnected: false,
                                facebookSelected: false,
                            }

                            return result;
                        }

                        return SocialMediaService.isFacebookConnected().then(onSuccessIsFacebookConnect, onErrorIsFacebookConnect);

                    },
                    instagramConnected: function (SocialMediaService) {

                        function onSuccessIsInstagramConnect(response) {
                            if (response.data) {
                                return {
                                    instagramConnected: true,
                                    instagramAccountConnected: response.data
                                }
                            }
                            return {
                                instagramConnected: false
                            }
                        }

                        function onErrorIsInstagramConnect(error){
                            return {
                                instagramConnected: false
                            }
                        }

                        return SocialMediaService.isInstagramConnected().then(onSuccessIsInstagramConnect, onErrorIsInstagramConnect);

                    }
                }
            });
    }
})();
