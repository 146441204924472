(function() {
    'use strict';

    angular
        .module('showcaseApp')
        .directive('backImg', BackImgDirective);

    function BackImgDirective() {
        return function(scope, element, attrs) {
            var url = attrs.backImg;
            var random = new Date().getTime();
            url = url + "?ts=" + random;
            element.css({
                'background-image': 'url(' + url + ')',
                'background-size': 'cover'
            });
        };
    }
})();
