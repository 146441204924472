/* globals $ */
(function() {
    'use strict';
    angular
        .module('showcaseApp')
        .component('messageReply', {
            bindings: {
                messageToReply: '<',
                messageOwnerName: '<',
                cancelReply: '<'
            },
            controller: MessageReplyController,
            templateUrl: 'app/admin/communication/directives/message-reply/message-reply.html',
            controllerAs: 'vm'
        });

    MessageReplyController.$inject = ['$scope', '$window'];

    function MessageReplyController($scope, $window) {
        var vm = this;
        vm.isMobile = detectar_mobile();

        getVideoDuration();
        getAudioDuration();

        function getVideoDuration() {
            if (vm.messageToReply.type === 'video' && !vm.messageToReply.text) {
                var video = document.createElement('video');
                video.preload = 'metadata';
                video.onloadedmetadata = function() {
                    window.URL.revokeObjectURL(this.src)
                    var duration = video.duration;
                    vm.messageToReply.duration = moment(duration * 1000).format('mm:ss');
                    $scope.$apply();
                }

                video.src = vm.messageToReply.data;
            }
        }

        function getAudioDuration() {
            if (vm.messageToReply.type === 'audio' && !vm.messageToReply.text) {
                var audio = document.createElement('audio');
                audio.preload = 'metadata';
                audio.onloadedmetadata = function() {
                    window.URL.revokeObjectURL(this.src)
                    var duration = audio.duration;
                    vm.messageToReply.duration = moment(duration * 1000).format('mm:ss');
                    $scope.$apply();
                }

                audio.src = vm.messageToReply.data;
            }
        }
    }
})();
