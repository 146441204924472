(function () {
    'use strict';

    angular
        .module('showcaseApp')
        .controller('CustomHtmlModalController', CustomHtmlModalController);

    CustomHtmlModalController.$inject = ['$uibModalInstance', 'customHtml'];

    function CustomHtmlModalController($uibModalInstance, customHtml) {

        var vm = this;
        vm.customHtml = customHtml;

        vm.closeModal = function () {
            $uibModalInstance.close();
        }

        vm.save = function () {
            $uibModalInstance.close({ customHtml: vm.customHtml });
        }

        vm.clear = function () {
            vm.customHtml = null;
        }

    }
})();
