(function () {
    'use strict'


    angular
        .module('showcaseApp')
        .service('DigitalPosterService', DigitalPosterService);

    //** NgInject */
    function DigitalPosterService(DigitalPosterFactory) {

        var service = {
            generatePoster: generatePoster
        };
        
        function generatePoster(posterDTO) {
            return DigitalPosterFactory.generatePoster(posterDTO).$promise;
        }

        return service;

       
    }


})();
