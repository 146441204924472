(function () {
    'use strict';

    angular
        .module('showcaseApp')
        .controller('CustomersListController', CustomersListController);

    /** @ngInject */
    function CustomersListController($rootScope, $scope, CustomerService, CustomerModalFactory, toastr, $translate) {

        var vm = this;
        vm.filter = {};
        vm.customers = [];

        vm.originalOrder = [];

        vm.currentPage = 1;
        vm.numPerPage = 10;
        vm.maxSize = 5;
        vm.totalCustomers = 0;

        vm.startRegistry = 1;
        vm.endRegistry = vm.numPerPage;

        vm.paginationOptions = {
            page: vm.currentPage - 1,
            size: vm.numPerPage,
            sort: null
        };

        vm.orderedByBirthdayWeek = false;

        vm.showModalAddCustomer = showModalAddCustomer;
        vm.selectCustomer = selectCustomer;
        vm.searchByFilter = searchByFilter;
        vm.filterChange = filterChange;

        init();

        function init() {
            listCustomers(vm.paginationOptions, vm.filter, true);
        }

        vm.orderByBirthdayWeek = function () {
            vm.orderedByBirthdayWeek = !vm.orderedByBirthdayWeek;

            vm.filter.orderedByBirthdayWeek = vm.orderedByBirthdayWeek;
            vm.currentPage = 1;
            vm.paginationOptions.page = 0;
            listCustomers(vm.paginationOptions, vm.filter, true);

        }

        function parseDates(elements){
            elements.forEach(function (element){
                element.birthdate = moment.utc(element.birthdate);
            });
        }

        function listCustomers(pagination, filter, selectFirst) {
            vm.loadingCustomers = true;
            CustomerService.list(pagination, filter).then(function (result) {
                parseDates(result.data);
                vm.totalCustomers = result.headers['x-total-count'];
                vm.customers = result.data.slice();
                vm.originalOrder = result.data;
                if (selectFirst) {
                    $rootScope.$broadcast('selectedCustomer', angular.copy(vm.customers[0]));
                }
                vm.loadingCustomers = false;
            }, function (error) {
                vm.loadingCustomers = false;
            });
        }

        function showModalAddCustomer() {
            CustomerModalFactory.addCustomer().then(function (result) {
                toastr.success($translate.instant(result.headers["x-adminapp-alert"]));
                listCustomers(vm.paginationOptions, {}, true);
            });
        }

        function selectCustomer(customer) {
            $rootScope.$broadcast('selectedCustomer', angular.copy(customer));
        }

        function searchByFilter() {
            if (vm.filter.fullName) {
                vm.currentPage = 1;
                vm.paginationOptions.page = 0;
                listCustomers(vm.paginationOptions, vm.filter, true);
            }
        }

        function filterChange() {
            vm.currentPage = 1;
            vm.paginationOptions.page = 0;
            listCustomers(vm.paginationOptions, vm.filter, true);
        }

        vm.changePage = function () {

            var begin = ((vm.currentPage - 1) * vm.numPerPage),
                end = begin + vm.numPerPage;

            vm.paginationOptions.page = vm.currentPage - 1;

            vm.customers = listCustomers(vm.paginationOptions, vm.filter, true);
            vm.startRegistry = begin + 1;
            vm.endRegistry = end;

        }

        $scope.$on('refreshListCustomer', function (env) {
            listCustomers(vm.paginationOptions, vm.filter, false);
        });

        $scope.$on('deletedCustomer', function (env) {
            listCustomers(vm.paginationOptions, vm.filter, true);
        });
    }
})();
