(function() {
	'use strict';

	angular.module('showcaseApp').service('BankDataService', BankDataService);

	BankDataService.$inject = [ 'BankDataFactory' ];

	function BankDataService(BankDataFactory) {
		var service = {
			getBanks : getBanks
		};

		return service;

		function getBanks() {
			var banks = BankDataFactory.getBanks().$promise;
			return banks;
		}

	}
})();
