(function() {
    'use strict';
    angular
        .module('showcaseApp')
        .factory('RedeemCouponsFactory', RedeemCouponsFactory);

    RedeemCouponsFactory.$inject = ['$resource'];

    function RedeemCouponsFactory($resource) {
        var resourceUrl = '';

        return $resource(
            resourceUrl
        , {}, {
            'redeem': {
                method: 'POST',
                url: 'flyer/api/coupon/redeem',
                transformResponse: [function(data) {
                    return data;
                }]
            },
            'validate': {
                method: 'POST',
                url: 'flyer/api/coupon/validate'
            }
        });
    }
})();
