(function() {
	'use strict';

    angular.module('showcaseApp')
        .controller('SelectOfferModalController', SelectOfferModalController);

	function SelectOfferModalController($uibModalInstance, $stateParams, title, singleSelection, FlyersCurrencyValue, FlyersService) {
		var vm = this;

		vm.confirm = confirm;
        vm.cancel = cancel;
        
		vm.selectedFlyer = undefined;
		vm.error = false;

		vm.offers = [];
		vm.busy = false;
		vm.hasNextPage = true;
		vm.page = 0;
		vm.pageable = {
			page: vm.page,
			size: 10
		};
		vm.title = title;
		vm.singleSelection = singleSelection;

		vm.toggleSelection = function toggleSelection(flyer) {
			vm.selectedFlyer = flyer;
		}
		
		function loadlAll(){
			vm.loadlFlyers(0);
		}

		vm.loadlFlyers = function (page) {
			vm.busy = true;
			vm.page = page;
			vm.pageable.page = page;
			FlyersService.listFlyersPageToModal(vm.pageable).then(function (response, headers) {
				for (var i = 0; i < response.data.content.length; i++) {
					vm.offers.push(response.data.content[i]);
				}
				
				
				vm.hasNextPage = (response.data.totalElements > vm.offers.length);
				vm.busy = false;
			}, function (error) {
				vm.error = true;
				vm.busy = false;
			});
		}

		vm.getFlyerCurrency = function (currency) {
            return FlyersCurrencyValue[currency];
        }

		vm.stopPropagation = function(event) {
			event.stopPropagation();
		};

		function cancel() {
			$uibModalInstance.dismiss('close');
		}

		function confirm() {
			$uibModalInstance.close(vm.selectedFlyer);
		}

		loadlAll();

	}
})();
