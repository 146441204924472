(function () {
    'use strict';

    angular
        .module('showcaseApp')
        .factory('ModalBrandFactory', ModalBrandFactory);

    /** @ngInject */
    function ModalBrandFactory($uibModal) {

        var factory = {
            listModal: listModal,
        }

        return factory;

        function listModal() {
            return listBrandModal();
        }

        function listBrandModal() {
            return $uibModal.open({
                templateUrl: "app/admin/brand/modal-brand.html",
                windowClass: 'modal-fit',
                size: 'md',
                controller: "ModalBrandConstroller",
                controllerAs: "vm",
                backdrop: 'static'
            }).result;
        }
    }
})();
