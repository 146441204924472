/* globals $ */
(function() {
    'use strict';

    angular
        .module('showcaseApp')
        .directive('messageOptions', messageOptions);

    function messageOptions() {
        var directive = {
            restrict: 'E',
            templateUrl: 'app/admin/communication/directives/message-options.html',
            scope: {
                message: '=',
                applyOption: '&'
            },
            link: function(scope, element, attr) {
                scope.isOpen = false;
                scope.isMobile = detectar_mobile();

                scope.callApplyOption = function (option, message) {
                    scope.applyOption(option, message);
                }

                scope.toggled = function (isOpen) {
                    scope.message.holdMessage = isOpen;
                }
            }
        };

        return directive;
    }
})();
