(function () {
    'use strict';
    angular
        .module('showcaseApp')
        .factory('ShowcaseSettingsFactory', ShowcaseSettingsFactory);

        ShowcaseSettingsFactory.$inject = ['$resource'];

        function ShowcaseSettingsFactory($resource) {
            var resourceUrl = '/admin/api/showcase-settings';

            return $resource(resourceUrl, {}, {
                'getByStoreId': {
                    method: 'GET',
                    url: resourceUrl + '/:storeId',
                    transformResponse: function (data, headers) {
                        var response = {};
                        if (data) {
                            response.data = angular.fromJson(data);
                        }
                        response.headers = headers();
                        return response;
                    }
                },
                'update': {
                    method: 'PUT',
                    url: resourceUrl + '/:storeId',
                    transformResponse: function (data, headers) {
                        var response = {};
                        if (data) {
                            response.data = angular.fromJson(data);
                        }
                        response.headers = headers();
                        return response;
                    }
                }
            });
        }
})();
