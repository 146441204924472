(function() {
    'use strict';
    angular
        .module('showcaseApp')
        .factory('CheckCEPFactory', CheckCEPFactory);

    CheckCEPFactory.$inject = ['$resource'];

    function CheckCEPFactory($resource) {
        var resourceUrl =  'zipcode/api/address/:cep';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data, headers) {
                    var response = {};
                    var response = {};
                    response.headers = headers();
                    try {
                        response.data = angular.fromJson(data);
                        return response
                    } catch (err){
                        return response;
                    }
                }
            }
        });
    }
})();
