(function() {
    'use strict';

    angular
        .module('showcaseApp')
        .controller('InvoicesController', InvoicesController);

    function InvoicesController($translate, $localStorage, $uibModal, InvoicesService, StoreService, toastr, ModalFactory) {
        var vm = this;

        vm.flyersFiltered = [];

        vm.startDate = {
            startDate: null,
            endDate: null
        };

        vm.currentPage = 1;
        vm.numPerPage = 10;
        vm.maxSize = 5;
        vm.totalCustomers = 0;
        vm.currencyPrefix = 'R$';

        vm.startRegistry = 1;
        vm.endRegistry = vm.numPerPage;

        vm.paginationOptions = {
            page: vm.currentPage - 1,
            size: vm.numPerPage,
            sort: null
        };

        vm.invoiceList = [];

        vm.filter = {
            transactionType: "SALE"
        };

        init();

        function init() {
            loadInvoiceList(vm.paginationOptions, vm.filter);
        }

        function loadInvoiceList(pagination, filter) {
            vm.loadingInvoices = true;
            InvoicesService.list(pagination, filter).then(function (result) {
                if (result.data) {
                    vm.invoiceList = result.data.content;
                    vm.invoiceList.forEach(function (transaction){
                        transaction.currencyPrefix = getCurrencyPrefix(transaction);
                    });
                    vm.totalInvoices = result.data.totalElements;
                    vm.loadingInvoices = false;
                }
            }, function (error) {
                vm.loadingInvoices = false;
            });
        }

        function getCurrencyPrefix(transaction) {
            var key = transaction.currency ? transaction.currency : 'REAL';
            var config = getCurrencyListConfiguration()[key];
            return config.prefix;
        }

        vm.openModalDetail = function(invoice) {
            ModalFactory.openDetailInvoice(invoice).then(function (result) {
                if (result && result.invoice) {
                    vm.openRefundModal(result.invoice);
                } else {
                    loadInvoiceList(vm.paginationOptions, vm.filter);
                }
            });
        }

        vm.openRefundModal = function (data) {
            vm.magicModalCtrl = $uibModal.open({
                templateUrl: 'app/admin/invoices/refund/refund-magic-modal.html',
                controller: 'RefundMagicModalController',
                windowClass: 'modal-center',
                windowTopClass: 'modal-dialog-center',
                controllerAs: 'vm',
                resolve: {
                    invoice: function () {
                        return data
                    }
                },
                keyboard: false
            }).result.then(function (response) {
                loadInvoiceList(vm.paginationOptions, vm.filter);
            });
        };

        vm.changePage = function () {
            var begin = ((vm.currentPage - 1) * vm.numPerPage),
                end = begin + vm.numPerPage;

            vm.paginationOptions.page = vm.currentPage - 1;

            vm.customers = loadInvoiceList(vm.paginationOptions, vm.filter);
            vm.startRegistry = begin + 1;
            vm.endRegistry = end;

        }

        vm.updateStatus = function(invoice, status) {
            vm.isSaving = true;
            InvoicesService.updateTransactionStatus(invoice.id, status).then(function(result) {
                toastr.success($translate.instant('invoice.home.messages.saveSuccess'));
                invoice.status = status;
                vm.isSaving = false;
            }).catch(function(err) {
                toastr.error($translate.instant('invoice.home.messages.noItens'));
                vm.isSaving = false;
            });
        }


        vm.filterInvoices = function() {
            vm.filter.status = vm.filter.status == "" ? null : vm.filter.status
            vm.currentPage = 1;
            vm.paginationOptions.page = 0;
            loadInvoiceList(vm.paginationOptions, vm.filter);
        };

        vm.maskPrice = function (value, currencyKey) {
            var currencyConfiguration = getCurrencyListConfiguration()[currencyKey];
            return formatMoney(value, currencyConfiguration.prefix, currencyConfiguration.decimalQuant, currencyConfiguration.centsSeparator, currencyConfiguration.thousandsSeparator);
        }
    }
})();
