(function () {
    'use strict';
    angular
        .module('showcaseApp')
        .factory('ErpsSettingsFactory', ErpsSettingsFactory);

    ErpsSettingsFactory.$inject = ['$resource'];

    function ErpsSettingsFactory($resource) {
        var resourceUrl = 'admin/api/erp-configuration';

        return $resource(resourceUrl, {storeId: '@storeId'}, {
            'createERPConfiguration': {
                method: 'POST',
                url: resourceUrl,
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'getERPConfigurationByStoreIdAndPartnerId': {
                method: 'GET',
                url: resourceUrl + '/store/:storeId/partner/:partnerId',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'getERPConfigurationByStoreId': {
                method: 'GET',
                url: resourceUrl + '/store/:storeId',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'updateERPConfiguration': {
                method: 'PUT',
                url: resourceUrl + '/:erpConfigurationId',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            }, 'getPriceTablesByToken': {
                method: 'GET',
                url: resourceUrl + '/price-tables/:token',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            }, 'getEntitiesByToken': {
                method: 'GET',
                url: resourceUrl + '/entities/:token',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            }, 'getNexaasOrganizationsByToken': {
                method: 'GET',
                url: resourceUrl + '/organizations/:token',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            }, 'getNexaasOrganizationStocks': {
                method: 'GET',
                url: resourceUrl + '/stocks/nexaas/:token',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'startNexaasSynchronization': {
                method: 'POST',
                url: 'flyer/api/integration/nexaas/synchronize',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'reprocessOnlineRetail': {
                method: 'PUT',
                url: resourceUrl + '/reprocess/:id',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'deleteErpConfiguration': {
                method: 'DELETE',
                url: resourceUrl + '/:id',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'createERPConfigurationForSoftcom': {
                method: 'POST',
                url: resourceUrl + '/softcom/create',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
        });
    }
})();
