(function() {
    'use strict'


    angular
        .module('showcaseApp')
        .service('EngagementService', EngagementService);

    //** NgInject */
    function EngagementService(EngagementFactory) {

        var service = {
            listUsersFromCoupons: listUsersFromCoupons,
            listUsersFromContact: listUsersFromContact,
            listUsersFromSale: listUsersFromSale
        };

        return service;

        function listUsersFromCoupons(storeId, flyersIds) {
            return EngagementFactory.listUsersFromCoupons({
                storeId: storeId
            }, {
                flyersIds: flyersIds
            }).$promise;
        }

        function listUsersFromContact(storeId, flyersIds) {
            return EngagementFactory.listUsersFromContact({
                storeId: storeId
            }, {
                flyersIds: flyersIds
            }).$promise;
        }

        function listUsersFromSale(storeId, flyersIds) {
            return EngagementFactory.listUsersFromSale({
                storeId: storeId
            }, {
                flyersIds: flyersIds
            }).$promise;
        }
    }


})();
