(function () {
    'use strict';

    angular
        .module('showcaseApp')
        .config(stateConfig);

    function stateConfig($stateProvider) {
        $stateProvider
            .state('communication', {
                parent: 'admin',
                url: '/communication/{chatPlatform}/{conversationId}',
                params: {
                    chatPlatform: { squash: true, value: '' },
                    conversationId: { squash: true, value: '' }
                },
                data: {
                    authorities: ['SEND_MESSAGE_TO_WATI', 'SEND_MESSAGE_TO_FACEBOOK'],
                    pageTitle: 'global.menu.admin.communication'
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/admin/communication/communication.html',
                        controller: 'CommunicationController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('communication');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('customers');
                        $translatePartialLoader.addPart('bag');
                        $translatePartialLoader.addPart('integrations');
                        $translatePartialLoader.addPart('social-media');
                        $translatePartialLoader.addPart('reports');
                        $translatePartialLoader.addPart('flyers');
                        return $translate.refresh();
                    }]
                }
            })
    }

})();
