(function() {
    'use strict';

    angular
        .module('showcaseApp')
        .factory('AuthServerSessionProvider', AuthServerSessionProvider);

    AuthServerSessionProvider.$inject = ['$http', '$localStorage', '$sessionStorage'];

    function AuthServerSessionProvider ($http, $localStorage, $sessionStorage) {
        var service = {
            changeSession: changeSession,
            storeAuthenticationToken: storeAuthenticationToken
        };

        return service;

        function changeSession (storeId) {
            var data = {
                storeId: storeId
            };

            return $http.post('api/change-session', data).success(changeSessionSuccess);

            function changeSessionSuccess (data, status, headers) {
                var bearerToken = headers('Authorization');
                if (angular.isDefined(bearerToken) && bearerToken.slice(0, 7) === 'Bearer ') {
                    var jwt = bearerToken.slice(7, bearerToken.length);
                    service.storeAuthenticationToken(jwt, true);
                    return jwt;
                }
            }
        }

        function storeAuthenticationToken(jwt, rememberMe) {
            if(rememberMe){
                $localStorage.authenticationToken = jwt;
            } else {
                $sessionStorage.authenticationToken = jwt;
            }
        }
    }
})();
