(function () {
  'use strict';

  angular.module('showcaseApp')
    .controller('CategoryFlyerListController', CategoryFlyerListController);

  /** @ngInject */
  function CategoryFlyerListController($scope, RemoveModalFactory, CategoryFlyerService, toastr, $translate, $state) {
    var vm = this;
    vm.showModalAddCategory = showModalAddCategory;
    vm.categorys = [];
    vm.search = '';
    vm.fullCategories = []
    vm.loadingList = false;

    getCategorys();

    function showModalAddCategory() {
      CategoryFlyerService.showAddEditModal().then(function (result) {
      });
    }

    vm.showModalEditcategory = function (category) {
      CategoryFlyerService.showAddEditModal(category).then(function (result) {
        vm.category = result.data;
      });
    }

    function getCategorys() {
      vm.loadingList = true;
      CategoryFlyerService.listCategoriesByCurrentStore($translate.use()).then(function (response) {
        vm.fullCategories = response.data;
        if (vm.fullCategories.length > 0) {
          vm.categorys = listToTree(vm.fullCategories);
        } else {
          vm.categorys = [];
          vm.loadingList = false;
        }
      });
    }

    function findIndex(value) {
      return value.id == this;
    }

    function listToTree(list) {
      var map = {}, node, roots = [], i;

      for (i = 0; i < list.length; i += 1) {
        map[list[i].id] = i; // initialize the map
        list[i].children = []; // initialize the children
      }

      for (i = 0; i < list.length; i += 1) {
        node = list[i];
        if (node.categoryId && node.categoryId !== '0' && list.findIndex(findIndex, node.categoryId) !== -1) {
          list[map[node.categoryId]].children.push(node);
        } else {
          roots.push(node);
        }
      }
      vm.loadingList = false;
      return roots;
    }

    vm.querySearchCategory = function () {
      vm.loadingList = true;
      var results = vm.fullCategories.filter(createFilterFor(vm.search));
      if (results.length) {
        vm.categorys = listToTree(results);
      } else {
        vm.categorys = [];
        vm.loadingList = false;
      }
    }

    function createFilterFor(query) {
      var lowercaseQuery = query.toLowerCase();

      return function filterFn(category) {
        return (category.title.toLowerCase().indexOf(lowercaseQuery) === 0);
      };
    }

    vm.removeCategory = function (category) {
      RemoveModalFactory.deleteItem(1).then(function (data) {
        CategoryFlyerService.deleteCategory(category.id).then(function (data) {
          toastr.success($translate.instant('categorys.messages.remove.success'));
          $state.reload();
        }, function (error) {
          if (error && error.data && error.data.headers.statuskey === 'categoryCannotBeRemoved') {
            toastr.error($translate.instant('categorys.messages.remove.error-associated-category'));
          } else {
            toastr.error($translate.instant('categorys.messages.remove.error'));
          }
        });
      });
    }

    vm.toggle = function (scope) {
      scope.toggle();
    };

    $scope.collapseAll = function () {
      $scope.$broadcast('angular-ui-tree:collapse-all');
    };

    $scope.expandAll = function () {
      $scope.$broadcast('angular-ui-tree:expand-all');
    };


  }

}());