(function() {
    'use strict';

    angular
        .module('showcaseApp')
        .controller('ChangeStateModalController', ChangeStateModalController);

        ChangeStateModalController.$inject = ['$scope', '$uibModal', '$uibModalInstance', 'toastr', 'entity'];

    function ChangeStateModalController($scope, $uibModal, $uibModalInstance, toastr, entity) {
        var vm = this;
        vm.entity = entity;

        vm.confirm = function() {
            $uibModalInstance.close(true);
        }

        vm.closeModal = function() {
            $uibModalInstance.close(false);
        }

    }

})();
