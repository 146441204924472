(function() {
    'use strict';
    angular
        .module('showcaseApp')
        .service('HubService', HubService);

    HubService.$inject = ['HubFactory'];

    function HubService(HubFactory) {
        var service = {
            getStores: getStores,
            getStoreTypes: getStoreTypes,
            getHubByOwner: getHubByOwner,
            migrateFlyersToStores: migrateFlyersToStores,
            migrateFlyersToStoresByStoreType: migrateFlyersToStoresByStoreType,
			getHubByStoreId: getHubByStoreId
        };

        return service;

        function getStores(params,  pageable, onSuccess, onError) {
            return HubFactory.getStores(params, pageable, onSuccess, onError);
        }

        function getStoreTypes() {
            return HubFactory.getStoreTypes().$promise;
        }

        function getHubByOwner(id) {
            return HubFactory.getHubByOwner({id: id}).$promise;
        }

        function migrateFlyersToStores(ids, stores) {
            return HubFactory.migrateFlyersToStores({flyerIds: ids, stores: stores}).$promise;
        }

        function migrateFlyersToStoresByStoreType(ids, storeTypeId) {
            return HubFactory.migrateFlyersToStores({flyerIds: ids, storeTypeId: storeTypeId}).$promise;
        }

        function getHubByStoreId(id) {
            return HubFactory.getHubByStoreId({id: id}).$promise;
        }

    }
})();
