/* globals $ */
(function() {
    'use strict';

    angular
        .module('showcaseApp')
        .directive('messageLongPress', messageLongPress);

    function messageLongPress($timeout) {
        var directive = {
            restrict: 'A',
            link: function($scope, $elm, $attrs) {
                $elm.bind('touchstart', function(evt) {
                    // Locally scoped variable that will keep track of the long press
                    $scope.longPress = true;

                    // We'll set a timeout for 600 ms for a long press
                    $timeout(function() {
                        if ($scope.longPress) {
                            var message = $scope.$eval($attrs.message);
                            // If the touchend event hasn't fired,
                            // apply the function given in on the element's on-long-press attribute
                            $scope.$apply(function() {
                                message.holdMessage = false;
                            });

                            $scope.$apply(function() {
                                evt.preventDefault();
                                evt.stopPropagation();
                                message.holdMessage = true;
                            });
                        }
                    }, 600);
                });

                $elm.bind('touchend', function(evt) {
                    // Prevent the onLongPress event from firing
                    $scope.longPress = false;
                    // If there is an on-touch-end function attached to this element, apply it
                    if ($attrs.onTouchEnd) {
                        $scope.$apply(function() {
                            $scope.$eval($attrs.onTouchEnd)
                        });
                    }
                });
            }
        };

        return directive;
    }
})();
