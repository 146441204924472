(function () {
    'use strict';
    angular
        .module('showcaseApp')
        .service('SocialMediaService', SocialMediaService);

    SocialMediaService.$inject = ['SocialMediaFactory', '$localStorage', 'Upload'];

    function SocialMediaService(SocialMediaFactory, $localStorage, Upload) {

        var service = {
            isFacebookConnected: isFacebookConnected,
            connectWithFacebook: connectWithFacebook,
            doAFacebookPost: doAFacebookPost,
            facebookDisconnect: facebookDisconnect,
            connectWithInstagram: connectWithInstagram,
            isInstagramConnected: isInstagramConnected,
            doAInstagramPost: doAInstagramPost,
            instagramDisconnect: instagramDisconnect
        };

        return service;

        function isFacebookConnected() {
            return SocialMediaFactory.isFacebookConnected().$promise;
        }

        function facebookDisconnect() { 
            return SocialMediaFactory.facebookDisconnect().$promise;
        }

        function connectWithFacebook(connectVMDTO) {
            return SocialMediaFactory.connectWithFacebook(connectVMDTO).$promise;
        }

        function doAFacebookPost(postDTO, onSuccess, onError) {
            return SocialMediaFactory.doAFacebookPost(postDTO, onSuccess, onError).$promise;
        }

        function doAInstagramPost(postDTO, onSuccess, onError) {
            return SocialMediaFactory.doAInstagramPost(postDTO, onSuccess, onError).$promise;
        }

        function connectWithInstagram(connectVMDTO) {
            return SocialMediaFactory.connectWithInstagram(connectVMDTO).$promise;
        }

        function isInstagramConnected() {
            return SocialMediaFactory.isInstagramConnected().$promise;
        }

        function instagramDisconnect() { 
            return SocialMediaFactory.instagramDisconnect().$promise;
        }
    }

})();
