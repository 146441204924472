(function () {
    'user strict'

    angular.module('showcaseApp')
        .factory('CustomerFactory', CustomerFactory);

    /** ngInject */
    function CustomerFactory($resource) {

        var resourceUrl = 'admin/api/customer/:id';

        return $resource(resourceUrl, {
            id: '@id'
        }, {
            'create': {
                method: 'POST',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'update': {
                method: 'PUT',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'delete': {
                method: 'DELETE',
                transformResponse: function (data, headers) {
                    var response = {};
                    response.headers = headers();
                    return response;
                }
            },
            'listAll': {
                method: 'POST',
                url: 'admin/api/customer/list',
                isArray: true
            },
            'listAllByIds': {
                method: 'POST',
                url: 'admin/api/customer/list-by-ids',
                isArray: true
            },
            'list': {
                method: 'POST',
                url: 'admin/api/customers',
                // isArray: true,
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data){
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'importCustomers': {
                method: 'POST',
                url: 'admin/api/customer/import',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'getOrCreateCustomerByWhatsapp': {
                method: 'POST',
                url: 'admin/api/customer/create/by-whatsapp',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'getByName': {
                method: 'GET',
                url: 'admin/api/customers',
                // isArray: true,
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data){
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'getOrCreateCustomerByFbInstagram': {
                method: 'POST',
                url: 'admin/api/customer/create/by-facebook-instagram',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            }
        });
    }
})();
