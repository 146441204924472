(function() {
    'use strict';
    angular
        .module('showcaseApp')
        .factory('WsFactory', WsFactory);

    WsFactory.$inject = ['$resource'];

    function WsFactory($resource) {
        var resourceUrl = 'admin/api/ws/info';

        return $resource(resourceUrl, {}, {});
    }

})();
