(function() {
    'use strict';

    angular
        .module('showcaseApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('main-login', {
            url: '/',
            templateUrl: 'app/components/login/main-login/main-login.html',
            controller: 'MainLoginController',
            controllerAs: 'vm',
            data: {
                authorities: [],
                pageTitle: 'Login to Kaztor',
                specialClass: 'main-login',
                mainFlexClass: 'special-height'
            },
            params: {
                'email': null,
                'password': null
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('login');
                    return $translate.refresh();
                }]
            }
        });
    }
})();
