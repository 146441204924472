(function () {
    'use strict';

    angular
        .module('showcaseApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('home', {
            parent: 'app',
            url: '/home',
            data: {
                authorities: ['SYSTEM_USER'],
                pageTitle: 'home.pageTitle'
            },
            views: {
                'content@app': {
                    templateUrl: 'app/home/home.html',
                    controller: 'HomeController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    $translatePartialLoader.addPart('showcases');
                    $translatePartialLoader.addPart('checklist');
                    return $translate.refresh();
                }],
                data: function($q, $state, $timeout, $localStorage) {
                    var deferred = $q.defer();

                    $timeout(function() {
                      if ($localStorage.defaultStore && !$localStorage.defaultStore.storeId) {
                        $state.go('store-info');
                        deferred.reject();
                      } else {
                        deferred.resolve();
                      }
                    }, 10);

                    return deferred.promise;
                  }
            }
        });
    }
})();
