(function() {
    'use strict';

    angular
        .module('showcaseApp')
        .controller('TemplateMessageController', TemplateMessageController);

        TemplateMessageController.$inject = ['$uibModalInstance', 'TemplateMessageService', 'DeleteTemplateModalFactory'];

    function TemplateMessageController($uibModalInstance, TemplateMessageService, DeleteTemplateModalFactory) {
        
        var vm = this;

        vm.templates = []

        TemplateMessageService.getAll().then(function(result) {
            vm.templates = result.data;
        });

        vm.onSelectTemplate = function(template) {
            vm.template = template.template;
            vm.currentTemplate = template;
        }

        vm.dismiss = function() {
			$uibModalInstance.dismiss();
		}

        vm.sendTemplate = function(template) {
            $uibModalInstance.close(template.template);
        }

        vm.addTemplate = function() {
            if (vm.currentTemplate !== undefined) {
                vm.update();
            } else {
                TemplateMessageService.create({template: vm.template}).then(function(result) {
                    vm.templates.push({id: result.id, template: result.template});
                    vm.template = '';
                });}
            }


        vm.remove = function(template) {
            vm.removeModalActive = true;
            DeleteTemplateModalFactory.deleteTemplate(template).then(function(result) {
                if (result) {
                    TemplateMessageService.remove(template.id).then(function(result) {
                        vm.templates = vm.templates.filter(function(temp) {
                            return template.id != temp.id;
                        });
                    });
                }
                vm.removeModalActive = false;
                
            }, function(cancel) {
                vm.removeModalActive = false;
            })

        }

        vm.cancel = function() {
            $uibModalInstance.dismiss('cancel');
        }

        vm.ok = function() {
            $uibModalInstance.close(true);
        }

        vm.update = function() {
            vm.currentTemplate.template = vm.template
            TemplateMessageService.update(vm.currentTemplate.id, vm.currentTemplate).then(function(result) {
                var templateIndex = vm.templates.findIndex(function (template) {
                    return template.id == vm.currentTemplate.id;
                });
                vm.templates[templateIndex] = vm.currentTemplate;
                vm.template = '';
                vm.currentTemplate = undefined;
            });
        }

    }

})();
