(function() {
    'use strict';

    angular
        .module('showcaseApp')
        .controller('FiltersFlyersController', FiltersFlyersController);

    FiltersFlyersController.$inject = ['$uibModalInstance', '$translate', '$localStorage', 'toastr', 'filters', 'options', 'list', 'paginationConstants', 'StoreService'];

    function FiltersFlyersController($uibModalInstance, $translate, $localStorage, toastr, filters, options, list, paginationConstants, StoreService) {

        var vm = this;
        vm.originalFilters = angular.copy(filters);
        vm.filters = angular.copy(filters);
        vm.listFilter = list;
        vm.canChangeView = options.canChangeView;
        vm.sortOptions = paginationConstants.sortOptions[$translate.use().toLowerCase()];

        vm.colors = formatOptionsDict(options.colors);
        vm.sizes = formatOptionsDict(options.sizes);
        vm.categories = formatOptionsDict(options.categories);
        vm.brands = formatOptionsDict(options.brands);
        vm.tags = formatOptionsDict(options.tags);
        vm.hasInventory = filters.hasInventory;
        vm.viewByCategories = options.viewByCategories;

        StoreService.getStore($localStorage.defaultStore.storeId).then(function(response) {
            vm.storeCategory = response.data.categoryKey;
        });

        vm.genders = formatOptionsDict(
            [{"label":"flyers.new.form.gender.male","id":1},
            {"label":"flyers.new.form.gender.female","id":2},
            {"label":"flyers.new.form.gender.both","id":3}]);
        vm.configurations = formatOptionsDict([
            {"label":"flyers.new.form.configurations.launch","id":1, "code": "launch"},
            {"label":"flyers.new.form.configurations.basic","id":2, "code": "basic"}]);

        vm.isBagFilter = false
        if (options.isBagFilter) {
            vm.isBagFilter = options.isBagFilter;
            formatFilterOptions();
        }

        vm.closeModal = function(response, applyFilters) {
            if (!applyFilters) {
                vm.filters = vm.originalFilters;
            }
            $uibModalInstance.close(response);
        }

        vm.closeDropDown = function () {
            $("md-backdrop").trigger('click');
        }

        vm.getSelectedText = function (filter) {
            if (filter.length === 0) return '';

            var elementTranslate = (filter[0].label);
            if (elementTranslate.includes("."))
                elementTranslate = $translate.instant(filter[0].label);

            if (filter.length === 1) {
                return '<span class="highlight">' + elementTranslate + '</span>';
            }

            if (filter.length > 1) {
                var temp = '  +';
                temp += filter.length - 1;
                temp += ' ' + $translate.instant('flyers.filters.selected');

                return '<span class="highlight">' + elementTranslate + '</span>' +
                    '<span>&nbsp</span>' +
                    '<span class="highlight">' + temp + '</span>';
            }
            return '';
        }

        vm.clearFilters = function () {
            vm.filters.categories = [];
            vm.filters.brands = [];
            vm.filters.colors = [];
            vm.filters.sizes = [];
            vm.filters.genders = [];
            vm.filters.configurations = [];
            vm.filters.tags = [];
            vm.hasInventory = false;
            vm.filters.sortSelected = {
                value: 'lastModifiedDate',
                direction: 'desc'
            };
        }

        vm.applyFilters = function () {
            var filters = angular.copy(vm.filters);
            filters.categories = filters.categories.map(function(category){ return category.key });
            filters.brands = filters.brands.map(function(brand){ return brand.name });
            filters.colors = filters.colors.map(function(color){ return color.label });
            filters.sizes = filters.sizes.map(function(size){ return size.label });
            filters.genders = filters.genders.map(function(gender){ return gender.id });
            filters.configurations = filters.configurations.map(function(configuration) { return configuration.code })
            filters.sortDirection = filters.sortSelected ? filters.sortSelected.direction : 'desc';
            filters.sortBy = filters.sortSelected ? filters.sortSelected.value : 'lastModifiedDate';

            var totalFilters = filters.categories.length + filters.brands.length + filters.colors.length + filters.sizes.length +
                filters.genders.length + filters.configurations.length;

            if ((filters.categories).length > 0) {
                vm.listFilter.categoryKeys = filters.categories;
                vm.listFilter.categoryKey = filters.categories[0];
            } else {
                vm.listFilter.categoryKeys = [];
            }

            if ((filters.colors).length > 0) {
                vm.listFilter.colors = filters.colors;
            } else {
                vm.listFilter.colors = [];
            }

            if ((filters.sizes).length > 0) {
                vm.listFilter.sizes = filters.sizes;
            } else {
                vm.listFilter.sizes = [];
            }

            if ((filters.genders).length > 0) {
                vm.listFilter.genders = filters.genders;
            } else {
                vm.listFilter.genders = [];
            }

            if ((filters.configurations).length > 0) {
                vm.listFilter.configurations = filters.configurations;
            } else {
                vm.listFilter.configurations = [];
            }

            if ((filters.brands).length > 0) {
                vm.listFilter.brands = filters.brands;
            } else {
                vm.listFilter.brands = [];
            }

            if (filters.sortBy && filters.sortDirection) {
                vm.listFilter.sortBy = filters.sortBy;
                vm.listFilter.sortDirection = filters.sortDirection;
            } else {
                vm.listFilter.sortBy = '';
                vm.listFilter.sortDirection = '';
            }

            vm.listFilter.hasInventory = vm.hasInventory;
            vm.filters.hasInventory = vm.hasInventory;

            if (vm.viewByCategories !== options.viewByCategories){
                vm.changeView = true;
                vm.filters.viewByCategories = vm.viewByCategories;
            }

            vm.closeModal({filtered: filters, selectedFilters: angular.copy(vm.filters), totalFilters: totalFilters,
                listFilter: vm.listFilter, changeView: vm.changeView}, true);
        }

        function formatOptionsDict(optionsDict) {
            if (!optionsDict) {
                return {}
            }
            var count = 0;
            optionsDict.forEach(function(option) {
                if (typeof option === 'string' || option instanceof String) {
                    optionsDict[count] = {'label': option};
                } else if (!option.hasOwnProperty('label')){
                    if (option.hasOwnProperty('title')) {
                        option.label = option.title;
                    } else {
                        option.label = option.name;
                    }
                }
                count++;
            });

            count = 1;
            optionsDict.forEach(function(option) {
                if(!option.hasOwnProperty('id')){
                    option.id = count;
                }
                count++;
            });
            return optionsDict;
        }

        function formatFilterOptions() {
            var categories = [];
            vm.filters.categories.map(function(category) {
                vm.categories.forEach(function(configured_category) { if (configured_category.key === category) {
                    categories.push({'label': configured_category.label, 'id': configured_category.id, 'title': configured_category.title, 'key': category})
                }});
            })
            vm.filters.categories = categories;

            var sizes = [];
            vm.filters.sizes.map(function(size) {
                vm.sizes.forEach(function(configured_size) { if (configured_size.label === size) { sizes.push({'label': size, 'id': configured_size.id}); }});
            })
            vm.filters.sizes = sizes;

            var brands = [];
            vm.filters.brands.map(function(brand) {
                vm.brands.forEach(function(configured_brand) { if (configured_brand.name === brand) { brands.push({'name': brand, 'label': brand, 'id': configured_brand.id}); }});
            })
            vm.filters.brands = brands;

            var colors = [];
            vm.filters.colors.map(function(color) {
                vm.colors.forEach(function(configured_color) { if (configured_color.label === color) { colors.push({'label': color, 'id': configured_color.id});}});
            })
            vm.filters.colors = colors;

            var genders = [];
            vm.filters.genders.map(function(gender) {
                vm.genders.forEach(function(configured_gender) { if (configured_gender.id === gender) { genders.push({'label': configured_gender.label, 'id': gender});}});
            })
            vm.filters.genders = genders;

            var tags = [];
            vm.filters.tags.map(function(tag) {
                vm.tags.forEach(function(configured_tag) { if (configured_tag.label === tag) { tags.push({'label': tag, 'id': configured_tag.id})}});
            })
            vm.filters.tags = tags;
        }

    }

})();
