(function() {
    'use strict';
    angular
        .module('showcaseApp')
        .factory('PagarMeFactory', PagarMeFactory);

    PagarMeFactory.$inject = ['$resource'];

    function PagarMeFactory($resource) {
        var resourceUrl = 'api/pagarme/v4';

        return $resource(resourceUrl, {}, {
            'getRecipient': {
                method: 'GET',
                url: resourceUrl + '/user/:managerUserId/recipient',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'createRecipient': {
                method: 'POST',
                params: {},
                isArray: false,
                url: resourceUrl + '/user/:managerUserId/recipient',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'updateRecipient': {
                method: 'PUT',
                params: {},
                isArray: false,
                url: resourceUrl + '/:id/recipient',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            }
        });
    }
})();
