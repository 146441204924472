(function () {
    'use strict';

    angular
        .module('showcaseApp')
        .controller('ShowcasesDetailController', ShowcasesDetailController);

    /** @ngInject */
    function ShowcasesDetailController($state, $timeout, $stateParams, $filter, $q, $window, $localStorage,
        StoreService, $scope, $translate, FlyersService, FlyerCategoryService, FlyersCurrencyValue,
        ShowcasesService, toastr, Principal, UserInteractModalFactory, ModalFactory, DOMAIN, $rootScope, $uibModal) {

        var vm = this;
        var userInteractModal;
        var storeId = $localStorage.defaultStore.storeId;
        var mainColor = getComputedStyle(document.documentElement).getPropertyValue('--main-color');
        vm.showcaseId = $stateParams.showcaseId;
        var DEFAULT_TYPE = "SALE";
        vm.backgroundColorAux = '';
        vm.banners = [];
        vm.haveBanners = false;
        vm.isMobile = detectar_mobile();

        vm.isPrinting = false;

        var DEFAULT_IMAGE = "https://showkase-prod.s3.sa-east-1.amazonaws.com/hub/system-images/showkase/capa-loading-image-s3.png";

        vm.showcase = {
            'backgroundColor': false,
            flyers: [],
            ownerId: null,
            hasCover: false,
            userInteract: "SALE"
        };

        $scope.options = {
            format: 'hex',
            case: 'upper'
        }

        vm.listFilter = {
            storeId: storeId,
            activated: null,
            type: null
        };

        vm.openShowcaseModalType = openShowcaseModalType;

        vm.imageSize = 800;

		function loadCanvaAPI(c,a,n){
			var w=c.createElement(a),s=c.getElementsByTagName(a)[0];
			w.src=n;s.parentNode.insertBefore(w,s);
		}

		loadCanvaAPI (document,'script','https://sdk.canva.com/designbutton/v2/api.js');

        vm.storeOwnerHasMoipAccount = false;
        storeOwnerHasMoipAccount();
        vm.dynamicPopover = {
            templateUrl: 'userInteractPopoverTemplate.html',
        };
        vm.coverEdit = true;
        vm.templateIsLoading = true;
        vm.showcases = [1, 2, 3, 4, 5, 6, 7, 8];
        vm.templates = [];
        vm.flyersToLoad = 1;
        vm.isSaving = false;
		vm.saveImageCover = true;
        vm.isSavingPreview = false;

        loadAll();

        vm.openInputColor = function() {
            document.getElementsByClassName('pcr-button')[0].click();
        }

        vm.goToSettings = function () {
            $state.go('settings');
        }

        vm.openColorModal = function () {
            $uibModal.open({
                templateUrl: 'app/admin/showcases/select-colors-modal/select-colors-modal.html',
                controller: 'SelectColorsModalConstroller',
                windowClass: 'modal-center',
                windowTopClass: 'center-modal',
                controllerAs: 'vm',
                size: 'lg',
                keyboard: false,
                resolve: {
                    colors: function () {
                        return {
                            backgroundColor: vm.showcase.backgroundColor,
                            headerBackgroundColor: vm.showcase.headerBackgroundColor,
                            iconColor: vm.showcase.headerIconColor
                        }
                    }
                },
            }).result.then(function (response) {
                if (response) {
                    vm.showcase.backgroundColor = response.backgroundColor;
                    vm.showcase.headerBackgroundColor = response.headerBackgroundColor;
                    vm.showcase.headerIconColor = response.iconColor;
                    document.getElementsByClassName('icon-ksc-colorselection')[0].style.color = response.backgroundColor;
                }
            });
        }

        function loadAll() {
            vm.templateId = 1; // Chose the first template as default
            ShowcasesService.getTemplates().then(function (response) {
                vm.templates = response;
            });
            FlyerCategoryService.listCategories($translate.use()).then(function(response) {
                vm.categories = response.data;
            });
            if (vm.showcaseId) {
                loadShowcase();
            } else {
                vm.showcase.ownerId = storeId;
                vm.showcase.backgroundColor = mainColor;
                vm.showcase.headerBackgroundColor = mainColor;
                vm.backgroundColorAux = mainColor;
                vm.showcase.showcaseTemplateId = vm.templateId;
                vm.templateIsLoading = false;
            }
        }

        function loadShowcase() {
            return ShowcasesService.getShowcase(vm.showcaseId).then(function (response) {
                vm.showcase = response;
                vm.backgroundColorAux = vm.showcase.backgroundColor;
                vm.showcase.userInteract = vm.originalUserInteract ? vm.originalUserInteract: vm.showcase.userInteract;

                if(vm.showcase.banners != null && vm.showcase.banners.length > 0) {
                    vm.banners = vm.showcase.banners;
                    vm.haveBanners = true;
                    vm.showcase.banners.forEach(function(banner, i) {
                        vm.banners[i].image = vm.showcase.coverUrl.split("showcase")[0] + vm.banners[i].cover;

                        if (vm.banners[i].coverMobile) {
                            vm.banners[i].imageMobile = vm.showcase.coverUrl.split("showcase")[0] + vm.banners[i].coverMobile;
                        }
                    })
                } else if (vm.showcase.hasCover && vm.showcase.coverUrl !== '') {
                    checkImage(function (url) {
                        vm.banners.push({'id': 1, 'image': url, 'name': '', 'link': ''});
                        loadCarousel();
                    });
                    vm.haveBanners = true;
                }

                loadCarousel();
                loadShowcasesFlyers();
            });
        }

        function checkImage(callback) {
            var image = new Image();
            image.onload = function () {
                if (this.width > 0) {
                    callback(vm.showcase.coverUrl);
                }
            }
            image.onerror = function () {
                callback(DEFAULT_IMAGE);
            }
            image.src = vm.showcase.coverUrl;
        }

        vm.checkFlyerIsSelected = function (id) {
            if (vm.flyersMap) {
                return angular.isDefined(vm.flyersMap[id]);
            }
        };

        vm.changeFlyerSelected = function (flyer) {
            if (vm.flyersMap) {
                if (vm.flyersMap[flyer.id]) {
                    vm.showcase.flyers.splice(vm.flyersMap[flyer.id].pos, 1);
                    loadShowcasesFlyers();
                } else {
                    vm.showcase.flyers.splice(0, 0, flyer);
                    loadShowcasesFlyers();
                }
            } else {
                vm.showcase.flyers.splice(0, 0, flyer);
                loadShowcasesFlyers();
            }
        };

        vm.changeTemplate = function (templateId) {
            vm.templateIsLoading = true;
            vm.templateId = templateId;
            loadTemplate().then(function () {
                vm.showcase.showcaseTemplateId = templateId;
            });
        };


        vm.getImageSrc = function (templateId) {
            return 'content/images/templates/' + templateId + '.png';
        };


        function succesPrint(res) {

            var saveByteArray = (function() {
                return function(data, name, mimeType) {
                    var blob = new Blob(data, {
                        type: mimeType
                    });
                    var url = window.URL.createObjectURL(blob);
                    var element = angular.element('<a/>');
                    element.attr({
                        href: url,
                        target: '_blank',
                        download: name
                    });
                    if(document.createEvent) {
                        var ev = document.createEvent("MouseEvent");
                        ev.initMouseEvent("click", true /* bubble */, true /* cancelable */, window, null, 0, 0, 0, 0, /* coordinates */
                        false, false, false, false,
                        /*
                         * modifier keys
                         */
                        0 /* left */, null);
                        element[0].dispatchEvent(ev);
                    } else {
                        element[0].fireEvent("onclick");
                    }
                    setTimeout(function() {
                        document.body.removeChild;
                        window.URL.revokeObjectURL(url);
                    }, 200);
                };
            }());
            if (res.headers["content-disposition"]){
                var filename = res.headers["content-disposition"].match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)[1];
                var mimeType = res.headers["content-type"];
                filename = filename.replace(/['"]/g, '');
                saveByteArray([res.data], filename, mimeType);
            } else {
                toastr.error($translate.instant('showcases.messages.tabloid.error.erroByGenerateCatalog'));
            }

            vm.isPrinting = false;
            $rootScope.$emit('finish-generate-tabloid');
        }

        function errorPrint(response) {
            vm.isPrinting = false;
            $rootScope.$emit('finish-generate-tabloid');
            toastr.error($translate.instant('showcases.messages.tabloid.error.erroByGenerateCatalog'));

        }

        vm.generateTabloid = function(){
            vm.saveFlyer(false, function () {
                if (!vm.showcase.hasCover) {
                    toastr.error($translate.instant('showcases.messages.errors.blankCover'));
                    vm.isSaving = false;
                } else if (vm.showcase.flyers.length < 1) {
                    toastr.error($translate.instant('showcases.messages.errors.blankFlyers'));
                } else if (!vm.showcase.name) {
                    toastr.error($translate.instant('showcases.messages.errors.blankName'));
                } else {
                    vm.isPrinting = true;
                    buildMessageGeneratedTabloid();
                    $rootScope.$emit('waiting-generates-tabloid');
                    ShowcasesService.generateTabloid({
                        showcaseId: Number(vm.showcase.id) || undefined,
                        storeId: ( storeId ? storeId + "" : undefined) || undefined,
                        flyers: vm.showcase.flyers.map(function (flyer) {
                            return flyer.id;
                        })
                    }).then(succesPrint, errorPrint);
                }
            });
        }


        function loadShowcasesFlyers() {
            if (vm.showcase.flyers) {
                vm.flyerId = 0;
                if (vm.showcase.showcaseTemplateId) {
                    vm.templateId = vm.showcase.showcaseTemplateId;
                }
                vm.showcase.flyers.forEach(function (flyer) {
                    vm.getCategoryName(flyer);
                    var random = new Date().getTime();
                    flyer.imagePath = '/flyer/api/' + flyer.id + '/picture?type=large&ts=' + random;
                });
            }
            loadTemplate();
        }


        function loadTemplate() {
            return ShowcasesService.getTemplate(vm.templateId).then(function (response) {
                vm.template = response;
                vm.templateIsLoading = false;
            });
        }

        function openShowcaseModalType() {
            userInteractModal = UserInteractModalFactory.selectUserInterectType(storeId, vm.showcase.userInteract);
            userInteractModal.result.then(function (userInteract) {

                vm.originalUserInteract = angular.copy(vm.showcase.userInteract);
                vm.showcase.userInteract = userInteract;

                vm.listFilter.type = vm.showcase.userInteract;
                if (!(vm.originalUserInteract && vm.originalUserInteract === vm.showcase.userInteract)) {
                    vm.flyersInfo = null;
                    vm.flyersFiltered = [];
                    vm.flyersMap = undefined;
                    vm.showcase.flyers = [];
                    loadShowcasesFlyers();
                }
            });
        }

        function setShowcaseTypeDefault() {
            vm.showcase.userInteract = DEFAULT_TYPE;
            vm.listFilter.type = vm.showcase.userInteract;
        }

        vm.getShowcasesFlyer = function () {
            vm.saveFlyer(true);
        }

        vm.goToFlyerByShowkase = function() {
            if (vm.showcase && vm.showcase.id) {
                vm.preSaveFlyer(true);
            } else {
                vm.saveFlyer(true);
            }
        }

        vm.saveFlyerPreview = function (isRedirectToFlyersScreen, callback) {
            vm.showcase.showcaseTemplateId = vm.templateId;
            if (vm.showcase.flyers.length >= 1 && vm.showcase.name) {
                vm.isSaving = true;
                vm.showcase.cover = "";
                vm.saveCover(function () {
                    if (vm.showcase.addImageFileDTO.length > 0) {
                        var flyers = angular.copy(vm.showcase.flyers);
                        var flyersPosition = [];
                        vm.showcase.flyers.forEach(function (flyer) {
                            flyersPosition.push({
                                    flyerId: flyer.id,
                                    position: flyer.position
                                });
                        });
                        vm.showcase.flyers = flyersPosition;
                        $q.all(vm.showcase.flyers).then(function (data) {
                            if (vm.showcaseId) {
                                ShowcasesService.updateShowcase(vm.showcase).then(function (data) {
                                    if (isRedirectToFlyersScreen) {
                                        $state.go('showcases-flyers', {
                                            showcaseId: data.id
                                        });
                                    } else {
                                        toastr.success($translate.instant('showcases.messages.update.success'));
                                        vm.isSaving = false;
                                    }
                                    if (callback) {
                                        callback(true);
                                    }
                                    vm.showcase.flyers = flyers;
                                    vm.isSavingPreview = false;
                                }, function (data) {
                                    toastr.error($translate.instant('showcases.messages.update.error'));
                                    vm.isSavingPreview = false;
                                    vm.isSaving = false;
                                });
                            } else {
                                ShowcasesService.saveShowcase(vm.showcase).then(function (data) {
                                    vm.showcase = data;
                                    toastr.success($translate.instant('showcases.messages.create.success'));
                                    vm.isSavingPreview = false;
                                    if (callback) {
                                        callback(true);
                                    }
                                    vm.showcase.flyers = flyers;
                                }, function (data) {
                                    toastr.error($translate.instant('showcases.messages.create.error'));
                                    vm.isSavingPreview = false;
                                });
                            }
                        });
                    } else if (!vm.showcase.cover) {
                        toastr.error($translate.instant('showcases.messages.errors.blankCover'));
                    }
                });
            } else if (vm.showcase.flyers.length < 1) {
                toastr.error($translate.instant('showcases.messages.errors.blankFlyers'));
            } else if (!vm.showcase.name) {
                toastr.error($translate.instant('showcases.messages.errors.blankName'));
            }
        }

        vm.preSaveFlyer = function(isToRedirect) {
            if (vm.showcase && vm.showcase.id) {
                return ShowcasesService.getShowcase(vm.showcase.id, vm.showcase.ownerId).then(
                    function(response) {
                        vm._showcase = response;

                        var isAfter = moment(vm._showcase.updatedAt).isAfter(moment(vm.showcase.updatedAt)); // true

                        if (isAfter) {
                            ModalFactory.openSimpleModal(
                                "ATENÇÃO!",
                                "Identificamos alterações recentes na vitrine que você está prestes a alterar, deseja realmente confirmar suas alterações e descartar a versão anteriormente criada?",
                                "Confirmar",
                                "Cancelar",
                                function() {
                                    return vm.saveFlyer(isToRedirect);
                                }
                            );
                        } else {
                            vm.saveFlyer(isToRedirect);
                        }
                    }
                );
            } else {
                vm.saveFlyer(isToRedirect);
            }
        };


        vm.saveFlyer = function (isRedirectToFlyersScreen, callback, isSaving) {
            vm.showcase.showcaseTemplateId = vm.templateId;
            vm.isSaving = angular.isUndefined(isSaving) ? true : isSaving;
            vm.showcase.cover = "";
            vm.saveCover(function () {
                var flyers = angular.copy(vm.showcase.flyers);
                var flyersPosition = [];
                vm.showcase.flyers.forEach(function (flyer, index) {
                    flyersPosition.push({
                        flyerId: flyer.id,
                        position: index + 1
                    });
                });
                vm.showcase.flyers = flyersPosition;
                var banners = angular.copy(vm.showcase.addImageFileDTO);
                vm.showcase.addImageFileDTO.forEach(function (banner, index) {
                    banners[index].nameFile = vm.banners[index].name;
                    banners[index].action = vm.banners[index].link;
                });
                vm.showcase.addImageFileDTO = banners;

                $q.all(vm.showcase.flyers).then(function (data) {
                    if (vm.showcaseId) {
                        ShowcasesService.updateShowcase(vm.showcase).then(function (data) {
                            vm.showcase = data;
                            vm.banners = vm.showcase.banners;
                            vm.showcase.banners.forEach(function(banner, i) {
                                vm.banners[i].image = vm.showcase.coverUrl.split("showcase")[0] + vm.banners[i].cover;

                                if (vm.banners[i].coverMobile) {
                                    vm.banners[i].imageMobile = vm.showcase.coverUrl.split("showcase")[0] + vm.banners[i].coverMobile;
                                }
                            })

                            if (isRedirectToFlyersScreen) {
                                $state.go('showcases-flyers', {
                                    showcaseId: data.id,
                                    ownerId: data.ownerId
                                });
                            } else {
                                toastr.success($translate.instant('showcases.messages.update.success'));
                                vm.isSaving = false;
                                // Follow the instruction of Jackson, when saves the showcase the state doesn't need to be changed
                                // $state.go('showcases');
                            }
                            if (callback) {
                                callback(true);
                            }
                            vm.showcase.flyers = flyers;

                        }, function (data) {
                            if (isRedirectToFlyersScreen) {
                                toastr.error($translate.instant('showcases.messages.update.error'));
                                vm.isSaving = false;
                            }
                        });
                    } else {
                        ShowcasesService.saveShowcase(vm.showcase).then(function (data) {
                            vm.isSaving = false;
                            vm.showcase = data;
                            if (isRedirectToFlyersScreen) {
                                $state.go('showcases-flyers', {
                                    showcaseId: data.id,
                                    ownerId: data.ownerId
                                });
                            } else {
                                toastr.success($translate.instant('showcases.messages.create.success'));
                                // Follow the instruction of Jackson, when saves the showcase the state doesn't need to be changed
                                // $state.go('showcases');
                            }
                            if (callback) {
                                callback(true);
                                vm.isSaving = false;
                            }
                            vm.showcase.flyers = flyers;
                        }, function (data) {
                            vm.isSaving = false;
                            if (isRedirectToFlyersScreen) {
                                toastr.error($translate.instant('showcases.messages.create.error'));
                            }
                        });
                    }
                });
            });
        }

        vm.setBackgroundColor = function (color) {
            vm.showcase.backgroundColor = color;
        }

        vm.getCategoryName = function (flyer) {
            var category = _.find(vm.categories, function(el) {return el.key === flyer.categoryKey});
            if (category) {
                flyer.category = category.title;
            }
        }

        vm.getFlyerCurrency = function (currency) {
            return FlyersCurrencyValue[currency];
        }

        vm.loadFlyer = function () {
            vm.flyerId++;
            vm.flyersToLoad--;
        }

        vm.checkIfShowcasePublished = function () {
            if (vm.showcase.published) {
                return true;
            } else {
                return false;
            }
        }

        vm.getBackgroundImg = function (flyerId) {
            return ('\'/flyer/api/' + flyerId + '/picture\'');
        }

        function getStoreInfo() {
            return StoreService.getStore($localStorage.defaultStore.storeId);
        }

        getStoreInfo().then(function(response) {
            $localStorage.defaultStore.storeType = response.data.storeType;
            vm.store = response.data;
        });

        function showMessagePublishShowcase() {
            if (!$localStorage.defaultStore.storeNickname){
                getStoreInfo().then(function(response) {
                    $localStorage.defaultStore.storeNickname = response.data.nickname;
                    $localStorage.defaultStore.storeType = response.data.storeType;
                    vm.store = response.data;
                    buildMessagePublishShowcase();
                });
            } else {
                buildMessagePublishShowcase();
            }
        }

        function buildMessagePublishShowcase() {
            ModalFactory.openModal(null,
                $translate.instant('showcases.messages.publish.successTitle', {
                showcaseType: $translate.instant('global.showcaseTypes.' + vm.showcase.userInteract) }),
                $translate.instant('showcases.messages.publish.successSubtitle', {
                    url: 'http://' + $localStorage.defaultStore.storeNickname.toLowerCase() + "." + getDomainNameSufix($window, vm.store.storeType) }),
                null, $translate.instant('showcases.messages.publish.goToShowcase'),
                'OK', function(){$window.open('http://' + $localStorage.defaultStore.storeNickname.toLowerCase() + "." + getDomainNameSufix($window, vm.store.storeType), '_blank');},
                null, null, 'icon-ksc-showcasepublished');
        }

        function buildMessageGeneratedTabloid() {
            ModalFactory.openModal(null,
                'showcases.messages.tabloid.readyTabloid',
                null, null, 'OK', null, 'close',null, null, null,
                'showcases.messages.tabloid.ifNotDownloaded', 'showcases.messages.tabloid.clickHere',
            function(callback){
                ShowcasesService.generateTabloid({
                    showcaseId: Number(vm.showcase.id) || undefined,
                    storeId: ( storeId ? storeId + "" : undefined) || undefined,
                    flyers: vm.showcase.flyers.map(function (flyer) {
                        return flyer.id;
                    })
                }).then(function(res) {
                    var saveByteArray = (function() {
                        return function(data, name, mimeType) {
                            var blob = new Blob(data, {
                                type: mimeType
                            });
                            var url = window.URL.createObjectURL(blob);
                            var element = angular.element('<a/>');
                            element.attr({
                                href: url,
                                target: '_blank',
                                download: name
                            });
                            if(document.createEvent) {
                                var ev = document.createEvent("MouseEvent");
                                ev.initMouseEvent("click", true /* bubble */, true /* cancelable */, window, null, 0, 0, 0, 0, /* coordinates */
                                false, false, false, false,
                                /*
                                 * modifier keys
                                 */
                                0 /* left */, null);
                                element[0].dispatchEvent(ev);
                            } else {
                                element[0].fireEvent("onclick");
                            }
                            setTimeout(function() {
                                document.body.removeChild;
                                window.URL.revokeObjectURL(url);
                            }, 200);
                        };
                    }());
                    if (res.headers["content-disposition"]){
                        var filename = res.headers["content-disposition"].match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)[1];
                        var mimeType = res.headers["content-type"];
                        filename = filename.replace(/['"]/g, '');
                        saveByteArray([res.data], filename, mimeType);
                    } else {
                        toastr.error($translate.instant('showcases.messages.tabloid.error.erroByGenerateCatalog'));
                    }
                    vm.isPrinting = false;
                    callback();
                }, function(response) {
                    vm.isPrinting = false;
                    callback();
                });
            }, 'width: 143px; height: 170px;', 'https://showkase-prod.s3-sa-east-1.amazonaws.com/hub/system-images/'+$rootScope.urlImage+'/download-tabloide.png');
        }

        function storeOwnerHasMoipAccount() {
            Principal.storeOwnerHasMoipAccount().then(function (responseMoipAccount) {
                vm.storeOwnerHasMoipAccount = responseMoipAccount.data != null && responseMoipAccount.data.moipLogin != null;
            });
        }

        vm.openModalConfirmCatalogChange = function(showcaseId) {
            ModalFactory.openModal(null,
                $translate.instant('showcases.messages.publish.msgModal'),
                null,
                null, $translate.instant('showcases.home.buttons.publishShowcase'),
                $translate.instant('showcases.home.buttons.no'), function(){
                    vm.publishShowcase(showcaseId);
                },
                null, null, 'icon-warning', null, null, null, null, null, {'font-size': '1.6em'}, true);
        }

        vm.publishShowcase = function () {
            vm.isPublishing = true;
            if (!vm.showcase.hasCover && vm.coverEdit) {
                toastr.warning($translate.instant('showcases.messages.errors.blankCover'));
                vm.isSaving = false;
                vm.isPublishing = false;
            } else if (vm.showcase.flyers.length < 1 && !vm.showcase.customHtml) {
                toastr.warning($translate.instant('showcases.messages.errors.blankFlyersAndNullCustomHtml'));
                vm.isPublishing = false;
                vm.isSaving = false;
            } else if (!vm.showcase.name) {
                toastr.warning($translate.instant('showcases.messages.errors.blankName'));
                vm.isPublishing = false;
                vm.isSaving = false;
            } else {
                vm.saveFlyer(false, function () {
                    ShowcasesService.publishShowcase(vm.showcase.id).then(function(data) {
                        vm.isPublishing = false;
                        showMessagePublishShowcase();
                        $state.go('showcases');
                    }, function(data) {
                        if (data.headers("errorKey")){
                            toastr.error($translate.instant('showcases.messages.publish.errors.' + data.headers("errorKey")));
                        } else {
                            toastr.error($translate.instant('showcases.messages.publish.errors.genericError'));
                        }

                        vm.isPublishing = false;
                    });
                });
            }
        }

        vm.openInput = function (evt) {
            document.getElementById('fileInput').click();
        }

        vm.getPreviewURL = function (showcase) {
            if (showcase.id) {
                vm.openPreviewURL();
            } else {
                if (vm.saveFlyerPreview(false, function (data) {
                        if (data) {
                            $timeout(function (data) {
                                vm.openPreviewURL();
                            }, 1000);
                        }
                    }));
            }
        };

        vm.openPreviewURL = function () {
            $window.open('http://' + $localStorage.defaultStore.storeNickname + '.showkase.com.br/preview/' + vm.showcase.id, '_blank');
        };

        function dataURItoBlob(dataURI) {
            var byteString;
            if (dataURI.split(',')[0].indexOf('base64') >= 0)
                byteString = atob(dataURI.split(',')[1]);
            else
                byteString = unescape(dataURI.split(',')[1]);
            var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
            var ia = new Uint8Array(byteString.length);
            for (var i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
            }
            return new Blob([ia], {
                type: mimeString
            });
        }

        vm.saveCover = function (callback) {
            vm.showcase.addImageFileDTO = [];

            if(vm.banners && (vm.banners.length === 0 ||  (vm.banners.length === 1 && vm.banners[0].image.indexOf(DEFAULT_IMAGE) != -1) )) {
                vm.loadingImage = false;
                if (callback) {
                    callback();
                }
            } else {
                var arr = Array.from(Array(vm.banners.length).keys());
                var aux = [];

                var index = 0;
                var orderNumber = 0;
                var blob = dataURItoBlob(vm.banners[index].image);
                var mobileImage = vm.banners[index].imageMobile;
                saveFlyerMultiImage(blob, arr, aux, index, orderNumber, mobileImage, callback);
            }
        }

        function saveFlyerMultiImage(blob, arr, aux, i, orderNumber, hasMobileImage, callback) {
            if (i === vm.banners.length) return;

            hasMobileImage = vm.banners[i].imageMobile;
            blob = dataURItoBlob(vm.banners[i].image);

            FlyersService.saveFlyerMultiImage(blob, i).then(function (response) {
                $timeout(function () {
                    if (response.data) {
                        aux.push('');
                        vm.showcase.hasCover = true;
                        if (hasMobileImage) {
                            var blobImageMobile = dataURItoBlob(vm.banners[i].imageMobile);
                            orderNumber = orderNumber + 1;
                            FlyersService.saveFlyerMultiImage(blobImageMobile, orderNumber).then(function (responseMobile) {
                                if (responseMobile) {
                                    response.data.mobileImageFile = responseMobile.data;
                                    arr[response.data.orderNumber] = response.data;
                                    if (aux.length === vm.banners.length) {
                                        vm.showcase.addImageFileDTO = arr;
                                        callback();
                                    }
                                    i = i + 1;
                                    saveFlyerMultiImage(blob, arr, aux, i, orderNumber, hasMobileImage, callback);
                                }
                            });
                        } else {
                            response.data.hasMobileImage = false;
                            arr[response.data.orderNumber] = response.data;
                            orderNumber = orderNumber + 1;
                            if (aux.length === vm.banners.length) {
                                vm.showcase.addImageFileDTO = arr;
                                callback();
                            }
                            i = i + 1;
                            saveFlyerMultiImage(blob, arr, aux, i, orderNumber, hasMobileImage, callback);
                        }
                    } else {
                        toastr.error($translate.instant('flyers.messages.create.error'));
                    }
                    vm.loadingImage = false;
                });
            }, function (response) {
                toastr.error($translate.instant('flyers.messages.create.image.uploadError'));
                vm.loadingImage = false;
            });
        }

		window.onDesignPublishCallback = function (data) {
		  var exportUrl = data.exportUrl;
		  var designId = data.designId;
		  loadImageToCroppieByURLCanvas(exportUrl);
		  //sendDataViaAjax (exportUrl, designId);
		}

        function openBannersModal() {
            vm.openAddBannersModal();
        }

        function removeBanner(index) {
            var carousel = document.getElementById("carousel");

            vm.banners.splice(index, 1);
            if (vm.banners.length === 0) {
                vm.haveBanners = false;
                vm.showcase.hasCover = false;
                vm.coverEdit = true;

                while(carousel.firstChild) {
                    carousel.removeChild(carousel.firstChild);
                }

                $scope.$apply();
            } else {
                loadCarousel();
            }
        }

        function loadCarousel() {
            var carousel = document.getElementById("carousel");

            while(carousel.firstChild) {
                carousel.removeChild(carousel.firstChild);
            }

            var counter = vm.banners.length;
            if(counter > 0) {
                carousel.style.display = 'block';
                var reversedBanners = vm.banners.slice().reverse();
                reversedBanners.forEach(function (banner) {
                    var name = banner.name;
                    if (name.length > 20) {
                        name = name.slice(0, 20).concat('...');
                    } else if (name.length == 0) {
                        name = "[SEM NOME]"
                    }

                    var srcImage = vm.isMobile
                        ? (banner.imageMobile ? banner.imageMobile : banner.image)
                        : banner.image;
                    if (srcImage.split(',')[0].indexOf('base64') == -1) {
                        srcImage += '?dummy=' + new Date().getTime();
                    }
                    carousel.insertAdjacentHTML(
                        "afterbegin",
                            ' <div class="carousel__item" style=" height: 100%; width: 100%;"> ' +
                        '<img id="img" style=" width: 100%; object-fit: cover; max-height: 100%;" src="' + srcImage + '"  alt="">  ' +
                        '<div style="width: 100%; position: absolute; bottom: 0; left: 0; text-align: center;" class="hidden-xs hidden-sm"> ' +
                        '<div class="carousel__title col-md-5">' + counter + 'º Banner <span class="carrousel__banner__name"> - ' + name + ' </span> ' +
                        '<span class="fa fa-edit" style="z-index: 10; color: #5b5b5b !important;"> </span> ' +
                        '<span class="fa fa-trash" style="z-index: 10; color: #5b5b5b !important; margin-left: 8px"> </span> </div> </div> </img> </div> '
                        );
                    counter--;
                    });

                var items = carousel.querySelectorAll(".carousel__item");


                var buttonsHtml = Array.from(items, function () {
                    return '<span class="carousel__button"></span>';
                });

                carousel.insertAdjacentHTML(
                    "beforeend",
                    ' <div class="carousel__nav">' + buttonsHtml.join("") + ' </div> ' );

                var carouselButtons = carousel.querySelectorAll(".carousel__button");
                carouselButtons.forEach(function (button, i) {
                        button.addEventListener("click", function() {
                            // un-select all the items
                            items.forEach(function (item) {
                                item.classList.remove("carousel__item--selected")
                                });
                            carouselButtons.forEach(function (button) {
                                button.classList.remove("carousel__button--selected")
                                });

                            items[i].classList.add("carousel__item--selected");
                            button.classList.add("carousel__button--selected");
                            vm.selectedBanner = i;
                        });
                    });

                // Select the first item on page load
                items[0].classList.add("carousel__item--selected");
                carouselButtons[0].classList.add("carousel__button--selected");

                // ARROW BUTTONS
                if (reversedBanners.length > 1) {
                    carousel.insertAdjacentHTML(
                        "beforeend",
                        ' <div class="backward_button arrow_buttons col-md-1"> <i class="fa fa-chevron-left carousel_arrows"></i> </div> ' );

                    carousel.insertAdjacentHTML(
                        "beforeend",
                        ' <div class="forward_button arrow_buttons col-md-1" style="margin-left: 91%"> <i class="fa fa-chevron-right carousel_arrows"></i> </div> ' );

                    var backwardButton = carousel.querySelectorAll(".backward_button");
                    backwardButton.forEach(function (button, i) {
                        button.addEventListener("click", function (){
                            // un-select all the items
                            items.forEach(function (item) {
                                    item.classList.remove("carousel__item--selected")
                                });
                            carouselButtons.forEach(function (button) {
                                    button.classList.remove("carousel__button--selected")
                                });

                            var index = vm.selectedBanner -= 1;
                            if (index < 0) {
                                index = 0;
                            }
                            vm.selectedBanner = index;
                            items[index].classList.add("carousel__item--selected");
                            carouselButtons[index].classList.add("carousel__button--selected");
                        });
                    });

                    var forwardButton = carousel.querySelectorAll(".forward_button");
                    forwardButton.forEach(function (button, i) {
                        button.addEventListener("click", function () {
                            // un-select all the items
                            items.forEach(function (item) {
                                item.classList.remove("carousel__item--selected")
                            });
                            carouselButtons.forEach(function (button){
                                button.classList.remove("carousel__button--selected")
                            });

                            var index = vm.selectedBanner += 1;
                            if (index > reversedBanners.length - 1) {
                                index = reversedBanners.length - 1;
                            }
                            vm.selectedBanner = index;
                            items[index].classList.add("carousel__item--selected");
                            carouselButtons[index].classList.add("carousel__button--selected");
                        });
                    });
                }


                vm.selectedBanner = 0;

                // BUTTON ACTIONS
                var editButtons = carousel.querySelectorAll(".fa-edit");
                editButtons.forEach(function (button, i) {
                    button.addEventListener("click", function() {
                        openBannersModal();
                    });
                });

                var trashButtons = carousel.querySelectorAll(".fa-trash");
                trashButtons.forEach(function (button, i) {
                    button.addEventListener("click", function(){
                        removeBanner(i);
                    });
                });

                }
        }


		$scope.$on("$destroy", function () {
            if (!angular.isUndefined(userInteractModal)) {
                userInteractModal.close();
            }
        });

        vm.openAddBannersModal = function () {
            vm.filtersConversationModal = $uibModal.open({
                templateUrl: 'app/admin/showcases/banner-modal/banners_modal.html',
                controller: 'BannersModalController',
                windowClass: 'modal-center',
                windowTopClass: 'center-modal',
                controllerAs: 'vm',
                size: 'lg',
                keyboard: false,
                resolve: {
                    banners: function () {
                        return vm.banners
                    }
                },
            }).result.then(function (response) {
                if (response) {
                    vm.banners = response.banners;
                    vm.haveBanners = true;
                    vm.showcase.hasCover = true;
                    loadCarousel();
                }
            });
        }

        vm.openAddCustomHtmlModal = function () {
            $uibModal.open({
                templateUrl: 'app/admin/showcases/custom-html-modal/custom-html-modal.html',
                controller: 'CustomHtmlModalController',
                windowClass: 'modal-center',
                windowTopClass: 'center-modal',
                controllerAs: 'vm',
                size: 'lg',
                keyboard: false,
                resolve: {
                    customHtml: function () {
                        return vm.showcase.customHtml
                    }
                },
            }).result.then(function (response) {
                if (response) {
                    vm.showcase.customHtml = response.customHtml;
                }
            });
        }
    }
})();
