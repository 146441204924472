(function () {
    'use strict';

    angular
        .module('showcaseApp')
        .factory('UserFactory', UserFactory);

    UserFactory.$inject = ['$resource'];

    function UserFactory ($resource) {
        return $resource('api/users/:login', {}, {
            'query': {
                method: 'GET',
                isArray: false,
                transformResponse: function (data, headers) {
                    var response = {};
                    response.data = angular.fromJson(data);
                    response.headers = headers();
                    return response;
                }
            },
            'get': {
                method: 'GET',
                transformResponse: function (data, headers) {
                    var response = {};
                    response.data = angular.fromJson(data);
                    response.headers = headers();
                    return response;
                }
            },
            'listByParams': {
                method: 'POST',
                url: '/api/users/list',
                transformResponse: function (data, headers) {
                    var response = {};
                    response.data = angular.fromJson(data);
                    response.headers = headers();
                    return response;
                }
            },
            'addUser':{
                method: 'POST',
                url: '/api/users/add',
                transformResponse: function (data, headers) {
                    var response = {};
                    response.headers = headers();
                    return response;
                }
            },
            'save': { 
            	method:'POST', 
            	transformResponse: function (data, headers) {
                    var response = {};
                    if(data) {
                    	response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'update': { method:'PUT' },
            'delete':{ method:'DELETE'},
            'findByEmail': {
                method: 'GET',
                isArray: false,
                url: '/api/users/by-email/:email',
                transformResponse: function (data, headers) {
                    var response = {};
                    response.data = angular.fromJson(data);
                    response.headers = headers();
                    return response;
                }
            },
            'listUserGenders': {
            	method: 'GET',
                isArray: true,
            	url: '/api/users/genders',
            	transformResponse: function(data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'listUserGendersToSelectComponent': {
                method: 'GET',
                url: '/api/users/genders/select-component',
                transformResponse: function(data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'listUserSellers': {
            	method: 'GET',
            	url: '/api/users/list-all/:storeId',
                transformResponse: function (data, headers) {
                    var response = {};
                    response.data = angular.fromJson(data);
                    response.headers = headers();
                    return response;
                }
            },
        });
    }
})();
