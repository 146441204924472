(function() {
    'use strict';
    angular
        .module('showcaseApp')
        .controller('WhatsappQrCodeController', WhatsappQrCodeController);

    function WhatsappQrCodeController(
        $uibModalInstance,
        biWhatsappConfiguration,
        $localStorage,
        BusinessIntegrationService,
        $scope,
        toastr,
        $rootScope,
        CustomerService,
        ModalFactory,
        $translate,
        StoreInfoFactory,
        Upload
    ) {
        // 3 seconds of interval to fetch a new qrcode
        var WHATSAPP_FETCH_INTERVAL = 3000;

        var vm = this;

        $rootScope.fetchQrCodeInterval = null;

        vm.isLoading = true;
        vm.isRefreshing = false;

        vm.currentStep = 0;
        vm.totalOfSteps = 3;

        // 20 seconds in milliseconds
        vm.SPEND_TIME_PER_PHONE = 20000;

        vm.whatsappConf = null;
        vm.isWhatsappTabLoading = false;
        vm.isSending = false;
        vm.loadingCustomers = false;
        vm.selectedCustomers = [];
        vm.unselectedCustomers = [];
        vm.customers = [];

        vm.currentPage = 1;
        vm.numPerPage = 5;
        vm.maxSize = 5;
        vm.totalCustomers = 0;

        vm.wappDTO = {};
        vm.wappDTO.phones = [];
        vm.wappDTO.excludedPhones = [];
        vm.wappDTO.isAllCustomers = false;

        vm.startRegistry = 1;
        vm.endRegistry = vm.numPerPage;

        vm.filter = {};

        vm.paginationOptions = {
            page: vm.currentPage - 1,
            size: vm.numPerPage,
            sort: null
        };

        // Functions
        vm.selectCustomer = selectCustomer;
        vm.changePageCustomer = changePageCustomer;
        vm.sendMessage = sendMessage;
        vm.createNewClient = createNewClient;

        vm.whatsappModalConf = biWhatsappConfiguration;

        // Functions
        vm.refreshWhatsappQrCode = refreshWhatsappQrCode;
        vm.closeModal = closeModal;

        vm.showClientForm = showClientForm;
        vm.cancelCustomer = cancelCustomer;
        vm.searchByFilter = searchByFilter;

        vm.isClietFormVisible = false;

        function closeModal(method) {
            $uibModalInstance.dismiss(method);
        }

        function closeModalSuccessfully(method) {
            $uibModalInstance.close(method);
        }

        function whatsappGetConfigurationCallback(response) {
            vm.whatsappModalConf = response.data;
            if (vm.whatsappModalConf && vm.whatsappModalConf.whatsappQRCode) {
                vm.isLoading = false;
                vm.isRefreshing = false;
            }
            if (
                vm.whatsappModalConf &&
                vm.whatsappModalConf.whatsappConnected
            ) {
                clearInterval($rootScope.fetchQrCodeInterval);
                vm.next();
            } else if (
                vm.whatsappModalConf &&
                vm.whatsappModalConf.whatsappWaitTooLong
            ) {
                clearInterval($rootScope.fetchQrCodeInterval);
            }

            if (!$scope.$$phase) {
                $scope.$apply();
            }
        }

        function whatsappGetConfigurationErrorCallback() {
            toastr.error(
                $translate.instant(
                    'integrations.whatsapp.messages.errorGetConfiguration'
                )
            );
            clearInterval($rootScope.fetchQrCodeInterval);
            vm.isLoading = false;
        }

        function sendMessage() {
            vm.isSending = true;
            if (vm.uploadFileDTO) {
                vm.wappDTO.file = vm.uploadFileDTO;
            }
            if (
                !vm.wappDTO.isAllCustomers &&
                vm.selectedCustomers &&
                vm.selectedCustomers.length < 1
            ) {
                toastr.error(
                    $translate.instant(
                        'integrations.whatsapp.messages.noSelectedNumbers'
                    )
                );
                vm.isSending = false;
            } else if (!vm.wappDTO.message || vm.wappDTO.message == '') {
                toastr.error(
                    $translate.instant(
                        'integrations.whatsapp.messages.noMessage'
                    )
                );
                vm.isSending = false;
            } else if (
                !vm.wappDTO.campaingName ||
                vm.wappDTO.campaingName == ''
            ) {
                toastr.error(
                    $translate.instant(
                        'integrations.whatsapp.messages.noCampaingName'
                    )
                );
                vm.isSending = false;
            } else {
                createAndSendANewWhatsappJob(saveWhatsappJob);
            }
        }

        function searchByFilter() {
            vm.currentPage = 1;
            vm.paginationOptions.page = 0;
            listCustomers(vm.paginationOptions, vm.filter, true);
        }

        function createAndSendANewWhatsappJob(callback) {
            vm.wappDTO.storeId = $localStorage.defaultStore
                ? $localStorage.defaultStore.storeId || ''
                : '';
            vm.selectedCustomers.forEach(function(cus) {
                vm.wappDTO.phones.push(cus.phone);
            });
            if (callback) callback();
        }

        function saveWhatsappJobCallback() {
            ModalFactory.openWhatsappConfirmModal();
            clearData();

            closeModalSuccessfully(vm.whatsappModalConf);
            vm.isSending = false;
        }

        function createNewClient() {
            vm.isSaving = true;
            vm.customer.storeId = StoreInfoFactory.getStoreId();
            CustomerService.create(vm.customer).then(onSuccess, onError);

            function onSuccess() {
                vm.isSaving = false;
                vm.customer = {};
                listCustomers(vm.paginationOptions, vm.filter);
            }

            function onError(error) {
                vm.isSaving = false;
                if (error.data.headers['x-adminapp-params']) {
                    toastr.error(
                        $translate.instant(
                            error.data.headers['x-adminapp-params']
                                .concat('.')
                                .concat(error.data.headers['x-adminapp-error'])
                        )
                    );
                } else {
                    toastr.error(
                        $translate.instant('global.messages.somethingWrong')
                    );
                }
            }
        }

        function cancelCustomer() {
            vm.customer = {};
            showClientForm();
        }

        function saveWhatsappJobErrorCallback() {
            toastr.error(
                $translate.instant(
                    'integrations.whatsapp.messages.errorWhatsappJobSave'
                )
            );
            vm.isSending = false;
            clearData();
            closeModal();
        }

        function saveWhatsappJob(callback) {
            var wappDTO = vm.wappDTO;
            BusinessIntegrationService.createWhatsappJob(
                wappDTO,
                saveWhatsappJobCallback,
                saveWhatsappJobErrorCallback
            );
            if (callback) callback();
        }

        function fetchQrCode() {
            // Geeting initial configuration
            BusinessIntegrationService.getConfiguration(
                $localStorage.defaultStore
                    ? $localStorage.defaultStore.storeId || ''
                    : '',
                'WHATSAPP',
                whatsappGetConfigurationCallback,
                whatsappGetConfigurationErrorCallback
            );
        }

        function loadModal() {
            vm.isLoading = true;
            initiateLoginMode();

            if (!$scope.$$phase) {
                $scope.$apply();
            }
        }

        function showClientForm() {
            vm.isClietFormVisible = !vm.isClietFormVisible;
        }

        function initiateLoginMode() {
            vm.isLoading = true;
            if (
                !vm.whatsappModalConf ||
                !vm.whatsappModalConf.whatsappConnected
            ) {
                BusinessIntegrationService.initiateWhatsappSync(
                    $localStorage.defaultStore
                        ? $localStorage.defaultStore.storeId || ''
                        : '',
                    'LOGIN_MODE',
                    function() {
                        fetchQrCode();
                        $rootScope.fetchQrCodeInterval = setInterval(
                            fetchQrCode,
                            WHATSAPP_FETCH_INTERVAL
                        );
                        if (!$scope.$$phase) {
                            $scope.$apply();
                        }
                    },
                    function() {
                        toastr.error(
                            $translate.instant(
                                'integrations.whatsapp.messages.errorInitiateWhatsappLoginMode'
                            )
                        );
                        vm.isRefreshing = false;
                        vm.isLoading = false;
                        if (!$scope.$$phase) {
                            $scope.$apply();
                        }
                    }
                );
            }
        }

        function clearData() {
            vm.selectedCustomers = [];
            vm.unselectedCustomers = [];
            vm.wappDTO = {};
            vm.wappDTO.phones = [];
            vm.wappDTO.excludedPhones = [];
            vm.wappDTO.isAllCustomers = false;

            vm.customers.forEach(function(element) {
                element.selected = false;
            });
        }

        function changePageCustomer() {
            var begin = (vm.currentPage - 1) * vm.numPerPage,
                end = begin + vm.numPerPage;

            vm.paginationOptions.page = vm.currentPage - 1;

            vm.customers = listCustomers(vm.paginationOptions, vm.filter);
            vm.startRegistry = begin + 1;
            vm.endRegistry = end;
        }
        // List the customer using pagination
        function listCustomers(pagination, filter) {
            vm.loadingCustomers = true;
            CustomerService.list(pagination, filter).then(
                function(result) {
                    vm.totalCustomers = result.headers['x-total-count'];
                    vm.customers = result.data.slice();

                    // Sync all the new customers with selected customers
                    syncCustomerSelectedWithCustomers(
                        vm.customers,
                        vm.selectedCustomers
                    );
                },
                function(error) {
                    vm.loadingCustomers = false;
                }
            );
        }

        function refreshWhatsappQrCode() {
            vm.isRefreshing = true;
            vm.isLoading = true;
            clearInterval($rootScope.fetchQrCodeInterval);
            BusinessIntegrationService.clearWhatsappData(
                $localStorage.defaultStore
                    ? $localStorage.defaultStore.storeId || ''
                    : '',
                function() {
                    initiateLoginMode();
                },
                function() {
                    initiateLoginMode();
                }
            );
        }

        // Select or unselect the customer
        function selectCustomer(customer) {
            // Switch the customer selected variable
            // Here the customers must be unselected if the all customers option is true
            // otherwise, if customers is unselected by user and all customers option is true
            // then we have to add the customer to excludedCustomers list in wappDTO
            customer.selected = !customer.selected;

            // If customer is selected and allCustomer is true, so we don't need to insert customer into selectedCustomers list
            if (customer.selected && !vm.wappDTO.isAllCustomers) {
                var isPresent =
                    findIndexByAttr(vm.selectedCustomers, 'id', customer.id) >
                    -1;
                if (!isPresent) vm.selectedCustomers.push(customer);
            } else if (!customer.selected && vm.wappDTO.isAllCustomers) {
                vm.unselectedCustomers.push(customer);
            } else {
                removeElementFromArrayByAttr(
                    vm.selectedCustomers,
                    'id',
                    customer.id
                );
            }
        }
        vm.selectAllCustomers = function() {
            if (vm.wappDTO.isAllCustomers) {
                clearAllSelections();
            } else {
                vm.wappDTO.isAllCustomers = true;
                vm.selectedCustomers = [];
                vm.unselectedCustomers = [];
                vm.customers.forEach(function(elem) {
                    if (!elem.selected) {
                        selectCustomer(elem);
                    }
                });
            }
        };

        function loadWhatsapp() {
            vm.isWhatsappTabLoading = true;
            BusinessIntegrationService.getConfiguration(
                $localStorage.defaultStore
                    ? $localStorage.defaultStore.storeId || ''
                    : '',
                'WHATSAPP',
                whatsappGetConfigurationCallback,
                whatsappGetConfigurationErrorCallback
            );

            listCustomers(vm.paginationOptions, vm.filter);
        }

        loadWhatsapp();

        function clearAllSelections() {
            vm.wappDTO.isAllCustomers = false;
            vm.selectedCustomers = [];
            vm.unselectedCustomers = [];
            clearPageSelections();
        }

        function clearPageSelections() {
            vm.customers.forEach(function(elem) {
                if (elem.selected) {
                    selectCustomer(elem);
                }
            });
        }

        loadModal();

        // Sync the page with all previous selected customers
        function syncCustomerSelectedWithCustomers(
            customers,
            selectedCustomers
        ) {
            // If all customers is selected is only to turn selected variable to true in each customer
            if (vm.wappDTO.isAllCustomers) {
                customers.forEach(function(element) {
                    // Verify if customer is in unselected list
                    var isPresent =
                        findIndexByAttr(
                            vm.unselectedCustomers,
                            'id',
                            element.id
                        ) > -1;
                    if (!isPresent) {
                        // Here we turn selected variable to true or false;
                        selectCustomer(element);
                    }
                });
            } else {
                selectedCustomers.forEach(function(elm) {
                    var targetIndex = findIndexByAttr(customers, 'id', elm.id);
                    if (targetIndex > -1) {
                        customers[targetIndex].selected = true;
                    }
                });
            }

            vm.loadingCustomers = false;
        }

        vm.excludeImageUploaded = function() {
            vm.imageUpload = null;
            vm.uploadFileDTO = null;
        }

        vm.uploadFile = function(file, errFiles) {
            vm.imageUpload = file;
            vm.errFiles = errFiles && errFiles[0];

            if (file) {
                vm.isUploading = true;

                file.upload = Upload.upload({
                    url: 'admin/api/upload-whatsapp-media',
                    data: {
                        file: file
                    }
                });

                file.upload.then(
                    function(response) {
                        if (response.data) {
                            vm.uploadFileDTO = response.data;
                            vm.allStatusOk = true;

                            vm.isUploading = false;

                        } else {
                            vm.imageUpload = null;
                            toastr.warning(
                                $translate.instant(
                                    'integrations.whatsapp.qrCodeWa.stepTwo.fileUploadTryAgain'
                                )
                            );
                            vm.isUploading = false;
                        }
                    },
                    function(response) {
                        vm.isUploading = false;
                        vm.imageUpload = null;
                        toastr.error(
                            $translate.instant(
                                'integrations.whatsapp.qrCodeWa.stepTwo.fileUploadError'
                            )
                        );
                    }
                );
            }
        };

        vm.next = function() {
            vm.currentStep < vm.totalOfSteps
                ? (vm.currentStep = vm.currentStep + 1)
                : '';
        };

        vm.previous = function() {
            if (vm.currentStep === 1) {
                vm.selectedBusiness = null;
            }
            if (vm.currentStep === 2) {
                vm.selectedBusinessPage = null;
            }
            vm.currentStep !== 0 ? (vm.currentStep = vm.currentStep - 1) : '';
        };

        vm.nextDisabled = function() {
            switch (vm.currentStep) {
                case 0:
                    return (
                        !vm.whatsappModalConf ||
                        !vm.whatsappModalConf.whatsappConnected
                    );
                case 1:
                    return vm.wappDTO.message && vm.wappDTO.campaingName
                        ? false
                        : true;
                case 2:
                    return vm.selectedCustomers.length ||
                        vm.wappDTO.isAllCustomers
                        ? false
                        : true;
                default:
                    return false;
            }
        };
        vm.previousDisabled = function() {
            switch (vm.currentStep) {
                case 0:
                    return false;
                case 1:
                    return !vm.whatsappModalConf.whatsappConnected;
                case 2:
                    return true;
                default:
                    return false;
            }
        };
    }
})();
