(function() {
    'use strict';
    angular
        .module('showcaseApp')
        .service('FlyerCategoryService', FlyerCategoryService);

    FlyerCategoryService.$inject = ['FlyerCategoryFactory', '$localStorage'];

    function FlyerCategoryService(FlyerCategoryFactory, $localStorage) {
        var service = {
                createCategory: createCategory,
                updateCategory: updateCategory,
                deleteCategory: deleteCategory,
                listCategories: listCategories,
                listAllCategories: listAllCategories,
                getCategoryTitleByKey: getCategoryTitleByKey,
                listCategoriesByCurrentStore: listCategoriesByCurrentStore,
                createCategoryByStore: createCategoryByStore,
                listCategoriesByStoreId: listCategoriesByStoreId,
                listCategoriesWithFlyers: listCategoriesWithFlyers
        };

        return service;

        function getCategoryTitleByKey(categoryKey, categoryLang) {
            return FlyerCategoryFactory.getData({key: categoryKey, langKey: categoryLang}).$promise;
        }

        function createCategory(category) {
            return FlyerCategoryFactory.save(category).$promise;
        }

        function updateCategory(category) {
            return FlyerCategoryFactory.update(category).$promise;
        }

        function deleteCategory(categoryId) {
            return FlyerCategoryFactory.delete({id: categoryId}).$promise;
        }

        function listCategories(userLang) {
            return FlyerCategoryFactory.getJSON({langKey: userLang}).$promise;
        }

        function listCategoriesByCurrentStore(userLang) {
            return FlyerCategoryFactory.listAllByCurrentStore({langKey: userLang}).$promise;
        }

        function listAllCategories(pageable) {
            return FlyerCategoryFactory.listAll(pageable, {}).$promise;
        }

        function createCategoryByStore(category) {
            return FlyerCategoryFactory.createByStore(category).$promise;
        }

        function listCategoriesByStoreId(userLang, storeId) {
            return FlyerCategoryFactory.listCategoriesByStoreId({langKey: userLang, storeId: storeId}).$promise;
        }
        
        function listCategoriesWithFlyers(userLang){
            return FlyerCategoryFactory.listCategoriesWithFlyers({langKey: userLang}).$promise;
        }
    }
})();
