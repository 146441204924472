(function () {
    'use strict';
    angular
        .module('showcaseApp')
        .factory('SocialMediaFactory', SocialMediaFactory);

    SocialMediaFactory.$inject = ['$resource'];

    function SocialMediaFactory($resource) {
        var resourceUrl = '';
        return $resource(resourceUrl, {}, {
            'isFacebookConnected': {
                method: 'GET',
                url: 'admin/api/auth/facebook/isconnected',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'connectWithFacebook': {
                method: 'POST',
                url: 'admin/api/auth/facebook/connect',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'doAFacebookPost': {
                method: 'POST',
                url: 'admin/api/social/facebook/post',
                transformResponse: function (data, headers) {
                    var response = {};
                    response.data = data;
                    response.headers = headers();
                    return response;
                }
            },
            'facebookDisconnect': {
                method: 'GET',
                url: 'admin/api/auth/facebook/disconnect',
                transformResponse: function (data, headers) {
                    var response = {};
                    response.data = data;
                    response.headers = headers();
                    return response;
                }
            },
            'connectWithInstagram': {
                method: 'POST',
                url: 'admin/api/auth/instagram/connect',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'isInstagramConnected': {
                method: 'GET',
                url: 'admin/api/auth/instagram/isconnected',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'doAInstagramPost': {
                method: 'POST',
                url: 'admin/api/social/instagram/post',
                transformResponse: function (data, headers) {
                    var response = {};
                    response.data = data;
                    response.headers = headers();
                    return response;
                }
            },
            'instagramDisconnect': {
                method: 'POST',
                url: 'admin/api/auth/instagram/disconnect',
                transformResponse: function (data, headers) {
                    var response = {};
                    response.data = data;
                    response.headers = headers();
                    return response;
                }
            },
        });
    }

})();
