(function () {
    'use strict';

    angular
        .module('showcaseApp')
        .controller('GiftCardController', GiftCardController);

    function GiftCardController($localStorage, GiftCardService, CheckoutService, $translate, toastr) {

        var vm = this;

        vm.hubId = $localStorage.defaultStore.hubId;
        vm.giftSending = {};
        vm.sendGift = sendGift;
		vm.validateGiftCard = validateGiftCard;
		vm.giftCard = {};

        function getHubGiftCardsAvailable() {
            vm.isLoading = true;

            GiftCardService.cardsAvailable(vm.hubId).then(function (response) {
                vm.isLoading = false;
                vm.hubGifts = response.data;
            }, function (error) {
                vm.isLoading = false;
                vm.hubGifts = 0;
            });
        }

        function sendGift() {
            vm.giftSending.hubId = vm.hubId;
            if (vm.formGiftSending.$valid && !vm.isSending) {
                vm.isSending = true;

                GiftCardService.sendGiftCard(vm.giftSending).then(function (response) {
                    vm.isSending = false;
                    vm.hubGifts = response.data;

                    toastr.success($translate.instant('gift-card.send-success'));

                    //clear data
                    vm.giftSending = {};
                    vm.formGiftSending.$setPristine();

                    getHubGiftCardsAvailable();
                }, function (error) {
                    vm.isSending = false;

                    toastr.error($translate.instant('gift-card.send-error'));

                    getHubGiftCardsAvailable();
                });
            }
        }

		function validateGiftCard() {
            if (vm.formGiftValidate.$valid && !vm.isSending) {
                vm.isSending = true;
				GiftCardService.updateSubscriptionGiftCard(vm.giftCard).then(function (response) {
                    vm.isSending = false;
                    toastr.success($translate.instant('gift-card.validate-sucess'));
	            }, function (response) {
                    vm.isSending = false;
					if (response.headers()['x-error-validate']){
				    	toastr.error($translate.instant('gift-card.not-exists'));
				    }else if (response.headers()['x-error']){
	                    toastr.error($translate.instant('gift-card.validate-error'));
					}
	            });

            }
        }

        getHubGiftCardsAvailable();

    }
})();
