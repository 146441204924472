(function () {
    'use strict';

    angular
        .module('showcaseApp')
        .controller('TermOfUseController', TermOfUseController);

    function TermOfUseController($uibModalInstance, Auth, storeType, $localStorage) {
        var vm = this;
        vm.disableButtonSave = disableButtonSave;
        vm.acceptTermsMarkersChecked = false;
        vm.storeType = storeType;
        vm.hub = $localStorage.hub;

        function disableButtonSave() {
            return !vm.acceptTermsMarkersChecked;
        }

        vm.close = function () {
            $uibModalInstance.close();
        }

        vm.save = function () {
            var NEWSTATUS = true;
            Auth.acceptedTerm(NEWSTATUS)
                .then(function () {
                    vm.close();
                });
        }
    }
})();
