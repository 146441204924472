(function() {
    'use strict';

    angular
        .module('showcaseApp')
        .config(angulartoastrConfig);

    angulartoastrConfig.$inject = ['toastrConfig'];


    function angulartoastrConfig(toastrConfig) {
        angular.extend(toastrConfig, {
            autoDismiss: true,
            timeOut: 5000,
            preventOpenDuplicates: true
        });
    }
})();
