(function () {
    'use strict';

    angular
        .module('showcaseApp')
        .controller('CheckedController', CheckedController);

    function CheckedController($stateParams, toastr, CheckoutService, Auth, $state, $window) {

        var vm = this;

        vm.hasSuccess = undefined;
        vm.billetLink = null;
        vm.account = null;
        vm.isGeneratingBillet = false;

        function loadAll(){
            vm.hasSuccess = $stateParams.success;
            vm.billetLink = $stateParams.billetLink;
        }

        vm.openReceivedBilletLink = function(){
            if (vm.billetLink){
                $window.open(vm.billetLink, 'BILLET', 'height=800,width=600,scrollbars=yes');
            }            
        }

        vm.openBilletLink = function () {
            vm.isGeneratingBillet = true;
            CheckoutService.generateBillet().then(function (response) {
                if (response.headers['x-billet-href']) {
                    $window.open(response.headers['x-billet-href'] + '/print', 'BILLET', 'location=yes,height=800,width=600,scrollbars=yes');
                }
                vm.isGeneratingBillet = false;
            }, function (error) {
                vm.isGeneratingBillet = false;
                toastr.error($translate.instant('register.messages.error.emailexists'));
            });
        }

        vm.logout = function () {
            Auth.logout();
            $state.go('main-login');
        }

        loadAll();
    }

})();
