(function() {
    'use strict';
    angular
        .module('showcaseApp')
        .service('RentInvoicesService', RentInvoicesService);

        RentInvoicesService.$inject = ['RentInvoicesFactory', '$localStorage', 'Upload'];

    function RentInvoicesService(RentInvoicesFactory) {

        var service = {
            listAll: listAll,
            updateStatus: updateStatus,
        };

        return service;

        function listAll() {
            return RentInvoicesFactory.listAll().$promise;
        }

        function updateStatus(transation) {
            return RentInvoicesFactory.updateStatus({id: transation.transaction_id, status: transation.status}).$promise;
        }
    }

})();
