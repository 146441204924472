(function() {
    'use strict';
    angular
        .module('showcaseApp')
        .factory('HubFactory', HubFactory);

    HubFactory.$inject = ['$resource'];

    function HubFactory($resource) {
        var resourceUrl =  'api/hub';
        return $resource(resourceUrl, {}, {
            'migrateFlyersToStores': {
                method: 'POST',
                url: 'flyer/api/hub-flyer/migrate'
            },
            'query': {
                method: 'GET',
                isArray: false,
                transformResponse: function (data, headers) {
                    var response = {};
                    response.data = angular.fromJson(data);
                    response.headers = headers();
                    return response;
                }
            },
            'get': {
                method: 'GET',
                transformResponse: function (data, headers) {
                    var response = {};
                    response.data = angular.fromJson(data);
                    response.headers = headers();
                    return response;
                }
            },
            'getHubByOwner': {
                method: 'GET',
                url: 'admin/api/hub/owner/:id',
                transformResponse: function (data, headers) {
                    return  angular.fromJson(data);
                }
            },
            'deletebyids': {
                method: 'POST',
                url: '/api/store/deletebyids'
            },
            'getStores': {
                method: 'GET',
                url: 'admin/api/hub/stores',
                isArray: true
            },
            'getStoreTypes': {
                method: 'GET',
                url: 'admin/api/storetype/list',
                isArray: true
            },
            'saveRedirectDomains': {
                method: 'POST',
                url: '/admin/api/store/save-url-domains',
                transformResponse: function (data, headers) {
                    var response = {};
                    response.data = angular.fromJson(data);
                    response.headers = headers();
                    return response;
                }
            },
            'saveStoreAnalytics': {
                method: 'POST',
                url: '/admin/api/store/save-store-analytics',
                transformResponse: function (data, headers) {
                    var response = {};
                    response.data = angular.fromJson(data);
                    response.headers = headers();
                    return response;
                }
            },
            'getMyStores': {
                url: '/api/store/my',
                method: 'GET',
                transformResponse: function (data, headers) {
                    var response = {};
                    response.data = angular.fromJson(data);
                    response.headers = headers();
                    return response;
                }
            },
            'getMyAllStores': {
                url: '/admin/api/store/list-all',
                method: 'GET',
                transformResponse: function (data, headers) {
                    var response = {};
                    response.data = angular.fromJson(data);
                    response.headers = headers();
                    return response;
                }
            },
            'update': { method:'PUT' },
            'delete': { method:'DELETE' },
            'getHubByStoreId': {
                method: 'GET',
                url: 'admin/api/hub/store/:id',
                transformResponse: function (data, headers) {
                    return  angular.fromJson(data);
                }
            }
        });
    }
})();
