(function () {
    'use strict';

    angular
        .module('showcaseApp')
        .controller('SingleProductController', SingleProductController);

    SingleProductController.$inject = ['$uibModalInstance', '$window', '$stateParams', '$localStorage',
        'StoreService', '$translate', 'FlyersService', 'conversation',
        'FlyerCategoryService', 'ShowcasesService', 'toastr', 'WatiService',
        'communicationConstants', '$rootScope', '$timeout', '$scope'];

    function SingleProductController($uibModalInstance, $window, $stateParams, $localStorage,
        StoreService, $translate, FlyersService, conversation, FlyerCategoryService,
        ShowcasesService, toastr, WatiService, communicationConstants, $rootScope, $timeout, $scope) {

        StoreService.getStore($localStorage.defaultStore.storeId).then(function(response) {
            vm.storeCategory = response.data.categoryKey;
        });

        var vm = this;
        vm.isMobile = detectar_mobile();
        var storeId = $localStorage.defaultStore.storeId;
        vm.showcaseId = $stateParams.showcaseId;
        vm.isWati = conversation.platform === communicationConstants.SOCIAL_MEDIAS.WATI;
        vm.isCarousel = !vm.isWati;

        vm.currentPage = 1;
        vm.maxSize = 15;
        vm.numPerPage = vm.maxSize;
        vm.paginationOptions = {
            page: vm.currentPage - 1,
            size: vm.numPerPage,
            sort: null
        };

        var FLYER_ID_MARKER = "{{flyerName}}-SHW_{{flyerId}}_KSE";

        vm.colors = [];
        vm.sizes = [];
        vm.excludes = [];
        vm.featureds = [];
        vm.tags = [];
        vm.genders = [];
        vm.isActive = false;
        vm.file = {};
        vm.flyersToSend = [];

        vm.selected = {};
        vm.selectedProductsLen = function () {
            return Object.keys(vm.selected).length;
        }

        vm.filters = {
            brands: [],
            colors: [],
            sizes: [],
            categories: [],
            genders: [],
            tags: [],
            storeId: storeId
        }
        vm.originalFlyers = [];

        vm.watiMessages = [];

        vm.filtersLen = 0;
        vm.getFiltersLen = function () {
            vm.filtersLen = 0;
            for (var filter in vm.filters) {
                if (Array.isArray(vm.filters[filter])) {
                    vm.filtersLen += vm.filters[filter].length;
                }
            }

        }

        vm.changeActive = function () {
            vm.isActive = !vm.isActive;
        }

        vm.closePreferences = function () {
            $("btn-group preferences open").removeClass("open");
        }

        vm.closeDropDown = function (event, level) {
            $("md-backdrop").trigger('click');
        }

        vm.getSelectedTextGender = function (filter) {
            var genderTranslate = '';
            if (filter[0] === 1) {
                genderTranslate = $translate.instant('flyers.new.form.gender.male');
            }
            if (filter[0] === 2) {
                genderTranslate = $translate.instant('flyers.new.form.gender.female');
            }
            if (filter[0] === 3) {
                genderTranslate = $translate.instant('flyers.new.form.gender.both');
            }
            if (filter.length == 1) {
                return '<span class="highlight">' + genderTranslate + '</span>';
            }
            if (filter.length > 1) {
                var temp = '  +';
                temp += filter.length - 1;
                temp += ' selecionados';
                return '<span class="highlight">' + genderTranslate + '</span>' +
                    '<span>&nbsp</span>' +
                    '<span class="highlight">' + temp + '</span>';
            }
            return '';
        }

        vm.getSelectedTextCategory = function (filter) {
            for (var i = 0; i < vm.categories.length; i++) {
                if (vm.categories[i].key === filter[0]) {
                    if (filter.length == 1) {
                        return '<span class="highlight">' + vm.categories[i].title + '</span>';
                    }
                    if (filter.length > 1) {
                        var temp = '  +';
                        temp += filter.length - 1;
                        temp += ' selecionados';

                        return '<span class="highlight">' + vm.categories[i].title + '</span>' +
                            '<span>&nbsp</span>' +
                            '<span class="highlight">' + temp + '</span>';
                    }
                    return '';
                }
            }
            return '';
        }

        vm.getSelectedText = function (filter) {
            var elementTranslate = '';

            if (filter[0] === 'launch' || filter[0] === 'basic') {
                elementTranslate = $translate.instant('flyers.new.form.configurations.' + filter[0].toLowerCase());
            } else {
                elementTranslate = filter[0];
            }
            if (filter.length == 1) {
                return '<span class="highlight">' + elementTranslate + '</span>';
            }
            if (filter.length > 1) {
                var temp = '  +';
                temp += filter.length - 1;
                temp += ' selecionados';

                return '<span class="highlight">' + elementTranslate + '</span>' +
                    '<span>&nbsp</span>' +
                    '<span class="highlight">' + temp + '</span>';
            }
            return '';

        }

        vm.checkIfFilterSelected = function (filterKey, filter) {
            return (vm.filters[filterKey] ? vm.filters[filterKey] : []).indexOf(filter) >= 0 ? true : false;
        }

        vm.updateFilterOrRemove = function (filterKey, filter) {
            if (!vm.filters[filterKey]) {
                vm.filters[filterKey] = [];
            }
            var index = vm.filters[filterKey].indexOf(filter);
            if (index === -1) {
                vm.filters[filterKey].push(filter);
                return;
            }
            vm.filters[filterKey].splice(index, 1);
        }

        vm.hasSome = function (list, arr) {
            if (arr) {
                return arr.some(function (v) {
                    return list.indexOf(v.id) >= 0;
                });
            }
        };

        vm.makeTagsToFilter = function () {
            vm.filters.tags = vm.filters.tags ? vm.filters.tags.map(function (item) { return item.text ? item.text : item }) : [];
        }

        vm.getCategoryName = function (flyer) {
            var category = _.find(vm.categories, function (el) { return el.key === flyer.categoryKey });
            if (category) {
                flyer.category = category.title;
            }
        }

        vm.getTranslateType = function (type) {
            return 'flyers.new.form.userInteract.types.' + type.toLowerCase();
        }

        vm.getTranslateFeatureds = function (featured) {
            return 'flyers.new.form.configurations.' + featured.toLowerCase();
        }

        vm.getTranslateGenders = function (gender) {
            if (gender === 1) {
                return 'flyers.new.form.gender.male';
            }
            if (gender === 2) {
                return 'flyers.new.form.gender.female';
            }
            if (gender === 3) {
                return 'flyers.new.form.gender.both';
            }
            return '';
        }
        
        init();

        function init() {
            loadStore();
            loadAll();
        }

        function loadAll() {
            FlyersService.getAllFiltersFlyer().then(function (response) {
                vm.brands = response.data.brands;
                vm.colors = response.data.colors;
                vm.sizes = response.data.sizes;
                vm.types = response.data.types;
                vm.featureds = response.data.features;
                vm.tags = response.data.tags;
                vm.genders = response.data.genders;
            });

            FlyerCategoryService.listCategoriesWithFlyers($translate.use()).then(function (response) {
                vm.categories = response.data;
            });

            ShowcasesService.findFlyerByFilterBag(vm.paginationOptions, vm.filters).then(function (response) {
                vm.flyersFiltered = response.content;
                vm.originalFlyers = vm.flyersFiltered;
            });

        }

        function getStoreInfo() {
            return StoreService.getStore($localStorage.defaultStore.storeId);
        }

        function loadStore() {
            getStoreInfo().then(function (response) {
                if (response.data) {
                    vm.store = response.data;
                    $localStorage.defaultStore.storeType = response.data.storeType;
                    $localStorage.defaultStore.storeNickname = response.data.nickname;
                    loadCurrencyPrefix(response.data)
                }
            });
        }

        function loadCurrencyPrefix(store) {
            var key = store.currency ? store.currency : 'REAL';
            var config = getCurrencyListConfiguration()[key];
            vm.currencyPrefix = config.prefix;
        }

        vm.getCurrencyPrefix = function(flyer) {
            var key = flyer.currency ? flyer.currency : 'REAL';
            var config = getCurrencyListConfiguration()[key];
            return config.prefix;
        }

        vm.totalFlyers = 0;
        vm.getTotalFlyers = function () {
            if (vm.flyersFiltered !== undefined && vm.selected !== undefined) {
                return vm.flyersFiltered.length - (vm.flyersFiltered.length - Object.keys(vm.selected).length);
            }

            return 0;
        }

        vm.flyersSelected = function () {
            if (vm.flyersFiltered) {
                return Object.keys(vm.selected).length === vm.flyersFiltered.length;
            } else {
                return false;
            }
        }

        vm.refreshSelectedFlyers = function (flyer) {
            if (flyer.id in vm.selected) {
                delete vm.selected[flyer.id]
            } else {
                if (vm.selectedProductsLen() < 10) {
                    vm.selected[flyer.id] = flyer
                }
            }
            vm.totalFlyers = vm.getTotalFlyers();
        }

        vm.checkIfFlyerSelected = function (idFlyer) {
            return idFlyer in vm.selected;
        }

        function flyerExists(id, arr) {
            return arr.some(function (flyer) {
                return flyer.id === id;
            });
        }

        vm.searchFlyers = function () {

            if (vm.filters.text === '') {
                vm.flyersFiltered = vm.originalFlyers
                for (var flyerId in vm.selected) {
                    if (!(flyerExists(flyerId, vm.flyersFiltered))) {
                        vm.flyersFiltered.push(vm.selected[flyerId])
                    }
                }
            } else {
                vm.currentPage = 0;
                vm.paginationOptions.page = vm.currentPage;
                FlyersService.listAllToAdmin(vm.paginationOptions, vm.filters, function (data, headers) {
                    vm.flyersFiltered = data.content;
                }, function (_) { });
            }

        }

        vm.filterFlyers = function () {
            vm.currentPage = 0;
            vm.paginationOptions.page = vm.currentPage;
            scrollToTop();
            ShowcasesService.findFlyerByFilterBag(vm.paginationOptions, vm.filters).then(function (response) {
                vm.flyersFiltered = response.content;
                vm.originalFlyers = vm.flyersFiltered;
            });

            vm.getFiltersLen();
            $('.btn-group.preferences').removeClass('open');
            $('.dropdown-backdrop').css('display', 'none');
            vm.changeActive();

        };

        function scrollToTop() {
            $timeout(function () {
                var scroller = document.getElementById("scroll-div");
                if (scroller) {
                    scroller.scrollTop = 0;
                }
            }, 0, false);
        }

        vm.flyerScrollToBottom = function () {
            vm.currentPage += 1;
            vm.paginationOptions.page += 1;
            ShowcasesService.findFlyerByFilterBag(vm.paginationOptions, vm.filters).then(function (response) {
                var temp = response.content;
                vm.flyersFiltered = vm.flyersFiltered.concat(temp)
            })
        }

        vm.closeModal = function (url) {
            $uibModalInstance.close(url);
        }

        vm.sendProducts = function () {
            var finalFlyers = [];
            for (var flyerId in vm.selected) {
                finalFlyers.push(vm.selected[flyerId])
            }

            if (vm.isWati) {
                sendWatiSingleProducts(finalFlyers);
            } else {
                if (!$localStorage.defaultStore.storeNickname) {
                    getStoreInfo().then(function (response) {
                        vm.closeModal({ flyers: finalFlyers, isCarousel: vm.isCarousel });
                    });
                } else {
                    vm.closeModal({ flyers: finalFlyers, isCarousel: vm.isCarousel });
                }
            }
        }

        function toDataUrl(url, flyer) {
            return new Promise(function (resolve, reject) {
                var xhr = new XMLHttpRequest();
                xhr.onload = function() {
                    var reader = new FileReader();
                    reader.onloadend = function() {
                        resolve({base64: reader.result, flyer: flyer, url: url});
                    }
                    reader.readAsDataURL(xhr.response);
                };
                xhr.open('GET', url);
                xhr.responseType = 'blob';
                xhr.send();
            })
        }

        vm.getUrlImage = function (message) {
            if (message.isServer && message.fullData) {
                return message.fullData + '&token=' + WatiService.replaceWatiToken(vm.watiSetting.watiToken);
            } else if (message.isServer) {
                return WatiService.makeUriToShowImageByWati(vm.watiSetting.watiUri, message.data, vm.watiSetting.watiToken);
            }
            return message.data.fullData.fullBase64;
        }

        function formatWatiMessages(mediaMessage) {
            var imageMessage = {
                owner: true,
                type: mediaMessage.type,
                data: mediaMessage.media,
                text: mediaMessage.media.caption,
                isServer: false,
                created: moment().valueOf()
            };
            imageMessage.data = vm.getUrlImage(imageMessage);
            vm.watiMessages.push(imageMessage);

        }

        function sendMultipleMessagesToWhatsapp(messageList) {
            WatiService.sendMultipleMessages(messageList).then(function () {
                messageList.forEach(function (mediaMessage) {
                    formatWatiMessages(mediaMessage);
                });
                vm.closeModal({ flyers: vm.flyersToSend, isCarousel: vm.isCarousel, watiMessages: vm.watiMessages });
                vm.isSendingMessage = false;

            }).catch(function (error) {
                vm.isSendingMessage = false;
                toastr.warning($translate.instant('communication.errors.watiConfiguration'));
            });
        }

        function getFlyerUrl(flyer) {
            return 'https://' + $rootScope.bucket + '.s3-sa-east-1.amazonaws.com/flyer/' + storeId + '/400x400/' + flyer.id + '.jpg';
        }

        function formatCaption(flyer) {
            var caption = '';
            caption = '*' + flyer.name + '* - ';
            var price = flyer.purchaseValue;
            price = price.toLocaleString('pt-br', {minimumFractionDigits: 2});

            if (flyer.discountedValue) {
                var discountedPrice = flyer.discountedValue;
                caption = caption + '*R$ ' + discountedPrice.toLocaleString('pt-br', {minimumFractionDigits: 2}) + '*';
                price = '~' + price + '~';
            }
            caption = caption + ' R$ ' + price;
            caption = caption + ' \n\n ';

            var cleanName = flyer.name.normalize("NFD").replace(/[\u0300-\u036f]/g, '').replace(/[^\w\d]/g, '-');

            caption = caption + $window.location.protocol + '//' + $localStorage.defaultStore.storeNickname.toLowerCase()
                + "." + getDomainNameSufix($window) + '/flyer/'
                + FLYER_ID_MARKER.replace('{{flyerName}}', cleanName).replace('{{flyerId}}', flyer.id) ;
            caption = caption.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
            return caption
        }

        function formatSetting(fullBase64, url) {
            var setting = makeSettingByBase64(fullBase64);
            if (setting.type != communicationConstants.TYPES.IMAGE.toLowerCase()) {
                setting.type = communicationConstants.TYPES.IMAGE.toLowerCase();
                var splitedUrl = url.split('.')
                setting.mimeType = 'image/' + splitedUrl[splitedUrl.length - 1]
            }

            if (setting.mimeType.split('/')[1] == 'jpg') {
                setting.mimeType = setting.mimeType.split('/')[0] + '/jpeg';
            }
            return setting
        }

        function makePromiseFlyersImages(flyers) {
            return new Promise(function (resolve, reject) {
                var requests = [];
                flyers.forEach(function (flyer) {
                    requests.push(toDataUrl(getFlyerUrl(flyer), flyer))
                })
                Promise.all(requests).then(function (result) {
                    result.forEach(function (obj) {
                        vm.file = {};
                        if (obj.base64.slice(5, 9) == 'imag'){
                            obj.base64 = obj.base64.slice(0, 5) + 'image' + obj.base64.slice(9, obj.base64.length)
                        }
                        vm.file.fullBase64 = obj.base64;
                        vm.file.base64 = vm.file.fullBase64.split(',')[1];
                        var setting = formatSetting(vm.file.fullBase64, obj.url);
                        vm.file.type = setting.type;
                        vm.file.mimeType = setting.mimeType;
                        vm.file.name = obj.flyer.name;
                        vm.flyersToSend.push({number: conversation.chatUserId, text: undefined, media: { data: vm.file.base64, mimeType: vm.file.mimeType, caption: formatCaption(obj.flyer), fullData: vm.file }, type: "image"})

                    })
                    resolve();
                })
            })
        }

        vm.isSendingMessage = false;

        function sendWatiSingleProducts(flyers) {
            vm.isSendingMessage = true;
            makePromiseFlyersImages(flyers).then(function () {
                sendMultipleMessagesToWhatsapp(vm.flyersToSend);
            })
        }

        /**
         * Extracts information of the base64, as extension (png/jpg/jpeg,pdf), mimeType (image/png or application/pdf).
         * @param {*} base64
         * @returns
         */
        function makeSettingByBase64(base64) {
            var base = base64.split(';')[0];
            var types = communicationConstants.FILE_TYPES;
            return {
                type: types.find(function (type) {
                    return base.split('/')[1] === type.source;
                }).target, mimeType: base.split(':')[1]
            };
        }

    }
})();
