(function () {
    'use strict'

    angular
        .module('showcaseApp')
        .controller('UserInteractModalConstroller', UserInteractModalConstroller);

    /** @ngInject */
    function UserInteractModalConstroller($uibModalInstance, storeId, StoreService, storeOwnerHasMoipAccount, selectedType,
                                          $state, isStoreOwner, backUrl) {

        var vm = this;

        vm.selectedType = angular.isUndefined(selectedType) ? undefined : selectedType;
        vm.intentionType = vm.selectedType

        vm.selectIntentionType = selectIntentionType;
        vm.goToSettings = goToSettings;
        vm.goToDetail = goToDetail;
        vm.goBack = goBack;
        vm.cancel = cancel;
        vm.next = next;
        vm.isStoreOwner = isStoreOwner.data;
        vm.storeOwnerHasMoipAccount = storeOwnerHasMoipAccount;


        if (storeId) {
            StoreService.getStore(storeId).then(function (response) {
                vm.store = response.data;
            });
        }

        function selectIntentionType(type) {
            vm.intentionType = type;
        }

        function next() {
            $uibModalInstance.close(vm.intentionType);
        }

        function cancel() {
            $uibModalInstance.close(vm.selectedType);
        }

        function goBack() {
            $state.go(backUrl);
        }

        function goToSettings() {
            $state.go('payment-settings', {tab: 2});
        }

        function goToDetail() {
            $state.go('store-detail');
        }
    }

})();
