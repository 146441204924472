(function() {
    'use strict';
    angular
        .module('showcaseApp')
        .factory('ChatUnreadMessageFactory', ChatUnreadMessageFactory);

        ChatUnreadMessageFactory.$inject = ['$resource'];

    function ChatUnreadMessageFactory($resource) {
        var resourceChatUnreadMessage =  'admin/api/chat-unread-messages';
        var resourceUpdateChatUnreadMessageById = resourceChatUnreadMessage + '/:id';
        var resourceSumChatUnreadMessage = resourceChatUnreadMessage + '/:socialNetwork';
        var resourceGetChatUnreadMessage = resourceChatUnreadMessage + '/chat-id/:chatId';

        return $resource(resourceChatUnreadMessage, {}, {
            'getChatUnreadMessage': {
                method: 'POST',
                url: resourceGetChatUnreadMessage,
                isArray: false,
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data){
                        response = angular.fromJson(data);
                    }
                    return response;
                }
            },
            'updateChatUnreadMessage':{
                method: 'PUT',
                url: resourceUpdateChatUnreadMessageById,
                isArray: false,
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data){
                        response = angular.fromJson(data);
                    }
                    return response;
                }
            },
            'getUnreadMessagesSum':{
                method: 'GET',
                url: resourceSumChatUnreadMessage,
                isArray: false,
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data){
                        response = angular.fromJson(data);
                    }
                    return response;
                }
            }
        });
    }

})();
