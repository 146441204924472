(function() {
    'use strict';
    angular
        .module('showcaseApp')
        .factory('SaleReportFactory', SaleReportFactory);

    //** NgInject */
    function SaleReportFactory($resource) {
        var resourceUrl = '';

        return $resource(resourceUrl, {}, {

            'listUsersRegisteredByGenderWithSaleGeneratedByStore': {
                method: 'POST',
                isArray: true,
                url: '/api/transaction/sale/report/users-by-gender',
                transformResponse: function(data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'listMostLikedCategories': {
                method: 'POST',
                url: '/flyer/api/transaction/sale/report/most-liked-categories',
                transformResponse: function(data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'listUsersPerformMoreSalesTransactions': {
                method: 'POST',
                isArray: true,
                url: '/api/transaction/sale/report/users-perform-more-sales-transactions',
                transformResponse: function(data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'listUsersFirstInteraction': {
                method: 'POST',
                isArray: true,
                url: 'flyer/api/transaction/sale/report/users-first-interaction',
                transformResponse: function(data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            }

        });
    }

})();
