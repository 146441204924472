(function() {
    'use strict';
    angular
        .module('showcaseApp')
        .factory('SettingsFactory', SettingsFactory);

    SettingsFactory.$inject = ['$resource'];

    function SettingsFactory($resource) {
        var resourceUrl = 'admin/api/notifications';
        return $resource(resourceUrl, {}, {
            'getKey': {
                method: 'GET',
                isArray: false,
                url: 'admin/api/notifications/:accountId/key'
            },
            'getRegister': {
                method: 'GET',
                isArray: false,
                url: 'admin/api/notifications/:accountId/:storeId'
            },
            'registerToReceiveNotifications': {
                method: 'POST',
                isArray: false,
                url: resourceUrl
            },
            'unregisterToReceiveNotifications': {
                method:'DELETE',
                isArray: false,
                url: 'admin/api/notifications/:accountId'
            },
            'updateSystemNotifications': {
                method:'POST',
                isArray: false,
                url: 'admin/api/notifications/:accountId/system'
            },
            'updateEmailNotifications': {
                method:'POST',
                isArray: false,
                url: 'admin/api/notifications/:accountId/email'
            },
        });
    }
})();
