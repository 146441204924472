(function () {
    'use strict';

    angular
        .module('showcaseApp')
        .controller('CheckoutController', CheckoutController);

    function CheckoutController($translate, CheckCEPService, Auth,
                                    CheckoutService, $state, $localStorage,
                                    toastr, Principal, plans, account, $window,
                                    $timeout, $stateParams, $filter, $uibModal, moment) {

        var vm = this;
        vm.maskLocale = '99/99/9999';
        vm.changeState = changeState;
        vm.hubId = $localStorage.defaultStore.hubId;
        vm.hubURL = $localStorage.defaultStore.hubURL;
		vm.visibleAlert = false;
        vm.periodSelected = 'annually';
        var mainColor = getComputedStyle(document.documentElement).getPropertyValue('--main-color');
        vm.mainColor = mainColor;
        vm.paymentUrl = ""

        var copyAccount = function (account) {
            if (account){
                return {
                    activated: account.activated,
                    hasMoipPlan: account.hasMoipPlan,
                    isMoipPlanFree: account.isMoipPlanFree,
                    statusPaymentSignature: account.statusPaymentSignature,
                    isExpired: account.isExpired,
                    billetLink: account.billetLink,
                    firstName: account.firstName
                };
            }

            return null;
        };

        vm.isGeneratingBillet = false;

        vm.hasSuccess = false;
        vm.loggedAccount = copyAccount(account);
        vm.selectedPlan = undefined;
        vm.invalidCardNumber = true;
        vm.invalidExpirationDate = true;
        vm.brandCard = undefined;
        vm.isLoading = false;
        vm.isSaving = false;
        vm.isValidatingCoupon = false;
        vm.expirationDate = undefined;
        vm.checkout = {
            paymentMethod: '',
        };
        vm.gettedPlans = undefined;
        vm.creditCardInfoIsValid = true;

        vm.checkedBoleto = false;
        vm.checkedCreditCard = false;
        vm.boletoSpanStyle = undefined;
        vm.creditCardSpanStyle = undefined;
        vm.showBilletOption = $stateParams.payment === 'billet';

        vm.logout = undefined;

		vm.isVisibleForm = function(){
			if (!vm.loggedAccount.isMoipPlanFree && vm.loggedAccount.hasMoipPlan && ["PAGO", "ATRASADA", "NAO_PAGO", "CANCELADA", "AGUARDANDO_CONFIRMACAO"].includes(vm.loggedAccount.statusPaymentSignature)){
				return false;
			} else if (vm.loggedAccount.hasMoipPlan && vm.loggedAccount.isMoipPlanFree){
				return true;
			} else if (vm.loggedAccount.hasMoipPlan && vm.loggedAccount.isExpired){
				return true;
			} else if (!vm.loggedAccount.hasMoipPlan){
				return true;
			}
		}

        vm.logout = function logout() {
            Auth.logout();
            $timeout(function(){
                $state.go('main-login');
            }, 200);

        }

		function insertMessageAlert(message){
			vm.messageAlert = message;
			vm.visibleAlert = true;
		}

        vm.getNewBillet = function () {
            vm.isGeneratingBillet = true;
            CheckoutService.generateBillet().then(function (response) {
                vm.isGeneratingBillet = false;
                if (response.headers['x-billet-href']) {
                    $window.open(response.headers['x-billet-href'] + '/print', 'BILLET', 'location=yes,height=800,width=600,scrollbars=yes');
                }
            }, function (response) {
                vm.isGeneratingBillet = false;
                insertMessageAlert($translate.instant('checkout.afterPages.hasPlan.error.' + response.headers()['x-error']));
            });
        }


        vm.resetBillet = function () {
            vm.isGeneratingBillet = true;
            CheckoutService.generateBillet().then(function (response) {
                vm.isGeneratingBillet = false;
                if (response.headers['x-billet-href']) {
                    $window.open(response.headers['x-billet-href'] + '/print', 'BILLET', 'location=yes,height=800,width=600,scrollbars=yes');
                }
            }, function (response) {
                vm.isGeneratingBillet = false;
                insertMessageAlert($translate.instant('checkout.afterPages.hasPlan.error.' + response.headers()['x-error']));
            });
        }

        function loadCountrysAndStates() {
            CheckCEPService.listStates().then(function(response) {
                vm.states = response.data;
                vm.checkout.country = 'BRA';
            });
            CheckCEPService.listCountrys().then(function(response) {
                vm.countrys = response.data;
            });
        }

        function changeState(state) {
            $state.go(state);
        }

        vm.getBilletLink = function(){
            if (vm.loggedAccount){
                return vm.loggedAccount.billetLink;
            }
        }

        vm.makePayment = function() {
			if (!vm.selectedPlan.free){
				vm.visibleAlert = false;
	            if (vm.checkedBoleto) {
	                vm.sendToMopiPaymentWithBillet();
	            } else if (vm.checkedCreditCard) {
	                vm.sendToMopiPaymentWithCard();
	            }
			}else{
				vm.isSaving = true;
                createSubscriber();
			}
        }

        function loadPaymentUrl() {
            if (vm.selectedPlan.annuallyCode === "1669774992") {
                // Showkase Plan
                vm.paymentUrl = "https://payment-link.pagar.me/pl_x7bWJq1VeyKBnJKIggI5JBMYZlnvkwjo";
            } else if (vm.selectedPlan.annuallyCode === "1669775157") {
                // Impulso Plan
                vm.paymentUrl = "https://payment-link.pagar.me/pl_mZ3lxa0r7kvQeyzUjS9qEwKyODp682bJ";
            } else if (vm.selectedPlan.annuallyCode === "1669775314") {
                // Escala Plan
                vm.paymentUrl = "https://payment-link.pagar.me/pl_oMag8v43m9VXazotJJcr2n7qGw65J1xB";
            }
        }

        function loadAll() {
            if (vm.loggedAccount){
                if (vm.loggedAccount.isFree){
                    $state.go('home');
                    return;
                }
            }
            vm.gettedPlans = plans;
            if(vm.gettedPlans && vm.gettedPlans.length){
                vm.selectPlan(vm.gettedPlans[0]);
            }

            loadPaymentUrl();
            loadCountrysAndStates();
        }

        vm.verifyExpirationDate = function(){
            if (vm.expirationDate && vm.expirationDate.length == 4){
                vm.invalidExpirationDate = !Moip.Validator.isExpiryDateValid(vm.expirationDate.substring(0,2), vm.expirationDate.substring(2,4));
            }
        }

        vm.hasMoipPlanAndIsExpired = function(){
            if (vm.loggedAccount){
                return vm.loggedAccount.isExpired && vm.loggedAccount.hasMoipPlan;
            }
        }

        vm.statusPaymentIsAnalyzing = function(){
            if (vm.loggedAccount){
                return vm.loggedAccount.statusPaymentSignature == 'AGUARDANDO_CONFIRMACAO' && !vm.loggedAccount.isExpired;
            }
        }

        vm.getFormattedPrice = function(price) {
			if (!price || price == 0){
	            return '0' + ',' + '00';
			}else {
	            price = price.toString();
	            return price.substring(0, price.length - 2) + ',' + price.substring(price.length - 2, price.length);
			}
        }

		vm.getPriceWithoutCents = function(price) {
            price = price.toString();
            return price.substring(0, price.length - 2);
        }

		vm.loadValueStarter = function(){
			valueStarter
		}

        vm.getWithCouponPrice = function(price) {
            if (vm.checkout.coupon && vm.checkout.coupon.status === 'ACTIVE' && vm.isPlanoPro) {
                delete vm.checkout.coupon.creationDate
                if (vm.checkout.coupon.discount.type === 'PERCENT') {
                    price = price - (price * (vm.checkout.coupon.discount.value / 100) ) / 100;
                } else if (vm.checkout.coupon.discount.type === 'AMOUNT') {
                    price = price - vm.checkout.coupon.discount.value;
                };
                if (price === 0) {
                    price = "000"
                };
            }
            price = price.toString();
            return price.substring(0, price.length - 2) + ',' + price.substring(price.length - 2, price.length);
        }

        vm.verifyCardNumber = function(){
            vm.invalidCardNumber = !Moip.Validator.isValid(vm.checkout.cardNumber);
            vm.brandCard = Moip.Validator.cardType(vm.checkout.cardNumber) ? Moip.Validator.cardType(vm.checkout.cardNumber).brand : null;
        }

        function commonsVerifies(){
            if (vm.outsideBrazilState && vm.checkout.country != "BRA") {
                vm.checkout.state = vm.outsideBrazilState;
            }

            if (vm.selectedPlan && vm.selectedPlan.code){
                vm.checkout.planCode = vm.periodSelected == 'annually' ?  vm.selectedPlan.annuallyCode : vm.selectedPlan.code;
                return true;
            }

            return false;
        }

        function selectColorBoleto(){

            if (!vm.checkedBoleto) {
                $( "#boleto_div" ).css("background-color", "");
                $( "#boleto_span" ).css("color", "");

            } else {
                $( "#boleto_div" ).css("background-color", mainColor);
                $( "#boleto_span" ).css("color", "white");
            }



            $( "#credit_card_div" ).css("background-color", "");
            $( "#credit_card_span" ).css("color", "");
        }

        function selectColorCreditCard(){

            if (!vm.checkedCreditCard) {
                $( "#credit_card_div" ).css("background-color", "");
                $( "#credit_card_span" ).css("color", "");

            } else {
                $( "#credit_card_div" ).css("background-color", mainColor);
                $( "#credit_card_span" ).css("color", "white");
            }

            $( "#boleto_div" ).css("background-color", "");
            $( "#boleto_span" ).css("color", "");
        }

        vm.selectBoletoAsPaymentWay = function () {
            if (!vm.selectedPlan.paymentMethod == 'BOLETO' || !vm.selectedPlan.paymentMethod == 'ALL'){
                vm.checkedBoleto = !vm.checkedBoleto;
            }

            if (vm.checkedCreditCard && vm.checkedBoleto){
                vm.checkedCreditCard = false;
            }

            selectColorBoleto();

        }

        vm.selectCreditCardAsPaymentWay = function () {
            if (!vm.selectedPlan.paymentMethod == 'CREDIT_CARD' || !vm.selectedPlan.paymentMethod == 'ALL'){
                vm.checkedCreditCard = !vm.checkedCreditCard;
            }
            if (vm.checkedBoleto && vm.checkedCreditCard){
                vm.checkedBoleto = false;
            }

            selectColorCreditCard();

        }

        function createSubscriber(){
			if (!vm.selectedPlan.free){
	            vm.isSaving = true;
	            Principal.identity().then(function(account) {
                    vm.settingsAccount = copyAccount(account)
	                vm.checkout.subscriberLogin = account.login;
	            });
				if (!vm.isPlanoPro){
					vm.checkout.coupon = null;
				}
	            var params = {};
	            vm.checkout.phoneAreaCode = vm.checkout.telNumber.substring(0,2);
	            vm.checkout.phoneNumber = vm.checkout.telNumber.substring(2, vm.checkout.telNumber.length);
	            vm.checkout.streetNumber = vm.checkout.streetNumber || 0;
                vm.checkout.planId = vm.selectedPlan.id;
                vm.checkout.planId = vm.selectedPlan.id;
	            CheckoutService.createSubscriber(vm.checkout).then(function (response) {
	                if (response.data == "OK"){
	                    toastr.success($translate.instant('checkout.form.success.saved'));
	                    params.success = true;

	                    if (response.headers['x-billet-href']){
	                        params.billetLink = response.headers['x-billet-href'] + '/print';
	                    }
	                    $state.go('checkout-result', params);

	                }
	            }, function (response){
	                if (response.headers()['x-error-on-subscriber-message']){
	                    insertMessageAlert(response.headers()['x-error-on-subscriber-message']);
	                }
	                else if (response.headers()['x-showcaseapp-params']){
	                    insertMessageAlert($translate.instant('checkout.form.error.' + response.headers()['x-showcaseapp-params']));
	                }
	                else {
	                    insertMessageAlert($translate.instant('checkout.form.error.notSaved'));
	                }
	                vm.isSaving = false;
					vm.disableButton();
	            });
			}else{
				vm.isSaving = true;
				vm.checkout.planCode = '9999999999'
	            Principal.identity().then(function(account) {
                    vm.settingsAccount = copyAccount(account)
	                vm.checkout.subscriberLogin = account.login;
	            });
	            var params = {};
	            CheckoutService.createSubscriber(vm.checkout).then(function (response) {
	                if (response.data == "OK"){
	                    toastr.success($translate.instant('checkout.form.success.saved'));
	                    params.success = true;

	                    if (response.headers['x-billet-href']){
	                        params.billetLink = response.headers['x-billet-href'] + '/print';
	                    }
	                    //$state.go('checkout-result', params);
						window.location.href = '/app/home';

	                }
	            }, function (response){
	                if (response.headers()['x-error-on-subscriber-message']){
	                    insertMessageAlert(response.headers()['x-error-on-subscriber-message']);
	                }
	                else if (response.headers()['x-showcaseapp-params']){
	                    insertMessageAlert($translate.instant('checkout.form.error.' + response.headers()['x-showcaseapp-params']));
	                }
	                else {
	                    insertMessageAlert($translate.instant('checkout.form.error.notSaved'));
	                }
	                vm.isSaving = false;
					vm.disableButton();
	            });
			}
        }

        vm.validateCoupon = function() {
			vm.visibleAlert = false;
            vm.isValidatingCoupon = true;
            CheckoutService.validateCoupon(vm.checkout.couponCode).then( function (res) {
                if (res && res.data && res.data.status === 'ACTIVE') {
                    vm.checkout.coupon = res.data;
                } else {
                    insertMessageAlert($translate.instant('checkout.plan.coupon.error.invalid'));
                }
            }).catch(function(err) {
                vm.checkout.coupon = null;
                insertMessageAlert($translate.instant('checkout.plan.coupon.error.notFound'));
            }).finally(function(){
                vm.isValidatingCoupon = false;
            });
        }

        vm.subscribAsFree = function() {
            vm.isSaving = true;
            CheckoutService.createSubscriberFree().then(function() {
                vm.isSaving = false;
                $timeout(function(){
                    if (!$localStorage.defaultStore.storeId) {
                        window.location = '/first/info';
                    } else {
                        $state.go('home');
                    }
                }, 200);
            }).catch(function() {
                vm.isSaving = false;
            });
        }

        vm.sendToMopiPaymentWithBillet = function() {
            if (!vm.formAddress.$invalid){
                if (commonsVerifies()){
                    vm.checkout.birthdateDay = vm.checkout.dateOfBirth.substring(0,2);
                    vm.checkout.birthdateMonth = vm.checkout.dateOfBirth.substring(2,4);
                    vm.checkout.birthdateYear = vm.checkout.dateOfBirth.substring(4,8);

                    vm.checkout.paymentMethod = 'BOLETO';
                    vm.checkout.expirationMonth = undefined;
                    vm.checkout.expirationYear = undefined;
                    vm.checkout.holderName = undefined;
                    vm.isSaving = true;
                    createSubscriber();
                }
            }
        }

        vm.sendToMopiPaymentWithCard = function() {
            if (!vm.paymentForm.$invalid && !vm.formAddress.$invalid){
                if (commonsVerifies()){
                    if (vm.expirationDate && !vm.invalidExpirationDate) {
                        vm.checkout.birthdateDay = vm.checkout.dateOfBirth.substring(0,2);
                        vm.checkout.birthdateMonth = vm.checkout.dateOfBirth.substring(2,4);
                        vm.checkout.birthdateYear = vm.checkout.dateOfBirth.substring(4,8)

                        vm.checkout.expirationMonth = vm.expirationDate.substring(0,2);
                        vm.checkout.expirationYear = vm.expirationDate.substring(2,4);
                        vm.isSaving = true;
                        vm.checkout.paymentMethod = 'CREDIT_CARD';
                        createSubscriber();
                    }
                    else {
                        toastr.warning($translate.instant('checkout.form.error.wrongExpirationDate'));
                    }

                }
            }
        }

		vm.initSelectPlan = function(){
			//vm.checkPlanStarter = true;
			vm.checkPlanPro = false;
			vm.selectPlanStarter();
			vm.selectPlanPro();
		}

		vm.selectPlanStarter = function(){
			//if(vm.checkPlanStarter){
			vm.selectedPlanStarter = vm.gettedPlans[5];
			/*}else{
				vm.selectedPlanStarter = vm.gettedPlans[1];
			}*/

			// vm.selectPlan(vm.selectedPlanStarter);
            unmarkSelected("planPro");
	        markSelected("planStarter");
			$( "#chkPlanStarter" ).addClass( "md-checked" );
			$( "#chkPlanPro" ).removeClass( "md-checked" );
			vm.isPlanoPro = false;
			vm.isSaving = false;
		}




		vm.selectPlanPro = function(){
			if(vm.checkPlanPro){
				vm.selectedPlanPro = vm.gettedPlans[4];
			}else{
				vm.selectedPlanPro = vm.gettedPlans[3];
			}
			// vm.selectPlan(vm.selectedPlanPro);
	        unmarkSelected("planStarter");
            markSelected( "planPro");
			$( "#chkPlanStarter" ).removeClass( "md-checked" );
			$( "#chkPlanPro" ).addClass( "md-checked" );
			vm.isPlanoPro = true;
		}

		vm.saibaMais = function (){
			/*PlanModalFactory.choosePlan().then(function() {
            });*/
 			return $uibModal.open({
                templateUrl: 'app/account/checkout/plan-modal.html',
                controller: 'PlanModalController',
                controllerAs: 'vm',
                size: 'md',
                keyboard : false
            }).result;
		}

        vm.getCurrentPrice = function() {
            if (vm.selectedPlan) {
                return vm.periodSelected == 'annually' ? vm.selectedPlan.amountAnnually : vm.selectedPlan.amountMonth;
            }
            return 0;
        }

        vm.selectPlan = function (plan){
            vm.selectedPlan = plan;

            vm.checkedBoleto = false;
            vm.checkedCreditCard = false;

            selectColorBoleto();
            selectColorCreditCard();
            loadPaymentUrl();
        }

        vm.disableButton = function (){
            // if (vm.formAddress.$invalid){
            //     return true;
            // } else if (!vm.checkedBoleto && !vm.checkedCreditCard) {
            //     return true;
            // } else if (vm.checkedCreditCard && vm.paymentForm.$invalid) {
            //     return true;
            // } else if (vm.checkedCreditCard && vm.invalidCardNumber){
            //     return true;
            // } else if(vm.isSaving){
            //     return vm.isSaving;
            // } else if (vm.checkout.dateOfBirth == undefined ) {
            //     return true;
            // } else {
            //     return false;
            // }

            return false;
        }


        function markSelected(plan){
            $("#" + plan).css("background-color", mainColor);
            $("#" + plan).css("color", "white");
			$("#saibaMais" + plan).css("color", "#45ce84")
        }

        function unmarkSelected(plan){
            $("#" + plan).css("background-color", "white");
            $("#" + plan).css("color", "black");
			$("#saibaMais" + plan).css("color", mainColor)
        }

        vm.getStoreNickName = function () {
            if ($localStorage.defaultStore){
                return $localStorage.defaultStore.storeNickname;
            }
        }

		vm.goHome = function(){
			window.location.href = '/app/home';
		}

        vm.checkCEP = function () {
            if (vm.checkout.zipcode && vm.checkout.zipcode.length === 8) {
                CheckCEPService.getAddress(vm.checkout.zipcode).then(function (response) {
                    // For now, only Brazilan addresses
                    if (response.data){
                        vm.checkout.country = "BRA";
                        vm.checkout.city = response.data.location.name;
                        vm.checkout.state = response.data.location.uf;
                        vm.checkout.street = response.data.name;
                        vm.checkout.district = response.data.district.name;
                    }
                });
            }
        }

        loadAll();
    }
})();
