(function () {
    'use strict';

    angular
        .module('showcaseApp')
        .factory('HubModalFactory', HubModalFactory);

    /** @ngInject */
    function HubModalFactory($uibModal) {

        var factory = {
            addStoreType: addStoreType,
            editStoreType: editStoreType,
            deleteStoreType: deleteStoreType
        }

        return factory;

        function addStoreType() {
            return showAddEditStoreType();
        }

        function editStoreType(storeType) {
            return showAddEditStoreType(storeType);
        }

        function deleteStoreType(customer) {
            //TODO
        }

        function showAddEditStoreType(storeType) {
            return $uibModal.open({
                templateUrl: "app/admin/hub-management/modal-add-edit-store-type.html",
                size: 'lg',
                windowClass: 'modal-center',
                windowTopClass: 'modal-dialog-center',
                resolve: {
                    customer: function () {
                        return angular.copy(storeType);
                    }
                },
                controller: "StoreTypeModalConstroller",
                controllerAs: "vm"
            }).result;
        }

        function showDeleteModal(fullName) {
            return $uibModal.open({
                templateUrl: "app/admin/customer/_modal_delete_customer.html",
                size: 'md',
                windowClass: 'modal-center',
                windowTopClass: 'modal-dialog-center',
                resolve: {
                    fullName: function () {
                        return fullName;
                    }
                },
                controller: function ($uibModalInstance, fullName) {
                    var vm = this;
                    vm.fullName = fullName;
                    vm.cancel = cancel;
                    vm.ok = ok;

                    function cancel() {
                        $uibModalInstance.dismiss('cancel');
                    }

                    function ok() {
                        $uibModalInstance.close(true);
                    }
                },
                controllerAs: "vm"
            }).result;
        }
    }
})();
