(function() {
    "use strict";

    angular
        .module("showcaseApp")
        .controller("HubFlyersNewController", HubFlyersNewController);

    function HubFlyersNewController(
        $translate,
        $timeout,
        $scope,
        FlyerCategoryService,
        StoreService,
        HubFlyersService,
        $localStorage,
        $q,
        $state,
        toastr,
        moment,
        BrandService,
		$rootScope
    ) {
        var format = "L LT";
        var FORMAT_DATE = "L";
        // Beginning of the day
        var CURRENT_DAY = moment().startOf("day");

        var vm = this;

        StoreService.getStore($localStorage.defaultStore.storeId).then(function(response) {
            vm.storeCategory = response.data.categoryKey;
        });

        vm.userInteracts = ["COUPON", "RENT", "CONTACT", "SALE"];
        vm.transactionalUserInteractions = ["RENT", "SALE"];
        vm.disabledCurrency = false;

        vm.currencyConfigurations = getCurrencyListConfiguration();

        vm.options = {
            fullscreenControl: false
        };
        vm.brands = [];
        vm.colors = getAllColors();
        vm.sizes = getAllSizes();

        vm.flyer = {
            image: null,
            discountPercentageValue: undefined,
            currency: "REAL",
            currencyInstance: vm.currencyConfigurations.REAL,
            discountType: "DISCOUNT_FIXED",
            sizes: [],
            tags: [],
            isLaunch: false,
            isBasic: true
        };

        const MAX_IMAGESIZE = 10240;

        vm.onValidityTypeDateChange = function(checked) {
            if (checked) {
                angular
                    .element("#expirationDateTime")
                    .data("DateTimePicker")
                    .clear();
            }
        };

        vm.onImmediateAvailability = function() {
            if (vm.checks.immediateAvailability) {
                vm.flyerSecondForm.startDate.$setValidity("minDate", true);
                angular
                    .element("#startDateTime")
                    .data("DateTimePicker")
                    .clear();
            }
        };

        vm.onChangeCategory = function() {
            vm.fullCategory = vm.categories.find(function(category) {
                return category.key === vm.flyer.categoryKey;
            });
        }

        vm.mainMap = {
            center: {
                latitude: 0,
                longitude: 0
            },
            zoom: 10,
            controls: {},
            bounds: {}
        };
        vm.tabsState = [{
                active: true
            },
            {
                active: false
            },
            {
                active: false
            }
        ];
        vm.actualTab = 0;
        vm.imageSize = 400;
        vm.mainMap.window = {
            model: {},
            show: false,
            options: {
                pixelOffset: {
                    width: -1,
                    height: -65
                }
            },
            closeClick: function() {
                this.show = false;
            }
        };
        vm.mainMap.markers = [];
        vm.checkBoxControl = [];
        vm.checks = {
            validityTypeDate: true,
            validityTypeNumber: true,
            usageLimitByUser: true,
            immediateAvailability: true
        };
        vm.flyerImage = "";
        vm.isOpenCalendarStart = false;
        vm.isOpenCalendarExpire = false;
        vm.startDateSelected = false;
        vm.showMap = false;
        vm.allSelected = false;
        vm.isSaving = false;
        vm.showExpirateDate = true;
        vm.imageChanged = false;
        vm.firstFormSubmitted = false;
        vm.loadingImage = false;

        const ICON_ACTIVE = "https://showkase-prod.s3-sa-east-1.amazonaws.com/hub/system-images/'+$rootScope.urlImage+'/pointactive.png";
        const ICON_INACTIVE = "content/images/pointinactive.png";

        vm.formatMoney = function(value, currencySymbol) {
            return formatMoney(
                value,
                currencySymbol,
                vm.flyer.currencyInstance.decimalQuant,
                vm.flyer.currencyInstance.centsSeparator,
                vm.flyer.currencyInstance.thousandsSeparator
            );
        };

        vm.getCurrencySymbol = function() {
            return JSON.parse(vm.flyer.currencyMethod);
        };

        vm.openInput = function(evt) {
            document.getElementById("fileInput").click();
        };

        vm.getHowMuchIsSaving = function() {
            if (vm.flyer.discountType == "DISCOUNT_PERCENTAGE") {
                if (vm.flyer.discountPercentageValue) {
                    return vm.flyer.discountPercentageValue.toFixed(1);
                }
            } else if (vm.flyer.discountType == "DISCOUNT_FIXED") {
                if (vm.flyer.discountValue && vm.flyer.purchaseValue) {
                    return (
                        (vm.flyer.discountValue * 100) /
                        vm.flyer.purchaseValue
                    ).toFixed(1);
                }
            }
        };

        vm.getDiscountedValueByDiscountType = function(
            value,
            discountType,
            discountValue
        ) {
            if (discountType == "DISCOUNT_PERCENTAGE") {
                discountValue =
                    vm.flyer.discountPercentageValue || discountValue;
                if (!discountValue) return value;
                return value - value * ((discountValue || 100) * 0.01);
            } else if (discountType == "DISCOUNT_FIXED") {
                discountValue = vm.flyer.discountValue;
                return value - (discountValue || 0);
            }
        };

        vm.getDiscountValueByDiscountType = function(
            value,
            discountType,
            discountValue
        ) {
            if (discountType == "DISCOUNT_PERCENTAGE") {
                discountValue =
                    vm.flyer.discountPercentageValue || discountValue;
                return value * ((discountValue || 100) * 0.01);
            } else if (discountType == "DISCOUNT_FIXED") {
                discountValue = vm.flyer.discountValue || discountValue;
                return discountValue;
            }
        };

        vm.isToDisplayMe = function(value) {
            return value == vm.flyer.discountType;
        };

        var handleFileSelect = function(evt) {
            var file = evt.currentTarget.files[0];
            if (file.size / 1024 > MAX_IMAGESIZE) {
                toastr.error(
                    $translate.instant("flyers.messages.create.image.sizeError")
                );
            } else {
                var reader = new FileReader();
                reader.onload = function(evt) {
                    $scope.$apply(function() {
                        vm.imageUpload = evt.target.result;
                    });
                };
                reader.readAsDataURL(file);
            }
        };

        vm.activeImageCrop = function() {
            $timeout(function() {
                if ($("canvas")) {
                    $("canvas").click();
                }
            }, 100);
        };

        vm.startClosed = function() {
            vm.startDateSelected = true;
        };

        vm.changeExpireDate = function(value) {
            vm.isSelectExpireDate = value;
        };

        vm.firstZoom = true;

        $scope.$watch("vm.mainMap.zoom", function(newVal) {
            if (newVal > 16 && vm.firstZoom) {
                vm.mainMap.zoom = 16;
                vm.firstZoom = false;
            }
        });

        vm.reopen = false;

        vm.checkIfSizeSelected = function(size) {
            return vm.flyer.sizes.includes(size);
        }

        vm.updateSizeOrRemove = function(size) {
            var isSelected = vm.flyer.sizes.includes(size);
            if (isSelected) {
                vm.flyer.sizes = vm.flyer.sizes.filter(function(item) {
                    return item !== size;
                });
            } else {
                vm.flyer.sizes.push(size);
            }
        }

        vm.checkIfLaunchSelected = function() {
            return vm.flyer.isLaunch;
        }

        vm.updateLaunchOrRemove = function() {
            vm.flyer.isLaunch = !vm.flyer.isLaunch;
        }

        vm.checkIfBasicSelected = function() {
            return vm.flyer.isBasic;
        }

        vm.updateBasicOrRemove = function() {
            vm.flyer.isBasic = !vm.flyer.isBasic;
        }

        vm.saveFlyer = function() {
            if (!vm.isSaving) {
                vm.isSaving = true;
                vm.flyer.purchaseValue =
                    vm.flyer.purchaseValue === 0 ?
                    null :
                    vm.flyer.purchaseValue;
                vm.flyer.discountValue =
                    vm.flyer.discountValue === 0 ?
                    null :
                    vm.flyer.discountValue;
                var flyerToSave = angular.copy(vm.flyer);
                flyerToSave.startDate = moment(
                    flyerToSave.startDate,
                    format
                ).toDate();
                flyerToSave.expirationDate = flyerToSave.expirationDate ?
                    moment(flyerToSave.expirationDate, format).toDate() :
                    undefined;
                vm.existsSalesPoint = false;
                flyerToSave.validityTypeDate = !vm.checks["validityTypeDate"];
                flyerToSave.validityTypeNumber = !vm.checks[
                    "validityTypeNumber"
                ];
                flyerToSave.usageLimitByUser = !vm.checks["usageLimitByUser"];
                flyerToSave.immediateAvailability =
                    vm.checks["immediateAvailability"];
                flyerToSave.hub = $localStorage.hub.id;
                flyerToSave.salesPoint = [];
                flyerToSave.discountValue =
                    flyerToSave.discountValue ||
                    flyerToSave.discountPercentageValue;
                flyerToSave.currency = vm.flyer.currencyInstance.key;
                flyerToSave.gender = parseInt(vm.flyer.gender);
                flyerToSave.brand = vm.flyer.brand;
                flyerToSave.color = vm.flyer.color;
                flyerToSave.sizes = vm.flyer.sizes;
                flyerToSave.tags = vm.flyer.tags.map(function(tag) {
                    return tag['text'];
                });
                flyerToSave.productReference = vm.flyer.productReference;
                flyerToSave.isLaunch = vm.flyer.isLaunch;
                flyerToSave.isBasic = vm.flyer.isBasic;

                var blob = dataURItoBlob(vm.flyerImage);
                HubFlyersService.saveFlyerImage(blob).then(
                    function(response) {
                        $timeout(function() {
                            vm.flyer.addImageFileDTO = response.data;
                            flyerToSave.addImageFileDTO = response.data;
                            if (vm.flyer.addImageFileDTO) {
                                HubFlyersService.createFlyer(flyerToSave).then(
                                    function(data) {
                                        $state.go("hub-flyers", {
                                            type: flyerToSave.type
                                        });
                                        toastr.success(
                                            $translate.instant(
                                                "flyers.messages.create.success"
                                            )
                                        );
                                        vm.isSaving = false;
                                    },
                                    function(error) {
                                        vm.flyer.startDate = moment(
                                            vm.flyer.startDate
                                        ).format(format);
                                        vm.flyer.expirationDate = vm.flyer
                                            .expirationDate ?
                                            moment(
                                                vm.flyer.expirationDate
                                            ).format(format) :
                                            undefined;
                                        vm.isSaving = false;
                                        toastr.error(
                                            $translate.instant(
                                                "flyers.messages.create.error"
                                            )
                                        );
                                    }
                                );
                            } else {
                                toastr.error(
                                    $translate.instant(
                                        "flyers.messages.create.error"
                                    )
                                );
                            }
                            vm.loadingImage = false;
                        }, 3000);
                    },
                    function(response) {
                        toastr.error(
                            $translate.instant(
                                "flyers.messages.create.image.uploadError"
                            )
                        );
                        vm.loadingImage = false;
                    }
                );
            }
        };

        vm.mainMap.markerEvents = {
            dragend: function(marker, eventName, model) {
                vm.refreshMapWithCoordinates(
                    marker.position.lat(),
                    marker.position.lng()
                );
            },
            mouseover: function(marker, eventName, model, args) {
                vm.mainMap.window.model = model;
                vm.mainMap.window.show = true;
            },
            mouseout: function(marker, eventName, model, args) {
                vm.mainMap.window.show = false;
            },
            click: function(marker, eventName, model, args) {
                for (var i = 0; i < vm.mainMap.markers.length; i++) {
                    if (vm.mainMap.markers[i].id == marker.key) {
                        if (vm.mainMap.markers[i].clicked) {
                            vm.mainMap.markers[i].options.icon = ICON_INACTIVE;
                            vm.mainMap.markers[i].clicked = false;
                        } else {
                            vm.mainMap.markers[i].options.icon = ICON_ACTIVE;
                            vm.mainMap.markers[i].clicked = true;
                        }
                    }
                }
            }
        };

        vm.checkAllMarkers = function() {
            vm.allSelected = !vm.allSelected;
            var icon;
            if (vm.allSelected) {
                icon = ICON_ACTIVE;
            } else {
                icon = ICON_INACTIVE;
            }
            for (var i = 0; i < vm.mainMap.markers.length; i++) {
                vm.mainMap.markers[i].options.icon = icon;
                vm.mainMap.markers[i].clicked = vm.allSelected;
            }
        };

        vm.typeChanged = function() {
            if (vm.flyer.type == "RENT") {
                vm.flyer.maxUsageByUser = 1;
                vm.flyer.maxUsages = 1;
                vm.checks["usageLimitByUser"] = false;
                vm.checks["validityTypeNumber"] = false;
                vm.checks["validityTypeDate"] = true;
            } else {
                vm.flyer.maxUsageByUser = undefined;
                vm.flyer.maxUsages = undefined;
                vm.checks["usageLimitByUser"] = true;
                vm.checks["validityTypeNumber"] = true;
            }

            vm.flyer.bulkQuantity = null;
            vm.flyer.discountBulkPercentage = null;

            if (vm.transactionalUserInteractions.includes(vm.flyer.type)) {
                vm.flyer.currency = "REAL";
                vm.flyer.currencyInstance = vm.currencyConfigurations.REAL;
                vm.disabledCurrency = true;
            } else {
                vm.disabledCurrency = false;
            }
        };

        function checkExpirationDate() {
            if (vm.flyer.expirationDate) {
                if (
                    moment(vm.flyer.startDate, format).isSameOrAfter(
                        moment(vm.flyer.expirationDate, format)
                    )
                ) {
                    /** returns false if the expirationDate is before startDate */
                    return false;
                }
                /** returns true if the expirationDate is ok (the expirationDate is after startDate) */
                return true;
            }
        }

        vm.changeToTab = function(tab) {
            if (tab === 1) {
                vm.activeImageCrop();
                vm.firstFormSubmitted = true;
                if (!vm.flyerFirstForm.$invalid) {
                    vm.tabsState[tab].active = true;
                    vm.actualTab = tab;
                }
            } else if (tab === 2) {
                if (vm.flyerImage) {
                    vm.loadingImage = true;
                    vm.secondFormSubmitted = true;
                    var expirationDateStatus = checkExpirationDate();
                    if (expirationDateStatus == false) {
                        vm.tabsState[1].active = true;
                        vm.actualTab = 1;
                        toastr.warning(
                            $translate.instant(
                                "error.flyerStartDateGreaterThanOrEqualsToExpirationDate"
                            )
                        );
                        return;
                    }
                    if (vm.flyerSecondForm.$valid) {
                        vm.uploadImage(vm.flyerImage, function(data) {
                            vm.loadingImage = false;
                            vm.tabsState[tab].active = true;
                            vm.actualTab = tab;
                            $timeout(function() {
                                vm.showMap = false;
                                $timeout(function() {
                                    vm.showMap = true;
                                }, 300);
                            }, 100);
                        });
                    } else {
                        vm.loadingImage = false;
                    }
                }
            }
        };

        vm.uploadImage = function(file, callback) {
            var blob = dataURItoBlob(file);
            HubFlyersService.saveFlyerImage(blob).then(
                function(response) {
                    $timeout(function() {
                        vm.flyer.addImageFileDTO = response.data;
                        if (vm.flyer.addImageFileDTO) {
                            callback();
                        } else {
                            toastr.error(
                                $translate.instant(
                                    "flyers.messages.create.error"
                                )
                            );
                        }
                        vm.loadingImage = false;
                    });
                },
                function(response) {
                    toastr.error(
                        $translate.instant(
                            "flyers.messages.create.image.uploadError"
                        )
                    );
                    vm.loadingImage = false;
                }
            );
        };

        vm.imageIsLoaded = function(e) {
            $scope.$apply(function() {
                vm.flyer.imagePath = e.target.result;
            });
        };

        vm.getFlyerPrice = function() {
            if (vm.flyer.discountType === "DISCOUNT_PERCENTAGE") {
                return (
                    (vm.flyer.purchaseValue ? vm.flyer.purchaseValue : 0) -
                    (vm.flyer.purchaseValue ?
                        vm.flyer.purchaseValue *
                        (vm.flyer.discountValue ?
                            vm.flyer.discountValue / 100 :
                            0) :
                        0)
                );
            } else {
                return vm.flyer.purchaseValue && vm.flyer.discountValue ?
                    vm.flyer.purchaseValue -
                    (vm.flyer.discountValue ? vm.flyer.discountValue : 0) :
                    vm.flyer.purchaseValue;
            }
        };

        vm.checkDiscountType = function() {
            if (vm.flyer.discountValue && !vm.flyer.discountType) {
                vm.flyer.discountType = "DISCOUNT_FIXED";
            }
            if (vm.flyer.discountType === "DISCOUNT_FIXED") {
                vm.flyer.discountPercentageValue = undefined;
                vm.flyerFirstForm.discountValue.$setValidity("max", true);
                if (
                    parseFloat(vm.flyer.discountValue) >
                    parseFloat(vm.flyer.purchaseValue)
                ) {
                    vm.flyerFirstForm.discountValue.$setValidity(
                        "discountGreaterThanValue",
                        false
                    );
                } else {
                    vm.flyerFirstForm.discountValue.$setValidity(
                        "discountGreaterThanValue",
                        true
                    );
                }
            } else {
                vm.flyer.discountValue = undefined;
            }
        };

        function convertToMarkers(points) {
            points.forEach(function(point) {
                vm.mainMap.markers.push({
                    pointlocation: {
                        name: point.name,
                        country: point.country,
                        state: point.state,
                        neighborhood: point.neighborhood,
                        number: point.number,
                        address: point.address,
                        city: point.city,
                        salesPointId: point.id,
                        postalCode: point.postalCode,
                        contactPhone: point.contactPhone,
                        storeId: vm.store
                    },
                    latitude: point.coordinate.latitude,
                    longitude: point.coordinate.longitude,
                    id: point.id,
                    clicked: false,
                    options: {
                        icon: ICON_INACTIVE
                    }
                });
            });
        }

        function loadAll() {
            loadBrands();
            FlyerCategoryService.listCategories($translate.use()).then(function(
                response
            ) {
                vm.categories = response.data;
                vm.fullCategory = vm.categories[0];
                vm.flyer.categoryKey = vm.categories[0].key;
                vm.activeImageCrop();
                vm.initSecondTab();
                vm.changeToTab(1);
                vm.changeToTab(2);
            });

            StoreService.getSalesPoints(
                $localStorage.defaultStore.storeId
            ).then(function(response) {
                convertToMarkers(response.data);
            });
        }

        function loadBrands() {
            BrandService.getBrandsByCurrentStore().then(function(response) {
                vm.brands = response.data;
            });
        }

        function dataURItoBlob(dataURI) {
            var byteString;
            if (dataURI.split(",")[0].indexOf("base64") >= 0)
                byteString = atob(dataURI.split(",")[1]);
            else byteString = unescape(dataURI.split(",")[1]);

            var mimeString = dataURI
                .split(",")[0]
                .split(":")[1]
                .split(";")[0];

            var ia = new Uint8Array(byteString.length);
            for (var i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
            }

            return new Blob([ia], {
                type: mimeString
            });
        }

        vm.initSecondTab = function() {
            dateTimePicker();
            angular
                .element(document.querySelector("#fileInput"))
                .on("change", handleFileSelect);
        };

        loadAll();

        function dateTimePicker() {
            vm.startDateOptions = {
                format: format,
                sideBySide: true,
                ignoreReadonly: true,
                locale: moment.locale(),
                // minDate: CURRENT_DAY,
                allowInputToggle: true
            };

            vm.expireDateOptions = {
                format: format,
                sideBySide: true,
                ignoreReadonly: true,
                minDate: moment(),
                locale: moment.locale(),
                allowInputToggle: true,
                useCurrent: false
            };

            angular
                .element("#startDateTime")
                .datetimepicker(vm.startDateOptions)
                .on("dp.change", function(ev) {
                    if (ev.date) {
                        vm.flyer.startDate = ev.date.format(format);
                        if (vm.flyer.expirationDate && !checkExpirationDate()) {
                            vm.flyer.expirationDate = undefined;
                            angular
                                .element("#expirationDateTime")
                                .data("DateTimePicker")
                                .minDate(ev.date || moment());
                        } else {
                            angular
                                .element("#expirationDateTime")
                                .data("DateTimePicker")
                                .minDate(moment());
                        }
                        $scope.$apply(function() {
                            vm.flyerSecondForm.startDate.$setValidity(
                                "minDate",
                                true
                            );
                        });
                    } else {
                        vm.flyer.startDate = undefined;
                    }
                });

            angular
                .element("#expirationDateTime")
                .datetimepicker(vm.expireDateOptions)
                .on("dp.change", function(ev) {
                    if (ev.date) {
                        vm.flyer.expirationDate = ev.date.format(format);
                        $scope.$apply(function() {
                            vm.flyerSecondForm.expirationDate.$setValidity(
                                "required",
                                true
                            );
                        });
                    } else {
                        vm.flyer.expirationDate = undefined;
                    }
                });
        }
    }
})();
