(function () {
    'user strict'

    angular
        .module('showcaseApp')
        .controller('CommunicationSettingsController', CommunicationSettingsController);

    function CommunicationSettingsController(toastr, $state, $localStorage, $translate, Constants,
                                    ModalFactory, StoreService, ConfirmActionModalFactory, WatiService,
                                         ChatAssignmentRuleService, TeamService) {

        var vm = this;

        vm.notifyTimes = notifyTimes();
        vm.isMobile = detectar_mobile();

        vm.store = $localStorage.defaultStore;
        vm.form = {};
        vm.templates = [];
        vm.loadingTemplates = true;
        vm.notifications = [];
        vm.templateHasCoupon = false;
        vm.isSaving = false;
        vm.activeForm = false;

        vm.WATICredits;
        vm.isWATIConnected = false;

        init();

        function init() {
            loadRules();
            loadTeams();
        }

        function loadRules() {
            ChatAssignmentRuleService.list($localStorage.defaultStore.storeId).then(function (response) {
                console.log(response.data);
                if (response.data) {
                    vm.rules = response.data;
                }
            });
        }

        function loadTeams() {
            var params = {
                "storeId": $localStorage.defaultStore.storeId,
                "isEnabled": true
            }
            
            TeamService.list(params, {}).then(function(response) {
                console.log(response.data.content);
                if (response.data && response.data.content) {
                    vm.teams = response.data.content;
                }
            });
        }

        function getWATIConnection() {
            WatiService.getConfigByCurrentStore().then(function (result) {
                if (result.data.id) {
                    vm.isWATIConnected = true;
                } else {
                    vm.isWATIConnected = false;
                }
            })
        }

        vm.selectTeam = function () {
            console.log(vm.form);
        }

        vm.openCreateRuleForm = function () {
            vm.activeForm = !vm.activeForm;
            scrollToBottom();
        }

        vm.cancelForm = function() {
            vm.activeForm = false;
        }


        vm.getRuleById = function(id) {
            ChatAssignmentRuleService.get(id, $localStorage.defaultStore.storeId).then(function (result) {
                console.log(result.data);
                if (result.data) {
                    vm.form = result.data;
                    vm.form.team = vm.teams.find(function (team) {
                        return team.id === result.data.teamId;
                    });
                }
                
                vm.activeForm = true;
                scrollToBottom();
            });
        }

        vm.createUpdateRule = function () {
            if (vm.form.id){
                updateRule();
            } else {
                createRule();
            }
        }

        function createRule() {
            var body = {
                isEnabled: true,
                teamId: vm.form.team.id,
                storeId: $localStorage.defaultStore.storeId,
                name: vm.form.name,
                keyword: vm.form.keyword
            }
            ModalFactory.openWarningModal(
                null,
                $translate.instant('settings.communication.chat-assignment-rule.create-modal.title'),
                $translate.instant('settings.communication.chat-assignment-rule.create-modal.small-text'),
                $translate.instant('settings.communication.chat-assignment-rule.create-modal.warning-text'),
                $translate.instant('entity.action.continue'),
                $translate.instant('entity.action.cancel'),
                function () {
                    vm.isSaving = true;
                    ChatAssignmentRuleService.save(body).then(function (result) {
                        console.log(result.data);
                        vm.isSaving = false
                        if (result.data) {
                            vm.rules.push(result.data);
                        }
                        loadRules();
                        vm.form = {}
                        vm.activeForm = false;
                        vm.ruleForm.$setPristine();
                        toastr.success($translate.instant('settings.communication.chat-assignment-rule.success-to-create'));
                    }).catch(function (error) {
                        vm.isSaving = false;
                        if (error.data && error.data.data && error.data.data == "CHAT_ASSIGNMENT_RULE_NAME_OR_KEYWORD_DUPLICATED") {
                            toastr.error($translate.instant(
                                'settings.communication.chat-assignment-rule.failed-to-create-duplicated', 
                                {
                                    name: vm.form.name,
                                    keyword: vm.form.keyword 
                                })
                            );
                        } else {
                            toastr.error($translate.instant('settings.communication.chat-assignment-rule.failed-to-create'));
                        }
                    });
                }
            );
        }

        vm.disableRule = function (rule) {
            var body = {
                id: rule.id,
                isEnabled: rule.isEnabled,
                teamId: rule.teamId,
                storeId: rule.storeId,
                name: rule.name,
                keyword: rule.keyword
            }
            var action = rule.isEnabled ? 'active' : 'disable';

            ModalFactory.openWarningModal(
                null,
                $translate.instant('settings.communication.chat-assignment-rule.' + action + '-modal.title'),
                $translate.instant('settings.communication.chat-assignment-rule.' + action + '-modal.small-text'),
                $translate.instant('settings.communication.chat-assignment-rule.' + action + '-modal.warning-text'),
                $translate.instant('entity.action.continue'),
                $translate.instant('entity.action.cancel'),
                function () {
                    vm.isSaving = true;
                    ChatAssignmentRuleService.update(rule.id, body)
                        .then(function (result) {
                            console.log(result)
                            vm.isSaving = false;
                            vm.form = {}
                            toastr.success($translate.instant('settings.communication.chat-assignment-rule.success-to-' + action));
                        })
                        .catch(function (err) {
                            vm.isSaving = false;
                            notification.isEnabled = !notification.isEnabled;
                            toastr.error($translate.instant('settings.communication.chat-assignment-rule.failed-to-' + action));
                        });
                },
                function () {
                    rule.isEnabled = !rule.isEnabled;
                }
            );
        }

        function updateRule() {
            var body = {
                id: vm.form.id,
                isEnabled: vm.form.isEnabled,
                teamId: vm.form.team.id,
                storeId: $localStorage.defaultStore.storeId,
                name: vm.form.name,
                keyword: vm.form.keyword
            }

            ModalFactory.openWarningModal(
                null,
                $translate.instant('settings.communication.chat-assignment-rule.update-modal.title'),
                $translate.instant('settings.communication.chat-assignment-rule.update-modal.small-text'),
                $translate.instant('settings.communication.chat-assignment-rule.update-modal.warning-text'),
                $translate.instant('entity.action.continue'),
                $translate.instant('entity.action.cancel'),
                function () {
                    vm.isSaving = true;
                    ChatAssignmentRuleService.update(vm.form.id, body).then(function (result) {
                        vm.isSaving = false;
                        console.log(result.data)
                        var newRule = result.data;
                        var index = _.findIndex(vm.rules, {id: newRule.id});
                        vm.rules.splice(index, 1, newRule);
                        loadRules();
                        vm.form = {}
                        vm.activeForm = false;
                        toastr.success($translate.instant('settings.communication.chat-assignment-rule.success-to-update'));
                    }).catch(function (error) {
                        vm.isSaving = false;
                        if (error.data && error.data.data && error.data.data == "CHAT_ASSIGNMENT_RULE_NAME_OR_KEYWORD_DUPLICATED") {
                            toastr.error($translate.instant(
                                'settings.communication.chat-assignment-rule.failed-to-create-duplicated', 
                                {
                                    name: vm.form.name,
                                    keyword: vm.form.keyword 
                                })
                            );
                        } else {
                            toastr.error($translate.instant('settings.communication.chat-assignment-rule.failed-to-update'));
                        }
                    });
                }
            );
        }

        vm.deleteRule = function (id) {
            ModalFactory.openWarningModal(
                null,
                $translate.instant('settings.communication.chat-assignment-rule.delete-modal.title'),
                $translate.instant('settings.communication.chat-assignment-rule.delete-modal.small-text'),
                $translate.instant('settings.communication.chat-assignment-rule.delete-modal.warning-text'),
                $translate.instant('entity.action.continue'),
                $translate.instant('entity.action.cancel'),
                function () {
                    vm.isSaving = true;
                    ChatAssignmentRuleService.remove(id, $localStorage.defaultStore.storeId).then(function (result) {
                        loadRules();
                        vm.isSaving = false;
                        toastr.success($translate.instant('settings.communication.chat-assignment-rule.success-to-remove'));
                    }).catch(function (err) {
                        vm.isSaving = false;
                        toastr.error($translate.instant('settings.communication.chat-assignment-rule.failed-to-remove'));
                    });
                }
            );
        }

        function scrollToBottom() {
            setTimeout(function() {
                var element = document.getElementById('abandoned-cart-form');
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth', block: 'center' });
                }
            }, 100);
        }
    }
})();
