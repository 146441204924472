(function () {
    'use strict';
    angular
        .module('showcaseApp')
        .factory('BlingFactory', BlingFactory);

    BlingFactory.$inject = ['$resource'];

    function BlingFactory($resource) {
        var resourceUrl = 'flyer/api/integration/bling';

        return $resource(resourceUrl, {}, {
            'syncProductsByStoreAndFlyerIds': {
                method: 'POST',
                url: resourceUrl + '/sync-products/:storeId',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'refreshToken': {
                method: 'POST',
                url: 'admin/api/bling/refresh-token/:storeId',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            }
        });
    }

})();
