(function() {
    'use strict';

    angular
        .module('showcaseApp')
        .controller('ProfileDetailController', ProfileDetailController);

    ProfileDetailController.$inject = ['$timeout', '$translate', '$localStorage', '$state', '$scope', '$stateParams', 'UserProfileService', 'AuthorityService', 'toastr'];

    function ProfileDetailController($timeout, $translate, $localStorage, $state, $scope, $stateParams, UserProfileService, AuthorityService, toastr) {

        var vm = this;
        vm.authoritiesCheck = [];
        vm.authoritiesWithHierarchy = [];
        vm.isSaving = false;
        vm.profileTypes = ["ADMIN_PROFILE", "CUSTOMER_PROFILE", "STORE_PROFILE"];
        vm.isNewProfile = false;
		vm.profileId = $localStorage.defaultStore.profileId;
        vm.profile = {
            'profileOwner': $stateParams.storeId || $localStorage.defaultStore.storeId
        };

        loadAll();

        function loadAll() {
            if ($stateParams.id) {
                UserProfileService.getProfile($stateParams.id, vm.profile['profileOwner']).then(function(response) {
                    vm.profile = response.data;
                }).then(function(data) {
                    loadAuthorities();
                });
            } else {
                vm.isNewProfile = true;
                loadAuthorities();
            }
            checkIfUserIsAdmin();
        }


        function loadAuthorities() {
            AuthorityService.getAllWithHierarchy(vm.profile['profileOwner']).then(function(authorities) {
                vm.authoritiesWithHierarchy = authorities;
                if (!vm.isNewProfile) {
                    var profileAuthoritie = [];
                    for (var authoritie in authorities) {
                        if (vm.profile.authorities.indexOf(authorities[authoritie].authority) != '-1') {
                            vm.authoritiesCheck.push(true);
                        } else {
                            vm.authoritiesCheck.push(false);
                        }
                    }
                }
                var storeReadIndex = findIndexByAttr(vm.authoritiesWithHierarchy, 'authority', 'STORE_READ');
                if (storeReadIndex != -1){
                    vm.authoritiesCheck[storeReadIndex] = true;
                }
            });
        }

        vm.isAuthorityDependenciesChecked = function (index){
            if (vm.authoritiesWithHierarchy[index].authority != "STORE_READ"){
                if (vm.authoritiesCheck[index]){
                    // Verify if is a child authority and check him parent.
                    for (var indexDependency in vm.authoritiesWithHierarchy[index].dependencies){
                        var indexParentAuthority = findIndexByAttr(vm.authoritiesWithHierarchy, 'authority', vm.authoritiesWithHierarchy[index].dependencies[indexDependency]);
                        if (indexParentAuthority != -1){
                            vm.authoritiesCheck[indexParentAuthority] = true;
                        }
                    }
                    
                } else {
                    // If unchecked, verify if is a parent authority and if has some child authority checked 
                    for (var indexChild in vm.authoritiesWithHierarchy){
                        if (vm.authoritiesWithHierarchy[indexChild].dependencies != null && vm.authoritiesWithHierarchy[indexChild].dependencies.includes(vm.authoritiesWithHierarchy[index].authority)){
                            vm.authoritiesCheck[indexChild] = false;
                        }
                    }
                }
            }
        }

        function checkIfUserIsAdmin() {
            if (vm.profile.profileOwner == '0') {
                vm.profile.type = 'ADMIN_PROFILE';
            } else {
                vm.profile.type = 'STORE_PROFILE';
            }
        }

        vm.save = function() {
            vm.isSaving = true;
            vm.profile.authorities = [];

            for (var authoritie in vm.authoritiesCheck) {
                if (vm.authoritiesCheck[authoritie]) {
                    vm.profile.authorities.push(vm.authoritiesWithHierarchy[authoritie].authority);
                }
            }

            if (!vm.isNewProfile) {
                UserProfileService.updateProfile(vm.profile).then(function(data, error) {
                    toastr.success($translate.instant('showcaseApp.profile.messages.update.success'));
                    $state.go('^');
                }, function(data) {
                    toastr.error($translate.instant('showcaseApp.profile.messages.update.error'));
                });
                vm.isSaving = false;
            } else {
                UserProfileService.createProfile(vm.profile).then(function(data, error) {
                    toastr.success($translate.instant('showcaseApp.profile.messages.create.success'));
                    $state.go('^');
                }, function(data) {
                    toastr.error($translate.instant('showcaseApp.profile.messages.create.error'));
                });
                vm.isSaving = false;
            }
        }
    }
})();
