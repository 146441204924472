(function () {
    'use strict';
    angular
        .module('showcaseApp')
        .factory('ShowcasesFactory', ShowcasesFactory);

    ShowcasesFactory.$inject = ['$resource'];

    function ShowcasesFactory($resource) {
        var resourceUrl = 'flyer/api/showcase/:id';

        return $resource(resourceUrl, {}, {
            'saveBag':  {
                method: 'POST',
                isArray: false,
                url: 'flyer/api/showcase/bag'
            },
            'findFlyerByFilterBag': {
                method: 'POST',
                isArray: false,
                url: 'flyer/api/showcase/bag/flyers/filter'
            },
            'get': {},
            'getTemplates': {
                method: 'GET',
                isArray: true,
                url: 'flyer/api/showcase-template'
            },
            'getAllFlyerIdsFromShowcasesPublished': {
                method: 'GET',
                isArray: true,
                url: 'flyer/api/flyer/listall/publish'
            },
            'getTemplate': {
                url: 'flyer/api/showcase-template/:templateId'
            },
            'listAll': {
                method: 'POST',
                url: 'flyer/api/showcase/list',
                isArray: true
            },
            'listBagsFiltersValues': {
                method: 'POST',
                url: 'flyer/api/showcase/bag/filter/values'
            },
            'update': {
                method: 'PUT'
            },
            'deletebyids': {
                method: 'POST',
                url: '/flyer/api/showcase/deleteByIds'
            },
            'publish': {
                method: 'PUT',
                url: '/flyer/api/showcase/:id/publishe'
            },
            'getNumberOfVisualization': {
                method: 'POST',
                url: '/flyer/api/showcase/numberOfVisualizations',
                transformResponse: function (data, headers) {
                    var response = {};
                    response.data = angular.fromJson(data);
                    response.headers = headers();
                    return response;
                }
            },
            'getNumberOfFlyers': {
                method: 'GET',
                url: '/flyer/api/showcase/bag/:id/numberOfFlyers',
                transformResponse: function (data, headers) {
                    var response = {};
                    response.data = angular.fromJson(data);
                    response.headers = headers();
                    return response;
                }
            },
            'getPublishedShowcaseByStoreId': {
                method: 'GET',
                url: '/flyer/api/showcase/store/published',
                transformResponse: function (data, headers) {
                    var response = {};
                    response.headers = headers();
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    return response;
                }
            },
            'generateTabloid': {
                method: 'POST',
                url: '/flyer/api/showcase-tabloid/generate-tabloid',
                responseType: 'arraybuffer',
                transformResponse: function (data, headers) {
                    var response = {};
                    response.data = angular.fromJson(data);
                    response.headers = headers();
                    return response;
                }
            },
            'getPublishedFlyer': {
                method: 'GET',
                url: 'flyer/api/showcase/published/flyer/:id',
                responseType: 'arraybuffer',
                transformResponse: function (data, headers) {
                    var response = {};
                    response.data = angular.fromJson(data);
                    response.headers = headers();
                    return response;
                }
            },
            'getPublishedFlyers': {
                method: 'POST',
                url: 'flyer/api/showcase/published/publish-flyers',
                responseType: 'arraybuffer',
                transformResponse: function (data, headers) {
                    var response = {};
                    response.data = angular.fromJson(data);
                    response.headers = headers();
                    return response;
                }
            },
            'deletePublishedFlyer': {
                method: 'DELETE',
                url: 'flyer/api/showcase/published/flyer/:id',
                responseType: 'arraybuffer',
                transformResponse: function (data, headers) {
                    var response = {};
                    response.data = angular.fromJson(data);
                    response.headers = headers();
                    return response;
                }
            },
            'deletePublishedFlyers': {
                method: 'POST',
                url: 'flyer/api/showcase/published/unpublish-flyers',
                responseType: 'arraybuffer',
                transformResponse: function (data, headers) {
                    var response = {};
                    response.data = angular.fromJson(data);
                    response.headers = headers();
                    return response;
                }
            },
            'isFlyerPublished': {
                method: 'GET',
                url: 'flyer/api/showcase/flyer/:id/published',
                cache: false,
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data){
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'createBagByRecommendation':  {
                method: 'POST',
                isArray: false,
                url: 'flyer/api/showcase/bag-by-recommendation'
            },

        });
    }
})();
