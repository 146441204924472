(function() {
    'use strict';

    angular
        .module('showcaseApp')
        .controller('PlanModalController', PlanModalController);

        PlanModalController.$inject = ['$uibModalInstance', '$state'];

    function PlanModalController($uibModalInstance, $state) {
        var vm = this;

        vm.closeModal = function() {
            $uibModalInstance.close(false);
        }

    }

})();
	