(function () {
    'use strict';

    angular
        .module('showcaseApp')
        .controller('CampaignInsightsModalController', CampaignInsightsModalController);

    CampaignInsightsModalController.$inject = [
        '$uibModalInstance', '$translate', 'BusinessIntegrationService', 'data'
    ];

    function CampaignInsightsModalController($uibModalInstance, $translate, BusinessIntegrationService, data) {

        var vm = this;
        vm.campaignId = data;

        vm.confirm = function () {
            $uibModalInstance.close(true);
        };

        vm.closeModal = function () {
            $uibModalInstance.close(false);
        };

        var barChartAxes = [{
            gridLines: {
                display: false
            },
            ticks: {
                min: 0,
                max: this.max,
                callback: function (value) {
                    if (Number.isInteger(value)) {
                        var percentage = value / vm.campaignInsights.impressions * 100;
                        return percentage > 100 ? '100%' : percentage.toFixed(0) + '%';
                    }
                    return value;
                },
            },
        }];

        var chartTooltip = {
            callbacks: {
                label: function (tooltipItem, data) {
                    var value = data.datasets[0].data[tooltipItem.index];
                    return vm.getRateValue(value, vm.campaignInsights.impressions);
                }
            }
        }

        vm.barChartOptions = {
            maintainAspectRatio: false,
            tooltips: chartTooltip,
            scales: {
                xAxes: barChartAxes,
                yAxes: barChartAxes,
            }
        }

        vm.optionsPie = {
            legend: { display: true },
            maintainAspectRatio: false,
            responsive: true,
            tooltips: chartTooltip,
        }

        function getCampaignInsights(campaignId) {
            vm.processing = true;
            BusinessIntegrationService.getFbCampaignInsights(campaignId)
                .then(function (response) {
                    if (response.data) {
                        vm.campaignInsights = response.data;
                        vm.campaignInsights.frequency = vm.campaignInsights.frequency.toFixed(2);
                        setAudienceAgeData();
                        setAudienceGenderData();
                        setAudienceRegionData();
                        setAudiencPlatformData();
                    }
                    vm.processing = false;
                }).catch(function (err) {
                    console.log(err)
                    vm.processing = false;
                })
        }

        function setChartData(data) {
            var labels = [];
            var values = [];
            try {
                data.forEach(function (item) {
                    labels.push(item.name)
                    values.push(item.value);
                });
            } catch (error) {
                console.log(error);
            }
            return { labels: labels, values: values };
        }

        function setAudienceAgeData() {
            var data = setChartData(vm.campaignInsights.age);
            vm.audienceAgeLabels = data.labels;
            vm.audienceAgeValues = data.values;
        }

        function setAudienceGenderData() {
            var data = setChartData(vm.campaignInsights.gender);
            vm.audienceGenderLabels = data.labels.map(function (label) {
                return $translate.instant('integrations.facebookBusiness.insights.' + label)
            });
            vm.audienceGenderValues = data.values;
        }

        function setAudienceRegionData() {
            var data = setChartData(vm.campaignInsights.region);
            vm.audienceRegionLabels = data.labels;
            vm.audienceRegionValues = data.values;
        }

        function setAudiencPlatformData() {
            var data = setChartData(vm.campaignInsights.devicePlatform);
            vm.audiencePlatformLabels = data.labels.map(function (label) {
                return $translate.instant('integrations.facebookBusiness.insights.' + label)
            });
            vm.audiencePlatformValues = data.values;
        }

        vm.getRateValue = function (value, total) {
            total = total === undefined ? 1 : total;
            return value > 0 ? ((value / total) * 100).toFixed(2) + '%' : '0%';
        }

        vm.getCostValue = function (value) {
            return value.toFixed(2);
        }

        getCampaignInsights(vm.campaignId);
    }
})();
