(function() {
    'use strict';

    angular
        .module('showcaseApp')
        .controller('AddSalesPointManagementController', AddSalesPointManagementController);

    AddSalesPointManagementController.$inject = ['$scope', 'StoreService', 'CheckCEPService', 'GoogleMapsService', 'pointlocation', '$uibModalInstance'];

    function AddSalesPointManagementController($scope, StoreService, CheckCEPService, GoogleMapsService, pointlocation, $uibModalInstance) {
        var vm = this;

        const ICON_ACTIVE = 'content/images/pointinactive.png';

        vm.countrys = {};
        vm.states = {};
        vm.salespoint = pointlocation;
        vm.options= {
            fullscreenControl: false
        };
        vm.mainMap = {
            center: {
                latitude: (vm.salespoint != undefined ? vm.salespoint.latitude : 45),
                longitude: (vm.salespoint != undefined ? vm.salespoint.longitude : -73)
            },
            zoom: 10,
            control: {}
        };
        vm.mainMap.bounds = {};
        vm.mainMap.markers = [vm.salespoint];
        vm.mainMap.markerOptions = {
            draggable: true,
            icon: ICON_ACTIVE
        };
        vm.isNewSalesPoint = false;
        vm.mainMap.markerEvents = {
            dragend: function(marker, eventName, model) {
                vm.refreshMapWithCoordinates(marker.position.lat(), marker.position.lng());
            }
        }

        loadCountrysAndStates();
        checkIsNewSalesPoint();
        loadParams();


        function loadCountrysAndStates() {
            CheckCEPService.listStates().then(function(response) {
                vm.states = response.data;
            });
            CheckCEPService.listCountrys().then(function(response) {
                vm.countrys = response.data;
            });
            vm.showMap = true;
        }

        function checkIsNewSalesPoint() {
            if (vm.salespoint === undefined) {
                getLocation();
                vm.isNewSalesPoint = true;
                vm.salespoint = {
                    id: 2
                };
            }
        }

        vm.checkCEP = function() {
            if (vm.salespoint.pointlocation.postalCode && vm.salespoint.pointlocation.postalCode.length === 8) {
                CheckCEPService.getAddress(vm.salespoint.pointlocation.postalCode).then(function(response) {
                    if (response.data){
                    vm.salespoint.pointlocation.country = "BRASIL";
                    vm.salespoint.pointlocation.city = response.data.location.name;
                    vm.salespoint.pointlocation.state = response.data.location.uf;
                    vm.salespoint.pointlocation.address = response.data.name;
                    vm.salespoint.pointlocation.neighborhood = response.data.district.name;
                    vm.refreshMapWithAddress();
                    }
                    
                });
            }

        }

        vm.updatePointLocation = function() {
            vm.salesPointFormSubmitted = true;
            if (!vm.salesPointForm.$invalid) {
                var newPoint = vm.salespoint.pointlocation;
                newPoint.coordinate = {
                    latitude: "",
                    longitude: ""
                };
                newPoint.coordinate.latitude = vm.salespoint.latitude;
                newPoint.coordinate.longitude = vm.salespoint.longitude;
                if (vm.isNewSalesPoint) {
                    $scope.$emit('salesPointCreated', vm.salespoint);
                    $uibModalInstance.close(vm.salespoint);
                } else {
                    vm.isSaving = false;
                    StoreService.updateSalesPoint(newPoint).then(function(data, error) {
                        vm.isSaving = true;
                        $uibModalInstance.close(vm.salespoint);
                    });
                    $scope.$emit('salesPointUpdated', vm.salespoint);
                    $uibModalInstance.close(vm.salespoint);
                }
            }
        }

        vm.closeModal = function() {
            $uibModalInstance.close();
        }

        vm.refreshMapWithCoordinates = function(lat, long) {
            GoogleMapsService.getAddressByCoordinates(lat, long).then(function(data) {
                var location = data.address_components;
                vm.salespoint.pointlocation.country = location[6].long_name.toUpperCase();
                vm.salespoint.pointlocation.city = location[3].long_name;
                vm.salespoint.pointlocation.state = location[5].short_name;
                vm.salespoint.pointlocation.address = location[1].long_name;
                vm.salespoint.pointlocation.neighborhood = location[2].long_name;
            });
        }

        vm.refreshMapWithAddress = function() {
            if (vm.salespoint.pointlocation){
                GoogleMapsService.getAddress((vm.salespoint.pointlocation.number + ", " + vm.salespoint.pointlocation.address + ", " + vm.salespoint.pointlocation.neighborhood + ", " + vm.salespoint.pointlocation.city + ", " + vm.salespoint.pointlocation.state + ", " + vm.salespoint.pointlocation.country).replace(/undefined/g, '').replace(/null/g, '')).then(function(data) {
                    vm.salespoint.latitude = data.geometry.location.lat();
                    vm.salespoint.longitude = data.geometry.location.lng();
                    vm.mainMap.center.latitude = vm.salespoint.latitude;
                    vm.mainMap.center.longitude = vm.salespoint.longitude;
                });
            }
        }


        function getLocationSuccFn(position) {
            vm.mainMap.center.latitude = position.coords.latitude;
            vm.mainMap.center.longitude = position.coords.longitude;
            vm.mainMap.control.refresh({
                latitude: vm.mainMap.center.latitude,
                longitude: vm.mainMap.center.longitude
            });
        }

        function getLocation() {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(getLocationSuccFn, function(error) {}, {
                    timeout: 3000
                });
            }
        }

        function loadParams() {
            if ($scope.store){
                vm.salespoint.pointlocation = {name: $scope.store.name};
                vm.salespoint.pointlocation.contactPhone = $scope.store.contactPhone;            
            }
        }
    }
})();
