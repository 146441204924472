(function () {
    'use strict';
    angular
        .module('showcaseApp')
        .service('BrandService', BrandService);

    BrandService.$inject = ['BrandFactoryAdmin'];

    function BrandService(BrandFactoryAdmin) {
        var service = {
            getBrandsByCurrentStore: getBrandsByCurrentStore,
            findBrand: findBrand,
            createBrand: createBrand,
            getBrandById: getBrandById,
            removeBrand: removeBrand,
            editBrand: editBrand
        };

        return service;

        function getBrandsByCurrentStore() {
            return BrandFactoryAdmin.getBrandsByCurrentSTore().$promise;
        }

        function findBrand(brandName) {
            return BrandFactoryAdmin.findBrand({ "name": brandName }).$promise;
        }

        function createBrand(brandName) {
            return BrandFactoryAdmin.createBrand({ "name": brandName }).$promise;
        }

        function getBrandById(brandId) {
            return BrandFactoryAdmin.getBrandById({"brandId": brandId}).$promise;
        }

        function removeBrand(brandId) {
            return BrandFactoryAdmin.removeBrand({"brandId": brandId}).$promise;
        }

        function editBrand(brand) {   
            return BrandFactoryAdmin.editBrand({"brandId": brand.id}, brand).$promise;
        }

    }
})();
