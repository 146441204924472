(function() {
    'use strict';

    angular
        .module('showcaseApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('customer', {
            parent: 'admin',
            abstract: true,
            data: {
                authorities: ['STORE_READ_CUSTOMER'],
                pageTitle: 'customers.home.title'
            },
            views: {
                'content@app': {
                    templateUrl: 'app/admin/customer/customer.html'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('customers');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('communication');
                    return $translate.refresh();
                }]
            }
        })
        .state('customers', {
            parent: 'customer',
            url: '/customers',
            data: {
                pageTitle: 'customer.title',
                authorities: [],
                // specialClass: "md-skin fixed-nav mini-navbar"
            },
            views: {
                'customer-list': {
                    templateUrl: 'app/admin/customer/customers-list.html',
                    controller: 'CustomersListController',
                    controllerAs: 'vm'
                },
                'customer-detail': {
                    templateUrl: 'app/admin/customer/customers-detail.html',
                    controller: 'CustomersDetailController',
                    controllerAs: 'vm'
                }
            }
        }).state('import-customers', {
            parent: 'app',
            url: '/customers-import',
            data: {
                pageTitle: 'customer.importTitle',
                authorities: ['STORE_CREATE_CUSTOMER'],
                specialClass: "md-skin fixed-nav"
            },
            views: {
                'content@app': {
                    templateUrl: 'app/admin/customer/import/customers-import.html',
                    controller: 'CustomersImportController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('customers');
                    return $translate.refresh();
                }]
            }
        });
    }
})();
