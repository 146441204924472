(function() {
    'use strict';
    angular
        .module('showcaseApp')
        .factory('MoipPlansFactory', MoipPlansFactory);

    MoipPlansFactory.$inject = ['$resource'];

    function MoipPlansFactory($resource) {
        var resourceUrl =  'api/signatures';

        return $resource(resourceUrl, {}, {
            'getSubscriber': {
                method: 'GET',
                url: resourceUrl + '/subscriber/:code',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data){
                        response.data = angular.fromJson(data);
                    } else{
                        response.data = data;
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'getPlans': {
                method: 'GET',
                url: 'api/plan/list',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data){
                        response.data = angular.fromJson(data);
                    } else{
                        response.data = data;
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'createSubscriberFree': {
                method: 'GET',
                url: resourceUrl + '/subscription/free',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data){
                        response.data = angular.fromJson(data);
                    } else{
                        response.data = data;
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'updateSubscriber': {
                method: 'PUT',
                url: resourceUrl + '/subscriber',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data){
                        response.data = angular.fromJson(data);
                    } else{
                        response.data = data;
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'createSubscriber': {
                method: 'POST',
                url: resourceUrl + '/subscriber',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data){
                        response.data = angular.fromJson(data);
                    } else{
                        response.data = data;
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'updateSubscriberCard': {
                method: 'PUT',
                url: resourceUrl + '/subscriber/card',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data){
                        response.data = angular.fromJson(data);
                    } else{
                        response.data = data;
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'validateCoupon': {
                method: 'GET',
                url: resourceUrl + '/coupon/:coupon',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data){
                        response.data = angular.fromJson(data);
                    } else{
                        response.data = data;
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'getPlanByCode': {
                method: 'GET',
                url: resourceUrl + '/plans/:code',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data){
                        response.data = angular.fromJson(data);
                    } else{
                        response.data = data;
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'isBilletOverdue': {
                method: 'GET',
                url: resourceUrl + '/subscription/is-actual-billet-overdue',
                transformResponse: function (data, headers){
                    var response = {};
                    if (data){
                        response.data = angular.fromJson(data);
                    } else{
                        response.data = data;
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'generateBillet': {
                method: 'GET',
                url: resourceUrl + '/subscription/generate-billet',
                transformResponse: function (data, headers){
                    var response = {};
                    if (data){
                        response.data = angular.fromJson(data);
                    } else{
                        response.data = data;
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'resetBillet': {
                method: 'GET',
                url: resourceUrl + '/subscription/reset-billet',
                transformResponse: function (data, headers){
                    var response = {};
                    if (data){
                        response.data = angular.fromJson(data);
                    } else{
                        response.data = data;
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'updateSubscriptionGiftCard': {
                method: 'PUT',
                url: resourceUrl + '/subscription/updateGiftCard',
                transformResponse: function (data, headers){
                    var response = {};
                    if (data){
                        response.data = angular.fromJson(data);
                    } else{
                        response.data = data;
                    }
                    response.headers = headers();
                    return response;
                }
            }

        });
    }

})();
