(function () {
    'use strict';

    angular
        .module('showcaseApp')
        .controller('FBAuthModalController', FBAuthModalController);

        FBAuthModalController.$inject = ['$uibModalInstance', '$timeout', 'FacebookService', '$translate',
        'toastr', '$q', 'ChatCommerceService', 'MessengerService', '$scope', 'isCommunication'
    ];

    function FBAuthModalController($uibModalInstance, $timeout, FacebookService, $translate,
        toastr, $q, ChatCommerceService, MessengerService, $scope, isCommunication) {

        var vm = this;

        var SOURCE_MESSENGER = 'MESSENGER';

        vm.isCommunication = isCommunication;
        vm.selectedCity;
        vm.facebook = {
            catalogGenerated: false,
            instagram: {
                id: ''
            },
            business: {
                id: ''
            },
            feed: {
                id: ''
            },
            page: {
                id: ''
            },
            paymentAccount: {
                id: ''
            },
            connected: false
        };
        vm.isBussy = false;
        vm.userBusinesses = [];
        vm.userInstagramAccounts = [];
        vm.user = {};
        vm.currentStep = 0;
        vm.totalOfSteps = 5;
        vm.selectedBusiness;
        vm.selectedAdAccount;
        vm.selectedInstagram;
        vm.selectedBusinessPage;
        vm.searchText = null;

        vm.syncProgress = 0;

        vm.progressInterval;

        vm.confirm = function () {
            $uibModalInstance.close(true);
        };

        vm.closeModal = function () {
            $uibModalInstance.close(false);
        };

        vm.fbLogin = function () {
            vm.processing = true;
            FacebookService.login().then(updateStatus);
        };

        vm.fbLogout = function () {
            FacebookService.logout().then(updateStatus);
        };

        vm.checkFbStatus = function () {
            // TODO
        };

        vm.loadUserData = function () {
            vm.processing = true;
            if (vm.facebook.connected) {
                FacebookService.me().then(function (data) {
                    vm.user = data;
                    vm.processing = false;
                })
            }
        };

        function updateStatus(response) {
            // vm.processing = false;
            if (response.status === 'connected') {
                vm.facebook.connected = true;
                vm.facebook.data = response;
                vm.loadBusiness();
                vm.loadUserData();

                if (!vm.selectedInstagram) return;

                vm.connectData = {
                    accessToken: vm.facebook.data.authResponse.accessToken,
                    igBusinessAccount: { igAccountId: vm.selectedInstagram.id, name: vm.selectedInstagram.username },
                    businessId: vm.selectedBusiness.id,
                    pageId: vm.selectedBusinessPage.id,
                    adsAccount: vm.selectedAdAccount ? vm.selectedAdAccount.id : ''
                }

                MessengerService.makeMessengerCredentials(vm.connectData).then(function(result) {
                    console.log('Messenger connected automatic');
                }).catch(function(error) {

                });
            } else {
                vm.facebook.connected = false;
                vm.userBusinesses = [];
                vm.facebook.data = {};
            }

        }

        vm.loadBusiness = function (token) {
            vm.selectedBusiness = null;
            vm.processing = true;
            FacebookService.business(token).then(function (res) {
                 if (res.data && res.data.length > 0) { 
                    vm.userBusinesses = vm.userBusinesses.concat(res.data);
                 }
                if (res.paging && res.paging.next) {
                    vm.loadBusiness(res.paging.cursors.after);
                } 
            }).catch(function(err) {
                console.log("ERR::", err);
            }).finally(function(){
                vm.processing = false;
            });
        };

        vm.loadBusinessPages = function () {
            vm.selectedBusinessPage = null;
            vm.processing = true;
            FacebookService.businessPages(vm.selectedBusiness.id).then(function (res) {
                vm.userBusinessesPages = res.data;
                vm.processing = false;
            }).catch(function(err) {
                console.log("ERR::", err);
            }).finally(function(){
                vm.processing = false;
            });
        };

        vm.loadBusinessInsta = function () {
            vm.userInstagramAccounts = [];
            if (vm.selectedBusinessPage.instagram_business_account) {
                vm.userInstagramAccounts.push(vm.selectedBusinessPage.instagram_business_account);
            }
        };

        vm.querySearch = function (query) {

            vm.deferred = $q.defer();
            FacebookService.searchCity(query)
                .then(function (res) {
                    vm.deferred.resolve(res)
                });
            return vm.deferred.promise;
        }

        /**
         * On Init
         */
        $timeout(function () {
            vm.checkFbStatus();
        }, 500);

        vm.next = function () {
            vm.currentStep < vm.totalOfSteps ?
                (vm.currentStep = vm.currentStep + 1) : '';

        };

        vm.previous = function () {
            if (vm.currentStep === 1) {
                vm.selectedBusiness = null;
            }
            if (vm.currentStep === 2) {
                vm.selectedBusinessPage = null;
            }
            vm.currentStep !== 0 ? (vm.currentStep = vm.currentStep - 1) : '';
        };

        vm.nextDisabled = function () {
            switch (vm.currentStep) {
                case 0:
                    return vm.facebook.connected && vm.selectedBusiness ? false : true;
                case 1:
                    return vm.selectedBusinessPage ? false : true;
                case 2:
                    return vm.selectedInstagram ? false : true;
                case 3:
                    return false;
                default:
                    return false;
            }
        };

        vm.previousDisabled = function () {
            switch (vm.currentStep) {
                case 2:
                    return vm.facebook.catalog.id ? true : false;
                case 3:
                    return vm.campaingResult ? true : false;
                case 4:
                    return true;

                default:
                    return false;
            }
        };

        vm.modalTitle = function () {
            switch (vm.currentStep) {
                case 0:
                    return {title: 'Selecione seu negócio', subTitle: 'Aqui você seleciona seu negócio do Facebook'} ;
                case 1:
                    return {title: 'Selecione sua página', subTitle: 'Aqui você seleciona a página na qual seu anúncio será vinculado'};
                case 2:
                    return {title: 'Selecione o instagram', subTitle: 'Aqui você seleciona sua conta do instagram'} ;
                case 3:
                    return {title: 'Selecione sua conta de anúncios', subTitle: 'Essa conta precisa ter um meio de pagamento ativo'};
                default:
                    return false;
            }
        };

        vm.connectFacebook = function () {
            vm.isBuying = true;

            vm.connectData = {
                accessToken: vm.facebook.data.authResponse.accessToken,
                igBusinessAccount: { igAccountId: vm.selectedInstagram.id, name: vm.selectedInstagram.username },
                businessId: vm.selectedBusiness.id,
                pageId: vm.selectedBusinessPage.id,
                adsAccount: vm.selectedAdAccount ? vm.selectedAdAccount.id : ''
            }

            vm.progressInterval = setInterval(function () {
                if (vm.syncProgress === 100) {
                    clearInterval(vm.progressInterval);
                    return;
                }
                vm.syncProgress = vm.syncProgress + 5;
                $scope.$apply();
            }, 1000);

            ChatCommerceService.connectToChat({
                facebookAccountData: vm.connectData,
                chatPlatform: SOURCE_MESSENGER
            }).then(onSuccessFacebookConnect, onErrorFacebookConnect);

        }

        function onSuccessFacebookConnect(response) {
            if (response.facebookAccountData && response.facebookAccountData.accessToken) {
                vm.syncProgress = 100;
                clearInterval(vm.progressInterval);
                setTimeout(function () {
                    vm.isBuying = false;
                    $uibModalInstance.close(true);
                    toastr.success($translate.instant('socialMedia.success.successFacebookConnect'));
                }, 1000);
            }
        }

        function onErrorFacebookConnect(error) {
            vm.isBuying = false;
            vm.syncProgress = 0;
            clearInterval(vm.progressInterval);
            if (error.data) {
                if (error.data.headers['x-adminapp-alert'] == 'noPagesFound'){
                    ModalFactory.openSimpleModal(
                        $translate.instant('socialMedia.error.noPagesFoundTitle'),
                        $translate.instant('socialMedia.error.noPagesFoundMessage'),
                        $translate.instant('socialMedia.error.noPagesFoundConfirmText'),
                        null,
                        vm.facebookDisconnect).then(null, function (result){});
                }
            }
        }

        vm.loadAdAcconunts = function () {
            vm.selectedAdAccount = null;
            vm.processing = true;
            FacebookService.adAccounts(vm.selectedBusiness.id).then(function (res) {
                vm.userAdAccounts = res.data;
                // if (vm.userAdAccounts && !vm.userAdAccounts.length) {
                //     vm.createAdAccount();
                // }
                vm.processing = false;
            }, function() {
                vm.processing = false;

            });
        };

        vm.createAdAccount = function () {
            vm.processing = true;
             FacebookService.createAdAccount(vm.selectedBusiness.id, vm.facebook.data.authResponse.accessToken).then(function (res) {
                vm.processing = false;
                vm.loadAdAcconunts();
            }).catch(function(err) {
                vm.processing = false;
                toastr.error($translate.instant('integrations.facebookBusiness.errors.createCampain'));
            });
        };

        vm.addPaymentMethod = function() {
            FB.ui(
                {
                    account_id: vm.selectedAdAccount.account_id,
                    display: 'popup',
                    method: 'ads_payment',
                },   // callback
                function(response) {
                    if (response && !response.error_message) {
                        vm.loadAdAcconunts();
                        toastr.success("Adicionado com sucesso!");
                    } else {
                        toastr.error("Erro ao adicionar meio de pagamento!");
                    }
              });
        }
    }
})();
