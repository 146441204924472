(function () {
    'use strict';
    angular
        .module('showcaseApp')
        .constant('DOMAIN', "showkase.com.br")
        .constant('Constants', {
            ErpPartnerId: {
                VAREJO_ONLINE: 8,
                NEXAAS: 9,
                BLING: 10,
                SETA: 11,
                SOFTCOM: 12
            }
        });
        //showkase.com.br:  1927510520841177
        //tukluc.com: 106102010234151
        //showkase.online: 361048703918081
        //ShowkaseTeste: 1069039666583627

})();
