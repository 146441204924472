(function() {
    'use strict';
    angular
    .module('showcaseApp')
    .filter('statusFilter', function($translate) {

        return function (value) {
            var newValue;

            switch (value) {
                case 'WAITING_PAYMENT':
                    newValue = $translate.instant('invoice.states.waiting');
                    break;
                case 'PAID':
                    newValue = $translate.instant('invoice.states.paid');
                    break;
                case 'CANCELLED':
                    newValue = $translate.instant('invoice.states.cancelled');
                    break;
                case 'WAITING_CONFIRMATION':
                		newValue = $translate.instant('invoice.states.waitingConfirmation');
                	break;
                case 'CONFIRMED':
                	newValue = $translate.instant('invoice.states.confirmed');
                	break;
                case 'SEPARATING':
                		newValue = $translate.instant('invoice.states.separating');
                	break;
                case 'AVAILABLE_TO_DELIVERY':
                		newValue = $translate.instant('invoice.states.availableToDelivery');
                	break;
                case 'DELIVERED':
                	newValue = $translate.instant('invoice.states.delivered');
                	break;
                case 'NOT_PAID':
                    newValue = $translate.instant('invoice.states.cancelled');
                    break;
                case 'REVERTED':
                    newValue = $translate.instant('invoice.states.reverted');
                    break;
                default:
                    break;
            }
            return newValue;
        }
    });
})();
