(function() {
    'use strict';
    angular
        .module('showcaseApp')
        .factory('UserProfileFactory', UserProfileFactory);

    UserProfileFactory.$inject = ['$resource'];

    function UserProfileFactory ($resource) {

        var resourceUrl =  'api/profile/:id';

        return $resource(resourceUrl, {}, {
            'query': {
                method: 'GET',
                isArray: false,
                transformResponse: function (data, headers) {
                    var response = {};
                    response.data = angular.fromJson(data);
                    response.headers = headers();
                    return response;
                }
            },
            'get': {
                method: 'GET',
                transformResponse: function (data, headers) {
                    var response = {};
                    response.data = angular.fromJson(data);
                    response.headers = headers();
                    return response;
                }
            },
            'listByParams': {
                method: 'POST',
                url: '/api/profile/list',
                transformResponse: function (data, headers) {
                    var response = {};
                    response.data = angular.fromJson(data);
                    response.headers = headers();
                    return response;
                }
            },
            'update': { method:'PUT' },
            'delete': { method:'DELETE' }
        });
    }
})();
