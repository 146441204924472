(function() {
    'use strict';

    angular
        .module('showcaseApp')
        .controller('TeamManagementDialogController', TeamManagementDialogController);

        TeamManagementDialogController.$inject = ['$scope', '$uibModalInstance', 'TeamService', 'UserService', 'storeId','team', 'toastr', '$translate'];

    function TeamManagementDialogController($scope, $uibModalInstance, TeamService, UserService, storeId, team, toastr, $translate) {
        var format = 'L';
        var vm = this;

        vm.maskLocale = '99/99/9999';
        
        vm.clear = clear;
        vm.save = save;
        vm.storeId = storeId;
        vm.team = team ? team : {};
        vm.isSaving = false;
        vm.users = [];
        vm.usersSelected = team && team.users ? team.users : [];

        loadAll();

        function loadAll() {
            loadUsers();
        }

        function loadUsers() {
            var pageable = {
                size: 100,
                page: 0
            };
            var params = {
                login: "",
                firstName: "",
                lastName: "",
                email: "",
                all: true
            };

            UserService.listUsersByStoreId(pageable, vm.storeId, params).then(function(response) {
                if (response.data) {
                    vm.users = response.data;
                }
            });
        }

        vm.isUserSelected = function(user) {
            return vm.usersSelected.indexOf(user.id) >= 0;
        }

        vm.selectUnselectUser = function(user) {
            var index = vm.usersSelected.indexOf(user.id);
            if (index >= 0) {
                vm.usersSelected.splice(index, 1);
            } else {
                vm.usersSelected.push(user.id)
            }
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function onSaveSuccess(result) {
            vm.isSaving = false;
            $scope.$emit('userUpdated', result);
            toastr.success($translate.instant('team-management.created'));
            $uibModalInstance.close(result);
        }
        
        function onUpdateSuccess(result) {
            vm.isSaving = false;
            $scope.$emit('userUpdated', result);
            toastr.success($translate.instant('team-management.updated'));
            $uibModalInstance.close(result);
        }

        function onSaveError (error) {
            vm.isSaving = false;
            if (error.data && error.data.data && error.data.data == "TEAM_NAME_DUPLICATED") {
                toastr.error($translate.instant('team-management.error.duplicatedName', { name: vm.team.name }));
            } else {
                toastr.error($translate.instant('team-management.error.save'));
            }
        }

        function save (form) {
            if (form && form.$invalid && form.teamName && form.teamName.$error && form.teamName.$error.required) {
                return;
            } else if (form && form.$invalid && form.teamName && form.teamName.$error.maxlength && form.teamName.$error.maxlength) {
                return;
            } else if (!vm.usersSelected || vm.usersSelected.length == 0) {
                toastr.error($translate.instant('team-management.error.userListEmpty'));
                return;
            }

            if(form.$valid){
                vm.isSaving = true;

                var teamToSave = angular.copy(vm.team);
                teamToSave.storeId = vm.storeId;
                teamToSave.users = vm.usersSelected;
                if (teamToSave.id) {
                    TeamService.update(teamToSave.id, teamToSave).then(function (result) {
                        onUpdateSuccess(result);
                    }, function (error) {
                        onSaveError(error);
                    });
                } else {
                    TeamService.create(teamToSave).then(function (result) {
                        onSaveSuccess(result);
                    }, function (error) {
                        console.log(error);
                        onSaveError(error);
                    });
                }
            }
        }
    }
})();
