(function() {
    'use strict';

    angular
        .module('showcaseApp')
        .controller('ChatContactsSkeletonController', ChatContactsSkeletonController);

    ChatContactsSkeletonController.$inject = ['$scope'];

    function ChatContactsSkeletonController($scope) {
        var vm = this;

        vm.conversationHeight = window.innerHeight - 71;
        vm.cards = [];

        function init() {
            var lenght = $scope.cardQtt ? $scope.cardQtt : 1;
            vm.cards = Array.from({length: lenght}, function(v, k) { return k+1 });
        }

        init();
    }

})();
