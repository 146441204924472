(function() {
    'use strict';

    angular
        .module('showcaseApp')
        .directive('noContenteditable', noContenteditable);

    /* @ngInject */
    function noContenteditable() {
        /**
         * @ngdoc directive
         * @name app.directive:noContenteditable
         * @description
         * # noContenteditable
         */
        var directive = {
            link: link,
            restrict: 'EA'
        };

        return directive;

        function link(scope, element, attrs, ngModel){
            element.on('keypress', function(event) {
                if (event && event.keyCode == 13 && !event.shiftKey) {
                    event.preventDefault();
                }
            });
        };
    };
})();
