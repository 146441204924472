(function () {
    'user strict'

    angular
        .module('showcaseApp')
        .controller('ShowcaseSettingsController', ShowcaseSettingsController);

    function ShowcaseSettingsController(toastr, $state, $localStorage, $translate, Constants,
                                    ModalFactory, StoreService, ShowcaseSettingsService) {

        var vm = this;

        vm.isMobile = detectar_mobile();
        vm.store = $localStorage.defaultStore;
        vm.showcaseSettings = undefined;
        
        init();

        function init() {
            loadSettigns();
        }

        function loadSettigns() {
            ShowcaseSettingsService.getByStoreId($localStorage.defaultStore.storeId).then(function (result) {
                console.log(result.data);
                if (result.data) {
                    vm.showcaseSettings = result.data;
                }
            })
        }

        vm.updateSettings = function() {
            console.log(vm.showcaseSettings);
            ModalFactory.openWarningModal(
                null,
                $translate.instant('settings.showcase.general.save-modal.title'),
                $translate.instant('settings.showcase.general.save-modal.small-text'),
                $translate.instant('settings.showcase.general.save-modal.warning-text'),
                $translate.instant('entity.action.continue'),
                $translate.instant('entity.action.cancel'),
                function () {
                    vm.isSaving = true;
                    ShowcaseSettingsService.update($localStorage.defaultStore.storeId, vm.showcaseSettings).then(function (result) {
                        console.log(result.data);
                        if (result.data) {
                            vm.showcaseSettings = result.data;
                        }
                        toastr.success($translate.instant('settings.showcase.general.messages.success'));
                    }).catch(function (err) {
                        vm.isSaving = false;
                        toastr.error($translate.instant('settings.showcase.general.messages.error'));
                    });
                }
            );
        }
    }
})();
