(function() {
    'use strict';

    angular
        .module('showcaseApp')
        .constant('EmojisConstants', {
            "EMOJIS":[
                {
                  "code": "1f600",
                  "name": "grinning face",
                  "category": "people",
                  "keywords": "['face', 'grin']",
                  "display": 1,
                  "translatedName": "sorrindo cara"
                },
                {
                  "code": "1f603",
                  "name": "smiling face with open mouth",
                  "category": "people",
                  "keywords": "['face', 'mouth', 'open', 'smile']",
                  "display": 1,
                  "translatedName": "rosto sorridente com boca aberta"
                },
                {
                  "code": "1f604",
                  "name": "smiling face with open mouth & smiling eyes",
                  "category": "people",
                  "keywords": "['eye', 'face', 'mouth', 'open', 'smile']",
                  "display": 1,
                  "translatedName": "Rosto sorridente com boca aberta e olhos de sorriso"
                },
                {
                  "code": "1f601",
                  "name": "grinning face with smiling eyes",
                  "category": "people",
                  "keywords": "['eye', 'face', 'grin', 'smile']",
                  "display": 1,
                  "translatedName": "sorrindo cara com olhos sorridentes"
                },
                {
                  "code": "1f606",
                  "name": "smiling face with open mouth & closed eyes",
                  "category": "people",
                  "keywords": "['face', 'laugh', 'mouth', 'open', 'satisfied', 'smile']",
                  "display": 1,
                  "translatedName": "Rosto sorridente com boca aberta e olhos fechados"
                },
                {
                  "code": "1f605",
                  "name": "smiling face with open mouth & cold sweat",
                  "category": "people",
                  "keywords": "['cold', 'face', 'open', 'smile', 'sweat']",
                  "display": 1,
                  "translatedName": "Rosto sorridente com boca aberta e suor frio"
                },
                {
                  "code": "1f602",
                  "name": "face with tears of joy",
                  "category": "people",
                  "keywords": "['face', 'joy', 'laugh', 'tear']",
                  "display": 1,
                  "translatedName": "cara com lágrimas de alegria"
                },
                {
                  "code": "1f923",
                  "name": "rolling on the floor laughing",
                  "category": "people",
                  "keywords": "['face', 'floor', 'laugh', 'rolling']",
                  "display": 1,
                  "translatedName": "rolando de rir no chão"
                },
                {
                  "code": "263a",
                  "name": "smiling face",
                  "category": "people",
                  "keywords": "['face', 'outlined', 'relaxed', 'smile']",
                  "display": 1,
                  "translatedName": "rosto sorridente"
                },
                {
                  "code": "1f60a",
                  "name": "smiling face with smiling eyes",
                  "category": "people",
                  "keywords": "['blush', 'eye', 'face', 'smile']",
                  "display": 1,
                  "translatedName": "Rosto sorridente com olhos sorridentes"
                },
                {
                  "code": "1f607",
                  "name": "smiling face with halo",
                  "category": "people",
                  "keywords": "['angel', 'face', 'fairy tale', 'fantasy', 'halo', 'innocent', 'smile']",
                  "display": 1,
                  "translatedName": "rosto sorridente com halo"
                },
                {
                  "code": "1f642",
                  "name": "slightly smiling face",
                  "category": "people",
                  "keywords": "['face', 'smile']",
                  "display": 1,
                  "translatedName": "cara ligeiramente sorridente"
                },
                {
                  "code": "1f643",
                  "name": "upside-down face",
                  "category": "people",
                  "keywords": "['face', 'upside-down']",
                  "display": 1,
                  "translatedName": "rosto de cabeça para baixo"
                },
                {
                  "code": "1f609",
                  "name": "winking face",
                  "category": "people",
                  "keywords": "['face', 'wink']",
                  "display": 1,
                  "translatedName": "cara piscando"
                },
                {
                  "code": "1f60c",
                  "name": "relieved face",
                  "category": "people",
                  "keywords": "['face', 'relieved']",
                  "display": 1,
                  "translatedName": "rosto aliviado"
                },
                {
                  "code": "1f60d",
                  "name": "smiling face with heart-eyes",
                  "category": "people",
                  "keywords": "['eye', 'face', 'love', 'smile']",
                  "display": 1,
                  "translatedName": "cara sorridente com coração-olhos"
                },
                {
                  "code": "1f618",
                  "name": "face blowing a kiss",
                  "category": "people",
                  "keywords": "['face', 'kiss']",
                  "display": 1,
                  "translatedName": "cara soprando um beijo"
                },
                {
                  "code": "1f617",
                  "name": "kissing face",
                  "category": "people",
                  "keywords": "['face', 'kiss']",
                  "display": 1,
                  "translatedName": "Beijando o rosto"
                },
                {
                  "code": "1f619",
                  "name": "kissing face with smiling eyes",
                  "category": "people",
                  "keywords": "['eye', 'face', 'kiss', 'smile']",
                  "display": 1,
                  "translatedName": "Beijando o rosto com olhos sorridentes"
                },
                {
                  "code": "1f61a",
                  "name": "kissing face with closed eyes",
                  "category": "people",
                  "keywords": "['closed', 'eye', 'face', 'kiss']",
                  "display": 1,
                  "translatedName": "Beijando o rosto com os olhos fechados"
                },
                {
                  "code": "1f60b",
                  "name": "face savouring delicious food",
                  "category": "people",
                  "keywords": "['delicious', 'face', 'savouring', 'smile', 'um', 'yum']",
                  "display": 1,
                  "translatedName": "rosto saboreando comida deliciosa"
                },
                {
                  "code": "1f61c",
                  "name": "face with stuck-out tongue & winking eye",
                  "category": "people",
                  "keywords": "['eye', 'face', 'joke', 'tongue', 'wink']",
                  "display": 1,
                  "translatedName": "cara com língua presa e olho piscando"
                },
                {
                  "code": "1f61d",
                  "name": "face with stuck-out tongue & closed eyes",
                  "category": "people",
                  "keywords": "['eye', 'face', 'horrible', 'taste', 'tongue']",
                  "display": 1,
                  "translatedName": "cara com língua presa e olhos fechados"
                },
                {
                  "code": "1f61b",
                  "name": "face with stuck-out tongue",
                  "category": "people",
                  "keywords": "['face', 'tongue']",
                  "display": 1,
                  "translatedName": "cara com língua presa"
                },
                {
                  "code": "1f911",
                  "name": "money-mouth face",
                  "category": "people",
                  "keywords": "['face', 'money', 'mouth']",
                  "display": 1,
                  "translatedName": "rosto de boca-boca"
                },
                {
                  "code": "1f917",
                  "name": "hugging face",
                  "category": "people",
                  "keywords": "['face', 'hug', 'hugging']",
                  "display": 1,
                  "translatedName": "Abraçando o rosto"
                },
                {
                  "code": "1f913",
                  "name": "nerd face",
                  "category": "people",
                  "keywords": "['face', 'geek', 'nerd']",
                  "display": 1,
                  "translatedName": "Nerd face"
                },
                {
                  "code": "1f60e",
                  "name": "smiling face with sunglasses",
                  "category": "people",
                  "keywords": "['bright', 'cool', 'eye', 'eyewear', 'face', 'glasses', 'smile', 'sun', 'sunglasses']",
                  "display": 1,
                  "translatedName": "rosto sorridente com óculos de sol"
                },
                {
                  "code": "1f921",
                  "name": "clown face",
                  "category": "people",
                  "keywords": "['clown', 'face']",
                  "display": 1,
                  "translatedName": "rosto palhaço"
                },
                {
                  "code": "1f920",
                  "name": "cowboy hat face",
                  "category": "people",
                  "keywords": "['cowboy', 'cowgirl', 'face', 'hat']",
                  "display": 1,
                  "translatedName": "Cara do chapéu de cowboy"
                },
                {
                  "code": "1f60f",
                  "name": "smirking face",
                  "category": "people",
                  "keywords": "['face', 'smirk']",
                  "display": 1,
                  "translatedName": "Smirking Face."
                },
                {
                  "code": "1f612",
                  "name": "unamused face",
                  "category": "people",
                  "keywords": "['face', 'unamused', 'unhappy']",
                  "display": 1,
                  "translatedName": "rosto não amuado"
                },
                {
                  "code": "1f61e",
                  "name": "disappointed face",
                  "category": "people",
                  "keywords": "['disappointed', 'face']",
                  "display": 1,
                  "translatedName": "rosto desapontado"
                },
                {
                  "code": "1f614",
                  "name": "pensive face",
                  "category": "people",
                  "keywords": "['dejected', 'face', 'pensive']",
                  "display": 1,
                  "translatedName": "rosto pensativo"
                },
                {
                  "code": "1f61f",
                  "name": "worried face",
                  "category": "people",
                  "keywords": "['face', 'worried']",
                  "display": 1,
                  "translatedName": "cara preocupada"
                },
                {
                  "code": "1f615",
                  "name": "confused face",
                  "category": "people",
                  "keywords": "['confused', 'face']",
                  "display": 1,
                  "translatedName": "rosto confuso"
                },
                {
                  "code": "1f641",
                  "name": "slightly frowning face",
                  "category": "people",
                  "keywords": "['face', 'frown']",
                  "display": 1,
                  "translatedName": "cara ligeiramente franzida"
                },
                {
                  "code": 2639,
                  "name": "frowning face",
                  "category": "people",
                  "keywords": "['face', 'frown']",
                  "display": 1,
                  "translatedName": "Cara franzindo a testa"
                },
                {
                  "code": "1f623",
                  "name": "persevering face",
                  "category": "people",
                  "keywords": "['face', 'persevere']",
                  "display": 1,
                  "translatedName": "cara perseverante"
                },
                {
                  "code": "1f616",
                  "name": "confounded face",
                  "category": "people",
                  "keywords": "['confounded', 'face']",
                  "display": 1,
                  "translatedName": "rosto confundido"
                },
                {
                  "code": "1f62b",
                  "name": "tired face",
                  "category": "people",
                  "keywords": "['face', 'tired']",
                  "display": 1,
                  "translatedName": "cara cansada"
                },
                {
                  "code": "1f629",
                  "name": "weary face",
                  "category": "people",
                  "keywords": "['face', 'tired', 'weary']",
                  "display": 1,
                  "translatedName": "rosto cansado"
                },
                {
                  "code": "1f624",
                  "name": "face with steam from nose",
                  "category": "people",
                  "keywords": "['face', 'triumph', 'won']",
                  "display": 1,
                  "translatedName": "cara com vapor do nariz"
                },
                {
                  "code": "1f620",
                  "name": "angry face",
                  "category": "people",
                  "keywords": "['angry', 'face', 'mad']",
                  "display": 1,
                  "translatedName": "cara de bravo"
                },
                {
                  "code": "1f621",
                  "name": "pouting face",
                  "category": "people",
                  "keywords": "['angry', 'face', 'mad', 'pouting', 'rage', 'red']",
                  "display": 1,
                  "translatedName": "cara de beicinho"
                },
                {
                  "code": "1f636",
                  "name": "face without mouth",
                  "category": "people",
                  "keywords": "['face', 'mouth', 'quiet', 'silent']",
                  "display": 1,
                  "translatedName": "cara sem boca"
                },
                {
                  "code": "1f610",
                  "name": "neutral face",
                  "category": "people",
                  "keywords": "['deadpan', 'face', 'neutral']",
                  "display": 1,
                  "translatedName": "rosto neutro"
                },
                {
                  "code": "1f611",
                  "name": "expressionless face",
                  "category": "people",
                  "keywords": "['expressionless', 'face', 'inexpressive', 'unexpressive']",
                  "display": 1,
                  "translatedName": "rosto inexpressivo."
                },
                {
                  "code": "1f62f",
                  "name": "hushed face",
                  "category": "people",
                  "keywords": "['face', 'hushed', 'stunned', 'surprised']",
                  "display": 1,
                  "translatedName": "cara silenciada"
                },
                {
                  "code": "1f626",
                  "name": "frowning face with open mouth",
                  "category": "people",
                  "keywords": "['face', 'frown', 'mouth', 'open']",
                  "display": 1,
                  "translatedName": "Cara franzindo a testa com a boca aberta"
                },
                {
                  "code": "1f627",
                  "name": "anguished face",
                  "category": "people",
                  "keywords": "['anguished', 'face']",
                  "display": 1,
                  "translatedName": "rosto angustiado"
                },
                {
                  "code": "1f62e",
                  "name": "face with open mouth",
                  "category": "people",
                  "keywords": "['face', 'mouth', 'open', 'sympathy']",
                  "display": 1,
                  "translatedName": "cara com boca aberta"
                },
                {
                  "code": "1f632",
                  "name": "astonished face",
                  "category": "people",
                  "keywords": "['astonished', 'face', 'shocked', 'totally']",
                  "display": 1,
                  "translatedName": "cara atônita"
                },
                {
                  "code": "1f635",
                  "name": "dizzy face",
                  "category": "people",
                  "keywords": "['dizzy', 'face']",
                  "display": 1,
                  "translatedName": "rosto vertiginoso"
                },
                {
                  "code": "1f633",
                  "name": "flushed face",
                  "category": "people",
                  "keywords": "['dazed', 'face', 'flushed']",
                  "display": 1,
                  "translatedName": "Cara corada"
                },
                {
                  "code": "1f631",
                  "name": "face screaming in fear",
                  "category": "people",
                  "keywords": "['face', 'fear', 'fearful', 'munch', 'scared', 'scream']",
                  "display": 1,
                  "translatedName": "rosto gritando com medo"
                },
                {
                  "code": "1f628",
                  "name": "fearful face",
                  "category": "people",
                  "keywords": "['face', 'fear', 'fearful', 'scared']",
                  "display": 1,
                  "translatedName": "rosto temeroso"
                },
                {
                  "code": "1f630",
                  "name": "face with open mouth & cold sweat",
                  "category": "people",
                  "keywords": "['blue', 'cold', 'face', 'mouth', 'open', 'rushed', 'sweat']",
                  "display": 1,
                  "translatedName": "cara com boca aberta e suor frio"
                },
                {
                  "code": "1f622",
                  "name": "crying face",
                  "category": "people",
                  "keywords": "['cry', 'face', 'sad', 'tear']",
                  "display": 1,
                  "translatedName": "cara de choro"
                },
                {
                  "code": "1f625",
                  "name": "disappointed but relieved face",
                  "category": "people",
                  "keywords": "['disappointed', 'face', 'relieved', 'whew']",
                  "display": 1,
                  "translatedName": "rosto desapontado mas aliviado"
                },
                {
                  "code": "1f924",
                  "name": "drooling face",
                  "category": "people",
                  "keywords": "['drooling', 'face']",
                  "display": 1,
                  "translatedName": "cara de babando"
                },
                {
                  "code": "1f62d",
                  "name": "loudly crying face",
                  "category": "people",
                  "keywords": "['cry', 'face', 'sad', 'sob', 'tear']",
                  "display": 1,
                  "translatedName": "cara de choro alto"
                },
                {
                  "code": "1f613",
                  "name": "face with cold sweat",
                  "category": "people",
                  "keywords": "['cold', 'face', 'sweat']",
                  "display": 1,
                  "translatedName": "cara com suor frio"
                },
                {
                  "code": "1f62a",
                  "name": "sleepy face",
                  "category": "people",
                  "keywords": "['face', 'sleep']",
                  "display": 1,
                  "translatedName": "cara de sono"
                },
                {
                  "code": "1f634",
                  "name": "sleeping face",
                  "category": "people",
                  "keywords": "['face', 'sleep', 'zzz']",
                  "display": 1,
                  "translatedName": "cara de dormir"
                },
                {
                  "code": "1f644",
                  "name": "face with rolling eyes",
                  "category": "people",
                  "keywords": "['eyes', 'face', 'rolling']",
                  "display": 1,
                  "translatedName": "cara com olhos rolantes"
                },
                {
                  "code": "1f914",
                  "name": "thinking face",
                  "category": "people",
                  "keywords": "['face', 'thinking']",
                  "display": 1,
                  "translatedName": "cara de pensamento"
                },
                {
                  "code": "1f925",
                  "name": "lying face",
                  "category": "people",
                  "keywords": "['face', 'lie', 'pinocchio']",
                  "display": 1,
                  "translatedName": "rosto deitado"
                },
                {
                  "code": "1f62c",
                  "name": "grimacing face",
                  "category": "people",
                  "keywords": "['face', 'grimace']",
                  "display": 1,
                  "translatedName": "careta cara"
                },
                {
                  "code": "1f910",
                  "name": "zipper-mouth face",
                  "category": "people",
                  "keywords": "['face', 'mouth', 'zipper']",
                  "display": 1,
                  "translatedName": "rosto de boca com zíper"
                },
                {
                  "code": "1f922",
                  "name": "nauseated face",
                  "category": "people",
                  "keywords": "['face', 'nauseated', 'vomit']",
                  "display": 1,
                  "translatedName": "rosto nauseado"
                },
                {
                  "code": "1f927",
                  "name": "sneezing face",
                  "category": "people",
                  "keywords": "['face', 'gesundheit', 'sneeze']",
                  "display": 1,
                  "translatedName": "sneezing face"
                },
                {
                  "code": "1f637",
                  "name": "face with medical mask",
                  "category": "people",
                  "keywords": "['cold', 'doctor', 'face', 'mask', 'medicine', 'sick']",
                  "display": 1,
                  "translatedName": "cara com máscara médica"
                },
                {
                  "code": "1f912",
                  "name": "face with thermometer",
                  "category": "people",
                  "keywords": "['face', 'ill', 'sick', 'thermometer']",
                  "display": 1,
                  "translatedName": "cara com termômetro"
                },
                {
                  "code": "1f915",
                  "name": "face with head-bandage",
                  "category": "people",
                  "keywords": "['bandage', 'face', 'hurt', 'injury']",
                  "display": 1,
                  "translatedName": "cara com atadura de cabeça"
                },
                {
                  "code": "1f608",
                  "name": "smiling face with horns",
                  "category": "people",
                  "keywords": "['face', 'fairy tale', 'fantasy', 'horns', 'smile']",
                  "display": 1,
                  "translatedName": "rosto sorridente com chifres"
                },
                {
                  "code": "1f47f",
                  "name": "angry face with horns",
                  "category": "people",
                  "keywords": "['demon', 'devil', 'face', 'fairy tale', 'fantasy', 'imp']",
                  "display": 1,
                  "translatedName": "cara irritada com chifres"
                },
                {
                  "code": "1f479",
                  "name": "ogre",
                  "category": "people",
                  "keywords": "['']",
                  "display": 1,
                  "translatedName": "ogro"
                },
                {
                  "code": "1f47a",
                  "name": "goblin",
                  "category": "people",
                  "keywords": "['creature', 'face', 'fairy tale', 'fantasy', 'monster']",
                  "display": 1,
                  "translatedName": "Goblin."
                },
                {
                  "code": "1f4a9",
                  "name": "pile of poo",
                  "category": "people",
                  "keywords": "['comic', 'dung', 'face', 'monster', 'poo', 'poop']",
                  "display": 1,
                  "translatedName": "Pilha de Poo."
                },
                {
                  "code": "1f47b",
                  "name": "ghost",
                  "category": "people",
                  "keywords": "['creature', 'face', 'fairy tale', 'fantasy', 'monster']",
                  "display": 1,
                  "translatedName": "fantasma"
                },
                {
                  "code": "1f480",
                  "name": "skull",
                  "category": "people",
                  "keywords": "['death', 'face', 'fairy tale', 'monster']",
                  "display": 1,
                  "translatedName": "crânio"
                },
                {
                  "code": 2620,
                  "name": "skull and crossbones",
                  "category": "people",
                  "keywords": "['crossbones', 'death', 'face', 'monster', 'skull']",
                  "display": 1,
                  "translatedName": "Crânio e Crossbones"
                },
                {
                  "code": "1f47d",
                  "name": "alien",
                  "category": "people",
                  "keywords": "['creature', 'extraterrestrial', 'face', 'fairy tale', 'fantasy', 'monster', 'ufo']",
                  "display": 1,
                  "translatedName": "estrangeiro"
                },
                {
                  "code": "1f47e",
                  "name": "alien monster",
                  "category": "people",
                  "keywords": "['alien', 'creature', 'extraterrestrial', 'face', 'fairy tale', 'fantasy', 'monster', 'ufo']",
                  "display": 1,
                  "translatedName": "monstro alienígena"
                },
                {
                  "code": "1f916",
                  "name": "robot face",
                  "category": "people",
                  "keywords": "['face', 'monster', 'robot']",
                  "display": 1,
                  "translatedName": "Robot Face."
                },
                {
                  "code": "1f383",
                  "name": "jack-o-lantern",
                  "category": "people",
                  "keywords": "['celebration', 'halloween', 'jack', 'lantern']",
                  "display": 1,
                  "translatedName": "jack-o-lantern"
                },
                {
                  "code": "1f63a",
                  "name": "smiling cat face with open mouth",
                  "category": "people",
                  "keywords": "['cat', 'face', 'mouth', 'open', 'smile']",
                  "display": 1,
                  "translatedName": "Rosto de gato sorridente com boca aberta"
                },
                {
                  "code": "1f638",
                  "name": "grinning cat face with smiling eyes",
                  "category": "people",
                  "keywords": "['cat', 'eye', 'face', 'grin', 'smile']",
                  "display": 1,
                  "translatedName": "Rosto de gato sorrindo com olhos sorridentes"
                },
                {
                  "code": "1f639",
                  "name": "cat face with tears of joy",
                  "category": "people",
                  "keywords": "['cat', 'face', 'joy', 'tear']",
                  "display": 1,
                  "translatedName": "cara de gato com lágrimas de alegria"
                },
                {
                  "code": "1f63b",
                  "name": "smiling cat face with heart-eyes",
                  "category": "people",
                  "keywords": "['cat', 'eye', 'face', 'love', 'smile']",
                  "display": 1,
                  "translatedName": "Rosto de gato sorridente com coração-olhos"
                },
                {
                  "code": "1f63c",
                  "name": "cat face with wry smile",
                  "category": "people",
                  "keywords": "['cat', 'face', 'ironic', 'smile', 'wry']",
                  "display": 1,
                  "translatedName": "cara de gato com sorriso irônico"
                },
                {
                  "code": "1f63d",
                  "name": "kissing cat face with closed eyes",
                  "category": "people",
                  "keywords": "['cat', 'eye', 'face', 'kiss']",
                  "display": 1,
                  "translatedName": "Beijando o rosto de gato com olhos fechados"
                },
                {
                  "code": "1f640",
                  "name": "weary cat face",
                  "category": "people",
                  "keywords": "['cat', 'face', 'oh', 'surprised', 'weary']",
                  "display": 1,
                  "translatedName": "Cara do gato cansado"
                },
                {
                  "code": "1f63f",
                  "name": "crying cat face",
                  "category": "people",
                  "keywords": "['cat', 'cry', 'face', 'sad', 'tear']",
                  "display": 1,
                  "translatedName": "cara de gato chorando"
                },
                {
                  "code": "1f63e",
                  "name": "pouting cat face",
                  "category": "people",
                  "keywords": "['cat', 'face', 'pouting']",
                  "display": 1,
                  "translatedName": "cara de gato de beicinho"
                },
                {
                  "code": "1f450",
                  "name": "open hands",
                  "category": "people",
                  "keywords": "['hand', 'open']",
                  "display": 1,
                  "translatedName": "mãos abertas"
                },
                {
                  "code": "1f64f",
                  "name": "folded hands",
                  "category": "people",
                  "keywords": "['ask', 'bow', 'folded', 'gesture', 'hand', 'please', 'pray', 'thanks']",
                  "display": 1,
                  "translatedName": "mãos dobradas"
                },
                {
                  "code": "1f91d",
                  "name": "handshake",
                  "category": "people",
                  "keywords": "['agreement', 'hand', 'handshake', 'meeting', 'shake']",
                  "display": 1,
                  "translatedName": "aperto de mão"
                },
                {
                  "code": "1f44d",
                  "name": "thumbs up",
                  "category": "people",
                  "keywords": "['+1', 'hand', 'thumb', 'up']",
                  "display": 1,
                  "translatedName": "afirmativo"
                },
                {
                  "code": "1f44a",
                  "name": "oncoming fist",
                  "category": "people",
                  "keywords": "['clenched', 'fist', 'hand', 'punch']",
                  "display": 1,
                  "translatedName": "punho que se aproxima"
                },
                {
                  "code": "270a",
                  "name": "raised fist",
                  "category": "people",
                  "keywords": "['clenched', 'fist', 'hand', 'punch']",
                  "display": 1,
                  "translatedName": "punho levantado"
                },
                {
                  "code": "1f91c",
                  "name": "right-facing fist",
                  "category": "people",
                  "keywords": "['fist', 'rightwards']",
                  "display": 1,
                  "translatedName": "punho voltado para a direita"
                },
                {
                  "code": "1f91e",
                  "name": "crossed fingers",
                  "category": "people",
                  "keywords": "['cross', 'finger', 'hand', 'luck']",
                  "display": 1,
                  "translatedName": "dedos cruzados"
                },
                {
                  "code": "270c",
                  "name": "victory hand",
                  "category": "people",
                  "keywords": "['hand', 'v', 'victory']",
                  "display": 1,
                  "translatedName": "mão da vitória"
                },
                {
                  "code": "1f918",
                  "name": "sign of the horns",
                  "category": "people",
                  "keywords": "['finger', 'hand', 'horns', 'rock-on']",
                  "display": 1,
                  "translatedName": "Sinal dos chifres"
                },
                {
                  "code": "1f44c",
                  "name": "OK hand",
                  "category": "people",
                  "keywords": "['OK', 'hand']",
                  "display": 1,
                  "translatedName": "Ok mão"
                },
                {
                  "code": "1f448",
                  "name": "backhand index pointing left",
                  "category": "people",
                  "keywords": "['backhand', 'finger', 'hand', 'index', 'point']",
                  "display": 1,
                  "translatedName": "índice de backhand apontando para a esquerda"
                },
                {
                  "code": "1f449",
                  "name": "backhand index pointing right",
                  "category": "people",
                  "keywords": "['backhand', 'finger', 'hand', 'index', 'point']",
                  "display": 1,
                  "translatedName": "índice de backhand apontando para a direita"
                },
                {
                  "code": "1f446",
                  "name": "backhand index pointing up",
                  "category": "people",
                  "keywords": "['backhand', 'finger', 'hand', 'index', 'point', 'up']",
                  "display": 1,
                  "translatedName": "índice de backhand apontando para cima"
                },
                {
                  "code": "1f447",
                  "name": "backhand index pointing down",
                  "category": "people",
                  "keywords": "['backhand', 'down', 'finger', 'hand', 'index', 'point']",
                  "display": 1,
                  "translatedName": "índice de backhand apontando para baixo"
                },
                {
                  "code": "261d",
                  "name": "index pointing up",
                  "category": "people",
                  "keywords": "['finger', 'hand', 'index', 'point', 'up']",
                  "display": 1,
                  "translatedName": "Índice apontando para cima"
                },
                {
                  "code": "270b",
                  "name": "raised hand",
                  "category": "people",
                  "keywords": "['hand']",
                  "display": 1,
                  "translatedName": "mão levantada"
                },
                {
                  "code": "1f91a",
                  "name": "raised back of hand",
                  "category": "people",
                  "keywords": "['backhand', 'raised']",
                  "display": 1,
                  "translatedName": "levantou a parte de trás da mão"
                },
                {
                  "code": "1f590",
                  "name": "raised hand with fingers splayed",
                  "category": "people",
                  "keywords": "['finger', 'hand', 'splayed']",
                  "display": 1,
                  "translatedName": "mão levantada com os dedos esplêndidos"
                },
                {
                  "code": "1f596",
                  "name": "vulcan salute",
                  "category": "people",
                  "keywords": "['finger', 'hand', 'spock', 'vulcan']",
                  "display": 1,
                  "translatedName": "saudação vulcana."
                },
                {
                  "code": "1f44b",
                  "name": "waving hand",
                  "category": "people",
                  "keywords": "['hand', 'wave', 'waving']",
                  "display": 1,
                  "translatedName": "Acenando a mão"
                },
                {
                  "code": "1f919",
                  "name": "call me hand",
                  "category": "people",
                  "keywords": "['call', 'hand']",
                  "display": 1,
                  "translatedName": "me chame de mão"
                },
                {
                  "code": "1f4aa",
                  "name": "flexed biceps",
                  "category": "people",
                  "keywords": "['biceps', 'comic', 'flex', 'muscle']",
                  "display": 1,
                  "translatedName": "Bíceps flexionados"
                },
                {
                  "code": "1f595",
                  "name": "middle finger",
                  "category": "people",
                  "keywords": "['finger', 'hand']",
                  "display": 1,
                  "translatedName": "dedo do meio"
                },
                {
                  "code": "270d",
                  "name": "writing hand",
                  "category": "people",
                  "keywords": "['hand', 'write']",
                  "display": 1,
                  "translatedName": "Escrevendo a mão"
                },
                {
                  "code": "1f933",
                  "name": "selfie",
                  "category": "people",
                  "keywords": "['camera', 'phone', 'selfie']",
                  "display": 1,
                  "translatedName": "selfie"
                },
                {
                  "code": "1f485",
                  "name": "nail polish",
                  "category": "people",
                  "keywords": "['care', 'cosmetics', 'manicure', 'nail', 'polish']",
                  "display": 1,
                  "translatedName": "lixador de unha"
                },
                {
                  "code": "1f48d",
                  "name": "ring",
                  "category": "people",
                  "keywords": "['diamond']",
                  "display": 1,
                  "translatedName": "anel"
                },
                {
                  "code": "1f484",
                  "name": "lipstick",
                  "category": "people",
                  "keywords": "['cosmetics', 'makeup']",
                  "display": 1,
                  "translatedName": "batom"
                },
                {
                  "code": "1f48b",
                  "name": "kiss mark",
                  "category": "people",
                  "keywords": "['kiss', 'lips']",
                  "display": 1,
                  "translatedName": "marca de beijo"
                },
                {
                  "code": "1f444",
                  "name": "mouth",
                  "category": "people",
                  "keywords": "['lips']",
                  "display": 1,
                  "translatedName": "boca"
                },
                {
                  "code": "1f445",
                  "name": "tongue",
                  "category": "people",
                  "keywords": "['body']",
                  "display": 1,
                  "translatedName": "língua"
                },
                {
                  "code": "1f442",
                  "name": "ear",
                  "category": "people",
                  "keywords": "['body']",
                  "display": 1,
                  "translatedName": "orelha"
                },
                {
                  "code": "1f443",
                  "name": "nose",
                  "category": "people",
                  "keywords": "['body']",
                  "display": 1,
                  "translatedName": "nariz"
                },
                {
                  "code": "1f463",
                  "name": "footprints",
                  "category": "people",
                  "keywords": "['clothing', 'footprint', 'print']",
                  "display": 1,
                  "translatedName": "pegadas"
                },
                {
                  "code": "1f441",
                  "name": "eye",
                  "category": "people",
                  "keywords": "['body']",
                  "display": 1,
                  "translatedName": "olho"
                },
                {
                  "code": "1f440",
                  "name": "eyes",
                  "category": "people",
                  "keywords": "['eye', 'face']",
                  "display": 1,
                  "translatedName": "olhos"
                },
                {
                  "code": "1f5e3",
                  "name": "speaking head",
                  "category": "people",
                  "keywords": "['face', 'head', 'silhouette', 'speak', 'speaking']",
                  "display": 1,
                  "translatedName": "Falando cabeça"
                },
                {
                  "code": "1f464",
                  "name": "bust in silhouette",
                  "category": "people",
                  "keywords": "['bust', 'silhouette']",
                  "display": 1,
                  "translatedName": "Busto na silhueta"
                },
                {
                  "code": "1f465",
                  "name": "busts in silhouette",
                  "category": "people",
                  "keywords": "['bust', 'silhouette']",
                  "display": 1,
                  "translatedName": "Bustos na silhueta"
                },
                {
                  "code": "1f476",
                  "name": "baby",
                  "category": "people",
                  "keywords": "['baby', 'young']",
                  "display": 1,
                  "translatedName": "bebê"
                },
                {
                  "code": "1f466",
                  "name": "boy",
                  "category": "people",
                  "keywords": "['boy', 'young']",
                  "display": 1,
                  "translatedName": "Garoto"
                },
                {
                  "code": "1f467",
                  "name": "girl",
                  "category": "people",
                  "keywords": "['Virgo', 'young', 'zodiac']",
                  "display": 1,
                  "translatedName": "garota"
                },
                {
                  "code": "1f468",
                  "name": "man",
                  "category": "people",
                  "keywords": "['man']",
                  "display": 1,
                  "translatedName": "cara"
                },
                {
                  "code": "1f469",
                  "name": "woman",
                  "category": "people",
                  "keywords": "['woman']",
                  "display": 1,
                  "translatedName": "mulher"
                },
                {
                  "code": "1f471-2640",
                  "name": "blond-haired woman",
                  "category": "people",
                  "keywords": "['blonde', 'woman']",
                  "display": 1,
                  "translatedName": "Mulher de cabelos loiros"
                },
                {
                  "code": "1f471",
                  "name": "blond-haired person",
                  "category": "people",
                  "keywords": "['blond']",
                  "display": 1,
                  "translatedName": "pessoa de cabelos louro"
                },
                {
                  "code": "1f471-2642",
                  "name": "blond-haired man",
                  "category": "people",
                  "keywords": "['blond', 'man']",
                  "display": 0,
                  "translatedName": "homem de cabelos loiros"
                },
                {
                  "code": "1f474",
                  "name": "old man",
                  "category": "people",
                  "keywords": "['man', 'old']",
                  "display": 1,
                  "translatedName": "Velhote"
                },
                {
                  "code": "1f475",
                  "name": "old woman",
                  "category": "people",
                  "keywords": "['old', 'woman']",
                  "display": 1,
                  "translatedName": "Mulher velha"
                },
                {
                  "code": "1f472",
                  "name": "man with Chinese cap",
                  "category": "people",
                  "keywords": "['gua pi mao', 'hat', 'man']",
                  "display": 1,
                  "translatedName": "Homem com boné chinês"
                },
                {
                  "code": "1f473-2640",
                  "name": "woman wearing turban",
                  "category": "people",
                  "keywords": "['turban', 'woman']",
                  "display": 1,
                  "translatedName": "Mulher vestindo turbante"
                },
                {
                  "code": "1f473",
                  "name": "person wearing turban",
                  "category": "people",
                  "keywords": "['turban']",
                  "display": 1,
                  "translatedName": "Pessoa vestindo turbante"
                },
                {
                  "code": "1f473-2642",
                  "name": "man wearing turban",
                  "category": "people",
                  "keywords": "['man', 'turban']",
                  "display": 0,
                  "translatedName": "Homem vestindo turbante"
                },
                {
                  "code": "1f46e-2640",
                  "name": "woman police officer",
                  "category": "people",
                  "keywords": "['cop', 'officer', 'police', 'woman']",
                  "display": 1,
                  "translatedName": "policial de mulher"
                },
                {
                  "code": "1f46e",
                  "name": "police officer",
                  "category": "people",
                  "keywords": "['cop', 'officer', 'police']",
                  "display": 1,
                  "translatedName": "policial"
                },
                {
                  "code": "1f46e-2642",
                  "name": "man police officer",
                  "category": "people",
                  "keywords": "['cop', 'man', 'officer', 'police']",
                  "display": 0,
                  "translatedName": "policial do homem"
                },
                {
                  "code": "1f477",
                  "name": "construction worker",
                  "category": "people",
                  "keywords": "['construction', 'hat', 'worker']",
                  "display": 1,
                  "translatedName": "trabalhador da construção"
                },
                {
                  "code": "1f482-2640",
                  "name": "woman guard",
                  "category": "people",
                  "keywords": "['guard', 'woman']",
                  "display": 1,
                  "translatedName": "guarda mulher"
                },
                {
                  "code": "1f482",
                  "name": "guard",
                  "category": "people",
                  "keywords": "['guard']",
                  "display": 1,
                  "translatedName": "guarda"
                },
                {
                  "code": "1f575-2640",
                  "name": "woman detective",
                  "category": "people",
                  "keywords": "['detective', 'sleuth', 'spy', 'woman']",
                  "display": 1,
                  "translatedName": "Detetive de mulher"
                },
                {
                  "code": "1f575",
                  "name": "detective",
                  "category": "people",
                  "keywords": "['detective', 'sleuth', 'spy']",
                  "display": 1,
                  "translatedName": "detetive"
                },
                {
                  "code": "1f575-2642",
                  "name": "man detective",
                  "category": "people",
                  "keywords": "['detective', 'man', 'sleuth', 'spy']",
                  "display": 0,
                  "translatedName": "detetive do homem"
                },
                {
                  "code": "1f469-2695",
                  "name": "woman health worker",
                  "category": "people",
                  "keywords": "['doctor', 'healthcare', 'nurse', 'therapist', 'woman']",
                  "display": 1,
                  "translatedName": "Trabalhador de saúde da mulher"
                },
                {
                  "code": "1f468-2695",
                  "name": "man health worker",
                  "category": "people",
                  "keywords": "['doctor', 'healthcare', 'man', 'nurse', 'therapist']",
                  "display": 1,
                  "translatedName": "Trabalhador de saúde do homem"
                },
                {
                  "code": "1f469-1f33e",
                  "name": "woman farmer",
                  "category": "people",
                  "keywords": "['farmer', 'gardener', 'rancher', 'woman']",
                  "display": 1,
                  "translatedName": "fazendeiro de mulher"
                },
                {
                  "code": "1f468-1f33e",
                  "name": "man farmer",
                  "category": "people",
                  "keywords": "['farmer', 'gardener', 'man', 'rancher']",
                  "display": 1,
                  "translatedName": "fazendeiro de homem"
                },
                {
                  "code": "1f469-1f373",
                  "name": "woman cook",
                  "category": "people",
                  "keywords": "['chef', 'cook', 'woman']",
                  "display": 1,
                  "translatedName": "Cozinheiro de mulher"
                },
                {
                  "code": "1f468-1f373",
                  "name": "man cook",
                  "category": "people",
                  "keywords": "['chef', 'cook', 'man']",
                  "display": 1,
                  "translatedName": "Cook do homem"
                },
                {
                  "code": "1f469-1f393",
                  "name": "woman student",
                  "category": "people",
                  "keywords": "['graduate', 'student', 'woman']",
                  "display": 1,
                  "translatedName": "estudante de mulher"
                },
                {
                  "code": "1f468-1f393",
                  "name": "man student",
                  "category": "people",
                  "keywords": "['graduate', 'man', 'student']",
                  "display": 1,
                  "translatedName": "estudante de homem"
                },
                {
                  "code": "1f469-1f3a4",
                  "name": "woman singer",
                  "category": "people",
                  "keywords": "['actor', 'entertainer', 'rock', 'singer', 'star', 'woman']",
                  "display": 1,
                  "translatedName": "cantor de mulher"
                },
                {
                  "code": "1f468-1f3a4",
                  "name": "man singer",
                  "category": "people",
                  "keywords": "['actor', 'entertainer', 'man', 'rock', 'singer', 'star']",
                  "display": 1,
                  "translatedName": "Cantor de homem"
                },
                {
                  "code": "1f469-1f3eb",
                  "name": "woman teacher",
                  "category": "people",
                  "keywords": "['instructor', 'professor', 'teacher', 'woman']",
                  "display": 1,
                  "translatedName": "professor de mulher"
                },
                {
                  "code": "1f468-1f3eb",
                  "name": "man teacher",
                  "category": "people",
                  "keywords": "['instructor', 'man', 'professor', 'teacher']",
                  "display": 1,
                  "translatedName": "professor de homem"
                },
                {
                  "code": "1f469-1f3ed",
                  "name": "woman factory worker",
                  "category": "people",
                  "keywords": "['assembly', 'factory', 'industrial', 'woman', 'worker']",
                  "display": 1,
                  "translatedName": "trabalhador de fábrica de mulher"
                },
                {
                  "code": "1f468-1f3ed",
                  "name": "man factory worker",
                  "category": "people",
                  "keywords": "['assembly', 'factory', 'industrial', 'man', 'worker']",
                  "display": 1,
                  "translatedName": "Homem de fábrica de homem"
                },
                {
                  "code": "1f469-1f4bb",
                  "name": "woman technologist",
                  "category": "people",
                  "keywords": "['coder', 'developer', 'inventor', 'software', 'technologist', 'woman']",
                  "display": 1,
                  "translatedName": "Tecnólogo de mulher"
                },
                {
                  "code": "1f468-1f4bb",
                  "name": "man technologist",
                  "category": "people",
                  "keywords": "['coder', 'developer', 'inventor', 'man', 'software', 'technologist']",
                  "display": 1,
                  "translatedName": "Tecnólogo de homem"
                },
                {
                  "code": "1f469-1f4bc",
                  "name": "woman office worker",
                  "category": "people",
                  "keywords": "['architect', 'business', 'manager', 'office', 'white-collar', 'woman']",
                  "display": 1,
                  "translatedName": "Trabalhador de escritório de mulher"
                },
                {
                  "code": "1f468-1f4bc",
                  "name": "man office worker",
                  "category": "people",
                  "keywords": "['architect', 'business', 'man', 'manager', 'office', 'white-collar']",
                  "display": 1,
                  "translatedName": "Trabalhador de escritório do homem"
                },
                {
                  "code": "1f469-1f527",
                  "name": "woman mechanic",
                  "category": "people",
                  "keywords": "['electrician', 'mechanic', 'plumber', 'tradesperson', 'woman']",
                  "display": 1,
                  "translatedName": "Mecânico de mulher"
                },
                {
                  "code": "1f468-1f527",
                  "name": "man mechanic",
                  "category": "people",
                  "keywords": "['electrician', 'man', 'mechanic', 'plumber', 'tradesperson']",
                  "display": 1,
                  "translatedName": "Mecânico de homem"
                },
                {
                  "code": "1f469-1f52c",
                  "name": "woman scientist",
                  "category": "people",
                  "keywords": "['biologist', 'chemist', 'engineer', 'mathematician', 'physicist', 'scientist', 'woman']",
                  "display": 1,
                  "translatedName": "cientista de mulher"
                },
                {
                  "code": "1f468-1f52c",
                  "name": "man scientist",
                  "category": "people",
                  "keywords": "['biologist', 'chemist', 'engineer', 'man', 'mathematician', 'physicist', 'scientist']",
                  "display": 1,
                  "translatedName": "Cientista do homem"
                },
                {
                  "code": "1f469-1f3a8",
                  "name": "woman artist",
                  "category": "people",
                  "keywords": "['artist', 'palette', 'woman']",
                  "display": 1,
                  "translatedName": "Artista de mulher"
                },
                {
                  "code": "1f468-1f3a8",
                  "name": "man artist",
                  "category": "people",
                  "keywords": "['artist', 'man', 'palette']",
                  "display": 1,
                  "translatedName": "artista do homem."
                },
                {
                  "code": "1f469-1f692",
                  "name": "woman firefighter",
                  "category": "people",
                  "keywords": "['firefighter', 'firetruck', 'woman']",
                  "display": 1,
                  "translatedName": "Bombeiro de mulher"
                },
                {
                  "code": "1f468-1f692",
                  "name": "man firefighter",
                  "category": "people",
                  "keywords": "['firefighter', 'firetruck', 'man']",
                  "display": 1,
                  "translatedName": "bombeiro de homem"
                },
                {
                  "code": "1f469-2708",
                  "name": "woman pilot",
                  "category": "people",
                  "keywords": "['pilot', 'plane', 'woman']",
                  "display": 1,
                  "translatedName": "piloto de mulher"
                },
                {
                  "code": "1f468-2708",
                  "name": "man pilot",
                  "category": "people",
                  "keywords": "['man', 'pilot', 'plane']",
                  "display": 1,
                  "translatedName": "homem piloto"
                },
                {
                  "code": "1f469-1f680",
                  "name": "woman astronaut",
                  "category": "people",
                  "keywords": "['astronaut', 'rocket', 'woman']",
                  "display": 1,
                  "translatedName": "Astronauta da mulher."
                },
                {
                  "code": "1f468-1f680",
                  "name": "man astronaut",
                  "category": "people",
                  "keywords": "['astronaut', 'man', 'rocket']",
                  "display": 1,
                  "translatedName": "Astronauta do homem."
                },
                {
                  "code": "1f469-2696",
                  "name": "woman judge",
                  "category": "people",
                  "keywords": "['judge', 'scales', 'woman']",
                  "display": 1,
                  "translatedName": "juiz de mulher"
                },
                {
                  "code": "1f468-2696",
                  "name": "man judge",
                  "category": "people",
                  "keywords": "['justice', 'man', 'scales']",
                  "display": 1,
                  "translatedName": "juiz do homem"
                },
                {
                  "code": "1f936",
                  "name": "Mrs. Claus",
                  "category": "people",
                  "keywords": "['Christmas', 'Mrs.', 'celebration', 'claus', 'mother']",
                  "display": 1,
                  "translatedName": "Sra. Claus."
                },
                {
                  "code": "1f385",
                  "name": "Santa Claus",
                  "category": "people",
                  "keywords": "['Christmas', 'celebration', 'claus', 'father', 'santa']",
                  "display": 1,
                  "translatedName": "Papai Noel"
                },
                {
                  "code": "1f478",
                  "name": "princess",
                  "category": "people",
                  "keywords": "['fairy tale', 'fantasy']",
                  "display": 1,
                  "translatedName": "Princesa"
                },
                {
                  "code": "1f934",
                  "name": "prince",
                  "category": "people",
                  "keywords": "['prince']",
                  "display": 1,
                  "translatedName": "Principe"
                },
                {
                  "code": "1f470",
                  "name": "bride with veil",
                  "category": "people",
                  "keywords": "['bride', 'veil', 'wedding']",
                  "display": 1,
                  "translatedName": "Noiva com véu"
                },
                {
                  "code": "1f935",
                  "name": "man in tuxedo",
                  "category": "people",
                  "keywords": "['groom', 'man', 'tuxedo']",
                  "display": 1,
                  "translatedName": "Homem em smoking."
                },
                {
                  "code": "1f47c",
                  "name": "baby angel",
                  "category": "people",
                  "keywords": "['angel', 'baby', 'face', 'fairy tale', 'fantasy']",
                  "display": 1,
                  "translatedName": "anjo do bebê"
                },
                {
                  "code": "1f930",
                  "name": "pregnant woman",
                  "category": "people",
                  "keywords": "['pregnant', 'woman']",
                  "display": 1,
                  "translatedName": "Mulher grávida"
                },
                {
                  "code": "1f647-2640",
                  "name": "woman bowing",
                  "category": "people",
                  "keywords": "['apology', 'bowing', 'favor', 'gesture', 'sorry', 'woman']",
                  "display": 1,
                  "translatedName": "Mulher curva"
                },
                {
                  "code": "1f647",
                  "name": "person bowing",
                  "category": "people",
                  "keywords": "['apology', 'bow', 'gesture', 'sorry']",
                  "display": 1,
                  "translatedName": "pessoa curvando"
                },
                {
                  "code": "1f647-2642",
                  "name": "man bowing",
                  "category": "people",
                  "keywords": "['apology', 'bowing', 'favor', 'gesture', 'man', 'sorry']",
                  "display": 0,
                  "translatedName": "homem curvando"
                },
                {
                  "code": "1f481",
                  "name": "person tipping hand",
                  "category": "people",
                  "keywords": "['hand', 'help', 'information', 'sassy', 'tipping']",
                  "display": 1,
                  "translatedName": "Pessoa derrubando a mão"
                },
                {
                  "code": "1f481-2642",
                  "name": "man tipping hand",
                  "category": "people",
                  "keywords": "['man', 'sassy', 'tipping hand']",
                  "display": 1,
                  "translatedName": "Homem derrubando a mão"
                },
                {
                  "code": "1f481-2640",
                  "name": "woman tipping hand",
                  "category": "people",
                  "keywords": "['sassy', 'tipping hand', 'woman']",
                  "display": 0,
                  "translatedName": "Mulher derrubando a mão"
                },
                {
                  "code": "1f645",
                  "name": "person gesturing NO",
                  "category": "people",
                  "keywords": "['forbidden', 'gesture', 'hand', 'no', 'not', 'prohibited']",
                  "display": 1,
                  "translatedName": "Pessoa gesticulando No."
                },
                {
                  "code": "1f645-2642",
                  "name": "man gesturing NO",
                  "category": "people",
                  "keywords": "['forbidden', 'gesture', 'hand', 'man', 'no', 'prohibited']",
                  "display": 1,
                  "translatedName": "Homem gesticulando No."
                },
                {
                  "code": "1f645-2640",
                  "name": "woman gesturing NO",
                  "category": "people",
                  "keywords": "['forbidden', 'gesture', 'hand', 'no', 'prohibited', 'woman']",
                  "display": 0,
                  "translatedName": "Mulher gesticulando não"
                },
                {
                  "code": "1f646",
                  "name": "person gesturing OK",
                  "category": "people",
                  "keywords": "['OK', 'gesture', 'hand']",
                  "display": 1,
                  "translatedName": "Pessoa gesticulando ok"
                },
                {
                  "code": "1f646-2642",
                  "name": "man gesturing OK",
                  "category": "people",
                  "keywords": "['OK', 'gesture', 'hand', 'man']",
                  "display": 1,
                  "translatedName": "Homem gesticulando ok"
                },
                {
                  "code": "1f646-2640",
                  "name": "woman gesturing OK",
                  "category": "people",
                  "keywords": "['OK', 'gesture', 'hand', 'woman']",
                  "display": 0,
                  "translatedName": "Mulher que gesticula ok"
                },
                {
                  "code": "1f64b",
                  "name": "person raising hand",
                  "category": "people",
                  "keywords": "['gesture', 'hand', 'happy', 'raised']",
                  "display": 1,
                  "translatedName": "pessoa levantando a mão"
                },
                {
                  "code": "1f64b-2642",
                  "name": "man raising hand",
                  "category": "people",
                  "keywords": "['gesture', 'man', 'raising hand']",
                  "display": 1,
                  "translatedName": "Homem levantando a mão"
                },
                {
                  "code": "1f64b-2640",
                  "name": "woman raising hand",
                  "category": "people",
                  "keywords": "['gesture', 'raising hand', 'woman']",
                  "display": 0,
                  "translatedName": "Mulher levantando a mão"
                },
                {
                  "code": "1f926-2640",
                  "name": "woman facepalming",
                  "category": "people",
                  "keywords": "['disbelief', 'exasperation', 'facepalm', 'woman']",
                  "display": 1,
                  "translatedName": "mulher facepalming"
                },
                {
                  "code": "1f926-2642",
                  "name": "man facepalming",
                  "category": "people",
                  "keywords": "['disbelief', 'exasperation', 'facepalm', 'man']",
                  "display": 1,
                  "translatedName": "homem facepalming"
                },
                {
                  "code": "1f926",
                  "name": "person facepalming",
                  "category": "people",
                  "keywords": "['disbelief', 'exasperation', 'face', 'palm']",
                  "display": 0,
                  "translatedName": "pessoa facepalming"
                },
                {
                  "code": "1f937-2640",
                  "name": "woman shrugging",
                  "category": "people",
                  "keywords": "['doubt', 'ignorance', 'indifference', 'shrug', 'woman']",
                  "display": 1,
                  "translatedName": "Mulher encolhendo os ombros."
                },
                {
                  "code": "1f937-2642",
                  "name": "man shrugging",
                  "category": "people",
                  "keywords": "['doubt', 'ignorance', 'indifference', 'man', 'shrug']",
                  "display": 1,
                  "translatedName": "homem encolhendo os ombros."
                },
                {
                  "code": "1f937",
                  "name": "person shrugging",
                  "category": "people",
                  "keywords": "['doubt', 'ignorance', 'indifference', 'shrug']",
                  "display": 0,
                  "translatedName": "Pessoa encolhendo os ombros."
                },
                {
                  "code": "1f64e",
                  "name": "person pouting",
                  "category": "people",
                  "keywords": "['gesture', 'pouting']",
                  "display": 1,
                  "translatedName": "Pouting Pouting."
                },
                {
                  "code": "1f64e-2642",
                  "name": "man pouting",
                  "category": "people",
                  "keywords": "['gesture', 'man', 'pouting']",
                  "display": 1,
                  "translatedName": "fazendo beicinho"
                },
                {
                  "code": "1f64e-2640",
                  "name": "woman pouting",
                  "category": "people",
                  "keywords": "['gesture', 'pouting', 'woman']",
                  "display": 0,
                  "translatedName": "mulher fazendo beicinho"
                },
                {
                  "code": "1f64d",
                  "name": "person frowning",
                  "category": "people",
                  "keywords": "['frown', 'gesture']",
                  "display": 1,
                  "translatedName": "pessoa franzindo a testa"
                },
                {
                  "code": "1f64d-2642",
                  "name": "man frowning",
                  "category": "people",
                  "keywords": "['frowning', 'gesture', 'man']",
                  "display": 1,
                  "translatedName": "homem franzindo a testa"
                },
                {
                  "code": "1f64d-2640",
                  "name": "woman frowning",
                  "category": "people",
                  "keywords": "['frowning', 'gesture', 'woman']",
                  "display": 0,
                  "translatedName": "Mulher franzindo a testa"
                },
                {
                  "code": "1f487",
                  "name": "person getting haircut",
                  "category": "people",
                  "keywords": "['barber', 'beauty', 'haircut', 'parlor']",
                  "display": 1,
                  "translatedName": "pessoa recebendo corte de cabelo"
                },
                {
                  "code": "1f487-2642",
                  "name": "man getting haircut",
                  "category": "people",
                  "keywords": "['haircut', 'man']",
                  "display": 1,
                  "translatedName": "Homem recebendo corte de cabelo"
                },
                {
                  "code": "1f487-2640",
                  "name": "woman getting haircut",
                  "category": "people",
                  "keywords": "['haircut', 'woman']",
                  "display": 0,
                  "translatedName": "Mulher recebendo corte de cabelo"
                },
                {
                  "code": "1f486",
                  "name": "person getting massage",
                  "category": "people",
                  "keywords": "['face', 'massage', 'salon']",
                  "display": 1,
                  "translatedName": "pessoa ficando massagem."
                },
                {
                  "code": "1f486-2642",
                  "name": "man getting massage",
                  "category": "people",
                  "keywords": "['face', 'man', 'massage']",
                  "display": 1,
                  "translatedName": "Homem ficando massagem"
                },
                {
                  "code": "1f486-2640",
                  "name": "woman getting massage",
                  "category": "people",
                  "keywords": "['face', 'massage', 'woman']",
                  "display": 0,
                  "translatedName": "Mulher recebendo massagem"
                },
                {
                  "code": "1f574",
                  "name": "man in business suit levitating",
                  "category": "people",
                  "keywords": "['business', 'man', 'suit']",
                  "display": 1,
                  "translatedName": "Homem em terno de negócio levitando"
                },
                {
                  "code": "1f483",
                  "name": "woman dancing",
                  "category": "people",
                  "keywords": "['dancing', 'woman']",
                  "display": 1,
                  "translatedName": "Dança da mulher"
                },
                {
                  "code": "1f57a",
                  "name": "man dancing",
                  "category": "people",
                  "keywords": "['dance', 'man']",
                  "display": 1,
                  "translatedName": "dança do homem"
                },
                {
                  "code": "1f46f",
                  "name": "people with bunny ears partying",
                  "category": "people",
                  "keywords": "['bunny ear', 'dancer', 'partying']",
                  "display": 1,
                  "translatedName": "Pessoas com orelhas de coelho festejando"
                },
                {
                  "code": "1f46f-2642",
                  "name": "men with bunny ears partying",
                  "category": "people",
                  "keywords": "['bunny ear', 'dancer', 'men', 'partying']",
                  "display": 1,
                  "translatedName": "Homens com orelhas de coelho festejando"
                },
                {
                  "code": "1f46f-2640",
                  "name": "women with bunny ears partying",
                  "category": "people",
                  "keywords": "['bunny ear', 'dancer', 'partying', 'women']",
                  "display": 0,
                  "translatedName": "mulheres com orelhas de coelho festejando"
                },
                {
                  "code": "1f6b6-2640",
                  "name": "woman walking",
                  "category": "people",
                  "keywords": "['hike', 'walk', 'woman']",
                  "display": 1,
                  "translatedName": "Mulher andando"
                },
                {
                  "code": "1f6b6",
                  "name": "person walking",
                  "category": "people",
                  "keywords": "['hike', 'walk', 'walking']",
                  "display": 1,
                  "translatedName": "Pessoa andando"
                },
                {
                  "code": "1f6b6-2642",
                  "name": "man walking",
                  "category": "people",
                  "keywords": "['hike', 'man', 'walk']",
                  "display": 0,
                  "translatedName": "homem andando"
                },
                {
                  "code": "1f3c3-2640",
                  "name": "woman running",
                  "category": "people",
                  "keywords": "['marathon', 'racing', 'running', 'woman']",
                  "display": 1,
                  "translatedName": "mulher correndo"
                },
                {
                  "code": "1f3c3",
                  "name": "person running",
                  "category": "people",
                  "keywords": "['marathon', 'running']",
                  "display": 1,
                  "translatedName": "Pessoa correndo"
                },
                {
                  "code": "1f3c3-2642",
                  "name": "man running",
                  "category": "people",
                  "keywords": "['man', 'marathon', 'racing', 'running']",
                  "display": 0,
                  "translatedName": "homem correndo"
                },
                {
                  "code": "1f46b",
                  "name": "man and woman holding hands",
                  "category": "people",
                  "keywords": "['couple', 'hand', 'hold', 'man', 'woman']",
                  "display": 1,
                  "translatedName": "homem e mulher de mãos dadas"
                },
                {
                  "code": "1f46d",
                  "name": "two women holding hands",
                  "category": "people",
                  "keywords": "['couple', 'hand', 'hold', 'woman']",
                  "display": 1,
                  "translatedName": "Duas mulheres de mãos dadas"
                },
                {
                  "code": "1f46c",
                  "name": "two men holding hands",
                  "category": "people",
                  "keywords": "['Gemini', 'couple', 'hand', 'hold', 'man', 'twins', 'zodiac']",
                  "display": 1,
                  "translatedName": "dois homens de mãos dadas"
                },
                {
                  "code": "1f491",
                  "name": "couple with heart",
                  "category": "people",
                  "keywords": "['couple', 'love']",
                  "display": 1,
                  "translatedName": "Casal com coração"
                },
                {
                  "code": "1f469-2764-1f469",
                  "name": "couple with heart: woman, woman",
                  "category": "people",
                  "keywords": "['couple', 'love', 'woman']",
                  "display": 1,
                  "translatedName": "Casal com coração: mulher, mulher"
                },
                {
                  "code": "1f468-2764-1f468",
                  "name": "couple with heart: man, man",
                  "category": "people",
                  "keywords": "['couple', 'love', 'man']",
                  "display": 1,
                  "translatedName": "Casal com coração: homem, homem"
                },
                {
                  "code": "1f48f",
                  "name": "kiss",
                  "category": "people",
                  "keywords": "['couple']",
                  "display": 1,
                  "translatedName": "beijo"
                },
                {
                  "code": "1f469-2764-1f48b-1f469",
                  "name": "kiss: woman, woman",
                  "category": "people",
                  "keywords": "['couple', 'woman']",
                  "display": 1,
                  "translatedName": "Beijo: mulher, mulher"
                },
                {
                  "code": "1f468-2764-1f48b-1f468",
                  "name": "kiss: man, man",
                  "category": "people",
                  "keywords": "['couple', 'man']",
                  "display": 1,
                  "translatedName": "Beijo: homem, homem"
                },
                {
                  "code": "1f46a",
                  "name": "family",
                  "category": "people",
                  "keywords": "['family']",
                  "display": 1,
                  "translatedName": "família"
                },
                {
                  "code": "1f468-1f469-1f467",
                  "name": "family: man, woman, girl",
                  "category": "people",
                  "keywords": "['family', 'girl', 'man', 'woman']",
                  "display": 1,
                  "translatedName": "Família: homem, mulher, menina"
                },
                {
                  "code": "1f468-1f469-1f467-1f466",
                  "name": "family: man, woman, girl, boy",
                  "category": "people",
                  "keywords": "['boy', 'family', 'girl', 'man', 'woman']",
                  "display": 1,
                  "translatedName": "Família: homem, mulher, menina, menino"
                },
                {
                  "code": "1f468-1f469-1f466-1f466",
                  "name": "family: man, woman, boy, boy",
                  "category": "people",
                  "keywords": "['boy', 'family', 'man', 'woman']",
                  "display": 1,
                  "translatedName": "Família: homem, mulher, menino, menino"
                },
                {
                  "code": "1f468-1f469-1f467-1f467",
                  "name": "family: man, woman, girl, girl",
                  "category": "people",
                  "keywords": "['family', 'girl', 'man', 'woman']",
                  "display": 1,
                  "translatedName": "Família: homem, mulher, menina, menina"
                },
                {
                  "code": "1f469-1f469-1f466",
                  "name": "family: woman, woman, boy",
                  "category": "people",
                  "keywords": "['boy', 'family', 'woman']",
                  "display": 1,
                  "translatedName": "Família: mulher, mulher, menino"
                },
                {
                  "code": "1f469-1f469-1f467",
                  "name": "family: woman, woman, girl",
                  "category": "people",
                  "keywords": "['family', 'girl', 'woman']",
                  "display": 1,
                  "translatedName": "Família: mulher, mulher, menina"
                },
                {
                  "code": "1f469-1f469-1f467-1f466",
                  "name": "family: woman, woman, girl, boy",
                  "category": "people",
                  "keywords": "['boy', 'family', 'girl', 'woman']",
                  "display": 1,
                  "translatedName": "Família: mulher, mulher, menina, menino"
                },
                {
                  "code": "1f469-1f469-1f466-1f466",
                  "name": "family: woman, woman, boy, boy",
                  "category": "people",
                  "keywords": "['boy', 'family', 'woman']",
                  "display": 1,
                  "translatedName": "Família: mulher, mulher, menino, menino"
                },
                {
                  "code": "1f469-1f469-1f467-1f467",
                  "name": "family: woman, woman, girl, girl",
                  "category": "people",
                  "keywords": "['family', 'girl', 'woman']",
                  "display": 1,
                  "translatedName": "Família: mulher, mulher, menina, menina"
                },
                {
                  "code": "1f468-1f468-1f466",
                  "name": "family: man, man, boy",
                  "category": "people",
                  "keywords": "['boy', 'family', 'man']",
                  "display": 1,
                  "translatedName": "Família: homem, homem, menino"
                },
                {
                  "code": "1f468-1f468-1f467",
                  "name": "family: man, man, girl",
                  "category": "people",
                  "keywords": "['family', 'girl', 'man']",
                  "display": 1,
                  "translatedName": "Família: homem, homem, menina"
                },
                {
                  "code": "1f468-1f468-1f467-1f466",
                  "name": "family: man, man, girl, boy",
                  "category": "people",
                  "keywords": "['boy', 'family', 'girl', 'man']",
                  "display": 1,
                  "translatedName": "Família: homem, homem, menina, menino"
                },
                {
                  "code": "1f468-1f468-1f466-1f466",
                  "name": "family: man, man, boy, boy",
                  "category": "people",
                  "keywords": "['boy', 'family', 'man']",
                  "display": 1,
                  "translatedName": "Família: homem, homem, menino, menino"
                },
                {
                  "code": "1f468-1f468-1f467-1f467",
                  "name": "family: man, man, girl, girl",
                  "category": "people",
                  "keywords": "['family', 'girl', 'man']",
                  "display": 1,
                  "translatedName": "Família: homem, homem, menina, menina"
                },
                {
                  "code": "1f469-1f466",
                  "name": "family: woman, boy",
                  "category": "people",
                  "keywords": "['boy', 'family', 'woman']",
                  "display": 1,
                  "translatedName": "Família: mulher, menino"
                },
                {
                  "code": "1f469-1f467",
                  "name": "family: woman, girl",
                  "category": "people",
                  "keywords": "['family', 'girl', 'woman']",
                  "display": 1,
                  "translatedName": "Família: mulher, menina"
                },
                {
                  "code": "1f469-1f467-1f466",
                  "name": "family: woman, girl, boy",
                  "category": "people",
                  "keywords": "['boy', 'family', 'girl', 'woman']",
                  "display": 1,
                  "translatedName": "Família: mulher, menina, menino"
                },
                {
                  "code": "1f469-1f466-1f466",
                  "name": "family: woman, boy, boy",
                  "category": "people",
                  "keywords": "['boy', 'family', 'woman']",
                  "display": 1,
                  "translatedName": "Família: mulher, menino, menino"
                },
                {
                  "code": "1f469-1f467-1f467",
                  "name": "family: woman, girl, girl",
                  "category": "people",
                  "keywords": "['family', 'girl', 'woman']",
                  "display": 1,
                  "translatedName": "Família: mulher, menina, menina"
                },
                {
                  "code": "1f468-1f466",
                  "name": "family: man, boy",
                  "category": "people",
                  "keywords": "['boy', 'family', 'man']",
                  "display": 1,
                  "translatedName": "Família: homem, menino"
                },
                {
                  "code": "1f468-1f467",
                  "name": "family: man, girl",
                  "category": "people",
                  "keywords": "['family', 'girl', 'man']",
                  "display": 1,
                  "translatedName": "Família: homem, menina"
                },
                {
                  "code": "1f468-1f467-1f466",
                  "name": "family: man, girl, boy",
                  "category": "people",
                  "keywords": "['boy', 'family', 'girl', 'man']",
                  "display": 1,
                  "translatedName": "Família: homem, menina, menino"
                },
                {
                  "code": "1f468-1f466-1f466",
                  "name": "family: man, boy, boy",
                  "category": "people",
                  "keywords": "['boy', 'family', 'man']",
                  "display": 1,
                  "translatedName": "Família: homem, menino, menino"
                },
                {
                  "code": "1f468-1f467-1f467",
                  "name": "family: man, girl, girl",
                  "category": "people",
                  "keywords": "['family', 'girl', 'man']",
                  "display": 1,
                  "translatedName": "Família: homem, menina, menina"
                },
                {
                  "code": "1f45a",
                  "name": "woman’s clothes",
                  "category": "people",
                  "keywords": "['clothing', 'woman']",
                  "display": 1,
                  "translatedName": "roupas da mulher"
                },
                {
                  "code": "1f455",
                  "name": "t-shirt",
                  "category": "people",
                  "keywords": "['clothing', 'shirt', 'tshirt']",
                  "display": 1,
                  "translatedName": "camisa"
                },
                {
                  "code": "1f456",
                  "name": "jeans",
                  "category": "people",
                  "keywords": "['clothing', 'pants', 'trousers']",
                  "display": 1,
                  "translatedName": "jeans"
                },
                {
                  "code": "1f454",
                  "name": "necktie",
                  "category": "people",
                  "keywords": "['clothing']",
                  "display": 1,
                  "translatedName": "gravata"
                },
                {
                  "code": "1f457",
                  "name": "dress",
                  "category": "people",
                  "keywords": "['clothing']",
                  "display": 1,
                  "translatedName": "vestir"
                },
                {
                  "code": "1f459",
                  "name": "bikini",
                  "category": "people",
                  "keywords": "['clothing', 'swim']",
                  "display": 1,
                  "translatedName": "bikini"
                },
                {
                  "code": "1f458",
                  "name": "kimono",
                  "category": "people",
                  "keywords": "['clothing']",
                  "display": 1,
                  "translatedName": "quimono"
                },
                {
                  "code": "1f460",
                  "name": "high-heeled shoe",
                  "category": "people",
                  "keywords": "['clothing', 'heel', 'shoe', 'woman']",
                  "display": 1,
                  "translatedName": "sapato de salto alto"
                },
                {
                  "code": "1f461",
                  "name": "woman’s sandal",
                  "category": "people",
                  "keywords": "['clothing', 'sandal', 'shoe', 'woman']",
                  "display": 1,
                  "translatedName": "Sandália da mulher"
                },
                {
                  "code": "1f462",
                  "name": "woman’s boot",
                  "category": "people",
                  "keywords": "['boot', 'clothing', 'shoe', 'woman']",
                  "display": 1,
                  "translatedName": "bota da mulher"
                },
                {
                  "code": "1f45e",
                  "name": "man’s shoe",
                  "category": "people",
                  "keywords": "['clothing', 'man', 'shoe']",
                  "display": 1,
                  "translatedName": "sapato de homem"
                },
                {
                  "code": "1f45f",
                  "name": "running shoe",
                  "category": "people",
                  "keywords": "['athletic', 'clothing', 'shoe', 'sneaker']",
                  "display": 1,
                  "translatedName": "tênis de corrida"
                },
                {
                  "code": "1f452",
                  "name": "woman’s hat",
                  "category": "people",
                  "keywords": "['clothing', 'hat', 'woman']",
                  "display": 1,
                  "translatedName": "chapéu da mulher"
                },
                {
                  "code": "1f3a9",
                  "name": "top hat",
                  "category": "people",
                  "keywords": "['clothing', 'hat', 'top', 'tophat']",
                  "display": 1,
                  "translatedName": "cartola"
                },
                {
                  "code": "1f393",
                  "name": "graduation cap",
                  "category": "people",
                  "keywords": "['cap', 'celebration', 'clothing', 'graduation', 'hat']",
                  "display": 1,
                  "translatedName": "chapéu de graduação"
                },
                {
                  "code": "1f451",
                  "name": "crown",
                  "category": "people",
                  "keywords": "['clothing', 'king', 'queen']",
                  "display": 1,
                  "translatedName": "coroa"
                },
                {
                  "code": "26d1",
                  "name": "rescue worker’s helmet",
                  "category": "people",
                  "keywords": "['aid', 'cross', 'face', 'hat', 'helmet']",
                  "display": 1,
                  "translatedName": "Capacete do trabalhador de resgate"
                },
                {
                  "code": "1f392",
                  "name": "school backpack",
                  "category": "people",
                  "keywords": "['bag', 'satchel', 'school']",
                  "display": 1,
                  "translatedName": "mochila escolar"
                },
                {
                  "code": "1f45d",
                  "name": "clutch bag",
                  "category": "people",
                  "keywords": "['bag', 'clothing', 'pouch']",
                  "display": 1,
                  "translatedName": "necessaire"
                },
                {
                  "code": "1f45b",
                  "name": "purse",
                  "category": "people",
                  "keywords": "['clothing', 'coin']",
                  "display": 1,
                  "translatedName": "Bolsa"
                },
                {
                  "code": "1f45c",
                  "name": "handbag",
                  "category": "people",
                  "keywords": "['bag', 'clothing', 'purse']",
                  "display": 1,
                  "translatedName": "Bolsa"
                },
                {
                  "code": "1f4bc",
                  "name": "briefcase",
                  "category": "people",
                  "keywords": "['briefcase']",
                  "display": 1,
                  "translatedName": "pasta"
                },
                {
                  "code": "1f453",
                  "name": "glasses",
                  "category": "people",
                  "keywords": "['clothing', 'eye', 'eyeglasses', 'eyewear']",
                  "display": 1,
                  "translatedName": "copos"
                },
                {
                  "code": "1f576",
                  "name": "sunglasses",
                  "category": "people",
                  "keywords": "['dark', 'eye', 'eyewear', 'glasses']",
                  "display": 1,
                  "translatedName": "oculos escuros"
                },
                {
                  "code": "1f302",
                  "name": "closed umbrella",
                  "category": "people",
                  "keywords": "['clothing', 'rain', 'umbrella']",
                  "display": 1,
                  "translatedName": "guarda-chuva fechado."
                },
                {
                  "code": 2602,
                  "name": "umbrella",
                  "category": "people",
                  "keywords": "['clothing', 'rain']",
                  "display": 1,
                  "translatedName": "guarda-chuva"
                },
                {
                  "code": "1f436",
                  "name": "dog face",
                  "category": "nature",
                  "keywords": "['dog', 'face', 'pet']",
                  "display": 1,
                  "translatedName": "cara de cachorro."
                },
                {
                  "code": "1f431",
                  "name": "cat face",
                  "category": "nature",
                  "keywords": "['cat', 'face', 'pet']",
                  "display": 1,
                  "translatedName": "cara de Gato"
                },
                {
                  "code": "1f42d",
                  "name": "mouse face",
                  "category": "nature",
                  "keywords": "['face', 'mouse']",
                  "display": 1,
                  "translatedName": "Cara do rato"
                },
                {
                  "code": "1f439",
                  "name": "hamster face",
                  "category": "nature",
                  "keywords": "['face', 'hamster', 'pet']",
                  "display": 1,
                  "translatedName": "cara de hamster"
                },
                {
                  "code": "1f430",
                  "name": "rabbit face",
                  "category": "nature",
                  "keywords": "['bunny', 'face', 'pet', 'rabbit']",
                  "display": 1,
                  "translatedName": "rosto de coelho"
                },
                {
                  "code": "1f98a",
                  "name": "fox face",
                  "category": "nature",
                  "keywords": "['face', 'fox']",
                  "display": 1,
                  "translatedName": "raposa cara"
                },
                {
                  "code": "1f43b",
                  "name": "bear face",
                  "category": "nature",
                  "keywords": "['bear', 'face']",
                  "display": 1,
                  "translatedName": "Cara de urso"
                },
                {
                  "code": "1f43c",
                  "name": "panda face",
                  "category": "nature",
                  "keywords": "['face', 'panda']",
                  "display": 1,
                  "translatedName": "Panda Face"
                },
                {
                  "code": "1f428",
                  "name": "koala",
                  "category": "nature",
                  "keywords": "['bear']",
                  "display": 1,
                  "translatedName": "Koala."
                },
                {
                  "code": "1f42f",
                  "name": "tiger face",
                  "category": "nature",
                  "keywords": "['face', 'tiger']",
                  "display": 1,
                  "translatedName": "Cara do tigre"
                },
                {
                  "code": "1f981",
                  "name": "lion face",
                  "category": "nature",
                  "keywords": "['Leo', 'face', 'lion', 'zodiac']",
                  "display": 1,
                  "translatedName": "rosto de leão"
                },
                {
                  "code": "1f42e",
                  "name": "cow face",
                  "category": "nature",
                  "keywords": "['cow', 'face']",
                  "display": 1,
                  "translatedName": "cara de vaca."
                },
                {
                  "code": "1f437",
                  "name": "pig face",
                  "category": "nature",
                  "keywords": "['face', 'pig']",
                  "display": 1,
                  "translatedName": "cara de porco"
                },
                {
                  "code": "1f43d",
                  "name": "pig nose",
                  "category": "nature",
                  "keywords": "['face', 'nose', 'pig']",
                  "display": 1,
                  "translatedName": "nariz de porco"
                },
                {
                  "code": "1f438",
                  "name": "frog face",
                  "category": "nature",
                  "keywords": "['face', 'frog']",
                  "display": 1,
                  "translatedName": "rosto de sapo"
                },
                {
                  "code": "1f435",
                  "name": "monkey face",
                  "category": "nature",
                  "keywords": "['face', 'monkey']",
                  "display": 1,
                  "translatedName": "cara de macaco"
                },
                {
                  "code": "1f648",
                  "name": "see-no-evil monkey",
                  "category": "nature",
                  "keywords": "['evil', 'face', 'forbidden', 'gesture', 'monkey', 'no', 'not', 'prohibited', 'see']",
                  "display": 1,
                  "translatedName": "Macaco Ver-No-Evil"
                },
                {
                  "code": "1f649",
                  "name": "hear-no-evil monkey",
                  "category": "nature",
                  "keywords": "['evil', 'face', 'forbidden', 'gesture', 'hear', 'monkey', 'no', 'not', 'prohibited']",
                  "display": 1,
                  "translatedName": "Macaco Hear-No-Evil"
                },
                {
                  "code": "1f64a",
                  "name": "speak-no-evil monkey",
                  "category": "nature",
                  "keywords": "['evil', 'face', 'forbidden', 'gesture', 'monkey', 'no', 'not', 'prohibited', 'speak']",
                  "display": 1,
                  "translatedName": "Macaco Não-Evil"
                },
                {
                  "code": "1f412",
                  "name": "monkey",
                  "category": "nature",
                  "keywords": "['monkey']",
                  "display": 1,
                  "translatedName": "macaco"
                },
                {
                  "code": "1f414",
                  "name": "chicken",
                  "category": "nature",
                  "keywords": "['bird', 'chicken']",
                  "display": 1,
                  "translatedName": "frango"
                },
                {
                  "code": "1f427",
                  "name": "penguin",
                  "category": "nature",
                  "keywords": "['bird', 'penguin']",
                  "display": 1,
                  "translatedName": "pinguim"
                },
                {
                  "code": "1f426",
                  "name": "bird",
                  "category": "nature",
                  "keywords": "['bird']",
                  "display": 1,
                  "translatedName": "pássaro"
                },
                {
                  "code": "1f424",
                  "name": "baby chick",
                  "category": "nature",
                  "keywords": "['baby', 'bird', 'chick']",
                  "display": 1,
                  "translatedName": "garota do bebê"
                },
                {
                  "code": "1f423",
                  "name": "hatching chick",
                  "category": "nature",
                  "keywords": "['baby', 'bird', 'chick', 'hatching']",
                  "display": 1,
                  "translatedName": "Chicking Chick"
                },
                {
                  "code": "1f425",
                  "name": "front-facing baby chick",
                  "category": "nature",
                  "keywords": "['baby', 'bird', 'chick']",
                  "display": 1,
                  "translatedName": "Pintainho de bebê voltado para frente"
                },
                {
                  "code": "1f986",
                  "name": "duck",
                  "category": "nature",
                  "keywords": "['bird', 'duck']",
                  "display": 1,
                  "translatedName": "Pato"
                },
                {
                  "code": "1f985",
                  "name": "eagle",
                  "category": "nature",
                  "keywords": "['bird', 'eagle']",
                  "display": 1,
                  "translatedName": "Águia"
                },
                {
                  "code": "1f989",
                  "name": "owl",
                  "category": "nature",
                  "keywords": "['bird', 'owl', 'wise']",
                  "display": 1,
                  "translatedName": "Coruja"
                },
                {
                  "code": "1f987",
                  "name": "bat",
                  "category": "nature",
                  "keywords": "['bat', 'vampire']",
                  "display": 1,
                  "translatedName": "bastão"
                },
                {
                  "code": "1f43a",
                  "name": "wolf face",
                  "category": "nature",
                  "keywords": "['face', 'wolf']",
                  "display": 1,
                  "translatedName": "cara de lobo."
                },
                {
                  "code": "1f417",
                  "name": "boar",
                  "category": "nature",
                  "keywords": "['pig']",
                  "display": 1,
                  "translatedName": "javali"
                },
                {
                  "code": "1f434",
                  "name": "horse face",
                  "category": "nature",
                  "keywords": "['face', 'horse']",
                  "display": 1,
                  "translatedName": "Cara de cavalo"
                },
                {
                  "code": "1f984",
                  "name": "unicorn face",
                  "category": "nature",
                  "keywords": "['face', 'unicorn']",
                  "display": 1,
                  "translatedName": "cara de unicórnio"
                },
                {
                  "code": "1f41d",
                  "name": "honeybee",
                  "category": "nature",
                  "keywords": "['bee', 'insect']",
                  "display": 1,
                  "translatedName": "Honeybee."
                },
                {
                  "code": "1f41b",
                  "name": "bug",
                  "category": "nature",
                  "keywords": "['insect']",
                  "display": 1,
                  "translatedName": "erro"
                },
                {
                  "code": "1f98b",
                  "name": "butterfly",
                  "category": "nature",
                  "keywords": "['butterfly', 'insect', 'pretty']",
                  "display": 1,
                  "translatedName": "borboleta"
                },
                {
                  "code": "1f40c",
                  "name": "snail",
                  "category": "nature",
                  "keywords": "['snail']",
                  "display": 1,
                  "translatedName": "Caracol"
                },
                {
                  "code": "1f41a",
                  "name": "spiral shell",
                  "category": "nature",
                  "keywords": "['shell', 'spiral']",
                  "display": 1,
                  "translatedName": "concha espiral"
                },
                {
                  "code": "1f41e",
                  "name": "lady beetle",
                  "category": "nature",
                  "keywords": "['beetle', 'insect', 'ladybird', 'ladybug']",
                  "display": 1,
                  "translatedName": "Lady Beetle."
                },
                {
                  "code": "1f41c",
                  "name": "ant",
                  "category": "nature",
                  "keywords": "['insect']",
                  "display": 1,
                  "translatedName": "formiga"
                },
                {
                  "code": "1f577",
                  "name": "spider",
                  "category": "nature",
                  "keywords": "['insect']",
                  "display": 1,
                  "translatedName": "aranha"
                },
                {
                  "code": "1f578",
                  "name": "spider web",
                  "category": "nature",
                  "keywords": "['spider', 'web']",
                  "display": 1,
                  "translatedName": "teia de aranha"
                },
                {
                  "code": "1f422",
                  "name": "turtle",
                  "category": "nature",
                  "keywords": "['terrapin', 'tortoise', 'turtle']",
                  "display": 1,
                  "translatedName": "tartaruga"
                },
                {
                  "code": "1f40d",
                  "name": "snake",
                  "category": "nature",
                  "keywords": "['Ophiuchus', 'bearer', 'serpent', 'zodiac']",
                  "display": 1,
                  "translatedName": "cobra"
                },
                {
                  "code": "1f98e",
                  "name": "lizard",
                  "category": "nature",
                  "keywords": "['lizard', 'reptile']",
                  "display": 1,
                  "translatedName": "Lagarto"
                },
                {
                  "code": "1f982",
                  "name": "scorpion",
                  "category": "nature",
                  "keywords": "['Scorpius', 'scorpio', 'zodiac']",
                  "display": 1,
                  "translatedName": "escorpião"
                },
                {
                  "code": "1f980",
                  "name": "crab",
                  "category": "nature",
                  "keywords": "['Cancer', 'zodiac']",
                  "display": 1,
                  "translatedName": "caranguejo"
                },
                {
                  "code": "1f991",
                  "name": "squid",
                  "category": "nature",
                  "keywords": "['food', 'molusc', 'squid']",
                  "display": 1,
                  "translatedName": "Lula"
                },
                {
                  "code": "1f419",
                  "name": "octopus",
                  "category": "nature",
                  "keywords": "['octopus']",
                  "display": 1,
                  "translatedName": "polvo"
                },
                {
                  "code": "1f990",
                  "name": "shrimp",
                  "category": "nature",
                  "keywords": "['food', 'shellfish', 'shrimp', 'small']",
                  "display": 1,
                  "translatedName": "camarão"
                },
                {
                  "code": "1f420",
                  "name": "tropical fish",
                  "category": "nature",
                  "keywords": "['fish', 'tropical']",
                  "display": 1,
                  "translatedName": "peixe tropical"
                },
                {
                  "code": "1f41f",
                  "name": "fish",
                  "category": "nature",
                  "keywords": "['Pisces', 'zodiac']",
                  "display": 1,
                  "translatedName": "peixe"
                },
                {
                  "code": "1f421",
                  "name": "blowfish",
                  "category": "nature",
                  "keywords": "['fish']",
                  "display": 1,
                  "translatedName": "blowfish"
                },
                {
                  "code": "1f42c",
                  "name": "dolphin",
                  "category": "nature",
                  "keywords": "['flipper']",
                  "display": 1,
                  "translatedName": "golfinho"
                },
                {
                  "code": "1f988",
                  "name": "shark",
                  "category": "nature",
                  "keywords": "['fish', 'shark']",
                  "display": 1,
                  "translatedName": "Tubarão"
                },
                {
                  "code": "1f433",
                  "name": "spouting whale",
                  "category": "nature",
                  "keywords": "['face', 'spouting', 'whale']",
                  "display": 1,
                  "translatedName": "baleia de jorrar"
                },
                {
                  "code": "1f40b",
                  "name": "whale",
                  "category": "nature",
                  "keywords": "['whale']",
                  "display": 1,
                  "translatedName": "baleia"
                },
                {
                  "code": "1f40a",
                  "name": "crocodile",
                  "category": "nature",
                  "keywords": "['crocodile']",
                  "display": 1,
                  "translatedName": "crocodilo"
                },
                {
                  "code": "1f406",
                  "name": "leopard",
                  "category": "nature",
                  "keywords": "['leopard']",
                  "display": 1,
                  "translatedName": "leopardo"
                },
                {
                  "code": "1f405",
                  "name": "tiger",
                  "category": "nature",
                  "keywords": "['tiger']",
                  "display": 1,
                  "translatedName": "tigre"
                },
                {
                  "code": "1f403",
                  "name": "water buffalo",
                  "category": "nature",
                  "keywords": "['buffalo', 'water']",
                  "display": 1,
                  "translatedName": "búfalo Marinho"
                },
                {
                  "code": "1f402",
                  "name": "ox",
                  "category": "nature",
                  "keywords": "['Taurus', 'bull', 'zodiac']",
                  "display": 1,
                  "translatedName": "boi"
                },
                {
                  "code": "1f404",
                  "name": "cow",
                  "category": "nature",
                  "keywords": "['cow']",
                  "display": 1,
                  "translatedName": "vaca"
                },
                {
                  "code": "1f98c",
                  "name": "deer",
                  "category": "nature",
                  "keywords": "['deer']",
                  "display": 1,
                  "translatedName": "cervo"
                },
                {
                  "code": "1f42a",
                  "name": "camel",
                  "category": "nature",
                  "keywords": "['dromedary', 'hump']",
                  "display": 1,
                  "translatedName": "camelo"
                },
                {
                  "code": "1f42b",
                  "name": "two-hump camel",
                  "category": "nature",
                  "keywords": "['bactrian', 'camel', 'hump']",
                  "display": 1,
                  "translatedName": "camelo de dois corcunda"
                },
                {
                  "code": "1f418",
                  "name": "elephant",
                  "category": "nature",
                  "keywords": "['elephant']",
                  "display": 1,
                  "translatedName": "elefante"
                },
                {
                  "code": "1f98f",
                  "name": "rhinoceros",
                  "category": "nature",
                  "keywords": "['rhinoceros']",
                  "display": 1,
                  "translatedName": "rinoceronte"
                },
                {
                  "code": "1f98d",
                  "name": "gorilla",
                  "category": "nature",
                  "keywords": "['gorilla']",
                  "display": 1,
                  "translatedName": "gorila"
                },
                {
                  "code": "1f40e",
                  "name": "horse",
                  "category": "nature",
                  "keywords": "['equestrian', 'racehorse', 'racing']",
                  "display": 1,
                  "translatedName": "cavalo"
                },
                {
                  "code": "1f416",
                  "name": "pig",
                  "category": "nature",
                  "keywords": "['sow']",
                  "display": 1,
                  "translatedName": "porco"
                },
                {
                  "code": "1f410",
                  "name": "goat",
                  "category": "nature",
                  "keywords": "['Capricorn', 'zodiac']",
                  "display": 1,
                  "translatedName": "cabra"
                },
                {
                  "code": "1f40f",
                  "name": "ram",
                  "category": "nature",
                  "keywords": "['Aries', 'male', 'sheep', 'zodiac']",
                  "display": 1,
                  "translatedName": "RAM"
                },
                {
                  "code": "1f411",
                  "name": "ewe",
                  "category": "nature",
                  "keywords": "['female', 'sheep']",
                  "display": 1,
                  "translatedName": "ovelha"
                },
                {
                  "code": "1f415",
                  "name": "dog",
                  "category": "nature",
                  "keywords": "['pet']",
                  "display": 1,
                  "translatedName": "cão"
                },
                {
                  "code": "1f429",
                  "name": "poodle",
                  "category": "nature",
                  "keywords": "['dog']",
                  "display": 1,
                  "translatedName": "poodle"
                },
                {
                  "code": "1f408",
                  "name": "cat",
                  "category": "nature",
                  "keywords": "['pet']",
                  "display": 1,
                  "translatedName": "gato"
                },
                {
                  "code": "1f413",
                  "name": "rooster",
                  "category": "nature",
                  "keywords": "['bird', 'rooster']",
                  "display": 1,
                  "translatedName": "Galo"
                },
                {
                  "code": "1f983",
                  "name": "turkey",
                  "category": "nature",
                  "keywords": "['bird', 'turkey']",
                  "display": 1,
                  "translatedName": "Turquia"
                },
                {
                  "code": "1f54a",
                  "name": "dove",
                  "category": "nature",
                  "keywords": "['bird', 'fly', 'peace']",
                  "display": 1,
                  "translatedName": "pomba"
                },
                {
                  "code": "1f407",
                  "name": "rabbit",
                  "category": "nature",
                  "keywords": "['bunny', 'pet']",
                  "display": 1,
                  "translatedName": "Coelho"
                },
                {
                  "code": "1f401",
                  "name": "mouse",
                  "category": "nature",
                  "keywords": "['mouse']",
                  "display": 1,
                  "translatedName": "mouse"
                },
                {
                  "code": "1f400",
                  "name": "rat",
                  "category": "nature",
                  "keywords": "['rat']",
                  "display": 1,
                  "translatedName": "rato"
                },
                {
                  "code": "1f43f",
                  "name": "chipmunk",
                  "category": "nature",
                  "keywords": "['chipmunk']",
                  "display": 1,
                  "translatedName": "Esquilo"
                },
                {
                  "code": "1f43e",
                  "name": "paw prints",
                  "category": "nature",
                  "keywords": "['feet', 'paw', 'print']",
                  "display": 1,
                  "translatedName": "pegadas"
                },
                {
                  "code": "1f409",
                  "name": "dragon",
                  "category": "nature",
                  "keywords": "['fairy tale']",
                  "display": 1,
                  "translatedName": "Dragão"
                },
                {
                  "code": "1f432",
                  "name": "dragon face",
                  "category": "nature",
                  "keywords": "['dragon', 'face', 'fairy tale']",
                  "display": 1,
                  "translatedName": "Face do dragão"
                },
                {
                  "code": "1f335",
                  "name": "cactus",
                  "category": "nature",
                  "keywords": "['plant']",
                  "display": 1,
                  "translatedName": "cacto"
                },
                {
                  "code": "1f384",
                  "name": "Christmas tree",
                  "category": "nature",
                  "keywords": "['Christmas', 'celebration', 'tree']",
                  "display": 1,
                  "translatedName": "árvore de Natal"
                },
                {
                  "code": "1f332",
                  "name": "evergreen tree",
                  "category": "nature",
                  "keywords": "['tree']",
                  "display": 1,
                  "translatedName": "Árvore Evergreen"
                },
                {
                  "code": "1f333",
                  "name": "deciduous tree",
                  "category": "nature",
                  "keywords": "['deciduous', 'shedding', 'tree']",
                  "display": 1,
                  "translatedName": "árvore de folhas secas"
                },
                {
                  "code": "1f334",
                  "name": "palm tree",
                  "category": "nature",
                  "keywords": "['palm', 'tree']",
                  "display": 1,
                  "translatedName": "Palmeira"
                },
                {
                  "code": "1f331",
                  "name": "seedling",
                  "category": "nature",
                  "keywords": "['young']",
                  "display": 1,
                  "translatedName": "plantinha"
                },
                {
                  "code": "1f33f",
                  "name": "herb",
                  "category": "nature",
                  "keywords": "['leaf']",
                  "display": 1,
                  "translatedName": "erva"
                },
                {
                  "code": 2618,
                  "name": "shamrock",
                  "category": "nature",
                  "keywords": "['plant']",
                  "display": 1,
                  "translatedName": "trevo"
                },
                {
                  "code": "1f340",
                  "name": "four leaf clover",
                  "category": "nature",
                  "keywords": "['4', 'clover', 'four', 'leaf']",
                  "display": 1,
                  "translatedName": "trevo-de-quatro-folhas"
                },
                {
                  "code": "1f38d",
                  "name": "pine decoration",
                  "category": "nature",
                  "keywords": "['Japanese', 'bamboo', 'celebration', 'pine']",
                  "display": 1,
                  "translatedName": "decoração de pinho"
                },
                {
                  "code": "1f38b",
                  "name": "tanabata tree",
                  "category": "nature",
                  "keywords": "['Japanese', 'banner', 'celebration', 'tree']",
                  "display": 1,
                  "translatedName": "Árvore Tanabata."
                },
                {
                  "code": "1f343",
                  "name": "leaf fluttering in wind",
                  "category": "nature",
                  "keywords": "['blow', 'flutter', 'leaf', 'wind']",
                  "display": 1,
                  "translatedName": "Folha tremulando no vento"
                },
                {
                  "code": "1f342",
                  "name": "fallen leaf",
                  "category": "nature",
                  "keywords": "['falling', 'leaf']",
                  "display": 1,
                  "translatedName": "folha caída"
                },
                {
                  "code": "1f341",
                  "name": "maple leaf",
                  "category": "nature",
                  "keywords": "['falling', 'leaf', 'maple']",
                  "display": 1,
                  "translatedName": "folha de Carvalho"
                },
                {
                  "code": "1f344",
                  "name": "mushroom",
                  "category": "nature",
                  "keywords": "['toadstool']",
                  "display": 1,
                  "translatedName": "cogumelo"
                },
                {
                  "code": "1f33e",
                  "name": "sheaf of rice",
                  "category": "nature",
                  "keywords": "['ear', 'grain', 'rice']",
                  "display": 1,
                  "translatedName": "feixe de arroz"
                },
                {
                  "code": "1f490",
                  "name": "bouquet",
                  "category": "nature",
                  "keywords": "['flower']",
                  "display": 1,
                  "translatedName": "ramalhete"
                },
                {
                  "code": "1f337",
                  "name": "tulip",
                  "category": "nature",
                  "keywords": "['flower']",
                  "display": 1,
                  "translatedName": "tulipa"
                },
                {
                  "code": "1f339",
                  "name": "rose",
                  "category": "nature",
                  "keywords": "['flower']",
                  "display": 1,
                  "translatedName": "Rosa"
                },
                {
                  "code": "1f940",
                  "name": "wilted flower",
                  "category": "nature",
                  "keywords": "['flower', 'wilted']",
                  "display": 1,
                  "translatedName": "Flor murcha"
                },
                {
                  "code": "1f33b",
                  "name": "sunflower",
                  "category": "nature",
                  "keywords": "['flower', 'sun']",
                  "display": 1,
                  "translatedName": "girassol"
                },
                {
                  "code": "1f33c",
                  "name": "blossom",
                  "category": "nature",
                  "keywords": "['flower']",
                  "display": 1,
                  "translatedName": "Flor"
                },
                {
                  "code": "1f338",
                  "name": "cherry blossom",
                  "category": "nature",
                  "keywords": "['blossom', 'cherry', 'flower']",
                  "display": 1,
                  "translatedName": "Flor de cerejeira"
                },
                {
                  "code": "1f33a",
                  "name": "hibiscus",
                  "category": "nature",
                  "keywords": "['flower']",
                  "display": 1,
                  "translatedName": "Hibiscus."
                },
                {
                  "code": "1f30e",
                  "name": "globe showing Americas",
                  "category": "nature",
                  "keywords": "['Americas', 'earth', 'globe', 'world']",
                  "display": 1,
                  "translatedName": "Globo mostrando as Américas"
                },
                {
                  "code": "1f30d",
                  "name": "globe showing Europe-Africa",
                  "category": "nature",
                  "keywords": "['Africa', 'Europe', 'earth', 'globe', 'world']",
                  "display": 1,
                  "translatedName": "Globo mostrando Europa-África"
                },
                {
                  "code": "1f30f",
                  "name": "globe showing Asia-Australia",
                  "category": "nature",
                  "keywords": "['Asia', 'Australia', 'earth', 'globe', 'world']",
                  "display": 1,
                  "translatedName": "Globo mostrando a Ásia-Austrália"
                },
                {
                  "code": "1f315",
                  "name": "full moon",
                  "category": "nature",
                  "keywords": "['full', 'moon']",
                  "display": 1,
                  "translatedName": "lua cheia"
                },
                {
                  "code": "1f316",
                  "name": "waning gibbous moon",
                  "category": "nature",
                  "keywords": "['gibbous', 'moon', 'waning']",
                  "display": 1,
                  "translatedName": "Lua Gibbous Waning."
                },
                {
                  "code": "1f317",
                  "name": "last quarter moon",
                  "category": "nature",
                  "keywords": "['moon', 'quarter']",
                  "display": 1,
                  "translatedName": "Último quarto da lua"
                },
                {
                  "code": "1f318",
                  "name": "waning crescent moon",
                  "category": "nature",
                  "keywords": "['crescent', 'moon', 'waning']",
                  "display": 1,
                  "translatedName": "Moon Crescent Waning."
                },
                {
                  "code": "1f311",
                  "name": "new moon",
                  "category": "nature",
                  "keywords": "['dark', 'moon']",
                  "display": 1,
                  "translatedName": "lua Nova"
                },
                {
                  "code": "1f312",
                  "name": "waxing crescent moon",
                  "category": "nature",
                  "keywords": "['crescent', 'moon', 'waxing']",
                  "display": 1,
                  "translatedName": "Despanhando a lua crescente"
                },
                {
                  "code": "1f313",
                  "name": "first quarter moon",
                  "category": "nature",
                  "keywords": "['moon', 'quarter']",
                  "display": 1,
                  "translatedName": "primeiro quarto da lua"
                },
                {
                  "code": "1f314",
                  "name": "waxing gibbous moon",
                  "category": "nature",
                  "keywords": "['gibbous', 'moon', 'waxing']",
                  "display": 1,
                  "translatedName": "Encerre a lua gibbous."
                },
                {
                  "code": "1f31a",
                  "name": "new moon face",
                  "category": "nature",
                  "keywords": "['face', 'moon']",
                  "display": 1,
                  "translatedName": "cara da nova lua"
                },
                {
                  "code": "1f31d",
                  "name": "full moon with face",
                  "category": "nature",
                  "keywords": "['bright', 'face', 'full', 'moon']",
                  "display": 1,
                  "translatedName": "Lua cheia com cara"
                },
                {
                  "code": "1f31e",
                  "name": "sun with face",
                  "category": "nature",
                  "keywords": "['bright', 'face', 'sun']",
                  "display": 1,
                  "translatedName": "Sol com cara"
                },
                {
                  "code": "1f31b",
                  "name": "first quarter moon with face",
                  "category": "nature",
                  "keywords": "['face', 'moon', 'quarter']",
                  "display": 1,
                  "translatedName": "Lua do primeiro quarto com cara"
                },
                {
                  "code": "1f31c",
                  "name": "last quarter moon with face",
                  "category": "nature",
                  "keywords": "['face', 'moon', 'quarter']",
                  "display": 1,
                  "translatedName": "Última lua de quarto com rosto"
                },
                {
                  "code": "1f319",
                  "name": "crescent moon",
                  "category": "nature",
                  "keywords": "['crescent', 'moon']",
                  "display": 1,
                  "translatedName": "lua crescente"
                },
                {
                  "code": "1f4ab",
                  "name": "dizzy",
                  "category": "nature",
                  "keywords": "['comic', 'star']",
                  "display": 1,
                  "translatedName": "tonto"
                },
                {
                  "code": "2b50",
                  "name": "white medium star",
                  "category": "nature",
                  "keywords": "['star']",
                  "display": 1,
                  "translatedName": "Estrela média branca"
                },
                {
                  "code": "1f31f",
                  "name": "glowing star",
                  "category": "nature",
                  "keywords": "['glittery', 'glow', 'shining', 'sparkle', 'star']",
                  "display": 1,
                  "translatedName": "estrela brilhante."
                },
                {
                  "code": 2728,
                  "name": "sparkles",
                  "category": "nature",
                  "keywords": "['sparkle', 'star']",
                  "display": 1,
                  "translatedName": "brilhos"
                },
                {
                  "code": "26a1",
                  "name": "high voltage",
                  "category": "nature",
                  "keywords": "['danger', 'electric', 'electricity', 'lightning', 'voltage', 'zap']",
                  "display": 1,
                  "translatedName": "alta voltagem"
                },
                {
                  "code": "1f525",
                  "name": "fire",
                  "category": "nature",
                  "keywords": "['flame', 'tool']",
                  "display": 1,
                  "translatedName": "incêndio"
                },
                {
                  "code": "1f4a5",
                  "name": "collision",
                  "category": "nature",
                  "keywords": "['boom', 'comic']",
                  "display": 1,
                  "translatedName": "colisão"
                },
                {
                  "code": 2604,
                  "name": "comet",
                  "category": "nature",
                  "keywords": "['space']",
                  "display": 1,
                  "translatedName": "cometa"
                },
                {
                  "code": 2600,
                  "name": "sun",
                  "category": "nature",
                  "keywords": "['bright', 'rays', 'sunny']",
                  "display": 1,
                  "translatedName": "sol"
                },
                {
                  "code": "1f324",
                  "name": "sun behind small cloud",
                  "category": "nature",
                  "keywords": "['cloud', 'sun']",
                  "display": 1,
                  "translatedName": "Sol atrás de pequena nuvem"
                },
                {
                  "code": "26c5",
                  "name": "sun behind cloud",
                  "category": "nature",
                  "keywords": "['cloud', 'sun']",
                  "display": 1,
                  "translatedName": "Sol atrás da nuvem"
                },
                {
                  "code": "1f325",
                  "name": "sun behind large cloud",
                  "category": "nature",
                  "keywords": "['cloud', 'sun']",
                  "display": 1,
                  "translatedName": "Sol atrás de grande nuvem"
                },
                {
                  "code": "1f326",
                  "name": "sun behind rain cloud",
                  "category": "nature",
                  "keywords": "['cloud', 'rain', 'sun']",
                  "display": 1,
                  "translatedName": "Sol atrás da nuvem de chuva"
                },
                {
                  "code": "1f308",
                  "name": "rainbow",
                  "category": "nature",
                  "keywords": "['rain']",
                  "display": 1,
                  "translatedName": "arco-íris"
                },
                {
                  "code": 2601,
                  "name": "cloud",
                  "category": "nature",
                  "keywords": "['weather']",
                  "display": 1,
                  "translatedName": "nuvem"
                },
                {
                  "code": "1f327",
                  "name": "cloud with rain",
                  "category": "nature",
                  "keywords": "['cloud', 'rain']",
                  "display": 1,
                  "translatedName": "nuvem com chuva"
                },
                {
                  "code": "26c8",
                  "name": "cloud with lightning and rain",
                  "category": "nature",
                  "keywords": "['cloud', 'rain', 'thunder']",
                  "display": 1,
                  "translatedName": "nuvem com relâmpago e chuva"
                },
                {
                  "code": "1f329",
                  "name": "cloud with lightning",
                  "category": "nature",
                  "keywords": "['cloud', 'lightning']",
                  "display": 1,
                  "translatedName": "Nuvem com relâmpago"
                },
                {
                  "code": "1f328",
                  "name": "cloud with snow",
                  "category": "nature",
                  "keywords": "['cloud', 'cold', 'snow']",
                  "display": 1,
                  "translatedName": "nuvem com neve"
                },
                {
                  "code": 2603,
                  "name": "snowman",
                  "category": "nature",
                  "keywords": "['cold', 'snow']",
                  "display": 1,
                  "translatedName": "boneco de neve"
                },
                {
                  "code": "26c4",
                  "name": "snowman without snow",
                  "category": "nature",
                  "keywords": "['cold', 'snow', 'snowman']",
                  "display": 1,
                  "translatedName": "Boneco de neve sem neve"
                },
                {
                  "code": 2744,
                  "name": "snowflake",
                  "category": "nature",
                  "keywords": "['cold', 'snow']",
                  "display": 1,
                  "translatedName": "floco de neve"
                },
                {
                  "code": "1f32c",
                  "name": "wind face",
                  "category": "nature",
                  "keywords": "['blow', 'cloud', 'face', 'wind']",
                  "display": 1,
                  "translatedName": "cara de vento"
                },
                {
                  "code": "1f4a8",
                  "name": "dashing away",
                  "category": "nature",
                  "keywords": "['comic', 'dash', 'running']",
                  "display": 1,
                  "translatedName": "arremessando"
                },
                {
                  "code": "1f32a",
                  "name": "tornado",
                  "category": "nature",
                  "keywords": "['cloud', 'whirlwind']",
                  "display": 1,
                  "translatedName": "tornado"
                },
                {
                  "code": "1f32b",
                  "name": "fog",
                  "category": "nature",
                  "keywords": "['cloud']",
                  "display": 1,
                  "translatedName": "névoa"
                },
                {
                  "code": "1f30a",
                  "name": "water wave",
                  "category": "nature",
                  "keywords": "['ocean', 'water', 'wave']",
                  "display": 1,
                  "translatedName": "onda de água"
                },
                {
                  "code": "1f4a7",
                  "name": "droplet",
                  "category": "nature",
                  "keywords": "['cold', 'comic', 'drop', 'sweat']",
                  "display": 1,
                  "translatedName": "gotícula"
                },
                {
                  "code": "1f4a6",
                  "name": "sweat droplets",
                  "category": "nature",
                  "keywords": "['comic', 'splashing', 'sweat']",
                  "display": 1,
                  "translatedName": "gotas de suor"
                },
                {
                  "code": 2614,
                  "name": "umbrella with rain drops",
                  "category": "nature",
                  "keywords": "['clothing', 'drop', 'rain', 'umbrella']",
                  "display": 1,
                  "translatedName": "Guarda-chuva com gotas de chuva"
                },
                {
                  "code": "1f992",
                  "name": "giraffe",
                  "category": "nature",
                  "keywords": "['spots']",
                  "display": 1,
                  "translatedName": "girafa"
                },
                {
                  "code": "1f993",
                  "name": "zebra",
                  "category": "nature",
                  "keywords": "['stripe']",
                  "display": 1,
                  "translatedName": "zebra"
                },
                {
                  "code": "1f994",
                  "name": "hedgehog",
                  "category": "nature",
                  "keywords": "['spiny']",
                  "display": 1,
                  "translatedName": "ouriço"
                },
                {
                  "code": "1f995",
                  "name": "sauropod",
                  "category": "nature",
                  "keywords": "['brachiosaurus', 'brontosaurus', 'diplodocus']",
                  "display": 1,
                  "translatedName": "saurópod."
                },
                {
                  "code": "1f996",
                  "name": "T-Rex",
                  "category": "nature",
                  "keywords": "['Tyrannosaurus Rex']",
                  "display": 1,
                  "translatedName": "T-rex."
                },
                {
                  "code": "1f997",
                  "name": "cricket",
                  "category": "nature",
                  "keywords": "['']",
                  "display": 1,
                  "translatedName": "Grilo"
                },
                {
                  "code": "1f34f",
                  "name": "green apple",
                  "category": "food",
                  "keywords": "['apple', 'fruit', 'green']",
                  "display": 1,
                  "translatedName": "maçã verde"
                },
                {
                  "code": "1f34e",
                  "name": "red apple",
                  "category": "food",
                  "keywords": "['apple', 'fruit', 'red']",
                  "display": 1,
                  "translatedName": "maçã vermelha"
                },
                {
                  "code": "1f350",
                  "name": "pear",
                  "category": "food",
                  "keywords": "['fruit']",
                  "display": 1,
                  "translatedName": "pera"
                },
                {
                  "code": "1f34a",
                  "name": "tangerine",
                  "category": "food",
                  "keywords": "['fruit', 'orange']",
                  "display": 1,
                  "translatedName": "tangerina"
                },
                {
                  "code": "1f34b",
                  "name": "lemon",
                  "category": "food",
                  "keywords": "['citrus', 'fruit']",
                  "display": 1,
                  "translatedName": "limão"
                },
                {
                  "code": "1f34c",
                  "name": "banana",
                  "category": "food",
                  "keywords": "['fruit']",
                  "display": 1,
                  "translatedName": "banana"
                },
                {
                  "code": "1f349",
                  "name": "watermelon",
                  "category": "food",
                  "keywords": "['fruit']",
                  "display": 1,
                  "translatedName": "Melancia"
                },
                {
                  "code": "1f347",
                  "name": "grapes",
                  "category": "food",
                  "keywords": "['fruit', 'grape']",
                  "display": 1,
                  "translatedName": "uvas"
                },
                {
                  "code": "1f353",
                  "name": "strawberry",
                  "category": "food",
                  "keywords": "['berry', 'fruit']",
                  "display": 1,
                  "translatedName": "morango"
                },
                {
                  "code": "1f348",
                  "name": "melon",
                  "category": "food",
                  "keywords": "['fruit']",
                  "display": 1,
                  "translatedName": "Melão"
                },
                {
                  "code": "1f352",
                  "name": "cherries",
                  "category": "food",
                  "keywords": "['cherry', 'fruit']",
                  "display": 1,
                  "translatedName": "cerejas"
                },
                {
                  "code": "1f351",
                  "name": "peach",
                  "category": "food",
                  "keywords": "['fruit']",
                  "display": 1,
                  "translatedName": "pêssego"
                },
                {
                  "code": "1f34d",
                  "name": "pineapple",
                  "category": "food",
                  "keywords": "['fruit']",
                  "display": 1,
                  "translatedName": "abacaxi"
                },
                {
                  "code": "1f95d",
                  "name": "kiwi fruit",
                  "category": "food",
                  "keywords": "['food', 'fruit', 'kiwi']",
                  "display": 1,
                  "translatedName": "Kiwi Fruit."
                },
                {
                  "code": "1f951",
                  "name": "avocado",
                  "category": "food",
                  "keywords": "['avocado', 'food', 'fruit']",
                  "display": 1,
                  "translatedName": "abacate"
                },
                {
                  "code": "1f345",
                  "name": "tomato",
                  "category": "food",
                  "keywords": "['fruit', 'vegetable']",
                  "display": 1,
                  "translatedName": "tomate"
                },
                {
                  "code": "1f346",
                  "name": "eggplant",
                  "category": "food",
                  "keywords": "['aubergine', 'vegetable']",
                  "display": 1,
                  "translatedName": "beringela"
                },
                {
                  "code": "1f952",
                  "name": "cucumber",
                  "category": "food",
                  "keywords": "['cucumber', 'food', 'pickle', 'vegetable']",
                  "display": 1,
                  "translatedName": "pepino"
                },
                {
                  "code": "1f955",
                  "name": "carrot",
                  "category": "food",
                  "keywords": "['carrot', 'food', 'vegetable']",
                  "display": 1,
                  "translatedName": "cenoura"
                },
                {
                  "code": "1f33d",
                  "name": "ear of corn",
                  "category": "food",
                  "keywords": "['corn', 'ear', 'maize', 'maze']",
                  "display": 1,
                  "translatedName": "espiga de milho"
                },
                {
                  "code": "1f336",
                  "name": "hot pepper",
                  "category": "food",
                  "keywords": "['hot', 'pepper']",
                  "display": 1,
                  "translatedName": "pimenta quente"
                },
                {
                  "code": "1f954",
                  "name": "potato",
                  "category": "food",
                  "keywords": "['food', 'potato', 'vegetable']",
                  "display": 1,
                  "translatedName": "batata"
                },
                {
                  "code": "1f360",
                  "name": "roasted sweet potato",
                  "category": "food",
                  "keywords": "['potato', 'roasted', 'sweet']",
                  "display": 1,
                  "translatedName": "batata doce assada"
                },
                {
                  "code": "1f330",
                  "name": "chestnut",
                  "category": "food",
                  "keywords": "['plant']",
                  "display": 1,
                  "translatedName": "castanha"
                },
                {
                  "code": "1f95c",
                  "name": "peanuts",
                  "category": "food",
                  "keywords": "['food', 'nut', 'peanut', 'vegetable']",
                  "display": 1,
                  "translatedName": "amendoim"
                },
                {
                  "code": "1f36f",
                  "name": "honey pot",
                  "category": "food",
                  "keywords": "['honey', 'honeypot', 'pot', 'sweet']",
                  "display": 1,
                  "translatedName": "pote de mel"
                },
                {
                  "code": "1f950",
                  "name": "croissant",
                  "category": "food",
                  "keywords": "['bread', 'crescent roll', 'croissant', 'food', 'french']",
                  "display": 1,
                  "translatedName": "croissant"
                },
                {
                  "code": "1f35e",
                  "name": "bread",
                  "category": "food",
                  "keywords": "['loaf']",
                  "display": 1,
                  "translatedName": "pão"
                },
                {
                  "code": "1f956",
                  "name": "baguette bread",
                  "category": "food",
                  "keywords": "['baguette', 'bread', 'food', 'french']",
                  "display": 1,
                  "translatedName": "Pão de baguete"
                },
                {
                  "code": "1f9c0",
                  "name": "cheese wedge",
                  "category": "food",
                  "keywords": "['cheese']",
                  "display": 1,
                  "translatedName": "cunha de queijo."
                },
                {
                  "code": "1f95a",
                  "name": "egg",
                  "category": "food",
                  "keywords": "['egg', 'food']",
                  "display": 1,
                  "translatedName": "ovo"
                },
                {
                  "code": "1f373",
                  "name": "cooking",
                  "category": "food",
                  "keywords": "['egg', 'frying', 'pan']",
                  "display": 1,
                  "translatedName": "cozinhando"
                },
                {
                  "code": "1f953",
                  "name": "bacon",
                  "category": "food",
                  "keywords": "['bacon', 'food', 'meat']",
                  "display": 1,
                  "translatedName": "bacon"
                },
                {
                  "code": "1f95e",
                  "name": "pancakes",
                  "category": "food",
                  "keywords": "['crêpe', 'food', 'hotcake', 'pancake']",
                  "display": 1,
                  "translatedName": "panquecas"
                },
                {
                  "code": "1f364",
                  "name": "fried shrimp",
                  "category": "food",
                  "keywords": "['fried', 'prawn', 'shrimp', 'tempura']",
                  "display": 1,
                  "translatedName": "camarão frito"
                },
                {
                  "code": "1f357",
                  "name": "poultry leg",
                  "category": "food",
                  "keywords": "['bone', 'chicken', 'leg', 'poultry']",
                  "display": 1,
                  "translatedName": "perna de aves"
                },
                {
                  "code": "1f356",
                  "name": "meat on bone",
                  "category": "food",
                  "keywords": "['bone', 'meat']",
                  "display": 1,
                  "translatedName": "carne no osso"
                },
                {
                  "code": "1f355",
                  "name": "pizza",
                  "category": "food",
                  "keywords": "['cheese', 'slice']",
                  "display": 1,
                  "translatedName": "pizza"
                },
                {
                  "code": "1f32d",
                  "name": "hot dog",
                  "category": "food",
                  "keywords": "['frankfurter', 'hotdog', 'sausage']",
                  "display": 1,
                  "translatedName": "cachorro-quente"
                },
                {
                  "code": "1f354",
                  "name": "hamburger",
                  "category": "food",
                  "keywords": "['burger']",
                  "display": 1,
                  "translatedName": "Hamburger"
                },
                {
                  "code": "1f35f",
                  "name": "french fries",
                  "category": "food",
                  "keywords": "['french', 'fries']",
                  "display": 1,
                  "translatedName": "batatas fritas"
                },
                {
                  "code": "1f959",
                  "name": "stuffed flatbread",
                  "category": "food",
                  "keywords": "['falafel', 'flatbread', 'food', 'gyro', 'kebab', 'stuffed']",
                  "display": 1,
                  "translatedName": "flatbread recheado"
                },
                {
                  "code": "1f32e",
                  "name": "taco",
                  "category": "food",
                  "keywords": "['mexican']",
                  "display": 1,
                  "translatedName": "taco."
                },
                {
                  "code": "1f32f",
                  "name": "burrito",
                  "category": "food",
                  "keywords": "['mexican', 'wrap']",
                  "display": 1,
                  "translatedName": "burrito"
                },
                {
                  "code": "1f957",
                  "name": "green salad",
                  "category": "food",
                  "keywords": "['food', 'green', 'salad']",
                  "display": 1,
                  "translatedName": "salada verde"
                },
                {
                  "code": "1f958",
                  "name": "shallow pan of food",
                  "category": "food",
                  "keywords": "['casserole', 'food', 'paella', 'pan', 'shallow']",
                  "display": 1,
                  "translatedName": "Panela rasa de alimentos"
                },
                {
                  "code": "1f35d",
                  "name": "spaghetti",
                  "category": "food",
                  "keywords": "['pasta']",
                  "display": 1,
                  "translatedName": "espaguete"
                },
                {
                  "code": "1f35c",
                  "name": "steaming bowl",
                  "category": "food",
                  "keywords": "['bowl', 'noodle', 'ramen', 'steaming']",
                  "display": 1,
                  "translatedName": "Bacia fumegante"
                },
                {
                  "code": "1f372",
                  "name": "pot of food",
                  "category": "food",
                  "keywords": "['pot', 'stew']",
                  "display": 1,
                  "translatedName": "pote de comida"
                },
                {
                  "code": "1f365",
                  "name": "fish cake with swirl",
                  "category": "food",
                  "keywords": "['cake', 'fish', 'pastry', 'swirl']",
                  "display": 1,
                  "translatedName": "Bolo de peixe com redemoinho"
                },
                {
                  "code": "1f363",
                  "name": "sushi",
                  "category": "food",
                  "keywords": "['sushi']",
                  "display": 1,
                  "translatedName": "Sushi"
                },
                {
                  "code": "1f371",
                  "name": "bento box",
                  "category": "food",
                  "keywords": "['bento', 'box']",
                  "display": 1,
                  "translatedName": "Bento Box."
                },
                {
                  "code": "1f35b",
                  "name": "curry rice",
                  "category": "food",
                  "keywords": "['curry', 'rice']",
                  "display": 1,
                  "translatedName": "arroz de curry"
                },
                {
                  "code": "1f359",
                  "name": "rice ball",
                  "category": "food",
                  "keywords": "['Japanese', 'ball', 'rice']",
                  "display": 1,
                  "translatedName": "bola de arroz"
                },
                {
                  "code": "1f35a",
                  "name": "cooked rice",
                  "category": "food",
                  "keywords": "['cooked', 'rice']",
                  "display": 1,
                  "translatedName": "arroz cozido"
                },
                {
                  "code": "1f358",
                  "name": "rice cracker",
                  "category": "food",
                  "keywords": "['cracker', 'rice']",
                  "display": 1,
                  "translatedName": "Cracker de arroz"
                },
                {
                  "code": "1f362",
                  "name": "oden",
                  "category": "food",
                  "keywords": "['kebab', 'seafood', 'skewer', 'stick']",
                  "display": 1,
                  "translatedName": "oden."
                },
                {
                  "code": "1f361",
                  "name": "dango",
                  "category": "food",
                  "keywords": "['Japanese', 'dessert', 'skewer', 'stick', 'sweet']",
                  "display": 1,
                  "translatedName": "DANGO."
                },
                {
                  "code": "1f367",
                  "name": "shaved ice",
                  "category": "food",
                  "keywords": "['dessert', 'ice', 'shaved', 'sweet']",
                  "display": 1,
                  "translatedName": "gelo raspado"
                },
                {
                  "code": "1f368",
                  "name": "ice cream",
                  "category": "food",
                  "keywords": "['cream', 'dessert', 'ice', 'sweet']",
                  "display": 1,
                  "translatedName": "sorvete"
                },
                {
                  "code": "1f366",
                  "name": "soft ice cream",
                  "category": "food",
                  "keywords": "['cream', 'dessert', 'ice', 'icecream', 'soft', 'sweet']",
                  "display": 1,
                  "translatedName": "sorvete macio"
                },
                {
                  "code": "1f370",
                  "name": "shortcake",
                  "category": "food",
                  "keywords": "['cake', 'dessert', 'pastry', 'slice', 'sweet']",
                  "display": 1,
                  "translatedName": "Shortcake"
                },
                {
                  "code": "1f382",
                  "name": "birthday cake",
                  "category": "food",
                  "keywords": "['birthday', 'cake', 'celebration', 'dessert', 'pastry', 'sweet']",
                  "display": 1,
                  "translatedName": "bolo de aniversário"
                },
                {
                  "code": "1f36e",
                  "name": "custard",
                  "category": "food",
                  "keywords": "['dessert', 'pudding', 'sweet']",
                  "display": 1,
                  "translatedName": "Quindim"
                },
                {
                  "code": "1f36d",
                  "name": "lollipop",
                  "category": "food",
                  "keywords": "['candy', 'dessert', 'sweet']",
                  "display": 1,
                  "translatedName": "pirulito"
                },
                {
                  "code": "1f36c",
                  "name": "candy",
                  "category": "food",
                  "keywords": "['dessert', 'sweet']",
                  "display": 1,
                  "translatedName": "doce"
                },
                {
                  "code": "1f36b",
                  "name": "chocolate bar",
                  "category": "food",
                  "keywords": "['bar', 'chocolate', 'dessert', 'sweet']",
                  "display": 1,
                  "translatedName": "Barra de chocolate"
                },
                {
                  "code": "1f37f",
                  "name": "popcorn",
                  "category": "food",
                  "keywords": "['popcorn']",
                  "display": 1,
                  "translatedName": "Pipoca"
                },
                {
                  "code": "1f369",
                  "name": "doughnut",
                  "category": "food",
                  "keywords": "['dessert', 'donut', 'sweet']",
                  "display": 1,
                  "translatedName": "rosquinha"
                },
                {
                  "code": "1f36a",
                  "name": "cookie",
                  "category": "food",
                  "keywords": "['dessert', 'sweet']",
                  "display": 1,
                  "translatedName": "biscoito"
                },
                {
                  "code": "1f95b",
                  "name": "glass of milk",
                  "category": "food",
                  "keywords": "['drink', 'glass', 'milk']",
                  "display": 1,
                  "translatedName": "copo de leite"
                },
                {
                  "code": "1f37c",
                  "name": "baby bottle",
                  "category": "food",
                  "keywords": "['baby', 'bottle', 'drink', 'milk']",
                  "display": 1,
                  "translatedName": "Mamadeira"
                },
                {
                  "code": 2615,
                  "name": "hot beverage",
                  "category": "food",
                  "keywords": "['beverage', 'coffee', 'drink', 'hot', 'steaming', 'tea']",
                  "display": 1,
                  "translatedName": "bebida quente"
                },
                {
                  "code": "1f375",
                  "name": "teacup without handle",
                  "category": "food",
                  "keywords": "['beverage', 'cup', 'drink', 'tea', 'teacup']",
                  "display": 1,
                  "translatedName": "xícara de chá sem cabo"
                },
                {
                  "code": "1f376",
                  "name": "sake",
                  "category": "food",
                  "keywords": "['bar', 'beverage', 'bottle', 'cup', 'drink']",
                  "display": 1,
                  "translatedName": "interesse"
                },
                {
                  "code": "1f37a",
                  "name": "beer mug",
                  "category": "food",
                  "keywords": "['bar', 'beer', 'drink', 'mug']",
                  "display": 1,
                  "translatedName": "Caneca de cerveja"
                },
                {
                  "code": "1f37b",
                  "name": "clinking beer mugs",
                  "category": "food",
                  "keywords": "['bar', 'beer', 'clink', 'drink', 'mug']",
                  "display": 1,
                  "translatedName": "Canecas de cerveja tilinting"
                },
                {
                  "code": "1f942",
                  "name": "clinking glasses",
                  "category": "food",
                  "keywords": "['celebrate', 'clink', 'drink', 'glass']",
                  "display": 1,
                  "translatedName": "copos clinking"
                },
                {
                  "code": "1f377",
                  "name": "wine glass",
                  "category": "food",
                  "keywords": "['bar', 'beverage', 'drink', 'glass', 'wine']",
                  "display": 1,
                  "translatedName": "copo de vinho"
                },
                {
                  "code": "1f943",
                  "name": "tumbler glass",
                  "category": "food",
                  "keywords": "['glass', 'liquor', 'shot', 'tumbler', 'whisky']",
                  "display": 1,
                  "translatedName": "copo de tumbler."
                },
                {
                  "code": "1f378",
                  "name": "cocktail glass",
                  "category": "food",
                  "keywords": "['bar', 'cocktail', 'drink', 'glass']",
                  "display": 1,
                  "translatedName": "copo de copo"
                },
                {
                  "code": "1f379",
                  "name": "tropical drink",
                  "category": "food",
                  "keywords": "['bar', 'drink', 'tropical']",
                  "display": 1,
                  "translatedName": "Bebida tropical"
                },
                {
                  "code": "1f37e",
                  "name": "bottle with popping cork",
                  "category": "food",
                  "keywords": "['bar', 'bottle', 'cork', 'drink', 'popping']",
                  "display": 1,
                  "translatedName": "garrafa com estalo de cortiça"
                },
                {
                  "code": "1f944",
                  "name": "spoon",
                  "category": "food",
                  "keywords": "['spoon', 'tableware']",
                  "display": 1,
                  "translatedName": "colher"
                },
                {
                  "code": "1f374",
                  "name": "fork and knife",
                  "category": "food",
                  "keywords": "['cooking', 'fork', 'knife']",
                  "display": 1,
                  "translatedName": "garfo e faca"
                },
                {
                  "code": "1f37d",
                  "name": "fork and knife with plate",
                  "category": "food",
                  "keywords": "['cooking', 'fork', 'knife', 'plate']",
                  "display": 1,
                  "translatedName": "garfo e faca com placa"
                },
                {
                  "code": "1f95f",
                  "name": "dumpling",
                  "category": "food",
                  "keywords": "['empanada', 'gyōza', 'jiaozi', 'pierogi', 'potsticker']",
                  "display": 1,
                  "translatedName": "bolinho de massa"
                },
                {
                  "code": "1f960",
                  "name": "fortune cookie",
                  "category": "food",
                  "keywords": "['prophecy']",
                  "display": 1,
                  "translatedName": "biscoito da sorte"
                },
                {
                  "code": "1f961",
                  "name": "takeout box",
                  "category": "food",
                  "keywords": "['oyster pail']",
                  "display": 1,
                  "translatedName": "Caixa de takeout"
                },
                {
                  "code": "1f962",
                  "name": "chopsticks",
                  "category": "food",
                  "keywords": "['']",
                  "display": 1,
                  "translatedName": "pauzinhos"
                },
                {
                  "code": "1f963",
                  "name": "bowl with spoon",
                  "category": "food",
                  "keywords": "['']",
                  "display": 1,
                  "translatedName": "Tigela com colher"
                },
                {
                  "code": "1f964",
                  "name": "cup with straw",
                  "category": "food",
                  "keywords": "['']",
                  "display": 1,
                  "translatedName": "Copo com palha"
                },
                {
                  "code": "1f965",
                  "name": "coconut",
                  "category": "food",
                  "keywords": "['palm', 'piña colada']",
                  "display": 1,
                  "translatedName": "coco"
                },
                {
                  "code": "1f966",
                  "name": "broccoli",
                  "category": "food",
                  "keywords": "['wild cabbage']",
                  "display": 1,
                  "translatedName": "brócolis"
                },
                {
                  "code": "1f967",
                  "name": "pie",
                  "category": "food",
                  "keywords": "['']",
                  "display": 1,
                  "translatedName": "torta"
                },
                {
                  "code": "1f968",
                  "name": "pretzel",
                  "category": "food",
                  "keywords": "['']",
                  "display": 1,
                  "translatedName": "pretzel"
                },
                {
                  "code": "1f969",
                  "name": "cut of meat",
                  "category": "food",
                  "keywords": "['chop', 'lambchop', 'porkchop', 'steak']",
                  "display": 1,
                  "translatedName": "Corte de carne"
                },
                {
                  "code": "1f96a",
                  "name": "sandwich",
                  "category": "food",
                  "keywords": "['bread']",
                  "display": 1,
                  "translatedName": "sanduíche"
                },
                {
                  "code": "1f96b",
                  "name": "canned food",
                  "category": "food",
                  "keywords": "['can']",
                  "display": 1,
                  "translatedName": "comida enlatada"
                },
                {
                  "code": "26bd",
                  "name": "soccer ball",
                  "category": "activity",
                  "keywords": "['ball', 'football', 'soccer']",
                  "display": 1,
                  "translatedName": "bola de futebol"
                },
                {
                  "code": "1f3c0",
                  "name": "basketball",
                  "category": "activity",
                  "keywords": "['ball', 'hoop']",
                  "display": 1,
                  "translatedName": "basquetebol"
                },
                {
                  "code": "1f3c8",
                  "name": "american football",
                  "category": "activity",
                  "keywords": "['american', 'ball', 'football']",
                  "display": 1,
                  "translatedName": "futebol americano"
                },
                {
                  "code": "26be",
                  "name": "baseball",
                  "category": "activity",
                  "keywords": "['ball']",
                  "display": 1,
                  "translatedName": "baseball"
                },
                {
                  "code": "1f3be",
                  "name": "tennis",
                  "category": "activity",
                  "keywords": "['ball', 'racquet']",
                  "display": 1,
                  "translatedName": "tênis"
                },
                {
                  "code": "1f3d0",
                  "name": "volleyball",
                  "category": "activity",
                  "keywords": "['ball', 'game']",
                  "display": 1,
                  "translatedName": "voleibol"
                },
                {
                  "code": "1f3c9",
                  "name": "rugby football",
                  "category": "activity",
                  "keywords": "['ball', 'football', 'rugby']",
                  "display": 1,
                  "translatedName": "futebol de rugby."
                },
                {
                  "code": "1f3b1",
                  "name": "pool 8 ball",
                  "category": "activity",
                  "keywords": "['8', '8 ball', 'ball', 'billiard', 'eight', 'game']",
                  "display": 1,
                  "translatedName": "Pool 8 Ball."
                },
                {
                  "code": "1f3d3",
                  "name": "ping pong",
                  "category": "activity",
                  "keywords": "['ball', 'bat', 'game', 'paddle', 'ping pong', 'table tennis']",
                  "display": 1,
                  "translatedName": "pingue-pongue"
                },
                {
                  "code": "1f3f8",
                  "name": "badminton",
                  "category": "activity",
                  "keywords": "['birdie', 'game', 'racquet', 'shuttlecock']",
                  "display": 1,
                  "translatedName": "badminton"
                },
                {
                  "code": "1f945",
                  "name": "goal net",
                  "category": "activity",
                  "keywords": "['goal', 'net']",
                  "display": 1,
                  "translatedName": "Net Net."
                },
                {
                  "code": "1f3d2",
                  "name": "ice hockey",
                  "category": "activity",
                  "keywords": "['game', 'hockey', 'ice', 'puck', 'stick']",
                  "display": 1,
                  "translatedName": "Hockey no gelo"
                },
                {
                  "code": "1f3d1",
                  "name": "field hockey",
                  "category": "activity",
                  "keywords": "['ball', 'field', 'game', 'hockey', 'stick']",
                  "display": 1,
                  "translatedName": "hóquei em campo"
                },
                {
                  "code": "1f3cf",
                  "name": "cricket game",
                  "category": "activity",
                  "keywords": "['ball', 'bat', 'game']",
                  "display": 1,
                  "translatedName": "Jogo de críquete"
                },
                {
                  "code": "26f3",
                  "name": "flag in hole",
                  "category": "activity",
                  "keywords": "['golf', 'hole']",
                  "display": 1,
                  "translatedName": "Bandeira no buraco"
                },
                {
                  "code": "1f3f9",
                  "name": "bow and arrow",
                  "category": "activity",
                  "keywords": "['Sagittarius', 'archer', 'archery', 'arrow', 'bow', 'tool', 'weapon', 'zodiac']",
                  "display": 1,
                  "translatedName": "arco e flecha"
                },
                {
                  "code": "1f3a3",
                  "name": "fishing pole",
                  "category": "activity",
                  "keywords": "['fish', 'pole']",
                  "display": 1,
                  "translatedName": "Vara de pescar"
                },
                {
                  "code": "1f94a",
                  "name": "boxing glove",
                  "category": "activity",
                  "keywords": "['boxing', 'glove']",
                  "display": 1,
                  "translatedName": "luva de boxe"
                },
                {
                  "code": "1f94b",
                  "name": "martial arts uniform",
                  "category": "activity",
                  "keywords": "['judo', 'karate', 'martial arts', 'taekwondo', 'uniform']",
                  "display": 1,
                  "translatedName": "uniforme de artes marciais"
                },
                {
                  "code": "26f8",
                  "name": "ice skate",
                  "category": "activity",
                  "keywords": "['ice', 'skate']",
                  "display": 1,
                  "translatedName": "patins de gelo"
                },
                {
                  "code": "1f3bf",
                  "name": "skis",
                  "category": "activity",
                  "keywords": "['ski', 'snow']",
                  "display": 1,
                  "translatedName": "esquis."
                },
                {
                  "code": "26f7",
                  "name": "skier",
                  "category": "activity",
                  "keywords": "['ski', 'snow']",
                  "display": 1,
                  "translatedName": "esquiador"
                },
                {
                  "code": "1f3c2",
                  "name": "snowboarder",
                  "category": "activity",
                  "keywords": "['ski', 'snow', 'snowboard']",
                  "display": 1,
                  "translatedName": "snowboarder."
                },
                {
                  "code": "1f3cb-2640",
                  "name": "woman lifting weights",
                  "category": "activity",
                  "keywords": "['weight lifter', 'woman']",
                  "display": 1,
                  "translatedName": "Mulher levantando pesos"
                },
                {
                  "code": "1f3cb",
                  "name": "person lifting weights",
                  "category": "activity",
                  "keywords": "['lifter', 'weight']",
                  "display": 1,
                  "translatedName": "Pessoa levantando pesos"
                },
                {
                  "code": "1f3cb-2642",
                  "name": "man lifting weights",
                  "category": "activity",
                  "keywords": "['man', 'weight lifter']",
                  "display": 0,
                  "translatedName": "Homem levantando pesos"
                },
                {
                  "code": "1f93a",
                  "name": "person fencing",
                  "category": "activity",
                  "keywords": "['fencer', 'fencing', 'sword']",
                  "display": 1,
                  "translatedName": "Esgrima pessoa"
                },
                {
                  "code": "1f93c-2640",
                  "name": "women wrestling",
                  "category": "activity",
                  "keywords": "['women', 'wrestle']",
                  "display": 1,
                  "translatedName": "Mulheres wrestling"
                },
                {
                  "code": "1f93c-2642",
                  "name": "men wrestling",
                  "category": "activity",
                  "keywords": "['men', 'wrestle']",
                  "display": 1,
                  "translatedName": "Homens wrestling"
                },
                {
                  "code": "1f93c",
                  "name": "people wrestling",
                  "category": "activity",
                  "keywords": "['wrestle', 'wrestler']",
                  "display": 0,
                  "translatedName": "wrestling de pessoas"
                },
                {
                  "code": "1f938-2640",
                  "name": "woman cartwheeling",
                  "category": "activity",
                  "keywords": "['cartwheel', 'gymnastics', 'woman']",
                  "display": 1,
                  "translatedName": "Cartwheeling da mulher"
                },
                {
                  "code": "1f938-2642",
                  "name": "man cartwheeling",
                  "category": "activity",
                  "keywords": "['cartwheel', 'gymnastics', 'man']",
                  "display": 1,
                  "translatedName": "Cartwheeling do homem"
                },
                {
                  "code": "1f938",
                  "name": "person cartwheeling",
                  "category": "activity",
                  "keywords": "['cartwheel', 'gymnastics']",
                  "display": 0,
                  "translatedName": "Cartwheeling de pessoa"
                },
                {
                  "code": "26f9-2640",
                  "name": "woman bouncing ball",
                  "category": "activity",
                  "keywords": "['ball', 'woman']",
                  "display": 1,
                  "translatedName": "Mulher saltando bola"
                },
                {
                  "code": "26f9",
                  "name": "person bouncing ball",
                  "category": "activity",
                  "keywords": "['ball']",
                  "display": 1,
                  "translatedName": "Pessoa saltando bola"
                },
                {
                  "code": "26f9-2642",
                  "name": "man bouncing ball",
                  "category": "activity",
                  "keywords": "['ball', 'man']",
                  "display": 0,
                  "translatedName": "Homem saltando bola"
                },
                {
                  "code": "1f93e-2640",
                  "name": "woman playing handball",
                  "category": "activity",
                  "keywords": "['handball', 'woman']",
                  "display": 1,
                  "translatedName": "Mulher jogando handebol"
                },
                {
                  "code": "1f93e-2642",
                  "name": "man playing handball",
                  "category": "activity",
                  "keywords": "['handball', 'man']",
                  "display": 1,
                  "translatedName": "Homem jogando handebol"
                },
                {
                  "code": "1f93e",
                  "name": "person playing handball",
                  "category": "activity",
                  "keywords": "['ball', 'handball']",
                  "display": 0,
                  "translatedName": "Pessoa jogando handebol"
                },
                {
                  "code": "1f3cc-2640",
                  "name": "woman golfing",
                  "category": "activity",
                  "keywords": "['golf', 'woman']",
                  "display": 1,
                  "translatedName": "mulher golfe"
                },
                {
                  "code": "1f3cc",
                  "name": "person golfing",
                  "category": "activity",
                  "keywords": "['ball', 'golf']",
                  "display": 1,
                  "translatedName": "Pessoa golfe"
                },
                {
                  "code": "1f3cc-2642",
                  "name": "man golfing",
                  "category": "activity",
                  "keywords": "['golf', 'man']",
                  "display": 0,
                  "translatedName": "homem golfe"
                },
                {
                  "code": "1f3c4-2640",
                  "name": "woman surfing",
                  "category": "activity",
                  "keywords": "['surfing', 'woman']",
                  "display": 1,
                  "translatedName": "Mulher surfando"
                },
                {
                  "code": "1f3c4",
                  "name": "person surfing",
                  "category": "activity",
                  "keywords": "['surfing']",
                  "display": 1,
                  "translatedName": "Pessoa Surf"
                },
                {
                  "code": "1f3c4-2642",
                  "name": "man surfing",
                  "category": "activity",
                  "keywords": "['man', 'surfing']",
                  "display": 0,
                  "translatedName": "Homem surfando"
                },
                {
                  "code": "1f3ca-2640",
                  "name": "woman swimming",
                  "category": "activity",
                  "keywords": "['swim', 'woman']",
                  "display": 1,
                  "translatedName": "mulher nadando"
                },
                {
                  "code": "1f3ca",
                  "name": "person swimming",
                  "category": "activity",
                  "keywords": "['swim']",
                  "display": 1,
                  "translatedName": "Pessoa nadando"
                },
                {
                  "code": "1f3ca-2642",
                  "name": "man swimming",
                  "category": "activity",
                  "keywords": "['man', 'swim']",
                  "display": 0,
                  "translatedName": "Natação do homem"
                },
                {
                  "code": "1f93d-2640",
                  "name": "woman playing water polo",
                  "category": "activity",
                  "keywords": "['water polo', 'woman']",
                  "display": 1,
                  "translatedName": "Mulher, tocando água, polo"
                },
                {
                  "code": "1f93d-2642",
                  "name": "man playing water polo",
                  "category": "activity",
                  "keywords": "['man', 'water polo']",
                  "display": 1,
                  "translatedName": "homem jogando água polo"
                },
                {
                  "code": "1f93d",
                  "name": "person playing water polo",
                  "category": "activity",
                  "keywords": "['polo', 'water']",
                  "display": 0,
                  "translatedName": "Pessoa jogando água polo"
                },
                {
                  "code": "1f6a3-2640",
                  "name": "woman rowing boat",
                  "category": "activity",
                  "keywords": "['boat', 'rowboat', 'woman']",
                  "display": 1,
                  "translatedName": "Barco de remos da mulher"
                },
                {
                  "code": "1f6a3",
                  "name": "person rowing boat",
                  "category": "activity",
                  "keywords": "['boat', 'rowboat']",
                  "display": 1,
                  "translatedName": "Barco de remo da pessoa"
                },
                {
                  "code": "1f6a3-2642",
                  "name": "man rowing boat",
                  "category": "activity",
                  "keywords": "['boat', 'man', 'rowboat']",
                  "display": 0,
                  "translatedName": "Barco de remo do homem"
                },
                {
                  "code": "1f3c7",
                  "name": "horse racing",
                  "category": "activity",
                  "keywords": "['horse', 'jockey', 'racehorse', 'racing']",
                  "display": 1,
                  "translatedName": "corrida de cavalo"
                },
                {
                  "code": "1f6b4-2640",
                  "name": "woman biking",
                  "category": "activity",
                  "keywords": "['bicycle', 'biking', 'cyclist', 'woman']",
                  "display": 1,
                  "translatedName": "Biking de mulher"
                },
                {
                  "code": "1f6b4",
                  "name": "person biking",
                  "category": "activity",
                  "keywords": "['bicycle', 'biking', 'cyclist']",
                  "display": 1,
                  "translatedName": "Pessoa de bicicleta"
                },
                {
                  "code": "1f6b4-2642",
                  "name": "man biking",
                  "category": "activity",
                  "keywords": "['bicycle', 'biking', 'cyclist', 'man']",
                  "display": 0,
                  "translatedName": "homem de bicicleta"
                },
                {
                  "code": "1f6b5-2640",
                  "name": "woman mountain biking",
                  "category": "activity",
                  "keywords": "['bicycle', 'bike', 'biking', 'cyclist', 'mountain', 'woman']",
                  "display": 1,
                  "translatedName": "Bicicleta de montanha de mulher"
                },
                {
                  "code": "1f6b5",
                  "name": "person mountain biking",
                  "category": "activity",
                  "keywords": "['bicycle', 'bicyclist', 'bike', 'cyclist', 'mountain']",
                  "display": 1,
                  "translatedName": "Pessoa mountain bike."
                },
                {
                  "code": "1f6b5-2642",
                  "name": "man mountain biking",
                  "category": "activity",
                  "keywords": "['bicycle', 'bike', 'cyclist', 'man', 'mountain']",
                  "display": 0,
                  "translatedName": "Homem mountain bike."
                },
                {
                  "code": "1f3bd",
                  "name": "running shirt",
                  "category": "activity",
                  "keywords": "['athletics', 'running', 'sash', 'shirt']",
                  "display": 1,
                  "translatedName": "Camisa correndo"
                },
                {
                  "code": "1f3c5",
                  "name": "sports medal",
                  "category": "activity",
                  "keywords": "['medal']",
                  "display": 1,
                  "translatedName": "Medalha de esportes"
                },
                {
                  "code": "1f396",
                  "name": "military medal",
                  "category": "activity",
                  "keywords": "['celebration', 'medal', 'military']",
                  "display": 1,
                  "translatedName": "Medalha militar"
                },
                {
                  "code": "1f947",
                  "name": "1st place medal",
                  "category": "activity",
                  "keywords": "['first', 'gold', 'medal']",
                  "display": 1,
                  "translatedName": "1º lugar medalha."
                },
                {
                  "code": "1f948",
                  "name": "2nd place medal",
                  "category": "activity",
                  "keywords": "['medal', 'second', 'silver']",
                  "display": 1,
                  "translatedName": "2º lugar medalha."
                },
                {
                  "code": "1f949",
                  "name": "3rd place medal",
                  "category": "activity",
                  "keywords": "['bronze', 'medal', 'third']",
                  "display": 1,
                  "translatedName": "3ª Medalha de Lugar"
                },
                {
                  "code": "1f3c6",
                  "name": "trophy",
                  "category": "activity",
                  "keywords": "['prize']",
                  "display": 1,
                  "translatedName": "troféu"
                },
                {
                  "code": "1f3f5",
                  "name": "rosette",
                  "category": "activity",
                  "keywords": "['plant']",
                  "display": 1,
                  "translatedName": "roseta"
                },
                {
                  "code": "1f397",
                  "name": "reminder ribbon",
                  "category": "activity",
                  "keywords": "['celebration', 'reminder', 'ribbon']",
                  "display": 1,
                  "translatedName": "Fita de lembrete"
                },
                {
                  "code": "1f3ab",
                  "name": "ticket",
                  "category": "activity",
                  "keywords": "['admission']",
                  "display": 1,
                  "translatedName": "bilhete"
                },
                {
                  "code": "1f39f",
                  "name": "admission tickets",
                  "category": "activity",
                  "keywords": "['admission', 'ticket']",
                  "display": 1,
                  "translatedName": "ingressos de admissão"
                },
                {
                  "code": "1f3aa",
                  "name": "circus tent",
                  "category": "activity",
                  "keywords": "['circus', 'tent']",
                  "display": 1,
                  "translatedName": "tenda de circo"
                },
                {
                  "code": "1f939-2640",
                  "name": "woman juggling",
                  "category": "activity",
                  "keywords": "['juggling', 'multitask', 'woman']",
                  "display": 1,
                  "translatedName": "Mulher malabarismo"
                },
                {
                  "code": "1f939-2642",
                  "name": "man juggling",
                  "category": "activity",
                  "keywords": "['juggling', 'man', 'multitask']",
                  "display": 1,
                  "translatedName": "Homem malabarismo"
                },
                {
                  "code": "1f939",
                  "name": "person juggling",
                  "category": "activity",
                  "keywords": "['balance', 'juggle', 'multitask', 'skill']",
                  "display": 0,
                  "translatedName": "Pessoa, malabarismo"
                },
                {
                  "code": "1f3ad",
                  "name": "performing arts",
                  "category": "activity",
                  "keywords": "['art', 'mask', 'performing', 'theater', 'theatre']",
                  "display": 1,
                  "translatedName": "Artes performáticas"
                },
                {
                  "code": "1f3a8",
                  "name": "artist palette",
                  "category": "activity",
                  "keywords": "['art', 'museum', 'painting', 'palette']",
                  "display": 1,
                  "translatedName": "Paleta do artista"
                },
                {
                  "code": "1f3ac",
                  "name": "clapper board",
                  "category": "activity",
                  "keywords": "['clapper', 'movie']",
                  "display": 1,
                  "translatedName": "Clapper Board"
                },
                {
                  "code": "1f3a4",
                  "name": "microphone",
                  "category": "activity",
                  "keywords": "['karaoke', 'mic']",
                  "display": 1,
                  "translatedName": "microfone"
                },
                {
                  "code": "1f3a7",
                  "name": "headphone",
                  "category": "activity",
                  "keywords": "['earbud']",
                  "display": 1,
                  "translatedName": "fone de ouvido"
                },
                {
                  "code": "1f3bc",
                  "name": "musical score",
                  "category": "activity",
                  "keywords": "['music', 'score']",
                  "display": 1,
                  "translatedName": "pontuação musical"
                },
                {
                  "code": "1f3b9",
                  "name": "musical keyboard",
                  "category": "activity",
                  "keywords": "['instrument', 'keyboard', 'music', 'piano']",
                  "display": 1,
                  "translatedName": "Teclado musical"
                },
                {
                  "code": "1f941",
                  "name": "drum",
                  "category": "activity",
                  "keywords": "['drum', 'drumsticks', 'music']",
                  "display": 1,
                  "translatedName": "tambor"
                },
                {
                  "code": "1f3b7",
                  "name": "saxophone",
                  "category": "activity",
                  "keywords": "['instrument', 'music', 'sax']",
                  "display": 1,
                  "translatedName": "saxofone"
                },
                {
                  "code": "1f3ba",
                  "name": "trumpet",
                  "category": "activity",
                  "keywords": "['instrument', 'music']",
                  "display": 1,
                  "translatedName": "trompete"
                },
                {
                  "code": "1f3b8",
                  "name": "guitar",
                  "category": "activity",
                  "keywords": "['instrument', 'music']",
                  "display": 1,
                  "translatedName": "violão"
                },
                {
                  "code": "1f3bb",
                  "name": "violin",
                  "category": "activity",
                  "keywords": "['instrument', 'music']",
                  "display": 1,
                  "translatedName": "violino"
                },
                {
                  "code": "1f3b2",
                  "name": "game die",
                  "category": "activity",
                  "keywords": "['dice', 'die', 'game']",
                  "display": 1,
                  "translatedName": "jogo morrer"
                },
                {
                  "code": "1f3af",
                  "name": "direct hit",
                  "category": "activity",
                  "keywords": "['bull', 'bullseye', 'dart', 'eye', 'game', 'hit', 'target']",
                  "display": 1,
                  "translatedName": "ataque direto"
                },
                {
                  "code": "1f3b3",
                  "name": "bowling",
                  "category": "activity",
                  "keywords": "['ball', 'game']",
                  "display": 1,
                  "translatedName": "boliche"
                },
                {
                  "code": "1f3ae",
                  "name": "video game",
                  "category": "activity",
                  "keywords": "['controller', 'game']",
                  "display": 1,
                  "translatedName": "videogame"
                },
                {
                  "code": "1f3b0",
                  "name": "slot machine",
                  "category": "activity",
                  "keywords": "['game', 'slot']",
                  "display": 1,
                  "translatedName": "Slot Machine."
                },
                {
                  "code": "1f6f7",
                  "name": "sled",
                  "category": "activity",
                  "keywords": "['']",
                  "display": 1,
                  "translatedName": "trenó"
                },
                {
                  "code": "1f931",
                  "name": "breast-feeding",
                  "category": "activity",
                  "keywords": "['baby', 'breast', 'nursing']",
                  "display": 1,
                  "translatedName": "amamentação"
                },
                {
                  "code": "1f94c",
                  "name": "curling stone",
                  "category": "activity",
                  "keywords": "['game', 'rock']",
                  "display": 1,
                  "translatedName": "pedra curling"
                },
                {
                  "code": "1f9d6",
                  "name": "person in steamy room",
                  "category": "activity",
                  "keywords": "['']",
                  "display": 0,
                  "translatedName": "Pessoa na sala fumegante"
                },
                {
                  "code": "1f9d7",
                  "name": "person climbing",
                  "category": "activity",
                  "keywords": "['climber']",
                  "display": 0,
                  "translatedName": "Escalada de pessoa"
                },
                {
                  "code": "1f9d8",
                  "name": "person in lotus position",
                  "category": "activity",
                  "keywords": "['']",
                  "display": 0,
                  "translatedName": "Pessoa na posição de lótus"
                },
                {
                  "code": "1f9d6-2640",
                  "name": "woman in steamy room",
                  "category": "activity",
                  "keywords": "['sauna', 'steam room']",
                  "display": 1,
                  "translatedName": "Mulher, em, sala fumegante"
                },
                {
                  "code": "1f9d6-2642",
                  "name": "man in steamy room",
                  "category": "activity",
                  "keywords": "['sauna', 'steam room']",
                  "display": 1,
                  "translatedName": "Homem, em, sala fumegante"
                },
                {
                  "code": "1f9d7-2640",
                  "name": "woman climbing",
                  "category": "activity",
                  "keywords": "['climber']",
                  "display": 1,
                  "translatedName": "escalada de mulher"
                },
                {
                  "code": "1f9d7-2642",
                  "name": "man climbing",
                  "category": "activity",
                  "keywords": "['climber']",
                  "display": 1,
                  "translatedName": "homem escalando"
                },
                {
                  "code": "1f9d8-2640",
                  "name": "woman in lotus position",
                  "category": "activity",
                  "keywords": "['meditation', 'yoga']",
                  "display": 1,
                  "translatedName": "Mulher na posição de lótus"
                },
                {
                  "code": "1f9d8-2642",
                  "name": "man in lotus position",
                  "category": "activity",
                  "keywords": "['meditation', 'yoga']",
                  "display": 1,
                  "translatedName": "Homem na posição de lótus"
                },
                {
                  "code": "1f697",
                  "name": "automobile",
                  "category": "travel",
                  "keywords": "['car']",
                  "display": 1,
                  "translatedName": "automóvel"
                },
                {
                  "code": "1f695",
                  "name": "taxi",
                  "category": "travel",
                  "keywords": "['vehicle']",
                  "display": 1,
                  "translatedName": "Táxi"
                },
                {
                  "code": "1f699",
                  "name": "sport utility vehicle",
                  "category": "travel",
                  "keywords": "['recreational', 'sport utility']",
                  "display": 1,
                  "translatedName": "veículo utilitário esportivo"
                },
                {
                  "code": "1f68c",
                  "name": "bus",
                  "category": "travel",
                  "keywords": "['vehicle']",
                  "display": 1,
                  "translatedName": "ônibus"
                },
                {
                  "code": "1f68e",
                  "name": "trolleybus",
                  "category": "travel",
                  "keywords": "['bus', 'tram', 'trolley']",
                  "display": 1,
                  "translatedName": "Trólebus."
                },
                {
                  "code": "1f3ce",
                  "name": "racing car",
                  "category": "travel",
                  "keywords": "['car', 'racing']",
                  "display": 1,
                  "translatedName": "carro de corrida"
                },
                {
                  "code": "1f693",
                  "name": "police car",
                  "category": "travel",
                  "keywords": "['car', 'patrol', 'police']",
                  "display": 1,
                  "translatedName": "carro de polícia"
                },
                {
                  "code": "1f691",
                  "name": "ambulance",
                  "category": "travel",
                  "keywords": "['vehicle']",
                  "display": 1,
                  "translatedName": "ambulância"
                },
                {
                  "code": "1f692",
                  "name": "fire engine",
                  "category": "travel",
                  "keywords": "['engine', 'fire', 'truck']",
                  "display": 1,
                  "translatedName": "Engine de bombeiros"
                },
                {
                  "code": "1f690",
                  "name": "minibus",
                  "category": "travel",
                  "keywords": "['bus']",
                  "display": 1,
                  "translatedName": "mini onibus"
                },
                {
                  "code": "1f69a",
                  "name": "delivery truck",
                  "category": "travel",
                  "keywords": "['delivery', 'truck']",
                  "display": 1,
                  "translatedName": "Caminhão de entrega"
                },
                {
                  "code": "1f69b",
                  "name": "articulated lorry",
                  "category": "travel",
                  "keywords": "['lorry', 'semi', 'truck']",
                  "display": 1,
                  "translatedName": "camião articulado"
                },
                {
                  "code": "1f69c",
                  "name": "tractor",
                  "category": "travel",
                  "keywords": "['vehicle']",
                  "display": 1,
                  "translatedName": "trator"
                },
                {
                  "code": "1f6f4",
                  "name": "kick scooter",
                  "category": "travel",
                  "keywords": "['kick', 'scooter']",
                  "display": 1,
                  "translatedName": "chute scooter."
                },
                {
                  "code": "1f6b2",
                  "name": "bicycle",
                  "category": "travel",
                  "keywords": "['bike']",
                  "display": 1,
                  "translatedName": "bicicleta"
                },
                {
                  "code": "1f6f5",
                  "name": "motor scooter",
                  "category": "travel",
                  "keywords": "['motor', 'scooter']",
                  "display": 1,
                  "translatedName": "motoneta"
                },
                {
                  "code": "1f3cd",
                  "name": "motorcycle",
                  "category": "travel",
                  "keywords": "['racing']",
                  "display": 1,
                  "translatedName": "motocicleta"
                },
                {
                  "code": "1f6a8",
                  "name": "police car light",
                  "category": "travel",
                  "keywords": "['beacon', 'car', 'light', 'police', 'revolving']",
                  "display": 1,
                  "translatedName": "luz do carro da polícia"
                },
                {
                  "code": "1f694",
                  "name": "oncoming police car",
                  "category": "travel",
                  "keywords": "['car', 'oncoming', 'police']",
                  "display": 1,
                  "translatedName": "carro policial que se aproxima"
                },
                {
                  "code": "1f68d",
                  "name": "oncoming bus",
                  "category": "travel",
                  "keywords": "['bus', 'oncoming']",
                  "display": 1,
                  "translatedName": "ônibus que se aproxima"
                },
                {
                  "code": "1f698",
                  "name": "oncoming automobile",
                  "category": "travel",
                  "keywords": "['automobile', 'car', 'oncoming']",
                  "display": 1,
                  "translatedName": "Automóvel que se aproxima"
                },
                {
                  "code": "1f696",
                  "name": "oncoming taxi",
                  "category": "travel",
                  "keywords": "['oncoming', 'taxi']",
                  "display": 1,
                  "translatedName": "Táxi que se aproxima"
                },
                {
                  "code": "1f6a1",
                  "name": "aerial tramway",
                  "category": "travel",
                  "keywords": "['aerial', 'cable', 'car', 'gondola', 'tramway']",
                  "display": 1,
                  "translatedName": "Tramway aéreo."
                },
                {
                  "code": "1f6a0",
                  "name": "mountain cableway",
                  "category": "travel",
                  "keywords": "['cable', 'gondola', 'mountain']",
                  "display": 1,
                  "translatedName": "Cabo aéreo da montanha"
                },
                {
                  "code": "1f69f",
                  "name": "suspension railway",
                  "category": "travel",
                  "keywords": "['railway', 'suspension']",
                  "display": 1,
                  "translatedName": "Ferrovia de suspensão"
                },
                {
                  "code": "1f683",
                  "name": "railway car",
                  "category": "travel",
                  "keywords": "['car', 'electric', 'railway', 'train', 'tram', 'trolleybus']",
                  "display": 1,
                  "translatedName": "carro ferroviário"
                },
                {
                  "code": "1f68b",
                  "name": "tram car",
                  "category": "travel",
                  "keywords": "['car', 'tram', 'trolleybus']",
                  "display": 1,
                  "translatedName": "carro de bonde"
                },
                {
                  "code": "1f69e",
                  "name": "mountain railway",
                  "category": "travel",
                  "keywords": "['car', 'mountain', 'railway']",
                  "display": 1,
                  "translatedName": "Estrada de ferro da montanha"
                },
                {
                  "code": "1f69d",
                  "name": "monorail",
                  "category": "travel",
                  "keywords": "['vehicle']",
                  "display": 1,
                  "translatedName": "monotrilho"
                },
                {
                  "code": "1f684",
                  "name": "high-speed train",
                  "category": "travel",
                  "keywords": "['railway', 'shinkansen', 'speed', 'train']",
                  "display": 1,
                  "translatedName": "trem de alta velocidade"
                },
                {
                  "code": "1f685",
                  "name": "high-speed train with bullet nose",
                  "category": "travel",
                  "keywords": "['bullet', 'railway', 'shinkansen', 'speed', 'train']",
                  "display": 1,
                  "translatedName": "Trem de alta velocidade com nariz de bala"
                },
                {
                  "code": "1f688",
                  "name": "light rail",
                  "category": "travel",
                  "keywords": "['railway']",
                  "display": 1,
                  "translatedName": "Veiculo Leve Sobre Trilhos"
                },
                {
                  "code": "1f682",
                  "name": "locomotive",
                  "category": "travel",
                  "keywords": "['engine', 'railway', 'steam', 'train']",
                  "display": 1,
                  "translatedName": "locomotiva"
                },
                {
                  "code": "1f686",
                  "name": "train",
                  "category": "travel",
                  "keywords": "['railway']",
                  "display": 1,
                  "translatedName": "Comboio"
                },
                {
                  "code": "1f687",
                  "name": "metro",
                  "category": "travel",
                  "keywords": "['subway']",
                  "display": 1,
                  "translatedName": "metrô"
                },
                {
                  "code": "1f68a",
                  "name": "tram",
                  "category": "travel",
                  "keywords": "['trolleybus']",
                  "display": 1,
                  "translatedName": "eléctrico"
                },
                {
                  "code": "1f689",
                  "name": "station",
                  "category": "travel",
                  "keywords": "['railway', 'train']",
                  "display": 1,
                  "translatedName": "estação"
                },
                {
                  "code": "1f681",
                  "name": "helicopter",
                  "category": "travel",
                  "keywords": "['vehicle']",
                  "display": 1,
                  "translatedName": "helicóptero"
                },
                {
                  "code": "1f6e9",
                  "name": "small airplane",
                  "category": "travel",
                  "keywords": "['aeroplane', 'airplane']",
                  "display": 1,
                  "translatedName": "Pequeno avião"
                },
                {
                  "code": 2708,
                  "name": "airplane",
                  "category": "travel",
                  "keywords": "['aeroplane', 'airplane']",
                  "display": 1,
                  "translatedName": "avião"
                },
                {
                  "code": "1f6eb",
                  "name": "airplane departure",
                  "category": "travel",
                  "keywords": "['aeroplane', 'airplane', 'check-in', 'departure', 'departures']",
                  "display": 1,
                  "translatedName": "Partida do avião."
                },
                {
                  "code": "1f6ec",
                  "name": "airplane arrival",
                  "category": "travel",
                  "keywords": "['aeroplane', 'airplane', 'arrivals', 'arriving', 'landing']",
                  "display": 1,
                  "translatedName": "Avião de chegada"
                },
                {
                  "code": "1f680",
                  "name": "rocket",
                  "category": "travel",
                  "keywords": "['space']",
                  "display": 1,
                  "translatedName": "foguete"
                },
                {
                  "code": "1f6f0",
                  "name": "satellite",
                  "category": "travel",
                  "keywords": "['space']",
                  "display": 1,
                  "translatedName": "satélite"
                },
                {
                  "code": "1f4ba",
                  "name": "seat",
                  "category": "travel",
                  "keywords": "['chair']",
                  "display": 1,
                  "translatedName": "assento"
                },
                {
                  "code": "1f6f6",
                  "name": "canoe",
                  "category": "travel",
                  "keywords": "['boat', 'canoe']",
                  "display": 1,
                  "translatedName": "canoa"
                },
                {
                  "code": "26f5",
                  "name": "sailboat",
                  "category": "travel",
                  "keywords": "['boat', 'resort', 'sea', 'yacht']",
                  "display": 1,
                  "translatedName": "barco a vela"
                },
                {
                  "code": "1f6e5",
                  "name": "motor boat",
                  "category": "travel",
                  "keywords": "['boat', 'motorboat']",
                  "display": 1,
                  "translatedName": "barco a motor"
                },
                {
                  "code": "1f6a4",
                  "name": "speedboat",
                  "category": "travel",
                  "keywords": "['boat']",
                  "display": 1,
                  "translatedName": "lancha"
                },
                {
                  "code": "1f6f3",
                  "name": "passenger ship",
                  "category": "travel",
                  "keywords": "['passenger', 'ship']",
                  "display": 1,
                  "translatedName": "navio de passageiros"
                },
                {
                  "code": "26f4",
                  "name": "ferry",
                  "category": "travel",
                  "keywords": "['boat', 'passenger']",
                  "display": 1,
                  "translatedName": "balsa"
                },
                {
                  "code": "1f6a2",
                  "name": "ship",
                  "category": "travel",
                  "keywords": "['boat', 'passenger']",
                  "display": 1,
                  "translatedName": "enviar"
                },
                {
                  "code": 2693,
                  "name": "anchor",
                  "category": "travel",
                  "keywords": "['ship', 'tool']",
                  "display": 1,
                  "translatedName": "âncora"
                },
                {
                  "code": "1f6a7",
                  "name": "construction",
                  "category": "travel",
                  "keywords": "['barrier']",
                  "display": 1,
                  "translatedName": "construção"
                },
                {
                  "code": "26fd",
                  "name": "fuel pump",
                  "category": "travel",
                  "keywords": "['fuel', 'fuelpump', 'gas', 'pump', 'station']",
                  "display": 1,
                  "translatedName": "bomba de combustivel"
                },
                {
                  "code": "1f68f",
                  "name": "bus stop",
                  "category": "travel",
                  "keywords": "['bus', 'busstop', 'stop']",
                  "display": 1,
                  "translatedName": "ponto de ônibus"
                },
                {
                  "code": "1f6a6",
                  "name": "vertical traffic light",
                  "category": "travel",
                  "keywords": "['light', 'signal', 'traffic']",
                  "display": 1,
                  "translatedName": "semáforo vertical"
                },
                {
                  "code": "1f6a5",
                  "name": "horizontal traffic light",
                  "category": "travel",
                  "keywords": "['light', 'signal', 'traffic']",
                  "display": 1,
                  "translatedName": "semáforo horizontal"
                },
                {
                  "code": "1f5fa",
                  "name": "world map",
                  "category": "travel",
                  "keywords": "['map', 'world']",
                  "display": 1,
                  "translatedName": "mapa mundial"
                },
                {
                  "code": "1f5ff",
                  "name": "moai",
                  "category": "travel",
                  "keywords": "['face', 'moyai', 'statue']",
                  "display": 1,
                  "translatedName": "moai."
                },
                {
                  "code": "1f5fd",
                  "name": "Statue of Liberty",
                  "category": "travel",
                  "keywords": "['liberty', 'statue']",
                  "display": 1,
                  "translatedName": "Estátua da Liberdade"
                },
                {
                  "code": "26f2",
                  "name": "fountain",
                  "category": "travel",
                  "keywords": "['fountain']",
                  "display": 1,
                  "translatedName": "fonte"
                },
                {
                  "code": "1f5fc",
                  "name": "Tokyo tower",
                  "category": "travel",
                  "keywords": "['Tokyo', 'tower']",
                  "display": 1,
                  "translatedName": "Torre de Tóquio"
                },
                {
                  "code": "1f3f0",
                  "name": "castle",
                  "category": "travel",
                  "keywords": "['European']",
                  "display": 1,
                  "translatedName": "castelo"
                },
                {
                  "code": "1f3ef",
                  "name": "Japanese castle",
                  "category": "travel",
                  "keywords": "['Japanese', 'castle']",
                  "display": 1,
                  "translatedName": "Castelo japonês"
                },
                {
                  "code": "1f3df",
                  "name": "stadium",
                  "category": "travel",
                  "keywords": "['stadium']",
                  "display": 1,
                  "translatedName": "estádio"
                },
                {
                  "code": "1f3a1",
                  "name": "ferris wheel",
                  "category": "travel",
                  "keywords": "['amusement park', 'ferris', 'wheel']",
                  "display": 1,
                  "translatedName": "roda gigante"
                },
                {
                  "code": "1f3a2",
                  "name": "roller coaster",
                  "category": "travel",
                  "keywords": "['amusement park', 'coaster', 'roller']",
                  "display": 1,
                  "translatedName": "montanha russa"
                },
                {
                  "code": "1f3a0",
                  "name": "carousel horse",
                  "category": "travel",
                  "keywords": "['carousel', 'horse']",
                  "display": 1,
                  "translatedName": "cavalo carrossel."
                },
                {
                  "code": "26f1",
                  "name": "umbrella on ground",
                  "category": "travel",
                  "keywords": "['rain', 'sun', 'umbrella']",
                  "display": 1,
                  "translatedName": "guarda-chuva no chão"
                },
                {
                  "code": "1f3d6",
                  "name": "beach with umbrella",
                  "category": "travel",
                  "keywords": "['beach', 'umbrella']",
                  "display": 1,
                  "translatedName": "Praia com guarda-chuva"
                },
                {
                  "code": "1f3dd",
                  "name": "desert island",
                  "category": "travel",
                  "keywords": "['desert', 'island']",
                  "display": 1,
                  "translatedName": "ilha deserta"
                },
                {
                  "code": "26f0",
                  "name": "mountain",
                  "category": "travel",
                  "keywords": "['mountain']",
                  "display": 1,
                  "translatedName": "montanha"
                },
                {
                  "code": "1f3d4",
                  "name": "snow-capped mountain",
                  "category": "travel",
                  "keywords": "['cold', 'mountain', 'snow']",
                  "display": 1,
                  "translatedName": "montanha coberta de neve"
                },
                {
                  "code": "1f5fb",
                  "name": "mount fuji",
                  "category": "travel",
                  "keywords": "['fuji', 'mountain']",
                  "display": 1,
                  "translatedName": "Monte Fuji."
                },
                {
                  "code": "1f30b",
                  "name": "volcano",
                  "category": "travel",
                  "keywords": "['eruption', 'mountain']",
                  "display": 1,
                  "translatedName": "vulcão"
                },
                {
                  "code": "1f3dc",
                  "name": "desert",
                  "category": "travel",
                  "keywords": "['desert']",
                  "display": 1,
                  "translatedName": "deserto"
                },
                {
                  "code": "1f3d5",
                  "name": "camping",
                  "category": "travel",
                  "keywords": "['camping']",
                  "display": 1,
                  "translatedName": "acampamento"
                },
                {
                  "code": "26fa",
                  "name": "tent",
                  "category": "travel",
                  "keywords": "['camping']",
                  "display": 1,
                  "translatedName": "tenda"
                },
                {
                  "code": "1f6e4",
                  "name": "railway track",
                  "category": "travel",
                  "keywords": "['railway', 'train']",
                  "display": 1,
                  "translatedName": "Trilha Railway"
                },
                {
                  "code": "1f6e3",
                  "name": "motorway",
                  "category": "travel",
                  "keywords": "['highway', 'road']",
                  "display": 1,
                  "translatedName": "auto-estrada"
                },
                {
                  "code": "1f3d7",
                  "name": "building construction",
                  "category": "travel",
                  "keywords": "['construction']",
                  "display": 1,
                  "translatedName": "construção civil"
                },
                {
                  "code": "1f3ed",
                  "name": "factory",
                  "category": "travel",
                  "keywords": "['building']",
                  "display": 1,
                  "translatedName": "fábrica"
                },
                {
                  "code": "1f3e0",
                  "name": "house",
                  "category": "travel",
                  "keywords": "['home', 'house']",
                  "display": 1,
                  "translatedName": "casa"
                },
                {
                  "code": "1f3e1",
                  "name": "house with garden",
                  "category": "travel",
                  "keywords": "['garden', 'home', 'house']",
                  "display": 1,
                  "translatedName": "Casa com jardim"
                },
                {
                  "code": "1f3d8",
                  "name": "houses",
                  "category": "travel",
                  "keywords": "['houses']",
                  "display": 1,
                  "translatedName": "Casas"
                },
                {
                  "code": "1f3da",
                  "name": "derelict house",
                  "category": "travel",
                  "keywords": "['derelict', 'house']",
                  "display": 1,
                  "translatedName": "casa abandonada"
                },
                {
                  "code": "1f3e2",
                  "name": "office building",
                  "category": "travel",
                  "keywords": "['building']",
                  "display": 1,
                  "translatedName": "Prédio comercial"
                },
                {
                  "code": "1f3ec",
                  "name": "department store",
                  "category": "travel",
                  "keywords": "['department', 'store']",
                  "display": 1,
                  "translatedName": "loja de departamento"
                },
                {
                  "code": "1f3e3",
                  "name": "Japanese post office",
                  "category": "travel",
                  "keywords": "['Japanese', 'post']",
                  "display": 1,
                  "translatedName": "Correios japoneses."
                },
                {
                  "code": "1f3e4",
                  "name": "post office",
                  "category": "travel",
                  "keywords": "['European', 'post']",
                  "display": 1,
                  "translatedName": "Correios"
                },
                {
                  "code": "1f3e5",
                  "name": "hospital",
                  "category": "travel",
                  "keywords": "['doctor', 'medicine']",
                  "display": 1,
                  "translatedName": "hospital"
                },
                {
                  "code": "1f3e6",
                  "name": "bank",
                  "category": "travel",
                  "keywords": "['building']",
                  "display": 1,
                  "translatedName": "Banco"
                },
                {
                  "code": "1f3e8",
                  "name": "hotel",
                  "category": "travel",
                  "keywords": "['building']",
                  "display": 1,
                  "translatedName": "hotel"
                },
                {
                  "code": "1f3ea",
                  "name": "convenience store",
                  "category": "travel",
                  "keywords": "['convenience', 'store']",
                  "display": 1,
                  "translatedName": "loja de conveniência"
                },
                {
                  "code": "1f3eb",
                  "name": "school",
                  "category": "travel",
                  "keywords": "['building']",
                  "display": 1,
                  "translatedName": "escola"
                },
                {
                  "code": "1f3e9",
                  "name": "love hotel",
                  "category": "travel",
                  "keywords": "['hotel', 'love']",
                  "display": 1,
                  "translatedName": "Amor Hotel."
                },
                {
                  "code": "1f492",
                  "name": "wedding",
                  "category": "travel",
                  "keywords": "['chapel', 'romance']",
                  "display": 1,
                  "translatedName": "Casamento"
                },
                {
                  "code": "1f3db",
                  "name": "classical building",
                  "category": "travel",
                  "keywords": "['classical']",
                  "display": 1,
                  "translatedName": "edifício clássico"
                },
                {
                  "code": "26ea",
                  "name": "church",
                  "category": "travel",
                  "keywords": "['Christian', 'cross', 'religion']",
                  "display": 1,
                  "translatedName": "Igreja"
                },
                {
                  "code": "1f54c",
                  "name": "mosque",
                  "category": "travel",
                  "keywords": "['Muslim', 'islam', 'religion']",
                  "display": 1,
                  "translatedName": "mesquita"
                },
                {
                  "code": "1f54d",
                  "name": "synagogue",
                  "category": "travel",
                  "keywords": "['Jew', 'Jewish', 'religion', 'temple']",
                  "display": 1,
                  "translatedName": "sinagoga"
                },
                {
                  "code": "1f54b",
                  "name": "kaaba",
                  "category": "travel",
                  "keywords": "['Muslim', 'islam', 'religion']",
                  "display": 1,
                  "translatedName": "kaaba."
                },
                {
                  "code": "2,60E+10",
                  "name": "shinto shrine",
                  "category": "travel",
                  "keywords": "['religion', 'shinto', 'shrine']",
                  "display": 1,
                  "translatedName": "santuário de Xintoísmo"
                },
                {
                  "code": "1f5fe",
                  "name": "map of Japan",
                  "category": "travel",
                  "keywords": "['Japan', 'map']",
                  "display": 1,
                  "translatedName": "Mapa do Japão"
                },
                {
                  "code": "1f391",
                  "name": "moon viewing ceremony",
                  "category": "travel",
                  "keywords": "['celebration', 'ceremony', 'moon']",
                  "display": 1,
                  "translatedName": "Cerimônia de visualização da lua"
                },
                {
                  "code": "1f3de",
                  "name": "national park",
                  "category": "travel",
                  "keywords": "['park']",
                  "display": 1,
                  "translatedName": "Parque Nacional"
                },
                {
                  "code": "1f305",
                  "name": "sunrise",
                  "category": "travel",
                  "keywords": "['morning', 'sun']",
                  "display": 1,
                  "translatedName": "nascer do sol"
                },
                {
                  "code": "1f304",
                  "name": "sunrise over mountains",
                  "category": "travel",
                  "keywords": "['morning', 'mountain', 'sun', 'sunrise']",
                  "display": 1,
                  "translatedName": "Nascer do sol sobre montanhas"
                },
                {
                  "code": "1f320",
                  "name": "shooting star",
                  "category": "travel",
                  "keywords": "['falling', 'shooting', 'star']",
                  "display": 1,
                  "translatedName": "estrela cadente"
                },
                {
                  "code": "1f387",
                  "name": "sparkler",
                  "category": "travel",
                  "keywords": "['celebration', 'fireworks', 'sparkle']",
                  "display": 1,
                  "translatedName": "Sparkler."
                },
                {
                  "code": "1f386",
                  "name": "fireworks",
                  "category": "travel",
                  "keywords": "['celebration']",
                  "display": 1,
                  "translatedName": "fogos de artifício"
                },
                {
                  "code": "1f307",
                  "name": "sunset",
                  "category": "travel",
                  "keywords": "['dusk', 'sun']",
                  "display": 1,
                  "translatedName": "pôr do sol"
                },
                {
                  "code": "1f306",
                  "name": "cityscape at dusk",
                  "category": "travel",
                  "keywords": "['city', 'dusk', 'evening', 'landscape', 'sun', 'sunset']",
                  "display": 1,
                  "translatedName": "Arquitetura da cidade no crepúsculo"
                },
                {
                  "code": "1f3d9",
                  "name": "cityscape",
                  "category": "travel",
                  "keywords": "['city']",
                  "display": 1,
                  "translatedName": "Arquitetura da cidade"
                },
                {
                  "code": "1f303",
                  "name": "night with stars",
                  "category": "travel",
                  "keywords": "['night', 'star']",
                  "display": 1,
                  "translatedName": "noite com estrelas"
                },
                {
                  "code": "1f30c",
                  "name": "milky way",
                  "category": "travel",
                  "keywords": "['space']",
                  "display": 1,
                  "translatedName": "via Láctea"
                },
                {
                  "code": "1f309",
                  "name": "bridge at night",
                  "category": "travel",
                  "keywords": "['bridge', 'night']",
                  "display": 1,
                  "translatedName": "Ponte à noite"
                },
                {
                  "code": "1f301",
                  "name": "foggy",
                  "category": "travel",
                  "keywords": "['fog']",
                  "display": 1,
                  "translatedName": "enevoado"
                },
                {
                  "code": "1f6f8",
                  "name": "flying saucer",
                  "category": "travel",
                  "keywords": "['UFO']",
                  "display": 1,
                  "translatedName": "disco voador"
                },
                {
                  "code": "231a",
                  "name": "watch",
                  "category": "objects",
                  "keywords": "['clock']",
                  "display": 1,
                  "translatedName": "Assistir"
                },
                {
                  "code": "1f4f1",
                  "name": "mobile phone",
                  "category": "objects",
                  "keywords": "['cell', 'mobile', 'phone', 'telephone']",
                  "display": 1,
                  "translatedName": "celular"
                },
                {
                  "code": "1f4f2",
                  "name": "mobile phone with arrow",
                  "category": "objects",
                  "keywords": "['arrow', 'call', 'cell', 'mobile', 'phone', 'receive', 'telephone']",
                  "display": 1,
                  "translatedName": "Telefone celular com seta"
                },
                {
                  "code": "1f4bb",
                  "name": "laptop computer",
                  "category": "objects",
                  "keywords": "['computer', 'pc', 'personal']",
                  "display": 1,
                  "translatedName": "notebook"
                },
                {
                  "code": 2328,
                  "name": "keyboard",
                  "category": "objects",
                  "keywords": "['computer']",
                  "display": 1,
                  "translatedName": "teclado"
                },
                {
                  "code": "1f5a5",
                  "name": "desktop computer",
                  "category": "objects",
                  "keywords": "['computer', 'desktop']",
                  "display": 1,
                  "translatedName": "computador desktop"
                },
                {
                  "code": "1f5a8",
                  "name": "printer",
                  "category": "objects",
                  "keywords": "['computer']",
                  "display": 1,
                  "translatedName": "impressora"
                },
                {
                  "code": "1f5b1",
                  "name": "computer mouse",
                  "category": "objects",
                  "keywords": "['computer']",
                  "display": 1,
                  "translatedName": "Mouse de computador"
                },
                {
                  "code": "1f5b2",
                  "name": "trackball",
                  "category": "objects",
                  "keywords": "['computer']",
                  "display": 1,
                  "translatedName": "trackball."
                },
                {
                  "code": "1f579",
                  "name": "joystick",
                  "category": "objects",
                  "keywords": "['game', 'video game']",
                  "display": 1,
                  "translatedName": "controle de video game"
                },
                {
                  "code": "1f5dc",
                  "name": "clamp",
                  "category": "objects",
                  "keywords": "['compress', 'tool', 'vice']",
                  "display": 1,
                  "translatedName": "braçadeira"
                },
                {
                  "code": "1f4bd",
                  "name": "computer disk",
                  "category": "objects",
                  "keywords": "['computer', 'disk', 'minidisk', 'optical']",
                  "display": 1,
                  "translatedName": "disco de computador"
                },
                {
                  "code": "1f4be",
                  "name": "floppy disk",
                  "category": "objects",
                  "keywords": "['computer', 'disk', 'floppy']",
                  "display": 1,
                  "translatedName": "disquete"
                },
                {
                  "code": "1f4bf",
                  "name": "optical disk",
                  "category": "objects",
                  "keywords": "['cd', 'computer', 'disk', 'optical']",
                  "display": 1,
                  "translatedName": "disco óptico"
                },
                {
                  "code": "1f4c0",
                  "name": "dvd",
                  "category": "objects",
                  "keywords": "['blu-ray', 'computer', 'disk', 'dvd', 'optical']",
                  "display": 1,
                  "translatedName": "DVD"
                },
                {
                  "code": "1f4fc",
                  "name": "videocassette",
                  "category": "objects",
                  "keywords": "['tape', 'vhs', 'video']",
                  "display": 1,
                  "translatedName": "videocassete"
                },
                {
                  "code": "1f4f7",
                  "name": "camera",
                  "category": "objects",
                  "keywords": "['video']",
                  "display": 1,
                  "translatedName": "Câmera"
                },
                {
                  "code": "1f4f8",
                  "name": "camera with flash",
                  "category": "objects",
                  "keywords": "['camera', 'flash', 'video']",
                  "display": 1,
                  "translatedName": "câmera com flash"
                },
                {
                  "code": "1f4f9",
                  "name": "video camera",
                  "category": "objects",
                  "keywords": "['camera', 'video']",
                  "display": 1,
                  "translatedName": "câmera de vídeo"
                },
                {
                  "code": "1f3a5",
                  "name": "movie camera",
                  "category": "objects",
                  "keywords": "['camera', 'cinema', 'movie']",
                  "display": 1,
                  "translatedName": "câmera cinematográfica"
                },
                {
                  "code": "1f4fd",
                  "name": "film projector",
                  "category": "objects",
                  "keywords": "['cinema', 'film', 'movie', 'projector', 'video']",
                  "display": 1,
                  "translatedName": "projetor de filme."
                },
                {
                  "code": "1f39e",
                  "name": "film frames",
                  "category": "objects",
                  "keywords": "['cinema', 'film', 'frames', 'movie']",
                  "display": 1,
                  "translatedName": "quadros de filme"
                },
                {
                  "code": "1f4de",
                  "name": "telephone receiver",
                  "category": "objects",
                  "keywords": "['phone', 'receiver', 'telephone']",
                  "display": 1,
                  "translatedName": "receptor de telefone"
                },
                {
                  "code": "260e",
                  "name": "telephone",
                  "category": "objects",
                  "keywords": "['phone']",
                  "display": 1,
                  "translatedName": "Telefone"
                },
                {
                  "code": "1f4df",
                  "name": "pager",
                  "category": "objects",
                  "keywords": "['pager']",
                  "display": 1,
                  "translatedName": "pager."
                },
                {
                  "code": "1f4e0",
                  "name": "fax machine",
                  "category": "objects",
                  "keywords": "['fax']",
                  "display": 1,
                  "translatedName": "maquina de fax"
                },
                {
                  "code": "1f4fa",
                  "name": "television",
                  "category": "objects",
                  "keywords": "['tv', 'video']",
                  "display": 1,
                  "translatedName": "televisão"
                },
                {
                  "code": "1f4fb",
                  "name": "radio",
                  "category": "objects",
                  "keywords": "['video']",
                  "display": 1,
                  "translatedName": "rádio"
                },
                {
                  "code": "1f399",
                  "name": "studio microphone",
                  "category": "objects",
                  "keywords": "['mic', 'microphone', 'music', 'studio']",
                  "display": 1,
                  "translatedName": "Microfone de estúdio"
                },
                {
                  "code": "1f39a",
                  "name": "level slider",
                  "category": "objects",
                  "keywords": "['level', 'music', 'slider']",
                  "display": 1,
                  "translatedName": "Slider de nível"
                },
                {
                  "code": "1f39b",
                  "name": "control knobs",
                  "category": "objects",
                  "keywords": "['control', 'knobs', 'music']",
                  "display": 1,
                  "translatedName": "Botões de controle"
                },
                {
                  "code": "23f1",
                  "name": "stopwatch",
                  "category": "objects",
                  "keywords": "['clock']",
                  "display": 1,
                  "translatedName": "cronômetro"
                },
                {
                  "code": "23f2",
                  "name": "timer clock",
                  "category": "objects",
                  "keywords": "['clock', 'timer']",
                  "display": 1,
                  "translatedName": "relógio temporizador"
                },
                {
                  "code": "23f0",
                  "name": "alarm clock",
                  "category": "objects",
                  "keywords": "['alarm', 'clock']",
                  "display": 1,
                  "translatedName": "despertador"
                },
                {
                  "code": "1f570",
                  "name": "mantelpiece clock",
                  "category": "objects",
                  "keywords": "['clock']",
                  "display": 1,
                  "translatedName": "Relógio de mantelpiece"
                },
                {
                  "code": "231b",
                  "name": "hourglass",
                  "category": "objects",
                  "keywords": "['sand', 'timer']",
                  "display": 1,
                  "translatedName": "ampulheta"
                },
                {
                  "code": "23f3",
                  "name": "hourglass with flowing sand",
                  "category": "objects",
                  "keywords": "['hourglass', 'sand', 'timer']",
                  "display": 1,
                  "translatedName": "Ampulheta com areia fluindo"
                },
                {
                  "code": "1f4e1",
                  "name": "satellite antenna",
                  "category": "objects",
                  "keywords": "['antenna', 'dish', 'satellite']",
                  "display": 1,
                  "translatedName": "Antena de satélite"
                },
                {
                  "code": "1f50b",
                  "name": "battery",
                  "category": "objects",
                  "keywords": "['battery']",
                  "display": 1,
                  "translatedName": "bateria"
                },
                {
                  "code": "1f50c",
                  "name": "electric plug",
                  "category": "objects",
                  "keywords": "['electric', 'electricity', 'plug']",
                  "display": 1,
                  "translatedName": "Plugue elétrico"
                },
                {
                  "code": "1f4a1",
                  "name": "light bulb",
                  "category": "objects",
                  "keywords": "['bulb', 'comic', 'electric', 'idea', 'light']",
                  "display": 1,
                  "translatedName": "lâmpada elétrica"
                },
                {
                  "code": "1f526",
                  "name": "flashlight",
                  "category": "objects",
                  "keywords": "['electric', 'light', 'tool', 'torch']",
                  "display": 1,
                  "translatedName": "lanterna"
                },
                {
                  "code": "1f56f",
                  "name": "candle",
                  "category": "objects",
                  "keywords": "['light']",
                  "display": 1,
                  "translatedName": "vela"
                },
                {
                  "code": "1f5d1",
                  "name": "wastebasket",
                  "category": "objects",
                  "keywords": "['wastebasket']",
                  "display": 1,
                  "translatedName": "lixebasket."
                },
                {
                  "code": "1f6e2",
                  "name": "oil drum",
                  "category": "objects",
                  "keywords": "['drum', 'oil']",
                  "display": 1,
                  "translatedName": "tambor de óleo"
                },
                {
                  "code": "1f4b8",
                  "name": "money with wings",
                  "category": "objects",
                  "keywords": "['bank', 'banknote', 'bill', 'dollar', 'fly', 'money', 'note', 'wings']",
                  "display": 1,
                  "translatedName": "Dinheiro com asas"
                },
                {
                  "code": "1f4b5",
                  "name": "dollar banknote",
                  "category": "objects",
                  "keywords": "['bank', 'banknote', 'bill', 'currency', 'dollar', 'money', 'note']",
                  "display": 1,
                  "translatedName": "Nota de dólar"
                },
                {
                  "code": "1f4b4",
                  "name": "yen banknote",
                  "category": "objects",
                  "keywords": "['bank', 'banknote', 'bill', 'currency', 'money', 'note', 'yen']",
                  "display": 1,
                  "translatedName": "notas de iene"
                },
                {
                  "code": "1f4b6",
                  "name": "euro banknote",
                  "category": "objects",
                  "keywords": "['bank', 'banknote', 'bill', 'currency', 'euro', 'money', 'note']",
                  "display": 1,
                  "translatedName": "notas de euro"
                },
                {
                  "code": "1f4b7",
                  "name": "pound banknote",
                  "category": "objects",
                  "keywords": "['bank', 'banknote', 'bill', 'currency', 'money', 'note', 'pound']",
                  "display": 1,
                  "translatedName": "Cédula de libra"
                },
                {
                  "code": "1f4b0",
                  "name": "money bag",
                  "category": "objects",
                  "keywords": "['bag', 'dollar', 'money', 'moneybag']",
                  "display": 1,
                  "translatedName": "Bolsa de dinheiro"
                },
                {
                  "code": "1f4b3",
                  "name": "credit card",
                  "category": "objects",
                  "keywords": "['bank', 'card', 'credit', 'money']",
                  "display": 1,
                  "translatedName": "Cartão de crédito"
                },
                {
                  "code": "1f48e",
                  "name": "gem stone",
                  "category": "objects",
                  "keywords": "['diamond', 'gem', 'jewel']",
                  "display": 1,
                  "translatedName": "pedra de gema"
                },
                {
                  "code": 2696,
                  "name": "balance scale",
                  "category": "objects",
                  "keywords": "['Libra', 'balance', 'justice', 'scales', 'tool', 'weight', 'zodiac']",
                  "display": 1,
                  "translatedName": "escala de equilíbrio."
                },
                {
                  "code": "1f527",
                  "name": "wrench",
                  "category": "objects",
                  "keywords": "['spanner', 'tool', 'wrench']",
                  "display": 1,
                  "translatedName": "Chave"
                },
                {
                  "code": "1f528",
                  "name": "hammer",
                  "category": "objects",
                  "keywords": "['tool']",
                  "display": 1,
                  "translatedName": "martelo"
                },
                {
                  "code": 2692,
                  "name": "hammer and pick",
                  "category": "objects",
                  "keywords": "['hammer', 'pick', 'tool']",
                  "display": 1,
                  "translatedName": "martelo e pick."
                },
                {
                  "code": "1f6e0",
                  "name": "hammer and wrench",
                  "category": "objects",
                  "keywords": "['hammer', 'spanner', 'tool', 'wrench']",
                  "display": 1,
                  "translatedName": "martelo e chave"
                },
                {
                  "code": "26cf",
                  "name": "pick",
                  "category": "objects",
                  "keywords": "['mining', 'tool']",
                  "display": 1,
                  "translatedName": "escolha"
                },
                {
                  "code": "1f529",
                  "name": "nut and bolt",
                  "category": "objects",
                  "keywords": "['bolt', 'nut', 'tool']",
                  "display": 1,
                  "translatedName": "porca e parafuso"
                },
                {
                  "code": 2699,
                  "name": "gear",
                  "category": "objects",
                  "keywords": "['tool']",
                  "display": 1,
                  "translatedName": "engrenagem"
                },
                {
                  "code": "26d3",
                  "name": "chains",
                  "category": "objects",
                  "keywords": "['chain']",
                  "display": 1,
                  "translatedName": "correntes"
                },
                {
                  "code": "1f52b",
                  "name": "pistol",
                  "category": "objects",
                  "keywords": "['gun', 'handgun', 'revolver', 'tool', 'weapon']",
                  "display": 1,
                  "translatedName": "pistola"
                },
                {
                  "code": "1f4a3",
                  "name": "bomb",
                  "category": "objects",
                  "keywords": "['comic']",
                  "display": 1,
                  "translatedName": "bombear"
                },
                {
                  "code": "1f52a",
                  "name": "kitchen knife",
                  "category": "objects",
                  "keywords": "['cooking', 'hocho', 'knife', 'tool', 'weapon']",
                  "display": 1,
                  "translatedName": "faca de cozinha"
                },
                {
                  "code": "1f5e1",
                  "name": "dagger",
                  "category": "objects",
                  "keywords": "['knife', 'weapon']",
                  "display": 1,
                  "translatedName": "punhal"
                },
                {
                  "code": 2694,
                  "name": "crossed swords",
                  "category": "objects",
                  "keywords": "['crossed', 'swords', 'weapon']",
                  "display": 1,
                  "translatedName": "Espadas cruzadas"
                },
                {
                  "code": "1f6e1",
                  "name": "shield",
                  "category": "objects",
                  "keywords": "['weapon']",
                  "display": 1,
                  "translatedName": "escudo"
                },
                {
                  "code": "1f6ac",
                  "name": "cigarette",
                  "category": "objects",
                  "keywords": "['smoking']",
                  "display": 1,
                  "translatedName": "cigarro"
                },
                {
                  "code": "26b0",
                  "name": "coffin",
                  "category": "objects",
                  "keywords": "['death']",
                  "display": 1,
                  "translatedName": "caixão"
                },
                {
                  "code": "26b1",
                  "name": "funeral urn",
                  "category": "objects",
                  "keywords": "['ashes', 'death', 'funeral', 'urn']",
                  "display": 1,
                  "translatedName": "urna funerária"
                },
                {
                  "code": "1f3fa",
                  "name": "amphora",
                  "category": "objects",
                  "keywords": "['Aquarius', 'cooking', 'drink', 'jug', 'tool', 'weapon', 'zodiac']",
                  "display": 1,
                  "translatedName": "ânfora"
                },
                {
                  "code": "1f52e",
                  "name": "crystal ball",
                  "category": "objects",
                  "keywords": "['ball', 'crystal', 'fairy tale', 'fantasy', 'fortune', 'tool']",
                  "display": 1,
                  "translatedName": "bola de cristal"
                },
                {
                  "code": "1f4ff",
                  "name": "prayer beads",
                  "category": "objects",
                  "keywords": "['beads', 'clothing', 'necklace', 'prayer', 'religion']",
                  "display": 1,
                  "translatedName": "grânulos de oração"
                },
                {
                  "code": "1f488",
                  "name": "barber pole",
                  "category": "objects",
                  "keywords": "['barber', 'haircut', 'pole']",
                  "display": 1,
                  "translatedName": "Barber Pole"
                },
                {
                  "code": 2697,
                  "name": "alembic",
                  "category": "objects",
                  "keywords": "['chemistry', 'tool']",
                  "display": 1,
                  "translatedName": "alambique"
                },
                {
                  "code": "1f52d",
                  "name": "telescope",
                  "category": "objects",
                  "keywords": "['science', 'tool']",
                  "display": 1,
                  "translatedName": "telescópio"
                },
                {
                  "code": "1f52c",
                  "name": "microscope",
                  "category": "objects",
                  "keywords": "['science', 'tool']",
                  "display": 1,
                  "translatedName": "microscópio"
                },
                {
                  "code": "1f573",
                  "name": "hole",
                  "category": "objects",
                  "keywords": "['hole']",
                  "display": 1,
                  "translatedName": "buraco"
                },
                {
                  "code": "1f48a",
                  "name": "pill",
                  "category": "objects",
                  "keywords": "['doctor', 'medicine', 'sick']",
                  "display": 1,
                  "translatedName": "comprimido"
                },
                {
                  "code": "1f489",
                  "name": "syringe",
                  "category": "objects",
                  "keywords": "['doctor', 'medicine', 'needle', 'shot', 'sick', 'tool']",
                  "display": 1,
                  "translatedName": "seringa"
                },
                {
                  "code": "1f321",
                  "name": "thermometer",
                  "category": "objects",
                  "keywords": "['weather']",
                  "display": 1,
                  "translatedName": "termômetro"
                },
                {
                  "code": "1f6bd",
                  "name": "toilet",
                  "category": "objects",
                  "keywords": "['toilet']",
                  "display": 1,
                  "translatedName": "banheiro"
                },
                {
                  "code": "1f6b0",
                  "name": "potable water",
                  "category": "objects",
                  "keywords": "['drinking', 'potable', 'water']",
                  "display": 1,
                  "translatedName": "água potável"
                },
                {
                  "code": "1f6bf",
                  "name": "shower",
                  "category": "objects",
                  "keywords": "['water']",
                  "display": 1,
                  "translatedName": "banho"
                },
                {
                  "code": "1f6c1",
                  "name": "bathtub",
                  "category": "objects",
                  "keywords": "['bath']",
                  "display": 1,
                  "translatedName": "banheira"
                },
                {
                  "code": "1f6c0",
                  "name": "person taking bath",
                  "category": "objects",
                  "keywords": "['bath', 'bathtub']",
                  "display": 1,
                  "translatedName": "Pessoa tomando banho"
                },
                {
                  "code": "1f6ce",
                  "name": "bellhop bell",
                  "category": "objects",
                  "keywords": "['bell', 'bellhop', 'hotel']",
                  "display": 1,
                  "translatedName": "Bellhop Bell."
                },
                {
                  "code": "1f511",
                  "name": "key",
                  "category": "objects",
                  "keywords": "['lock', 'password']",
                  "display": 1,
                  "translatedName": "chave"
                },
                {
                  "code": "1f5dd",
                  "name": "old key",
                  "category": "objects",
                  "keywords": "['clue', 'key', 'lock', 'old']",
                  "display": 1,
                  "translatedName": "chave velha"
                },
                {
                  "code": "1f6aa",
                  "name": "door",
                  "category": "objects",
                  "keywords": "['door']",
                  "display": 1,
                  "translatedName": "porta"
                },
                {
                  "code": "1f6cb",
                  "name": "couch and lamp",
                  "category": "objects",
                  "keywords": "['couch', 'hotel', 'lamp']",
                  "display": 1,
                  "translatedName": "sofá e lâmpada."
                },
                {
                  "code": "1f6cf",
                  "name": "bed",
                  "category": "objects",
                  "keywords": "['hotel', 'sleep']",
                  "display": 1,
                  "translatedName": "cama"
                },
                {
                  "code": "1f6cc",
                  "name": "person in bed",
                  "category": "objects",
                  "keywords": "['hotel', 'sleep']",
                  "display": 1,
                  "translatedName": "pessoa na cama."
                },
                {
                  "code": "1f5bc",
                  "name": "framed picture",
                  "category": "objects",
                  "keywords": "['art', 'frame', 'museum', 'painting', 'picture']",
                  "display": 1,
                  "translatedName": "Imagem emoldurada"
                },
                {
                  "code": "1f6cd",
                  "name": "shopping bags",
                  "category": "objects",
                  "keywords": "['bag', 'hotel', 'shopping']",
                  "display": 1,
                  "translatedName": "sacolas de compras"
                },
                {
                  "code": "1f6d2",
                  "name": "shopping cart",
                  "category": "objects",
                  "keywords": "['cart', 'shopping', 'trolley']",
                  "display": 1,
                  "translatedName": "carrinho de compras"
                },
                {
                  "code": "1f381",
                  "name": "wrapped gift",
                  "category": "objects",
                  "keywords": "['box', 'celebration', 'gift', 'present', 'wrapped']",
                  "display": 1,
                  "translatedName": "presente embrulhado"
                },
                {
                  "code": "1f388",
                  "name": "balloon",
                  "category": "objects",
                  "keywords": "['celebration']",
                  "display": 1,
                  "translatedName": "balão"
                },
                {
                  "code": "1f38f",
                  "name": "carp streamer",
                  "category": "objects",
                  "keywords": "['carp', 'celebration', 'streamer']",
                  "display": 1,
                  "translatedName": "flâmula de carpa."
                },
                {
                  "code": "1f380",
                  "name": "ribbon",
                  "category": "objects",
                  "keywords": "['celebration']",
                  "display": 1,
                  "translatedName": "fita"
                },
                {
                  "code": "1f38a",
                  "name": "confetti ball",
                  "category": "objects",
                  "keywords": "['ball', 'celebration', 'confetti']",
                  "display": 1,
                  "translatedName": "bola de confete."
                },
                {
                  "code": "1f389",
                  "name": "party popper",
                  "category": "objects",
                  "keywords": "['celebration', 'party', 'popper', 'tada']",
                  "display": 1,
                  "translatedName": "Popper de festa"
                },
                {
                  "code": "1f38e",
                  "name": "Japanese dolls",
                  "category": "objects",
                  "keywords": "['Japanese', 'celebration', 'doll', 'festival']",
                  "display": 1,
                  "translatedName": "Bonecas japonesas"
                },
                {
                  "code": "1f3ee",
                  "name": "red paper lantern",
                  "category": "objects",
                  "keywords": "['bar', 'lantern', 'light', 'red']",
                  "display": 1,
                  "translatedName": "Lanterna de papel vermelho"
                },
                {
                  "code": "1f390",
                  "name": "wind chime",
                  "category": "objects",
                  "keywords": "['bell', 'celebration', 'chime', 'wind']",
                  "display": 1,
                  "translatedName": "carrilhão de vento"
                },
                {
                  "code": 2709,
                  "name": "envelope",
                  "category": "objects",
                  "keywords": "['email', 'letter']",
                  "display": 1,
                  "translatedName": "envelope"
                },
                {
                  "code": "1f4e9",
                  "name": "envelope with arrow",
                  "category": "objects",
                  "keywords": "['arrow', 'down', 'e-mail', 'email', 'envelope', 'letter', 'mail', 'outgoing', 'sent']",
                  "display": 1,
                  "translatedName": "envelope com seta"
                },
                {
                  "code": "1f4e8",
                  "name": "incoming envelope",
                  "category": "objects",
                  "keywords": "['e-mail', 'email', 'envelope', 'incoming', 'letter', 'mail', 'receive']",
                  "display": 1,
                  "translatedName": "Envelope recebido"
                },
                {
                  "code": "1f4e7",
                  "name": "e-mail",
                  "category": "objects",
                  "keywords": "['email', 'letter', 'mail']",
                  "display": 1,
                  "translatedName": "o email"
                },
                {
                  "code": "1f48c",
                  "name": "love letter",
                  "category": "objects",
                  "keywords": "['heart', 'letter', 'love', 'mail']",
                  "display": 1,
                  "translatedName": "carta de amor"
                },
                {
                  "code": "1f4e5",
                  "name": "inbox tray",
                  "category": "objects",
                  "keywords": "['box', 'inbox', 'letter', 'mail', 'receive', 'tray']",
                  "display": 1,
                  "translatedName": "Bandeja de caixa de entrada"
                },
                {
                  "code": "1f4e4",
                  "name": "outbox tray",
                  "category": "objects",
                  "keywords": "['box', 'letter', 'mail', 'outbox', 'sent', 'tray']",
                  "display": 1,
                  "translatedName": "Bandeja Outbox."
                },
                {
                  "code": "1f4e6",
                  "name": "package",
                  "category": "objects",
                  "keywords": "['box', 'parcel']",
                  "display": 1,
                  "translatedName": "pacote"
                },
                {
                  "code": "1f3f7",
                  "name": "label",
                  "category": "objects",
                  "keywords": "['label']",
                  "display": 1,
                  "translatedName": "rótulo"
                },
                {
                  "code": "1f4ea",
                  "name": "closed mailbox with lowered flag",
                  "category": "objects",
                  "keywords": "['closed', 'lowered', 'mail', 'mailbox', 'postbox']",
                  "display": 1,
                  "translatedName": "caixa de correio fechada com bandeira abaixada"
                },
                {
                  "code": "1f4eb",
                  "name": "closed mailbox with raised flag",
                  "category": "objects",
                  "keywords": "['closed', 'mail', 'mailbox', 'postbox']",
                  "display": 1,
                  "translatedName": "caixa de correio fechada com bandeira levantada"
                },
                {
                  "code": "1f4ec",
                  "name": "open mailbox with raised flag",
                  "category": "objects",
                  "keywords": "['mail', 'mailbox', 'open', 'postbox']",
                  "display": 1,
                  "translatedName": "Abra a caixa de correio com bandeira levantada"
                },
                {
                  "code": "1f4ed",
                  "name": "open mailbox with lowered flag",
                  "category": "objects",
                  "keywords": "['lowered', 'mail', 'mailbox', 'open', 'postbox']",
                  "display": 1,
                  "translatedName": "Abra a caixa de correio com bandeira abaixada"
                },
                {
                  "code": "1f4ee",
                  "name": "postbox",
                  "category": "objects",
                  "keywords": "['mail', 'mailbox']",
                  "display": 1,
                  "translatedName": "caixa postal"
                },
                {
                  "code": "1f4ef",
                  "name": "postal horn",
                  "category": "objects",
                  "keywords": "['horn', 'post', 'postal']",
                  "display": 1,
                  "translatedName": "chifre postal"
                },
                {
                  "code": "1f4dc",
                  "name": "scroll",
                  "category": "objects",
                  "keywords": "['paper']",
                  "display": 1,
                  "translatedName": "rolagem"
                },
                {
                  "code": "1f4c3",
                  "name": "page with curl",
                  "category": "objects",
                  "keywords": "['curl', 'document', 'page']",
                  "display": 1,
                  "translatedName": "página com curl."
                },
                {
                  "code": "1f4c4",
                  "name": "page facing up",
                  "category": "objects",
                  "keywords": "['document', 'page']",
                  "display": 1,
                  "translatedName": "página voltada para cima"
                },
                {
                  "code": "1f4d1",
                  "name": "bookmark tabs",
                  "category": "objects",
                  "keywords": "['bookmark', 'mark', 'marker', 'tabs']",
                  "display": 1,
                  "translatedName": "guias de marcadores"
                },
                {
                  "code": "1f4ca",
                  "name": "bar chart",
                  "category": "objects",
                  "keywords": "['bar', 'chart', 'graph']",
                  "display": 1,
                  "translatedName": "gráfico de barras"
                },
                {
                  "code": "1f4c8",
                  "name": "chart increasing",
                  "category": "objects",
                  "keywords": "['chart', 'graph', 'growth', 'trend', 'upward']",
                  "display": 1,
                  "translatedName": "Gráfico aumentando"
                },
                {
                  "code": "1f4c9",
                  "name": "chart decreasing",
                  "category": "objects",
                  "keywords": "['chart', 'down', 'graph', 'trend']",
                  "display": 1,
                  "translatedName": "Gráfico decrescente"
                },
                {
                  "code": "1f5d2",
                  "name": "spiral notepad",
                  "category": "objects",
                  "keywords": "['note', 'pad', 'spiral']",
                  "display": 1,
                  "translatedName": "bloco de notas espiral."
                },
                {
                  "code": "1f5d3",
                  "name": "spiral calendar",
                  "category": "objects",
                  "keywords": "['calendar', 'pad', 'spiral']",
                  "display": 1,
                  "translatedName": "calendário espiral."
                },
                {
                  "code": "1f4c6",
                  "name": "tear-off calendar",
                  "category": "objects",
                  "keywords": "['calendar']",
                  "display": 1,
                  "translatedName": "Calendário de rasgo"
                },
                {
                  "code": "1f4c5",
                  "name": "calendar",
                  "category": "objects",
                  "keywords": "['date']",
                  "display": 1,
                  "translatedName": "calendário"
                },
                {
                  "code": "1f4c7",
                  "name": "card index",
                  "category": "objects",
                  "keywords": "['card', 'index', 'rolodex']",
                  "display": 1,
                  "translatedName": "fichário"
                },
                {
                  "code": "1f5c3",
                  "name": "card file box",
                  "category": "objects",
                  "keywords": "['box', 'card', 'file']",
                  "display": 1,
                  "translatedName": "Caixa de arquivo de cartão"
                },
                {
                  "code": "1f5f3",
                  "name": "ballot box with ballot",
                  "category": "objects",
                  "keywords": "['ballot', 'box']",
                  "display": 1,
                  "translatedName": "cédula com cédula"
                },
                {
                  "code": "1f5c4",
                  "name": "file cabinet",
                  "category": "objects",
                  "keywords": "['cabinet', 'file', 'filing']",
                  "display": 1,
                  "translatedName": "Armário de arquivo"
                },
                {
                  "code": "1f4cb",
                  "name": "clipboard",
                  "category": "objects",
                  "keywords": "['clipboard']",
                  "display": 1,
                  "translatedName": "prancheta"
                },
                {
                  "code": "1f4c1",
                  "name": "file folder",
                  "category": "objects",
                  "keywords": "['file', 'folder']",
                  "display": 1,
                  "translatedName": "pasta de arquivo"
                },
                {
                  "code": "1f4c2",
                  "name": "open file folder",
                  "category": "objects",
                  "keywords": "['file', 'folder', 'open']",
                  "display": 1,
                  "translatedName": "Abra a pasta de arquivos"
                },
                {
                  "code": "1f5c2",
                  "name": "card index dividers",
                  "category": "objects",
                  "keywords": "['card', 'dividers', 'index']",
                  "display": 1,
                  "translatedName": "Divisores de índice de cartão"
                },
                {
                  "code": "1f5de",
                  "name": "rolled-up newspaper",
                  "category": "objects",
                  "keywords": "['news', 'newspaper', 'paper', 'rolled']",
                  "display": 1,
                  "translatedName": "jornal enrolado"
                },
                {
                  "code": "1f4f0",
                  "name": "newspaper",
                  "category": "objects",
                  "keywords": "['news', 'paper']",
                  "display": 1,
                  "translatedName": "jornal"
                },
                {
                  "code": "1f4d3",
                  "name": "notebook",
                  "category": "objects",
                  "keywords": "['notebook']",
                  "display": 1,
                  "translatedName": "caderno"
                },
                {
                  "code": "1f4d4",
                  "name": "notebook with decorative cover",
                  "category": "objects",
                  "keywords": "['book', 'cover', 'decorated', 'notebook']",
                  "display": 1,
                  "translatedName": "Notebook com cobertura decorativa"
                },
                {
                  "code": "1f4d2",
                  "name": "ledger",
                  "category": "objects",
                  "keywords": "['notebook']",
                  "display": 1,
                  "translatedName": "Ledger."
                },
                {
                  "code": "1f4d5",
                  "name": "closed book",
                  "category": "objects",
                  "keywords": "['book', 'closed']",
                  "display": 1,
                  "translatedName": "livro fechado"
                },
                {
                  "code": "1f4d7",
                  "name": "green book",
                  "category": "objects",
                  "keywords": "['book', 'green']",
                  "display": 1,
                  "translatedName": "Livro Verde"
                },
                {
                  "code": "1f4d8",
                  "name": "blue book",
                  "category": "objects",
                  "keywords": "['blue', 'book']",
                  "display": 1,
                  "translatedName": "livro Azul"
                },
                {
                  "code": "1f4d9",
                  "name": "orange book",
                  "category": "objects",
                  "keywords": "['book', 'orange']",
                  "display": 1,
                  "translatedName": "Livro laranja"
                },
                {
                  "code": "1f4da",
                  "name": "books",
                  "category": "objects",
                  "keywords": "['book']",
                  "display": 1,
                  "translatedName": "livros"
                },
                {
                  "code": "1f4d6",
                  "name": "open book",
                  "category": "objects",
                  "keywords": "['book', 'open']",
                  "display": 1,
                  "translatedName": "abra o livro"
                },
                {
                  "code": "1f516",
                  "name": "bookmark",
                  "category": "objects",
                  "keywords": "['mark']",
                  "display": 1,
                  "translatedName": "marca páginas"
                },
                {
                  "code": "1f517",
                  "name": "link",
                  "category": "objects",
                  "keywords": "['link']",
                  "display": 1,
                  "translatedName": "ligação"
                },
                {
                  "code": "1f4ce",
                  "name": "paperclip",
                  "category": "objects",
                  "keywords": "['paperclip']",
                  "display": 1,
                  "translatedName": "clipe de papel"
                },
                {
                  "code": "1f587",
                  "name": "linked paperclips",
                  "category": "objects",
                  "keywords": "['link', 'paperclip']",
                  "display": 1,
                  "translatedName": "Paperclips vinculados"
                },
                {
                  "code": "1f4d0",
                  "name": "triangular ruler",
                  "category": "objects",
                  "keywords": "['ruler', 'set', 'triangle']",
                  "display": 1,
                  "translatedName": "régua triangular"
                },
                {
                  "code": "1f4cf",
                  "name": "straight ruler",
                  "category": "objects",
                  "keywords": "['ruler', 'straight edge']",
                  "display": 1,
                  "translatedName": "governante reto"
                },
                {
                  "code": "1f4cc",
                  "name": "pushpin",
                  "category": "objects",
                  "keywords": "['pin']",
                  "display": 1,
                  "translatedName": "pushpin"
                },
                {
                  "code": "1f4cd",
                  "name": "round pushpin",
                  "category": "objects",
                  "keywords": "['pin', 'pushpin']",
                  "display": 1,
                  "translatedName": "Pushpin redondo"
                },
                {
                  "code": 2702,
                  "name": "scissors",
                  "category": "objects",
                  "keywords": "['cutting', 'tool']",
                  "display": 1,
                  "translatedName": "tesoura"
                },
                {
                  "code": "1f58a",
                  "name": "pen",
                  "category": "objects",
                  "keywords": "['ballpoint']",
                  "display": 1,
                  "translatedName": "caneta"
                },
                {
                  "code": "1f58b",
                  "name": "fountain pen",
                  "category": "objects",
                  "keywords": "['fountain', 'pen']",
                  "display": 1,
                  "translatedName": "caneta tinteiro"
                },
                {
                  "code": 2712,
                  "name": "black nib",
                  "category": "objects",
                  "keywords": "['nib', 'pen']",
                  "display": 1,
                  "translatedName": "nib negra"
                },
                {
                  "code": "1f58c",
                  "name": "paintbrush",
                  "category": "objects",
                  "keywords": "['painting']",
                  "display": 1,
                  "translatedName": "pincel"
                },
                {
                  "code": "1f58d",
                  "name": "crayon",
                  "category": "objects",
                  "keywords": "['crayon']",
                  "display": 1,
                  "translatedName": "GIS de cera"
                },
                {
                  "code": "1f4dd",
                  "name": "memo",
                  "category": "objects",
                  "keywords": "['pencil']",
                  "display": 1,
                  "translatedName": "memorando"
                },
                {
                  "code": "270f",
                  "name": "pencil",
                  "category": "objects",
                  "keywords": "['pencil']",
                  "display": 1,
                  "translatedName": "lápis"
                },
                {
                  "code": "1f50d",
                  "name": "left-pointing magnifying glass",
                  "category": "objects",
                  "keywords": "['glass', 'magnifying', 'search', 'tool']",
                  "display": 1,
                  "translatedName": "Lupa apontadora esquerda"
                },
                {
                  "code": "1f50e",
                  "name": "right-pointing magnifying glass",
                  "category": "objects",
                  "keywords": "['glass', 'magnifying', 'search', 'tool']",
                  "display": 1,
                  "translatedName": "Lupa apontando para a direita"
                },
                {
                  "code": "1f50f",
                  "name": "locked with pen",
                  "category": "objects",
                  "keywords": "['ink', 'lock', 'nib', 'pen', 'privacy']",
                  "display": 1,
                  "translatedName": "Trancado com caneta"
                },
                {
                  "code": "1f510",
                  "name": "locked with key",
                  "category": "objects",
                  "keywords": "['closed', 'key', 'lock', 'secure']",
                  "display": 1,
                  "translatedName": "Bloqueado com chave"
                },
                {
                  "code": "1f512",
                  "name": "locked",
                  "category": "objects",
                  "keywords": "['closed']",
                  "display": 1,
                  "translatedName": "trancado"
                },
                {
                  "code": "1f513",
                  "name": "unlocked",
                  "category": "objects",
                  "keywords": "['lock', 'open', 'unlock']",
                  "display": 1,
                  "translatedName": "desbloqueado"
                },
                {
                  "code": "1f9e1",
                  "name": "orange heart",
                  "category": "objects",
                  "keywords": "['orange']",
                  "display": 1,
                  "translatedName": "coração laranja"
                },
                {
                  "code": 2764,
                  "name": "red heart",
                  "category": "symbols",
                  "keywords": "['heart']",
                  "display": 1,
                  "translatedName": "coração vermelho"
                },
                {
                  "code": "1f49b",
                  "name": "yellow heart",
                  "category": "symbols",
                  "keywords": "['yellow']",
                  "display": 1,
                  "translatedName": "Coração amarelo"
                },
                {
                  "code": "1f49a",
                  "name": "green heart",
                  "category": "symbols",
                  "keywords": "['green']",
                  "display": 1,
                  "translatedName": "coração verde"
                },
                {
                  "code": "1f499",
                  "name": "blue heart",
                  "category": "symbols",
                  "keywords": "['blue']",
                  "display": 1,
                  "translatedName": "Coração azul"
                },
                {
                  "code": "1f49c",
                  "name": "purple heart",
                  "category": "symbols",
                  "keywords": "['purple']",
                  "display": 1,
                  "translatedName": "coração roxo"
                },
                {
                  "code": "1f5a4",
                  "name": "black heart",
                  "category": "symbols",
                  "keywords": "['black', 'evil', 'wicked']",
                  "display": 1,
                  "translatedName": "coração preto"
                },
                {
                  "code": "1f494",
                  "name": "broken heart",
                  "category": "symbols",
                  "keywords": "['break', 'broken']",
                  "display": 1,
                  "translatedName": "coração partido"
                },
                {
                  "code": 2763,
                  "name": "heavy heart exclamation",
                  "category": "symbols",
                  "keywords": "['exclamation', 'mark', 'punctuation']",
                  "display": 1,
                  "translatedName": "Exclamação do coração pesado"
                },
                {
                  "code": "1f495",
                  "name": "two hearts",
                  "category": "symbols",
                  "keywords": "['love']",
                  "display": 1,
                  "translatedName": "dois corações"
                },
                {
                  "code": "1f49e",
                  "name": "revolving hearts",
                  "category": "symbols",
                  "keywords": "['revolving']",
                  "display": 1,
                  "translatedName": "corações rotativos"
                },
                {
                  "code": "1f493",
                  "name": "beating heart",
                  "category": "symbols",
                  "keywords": "['beating', 'heartbeat', 'pulsating']",
                  "display": 1,
                  "translatedName": "batendo coração"
                },
                {
                  "code": "1f497",
                  "name": "growing heart",
                  "category": "symbols",
                  "keywords": "['excited', 'growing', 'nervous', 'pulse']",
                  "display": 1,
                  "translatedName": "Coração crescente"
                },
                {
                  "code": "1f496",
                  "name": "sparkling heart",
                  "category": "symbols",
                  "keywords": "['excited', 'sparkle']",
                  "display": 1,
                  "translatedName": "coração espumante"
                },
                {
                  "code": "1f498",
                  "name": "heart with arrow",
                  "category": "symbols",
                  "keywords": "['arrow', 'cupid']",
                  "display": 1,
                  "translatedName": "Coração com flecha"
                },
                {
                  "code": "1f49d",
                  "name": "heart with ribbon",
                  "category": "symbols",
                  "keywords": "['ribbon', 'valentine']",
                  "display": 1,
                  "translatedName": "Coração com fita"
                },
                {
                  "code": "1f49f",
                  "name": "heart decoration",
                  "category": "symbols",
                  "keywords": "['heart']",
                  "display": 1,
                  "translatedName": "Decoração do coração"
                },
                {
                  "code": "262e",
                  "name": "peace symbol",
                  "category": "symbols",
                  "keywords": "['peace']",
                  "display": 1,
                  "translatedName": "símbolo de paz"
                },
                {
                  "code": "271d",
                  "name": "latin cross",
                  "category": "symbols",
                  "keywords": "['Christian', 'cross', 'religion']",
                  "display": 1,
                  "translatedName": "Cross latin."
                },
                {
                  "code": "262a",
                  "name": "star and crescent",
                  "category": "symbols",
                  "keywords": "['Muslim', 'islam', 'religion']",
                  "display": 1,
                  "translatedName": "estrela e crescente"
                },
                {
                  "code": "1f549",
                  "name": "om",
                  "category": "symbols",
                  "keywords": "['Hindu', 'religion']",
                  "display": 1,
                  "translatedName": "om."
                },
                {
                  "code": 2638,
                  "name": "wheel of dharma",
                  "category": "symbols",
                  "keywords": "['Buddhist', 'dharma', 'religion', 'wheel']",
                  "display": 1,
                  "translatedName": "Roda do Dharma"
                },
                {
                  "code": 2721,
                  "name": "star of David",
                  "category": "symbols",
                  "keywords": "['David', 'Jew', 'Jewish', 'religion', 'star']",
                  "display": 1,
                  "translatedName": "estrela de Davi"
                },
                {
                  "code": "1f52f",
                  "name": "dotted six-pointed star",
                  "category": "symbols",
                  "keywords": "['fortune', 'star']",
                  "display": 1,
                  "translatedName": "estrela pontilhada de seis pontas"
                },
                {
                  "code": "1f54e",
                  "name": "menorah",
                  "category": "symbols",
                  "keywords": "['candelabrum', 'candlestick', 'religion']",
                  "display": 1,
                  "translatedName": "Menorah."
                },
                {
                  "code": "262f",
                  "name": "yin yang",
                  "category": "symbols",
                  "keywords": "['religion', 'tao', 'taoist', 'yang', 'yin']",
                  "display": 1,
                  "translatedName": "Yin Yang."
                },
                {
                  "code": 2626,
                  "name": "orthodox cross",
                  "category": "symbols",
                  "keywords": "['Christian', 'cross', 'religion']",
                  "display": 1,
                  "translatedName": "Cross ortodoxo"
                },
                {
                  "code": "1f6d0",
                  "name": "place of worship",
                  "category": "symbols",
                  "keywords": "['religion', 'worship']",
                  "display": 1,
                  "translatedName": "local de culto"
                },
                {
                  "code": "26ce",
                  "name": "Ophiuchus",
                  "category": "symbols",
                  "keywords": "['bearer', 'serpent', 'snake', 'zodiac']",
                  "display": 1,
                  "translatedName": "Ophiuchus."
                },
                {
                  "code": 2648,
                  "name": "Aries",
                  "category": "symbols",
                  "keywords": "['ram', 'zodiac']",
                  "display": 1,
                  "translatedName": "Áries"
                },
                {
                  "code": 2649,
                  "name": "Taurus",
                  "category": "symbols",
                  "keywords": "['bull', 'ox', 'zodiac']",
                  "display": 1,
                  "translatedName": "Touro"
                },
                {
                  "code": "264a",
                  "name": "Gemini",
                  "category": "symbols",
                  "keywords": "['twins', 'zodiac']",
                  "display": 1,
                  "translatedName": "Gêmeos"
                },
                {
                  "code": "264b",
                  "name": "Cancer",
                  "category": "symbols",
                  "keywords": "['crab', 'zodiac']",
                  "display": 1,
                  "translatedName": "Câncer"
                },
                {
                  "code": "264c",
                  "name": "Leo",
                  "category": "symbols",
                  "keywords": "['lion', 'zodiac']",
                  "display": 1,
                  "translatedName": "Leo."
                },
                {
                  "code": "264d",
                  "name": "Virgo",
                  "category": "symbols",
                  "keywords": "['zodiac']",
                  "display": 1,
                  "translatedName": "Virgem"
                },
                {
                  "code": "264e",
                  "name": "Libra",
                  "category": "symbols",
                  "keywords": "['balance', 'justice', 'scales', 'zodiac']",
                  "display": 1,
                  "translatedName": "Libra"
                },
                {
                  "code": "264f",
                  "name": "Scorpius",
                  "category": "symbols",
                  "keywords": "['scorpio', 'scorpion', 'zodiac']",
                  "display": 1,
                  "translatedName": "Scorpius."
                },
                {
                  "code": 2650,
                  "name": "Sagittarius",
                  "category": "symbols",
                  "keywords": "['archer', 'zodiac']",
                  "display": 1,
                  "translatedName": "Sagitário"
                },
                {
                  "code": 2651,
                  "name": "Capricorn",
                  "category": "symbols",
                  "keywords": "['goat', 'zodiac']",
                  "display": 1,
                  "translatedName": "Capricórnio"
                },
                {
                  "code": 2652,
                  "name": "Aquarius",
                  "category": "symbols",
                  "keywords": "['bearer', 'water', 'zodiac']",
                  "display": 1,
                  "translatedName": "Aquário"
                },
                {
                  "code": 2653,
                  "name": "Pisces",
                  "category": "symbols",
                  "keywords": "['fish', 'zodiac']",
                  "display": 1,
                  "translatedName": "Peixes"
                },
                {
                  "code": "1f194",
                  "name": "ID button",
                  "category": "symbols",
                  "keywords": "['id', 'identity']",
                  "display": 1,
                  "translatedName": "Botão de identificação"
                },
                {
                  "code": "269b",
                  "name": "atom symbol",
                  "category": "symbols",
                  "keywords": "['atheist', 'atom']",
                  "display": 1,
                  "translatedName": "símbolo do átomo."
                },
                {
                  "code": "1f251",
                  "name": "Japanese “acceptable” button",
                  "category": "symbols",
                  "keywords": "['Japanese', 'ideograph', '“acceptable”', '可']",
                  "display": 1,
                  "translatedName": "Botão \"aceitável\" japonês"
                },
                {
                  "code": 2622,
                  "name": "radioactive",
                  "category": "symbols",
                  "keywords": "['radioactive', 'sign']",
                  "display": 1,
                  "translatedName": "radioativo"
                },
                {
                  "code": 2623,
                  "name": "biohazard",
                  "category": "symbols",
                  "keywords": "['biohazard', 'sign']",
                  "display": 1,
                  "translatedName": "risco biológico"
                },
                {
                  "code": "1f4f4",
                  "name": "mobile phone off",
                  "category": "symbols",
                  "keywords": "['cell', 'mobile', 'off', 'phone', 'telephone']",
                  "display": 1,
                  "translatedName": "Telefone celular desligado"
                },
                {
                  "code": "1f4f3",
                  "name": "vibration mode",
                  "category": "symbols",
                  "keywords": "['cell', 'mobile', 'mode', 'phone', 'telephone', 'vibration']",
                  "display": 1,
                  "translatedName": "modo de vibração"
                },
                {
                  "code": "1f236",
                  "name": "Japanese “not free of charge” button",
                  "category": "symbols",
                  "keywords": "['Japanese', 'ideograph', '“not free of charge”', '有']",
                  "display": 1,
                  "translatedName": "Botão japonês \"não livre"
                },
                {
                  "code": "1f21a",
                  "name": "Japanese “free of charge” button",
                  "category": "symbols",
                  "keywords": "['Japanese', 'ideograph', '“free of charge”', '無']",
                  "display": 1,
                  "translatedName": "Botão japonês de \"grátis"
                },
                {
                  "code": "1f238",
                  "name": "Japanese “application” button",
                  "category": "symbols",
                  "keywords": "['Japanese', 'ideograph', '“application”', '申']",
                  "display": 1,
                  "translatedName": "Botão de \"aplicação\" japonês"
                },
                {
                  "code": "1f23a",
                  "name": "Japanese “open for business” button",
                  "category": "symbols",
                  "keywords": "['Japanese', 'ideograph', '“open for business”', '営']",
                  "display": 1,
                  "translatedName": "Botão japonês \"aberto para negócios"
                },
                {
                  "code": "1f237",
                  "name": "Japanese “monthly amount” button",
                  "category": "symbols",
                  "keywords": "['Japanese', 'ideograph', '“monthly amount”', '月']",
                  "display": 1,
                  "translatedName": "Botão de \"quantidade mensal\" japonês"
                },
                {
                  "code": 2734,
                  "name": "eight-pointed star",
                  "category": "symbols",
                  "keywords": "['star']",
                  "display": 1,
                  "translatedName": "estrela de oito pontas"
                },
                {
                  "code": "1f19a",
                  "name": "VS button",
                  "category": "symbols",
                  "keywords": "['versus', 'vs']",
                  "display": 1,
                  "translatedName": "Vs botão."
                },
                {
                  "code": "1f4ae",
                  "name": "white flower",
                  "category": "symbols",
                  "keywords": "['flower']",
                  "display": 1,
                  "translatedName": "Flor branca"
                },
                {
                  "code": "1f250",
                  "name": "Japanese “bargain” button",
                  "category": "symbols",
                  "keywords": "['Japanese', 'ideograph', '“bargain”', '得']",
                  "display": 1,
                  "translatedName": "Botão japonês \"barganha"
                },
                {
                  "code": 3299,
                  "name": "Japanese “secret” button",
                  "category": "symbols",
                  "keywords": "['Japanese', 'ideograph', '“secret”', '秘']",
                  "display": 1,
                  "translatedName": "Botão \"secreto\" japonês"
                },
                {
                  "code": 3297,
                  "name": "Japanese “congratulations” button",
                  "category": "symbols",
                  "keywords": "['Japanese', 'ideograph', '“congratulations”', '祝']",
                  "display": 1,
                  "translatedName": "Botão japonês \"Parabéns"
                },
                {
                  "code": "1f234",
                  "name": "Japanese “passing grade” button",
                  "category": "symbols",
                  "keywords": "['Japanese', 'ideograph', '“passing grade”', '合']",
                  "display": 1,
                  "translatedName": "Botão japonês \"passagem"
                },
                {
                  "code": "1f235",
                  "name": "Japanese “no vacancy” button",
                  "category": "symbols",
                  "keywords": "['Japanese', 'ideograph', '“no vacancy”', '満']",
                  "display": 1,
                  "translatedName": "Botão japonês \"No Vacancy"
                },
                {
                  "code": "1f239",
                  "name": "Japanese “discount” button",
                  "category": "symbols",
                  "keywords": "['Japanese', 'ideograph', '“discount”', '割']",
                  "display": 1,
                  "translatedName": "Botão de \"desconto\" japonês"
                },
                {
                  "code": "1f232",
                  "name": "Japanese “prohibited” button",
                  "category": "symbols",
                  "keywords": "['Japanese', 'ideograph', '“prohibited”', '禁']",
                  "display": 1,
                  "translatedName": "Botão japonês \"proibido"
                },
                {
                  "code": "1f170",
                  "name": "A button (blood type)",
                  "category": "symbols",
                  "keywords": "['a', 'blood type']",
                  "display": 1,
                  "translatedName": "Um botão (tipo de sangue)"
                },
                {
                  "code": "1f171",
                  "name": "B button (blood type)",
                  "category": "symbols",
                  "keywords": "['b', 'blood type']",
                  "display": 1,
                  "translatedName": "Botão B (tipo de sangue)"
                },
                {
                  "code": "1f18e",
                  "name": "AB button (blood type)",
                  "category": "symbols",
                  "keywords": "['ab', 'blood type']",
                  "display": 1,
                  "translatedName": "Botão ab (tipo de sangue)"
                },
                {
                  "code": "1f191",
                  "name": "CL button",
                  "category": "symbols",
                  "keywords": "['cl']",
                  "display": 1,
                  "translatedName": "Botão cl"
                },
                {
                  "code": "1f17e",
                  "name": "O button (blood type)",
                  "category": "symbols",
                  "keywords": "['blood type', 'o']",
                  "display": 1,
                  "translatedName": "O botão (tipo de sangue)"
                },
                {
                  "code": "1f198",
                  "name": "SOS button",
                  "category": "symbols",
                  "keywords": "['help', 'sos']",
                  "display": 1,
                  "translatedName": "Botão SOS."
                },
                {
                  "code": "274c",
                  "name": "cross mark",
                  "category": "symbols",
                  "keywords": "['cancel', 'mark', 'multiplication', 'multiply', 'x']",
                  "display": 1,
                  "translatedName": "Marca Cross"
                },
                {
                  "code": "2b55",
                  "name": "heavy large circle",
                  "category": "symbols",
                  "keywords": "['circle', 'o']",
                  "display": 1,
                  "translatedName": "Círculo grande pesado"
                },
                {
                  "code": "1f6d1",
                  "name": "stop sign",
                  "category": "symbols",
                  "keywords": "['octagonal', 'sign', 'stop']",
                  "display": 1,
                  "translatedName": "Sinal de parada"
                },
                {
                  "code": "26d4",
                  "name": "no entry",
                  "category": "symbols",
                  "keywords": "['entry', 'forbidden', 'no', 'not', 'prohibited', 'traffic']",
                  "display": 1,
                  "translatedName": "Entrada proibida"
                },
                {
                  "code": "1f4db",
                  "name": "name badge",
                  "category": "symbols",
                  "keywords": "['badge', 'name']",
                  "display": 1,
                  "translatedName": "Nome Badge."
                },
                {
                  "code": "1f6ab",
                  "name": "prohibited",
                  "category": "symbols",
                  "keywords": "['entry', 'forbidden', 'no', 'not']",
                  "display": 1,
                  "translatedName": "Proibido"
                },
                {
                  "code": "1f4af",
                  "name": "hundred points",
                  "category": "symbols",
                  "keywords": "['100', 'full', 'hundred', 'score']",
                  "display": 1,
                  "translatedName": "cem pontos"
                },
                {
                  "code": "1f4a2",
                  "name": "anger symbol",
                  "category": "symbols",
                  "keywords": "['angry', 'comic', 'mad']",
                  "display": 1,
                  "translatedName": "símbolo de raiva"
                },
                {
                  "code": 2668,
                  "name": "hot springs",
                  "category": "symbols",
                  "keywords": "['hot', 'hotsprings', 'springs', 'steaming']",
                  "display": 1,
                  "translatedName": "Hot Springs."
                },
                {
                  "code": "1f6b7",
                  "name": "no pedestrians",
                  "category": "symbols",
                  "keywords": "['forbidden', 'no', 'not', 'pedestrian', 'prohibited']",
                  "display": 1,
                  "translatedName": "Proibido Pedestres"
                },
                {
                  "code": "1f6af",
                  "name": "no littering",
                  "category": "symbols",
                  "keywords": "['forbidden', 'litter', 'no', 'not', 'prohibited']",
                  "display": 1,
                  "translatedName": "Proibido jogar lixo"
                },
                {
                  "code": "1f6b3",
                  "name": "no bicycles",
                  "category": "symbols",
                  "keywords": "['bicycle', 'bike', 'forbidden', 'no', 'not', 'prohibited']",
                  "display": 1,
                  "translatedName": "Proibido Bicicletas"
                },
                {
                  "code": "1f6b1",
                  "name": "non-potable water",
                  "category": "symbols",
                  "keywords": "['non-drinking', 'non-potable', 'water']",
                  "display": 1,
                  "translatedName": "água não potável"
                },
                {
                  "code": "1f51e",
                  "name": "no one under eighteen",
                  "category": "symbols",
                  "keywords": "['18', 'age restriction', 'eighteen', 'forbidden', 'no', 'not', 'prohibited', 'underage']",
                  "display": 1,
                  "translatedName": "Ninguém com menos de dezoito anos"
                },
                {
                  "code": "1f4f5",
                  "name": "no mobile phones",
                  "category": "symbols",
                  "keywords": "['cell', 'forbidden', 'mobile', 'no', 'not', 'phone', 'prohibited', 'telephone']",
                  "display": 1,
                  "translatedName": "Não há telefones celulares"
                },
                {
                  "code": "1f6ad",
                  "name": "no smoking",
                  "category": "symbols",
                  "keywords": "['forbidden', 'no', 'not', 'prohibited', 'smoking']",
                  "display": 1,
                  "translatedName": "Proibido fumar"
                },
                {
                  "code": 2757,
                  "name": "exclamation mark",
                  "category": "symbols",
                  "keywords": "['exclamation', 'mark', 'punctuation']",
                  "display": 1,
                  "translatedName": "ponto de exclamação"
                },
                {
                  "code": 2755,
                  "name": "white exclamation mark",
                  "category": "symbols",
                  "keywords": "['exclamation', 'mark', 'outlined', 'punctuation']",
                  "display": 1,
                  "translatedName": "Marca de exclamação branca"
                },
                {
                  "code": 2753,
                  "name": "question mark",
                  "category": "symbols",
                  "keywords": "['mark', 'punctuation', 'question']",
                  "display": 1,
                  "translatedName": "ponto de interrogação"
                },
                {
                  "code": 2754,
                  "name": "white question mark",
                  "category": "symbols",
                  "keywords": "['mark', 'outlined', 'punctuation', 'question']",
                  "display": 1,
                  "translatedName": "Ponto de interrogação branco"
                },
                {
                  "code": "203c",
                  "name": "double exclamation mark",
                  "category": "symbols",
                  "keywords": "['bangbang', 'exclamation', 'mark', 'punctuation']",
                  "display": 1,
                  "translatedName": "Marca de exclamação dupla."
                },
                {
                  "code": 2049,
                  "name": "exclamation question mark",
                  "category": "symbols",
                  "keywords": "['exclamation', 'interrobang', 'mark', 'punctuation', 'question']",
                  "display": 1,
                  "translatedName": "ponto de interrogação de exclamação."
                },
                {
                  "code": "1f505",
                  "name": "dim button",
                  "category": "symbols",
                  "keywords": "['brightness', 'dim', 'low']",
                  "display": 1,
                  "translatedName": "Botão escuro"
                },
                {
                  "code": "1f506",
                  "name": "bright button",
                  "category": "symbols",
                  "keywords": "['bright', 'brightness']",
                  "display": 1,
                  "translatedName": "Botão brilhante"
                },
                {
                  "code": "303d",
                  "name": "part alternation mark",
                  "category": "symbols",
                  "keywords": "['mark', 'part']",
                  "display": 1,
                  "translatedName": "Parte de marca de alternância"
                },
                {
                  "code": "26a0",
                  "name": "warning",
                  "category": "symbols",
                  "keywords": "['warning']",
                  "display": 1,
                  "translatedName": "aviso"
                },
                {
                  "code": "1f6b8",
                  "name": "children crossing",
                  "category": "symbols",
                  "keywords": "['child', 'crossing', 'pedestrian', 'traffic']",
                  "display": 1,
                  "translatedName": "Cruzamento de crianças"
                },
                {
                  "code": "1f531",
                  "name": "trident emblem",
                  "category": "symbols",
                  "keywords": "['anchor', 'emblem', 'ship', 'tool', 'trident']",
                  "display": 1,
                  "translatedName": "Emblema Tridente"
                },
                {
                  "code": "269c",
                  "name": "fleur-de-lis",
                  "category": "symbols",
                  "keywords": "['fleur-de-lis']",
                  "display": 1,
                  "translatedName": "fleur-de-lis"
                },
                {
                  "code": "1f530",
                  "name": "Japanese symbol for beginner",
                  "category": "symbols",
                  "keywords": "['Japanese', 'beginner', 'chevron', 'green', 'leaf', 'tool', 'yellow']",
                  "display": 1,
                  "translatedName": "Símbolo japonês para iniciante"
                },
                {
                  "code": "267b",
                  "name": "recycling symbol",
                  "category": "symbols",
                  "keywords": "['recycle']",
                  "display": 1,
                  "translatedName": "Símbolo de reciclagem"
                },
                {
                  "code": 2705,
                  "name": "white heavy check mark",
                  "category": "symbols",
                  "keywords": "['check', 'mark']",
                  "display": 1,
                  "translatedName": "marca de cheque pesado branco"
                },
                {
                  "code": "1f22f",
                  "name": "Japanese “reserved” button",
                  "category": "symbols",
                  "keywords": "['Japanese', 'ideograph', '“reserved”', '指']",
                  "display": 1,
                  "translatedName": "Botão japonês \"reservado"
                },
                {
                  "code": "1f4b9",
                  "name": "chart increasing with yen",
                  "category": "symbols",
                  "keywords": "['bank', 'chart', 'currency', 'graph', 'growth', 'market', 'money', 'rise', 'trend', 'upward', 'yen']",
                  "display": 1,
                  "translatedName": "Gráfico aumentando com iene"
                },
                {
                  "code": 2747,
                  "name": "sparkle",
                  "category": "symbols",
                  "keywords": "['sparkle']",
                  "display": 1,
                  "translatedName": "brilhar"
                },
                {
                  "code": 2733,
                  "name": "eight-spoked asterisk",
                  "category": "symbols",
                  "keywords": "['asterisk']",
                  "display": 1,
                  "translatedName": "asterisco de oito direcionado"
                },
                {
                  "code": "274e",
                  "name": "cross mark button",
                  "category": "symbols",
                  "keywords": "['mark', 'square']",
                  "display": 1,
                  "translatedName": "Botão de marca cruzada"
                },
                {
                  "code": "1f310",
                  "name": "globe with meridians",
                  "category": "symbols",
                  "keywords": "['earth', 'globe', 'meridians', 'world']",
                  "display": 1,
                  "translatedName": "Globo com meridianos"
                },
                {
                  "code": "1f4a0",
                  "name": "diamond with a dot",
                  "category": "symbols",
                  "keywords": "['comic', 'diamond', 'geometric', 'inside']",
                  "display": 1,
                  "translatedName": "diamante com um ponto"
                },
                {
                  "code": "24c2",
                  "name": "circled M",
                  "category": "symbols",
                  "keywords": "['circle', 'm']",
                  "display": 1,
                  "translatedName": "circulou M."
                },
                {
                  "code": "1f300",
                  "name": "cyclone",
                  "category": "symbols",
                  "keywords": "['dizzy', 'twister', 'typhoon']",
                  "display": 1,
                  "translatedName": "ciclone"
                },
                {
                  "code": "1f4a4",
                  "name": "zzz",
                  "category": "symbols",
                  "keywords": "['comic', 'sleep']",
                  "display": 1,
                  "translatedName": "zzz."
                },
                {
                  "code": "1f3e7",
                  "name": "ATM sign",
                  "category": "symbols",
                  "keywords": "['atm', 'automated', 'bank', 'teller']",
                  "display": 1,
                  "translatedName": "Sinal ATM"
                },
                {
                  "code": "1f6be",
                  "name": "water closet",
                  "category": "symbols",
                  "keywords": "['closet', 'lavatory', 'restroom', 'water', 'wc']",
                  "display": 1,
                  "translatedName": "armário de água."
                },
                {
                  "code": "267f",
                  "name": "wheelchair symbol",
                  "category": "symbols",
                  "keywords": "['access']",
                  "display": 1,
                  "translatedName": "Símbolo de cadeira de rodas"
                },
                {
                  "code": "1f17f",
                  "name": "P button",
                  "category": "symbols",
                  "keywords": "['parking']",
                  "display": 1,
                  "translatedName": "Botão P."
                },
                {
                  "code": "1f233",
                  "name": "Japanese “vacancy” button",
                  "category": "symbols",
                  "keywords": "['Japanese', 'ideograph', '“vacancy”', '空']",
                  "display": 1,
                  "translatedName": "Botão de \"vaga\" japonês"
                },
                {
                  "code": "1f202",
                  "name": "Japanese “service charge” button",
                  "category": "symbols",
                  "keywords": "['Japanese', 'katakana', '“service charge”', 'サ']",
                  "display": 1,
                  "translatedName": "Botão de \"custo de serviço\" japonês"
                },
                {
                  "code": "1f6c2",
                  "name": "passport control",
                  "category": "symbols",
                  "keywords": "['control', 'passport']",
                  "display": 1,
                  "translatedName": "controle de passaporte"
                },
                {
                  "code": "1f6c3",
                  "name": "customs",
                  "category": "symbols",
                  "keywords": "['customs']",
                  "display": 1,
                  "translatedName": "Alfândega"
                },
                {
                  "code": "1f6c4",
                  "name": "baggage claim",
                  "category": "symbols",
                  "keywords": "['baggage', 'claim']",
                  "display": 1,
                  "translatedName": "Reivindicação de bagagem"
                },
                {
                  "code": "1f6c5",
                  "name": "left luggage",
                  "category": "symbols",
                  "keywords": "['baggage', 'locker', 'luggage']",
                  "display": 1,
                  "translatedName": "bagagem esquerda"
                },
                {
                  "code": "1f6b9",
                  "name": "men’s room",
                  "category": "symbols",
                  "keywords": "['lavatory', 'man', 'restroom', 'wc']",
                  "display": 1,
                  "translatedName": "quarto de homens"
                },
                {
                  "code": "1f6ba",
                  "name": "women’s room",
                  "category": "symbols",
                  "keywords": "['lavatory', 'restroom', 'wc', 'woman']",
                  "display": 1,
                  "translatedName": "sala das mulheres"
                },
                {
                  "code": "1f6bc",
                  "name": "baby symbol",
                  "category": "symbols",
                  "keywords": "['baby', 'changing']",
                  "display": 1,
                  "translatedName": "símbolo do bebê"
                },
                {
                  "code": "1f6bb",
                  "name": "restroom",
                  "category": "symbols",
                  "keywords": "['WC', 'lavatory', 'restroom']",
                  "display": 1,
                  "translatedName": "banheiro"
                },
                {
                  "code": "1f6ae",
                  "name": "litter in bin sign",
                  "category": "symbols",
                  "keywords": "['litter', 'litter bin']",
                  "display": 1,
                  "translatedName": "ninhada no sinal de bin"
                },
                {
                  "code": "1f3a6",
                  "name": "cinema",
                  "category": "symbols",
                  "keywords": "['camera', 'film', 'movie']",
                  "display": 1,
                  "translatedName": "cinema"
                },
                {
                  "code": "1f4f6",
                  "name": "antenna bars",
                  "category": "symbols",
                  "keywords": "['antenna', 'bar', 'cell', 'mobile', 'phone', 'signal', 'telephone']",
                  "display": 1,
                  "translatedName": "barras de antena"
                },
                {
                  "code": "1f201",
                  "name": "Japanese “here” button",
                  "category": "symbols",
                  "keywords": "['Japanese', 'katakana', '“here”', 'ココ']",
                  "display": 1,
                  "translatedName": "Botão japonês \"aqui"
                },
                {
                  "code": "1f523",
                  "name": "input symbols",
                  "category": "symbols",
                  "keywords": "['input', '〒♪&amp;%']",
                  "display": 1,
                  "translatedName": "Símbolos de entrada"
                },
                {
                  "code": 2139,
                  "name": "information",
                  "category": "symbols",
                  "keywords": "['i', 'information']",
                  "display": 1,
                  "translatedName": "em formação"
                },
                {
                  "code": "1f524",
                  "name": "input latin letters",
                  "category": "symbols",
                  "keywords": "['abc', 'alphabet', 'input', 'latin', 'letters']",
                  "display": 1,
                  "translatedName": "Entrada letras latinas."
                },
                {
                  "code": "1f521",
                  "name": "input latin lowercase",
                  "category": "symbols",
                  "keywords": "['abcd', 'input', 'latin', 'letters', 'lowercase']",
                  "display": 1,
                  "translatedName": "entrada em minúsculas latin."
                },
                {
                  "code": "1f520",
                  "name": "input latin uppercase",
                  "category": "symbols",
                  "keywords": "['ABCD', 'input', 'latin', 'letters', 'uppercase']",
                  "display": 1,
                  "translatedName": "entrada em maiúsculas latinas."
                },
                {
                  "code": "1f196",
                  "name": "NG button",
                  "category": "symbols",
                  "keywords": "['ng']",
                  "display": 1,
                  "translatedName": "Botão ng."
                },
                {
                  "code": "1f197",
                  "name": "OK button",
                  "category": "symbols",
                  "keywords": "['OK']",
                  "display": 1,
                  "translatedName": "Botão OK"
                },
                {
                  "code": "1f199",
                  "name": "UP! button",
                  "category": "symbols",
                  "keywords": "['mark', 'up']",
                  "display": 1,
                  "translatedName": "ACIMA! botão"
                },
                {
                  "code": "1f192",
                  "name": "COOL button",
                  "category": "symbols",
                  "keywords": "['cool']",
                  "display": 1,
                  "translatedName": "Botão legal"
                },
                {
                  "code": "1f195",
                  "name": "NEW button",
                  "category": "symbols",
                  "keywords": "['new']",
                  "display": 1,
                  "translatedName": "Botão novo"
                },
                {
                  "code": "1f193",
                  "name": "FREE button",
                  "category": "symbols",
                  "keywords": "['free']",
                  "display": 1,
                  "translatedName": "Botão grátis"
                },
                {
                  "code": "0030-20e3",
                  "name": "keycap: 0",
                  "category": "symbols",
                  "keywords": "['keycap']",
                  "display": 1,
                  "translatedName": "Keycap: 0."
                },
                {
                  "code": "0031-20e3",
                  "name": "keycap: 1",
                  "category": "symbols",
                  "keywords": "['keycap']",
                  "display": 1,
                  "translatedName": "Keycap: 1."
                },
                {
                  "code": "0032-20e3",
                  "name": "keycap: 2",
                  "category": "symbols",
                  "keywords": "['keycap']",
                  "display": 1,
                  "translatedName": "Keycap: 2."
                },
                {
                  "code": "0033-20e3",
                  "name": "keycap: 3",
                  "category": "symbols",
                  "keywords": "['keycap']",
                  "display": 1,
                  "translatedName": "Keycap: 3."
                },
                {
                  "code": "0034-20e3",
                  "name": "keycap: 4",
                  "category": "symbols",
                  "keywords": "['keycap']",
                  "display": 1,
                  "translatedName": "Keycap: 4."
                },
                {
                  "code": "0035-20e3",
                  "name": "keycap: 5",
                  "category": "symbols",
                  "keywords": "['keycap']",
                  "display": 1,
                  "translatedName": "Keycap: 5."
                },
                {
                  "code": "0036-20e3",
                  "name": "keycap: 6",
                  "category": "symbols",
                  "keywords": "['keycap']",
                  "display": 1,
                  "translatedName": "Keycap: 6."
                },
                {
                  "code": "0037-20e3",
                  "name": "keycap: 7",
                  "category": "symbols",
                  "keywords": "['keycap']",
                  "display": 1,
                  "translatedName": "Keycap: 7."
                },
                {
                  "code": "0038-20e3",
                  "name": "keycap: 8",
                  "category": "symbols",
                  "keywords": "['keycap']",
                  "display": 1,
                  "translatedName": "Keycap: 8."
                },
                {
                  "code": "0039-20e3",
                  "name": "keycap: 9",
                  "category": "symbols",
                  "keywords": "['keycap']",
                  "display": 1,
                  "translatedName": "KeyCap: 9."
                },
                {
                  "code": "1f51f",
                  "name": "keycap 10",
                  "category": "symbols",
                  "keywords": "['keycap 10']",
                  "display": 1,
                  "translatedName": "Keycap 10."
                },
                {
                  "code": "1f522",
                  "name": "input numbers",
                  "category": "symbols",
                  "keywords": "['1234', 'input', 'numbers']",
                  "display": 1,
                  "translatedName": "Números de entrada"
                },
                {
                  "code": "0023-20e3",
                  "name": "keycap: #",
                  "category": "symbols",
                  "keywords": "['keycap']",
                  "display": 1,
                  "translatedName": "Keycap: #"
                },
                {
                  "code": "002a-20e3",
                  "name": "keycap: *",
                  "category": "symbols",
                  "keywords": "['keycap']",
                  "display": 1,
                  "translatedName": "Keycap: *"
                },
                {
                  "code": "25b6",
                  "name": "play button",
                  "category": "symbols",
                  "keywords": "['arrow', 'play', 'right', 'triangle']",
                  "display": 1,
                  "translatedName": "Botão de reprodução"
                },
                {
                  "code": "23f8",
                  "name": "pause button",
                  "category": "symbols",
                  "keywords": "['bar', 'double', 'pause', 'vertical']",
                  "display": 1,
                  "translatedName": "Botão de pausa"
                },
                {
                  "code": "23ef",
                  "name": "play or pause button",
                  "category": "symbols",
                  "keywords": "['arrow', 'pause', 'play', 'right', 'triangle']",
                  "display": 1,
                  "translatedName": "Botão Reproduzir ou Pausar"
                },
                {
                  "code": "23f9",
                  "name": "stop button",
                  "category": "symbols",
                  "keywords": "['square', 'stop']",
                  "display": 1,
                  "translatedName": "botão de parada"
                },
                {
                  "code": "23fa",
                  "name": "record button",
                  "category": "symbols",
                  "keywords": "['circle', 'record']",
                  "display": 1,
                  "translatedName": "Botão de registro"
                },
                {
                  "code": "23cf",
                  "name": "eject button",
                  "category": "symbols",
                  "keywords": "['eject']",
                  "display": 1,
                  "translatedName": "botão de ejeção"
                },
                {
                  "code": "23ed",
                  "name": "next track button",
                  "category": "symbols",
                  "keywords": "['arrow', 'next scene', 'next track', 'triangle']",
                  "display": 1,
                  "translatedName": "Próximo botão de faixa"
                },
                {
                  "code": "23ee",
                  "name": "last track button",
                  "category": "symbols",
                  "keywords": "['arrow', 'previous scene', 'previous track', 'triangle']",
                  "display": 1,
                  "translatedName": "Último botão de faixa"
                },
                {
                  "code": "2,30E+10",
                  "name": "fast-forward button",
                  "category": "symbols",
                  "keywords": "['arrow', 'double', 'fast', 'forward']",
                  "display": 1,
                  "translatedName": "Botão Avançado"
                },
                {
                  "code": "23ea",
                  "name": "fast reverse button",
                  "category": "symbols",
                  "keywords": "['arrow', 'double', 'rewind']",
                  "display": 1,
                  "translatedName": "Botão reverso rápido"
                },
                {
                  "code": "23eb",
                  "name": "fast up button",
                  "category": "symbols",
                  "keywords": "['arrow', 'double']",
                  "display": 1,
                  "translatedName": "Botão de jejum"
                },
                {
                  "code": "23ec",
                  "name": "fast down button",
                  "category": "symbols",
                  "keywords": "['arrow', 'double', 'down']",
                  "display": 1,
                  "translatedName": "Botão rápido"
                },
                {
                  "code": "25c0",
                  "name": "reverse button",
                  "category": "symbols",
                  "keywords": "['arrow', 'left', 'reverse', 'triangle']",
                  "display": 1,
                  "translatedName": "Botão reverso"
                },
                {
                  "code": "1f53c",
                  "name": "up button",
                  "category": "symbols",
                  "keywords": "['arrow', 'button', 'red']",
                  "display": 1,
                  "translatedName": "botão para cima"
                },
                {
                  "code": "1f53d",
                  "name": "down button",
                  "category": "symbols",
                  "keywords": "['arrow', 'button', 'down', 'red']",
                  "display": 1,
                  "translatedName": "Botão para baixo"
                },
                {
                  "code": "27a1",
                  "name": "right arrow",
                  "category": "symbols",
                  "keywords": "['arrow', 'cardinal', 'direction', 'east']",
                  "display": 1,
                  "translatedName": "seta direita"
                },
                {
                  "code": "2b05",
                  "name": "left arrow",
                  "category": "symbols",
                  "keywords": "['arrow', 'cardinal', 'direction', 'west']",
                  "display": 1,
                  "translatedName": "seta esquerda"
                },
                {
                  "code": "2b06",
                  "name": "up arrow",
                  "category": "symbols",
                  "keywords": "['arrow', 'cardinal', 'direction', 'north']",
                  "display": 1,
                  "translatedName": "seta para cima"
                },
                {
                  "code": "2b07",
                  "name": "down arrow",
                  "category": "symbols",
                  "keywords": "['arrow', 'cardinal', 'direction', 'down', 'south']",
                  "display": 1,
                  "translatedName": "seta para baixo"
                },
                {
                  "code": 2197,
                  "name": "up-right arrow",
                  "category": "symbols",
                  "keywords": "['arrow', 'direction', 'intercardinal', 'northeast']",
                  "display": 1,
                  "translatedName": "seta para a direita"
                },
                {
                  "code": 2198,
                  "name": "down-right arrow",
                  "category": "symbols",
                  "keywords": "['arrow', 'direction', 'intercardinal', 'southeast']",
                  "display": 1,
                  "translatedName": "arrow para baixo"
                },
                {
                  "code": 2199,
                  "name": "down-left arrow",
                  "category": "symbols",
                  "keywords": "['arrow', 'direction', 'intercardinal', 'southwest']",
                  "display": 1,
                  "translatedName": "seta para baixo para a esquerda"
                },
                {
                  "code": 2196,
                  "name": "up-left arrow",
                  "category": "symbols",
                  "keywords": "['arrow', 'direction', 'intercardinal', 'northwest']",
                  "display": 1,
                  "translatedName": "seta para a esquerda"
                },
                {
                  "code": 2195,
                  "name": "up-down arrow",
                  "category": "symbols",
                  "keywords": "['arrow']",
                  "display": 1,
                  "translatedName": "seta para baixo"
                },
                {
                  "code": 2194,
                  "name": "left-right arrow",
                  "category": "symbols",
                  "keywords": "['arrow']",
                  "display": 1,
                  "translatedName": "Seta à esquerda"
                },
                {
                  "code": "21aa",
                  "name": "left arrow curving right",
                  "category": "symbols",
                  "keywords": "['arrow']",
                  "display": 1,
                  "translatedName": "seta esquerda curva direita"
                },
                {
                  "code": "21a9",
                  "name": "right arrow curving left",
                  "category": "symbols",
                  "keywords": "['arrow']",
                  "display": 1,
                  "translatedName": "arrow direita curva esquerda"
                },
                {
                  "code": 2934,
                  "name": "right arrow curving up",
                  "category": "symbols",
                  "keywords": "['arrow']",
                  "display": 1,
                  "translatedName": "arrow direita curvando-se"
                },
                {
                  "code": 2935,
                  "name": "right arrow curving down",
                  "category": "symbols",
                  "keywords": "['arrow', 'down']",
                  "display": 1,
                  "translatedName": "arrow direito curva-se"
                },
                {
                  "code": "1f500",
                  "name": "shuffle tracks button",
                  "category": "symbols",
                  "keywords": "['arrow', 'crossed']",
                  "display": 1,
                  "translatedName": "Botão Shuffle Tracks."
                },
                {
                  "code": "1f501",
                  "name": "repeat button",
                  "category": "symbols",
                  "keywords": "['arrow', 'clockwise', 'repeat']",
                  "display": 1,
                  "translatedName": "Repetir botão"
                },
                {
                  "code": "1f502",
                  "name": "repeat single button",
                  "category": "symbols",
                  "keywords": "['arrow', 'clockwise', 'once']",
                  "display": 1,
                  "translatedName": "Repita o botão único"
                },
                {
                  "code": "1f504",
                  "name": "anticlockwise arrows button",
                  "category": "symbols",
                  "keywords": "['anticlockwise', 'arrow', 'counterclockwise', 'withershins']",
                  "display": 1,
                  "translatedName": "Botão de setas anti-horário"
                },
                {
                  "code": "1f503",
                  "name": "clockwise vertical arrows",
                  "category": "symbols",
                  "keywords": "['arrow', 'clockwise', 'reload']",
                  "display": 1,
                  "translatedName": "setas verticais no sentido horário"
                },
                {
                  "code": "1f3b5",
                  "name": "musical note",
                  "category": "symbols",
                  "keywords": "['music', 'note']",
                  "display": 1,
                  "translatedName": "nota musical"
                },
                {
                  "code": "1f3b6",
                  "name": "musical notes",
                  "category": "symbols",
                  "keywords": "['music', 'note', 'notes']",
                  "display": 1,
                  "translatedName": "notas musicais"
                },
                {
                  "code": 2795,
                  "name": "heavy plus sign",
                  "category": "symbols",
                  "keywords": "['math', 'plus']",
                  "display": 1,
                  "translatedName": "sinal pesado mais"
                },
                {
                  "code": 2796,
                  "name": "heavy minus sign",
                  "category": "symbols",
                  "keywords": "['math', 'minus']",
                  "display": 1,
                  "translatedName": "sinal pesado de menos"
                },
                {
                  "code": 2797,
                  "name": "heavy division sign",
                  "category": "symbols",
                  "keywords": "['division', 'math']",
                  "display": 1,
                  "translatedName": "sinal de divisão pesada"
                },
                {
                  "code": 2716,
                  "name": "heavy multiplication x",
                  "category": "symbols",
                  "keywords": "['cancel', 'multiplication', 'multiply', 'x']",
                  "display": 1,
                  "translatedName": "Multiplicação pesada X."
                },
                {
                  "code": "1f4b2",
                  "name": "heavy dollar sign",
                  "category": "symbols",
                  "keywords": "['currency', 'dollar', 'money']",
                  "display": 1,
                  "translatedName": "Sinal de dólar pesado"
                },
                {
                  "code": "1f4b1",
                  "name": "currency exchange",
                  "category": "symbols",
                  "keywords": "['bank', 'currency', 'exchange', 'money']",
                  "display": 1,
                  "translatedName": "câmbio monetário"
                },
                {
                  "code": 2122,
                  "name": "trade mark",
                  "category": "symbols",
                  "keywords": "['mark', 'tm', 'trademark']",
                  "display": 1,
                  "translatedName": "marca comercial"
                },
                {
                  "code": "00a9",
                  "name": "copyright",
                  "category": "symbols",
                  "keywords": "['copyright']",
                  "display": 1,
                  "translatedName": "direito autoral"
                },
                {
                  "code": "00ae",
                  "name": "registered",
                  "category": "symbols",
                  "keywords": "['registered']",
                  "display": 1,
                  "translatedName": "registrado"
                },
                {
                  "code": 3030,
                  "name": "wavy dash",
                  "category": "symbols",
                  "keywords": "['dash', 'punctuation', 'wavy']",
                  "display": 1,
                  "translatedName": "traço ondulado"
                },
                {
                  "code": "27b0",
                  "name": "curly loop",
                  "category": "symbols",
                  "keywords": "['curl', 'loop']",
                  "display": 1,
                  "translatedName": "loop encaracolado"
                },
                {
                  "code": "27bf",
                  "name": "double curly loop",
                  "category": "symbols",
                  "keywords": "['curl', 'double', 'loop']",
                  "display": 1,
                  "translatedName": "loop duplo encaracolado"
                },
                {
                  "code": "1f51a",
                  "name": "END arrow",
                  "category": "symbols",
                  "keywords": "['arrow', 'end']",
                  "display": 1,
                  "translatedName": "Seta final"
                },
                {
                  "code": "1f519",
                  "name": "BACK arrow",
                  "category": "symbols",
                  "keywords": "['arrow', 'back']",
                  "display": 1,
                  "translatedName": "Back arrow."
                },
                {
                  "code": "1f51b",
                  "name": "ON! arrow",
                  "category": "symbols",
                  "keywords": "['arrow', 'mark', 'on']",
                  "display": 1,
                  "translatedName": "SOBRE! seta"
                },
                {
                  "code": "1f51d",
                  "name": "TOP arrow",
                  "category": "symbols",
                  "keywords": "['arrow', 'top', 'up']",
                  "display": 1,
                  "translatedName": "Seta superior"
                },
                {
                  "code": "1f51c",
                  "name": "SOON arrow",
                  "category": "symbols",
                  "keywords": "['arrow', 'soon']",
                  "display": 1,
                  "translatedName": "Logo arrow."
                },
                {
                  "code": 2714,
                  "name": "heavy check mark",
                  "category": "symbols",
                  "keywords": "['check', 'mark']",
                  "display": 1,
                  "translatedName": "Marca de verificação pesada"
                },
                {
                  "code": 2611,
                  "name": "ballot box with check",
                  "category": "symbols",
                  "keywords": "['ballot', 'box', 'check']",
                  "display": 1,
                  "translatedName": "cédula com cheque"
                },
                {
                  "code": "1f518",
                  "name": "radio button",
                  "category": "symbols",
                  "keywords": "['button', 'geometric', 'radio']",
                  "display": 1,
                  "translatedName": "botao de radio"
                },
                {
                  "code": "26aa",
                  "name": "white circle",
                  "category": "symbols",
                  "keywords": "['circle', 'geometric']",
                  "display": 1,
                  "translatedName": "Círculo branco"
                },
                {
                  "code": "26ab",
                  "name": "black circle",
                  "category": "symbols",
                  "keywords": "['circle', 'geometric']",
                  "display": 1,
                  "translatedName": "Círculo preto"
                },
                {
                  "code": "1f534",
                  "name": "red circle",
                  "category": "symbols",
                  "keywords": "['circle', 'geometric', 'red']",
                  "display": 1,
                  "translatedName": "círculo vermelho"
                },
                {
                  "code": "1f535",
                  "name": "blue circle",
                  "category": "symbols",
                  "keywords": "['blue', 'circle', 'geometric']",
                  "display": 1,
                  "translatedName": "Círculo azul"
                },
                {
                  "code": "1f53a",
                  "name": "red triangle pointed up",
                  "category": "symbols",
                  "keywords": "['geometric', 'red']",
                  "display": 1,
                  "translatedName": "Triângulo vermelho apontou para cima"
                },
                {
                  "code": "1f53b",
                  "name": "red triangle pointed down",
                  "category": "symbols",
                  "keywords": "['down', 'geometric', 'red']",
                  "display": 1,
                  "translatedName": "Triângulo vermelho apontou para baixo"
                },
                {
                  "code": "1f538",
                  "name": "small orange diamond",
                  "category": "symbols",
                  "keywords": "['diamond', 'geometric', 'orange']",
                  "display": 1,
                  "translatedName": "pequeno diamante laranja"
                },
                {
                  "code": "1f539",
                  "name": "small blue diamond",
                  "category": "symbols",
                  "keywords": "['blue', 'diamond', 'geometric']",
                  "display": 1,
                  "translatedName": "Diamante azul pequeno"
                },
                {
                  "code": "1f536",
                  "name": "large orange diamond",
                  "category": "symbols",
                  "keywords": "['diamond', 'geometric', 'orange']",
                  "display": 1,
                  "translatedName": "Grande laranja diamante"
                },
                {
                  "code": "1f537",
                  "name": "large blue diamond",
                  "category": "symbols",
                  "keywords": "['blue', 'diamond', 'geometric']",
                  "display": 1,
                  "translatedName": "Grande diamante azul"
                },
                {
                  "code": "1f533",
                  "name": "white square button",
                  "category": "symbols",
                  "keywords": "['button', 'geometric', 'outlined', 'square']",
                  "display": 1,
                  "translatedName": "Botão quadrado branco"
                },
                {
                  "code": "1f532",
                  "name": "black square button",
                  "category": "symbols",
                  "keywords": "['button', 'geometric', 'square']",
                  "display": 1,
                  "translatedName": "Botão quadrado preto"
                },
                {
                  "code": "25aa",
                  "name": "black small square",
                  "category": "symbols",
                  "keywords": "['geometric', 'square']",
                  "display": 1,
                  "translatedName": "Quadrado pequeno preto"
                },
                {
                  "code": "25ab",
                  "name": "white small square",
                  "category": "symbols",
                  "keywords": "['geometric', 'square']",
                  "display": 1,
                  "translatedName": "Quadrado pequeno branco"
                },
                {
                  "code": "25fe",
                  "name": "black medium-small square",
                  "category": "symbols",
                  "keywords": "['geometric', 'square']",
                  "display": 1,
                  "translatedName": "quadrado médio-pequeno preto"
                },
                {
                  "code": "25fd",
                  "name": "white medium-small square",
                  "category": "symbols",
                  "keywords": "['geometric', 'square']",
                  "display": 1,
                  "translatedName": "Quadrado médio-pequeno branco"
                },
                {
                  "code": "25fc",
                  "name": "black medium square",
                  "category": "symbols",
                  "keywords": "['geometric', 'square']",
                  "display": 1,
                  "translatedName": "quadrado médio preto"
                },
                {
                  "code": "25fb",
                  "name": "white medium square",
                  "category": "symbols",
                  "keywords": "['geometric', 'square']",
                  "display": 1,
                  "translatedName": "quadrado médio branco"
                },
                {
                  "code": "2b1b",
                  "name": "black large square",
                  "category": "symbols",
                  "keywords": "['geometric', 'square']",
                  "display": 1,
                  "translatedName": "grande quadrado preto"
                },
                {
                  "code": "2b1c",
                  "name": "white large square",
                  "category": "symbols",
                  "keywords": "['geometric', 'square']",
                  "display": 1,
                  "translatedName": "grande quadrado branco"
                },
                {
                  "code": "1f508",
                  "name": "speaker low volume",
                  "category": "symbols",
                  "keywords": "['soft']",
                  "display": 1,
                  "translatedName": "Alto-falante baixo volume."
                },
                {
                  "code": "1f507",
                  "name": "muted speaker",
                  "category": "symbols",
                  "keywords": "['mute', 'quiet', 'silent', 'speaker']",
                  "display": 1,
                  "translatedName": "Alto-falante silenciado."
                },
                {
                  "code": "1f509",
                  "name": "speaker medium volume",
                  "category": "symbols",
                  "keywords": "['medium']",
                  "display": 1,
                  "translatedName": "volume médio de alto-falante"
                },
                {
                  "code": "1f50a",
                  "name": "speaker high volume",
                  "category": "symbols",
                  "keywords": "['loud']",
                  "display": 1,
                  "translatedName": "Volume alto de alto-falante"
                },
                {
                  "code": "1f514",
                  "name": "bell",
                  "category": "symbols",
                  "keywords": "['bell']",
                  "display": 1,
                  "translatedName": "Sino"
                },
                {
                  "code": "1f515",
                  "name": "bell with slash",
                  "category": "symbols",
                  "keywords": "['bell', 'forbidden', 'mute', 'no', 'not', 'prohibited', 'quiet', 'silent']",
                  "display": 1,
                  "translatedName": "sino com barra"
                },
                {
                  "code": "1f4e3",
                  "name": "megaphone",
                  "category": "symbols",
                  "keywords": "['cheering']",
                  "display": 1,
                  "translatedName": "megafone"
                },
                {
                  "code": "1f4e2",
                  "name": "loudspeaker",
                  "category": "symbols",
                  "keywords": "['loud', 'public address']",
                  "display": 1,
                  "translatedName": "Alto-falante"
                },
                {
                  "code": "1f5e8",
                  "name": "left speech bubble",
                  "category": "symbols",
                  "keywords": "['dialog', 'speech']",
                  "display": 1,
                  "translatedName": "Bolha do discurso esquerdo"
                },
                {
                  "code": "1f441-1f5e8",
                  "name": "eye in speech bubble",
                  "category": "symbols",
                  "keywords": "['eye', 'speech bubble', 'witness']",
                  "display": 1,
                  "translatedName": "olho na bolha do discurso"
                },
                {
                  "code": "1f4ac",
                  "name": "speech balloon",
                  "category": "symbols",
                  "keywords": "['balloon', 'bubble', 'comic', 'dialog', 'speech']",
                  "display": 1,
                  "translatedName": "balão de fala"
                },
                {
                  "code": "1f4ad",
                  "name": "thought balloon",
                  "category": "symbols",
                  "keywords": "['balloon', 'bubble', 'comic', 'thought']",
                  "display": 1,
                  "translatedName": "balão de pensamento"
                },
                {
                  "code": "1f5ef",
                  "name": "right anger bubble",
                  "category": "symbols",
                  "keywords": "['angry', 'balloon', 'bubble', 'mad']",
                  "display": 1,
                  "translatedName": "Bolha de raiva direita"
                },
                {
                  "code": 2660,
                  "name": "spade suit",
                  "category": "symbols",
                  "keywords": "['card', 'game']",
                  "display": 1,
                  "translatedName": "terno de pá"
                },
                {
                  "code": 2663,
                  "name": "club suit",
                  "category": "symbols",
                  "keywords": "['card', 'game']",
                  "display": 1,
                  "translatedName": "terno do clube"
                },
                {
                  "code": 2665,
                  "name": "heart suit",
                  "category": "symbols",
                  "keywords": "['card', 'game']",
                  "display": 1,
                  "translatedName": "terno de coração"
                },
                {
                  "code": 2666,
                  "name": "diamond suit",
                  "category": "symbols",
                  "keywords": "['card', 'game']",
                  "display": 1,
                  "translatedName": "terno de diamante"
                },
                {
                  "code": "1f0cf",
                  "name": "joker",
                  "category": "symbols",
                  "keywords": "['card', 'game', 'wildcard']",
                  "display": 1,
                  "translatedName": "palhaço"
                },
                {
                  "code": "1f3b4",
                  "name": "flower playing cards",
                  "category": "symbols",
                  "keywords": "['Japanese', 'card', 'flower', 'game', 'playing']",
                  "display": 1,
                  "translatedName": "Cartas de jogar flor"
                },
                {
                  "code": "1f004",
                  "name": "mahjong red dragon",
                  "category": "symbols",
                  "keywords": "['game', 'mahjong', 'red']",
                  "display": 1,
                  "translatedName": "dragão vermelho de mahjong."
                },
                {
                  "code": "1f550",
                  "name": "one o’clock",
                  "category": "symbols",
                  "keywords": "['00', '1', '1:00', 'clock', 'one', 'o’clock']",
                  "display": 1,
                  "translatedName": "uma hora"
                },
                {
                  "code": "1f551",
                  "name": "two o’clock",
                  "category": "symbols",
                  "keywords": "['00', '2', '2:00', 'clock', 'o’clock', 'two']",
                  "display": 1,
                  "translatedName": "duas horas"
                },
                {
                  "code": "1f552",
                  "name": "three o’clock",
                  "category": "symbols",
                  "keywords": "['00', '3', '3:00', 'clock', 'o’clock', 'three']",
                  "display": 1,
                  "translatedName": "três horas"
                },
                {
                  "code": "1f553",
                  "name": "four o’clock",
                  "category": "symbols",
                  "keywords": "['00', '4', '4:00', 'clock', 'four', 'o’clock']",
                  "display": 1,
                  "translatedName": "quatro horas"
                },
                {
                  "code": "1f554",
                  "name": "five o’clock",
                  "category": "symbols",
                  "keywords": "['00', '5', '5:00', 'clock', 'five', 'o’clock']",
                  "display": 1,
                  "translatedName": "cinco horas"
                },
                {
                  "code": "1f555",
                  "name": "six o’clock",
                  "category": "symbols",
                  "keywords": "['00', '6', '6:00', 'clock', 'o’clock', 'six']",
                  "display": 1,
                  "translatedName": "seis horas"
                },
                {
                  "code": "1f556",
                  "name": "seven o’clock",
                  "category": "symbols",
                  "keywords": "['00', '7', '7:00', 'clock', 'o’clock', 'seven']",
                  "display": 1,
                  "translatedName": "sete horas"
                },
                {
                  "code": "1f557",
                  "name": "eight o’clock",
                  "category": "symbols",
                  "keywords": "['00', '8', '8:00', 'clock', 'eight', 'o’clock']",
                  "display": 1,
                  "translatedName": "oito horas"
                },
                {
                  "code": "1f558",
                  "name": "nine o’clock",
                  "category": "symbols",
                  "keywords": "['00', '9', '9:00', 'clock', 'nine', 'o’clock']",
                  "display": 1,
                  "translatedName": "nove horas"
                },
                {
                  "code": "1f559",
                  "name": "ten o’clock",
                  "category": "symbols",
                  "keywords": "['00', '10', '10:00', 'clock', 'o’clock', 'ten']",
                  "display": 1,
                  "translatedName": "dez horas"
                },
                {
                  "code": "1f55a",
                  "name": "eleven o’clock",
                  "category": "symbols",
                  "keywords": "['00', '11', '11:00', 'clock', 'eleven', 'o’clock']",
                  "display": 1,
                  "translatedName": "onze horas"
                },
                {
                  "code": "1f55b",
                  "name": "twelve o’clock",
                  "category": "symbols",
                  "keywords": "['00', '12', '12:00', 'clock', 'o’clock', 'twelve']",
                  "display": 1,
                  "translatedName": "doze horas"
                },
                {
                  "code": "1f55c",
                  "name": "one-thirty",
                  "category": "symbols",
                  "keywords": "['1', '1:30', '30', 'clock', 'one', 'thirty']",
                  "display": 1,
                  "translatedName": "uma e meia"
                },
                {
                  "code": "1f55d",
                  "name": "two-thirty",
                  "category": "symbols",
                  "keywords": "['2', '2:30', '30', 'clock', 'thirty', 'two']",
                  "display": 1,
                  "translatedName": "duas e meia"
                },
                {
                  "code": "1f55e",
                  "name": "three-thirty",
                  "category": "symbols",
                  "keywords": "['3', '30', '3:30', 'clock', 'thirty', 'three']",
                  "display": 1,
                  "translatedName": "três e meia"
                },
                {
                  "code": "1f55f",
                  "name": "four-thirty",
                  "category": "symbols",
                  "keywords": "['4', '30', '4:30', 'clock', 'four', 'thirty']",
                  "display": 1,
                  "translatedName": "quatro e meia"
                },
                {
                  "code": "1f560",
                  "name": "five-thirty",
                  "category": "symbols",
                  "keywords": "['5', '30', '5:30', 'clock', 'five', 'thirty']",
                  "display": 1,
                  "translatedName": "cinco e meia"
                },
                {
                  "code": "1f561",
                  "name": "six-thirty",
                  "category": "symbols",
                  "keywords": "['6', '30', '6:30', 'clock', 'six', 'thirty']",
                  "display": 1,
                  "translatedName": "seis e Meia"
                },
                {
                  "code": "1f562",
                  "name": "seven-thirty",
                  "category": "symbols",
                  "keywords": "['7', '30', '7:30', 'clock', 'seven', 'thirty']",
                  "display": 1,
                  "translatedName": "sete e meia"
                },
                {
                  "code": "1f563",
                  "name": "eight-thirty",
                  "category": "symbols",
                  "keywords": "['8', '30', '8:30', 'clock', 'eight', 'thirty']",
                  "display": 1,
                  "translatedName": "oito e meia"
                },
                {
                  "code": "1f564",
                  "name": "nine-thirty",
                  "category": "symbols",
                  "keywords": "['9', '30', '9:30', 'clock', 'nine', 'thirty']",
                  "display": 1,
                  "translatedName": "nove e meia"
                },
                {
                  "code": "1f565",
                  "name": "ten-thirty",
                  "category": "symbols",
                  "keywords": "['10', '10:30', '30', 'clock', 'ten', 'thirty']",
                  "display": 1,
                  "translatedName": "10:30"
                },
                {
                  "code": "1f566",
                  "name": "eleven-thirty",
                  "category": "symbols",
                  "keywords": "['11', '11:30', '30', 'clock', 'eleven', 'thirty']",
                  "display": 1,
                  "translatedName": "onze e meia"
                },
                {
                  "code": "1f567",
                  "name": "twelve-thirty",
                  "category": "symbols",
                  "keywords": "['12', '12:30', '30', 'clock', 'thirty', 'twelve']",
                  "display": 1,
                  "translatedName": "doze trinta"
                },
                {
                  "code": "1f3f3",
                  "name": "white flag",
                  "category": "flags",
                  "keywords": "['waving']",
                  "display": 1,
                  "translatedName": "bandeira branca"
                },
                {
                  "code": "1f3f4",
                  "name": "black flag",
                  "category": "flags",
                  "keywords": "['waving']",
                  "display": 1,
                  "translatedName": "Bandeira preta"
                },
                {
                  "code": "1f3c1",
                  "name": "chequered flag",
                  "category": "flags",
                  "keywords": "['checkered', 'chequered', 'racing']",
                  "display": 1,
                  "translatedName": "bandeira quadriculada"
                },
                {
                  "code": "1f6a9",
                  "name": "triangular flag",
                  "category": "flags",
                  "keywords": "['post']",
                  "display": 1,
                  "translatedName": "Bandeira triangular"
                },
                {
                  "code": "1f3f3-1f308",
                  "name": "rainbow flag",
                  "category": "flags",
                  "keywords": "['rainbow']",
                  "display": 1,
                  "translatedName": "Bandeira do arco-íris"
                },
                {
                  "code": "1f1e6-1f1eb",
                  "name": "Afghanistan",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Afeganistão"
                },
                {
                  "code": "1f1e6-1f1fd",
                  "name": "Åland Islands",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Ilhas Aland"
                },
                {
                  "code": "1f1e6-1f1f1",
                  "name": "Albania",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Albânia"
                },
                {
                  "code": "1f1e9-1f1ff",
                  "name": "Algeria",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Argélia"
                },
                {
                  "code": "1f1e6-1f1f8",
                  "name": "American Samoa",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Samoa Americana"
                },
                {
                  "code": "1f1e6-1f1e9",
                  "name": "Andorra",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Andorra."
                },
                {
                  "code": "1f1e6-1f1f4",
                  "name": "Angola",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Angola"
                },
                {
                  "code": "1f1e6-1f1ee",
                  "name": "Anguilla",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Anguilla."
                },
                {
                  "code": "1f1e6-1f1f6",
                  "name": "Antarctica",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Antártica"
                },
                {
                  "code": "1f1e6-1f1ec",
                  "name": "Antigua & Barbuda",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Antígua e Barbuda."
                },
                {
                  "code": "1f1e6-1f1f7",
                  "name": "Argentina",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Argentina"
                },
                {
                  "code": "1f1e6-1f1f2",
                  "name": "Armenia",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Armênia"
                },
                {
                  "code": "1f1e6-1f1fc",
                  "name": "Aruba",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Aruba."
                },
                {
                  "code": "1f1e6-1f1fa",
                  "name": "Australia",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Austrália"
                },
                {
                  "code": "1f1e6-1f1f9",
                  "name": "Austria",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Áustria"
                },
                {
                  "code": "1f1e6-1f1ff",
                  "name": "Azerbaijan",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Azerbaijão"
                },
                {
                  "code": "1f1e7-1f1f8",
                  "name": "Bahamas",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Bahamas."
                },
                {
                  "code": "1f1e7-1f1ed",
                  "name": "Bahrain",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Bahrein"
                },
                {
                  "code": "1f1e7-1f1e9",
                  "name": "Bangladesh",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Bangladesh"
                },
                {
                  "code": "1f1e7-1f1e7",
                  "name": "Barbados",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Barbados."
                },
                {
                  "code": "1f1e7-1f1fe",
                  "name": "Belarus",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Bielorrússia"
                },
                {
                  "code": "1f1e7-1f1ea",
                  "name": "Belgium",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Bélgica"
                },
                {
                  "code": "1f1e7-1f1ff",
                  "name": "Belize",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Belize"
                },
                {
                  "code": "1f1e7-1f1ef",
                  "name": "Benin",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Benin"
                },
                {
                  "code": "1f1e7-1f1f2",
                  "name": "Bermuda",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Bermudas"
                },
                {
                  "code": "1f1e7-1f1f9",
                  "name": "Bhutan",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Butão"
                },
                {
                  "code": "1f1e7-1f1f4",
                  "name": "Bolivia",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Bolívia"
                },
                {
                  "code": "1f1e7-1f1e6",
                  "name": "Bosnia & Herzegovina",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Bósnia e Herzegovina"
                },
                {
                  "code": "1f1e7-1f1fc",
                  "name": "Botswana",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Botsuana."
                },
                {
                  "code": "1f1e7-1f1f7",
                  "name": "Brazil",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Brasil"
                },
                {
                  "code": "1f1ee-1f1f4",
                  "name": "British Indian Ocean Territory",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Território do Oceano Índico Britânico"
                },
                {
                  "code": "1f1fb-1f1ec",
                  "name": "British Virgin Islands",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Ilhas Virgens Britânicas"
                },
                {
                  "code": "1f1e7-1f1f3",
                  "name": "Brunei",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Brunei"
                },
                {
                  "code": "1f1e7-1f1ec",
                  "name": "Bulgaria",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Bulgária"
                },
                {
                  "code": "1f1e7-1f1eb",
                  "name": "Burkina Faso",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Burkina Faso"
                },
                {
                  "code": "1f1e7-1f1ee",
                  "name": "Burundi",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Burundi"
                },
                {
                  "code": "1f1f0-1f1ed",
                  "name": "Cambodia",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Camboja"
                },
                {
                  "code": "1f1e8-1f1f2",
                  "name": "Cameroon",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Camarões"
                },
                {
                  "code": "1f1e8-1f1e6",
                  "name": "Canada",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Canadá"
                },
                {
                  "code": "1f1ee-1f1e8",
                  "name": "Canary Islands",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Ilhas Canárias"
                },
                {
                  "code": "1f1e8-1f1fb",
                  "name": "Cape Verde",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "cabo Verde"
                },
                {
                  "code": "1f1e7-1f1f6",
                  "name": "Caribbean Netherlands",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Caribe Holanda"
                },
                {
                  "code": "1f1f0-1f1fe",
                  "name": "Cayman Islands",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Ilhas Cayman"
                },
                {
                  "code": "1f1e8-1f1eb",
                  "name": "Central African Republic",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "República Centro-Africana"
                },
                {
                  "code": "1f1f9-1f1e9",
                  "name": "Chad",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Chade"
                },
                {
                  "code": "1f1e8-1f1f1",
                  "name": "Chile",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Chile"
                },
                {
                  "code": "1f1e8-1f1f3",
                  "name": "China",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "China"
                },
                {
                  "code": "1f1e8-1f1fd",
                  "name": "Christmas Island",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Ilha do Natal"
                },
                {
                  "code": "1f1e8-1f1e8",
                  "name": "Cocos (Keeling) Islands",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Ilhas Cocos (Keeling)"
                },
                {
                  "code": "1f1e8-1f1f4",
                  "name": "Colombia",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Colômbia"
                },
                {
                  "code": "1f1f0-1f1f2",
                  "name": "Comoros",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Comores."
                },
                {
                  "code": "1f1e8-1f1ec",
                  "name": "Congo - Brazzaville",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Congo - Brazzaville"
                },
                {
                  "code": "1f1e8-1f1e9",
                  "name": "Congo - Kinshasa",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Congo - Kinshasa."
                },
                {
                  "code": "1f1e8-1f1f0",
                  "name": "Cook Islands",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Ilhas Cook"
                },
                {
                  "code": "1f1e8-1f1f7",
                  "name": "Costa Rica",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Costa Rica"
                },
                {
                  "code": "1f1e8-1f1ee",
                  "name": "Côte d’Ivoire",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Costa do Marfim"
                },
                {
                  "code": "1f1ed-1f1f7",
                  "name": "Croatia",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Croácia"
                },
                {
                  "code": "1f1e8-1f1fa",
                  "name": "Cuba",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Cuba"
                },
                {
                  "code": "1f1e8-1f1fc",
                  "name": "Curaçao",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Curaçao."
                },
                {
                  "code": "1f1e8-1f1fe",
                  "name": "Cyprus",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Chipre"
                },
                {
                  "code": "1f1e8-1f1ff",
                  "name": "Czechia",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Czechia."
                },
                {
                  "code": "1f1e9-1f1f0",
                  "name": "Denmark",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Dinamarca"
                },
                {
                  "code": "1f1e9-1f1ef",
                  "name": "Djibouti",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Djibuti."
                },
                {
                  "code": "1f1e9-1f1f2",
                  "name": "Dominica",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Dominica."
                },
                {
                  "code": "1f1e9-1f1f4",
                  "name": "Dominican Republic",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "República Dominicana"
                },
                {
                  "code": "1f1ea-1f1e8",
                  "name": "Ecuador",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Equador"
                },
                {
                  "code": "1f1ea-1f1ec",
                  "name": "Egypt",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Egito"
                },
                {
                  "code": "1f1f8-1f1fb",
                  "name": "El Salvador",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "El Salvador"
                },
                {
                  "code": "1f1ec-1f1f6",
                  "name": "Equatorial Guinea",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Guiné Equatorial"
                },
                {
                  "code": "1f1ea-1f1f7",
                  "name": "Eritrea",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Eritreia."
                },
                {
                  "code": "1f1ea-1f1ea",
                  "name": "Estonia",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Estônia"
                },
                {
                  "code": "1f1ea-1f1f9",
                  "name": "Ethiopia",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Etiópia"
                },
                {
                  "code": "1f1ea-1f1fa",
                  "name": "European Union",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "União Européia"
                },
                {
                  "code": "1f1eb-1f1f0",
                  "name": "Falkland Islands",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Ilhas Falkland"
                },
                {
                  "code": "1f1eb-1f1f4",
                  "name": "Faroe Islands",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "ilhas Faroe"
                },
                {
                  "code": "1f1eb-1f1ef",
                  "name": "Fiji",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Fiji."
                },
                {
                  "code": "1f1eb-1f1ee",
                  "name": "Finland",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Finlândia"
                },
                {
                  "code": "1f1eb-1f1f7",
                  "name": "France",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "França"
                },
                {
                  "code": "1f1ec-1f1eb",
                  "name": "French Guiana",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Guiana Francesa"
                },
                {
                  "code": "1f1f5-1f1eb",
                  "name": "French Polynesia",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Polinésia Francesa"
                },
                {
                  "code": "1f1f9-1f1eb",
                  "name": "French Southern Territories",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Territórios do Sul franceses"
                },
                {
                  "code": "1f1ec-1f1e6",
                  "name": "Gabon",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Gabão"
                },
                {
                  "code": "1f1ec-1f1f2",
                  "name": "Gambia",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Gâmbia"
                },
                {
                  "code": "1f1ec-1f1ea",
                  "name": "Georgia",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Geórgia"
                },
                {
                  "code": "1f1e9-1f1ea",
                  "name": "Germany",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Alemanha"
                },
                {
                  "code": "1f1ec-1f1ed",
                  "name": "Ghana",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Gana"
                },
                {
                  "code": "1f1ec-1f1ee",
                  "name": "Gibraltar",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Gibraltar"
                },
                {
                  "code": "1f1ec-1f1f7",
                  "name": "Greece",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Grécia"
                },
                {
                  "code": "1f1ec-1f1f1",
                  "name": "Greenland",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Groenlândia"
                },
                {
                  "code": "1f1ec-1f1e9",
                  "name": "Grenada",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Granada"
                },
                {
                  "code": "1f1ec-1f1f5",
                  "name": "Guadeloupe",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Guadalupe."
                },
                {
                  "code": "1f1ec-1f1fa",
                  "name": "Guam",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Guam"
                },
                {
                  "code": "1f1ec-1f1f9",
                  "name": "Guatemala",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Guatemala"
                },
                {
                  "code": "1f1ec-1f1ec",
                  "name": "Guernsey",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Guernsey."
                },
                {
                  "code": "1f1ec-1f1f3",
                  "name": "Guinea",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Guiné"
                },
                {
                  "code": "1f1ec-1f1fc",
                  "name": "Guinea-Bissau",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Guiné-Bissau."
                },
                {
                  "code": "1f1ec-1f1fe",
                  "name": "Guyana",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Guiana"
                },
                {
                  "code": "1f1ed-1f1f9",
                  "name": "Haiti",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Haiti"
                },
                {
                  "code": "1f1ed-1f1f3",
                  "name": "Honduras",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Honduras"
                },
                {
                  "code": "1f1ed-1f1f0",
                  "name": "Hong Kong SAR China",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Hong Kong SAR China"
                },
                {
                  "code": "1f1ed-1f1fa",
                  "name": "Hungary",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Hungria"
                },
                {
                  "code": "1f1ee-1f1f8",
                  "name": "Iceland",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Islândia"
                },
                {
                  "code": "1f1ee-1f1f3",
                  "name": "India",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Índia"
                },
                {
                  "code": "1f1ee-1f1e9",
                  "name": "Indonesia",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Indonésia"
                },
                {
                  "code": "1f1ee-1f1f7",
                  "name": "Iran",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Irã"
                },
                {
                  "code": "1f1ee-1f1f6",
                  "name": "Iraq",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Iraque"
                },
                {
                  "code": "1f1ee-1f1ea",
                  "name": "Ireland",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Irlanda"
                },
                {
                  "code": "1f1ee-1f1f2",
                  "name": "Isle of Man",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Ilha do homem"
                },
                {
                  "code": "1f1ee-1f1f1",
                  "name": "Israel",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Israel"
                },
                {
                  "code": "1f1ee-1f1f9",
                  "name": "Italy",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Itália"
                },
                {
                  "code": "1f1ef-1f1f2",
                  "name": "Jamaica",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Jamaica"
                },
                {
                  "code": "1f1ef-1f1f5",
                  "name": "Japan",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Japão"
                },
                {
                  "code": "1f38c",
                  "name": "crossed flags",
                  "category": "flags",
                  "keywords": "['Japanese', 'celebration', 'cross', 'crossed']",
                  "display": 1,
                  "translatedName": "bandeiras cruzadas"
                },
                {
                  "code": "1f1ef-1f1ea",
                  "name": "Jersey",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Jersey."
                },
                {
                  "code": "1f1ef-1f1f4",
                  "name": "Jordan",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Jordânia"
                },
                {
                  "code": "1f1f0-1f1ff",
                  "name": "Kazakhstan",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Cazaquistão"
                },
                {
                  "code": "1f1f0-1f1ea",
                  "name": "Kenya",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Quênia"
                },
                {
                  "code": "1f1f0-1f1ee",
                  "name": "Kiribati",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Kiribati."
                },
                {
                  "code": "1f1fd-1f1f0",
                  "name": "Kosovo",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Kosovo"
                },
                {
                  "code": "1f1f0-1f1fc",
                  "name": "Kuwait",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Kuwait"
                },
                {
                  "code": "1f1f0-1f1ec",
                  "name": "Kyrgyzstan",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Quirguistão."
                },
                {
                  "code": "1f1f1-1f1e6",
                  "name": "Laos",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Laos"
                },
                {
                  "code": "1f1f1-1f1fb",
                  "name": "Latvia",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Letônia"
                },
                {
                  "code": "1f1f1-1f1e7",
                  "name": "Lebanon",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Líbano"
                },
                {
                  "code": "1f1f1-1f1f8",
                  "name": "Lesotho",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Lesoto"
                },
                {
                  "code": "1f1f1-1f1f7",
                  "name": "Liberia",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Libéria"
                },
                {
                  "code": "1f1f1-1f1fe",
                  "name": "Libya",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Líbia"
                },
                {
                  "code": "1f1f1-1f1ee",
                  "name": "Liechtenstein",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Liechtenstein."
                },
                {
                  "code": "1f1f1-1f1f9",
                  "name": "Lithuania",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Lituânia"
                },
                {
                  "code": "1f1f1-1f1fa",
                  "name": "Luxembourg",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Luxemburgo"
                },
                {
                  "code": "1f1f2-1f1f4",
                  "name": "Macau SAR China",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Macau SAR China."
                },
                {
                  "code": "1f1f2-1f1f0",
                  "name": "Macedonia",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Macedônia."
                },
                {
                  "code": "1f1f2-1f1ec",
                  "name": "Madagascar",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Madagáscar"
                },
                {
                  "code": "1f1f2-1f1fc",
                  "name": "Malawi",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Malawi."
                },
                {
                  "code": "1f1f2-1f1fe",
                  "name": "Malaysia",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Malásia"
                },
                {
                  "code": "1f1f2-1f1fb",
                  "name": "Maldives",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Maldivas"
                },
                {
                  "code": "1f1f2-1f1f1",
                  "name": "Mali",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Mali."
                },
                {
                  "code": "1f1f2-1f1f9",
                  "name": "Malta",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Malta"
                },
                {
                  "code": "1f1f2-1f1ed",
                  "name": "Marshall Islands",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Ilhas Marshall"
                },
                {
                  "code": "1f1f2-1f1f6",
                  "name": "Martinique",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Martinica."
                },
                {
                  "code": "1f1f2-1f1f7",
                  "name": "Mauritania",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Mauritânia"
                },
                {
                  "code": "1f1f2-1f1fa",
                  "name": "Mauritius",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Maurício"
                },
                {
                  "code": "1f1fe-1f1f9",
                  "name": "Mayotte",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Mayotte."
                },
                {
                  "code": "1f1f2-1f1fd",
                  "name": "Mexico",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "México"
                },
                {
                  "code": "1f1eb-1f1f2",
                  "name": "Micronesia",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Micronésia."
                },
                {
                  "code": "1f1f2-1f1e9",
                  "name": "Moldova",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Moldávia"
                },
                {
                  "code": "1f1f2-1f1e8",
                  "name": "Monaco",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Mônaco"
                },
                {
                  "code": "1f1f2-1f1f3",
                  "name": "Mongolia",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Mongólia"
                },
                {
                  "code": "1f1f2-1f1ea",
                  "name": "Montenegro",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Montenegro"
                },
                {
                  "code": "1f1f2-1f1f8",
                  "name": "Montserrat",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Montserrat."
                },
                {
                  "code": "1f1f2-1f1e6",
                  "name": "Morocco",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Marrocos"
                },
                {
                  "code": "1f1f2-1f1ff",
                  "name": "Mozambique",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Moçambique"
                },
                {
                  "code": "1f1f2-1f1f2",
                  "name": "Myanmar (Burma)",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Myanmar (Birmânia)"
                },
                {
                  "code": "1f1f3-1f1e6",
                  "name": "Namibia",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Namíbia."
                },
                {
                  "code": "1f1f3-1f1f7",
                  "name": "Nauru",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Nauru."
                },
                {
                  "code": "1f1f3-1f1f5",
                  "name": "Nepal",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Nepal"
                },
                {
                  "code": "1f1f3-1f1f1",
                  "name": "Netherlands",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Holanda"
                },
                {
                  "code": "1f1f3-1f1e8",
                  "name": "New Caledonia",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Nova Caledônia"
                },
                {
                  "code": "1f1f3-1f1ff",
                  "name": "New Zealand",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Nova Zelândia"
                },
                {
                  "code": "1f1f3-1f1ee",
                  "name": "Nicaragua",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Nicarágua"
                },
                {
                  "code": "1f1f3-1f1ea",
                  "name": "Niger",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Níger"
                },
                {
                  "code": "1f1f3-1f1ec",
                  "name": "Nigeria",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Nigéria"
                },
                {
                  "code": "1f1f3-1f1fa",
                  "name": "Niue",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Niue."
                },
                {
                  "code": "1f1f3-1f1eb",
                  "name": "Norfolk Island",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Ilha de Norfolk."
                },
                {
                  "code": "1f1f0-1f1f5",
                  "name": "North Korea",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Coréia do Norte"
                },
                {
                  "code": "1f1f2-1f1f5",
                  "name": "Northern Mariana Islands",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Ilhas Marianas do Norte"
                },
                {
                  "code": "1f1f3-1f1f4",
                  "name": "Norway",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Noruega"
                },
                {
                  "code": "1f1f4-1f1f2",
                  "name": "Oman",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Omã"
                },
                {
                  "code": "1f1f5-1f1f0",
                  "name": "Pakistan",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Paquistão"
                },
                {
                  "code": "1f1f5-1f1fc",
                  "name": "Palau",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Palau."
                },
                {
                  "code": "1f1f5-1f1f8",
                  "name": "Palestinian Territories",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Territórios Palestinos"
                },
                {
                  "code": "1f1f5-1f1e6",
                  "name": "Panama",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Panamá"
                },
                {
                  "code": "1f1f5-1f1ec",
                  "name": "Papua New Guinea",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Papua Nova Guiné"
                },
                {
                  "code": "1f1f5-1f1fe",
                  "name": "Paraguay",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Paraguai"
                },
                {
                  "code": "1f1f5-1f1ea",
                  "name": "Peru",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Peru"
                },
                {
                  "code": "1f1f5-1f1ed",
                  "name": "Philippines",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Filipinas"
                },
                {
                  "code": "1f1f5-1f1f3",
                  "name": "Pitcairn Islands",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Ilhas de Pitcairn."
                },
                {
                  "code": "1f1f5-1f1f1",
                  "name": "Poland",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Polônia"
                },
                {
                  "code": "1f1f5-1f1f9",
                  "name": "Portugal",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Portugal"
                },
                {
                  "code": "1f1f5-1f1f7",
                  "name": "Puerto Rico",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Porto Rico"
                },
                {
                  "code": "1f1f6-1f1e6",
                  "name": "Qatar",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Catar"
                },
                {
                  "code": "1f1f7-1f1ea",
                  "name": "Réunion",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Reunião"
                },
                {
                  "code": "1f1f7-1f1f4",
                  "name": "Romania",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Romênia"
                },
                {
                  "code": "1f1f7-1f1fa",
                  "name": "Russia",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Rússia"
                },
                {
                  "code": "1f1f7-1f1fc",
                  "name": "Rwanda",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Ruanda"
                },
                {
                  "code": "1f1fc-1f1f8",
                  "name": "Samoa",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Samoa."
                },
                {
                  "code": "1f1f8-1f1f2",
                  "name": "San Marino",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "San Marino."
                },
                {
                  "code": "1f1f8-1f1f9",
                  "name": "São Tomé & Príncipe",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "São Tomé e Príncipe"
                },
                {
                  "code": "1f1f8-1f1e6",
                  "name": "Saudi Arabia",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Arábia Saudita"
                },
                {
                  "code": "1f1f8-1f1f3",
                  "name": "Senegal",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Senegal"
                },
                {
                  "code": "1f1f7-1f1f8",
                  "name": "Serbia",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Sérvia"
                },
                {
                  "code": "1f1f8-1f1e8",
                  "name": "Seychelles",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Seychelles."
                },
                {
                  "code": "1f1f8-1f1f1",
                  "name": "Sierra Leone",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Serra Leoa"
                },
                {
                  "code": "1f1f8-1f1ec",
                  "name": "Singapore",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Cingapura"
                },
                {
                  "code": "1f1f8-1f1fd",
                  "name": "Sint Maarten",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Sint Maarten."
                },
                {
                  "code": "1f1f8-1f1f0",
                  "name": "Slovakia",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Eslováquia"
                },
                {
                  "code": "1f1f8-1f1ee",
                  "name": "Slovenia",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Eslovênia"
                },
                {
                  "code": "1f1ec-1f1f8",
                  "name": "South Georgia & South Sandwich Islands",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "South Georgia & South Sandwich Islands"
                },
                {
                  "code": "1f1f8-1f1e7",
                  "name": "Solomon Islands",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Ilhas Salomão"
                },
                {
                  "code": "1f1f8-1f1f4",
                  "name": "Somalia",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Somália"
                },
                {
                  "code": "1f1ff-1f1e6",
                  "name": "South Africa",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "África do Sul"
                },
                {
                  "code": "1f1f0-1f1f7",
                  "name": "South Korea",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Coreia do Sul"
                },
                {
                  "code": "1f1f8-1f1f8",
                  "name": "South Sudan",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Sudão do Sul"
                },
                {
                  "code": "1f1ea-1f1f8",
                  "name": "Spain",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Espanha"
                },
                {
                  "code": "1f1f1-1f1f0",
                  "name": "Sri Lanka",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Sri Lanka"
                },
                {
                  "code": "1f1e7-1f1f1",
                  "name": "St. Barthélemy",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "St. BarthÉlemy."
                },
                {
                  "code": "1f1f8-1f1ed",
                  "name": "St. Helena",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "St. Helena"
                },
                {
                  "code": "1f1f0-1f1f3",
                  "name": "St. Kitts & Nevis",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "St. Kitts & Nevis"
                },
                {
                  "code": "1f1f1-1f1e8",
                  "name": "St. Lucia",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Santa Lúcia"
                },
                {
                  "code": "1f1f5-1f1f2",
                  "name": "St. Pierre & Miquelon",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "St. Pierre & Miquelon"
                },
                {
                  "code": "1f1fb-1f1e8",
                  "name": "St. Vincent & Grenadines",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "São Vicente e Granadinas"
                },
                {
                  "code": "1f1f8-1f1e9",
                  "name": "Sudan",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Sudão"
                },
                {
                  "code": "1f1f8-1f1f7",
                  "name": "Suriname",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Suriname"
                },
                {
                  "code": "1f1f8-1f1ff",
                  "name": "Swaziland",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Suazilândia."
                },
                {
                  "code": "1f1f8-1f1ea",
                  "name": "Sweden",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Suécia"
                },
                {
                  "code": "1f1e8-1f1ed",
                  "name": "Switzerland",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Suíça"
                },
                {
                  "code": "1f1f8-1f1fe",
                  "name": "Syria",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Síria"
                },
                {
                  "code": "1f1f9-1f1fc",
                  "name": "Taiwan",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Taiwan"
                },
                {
                  "code": "1f1f9-1f1ef",
                  "name": "Tajikistan",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Tajiquistão."
                },
                {
                  "code": "1f1f9-1f1ff",
                  "name": "Tanzania",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Tanzânia"
                },
                {
                  "code": "1f1f9-1f1ed",
                  "name": "Thailand",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Tailândia"
                },
                {
                  "code": "1f1f9-1f1f1",
                  "name": "Timor-Leste",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Timor-Leste."
                },
                {
                  "code": "1f1f9-1f1ec",
                  "name": "Togo",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Ir"
                },
                {
                  "code": "1f1f9-1f1f0",
                  "name": "Tokelau",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Tokelau."
                },
                {
                  "code": "1f1f9-1f1f4",
                  "name": "Tonga",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Tonga"
                },
                {
                  "code": "1f1f9-1f1f9",
                  "name": "Trinidad & Tobago",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Trinidad & Tobago."
                },
                {
                  "code": "1f1f9-1f1f3",
                  "name": "Tunisia",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Tunísia"
                },
                {
                  "code": "1f1f9-1f1f7",
                  "name": "Turkey",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Turquia"
                },
                {
                  "code": "1f1f9-1f1f2",
                  "name": "Turkmenistan",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Turquemenistão."
                },
                {
                  "code": "1f1f9-1f1e8",
                  "name": "Turks & Caicos Islands",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Ilhas Turks & Caicos"
                },
                {
                  "code": "1f1f9-1f1fb",
                  "name": "Tuvalu",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Tuvalu."
                },
                {
                  "code": "1f1fb-1f1ee",
                  "name": "U.S. Virgin Islands",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Ilhas Virgens U.S."
                },
                {
                  "code": "1f1fa-1f1ec",
                  "name": "Uganda",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Uganda"
                },
                {
                  "code": "1f1fa-1f1e6",
                  "name": "Ukraine",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Ucrânia"
                },
                {
                  "code": "1f1e6-1f1ea",
                  "name": "United Arab Emirates",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Emirados Árabes Unidos"
                },
                {
                  "code": "1f1ec-1f1e7",
                  "name": "United Kingdom",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Reino Unido"
                },
                {
                  "code": "1f1fa-1f1f8",
                  "name": "United States",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Estados Unidos"
                },
                {
                  "code": "1f1fa-1f1fe",
                  "name": "Uruguay",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Uruguai"
                },
                {
                  "code": "1f1fa-1f1ff",
                  "name": "Uzbekistan",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Uzbequistão"
                },
                {
                  "code": "1f1fb-1f1fa",
                  "name": "Vanuatu",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Vanuatu"
                },
                {
                  "code": "1f1fb-1f1e6",
                  "name": "Vatican City",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Cidade do Vaticano"
                },
                {
                  "code": "1f1fb-1f1ea",
                  "name": "Venezuela",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Venezuela"
                },
                {
                  "code": "1f1fb-1f1f3",
                  "name": "Vietnam",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Vietnã"
                },
                {
                  "code": "1f1fc-1f1eb",
                  "name": "Wallis & Futuna",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Wallis & Futuna."
                },
                {
                  "code": "1f1ea-1f1ed",
                  "name": "Western Sahara",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Saara Ocidental"
                },
                {
                  "code": "1f1fe-1f1ea",
                  "name": "Yemen",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Iémen"
                },
                {
                  "code": "1f1ff-1f1f2",
                  "name": "Zambia",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Zâmbia"
                },
                {
                  "code": "1f1ff-1f1fc",
                  "name": "Zimbabwe",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Zimbábue"
                },
                {
                  "code": "1f1e6-1f1e8",
                  "name": "Ascension Island",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Ilha de ascensão"
                },
                {
                  "code": "1f1f9-1f1e6",
                  "name": "Tristan da Cunha",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Tristan da cunha."
                },
                {
                  "code": "1f1e7-1f1fb",
                  "name": "Bouvet Island",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Ilha Bouvet."
                },
                {
                  "code": "1f1ed-1f1f2",
                  "name": "Heard & McDonald Islands",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Heard & McDonald Islands"
                },
                {
                  "code": "1f1f8-1f1ef",
                  "name": "Svalbard & Jan Mayen",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Svalbard & Jan Mayen"
                },
                {
                  "code": "1f1fa-1f1f2",
                  "name": "U.S. Outlying Islands",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Ilhas U.S. Distantes"
                },
                {
                  "code": "1f1ea-1f1e6",
                  "name": "Ceuta & Melilla",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Ceuta e Melila"
                },
                {
                  "code": "1f1e8-1f1f5",
                  "name": "Clipperton Island",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Ilha de Clipperton."
                },
                {
                  "code": "1f1e9-1f1ec",
                  "name": "Diego Garcia",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Diego Garcia."
                },
                {
                  "code": "1f1f2-1f1eb",
                  "name": "St. Martin",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "St. Martin."
                },
                {
                  "code": "1f1fa-1f1f3",
                  "name": "United Nations",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Nações Unidas"
                },
                {
                  "code": "1f3f4-e0067-e0062-e0065-e006e-e0067-e007f",
                  "name": "England",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Inglaterra"
                },
                {
                  "code": "1f3f4-e0067-e0062-e0073-e0063-e0074-e007f",
                  "name": "Scotland",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "Escócia"
                },
                {
                  "code": "1f3f4-e0067-e0062-e0077-e006c-e0073-e007f",
                  "name": "Wales",
                  "category": "flags",
                  "keywords": "['flag']",
                  "display": 1,
                  "translatedName": "País de Gales."
                },
                {
                  "code": "002a",
                  "name": "asterisk",
                  "category": "symbols",
                  "keywords": "['']",
                  "display": 0,
                  "translatedName": "asterisco"
                },
                {
                  "code": "1f1ff",
                  "name": "regional indicator symbol letter z",
                  "category": "regional",
                  "keywords": "['']",
                  "display": 0,
                  "translatedName": "Indicador Regional Symbol Letter Z"
                },
                {
                  "code": "1f1fe",
                  "name": "regional indicator symbol letter y",
                  "category": "regional",
                  "keywords": "['']",
                  "display": 0,
                  "translatedName": "letra de símbolo indicador regional y"
                },
                {
                  "code": "1f1fd",
                  "name": "regional indicator symbol letter x",
                  "category": "regional",
                  "keywords": "['']",
                  "display": 0,
                  "translatedName": "letra de símbolo indicador regional x"
                },
                {
                  "code": 23,
                  "name": "pound symbol",
                  "category": "symbols",
                  "keywords": "['']",
                  "display": 0,
                  "translatedName": "símbolo de libra"
                },
                {
                  "code": "1f1fc",
                  "name": "regional indicator symbol letter w",
                  "category": "regional",
                  "keywords": "['']",
                  "display": 0,
                  "translatedName": "letra de símbolo indicador regional w"
                },
                {
                  "code": "1f1fb",
                  "name": "regional indicator symbol letter v",
                  "category": "regional",
                  "keywords": "['']",
                  "display": 0,
                  "translatedName": "Indicador Regional Symbol Letter V"
                },
                {
                  "code": "1f1fa",
                  "name": "regional indicator symbol letter u",
                  "category": "regional",
                  "keywords": "['']",
                  "display": 0,
                  "translatedName": "letra de símbolo indicador regional u"
                },
                {
                  "code": "1f1f9",
                  "name": "regional indicator symbol letter t",
                  "category": "regional",
                  "keywords": "['']",
                  "display": 0,
                  "translatedName": "letra de símbolo indicador regional t"
                },
                {
                  "code": "1f1f8",
                  "name": "regional indicator symbol letter s",
                  "category": "regional",
                  "keywords": "['']",
                  "display": 0,
                  "translatedName": "letra de símbolo indicador regional s"
                },
                {
                  "code": "1f1f7",
                  "name": "regional indicator symbol letter r",
                  "category": "regional",
                  "keywords": "['']",
                  "display": 0,
                  "translatedName": "letra de símbolo indicador regional r"
                },
                {
                  "code": "1f1f6",
                  "name": "regional indicator symbol letter q",
                  "category": "regional",
                  "keywords": "['']",
                  "display": 0,
                  "translatedName": "letra de símbolo indicador regional Q"
                },
                {
                  "code": "1f1f5",
                  "name": "regional indicator symbol letter p",
                  "category": "regional",
                  "keywords": "['']",
                  "display": 0,
                  "translatedName": "letra de símbolo indicador regional p"
                },
                {
                  "code": "1f1f4",
                  "name": "regional indicator symbol letter o",
                  "category": "regional",
                  "keywords": "['']",
                  "display": 0,
                  "translatedName": "letra do símbolo indicador regional"
                },
                {
                  "code": "1f1f3",
                  "name": "regional indicator symbol letter n",
                  "category": "regional",
                  "keywords": "['']",
                  "display": 0,
                  "translatedName": "letra de símbolo indicador regional n"
                },
                {
                  "code": "1f1f2",
                  "name": "regional indicator symbol letter m",
                  "category": "regional",
                  "keywords": "['']",
                  "display": 0,
                  "translatedName": "letra de símbolo indicador regional m"
                },
                {
                  "code": "1f1f1",
                  "name": "regional indicator symbol letter l",
                  "category": "regional",
                  "keywords": "['']",
                  "display": 0,
                  "translatedName": "letra de símbolo indicador regional l"
                },
                {
                  "code": "1f1f0",
                  "name": "regional indicator symbol letter k",
                  "category": "regional",
                  "keywords": "['']",
                  "display": 0,
                  "translatedName": "letra de símbolo indicador regional k"
                },
                {
                  "code": "1f1ef",
                  "name": "regional indicator symbol letter j",
                  "category": "regional",
                  "keywords": "['']",
                  "display": 0,
                  "translatedName": "Símbolo de indicador regional letra j"
                },
                {
                  "code": "1f1ee",
                  "name": "regional indicator symbol letter i",
                  "category": "regional",
                  "keywords": "['']",
                  "display": 0,
                  "translatedName": "letra do símbolo indicador regional i"
                },
                {
                  "code": "1f1ed",
                  "name": "regional indicator symbol letter h",
                  "category": "regional",
                  "keywords": "['']",
                  "display": 0,
                  "translatedName": "letra de símbolo indicador regional h"
                },
                {
                  "code": "1f1ec",
                  "name": "regional indicator symbol letter g",
                  "category": "regional",
                  "keywords": "['']",
                  "display": 0,
                  "translatedName": "letra de símbolo indicador regional g"
                },
                {
                  "code": "1f1eb",
                  "name": "regional indicator symbol letter f",
                  "category": "regional",
                  "keywords": "['']",
                  "display": 0,
                  "translatedName": "letra de símbolo indicador regional f"
                },
                {
                  "code": "1f1ea",
                  "name": "regional indicator symbol letter e",
                  "category": "regional",
                  "keywords": "['']",
                  "display": 0,
                  "translatedName": "letra do símbolo indicador regional e"
                },
                {
                  "code": "1f1e9",
                  "name": "regional indicator symbol letter d",
                  "category": "regional",
                  "keywords": "['']",
                  "display": 0,
                  "translatedName": "letra de símbolo indicador regional d"
                },
                {
                  "code": "1f1e8",
                  "name": "regional indicator symbol letter c",
                  "category": "regional",
                  "keywords": "['']",
                  "display": 0,
                  "translatedName": "letra de símbolo de indicador regional c"
                },
                {
                  "code": "1f1e7",
                  "name": "regional indicator symbol letter b",
                  "category": "regional",
                  "keywords": "['']",
                  "display": 0,
                  "translatedName": "letra de símbolo de indicador regional b"
                },
                {
                  "code": "1f1e6",
                  "name": "regional indicator symbol letter a",
                  "category": "regional",
                  "keywords": "['']",
                  "display": 0,
                  "translatedName": "letra de símbolo indicador regional A"
                },
                {
                  "code": 39,
                  "name": "digit nine",
                  "category": "symbols",
                  "keywords": "['']",
                  "display": 0,
                  "translatedName": "dígito nove"
                },
                {
                  "code": 38,
                  "name": "digit eight",
                  "category": "symbols",
                  "keywords": "['']",
                  "display": 0,
                  "translatedName": "dígito oito."
                },
                {
                  "code": 37,
                  "name": "digit seven",
                  "category": "symbols",
                  "keywords": "['']",
                  "display": 0,
                  "translatedName": "dígito sete."
                },
                {
                  "code": 36,
                  "name": "digit six",
                  "category": "symbols",
                  "keywords": "['']",
                  "display": 0,
                  "translatedName": "dígito seis."
                },
                {
                  "code": 35,
                  "name": "digit five",
                  "category": "symbols",
                  "keywords": "['']",
                  "display": 0,
                  "translatedName": "dígito cinco."
                },
                {
                  "code": 34,
                  "name": "digit four",
                  "category": "symbols",
                  "keywords": "['']",
                  "display": 0,
                  "translatedName": "dígito quatro."
                },
                {
                  "code": 33,
                  "name": "digit three",
                  "category": "symbols",
                  "keywords": "['']",
                  "display": 0,
                  "translatedName": "dígito três."
                },
                {
                  "code": 32,
                  "name": "digit two",
                  "category": "symbols",
                  "keywords": "['']",
                  "display": 0,
                  "translatedName": "Dígito dois."
                },
                {
                  "code": 31,
                  "name": "digit one",
                  "category": "symbols",
                  "keywords": "['']",
                  "display": 0,
                  "translatedName": "dígito um"
                },
                {
                  "code": 30,
                  "name": "digit zero",
                  "category": "symbols",
                  "keywords": "['']",
                  "display": 0,
                  "translatedName": "dígito zero"
                },
                {
                  "code": 2640,
                  "name": "female sign",
                  "category": "people",
                  "keywords": "['woman']",
                  "display": 0,
                  "translatedName": "sinal feminino"
                },
                {
                  "code": 2642,
                  "name": "male sign",
                  "category": "people",
                  "keywords": "['man']",
                  "display": 0,
                  "translatedName": "sinal masculino"
                },
                {
                  "code": 2695,
                  "name": "medical symbol",
                  "category": "people",
                  "keywords": "['aesculapius', 'medicine', 'staff']",
                  "display": 0,
                  "translatedName": "símbolo médico"
                },
                {
                  "code": "1f468-1f469-1f466",
                  "name": "family: man, woman, boy",
                  "category": "people",
                  "keywords": "['boy', 'family', 'man', 'woman']",
                  "display": 0,
                  "translatedName": "Família: homem, mulher, menino"
                },
                {
                  "code": "1f469-2764-1f468",
                  "name": "couple with heart: woman, man",
                  "category": "people",
                  "keywords": "['couple', 'love', 'man', 'woman']",
                  "display": 0,
                  "translatedName": "Casal com coração: mulher, homem"
                },
                {
                  "code": "1f469-2764-1f48b-1f468",
                  "name": "kiss: woman, man",
                  "category": "people",
                  "keywords": "['couple', 'man', 'woman']",
                  "display": 0,
                  "translatedName": "Beijo: Mulher, Homem"
                },
                {
                  "code": "1f928",
                  "name": "face with raised eyebrow",
                  "category": "people",
                  "keywords": "['']",
                  "display": 1,
                  "translatedName": "rosto com sobrancelha levantada"
                },
                {
                  "code": "1f929",
                  "name": "star-struck",
                  "category": "people",
                  "keywords": "['']",
                  "display": 1,
                  "translatedName": "estrela struck."
                },
                {
                  "code": "1f92a",
                  "name": "crazy face",
                  "category": "people",
                  "keywords": "['eye', 'large', 'small']",
                  "display": 1,
                  "translatedName": "Cara de maluco"
                },
                {
                  "code": "1f92b",
                  "name": "shushing face",
                  "category": "people",
                  "keywords": "['quiet', 'shush']",
                  "display": 1,
                  "translatedName": "Shushing Face"
                },
                {
                  "code": "1f92c",
                  "name": "face with symbols over mouth",
                  "category": "people",
                  "keywords": "['']",
                  "display": 1,
                  "translatedName": "rosto com símbolos sobre a boca"
                },
                {
                  "code": "1f92d",
                  "name": "face with hand over mouth",
                  "category": "people",
                  "keywords": "['']",
                  "display": 1,
                  "translatedName": "cara com a mão sobre a boca"
                },
                {
                  "code": "1f92e",
                  "name": "face vomiting",
                  "category": "people",
                  "keywords": "['sick', 'vomit']",
                  "display": 1,
                  "translatedName": "vômito de rosto"
                },
                {
                  "code": "1f92f",
                  "name": "exploding head",
                  "category": "people",
                  "keywords": "['shocked']",
                  "display": 1,
                  "translatedName": "cabeça explodindo"
                },
                {
                  "code": "1f9d0",
                  "name": "face with monocle",
                  "category": "people",
                  "keywords": "['']",
                  "display": 1,
                  "translatedName": "rosto com monóculo"
                },
                {
                  "code": "1f9d1",
                  "name": "adult",
                  "category": "people",
                  "keywords": "['']",
                  "display": 1,
                  "translatedName": "adulto"
                },
                {
                  "code": "1f9d2",
                  "name": "child",
                  "category": "people",
                  "keywords": "['']",
                  "display": 1,
                  "translatedName": "filho"
                },
                {
                  "code": "1f9d3",
                  "name": "older adult",
                  "category": "people",
                  "keywords": "['']",
                  "display": 1,
                  "translatedName": "adulto mais velho"
                },
                {
                  "code": "1f9d4",
                  "name": "bearded person",
                  "category": "people",
                  "keywords": "['']",
                  "display": 1,
                  "translatedName": "pessoa barbada"
                },
                {
                  "code": "1f9d5",
                  "name": "woman with headscarf",
                  "category": "people",
                  "keywords": "['']",
                  "display": 1,
                  "translatedName": "Mulher com headscarf."
                },
                {
                  "code": "1f9e0",
                  "name": "brain",
                  "category": "people",
                  "keywords": "['intelligent']",
                  "display": 1,
                  "translatedName": "cérebro"
                },
                {
                  "code": "1f9e2",
                  "name": "billed cap",
                  "category": "people",
                  "keywords": "['baseball cap']",
                  "display": 1,
                  "translatedName": "Tampa faturada"
                },
                {
                  "code": "1f9d9",
                  "name": "mage",
                  "category": "people",
                  "keywords": "['sorcerer', 'sorceress', 'witch', 'wizard']",
                  "display": 0,
                  "translatedName": "mago"
                },
                {
                  "code": "1f9da",
                  "name": "fairy",
                  "category": "people",
                  "keywords": "['Oberon', 'Puck', 'Titania']",
                  "display": 0,
                  "translatedName": "fadas"
                },
                {
                  "code": "1f9db",
                  "name": "vampire",
                  "category": "people",
                  "keywords": "['Dracula', 'undead']",
                  "display": 0,
                  "translatedName": "vampiro"
                },
                {
                  "code": "1f9dc",
                  "name": "merperson",
                  "category": "people",
                  "keywords": "['mermaid', 'merman', 'merwoman']",
                  "display": 0,
                  "translatedName": "merperson."
                },
                {
                  "code": "1f9dd",
                  "name": "elf",
                  "category": "people",
                  "keywords": "['']",
                  "display": 0,
                  "translatedName": "duende"
                },
                {
                  "code": "1f9de",
                  "name": "genie",
                  "category": "people",
                  "keywords": "['']",
                  "display": 0,
                  "translatedName": "gênio"
                },
                {
                  "code": "1f9df",
                  "name": "zombie",
                  "category": "people",
                  "keywords": "['']",
                  "display": 0,
                  "translatedName": "zumbi."
                },
                {
                  "code": "1f9e3",
                  "name": "scarf",
                  "category": "people",
                  "keywords": "['neck']",
                  "display": 1,
                  "translatedName": "lenço"
                },
                {
                  "code": "1f9e4",
                  "name": "gloves",
                  "category": "people",
                  "keywords": "['hand']",
                  "display": 1,
                  "translatedName": "luvas"
                },
                {
                  "code": "1f9e5",
                  "name": "coat",
                  "category": "people",
                  "keywords": "['jacket']",
                  "display": 1,
                  "translatedName": "casaco"
                },
                {
                  "code": "1f9e6",
                  "name": "socks",
                  "category": "people",
                  "keywords": "['stocking']",
                  "display": 1,
                  "translatedName": "meias"
                },
                {
                  "code": "1f91f",
                  "name": "love-you gesture",
                  "category": "people",
                  "keywords": "['ILY', 'hand']",
                  "display": 1,
                  "translatedName": "amor-você gesto"
                },
                {
                  "code": "1f932",
                  "name": "palms up together",
                  "category": "people",
                  "keywords": "['']",
                  "display": 1,
                  "translatedName": "palmas juntas"
                },
                {
                  "code": "1f9d9-2640",
                  "name": "woman mage",
                  "category": "people",
                  "keywords": "['sorceress', 'witch']",
                  "display": 1,
                  "translatedName": "Mago de mulher"
                },
                {
                  "code": "1f9d9-2642",
                  "name": "man mage",
                  "category": "people",
                  "keywords": "['sorcerer', 'wizard']",
                  "display": 1,
                  "translatedName": "homem mago"
                },
                {
                  "code": "1f9da-2640",
                  "name": "woman fairy",
                  "category": "people",
                  "keywords": "['Titania']",
                  "display": 1,
                  "translatedName": "Fada da mulher"
                },
                {
                  "code": "1f9da-2642",
                  "name": "man fairy",
                  "category": "people",
                  "keywords": "['Oberon', 'Puck']",
                  "display": 1,
                  "translatedName": "fada do homem."
                },
                {
                  "code": "1f9db-2640",
                  "name": "woman vampire",
                  "category": "people",
                  "keywords": "['undead']",
                  "display": 1,
                  "translatedName": "Vampiro de mulher"
                },
                {
                  "code": "1f9db-2642",
                  "name": "man vampire",
                  "category": "people",
                  "keywords": "['Dracula', 'undead']",
                  "display": 1,
                  "translatedName": "homem vampiro."
                },
                {
                  "code": "1f9dc-2640",
                  "name": "mermaid",
                  "category": "people",
                  "keywords": "['merwoman']",
                  "display": 1,
                  "translatedName": "sereia"
                },
                {
                  "code": "1f9dc-2642",
                  "name": "merman",
                  "category": "people",
                  "keywords": "['Triton']",
                  "display": 1,
                  "translatedName": "tritão"
                },
                {
                  "code": "1f9dd-2640",
                  "name": "woman elf",
                  "category": "people",
                  "keywords": "['magical']",
                  "display": 1,
                  "translatedName": "Elf mulher"
                },
                {
                  "code": "1f9dd-2642",
                  "name": "man elf",
                  "category": "people",
                  "keywords": "['magical']",
                  "display": 1,
                  "translatedName": "Elf do homem"
                },
                {
                  "code": "1f9de-2640",
                  "name": "woman genie",
                  "category": "people",
                  "keywords": "['djinn']",
                  "display": 1,
                  "translatedName": "Genie da mulher"
                },
                {
                  "code": "1f9de-2642",
                  "name": "man genie",
                  "category": "people",
                  "keywords": "['djinn']",
                  "display": 1,
                  "translatedName": "Genie do homem"
                },
                {
                  "code": "1f9df-2640",
                  "name": "woman zombie",
                  "category": "people",
                  "keywords": "['undead', 'walking dead']",
                  "display": 1,
                  "translatedName": "Zumbi de mulher"
                },
                {
                  "code": "1f9df-2642",
                  "name": "man zombie",
                  "category": "people",
                  "keywords": "['undead', 'walking dead']",
                  "display": 1,
                  "translatedName": "homem zumbi"
                }
              ],
            "FILTERS": [
              {
                "name": "all",
                "emoji": "🔎",
                "template": "Todos os emojis" 
              }
              ,{
                "name": "people",
                "emoji": "😊",
                "template": "Smillies e pessoas" 
              }
              ,{
                "name": "nature",
                "emoji": "🐻",
                "template": "Animais e natureza" 
              }
              ,{
                "name": "food",
                "emoji": "🍔",
                "template": "Alimentos" 
              }
              ,{
                "name": "activity",
                "emoji": "⚽",
                "template": "Atividades" 
              }
              ,{
                "name": "travel",
                "emoji": "🚌",
                "template": "Transportes e viagens" 
              }
              ,{
                "name": "objects",
                "emoji": "💡",
                "template": "Objetos" 
              }
              ,{
                "name": "symbols",
                "emoji": "🔣",
                "template": "Símbolos" 
              }
              ,{
                "name": "flags",
                "emoji": "🚩",
                "template": "Bandeiras" 
              }
            ]
        }
        )
})();
