(function () {
    'use strict';
    angular.module('showcaseApp')
        .controller('NotificationListController', NotificationListController);

    NotificationListController.$inject = ['$uibModalInstance', '$state', '$localStorage', 'toastr', '$translate', 'Principal', 'SettingsService', 'NotificationService'];

    function NotificationListController($uibModalInstance, $state, $localStorage, toastr, $translate, Principal, SettingsService, NotificationService) {

        var vm = this;

        vm.goToMessage = goToMessage;
        vm.readNotifications = readNotifications;
        vm.notifications = [];
        vm.loading = true;
        vm.pagination = {
            page: 0,
            limit: 20
        };

        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        }

        vm.nextPage = function () {
            if (vm.hasNext) {
                vm.pagination.page++;
                getNotificationsInfo(false);
            }
        }

        function getAccount() {
            Principal.identity(false).then(function (account) {
                vm.account = account;
                $translate.use(vm.account.langKey);
            });
        }

        function waitForElementToDisplay(selector, callback, checkFrequencyInMs, timeoutInMs) {
            var startTimeInMs = Date.now();
            (function loopSearch() {
                if (document.getElementById(selector) != null) {
                    callback();
                    return;
                } else {
                    setTimeout(function () {
                        if (timeoutInMs && Date.now() - startTimeInMs > timeoutInMs)
                            return;
                        loopSearch();
                    }, checkFrequencyInMs);
                }
            })();
        }

        function getNotificationsInfo(hook) {
            vm.systemNotificationsSound = true;
            SettingsService.getRegister($localStorage.userId, $localStorage.defaultStore.storeId).then(function (data) {
                vm.systemNotificationsSound = data['soundNotification'];
            });

            NotificationService.getNotificationsListByStoreAndReadIsFalse(
                $localStorage.defaultStore.storeId,
                vm.pagination
            ).then(function (result) {
                vm.hasNext = !result.data.last;
                result.data.content.forEach(function (item) {
                    if ((item.accountId == null || item.accountId == vm.account.id)) {
                        if(item.type == 'CHANGE_ATTENDANT') {
                            item.message = $translate.instant('notifications.messages.change-attendant') + item.title;
                        }

                        switch (item.origin) {
                            case 'WATI':
                                item.image = '/content/images/icon-whatsapp.png';
                                item.origin = 'WHATSAPP';
                                break;
                            case 'MESSENGER':
                                item.image = '/content/images/icon-messenger.png';
                                break;
                            case 'INSTAGRAM':
                                item.image = '/content/images/icon-instagram.png';
                                break;
                            default:
                                item.image = '/content/images/notification-badge.png';
                                break;
                        }

                        var date = new Date(item.updatedAt);
                        var today = new Date();
                        var diff = Math.abs(today - date);
                        var minutes_diff  = Math.floor((diff/1000)/60);

                        if (minutes_diff < 1) {
                            item.updatedAt = $translate.instant('notifications.messages.now')
                        } else if (minutes_diff == 1) {
                            item.updatedAt = $translate.instant('notifications.messages.pre-time') + '1 ' + $translate.instant('notifications.messages.minute');
                        } else if (minutes_diff < 6) {
                            item.updatedAt = $translate.instant('notifications.messages.pre-time') + minutes_diff + ' ' + $translate.instant('notifications.messages.minutes');
                        } else if (today.getDate() - date.getDate() == 0) {
                            item.updatedAt = ("0" + date.getHours()).slice(-2) + ':' + ("0" + date.getMinutes()).slice(-2);
                        } else if (today.getDate() - date.getDate() == 1) {
                            item.updatedAt = $translate.instant('notifications.messages.yesterday') +
                                ("0" + date.getHours()).slice(-2) + ':' + ("0" + date.getMinutes()).slice(-2);
                        } else {
                            item.updatedAt = date.toLocaleDateString("pt-br");
                        }

                        vm.notifications.push(item);
                    }
                });

                vm.loading = false;
            });
        }

        function goToMessage(notification) {
            vm.readNotifications(notification);
            $state.go('communication', {}, {reload: true});
            $localStorage.redirectNotificationData = {
                conversationId: notification.chatId,
                chatPlatform: notification.origin
            };
            vm.cancel();
        }

        function readNotifications(notification) {
            if (!notification.read) {
                NotificationService.readNotification(notification.id).then(function (data) {
                });
            }
        }

        function loadAll() {
            getAccount();

            waitForElementToDisplay("modalContent",function() {
                var acc = document.getElementById("modalContent");
                acc.style.height = screen.height - 100 + 'px';
            });

            vm.notifications = [];
            getNotificationsInfo(false);
        }

        loadAll();
    }

})();
