(function() {
    'use strict';

    angular
        .module('showcaseApp')
        .controller('StoreCategorysController', StoreCategorysController);

    StoreCategorysController.$inject = ['$scope', '$state', 'uiGridConstants', 'uiGridBuilder', 'CategoryService', '$localStorage', '$translate', '$uibModal'];

    function StoreCategorysController($scope, $state, uiGridConstants, uiGridBuilder, CategoryService, $localStorage, $translate, $uibModal) {

      var vm = this;
      vm.arrayPagination = [];
      vm.startRegistry = 0;
      vm.endRegistry = 0;
      vm.tableDivisor = 10;
      vm.filterFields = {};
      vm.defaultSearchIndex = 0;
      vm.checkIsFirstPage = false;
      vm.checkIsLastPage = false;
      vm.columnDefs = getColumnDefs();
      vm.currentPage = $state.current.name;

      loadAll();

      function loadAll() {
          vm.gridTableOptions = getGridOptions();
      }

      function getColumnDefs() {
          return [{
                  field: 'key',
                  displayName: 'categorys.form.key',
                  headerCellFilter: 'translate',
              },
              {
                  field: 'langKey',
                  displayName: 'categorys.form.langKey',
                  headerCellFilter: 'translate',
              },
              {
                  field: 'title',
                  displayName: 'categorys.form.title',
                  headerCellFilter: 'translate',
              }
          ];
      }

      function getGridOptions() {
          return uiGridBuilder.withDefaultOptions()
              .withActionColumn(100)
              .addActionToActionColumn("edit", "vm.getEditPage", "fa fa-edit", "text-primary", true)
              .addActionToActionColumn("delete", "vm.getDeletePage", "fa fa-trash", "text-primary", true)
              .withColumns(vm.columnDefs).build('search');
      }

      $scope.search = function() {
          var params = null;
          var pageable = vm.gridTableOptions.getPageAndSortOptions().getPageAbleObj();
          CategoryService.listAllCategories(pageable, vm.filterFields).then(function(response) {
              vm.totalItems = response.headers['x-total-count'];
              vm.gridTableOptions.virtualizationThreshold = response.data.length;
              vm.gridTableOptions.update(response.data, vm.totalItems);
              vm.gridTableOptions.getArrayPagination(function(arrayPagination) {
                  vm.arrayPagination = arrayPagination;
              });
              refreshDataInfos();
              vm.checkIsLastPage = vm.gridTableApi.pagination.getPage() === vm.gridTableApi.pagination.getTotalPages();
              vm.checkIsFirstPage = vm.gridTableApi.pagination.getPage() === 1;
              vm.pagesSize = createArray(vm.totalItems, vm.tableDivisor);
          });
      }

      vm.reload = function() {
        $scope.search();
      }

      function createArray(range, divisor) {
          if(!divisor)divisor = 0;
          if (divisor != 0) {
              range = range / divisor;
          }
          var values = [];
          var iteratorNumber = 0;
          for (var i = 0; i < range; i++) {
              iteratorNumber = divisor != 0 ? i * divisor : i + 1;
              values.push(iteratorNumber);
          }
          return values;
      };

      function refreshDataInfos() {
          vm.gridTableApi = vm.gridTableOptions.getApiGridTable();
          vm.startRegistry = (vm.gridTableApi.pagination.getPage() * vm.gridTableOptions.paginationPageSize) - vm.gridTableOptions.paginationPageSize;
          vm.endCalc = vm.gridTableOptions.paginationPageSize * vm.gridTableApi.pagination.getPage();
          vm.endRegistry = vm.endCalc > vm.totalItems ? vm.totalItems : vm.endCalc;
      };

      vm.changePageSize = function(pageSize) {
          vm.gridTableOptions.paginationPageSize = pageSize;
      };

      vm.reloadUsers = function() {
          $scope.search();
      }

      vm.stopPropagation = function(event) {
          event.stopPropagation();
      };

      vm.changeColumnView = function($index) {
          vm.gridTableOptions.columnDefs[$index].visible = !(vm.gridTableOptions.columnDefs[$index].visible || vm.gridTableOptions.columnDefs[$index].visible === undefined);
          vm.gridTableApi.core.notifyDataChange(uiGridConstants.dataChange.COLUMN);
      };

      vm.exporterTable = function(type) {
          if (type === 'pdf') {
              vm.gridTableApi.exporter.pdfExport('visible', 'visible');
          } else if (type === 'csv') {
              vm.gridTableApi.exporter.csvExport('visible', 'visible');
          }
      };

      vm.addCategory = function() {
          $uibModal.open({
              templateUrl: 'app/admin/category/category-new.html',
              controller: 'StoreCategorysNewController',
              controllerAs: 'vm',
              scope: $scope,
              backdrop: 'static',
              size: 'md',
              resolve: {
                  user: function() {
                      return undefined;
                  }
              }
          });
      }

      vm.getEditPage = function(category) {
          $uibModal.open({
              templateUrl: 'app/admin/category/category-edit.html',
              controller: 'StoreCategorysEditController',
              controllerAs: 'vm',
              scope: $scope,
              backdrop: 'static',
              size: 'md',
              resolve: {
                  category: function() {
                      return angular.copy(category);
                  }
              }
          }).result.then(function(response) {
              $scope.search();
          });;
      }

      $scope.$on('storeUpdated', function(event, data) {
          $scope.search();
      });

      $scope.$on('$localeChangeSuccess', function(e, locale) {
          vm.gridTableApi.core.notifyDataChange(uiGridConstants.dataChange.ALL);
          vm.gridTableApi.grid.refresh();
      });

    }
})();
