(function() {
    'use strict';

    angular
        .module('showcaseApp')
        .controller('UserManagementController', UserManagementController);

    UserManagementController.$inject = ['$scope', '$state', 'uiGridConstants', 'uiGridBuilder', 'UserService', '$localStorage', '$uibModal'];

    function UserManagementController($scope, $state, uiGridConstants, uiGridBuilder, UserService, $localStorage, $uibModal) {
        var vm = this;
        vm.arrayPagination = [];
        vm.startRegistry = 0;
        vm.endRegistry = 0;
        vm.tableDivisor = 5;
        vm.filterFields = {};
        vm.defaultSearchIndex = 0;
        vm.checkIsFirstPage = false;
        vm.checkIsLastPage = false;
        vm.columnDefs = getColumnDefs();

        loadAll();

        function loadAll() {
            vm.gridTableOptions = getGridOptions();
        }

        function getColumnDefs() {
            return [{
                    field: 'login',
                    displayName: 'userManagement.login',
                    headerCellFilter: 'translate'
                },
                {
                    field: 'firstName',
                    displayName: 'userManagement.firstName',
                    headerCellFilter: 'translate'
                },
                {
                    field: 'lastName',
                    displayName: 'userManagement.lastName',
                    headerCellFilter: 'translate'
                },
                {
                    field: 'email',
                    displayName: 'userManagement.email',
                    headerCellFilter: 'translate',
                    width: '25%'
                },
                {
                    field: 'profileName',
                    displayName: 'userManagement.profileName',
                    headerCellFilter: 'translate',
                    cellTemplate: '<span ng-repeat=\"profile in row.entity.userProfiles\" class=\"label label-info\">{{ profile.profileName }}</span>'
                }
            ];
        }

        function getGridOptions() {
            return uiGridBuilder.withDefaultOptions()
                .withActionColumn(100)
                .addActionToActionColumn("edit", "vm.getEditPage", "fa fa-edit", "text-primary", true)
                .addActionToActionColumn("delete", "vm.getDeletePage", "fa fa-trash", "text-primary", true)
                .withColumns(vm.columnDefs).build('search');
        }

        $scope.search = function() {
            var params = null;
            var pageable = vm.gridTableOptions.getPageAndSortOptions().getPageAbleObj();
            UserService.listUsersByStoreId(pageable, $localStorage.defaultStore.storeId, vm.filterFields).then(function(response) {
                vm.totalItems = response.headers['x-total-count'];
                vm.gridTableOptions.virtualizationThreshold = response.data.length;
                vm.gridTableOptions.update(response.data, vm.totalItems);
                vm.gridTableOptions.getArrayPagination(function(arrayPagination) {
                    vm.arrayPagination = arrayPagination;
                });
                refreshDataInfos();
                vm.checkIsLastPage = vm.gridTableApi.pagination.getPage() === vm.gridTableApi.pagination.getTotalPages();
                vm.checkIsFirstPage = vm.gridTableApi.pagination.getPage() === 1;
                vm.pagesSize = createArray(vm.totalItems, vm.tableDivisor);
            });
        }

        function createArray(range, divisor) {
            if(!divisor)divisor = 0;
            if (divisor != 0) {
                range = range / divisor;
            }
            var values = [];
            var iteratorNumber = 0;
            for (var i = 0; i < range; i++) {
                iteratorNumber = divisor != 0 ? i * divisor : i + 1;
                values.push(iteratorNumber);
            }
            return values;
        };

        function refreshDataInfos() {
            vm.gridTableApi = vm.gridTableOptions.getApiGridTable();
            vm.startRegistry = (vm.gridTableApi.pagination.getPage() * vm.gridTableOptions.paginationPageSize) - vm.gridTableOptions.paginationPageSize;
            vm.endCalc = vm.gridTableOptions.paginationPageSize * vm.gridTableApi.pagination.getPage();
            vm.endRegistry = vm.endCalc > vm.totalItems ? vm.totalItems : vm.endCalc;
        };

        vm.changePageSize = function(pageSize) {
            vm.gridTableOptions.paginationPageSize = pageSize;
        };

        vm.reloadUsers = function() {
            $scope.search();
        }

        vm.stopPropagation = function(event) {
            event.stopPropagation();
        };

        vm.changeColumnView = function($index) {
            vm.gridTableOptions.columnDefs[$index].visible = !(vm.gridTableOptions.columnDefs[$index].visible || vm.gridTableOptions.columnDefs[$index].visible === undefined);
            vm.gridTableApi.core.notifyDataChange(uiGridConstants.dataChange.COLUMN);
        };

        vm.exporterTable = function(type) {
            if (type === 'pdf') {
                vm.gridTableApi.exporter.pdfExport('visible', 'visible');
            } else if (type === 'csv') {
                vm.gridTableApi.exporter.csvExport('visible', 'visible');
            }
        };

        vm.addUser = function() {
            $uibModal.open({
                templateUrl: 'app/admin/user-management/user-management-dialog.html',
                controller: 'UserManagementDialogController',
                controllerAs: 'vm',
                scope: $scope,
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    user: function() {
                        return undefined;
                    },
                    storeId: function() {
                        return $localStorage.defaultStore.storeId;
                    }
                }
            });
        }

        vm.getEditPage = function(row) {
            $uibModal.open({
                templateUrl: 'app/admin/user-management/user-management-dialog.html',
                controller: 'UserManagementDialogController',
                controllerAs: 'vm',
                scope: $scope,
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    storeId: function() {
                        return $localStorage.defaultStore.storeId;
                    },
                    user: function(UserService) {
                        return UserService.getUser({login:row.login, storeId: $localStorage.defaultStore.storeId});
                    }
                }
            });
        }

        vm.getDeletePage = function(row) {
            $uibModal.open({
                templateUrl: 'app/admin/user-management/user-management-delete-dialog.html',
                controller: 'UserManagementDeleteController',
                controllerAs: 'vm',
                scope: $scope,
                size: 'md',
                resolve: {
                    storeId: function() {
                        return $localStorage.defaultStore.storeId;
                    },
                    userLogin: function() {
                        return row.login;
                    }
                }
            });
        }

        $scope.$on('userUpdated', function(event, data) {
            $scope.search();
        });

        $scope.$on('$localeChangeSuccess', function(e, locale) {
            vm.gridTableApi.core.notifyDataChange(uiGridConstants.dataChange.ALL);
            vm.gridTableApi.grid.refresh();
        });
    }
})();
