/* globals $ */
(function() {
	'use strict';

	angular
		.module('showcaseApp')
		.directive('detectLastInteration', function () {
			return function(scope, element, attrs) {
                if (scope.$last){
                  // iteration is complete, do whatever post-processing
                  // is necessary
                  setTimeout(function() {
                    var elements = document.querySelectorAll('video');
                    for(var i = 0; i < elements.length; i++) {
                        var state = elements[i].networkState;
                        if (state === 1) {
                            elements[i].style.display = 'block';
                        }
                    }
                  }, 1500);
                }
              };
		})
})();
