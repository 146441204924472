(function() {
    'use strict';
    angular
        .module('showcaseApp')
        .factory('AbandonedCartNotificationFactory', AbandonedCartNotificationFactory);

    AbandonedCartNotificationFactory.$inject = ['$resource'];

    function AbandonedCartNotificationFactory($resource) {
        var resourceUrl = 'admin/api/abandoned-cart-notify';
        var createNotificationResourceUrl = resourceUrl + '/create';
        var getNotificationsResourceUrl = resourceUrl + '/list';
        var getNotificationByIdResourceUrl = resourceUrl + '/find/:notificationId'
        var editNotificationResourceUrl = resourceUrl + '/edit/:notificationId'
        var removeNotificationResourceUrl = resourceUrl + '/remove/:notificationId'


        return $resource(resourceUrl, {}, {
            'saveNotification': {
                method: 'POST',
                url: createNotificationResourceUrl,
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'findAllNotifications': {
                method: 'GET',
                url: getNotificationsResourceUrl,
                transformResponse: function (data, headers) {
                    var response = {};
                    response.data = angular.fromJson(data);
                    response.headers = headers();
                    return response;
                }
            },
            'findNotificationById': {
                method: 'GET',
                url: getNotificationByIdResourceUrl,
                transformResponse: function (data, headers) {
                    var response = {};
                    response.data = angular.fromJson(data);
                    response.headers = headers();
                    return response;
                }
            },
            'removeNotification': {
                method: 'DELETE',
                url: removeNotificationResourceUrl,
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'editNotification': {
                method: 'PUT',
                url: editNotificationResourceUrl,
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data) {
                        response.data = angular.fromJson(data);
                    }
                    response.headers = headers();
                    return response;
                }
            }
        });
    }

})();
