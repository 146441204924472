(function () {
    'use strict';
    angular
        .module('showcaseApp')
        .factory('NotificationFactory', ChecklistFactory);

    ChecklistFactory.$inject = ['$resource'];
    function ChecklistFactory($resource) {
        var resourceUrl = 'admin/api/activity';
        return $resource(resourceUrl, {}, {
            'getNotificationsListByStoreAndReadIsFalse': {
                method: 'GET',
                url: 'admin/api/notifications/list/store/:storeId',
                transformResponse: function (data, headers) {
                    var response = {};
                    if (data){
                        response.data = angular.fromJson(data);
                    } else{
                        response.data = data;
                    }
                    response.headers = headers();
                    return response;
                }
            },
            'readNotification': {
                method: 'PUT',
                isArray: false,
                url: 'admin/api/notifications/read/:id'
            },
            'updateNotificationsAsReadByStoreIdAndConversationId': {
                method: 'PUT',
                isArray: true,
                url: 'admin/api/notifications/store/:storeId/read/:conversationId'
            },
            'getNotificationInfo': {
                method: 'GET',
                isArray: false,
                url: 'admin/api/notifications/store/:storeId/account/:accountId/info'
            }
        });

    }

})();
